

































import BaseToolbar from '@/components/core/BaseToolbar.vue';
import SchoolStatusDialog from '@/components/core/SchoolStatusDialog.vue';
import SchoolYearSelector from '@/components/core/SchoolYearSelector.vue';
import LessonManageStatus from '@/components/lessons/LessonManageStatus.vue';
import PrintOptions from '@/components/prints/PrintOptions.vue';
import CommonUtils from '@/utils/common-utils';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const settings = namespace('settings');
const plans = namespace('plans');

@Component({
  components: {
    PrintOptions,
    SchoolStatusDialog,
    LessonManageStatus,
    BaseToolbar,
    SchoolYearSelector
  }
})
export default class UserModeSelector extends Vue {
    @settings.Getter('getUserMode')
    userMode!: string;

    @plans.Action
    changeUserMode!: (param?: any) => Promise<any>;

    @settings.Getter
    allowViewTeachers!: string;

    localToolbarDisabled = false;

    async userModeChanged(param: string) {
      if (this.userMode !== param) {
        if (param === 'A' && this.localPage === 'my-list') {
          this.$router.replace('school-list');
        } else if (param === 'T' && this.localPage === 'school-list') {
          this.$router.replace('my-list');
        }
        await this.changeUserMode(param);
        this.$eventBus.$emit('userModeChanged', param);
      }
    }

    get userModeFirstLetter() {
      for (let x = 0; x < this.localUserModeList.length; x++) {
        if (this.localUserModeList[x].value === this.userMode) {
          return this.localUserModeList[x].text.substr(0, 1);
        }
      }
      return '..';
    }

    get canSwitchModes() {
      if (this.$currentUser.isStudent) {
        return false;
      }
      if (this.$currentUser.userType === 'T' && this.allowViewTeachers && !this.$currentUser.isStudent) {
        return ['plans', 'account'].includes(this.localPage);
      }
      return [
        'plans',
        'events',
        'grades',
        'lesson-banks',
        'messages',
        'school-years',
        'reporting',
        'students',
        'assign-students',
        'templates',
        'account',
        'lesson-layouts',
        'strategies',
        'sharing-options',
        'my-list',
        'school-list'
      ].includes(this.localPage);
    }

    get localUserModeList() {
      return [
        {
          text: ['A', 'B', 'D'].includes(this.$currentUser.userType) ? this.$t('adminLabel') as string : this.$t('schoolLabel') as string,
          value: 'A'
        },
        {
          text: this.$t('teacherLabel') as string,
          value: 'T'
        }
      ];
    }

    get localPage() {
      return CommonUtils.getPage(this.$route);
    }

    created() {
      this.$nextTick(() => {
        this.$eventBus.$on('toolbarDisable', () => {
          this.localToolbarDisabled = true;
        });
        this.$eventBus.$on('toolbarEnable', () => {
          this.localToolbarDisabled = false;
        });
      });
    }
}
