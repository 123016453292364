import { BASE_URL } from '@/axios/index';
import { UserSession } from '@/common/user-session';
import AppError, { createErrOptions, FormError, SessionExpiredError } from '@/errors';
import FileServices from '@/services/file-services';
import StudentServices from '@/services/student-services';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '..';

@Module({ namespaced: true })
export default class Students extends VuexModule {
    hasNotesStudents = false;
    notesStudents: any = null;
    attendance = null;
    assessments: any[] = [];
    assignments: any[] = [];
    selectedTags: any[] = [];
    localStudent: any = {};
    origTags: any[] = [];
    origStudent: any = {};
    students: any[] = [];
    gradePeriods: any[] = [];
    studentScores: any[] = [];
    selectedItemScores: any = {};
    showGradesStandards = true;
    showGradesAssessments = true;
    showGradesAssignments = true;
    sortGradesDateAscending = true;
    studentAccountGradesData = null;
    adminSchoolYears: any[] = [];
    listLoading = false;

    // GETTERS: START
    get getNotesStudentsItems() {
      const items = [{
        text: 'All Students',
        value: '0'
      }];
      for (const i in this.notesStudents) {
        items.push({
          text: this.notesStudents[i],
          value: '' + i
        })
      }
      return items;
    }

    get getHasNotesStudents() {
      return this.hasNotesStudents;
    }

    get getStudents() {
      return this.students;
    }

    get getSelectedTags() {
      return this.selectedTags;
    }

    get getAssessments() {
      return this.assessments;
    }

    get getAssignments() {
      return this.assignments;
    }

    get getGradePeriods() {
      return this.gradePeriods;
    }

    get getStudentScores() {
      return this.studentScores;
    }

    get getStudentAccountGradesData() {
      return this.studentAccountGradesData;
    }

    get getListLoading() {
      return this.listLoading;
    }

    get getAdminSchoolYears() {
      return this.adminSchoolYears;
    }
    // GETTERS: END

    // MUTATIONS: START
    @Mutation
    setNotesStudents(notesStudents: any) {
      this.hasNotesStudents = true;
      this.notesStudents = notesStudents;
    }

    @Mutation
    resetAttendance() {
      this.attendance = null;
    }

    @Mutation
    setStudents(students: any) {
      this.students = students;
    }

    @Mutation
    setAttendance(attendance: any) {
      this.attendance = attendance;
    }

    @Mutation
    setHasNotesStudents(hasNotesStudents: boolean) {
      this.hasNotesStudents = hasNotesStudents;
    }

    @Mutation
    setAssessments(assessments: Array<any>) {
      this.assessments = assessments;
    }

    @Mutation
    setAssignments(assignments: Array<any>) {
      this.assignments = assignments;
    }

    @Mutation
    setSelectedTags(tags: any) {
      this.selectedTags = tags;
    }

    @Mutation
    setLocalStudent(student: any) {
      this.localStudent = student;
    }

    @Mutation
    setOrigTags(tags: any) {
      this.origTags = tags;
    }

    @Mutation
    setOrigStudent(student: any) {
      this.origStudent = student;
    }

    @Mutation
    setGradePeriods(gradePeriods: Array<any>) {
      this.gradePeriods = gradePeriods;
    }

    @Mutation
    setStudentScores(studentScores: Array<any>) {
      this.studentScores = studentScores;
    }

    @Mutation
    resetStudentScores() {
      this.studentScores = [];
    }

    @Mutation
    setSelectedItemScores(selectedItemScores: any) {
      this.selectedItemScores = selectedItemScores;
    }

    @Mutation
    resetSelectedItemScores() {
      this.selectedItemScores = {};
    }

    @Mutation
    setShowGradesStandards(showGradesStandards: boolean) {
      this.showGradesStandards = showGradesStandards;
    }

    @Mutation
    setShowGradesAssessments(showGradesAssessments: boolean) {
      this.showGradesAssessments = showGradesAssessments;
    }

    @Mutation
    setShowGradesAssignments(showGradesAssignments: boolean) {
      this.showGradesAssignments = showGradesAssignments;
    }

    @Mutation
    setSortGradesDateAscending(sortGradesDateAscending: boolean) {
      this.sortGradesDateAscending = sortGradesDateAscending;
    }

    @Mutation
    resetStudentAccountGradesData() {
      this.studentAccountGradesData = null;
    }

    @Mutation
    setStudentAccountGradesData(studentAccountGradesData: any) {
      this.studentAccountGradesData = studentAccountGradesData;
    }

    @Mutation
    setListLoading(listLoading: boolean) {
      this.listLoading = listLoading;
    }

    @Mutation
    setAdminSchoolYears(years: any) {
      this.adminSchoolYears = years;
    }
    // MUTATION: END

    // ACTIONS: START
    @Action({ rawError: true })
    async loadStudents(params?: any) {
      try {
        const resp = await StudentServices.getStudentsTagged(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            this.context.commit('setStudents', data.students);
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    private static studentRequest(params: any, context: any) {
      const request: any = {
        studentCode: params.code,
        studentPassword: params.studentPassword,
        studentFirstName: params.firstName,
        studentMiddleName: params.middleName,
        studentLastName: params.lastName,
        studentEmailAddress: params.emailAddress,
        studentPhoneNumber: params.phoneNumber,
        parentEmailAddress: params.parentEmailAddress,
        studentBirthDate: params.birthDate,
        schoolDistrictId: 0,
        userMode: context.rootState.settings.userMode,
        studentPhotoUrl: params.photoUrl
      }
      if (params.gender) {
        request.studentGender = params.gender.value;
      }
      if (params.gradeLevel) {
        request.studentGradeLevel = params.gradeLevel.value;
        request.gradeLevel = params.gradeLevel.value;
      }
      return request;
    }

    @Action({ rawError: true })
    async addStudent(params?: any) {
      const request = Students.studentRequest(params, this.context);
      const allTags = store.state.students.selectedTags;
      request.tagId = allTags.filter((t: any) => t.editable).map((t: any) => { return t.tagId });

      try {
        const resp = await StudentServices.addStudent(request);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            if (data.existingEmail === 'true' || data.existingEmail === true || data.existingStudentCode === 'true' || data.existingStudentCode === true || data.preventTeacherStudents === 'true' || data.preventTeacherStudents === true) {
              return Promise.reject(new FormError(undefined, data));
            } else {
              return Promise.reject(new AppError('contactSupport', { data }));
            }
          } else {
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async updateStudent(params?: any) {
      const request = Students.studentRequest(params, this.context);
      const allTags = store.state.students.selectedTags;
      request.tagId = allTags.filter((t: any) => t.editable).map((t: any) => { return t.tagId });
      request.studentId = params.studentId;

      try {
        const resp = await StudentServices.updateStudent(request);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            if (data.existingEmail === 'true' || data.existingEmail === true || data.existingStudentCode === 'true' || data.existingStudentCode === true) {
              return Promise.reject(new FormError(undefined, data));
            } else {
              return Promise.reject(new AppError('contactSupport', { data }));
            }
          } else {
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async addTag(params: any) {
      try {
        params.userMode = this.context.rootState.settings.userMode;
        const resp = await StudentServices.addTag(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async removeTag(params: any) {
      try {
        const resp = await StudentServices.removeTag(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async deleteStudent(params?: any) {
      try {
        const resp = await StudentServices.deleteStudent(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async deleteStudents(params?: any) {
      const request = {
        studentId: params.studentIds
      }
      try {
        const resp = await StudentServices.deleteStudents(request);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async uploadStudents(params?: any) {
      try {
        const resp = await StudentServices.uploadStudents(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async getPromoteDetails(params?: any) {
      try {
        const resp = await StudentServices.getPromoteDetails(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async promoteStudents(params?: any) {
      try {
        const resp = await StudentServices.promoteStudents(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async getStudentProximityReport(params?: any) {
      const payload = {
        url: BASE_URL + '/services/planbook/print/studentProximityReport',
        params: [
          { name: 'exportType', value: 'pdf' },
          { name: 'studentId', value: params.studentId },
          { name: 'authToken', value: params.authToken || UserSession.getAccessToken() },
          { name: 'X-PB-ACCESS-TOKEN', value: params.authToken || UserSession.getAccessToken() },
          { name: 'X-PB-CLIENT-YEAR-ID', value: UserSession.getCurrentYearId() }
        ]
      };
      const formData = new URLSearchParams();
      payload.params.forEach((p: any) => {
        formData.append(p.name, p.value);
      });
      return fetch(payload.url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        credentials: 'include',
        body: formData
      }).then(async (response) => {
        const type = response.headers.get('Content-Type') as string;
        const disposition = response.headers.get('Content-Disposition') as string;
        const fileName = FileServices.getFileName(disposition);
        if (type.includes('json')) {
          const data = await response.json();
          window.open(data.documentLink, '_blank');
        } else {
          const blob = await response.blob();
          FileServices.downloadFile(blob, fileName, type);
        }
        return Promise.resolve();
      });
    }

    @Action({ rawError: true })
    async loadAttendance(params?: any) {
      try {
        const resp = await StudentServices.getAttendance(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            this.context.commit('resetAttendance');
            this.context.commit('setAttendance', data);
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async loadNotesStudents() {
      try {
        if (this.hasNotesStudents) {
          return Promise.resolve({ data: this.notesStudents });
        } else {
          const resp = await StudentServices.getStudentsForNotes();
          if (resp) {
            const data = resp.data;
            if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
              return Promise.reject(new SessionExpiredError());
            } else if (data.error === 'true' || data.error === true) {
              return Promise.reject(new AppError('contactSupport', { data }));
            } else {
              this.context.commit('setNotesStudents', data);
              return Promise.resolve({ data });
            }
          } else {
            return Promise.resolve({ data: {} });
          }
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async loadStudentScores(params?: any) {
      try {
        const resp = await StudentServices.getStudentScores(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            this.context.commit('resetStudentScores');
            this.context.commit('setAssessments', data.assessments);
            this.context.commit('setAssignments', data.assignments);
            this.context.commit('setGradePeriods', data.gradePeriods);
            this.context.commit('setStudentScores', data.studentScores);
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async overrideScore(params?: any) {
      try {
        const resp = await StudentServices.overrideScore(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve(data);
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async updateStudentScore(params?: any) {
      try {
        const resp = await StudentServices.updateStudentScore(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve(data);
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async updateStandardScore(params?: any) {
      try {
        const resp = await StudentServices.updateStandardScore(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve(data);
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async loadScoresForStudent(params?: any) {
      try {
        const resp = await StudentServices.getScoresForStudent(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            this.context.commit('resetStudentAccountGradesData');
            this.context.commit('setStudentAccountGradesData', data);
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async loadGoogleCourseWorkStatus(params?: any) {
      try {
        const resp = await StudentServices.getGooogleCourseWorkStatus(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async updateAttendance(params?: any) {
      try {
        const resp = await StudentServices.updateAttendance(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve(data);
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async joinClass(params: any) {
      try {
        const resp = await StudentServices.joinClass(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async getSchoolYears(params: any) {
      try {
        const resp = await StudentServices.getSchoolYears(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            this.context.commit('setAdminSchoolYears', data.years);
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async verifyStudentJoinCode(joinCode: string) {
      try {
        const resp = await StudentServices.verifyStudentJoinCode(joinCode);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }
  // ACTIONS: END
}
