
import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class';
import AccountMenu from '@/components/drawer/AccountMenu.vue';
import AddMenu from '@/components/drawer/AddMenu.vue';

const drawer = namespace('drawer');

@Component({
  components: {
    AddMenu,
    AccountMenu
  }
})
export default class RightDrawer extends Vue {
  @drawer.State('drawerRight')
  drawerRight!: boolean;

  @drawer.Mutation
  setDrawerRight!: (v: boolean) => void;

  @drawer.State
  drawerRightStateless!: boolean;

  @drawer.State('drawerRightMode')
  drawerRightMode!: string;

  $refs!: {
    addMenu: AddMenu,
    accountMenu: AccountMenu
  };

  get showDrawer(): boolean {
    return this.drawerRight;
  }

  get drawerMode(): string {
    return this.drawerRightMode || 'account';
  }

  @Watch('drawerRight')
  onOpen() {
    if (this.drawerRight) {
      if (this.drawerMode === 'add') {
        this.$refs.addMenu.focusOnOpen();
      } else {
        this.$refs.accountMenu.focusOnOpen();
      }
    }
  }

  closeDrawer() {
    this.setDrawerRight(false);
  }
}
