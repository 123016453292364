import qs from 'qs';
import axios from '@/axios';

export default class NotesTodoServices {
  public static getTodos() {
    const params = qs.stringify({
      classId: 'all'
    });
    return axios.post('getToDos', params);
  }

  public static getNotes(params?: any) {
    params = params || {};
    return axios.post('services/planbook/newNote/filterNotes', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static getRemindersEvents(params?: any) {
    params = params || {};
    return axios.post('services/planbook/newNote/getRemindersEvents', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static updateTodo(params?: any) {
    params = params || {};
    return axios.post('updateToDo', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static addNote(params?: any) {
    params = params || {};
    return axios.post('services/planbook/newNote/add', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static deleteNote(params?: any) {
    params = params || {};
    return axios.post('services/planbook/newNote/deleteNote', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static deleteMultipleNotesTodos(params?: any) {
    params = params || {};
    return axios.delete('services/planbook/newNote/deleteMultipleNotesTodos', {
      data: params,
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  public static createNewCustomTodo(localTodo:any, initialTodoData:any) {
    const customTodoArray = JSON.parse(localTodo.customTodo);
    const customTodo = {
      customTodoId: customTodoArray.length,
      currentDate: localTodo.currentDate,
      toDoText: localTodo.toDoText,
      priority: localTodo.priority,
      done: localTodo.done === 1
    }
    customTodoArray.push(customTodo);
    localTodo.customTodo = JSON.stringify(customTodoArray);
    localTodo.toDoText = initialTodoData.toDoText;
    localTodo.done = initialTodoData.done;
    localTodo.priority = initialTodoData.priority;
    localTodo.customTodoId = customTodo.customTodoId;
    localTodo.customToDoText = customTodo.toDoText;
    localTodo.customPriority = customTodo.priority;
  }
}
