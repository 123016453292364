import axios, { BASE_URL } from '@/axios';
import { UserSession } from '@/common/user-session';
import CommonUtils from '@/utils/common-utils';
import WebUtils from '@/utils/web-utils';
import qs from 'qs';

export default class AuthServices {
  public static async initSession(accessToken: string, refreshToken?: string) {
    return axios.post('services/auth/initSession', {}, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${accessToken}`,
        'X-PB-REFRESH-TOKEN': refreshToken || ''
      }
    });
  }

  public static async initSessionWithCode(code: string) {
    return axios.post('services/auth/initSession', qs.stringify({ code }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static async invalidateSession(clientId: string) {
    return fetch(BASE_URL + '/services/auth/invalidateSession', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-PB-CLIENT-VIEW-ID': clientId
      },
      credentials: 'include',
      keepalive: true
    }).then(() => {
      console.log('Invalidated');
    }).catch(e => {
      console.log('Error Invalidating.' + e);
    });
  }

  public static async checkSession() {
    return axios.post('services/auth/checkSession', {}, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static getOAuth2CodeAuthorizationUrl() {
    const authUrl = WebUtils.getAuthBaseUrl() + '/oauth2/authorize';
    const clientId = 'planbook';
    const responseType = 'code';
    const scope = encodeURIComponent('openid profile email');
    const state = encodeURIComponent(CommonUtils.generateUUID());
    const redirectUri = encodeURIComponent(`${window.location.protocol}//${window.location.host}/auth/callback`);
    return `${authUrl}?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}`;
  }

  public static async exchangeCodeForTokens(code: string) {
    return AuthServices.initSessionWithCode(code);
  }

  public static async logout() {
    return fetch(WebUtils.getAuthBaseUrl() + '/logout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${UserSession.getAccessToken()}`
      },
      credentials: 'include'
    })
      .then(() => {
        return Promise.resolve({})
      })
      .catch(error => {
        console.error('Error during logout', error);
      });
  }
}
