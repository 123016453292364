import qs from 'qs';
import axios from '@/axios';

export default class ClassworkServices {
  public static getAssessments(params?: any) {
    params = params || {};
    return axios.post('getAssessments', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static getAssessment(params?: any) {
    params = params || {};
    return axios.post('getAssessmentServlet', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static getAssignments(params?: any) {
    params = params || {};
    return axios.post('getAssignments', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static getAssignment(params?: any) {
    params = params || {};
    return axios.post('getAssignmentServlet', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static updateAssignment(params: any) {
    return axios.post('updateAssignment', qs.stringify(params, { arrayFormat: 'repeat' }));
  }

  public static updateAssessment(params: any) {
    return axios.post('updateAssessment', qs.stringify(params, { arrayFormat: 'repeat' }));
  }

  public static deleteMultiple(params: any) {
    return axios.post('services/planbook/classwork/delete', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }
}
