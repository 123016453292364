var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "banner" },
    [
      _vm.showAlertMsg
        ? _vm._l(_vm.localErrorMessages, function (message, i) {
            return _c(
              "v-banner",
              {
                key: `error-message-${i}`,
                style: {
                  "padding-left": _vm.leftPaddingWidth + "px",
                  color: "#842029",
                },
                attrs: { color: "#f8d7da" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "icon",
                      fn: function () {
                        return [
                          _c("v-icon", { attrs: { color: "#ff0000" } }, [
                            _vm._v("fas fa-triangle-exclamation"),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                    _vm.$vuetify.breakpoint.mdAndUp
                      ? {
                          key: "actions",
                          fn: function () {
                            return [
                              _c(
                                "pb-btn",
                                {
                                  staticClass: "mr-2",
                                  attrs: {
                                    icon: "",
                                    label: _vm.$t("closeLabel"),
                                  },
                                  on: { click: _vm.closeAlertMsg },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { small: "", color: "#842029" } },
                                    [_vm._v("fal fa-times")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        }
                      : null,
                  ],
                  null,
                  true
                ),
              },
              [
                _c("div", {
                  domProps: { innerHTML: _vm._s(message) },
                  on: {
                    click: function ($event) {
                      return _vm.onMsgClick($event)
                    },
                  },
                }),
                _vm.$vuetify.breakpoint.smAndDown
                  ? _c(
                      "pb-btn",
                      {
                        staticClass: "pb-12 pr-2",
                        staticStyle: { position: "absolute", right: "0px" },
                        attrs: { icon: "", label: _vm.$t("closeLabel") },
                        on: { click: _vm.closeAlertMsg },
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "pb-12",
                            attrs: { small: "", color: "#842029" },
                          },
                          [_vm._v("fal fa-times")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          })
        : _vm._e(),
      _vm.showInfoMsg
        ? _vm._l(_vm.localInfoMessages, function (message, i) {
            return _c(
              "v-banner",
              {
                key: `info-message-${i}`,
                style: {
                  "padding-left": _vm.leftPaddingWidth + "px",
                  color: "#055160",
                },
                attrs: { color: "#cff4fc" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "icon",
                      fn: function () {
                        return [
                          _c("v-icon", { attrs: { color: "primary" } }, [
                            _vm._v("fas fa-circle-info"),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                    _vm.$vuetify.breakpoint.mdAndUp
                      ? {
                          key: "actions",
                          fn: function () {
                            return [
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    bottom: "",
                                    "offset-y": "",
                                    "close-on-click": "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on: onMenu }) {
                                          return [
                                            _c(
                                              "pb-btn",
                                              _vm._g(
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    icon: "",
                                                    label: _vm.$t("closeLabel"),
                                                  },
                                                },
                                                { ...onMenu }
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      small: "",
                                                      color: "#055160",
                                                    },
                                                  },
                                                  [_vm._v("fal fa-times")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c(
                                    "v-list",
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.closeInfoMsg({
                                                closeMode: "1",
                                              })
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                text: "",
                                                color: "primary",
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.$t("closeLabel"))
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.closeInfoMsg({
                                                closeMode: "2",
                                              })
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                text: "",
                                                color: "primary",
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "dontShowAgainMessage"
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.closeInfoMsg({
                                                closeMode: "3",
                                              })
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                text: "",
                                                color: "primary",
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "dontShowLikeThisMessage"
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        }
                      : null,
                  ],
                  null,
                  true
                ),
              },
              [
                _c("div", {
                  domProps: { innerHTML: _vm._s(message) },
                  on: {
                    click: function ($event) {
                      return _vm.onMsgClick($event)
                    },
                  },
                }),
                _vm.$vuetify.breakpoint.smAndDown
                  ? _c(
                      "v-menu",
                      {
                        attrs: {
                          bottom: "",
                          "offset-y": "",
                          "nudge-top": "48",
                          "close-on-click": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on: onMenu }) {
                                return [
                                  _c(
                                    "pb-btn",
                                    _vm._g(
                                      {
                                        staticClass: "pb-12 pr-2",
                                        staticStyle: {
                                          position: "absolute",
                                          right: "0px",
                                        },
                                        attrs: {
                                          icon: "",
                                          label: _vm.$t("closeLabel"),
                                        },
                                      },
                                      { ...onMenu }
                                    ),
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "pb-4",
                                          attrs: {
                                            small: "",
                                            color: "#055160",
                                          },
                                        },
                                        [_vm._v("fal fa-times")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v("> "),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _c(
                          "v-list",
                          [
                            _c(
                              "v-list-item",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.closeInfoMsg({ closeMode: "1" })
                                  },
                                },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { text: "", color: "primary" },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("closeLabel"))),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-list-item",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.closeInfoMsg({ closeMode: "2" })
                                  },
                                },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { text: "", color: "primary" },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$t("dontShowAgainMessage"))
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-list-item",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.closeInfoMsg({ closeMode: "3" })
                                  },
                                },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { text: "", color: "primary" },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("dontShowLikeThisMessage")
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          })
        : _vm._e(),
      _c("pb-confirm", {
        ref: "confirm",
        attrs: {
          contentClass: "banner-confirm",
          persistent: false,
          closeButtonOnHeader: "",
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }