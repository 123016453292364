import CommonUtils from '@/utils/common-utils';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ld from 'lodash';

const settings = namespace('settings');
const classes = namespace('classes');
const referencedata = namespace('referencedata');
const plans = namespace('plans');

@Component
export default class SchoolYearMixin extends Vue {
  @settings.Mutation
  setUpdatedSettings!: (params?: any) => void;

  @settings.Getter('getSchoolId')
  schoolId!: number;

  @settings.Getter('getDistrictId')
  districtId!: number;

  @settings.Getter('getUserMode')
  userMode!: string;

  @settings.Action
  loadAdminSchoolYears!: () => Promise<any>;

  @settings.Action
  getAdminSchoolYear!: (yearId: number) => Promise<any>;

  @classes.Action
  loadSchoolYearClasses!: (schoolYear?: number) => Promise<any>;

  @referencedata.Action
  loadHolidays!: () => Promise<any>;

  @plans.Mutation
  resetPlans!: () => void;

  @classes.Mutation
  setHaveClass!: (value: boolean) => void;

  @settings.Action
  deleteSchoolYears!: (params: any) => Promise<any>;

  @settings.Action
  reloadSettings!: (params?: any) => Promise<any>;

  @classes.Action
  loadClasses!: (params?: any) => Promise<any>;

  @plans.Action
  changePlans!: (param?: any) => Promise<any>;

  @referencedata.State
  holidays!: Array<any>;

  get updatedSettings(): any {
    return this.$store.state.settings.updatedSettings;
  }

  set updatedSettings(value: any) {
    this.setUpdatedSettings(value);
  }

  editSchoolYear(modal: boolean, item: any): void {
    if (CommonUtils.hasText(this.$store.state.index.openSubpage) && !this.$store.state.index.isModal) {
      const subpageReference = this.$store.state.index.subpageReference;
      if (subpageReference && subpageReference.isDirty && subpageReference.isDirty()) {
        const confirm = this.$store.state.index.confirmFunction;
        return confirm({
          title: this.$t('unsavedChangesLabel'),
          text: this.$t('confirmAbandonUnsavedChangesGenericMsg'),
          option1ButtonAlternativeText: this.$t('saveChangesLabel'),
          option2ButtonAlternativeText: this.$t('discardChangesLabel'),
          cancelButtonText: this.$t('cancelLabel')
        }).then((result: any) => {
          if (result === 1) {
            this.$store.state.index.subpageReference.doApply().then((applyResult: any) => {
              if (!applyResult) {
                this.openEdit(modal, item);
              }
            });
          } else if (result === 2) {
            this.$eventBus.$emit('closeSubPage')
            this.openEdit(modal, item);
          }
        })
      }
    }
    return this.openEdit(modal, item);
  }

  openEdit(modal: boolean, item: any) {
    CommonUtils.showLoading();
    Promise.resolve().then(() => {
      if (this.userMode === 'T' && (this.schoolId > 0 || this.districtId > 0) && !item) {
        return this.loadAdminSchoolYears();
      } else if (this.userMode === 'A' && item && item.yearId > 0) {
        return this.getAdminSchoolYear(item.yearId).then(data => {
          item = data;
          return Promise.resolve();
        });
      }
    }).then(() => {
      if (!item || item.yearId === 0) {
        return this.loadHolidays();
      } else {
        return Promise.resolve();
      }
    }).then(() => {
      return this.loadSchoolYearClasses((item || { yearId: 0 }).yearId);
    }).then(() => {
      this.$eventBus.$emit('openSubPage', {
        type: 'schoolyear',
        modal,
        width: 700,
        input: {
          data: item
        }
      });
    });
  }

  getYearNames(items: any) {
    const years = ld.cloneDeep(items);
    let yearNames = '';
    do {
      if (yearNames === '' && years.length > 1) {
        yearNames = ' and ' + years.pop().yearName;
      } else {
        if (years.length > 1) {
          yearNames = ', ' + years.pop().yearName + yearNames;
        } else {
          yearNames = years.pop().yearName + yearNames;
        }
      }
    } while (years.length > 0);
    return yearNames;
  }
}
