
import CommonUtils from '@/utils/common-utils';
import { Component, Prop, Vue } from 'vue-property-decorator';

const tinycolor = require('tinycolor2');

@Component
export default class MultiSelectField extends Vue {
    @Prop({ type: String, required: false, default: '' })
    label!: string;

    @Prop({ type: String, required: false, default: '' })
    ariaLabel!: string;

    @Prop({ type: String, required: false, default: '' })
    prefix!: string

    @Prop({ type: Array, required: false, default: () => { return [] } })
    rules!: Array<any>

    @Prop({ type: String, required: false, default: '' })
    suffix!: string

    @Prop({ type: Array, required: false, default: () => { return [] } })
    value!: Array<any>;

    @Prop({ type: Array, required: false, default: () => { return [] } })
    items!: Array<any>;

    @Prop({ type: [String, Boolean], required: false, default: false })
    hideDetails!: any;

    @Prop({ type: Boolean, required: false, default: false })
    dense!: boolean;

    @Prop({ type: Boolean, required: false, default: false })
    disabled!: boolean;

    @Prop({ type: String, required: false, default: 'list' })
    mode!: string

    showList = false;

    get localTextValue() {
      let text = '';
      if (CommonUtils.hasText(this.prefix)) {
        text += this.prefix + ' ';
      }
      if (this.value.length === 0) {
        text += 'No';
      } else if (this.value.length === 1) {
        text += this.singleItemText;
      } else if (this.items.length === this.value.length) {
        text += 'All';
      } else {
        text += this.value.length;
      }
      if (this.value.length !== 1 && CommonUtils.hasText(this.suffix)) {
        text += ' ' + this.suffix;
      }
      return text;
    }

    focusFirst() {
      if (this.showList && this.mode !== 'list') {
        (this.$refs as any).chip0[0].$el.focus();
      }
    }

    onItemClick(item: any) {
      if (!this.localValue.includes(item.value)) {
        if (item.value === 'all') {
          this.localValue = this.items.map(i => i.value);
        } else {
          this.localValue = this.localValue.concat(item.value);
        }
      } else {
        if (item.value === 'all') {
          this.localValue = [];
        } else {
          this.localValue = this.localValue.filter(i => i !== item.value);
        }
      }
    }

    get localItems() {
      let items: Array<any> = [{
        value: 'all',
        text: this.$t('allItemsLabel')
      }];
      items = items.concat(this.items);
      items.forEach(item => {
        item.selected = this.localValue.includes(item.value);
      });
      return items;
    }

    get singleItemText() {
      if (this.localValue.length === 1) {
        return this.items.find(i => i.value === this.localValue[0]).text;
      }
      return '';
    }

    get hasText() {
      return CommonUtils.hasText;
    }

    getItemTextColor(itemColor: any) {
      if (CommonUtils.hasText(itemColor)) {
        const c = tinycolor(itemColor);
        if (c.getBrightness() < 220) {
          return '#FFF';
        } else {
          return '#000';
        }
      }
    }

    get localValue() {
      const selected = [];
      const value = this.value || [];
      if (value.length === this.items.length) {
        selected.push('all');
      }
      return selected.concat(value || []);
    }

    set localValue(val: Array<any>) {
      this.$emit('input', val.filter(i => i !== 'all'));
    }
}
