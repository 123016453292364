import qs from 'qs';
import axios from '@/axios';
import CurrentUser from '@/common/current-user';

export default class ClassServices {
  public static getClasses(params?: any): Promise<any> {
    params = params || {};
    return axios.post('getClasses2', qs.stringify(params));
  }

  public static getStudentClasses(params?: any): Promise<any> {
    params = params || {};
    return axios.post('services/common/student/getTeacherSubjects', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      withCredentials: !(CurrentUser.isStudent && CurrentUser.id === 0)
    });
  }

  public static getTeacherClasses(params?: any): Promise<any> {
    params = params || {};
    return axios.post('services/api/getTeacherSubjects', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      withCredentials: false
    });
  }

  public static getClass(params?: any): Promise<any> {
    params = params || {};
    return axios.post('getClass', qs.stringify(params));
  }

  public static addClass(params?: any): Promise<any> {
    params = params || {};
    return axios.post('addClass', qs.stringify(params));
  }

  public static updateClass(params?: any): Promise<any> {
    params = params || {};
    return axios.post('updateClass/v10', qs.stringify(params, { arrayFormat: 'repeat' }));
  }

  public static deleteClass(params?: any): Promise<any> {
    params = params || {};
    return axios.post('deleteClass', qs.stringify(params));
  }

  public static getSharedClasses(params?: any): Promise<any> {
    params = params || {};
    return axios.post('getSharedClasses', qs.stringify(params));
  }

  public static getSpecialDays(params?: any): Promise<any> {
    params = params || {};
    return axios.post('getSpecialDays', qs.stringify(params));
  }

  public static updateSpecialDay(params?: any): Promise<any> {
    params = params || {};
    return axios.post('updateSpecialDay', qs.stringify(params, { arrayFormat: 'repeat' }));
  }
}
