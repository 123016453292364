


































































import { UserSession } from '@/common/user-session';
import RemindersServices from '@/services/reminders-services';
import CommonUtils from '@/utils/common-utils';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const settings = namespace('settings');
const reminders = namespace('reminders');

@Component
export default class SubstituteNotesEditor extends Vue {
  @Prop({ type: Boolean })
  value!: boolean;

  @settings.Getter('getSubPlans')
  subPlans!: string;

  @reminders.Action
  updateSubPlans!: (params: any) => Promise<any>;

  @reminders.Action
  emailSubNotes!: (params: any) => Promise<any>;

  localSubstituteNotes = '';
  localShowActions = false;

  get localValue() {
    return this.value;
  }

  set localValue(val: boolean) {
    this.$emit('input', val);
  }

  get substituteNotes() {
    return this.localSubstituteNotes || this.subPlans;
  }

  set substituteNotes(val: string) {
    this.localSubstituteNotes = val;
  }

  applySubstituteNotes() {
    CommonUtils.showLoading();
    this.updateSubPlans({ subPlans: this.localSubstituteNotes }).then(() => {
      this.localSubstituteNotes = '';
      this.localValue = false;
    }).finally(() => {
      CommonUtils.hideLoading();
    });
  }

  deleteSubstituteNotes() {
    CommonUtils.showLoading();
    this.updateSubPlans({ subPlans: '' }).then(() => {
      this.localSubstituteNotes = '';
      this.localValue = false;
    }).finally(() => {
      CommonUtils.hideLoading();
    });
  }

  emailSubstituteNotes() {
    CommonUtils.showLoading();
    this.emailSubNotes({ subNotes: this.subPlans }).finally(() => {
      CommonUtils.hideLoading();
    });
  }

  printSubstituteNotes() {
    CommonUtils.showLoading();
    RemindersServices.createPDFNotes([
      { name: 'printNotesType', value: 'sub' },
      { name: 'printTeacherNotesText', value: this.subPlans },
      { name: 'X-PB-ACCESS-TOKEN', value: UserSession.getAccessToken() },
      { name: 'X-PB-CLIENT-YEAR-ID', value: UserSession.getCurrentYearId() }
    ]).finally(() => {
      CommonUtils.hideLoading();
    });
  }
}
