declare const MathJax: any;
const debug = process.env.NODE_ENV !== 'production';

export default class WebUtils {
  public static initMathJax() {
    console.log('Parsing and rendering MathJax expressions.');
    MathJax.Hub.Queue(['Typeset', MathJax.Hub, 'preview']);
  }

  public static getBaseUrl() {
    const hostname: string = window.location.hostname;
    if (debug) {
      if (hostname.endsWith('debug.planbook.com') || hostname.endsWith('debug-web.planbook.com')) {
        return 'https://debug-api.planbook.com';
      } else if (hostname.endsWith('planbook.com')) {
        return 'https://api.planbook.com';
      } else {
        return 'http://localhost:8080';
      }
    } else {
      if (hostname === 'staging-app.planbook.com') {
        return 'https://staging-api.planbook.com';
      } else if (hostname === 'test-app.planbook.com') {
        return 'https://test-api.planbook.com';
      } else if (hostname.endsWith('planbookbeta.com')) {
        return 'https://api.planbookbeta.com';
      } else {
        return 'https://api.planbook.com';
      }
    }
  }

  public static getAuthBaseUrl() {
    const hostname: string = window.location.hostname;
    if (hostname === 'staging-app.planbook.com') {
      return 'https://staging-auth.planbook.com';
    } else if (hostname === 'test-app.planbook.com') {
      return 'https://test-auth.planbook.com';
    } else if (hostname.endsWith('debug.planbook.com') || hostname.endsWith('debug-web.planbook.com')) {
      return 'https://debug-auth.planbook.com';
    } else if (hostname.endsWith('planbook.com')) {
      return 'https://auth.planbook.com';
    } else if (hostname.endsWith('planbookbeta.com')) {
      return 'https://auth.planbookbeta.com';
    } else {
      return 'http://localhost:9000';
    }
  }

  public static getJoinBaseUrl() {
    const hostname: string = window.location.hostname;
    if (hostname === 'staging-app.planbook.com') {
      return 'https://staging-join.planbook.com';
    } else if (hostname === 'test-app.planbook.com') {
      return 'https://test-join.planbook.com';
    } else if (hostname.endsWith('planbook.com')) {
      return 'https://join.planbook.com';
    } else if (hostname.endsWith('planbookbeta.com')) {
      return 'https://test-join.planbook.com';
    } else {
      return 'http://localhost:8091';
    }
  }
}
