import axios from '@/axios';
import queryString from 'query-string';

export default class DayServices {
  public static substituteDay(params?: any): Promise<any> {
    params = params || {};
    return axios.post('services/planbook/day/substitute', queryString.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }
}
