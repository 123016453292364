import CommonUtils from '@/utils/common-utils';
import axios from '@/axios';
import qs from 'qs';

export default class GoogleAppServices {
  public static async connect(code?: string): Promise<any> {
    const params: any = {};
    if (CommonUtils.hasText(code)) {
      params.code = code;
    }
    return axios.post('connectServlet', qs.stringify(params));
  }

  public static async disconnect(): Promise<any> {
    return axios.post('googleDisconnect');
  }

  public static async getCalendars(): Promise<any> {
    return axios.get('getCalendarsServlet');
  }

  public static async getGoogleClasses() {
    return axios.get('services/planbook/googleclassroom');
  }
}
