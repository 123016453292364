import AppError, { createErrOptions, SessionExpiredError } from '@/errors';
import StickersServices from '@/services/stickers-services';
import Vue from 'vue';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true })
export default class Stickers extends VuexModule {
    availableStickerData: any = null;
    haveStickers = false;
    selectedStickers:any[] = [];
    stickerDescription = '';
    updatedFields: Set<string> = new Set<string>();

    // GETTER: START
    get getAllStickers() {
      const allStickers = this.availableStickerData || { stickers: [] };
      if (!allStickers.stickers) allStickers.stickers = [];
      return allStickers;
    }

    get getSelectedStickers() {
      return this.selectedStickers;
    }

    get getHaveStickers() {
      return this.haveStickers;
    }

    get getStickerLabel() {
      return (key: string) => {
        const stickers = this.getAllStickers.stickers || [];
        for (const i in stickers) {
          if (stickers[i].value === key) {
            return stickers[i].shortValue;
          }
        }
        return '';
      }
    }

    // GETTERS: END

    // MUTATIONS: START

    @Mutation
    updateSelectedStickers(v: any) {
      Vue.set(this.selectedStickers, v.index, v.newValue);
    }

    @Mutation
    setHaveStickers(v: boolean) {
      this.haveStickers = v;
    }

    @Mutation
    setSelectedStickers(sticker: Array<any>) {
      this.selectedStickers = sticker;
    }

    @Mutation
    setStickerDescription(sticker: any) {
      this.stickerDescription = sticker;
    }

    @Mutation
    setUpdatedFields(updatedFields: Set<any>) {
      this.updatedFields = updatedFields;
    }

    @Mutation
    setAvailableStickerData(currentStickers: any) {
      this.availableStickerData = currentStickers;
    }

    // MUTATIONS: END

    // ACTIONS: START

    @Action({ rawError: true })
    async refreshFields() {
      this.context.commit('setStickerDescription', '');
      this.context.commit('setUpdatedFields', new Set<string>());
      this.context.commit('setSelectedStickers', []);
    }

    @Action({ rawError: true })
    async init() {
      console.log('STICKERS: init');
      await this.context.dispatch('loadStickers');
      return Promise.resolve();
    }

    @Action({ rawError: true })
    async loadStickers() {
      try {
        if (this.haveStickers) {
          return Promise.resolve({ data: {} });
        }
        const resp = await StickersServices.getAllStickers();

        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            this.context.commit('setAvailableStickerData', data);
            this.context.commit('setHaveStickers', true);
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async addOrEditSticker(params?: any) {
      const request: any = {
        sticker: params.sticker,
        date: params.date,
        description: params.description
      }
      try {
        const context = this.context;
        const resp = await StickersServices.addSticker(request);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            await context.dispatch('plans/loadLessonsEvents', {}, { root: true });
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('Error', { data: { error: 'true', message: e } }));
      }
    }

    @Action({ rawError: true })
    async editSticker(params?: any) {
      try {
        const context = this.context;
        const resp = await StickersServices.editSticker(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('Error', { data }));
          } else {
            await context.dispatch('plans/loadLessonsEvents', {}, { root: true });
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async deleteSticker(param?: any) {
      const request: any = {
        sticker: param.sticker,
        date: param.date
      };

      try {
        const context = this.context;
        const resp = await StickersServices.removeSticker(request);

        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            await context.dispatch('plans/loadLessonsEvents', {}, { root: true });
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

  // ACTIONS: END
}
