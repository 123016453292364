




















































import FileServices from '@/services/file-services';
import CommonUtils from '@/utils/common-utils';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import MultiListPicker from './MultiListPicker.vue';
import FolderPicker from '../resources/FolderPicker.vue';
import ld from 'lodash';
const settings = namespace('settings');
const files = namespace('files');

@Component({
  components: {
    MultiListPicker,
    FolderPicker
  }
})
export default class MyFilesPicker extends Vue {
  @Prop({ type: Boolean })
  value!: boolean;

  @Prop({ type: Array, required: true, default: () => [] })
  attachments!: Array<any>;

  @settings.State
  userMode!: string;

  @files.State('files')
  myFilesData!: any;

  @files.State('allTeacherFiles')
  myAllFilesData!: any;

  @files.State('haveAllTeacherFiles')
  haveAllTeacherFiles!: boolean;

  @files.Action
  getAllTeacherFiles!: (params?: any) => Promise<any>;

  @files.Action
  loadMyFiles!: (params?: any) => Promise<any>;

  @files.Mutation('setHaveAllTeacherFiles')
  setHaveAllTeacherFiles!: any;

  @files.Mutation('setFiles')
  setMyFiles!: any;

  @files.Mutation('setHaveFiles')
  setHaveMyFiles!: any;

  @settings.Getter('getCurrentYear')
  currentSchoolYear!: any;

  @settings.Getter('getUserType')
  userType!: string;

  $refs!: {
    fileInput: any;
  };

  filter = ''
  listViewLoaded = false;
  selected: Array<any> = [];
  localCachedFiles: any = {};
  localCachedGoogleFiles: Array<any> = [];
  files:any[] = [];
  errorUploadFile = false;
  filesUploadingArray: any[] = [];
  uploadToFolder:any = [];
  uploadMenu = false;
  uploadToOpenedFolder:any = [];
  uploadToIsAddingFolder = false;
  uploadToNewFolderName = '';
  isUploadToTableLoading = false;

  get currentSchoolYearName() {
    return this.currentSchoolYear.yearName;
  }

  get localValue() {
    return this.value;
  }

  set localValue(val: boolean) {
    this.$emit('input', val);
  }

  get localMyFilesData() {
    return this.myFilesData || {};
  }

  get localMyFiles() {
    return this.localMyFilesData.fileList || [];
  }

  get localMyFilesMap() {
    const map: any = {};
    this.localMyFiles.forEach((f: any) => {
      map[f.fileKey] = f;
    });
    return map;
  }

  get localMyFilesItems() {
    return this.localMyFiles.map((s: any) => {
      return {
        key: s.fileKey,
        shortValue: '',
        value: s.fileKey,
        data: s
      }
    });
  }

  get selectedCount() {
    return this.selected.length;
  }

  get uploadToCurrentOpenedFolder() {
    return this.uploadToOpenedFolder.slice(-1)[0];
  }

  get allFoldersAvailable() {
    if (CommonUtils.isEmpty(this.myAllFilesData.fileList)) return [];
    const allFiles = this.myAllFilesData.fileList;
    const folders:any = [];
    for (const file of allFiles) {
      const hasFolder = file.fileKey.includes('/');
      if (hasFolder) {
        const splittedKey = file.fileKey.split('/');
        splittedKey.forEach((key:string, index:number) => {
          if (index !== splittedKey.length - 1) {
            const value:any = [];
            splittedKey.every((element:any) => {
              if (key === element) {
                value.push(element);
                return false;
              }
              value.push(element);
              return true;
            });
            const data = {
              value: value.join('/') + '/',
              text: key
            };
            const found = folders.some((e:any) => e.text === key);
            if (!found) {
              folders.push(data);
            }
          }
        });
      }
    }
    return folders;
  }

  get filteredAllAvailableFolders() {
    const that = this;
    const folders:any = [];
    if (CommonUtils.isEmpty(this.uploadToOpenedFolder)) {
      this.allFoldersAvailable.forEach((element:any) => {
        if (!element.value) return;
        const splitted = element.value.split('/');
        const found = folders.some((e:any) => e.value === splitted[0] + '/');
        if (!found) {
          folders.push({
            text: element.text,
            value: splitted[0] + '/'
          });
        }
      });
    } else {
      this.allFoldersAvailable.forEach((element:any) => {
        if (!element.value) return;
        const splitted = element.value.split('/');
        const found = folders.some((e:any) => e.text === element.text);
        if (!found) {
          // first check if current element is inside current opened folder
          // second check if current element will not be a duplicate of current opened folder
          // third check if current element is not a child of current folder's child (to make sure to only display current folders direct child)
          if (splitted[that.uploadToOpenedFolder.length - 1] === that.uploadToCurrentOpenedFolder.text &&
          element.value !== that.uploadToCurrentOpenedFolder.value &&
          splitted.length === that.uploadToOpenedFolder.length + 2) {
            folders.push({
              text: element.text,
              value: element.value
            });
          }
        }
      });
    }
    return folders;
  }

  appendFiles(files: any) {
    this.files = this.files.concat(files);
  }

  saveFiles() {
    if (this.files.length > 0) {
      CommonUtils.showLoading();
      this.files.forEach((element: any) => {
        this.filesUploadingArray.push({
          fileName: element.name,
          percentage: 0
        });
      });
      return this.uploadFiles();
    } else {
      this.errorUploadFile = true;
    }
  }

  fileDataToSave(f: any) {
    return {
      name: f.fileKey,
      filename: f.fileKey,
      fileName: f.fileKey,
      url: f.fileUrl,
      privateFlag: false
    }
  }

  @Watch('selected')
  onSelectedChange(val: Array<any>) {
    const that = this;
    val.forEach(f => {
      if (!that.localCachedFiles[f] && that.localMyFilesMap[f]) {
        that.localCachedFiles[f] = that.fileDataToSave(that.localMyFilesMap[f]);
      }
    })
  }

  @Watch('localValue')
  onValueChange() {
    if (this.value) {
      this.attachments.forEach((f: any) => {
        const key = f.filename || f.name || f.fileName;
        if (CommonUtils.hasText(f.url)) {
          if (f.url.includes('//docs.google.com')) {
            this.localCachedGoogleFiles.push(f);
          } else {
            this.localCachedFiles[key] = f;
          }
        } else {
          this.localCachedFiles[key] = f;
        }
      });
      this.selected = this.attachments.map((s: any) => s.filename || s.name || s.fileName);
      this.initialize();
    } else {
      this.selected = [];
      this.localCachedGoogleFiles = [];
      this.localCachedFiles = [];
    }
  }

  @Watch('uploadMenu')
  onUploadMenuChanged(val:any) {
    if (!val) {
      this.$nextTick(() => {
        this.uploadToFolder = [];
        this.uploadToOpenedFolder = [];
        this.uploadToIsAddingFolder = false;
        this.uploadToNewFolderName = '';
        this.isUploadToTableLoading = false;
      });
    }
  }

  unselectAll() {
    this.selected = [];
  }

  refresh() {
    this.setMyFiles({});
    this.setHaveMyFiles(false);
    return this.initialize();
  }

  async initialize() {
    const that = this;
    this.listViewLoaded = false;
    return this.loadMyFiles().then(() => {
      that.setHaveMyFiles(true);
      that.listViewLoaded = true;
      return Promise.resolve();
    });
  }

  doApply(selected: any) {
    let newAttachments:any[] = [];
    if (selected.uploadedAttachments) {
      newAttachments = selected.uploadedAttachments;
      newAttachments.concat(this.localCachedGoogleFiles);
    } else {
      newAttachments = selected.map((f: any) => this.localCachedFiles[f]);
      newAttachments.concat(this.localCachedGoogleFiles);
    }
    this.$emit('update:attachments', newAttachments.filter(a => a !== undefined));
    this.localValue = false;
    return Promise.resolve();
  }

  async uploadFiles() {
    const that = this;
    return Promise.resolve().then(() => {
      if (CommonUtils.isEmpty(this.uploadToFolder)) {
        return FileServices.uploadFiles(this.files,
          (file, percent) => {
            this.filesUploadingArray.forEach((element: any) => {
              if (element.fileName === file.name) {
                element.percentage = percent;
              }
            });
          });
      } else {
        let uploadToFolder;
        if (this.uploadToFolder[0].value.endsWith('/')) {
          uploadToFolder = ld.cloneDeep(this.uploadToFolder[0].value);
          uploadToFolder = uploadToFolder.slice(0, -1);
        }
        return FileServices.uploadFilesNotRoot(this.files,
          uploadToFolder,
          (file, percent) => {
            this.filesUploadingArray.forEach((element: any) => {
              if (element.fileName === file.name) {
                element.percentage = percent;
              }
            });
          });
      }
    }).then(() => {
      setTimeout(() => {
        that.setHaveMyFiles(false);
        return this.initialize()
          .then(() => {
            const uploadedAttachments = [];
            for (const f of that.myFilesData.fileList) {
              if (that.files.some(e => {
                if (CommonUtils.isNotEmpty(that.uploadToFolder)) {
                  return that.uploadToFolder[0].value + e.name === f.fileKey;
                }
                return e.name === f.fileKey;
              })) {
                uploadedAttachments.push({
                  name: f.fileKey,
                  filename: f.fileKey,
                  fileName: f.fileKey,
                  url: f.fileUrl,
                  privateFlag: false
                })
              }
            }
            return this.doApply({ uploadedAttachments });
          })
          .then(() => {
            this.files = [];
            CommonUtils.hideLoading();
          });
      }, 1000);
    })
  }

  created() {
    this.setHaveMyFiles(false);
  }

  mounted() {
    this.$nextTick(() => {
      if (!this.haveAllTeacherFiles && !this.$currentUser.isStudent) {
        this.getAllTeacherFiles({ isFolderStructured: false, withAllFolders: true }).then(() => {
          this.setHaveAllTeacherFiles(true);
        });
      }
    })
  }

  uploadFileOpen() {
    this.$refs.fileInput.$refs.input.click();
  }

  openMoveToFolder(item:any) {
    this.uploadToOpenedFolder.push(item);
    this.uploadToFolder = [];
  }

  uploadToCreateNewFolder() {
    if (!this.uploadToNewFolderName) return;
    CommonUtils.showLoading();
    this.setHaveAllTeacherFiles(false);
    this.isUploadToTableLoading = true;
    if (CommonUtils.isEmpty(this.uploadToOpenedFolder)) {
      FileServices.createFolder(this.uploadToNewFolderName).then(() => {
        this.getAllTeacherFiles({ isFolderStructured: false, withAllFolders: true }).then(() => {
          this.setHaveAllTeacherFiles(true);
          this.isUploadToTableLoading = false;
          CommonUtils.hideLoading();
        });
      });
    } else {
      FileServices.createFolder(
        this.uploadToOpenedFolder.map((element:any) => {
          return element.text;
        }).join('/') + '/' + this.uploadToNewFolderName
      ).then(() => {
        this.getAllTeacherFiles({ isFolderStructured: false, withAllFolders: true }).then(() => {
          this.setHaveAllTeacherFiles(true);
          this.isUploadToTableLoading = false;
          CommonUtils.hideLoading();
        });
      });
    }
    this.uploadToIsAddingFolder = false;
    this.uploadToNewFolderName = '';
  }

  public async doUpload() {
    this.$refs.fileInput.$refs.input.click();
  }
}
