import Vue from 'vue'
import VueI18n from 'vue-i18n'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import en from 'vuetify/lib/locale/en'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import fr from 'vuetify/lib/locale/fr'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import es from 'vuetify/lib/locale/es'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import it from 'vuetify/lib/locale/it'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import de from 'vuetify/lib/locale/de'

Vue.use(VueI18n)

export const languages:any = {
  en: {
    ...require('@/locales/en.json'),
    $vuetify: en,
    label: 'English'
  },
  fr: {
    ...require('@/locales/fr.json'),
    $vuetify: fr,
    label: 'French'
  },
  es: {
    ...require('@/locales/es.json'),
    $vuetify: es,
    label: 'Spanish'
  },
  it: {
    ...require('@/locales/it.json'),
    $vuetify: it,
    label: 'Italian'
  },
  de: {
    ...require('@/locales/de.json'),
    $vuetify: de,
    label: 'German'
  }
}

export function getLocaleFromURL() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  return params.get('locale');
}

const i18n = new VueI18n({
  locale: getLocaleFromURL() || process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: languages
});

export default i18n;
