var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-menu",
    {
      staticStyle: { "z-index": "9999999" },
      attrs: {
        bottom: "",
        left: "",
        "close-on-click": true,
        "close-on-content-click": false,
        absolute: _vm.type == "absolute",
        "position-x": _vm.x,
        "position-y": _vm.y,
      },
      scopedSlots: _vm._u(
        [
          _vm.type == "button"
            ? {
                key: "activator",
                fn: function ({ on: onMenu }) {
                  return [
                    _c(
                      "pb-btn",
                      _vm._g(
                        {
                          attrs: {
                            label: _vm.$t("actionsLabel"),
                            color: _vm.color,
                            icon: "",
                            right: "",
                          },
                          on: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "escape",
                                  undefined,
                                  $event.key,
                                  undefined
                                )
                              )
                                return null
                              $event.stopPropagation()
                              $event.preventDefault()
                            },
                            focus: function ($event) {
                              return _vm.emit("focus")
                            },
                            blur: function ($event) {
                              return _vm.emit("blur")
                            },
                          },
                        },
                        { ...onMenu }
                      ),
                      [_c("v-icon", [_vm._v("fal fa-ellipsis-v-alt")])],
                      1
                    ),
                  ]
                },
              }
            : null,
        ],
        null,
        true
      ),
      model: {
        value: _vm.showMenu,
        callback: function ($$v) {
          _vm.showMenu = $$v
        },
        expression: "showMenu",
      },
    },
    [
      _c(
        "div",
        { staticClass: "editor-actions" },
        [
          _c(
            "v-list",
            { attrs: { dense: "" } },
            _vm._l(_vm.filteredActions, function (item, i) {
              return _c(
                "v-list-item",
                {
                  key: i,
                  attrs: { link: "" },
                  on: {
                    click: function ($event) {
                      return _vm.doAction(item)
                    },
                  },
                },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c("v-icon", {
                        domProps: { textContent: _vm._s(item.icon) },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", {
                        staticStyle: { width: "150px" },
                        domProps: { textContent: _vm._s(_vm.$t(item.label)) },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c("pb-confirm", { ref: "confirm" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }