import qs from 'qs';
import axios from '@/axios';

export default class GradeServices {
  public static updateLetterGrades(params?: any): Promise<any> {
    params = params || {};
    return axios.post('updateLetterGradesServlet', qs.stringify(params, { arrayFormat: 'repeat' }));
  }

  public static getLetterGrades(params: any): Promise<any> {
    return axios.get('services/planbook/lettergrade', { params: { yearId: params.yearId } });
  }

  public static updateScoreWeights(params?: any): Promise<any> {
    params = params || {};
    return axios.post('updateScoreWeightsServlet', params);
  }

  public static addCustomScoreWeight(params?: any): Promise<any> {
    params = params || {};
    return axios.post('services/planbook/customscoreweight', qs.stringify(params));
  }

  public static getPerformanceInfo(params?: any): Promise<any> {
    params = params || {};
    return axios.post('getCommentsTo', qs.stringify(params));
  }

  public static getPerformanceInfoAdmin(params?: any): Promise<any> {
    params = params || {};
    return axios.post('services/planbook/school/studentsForPerformanceReport', qs.stringify(params));
  }

  public static getGradePeriods(params: any): Promise<any> {
    return axios.get('services/planbook/gradeperiod', { params: { yearId: params.yearId } });
  }

  public static setGradePeriods(params: any): Promise<any> {
    return axios.post('services/planbook/gradeperiod/setGradePeriods', qs.stringify(params, { arrayFormat: 'repeat' }));
  }

  public static updateStudentScore(params?: any): Promise<any> {
    return axios.post('updateStudentScoreServlet', qs.stringify(params));
  }

  public static getScoresForStudent(params?: any): Promise<any> {
    return axios.post('services/planbook/student/scores', qs.stringify(params));
  }

  public static addNote(params?: any): Promise<any> {
    return axios.post('services/planbook/note/add', qs.stringify(params));
  }

  public static overrideScore(params?: any): Promise<any> {
    return axios.post('overrideScoreServlet', qs.stringify(params));
  }

  public static updateStandardScore(params?: any): Promise<any> {
    return axios.post('updateStandardScoreServlet', qs.stringify(params));
  }
}
