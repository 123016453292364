
import SchoolYearMixin from '@/mixins/school-year-mixin';
import CommonUtils from '@/utils/common-utils';
import { Component, Vue } from 'vue-property-decorator';
import { namespace, State } from 'vuex-class';
import { addItems } from '../../constants';
import SubstituteNotesEditor from '@/components/reminders/SubstituteNotesEditor.vue';
import StudentNotesEditor from '@/components/reminders/StudentNotesEditor.vue';
import moment from 'moment';

const settings = namespace('settings');
const lessons = namespace('lessons');

@Component({
  mixins: [SchoolYearMixin],
  components: {
    SubstituteNotesEditor,
    StudentNotesEditor
  }
})
export default class AddMenu extends Vue {
  @settings.Getter('getDisplayName')
  displayName!: any;

  @lessons.Getter
  createNewLesson!: () => any;

  @lessons.Action('init')
  initLesson!: (params?: any) => Promise<any>;

  @settings.Getter('getEmailAddress')
  emailAddress!: any;

  @State('drawerRight')
  drawerRight!: boolean;

  @settings.Getter('getUserInfo')
  userInfo!: any;

  localShowSubstituteNotes = false;
  localShowAnnouncements = false;

  editSchoolYear!: (modal: boolean, item: any) => void;

  log(a: any) {
    console.log(a);
  }

  get addItems(): Array<any> {
    return addItems;
  }

  get localDisplayName() {
    return this.displayName;
  }

  get localEmailAddress() {
    return this.emailAddress;
  }

  get editLink() {
    return this.$store.state.lessons.editLink;
  }

  set editLink(value: any) {
    this.$store.commit('lessons/setEditLink', value);
  }

  get isCycleSchedule() {
    return !isNaN(+this.userInfo.schoolYearSettings.classCycle);
  }

  focusOnOpen() {
    this.$nextTick(() => {
      new Promise(resolve => setTimeout(resolve, 100)).then(() => {
        try {
          (this.$refs as any).addItem0[0].$el.focus();
        } catch (e) {
          console.log(this.$t('focusFailed'));
        }
      });
    });
  }

  focusItem(index: any) {
    this.$nextTick(() => {
      new Promise(resolve => setTimeout(resolve, 100)).then(() => {
        try {
          (this.$refs as any)['addItem' + index][0].$el.focus();
        } catch (e) {
          console.log(this.$t('focusFailed'));
        }
      });
    });
  }

  closeMenu() {
    this.$emit('close');
  }

  isAction(item: any) {
    return CommonUtils.hasText(item.action);
  }

  doAction(item: any) {
    this.localShowSubstituteNotes = false;
    this.localShowAnnouncements = false;
    if (item.action === 'assessment') {
      this.$eventBus.$emit('openSubPage', {
        type: 'classwork',
        modal: true,
        input: {
          action: 'A',
          loadData: true
        }
      });
    } else if (item.action === 'class') {
      this.$eventBus.$emit('openSubPage', {
        type: 'class',
        modal: true,
        input: {
          action: 'A'
        }
      });
    } else if (item.action === 'event') {
      this.$eventBus.$emit('openSubPage', {
        type: 'event',
        modal: true,
        input: {
          loadData: true,
          data: null
        }
      });
    } else if (item.action === 'noSchool') {
      this.$eventBus.$emit('openSubPage', {
        type: 'event',
        modal: true,
        input: {
          loadData: true,
          data: {
            noSchool: true,
            noCycle: this.isCycleSchedule,
            eventDate: moment().format('MM/DD/YYYY'),
            endDate: moment().format('MM/DD/YYYY'),
            eventId: 0
          }
        }
      });
    } else if (item.action === 'extraLesson') {
      this.editLink = false;
      const newLesson = this.createNewLesson();
      this.initLesson({ data: newLesson })
      this.$eventBus.$emit('openSubPage', {
        type: 'lesson',
        modal: true,
        input: {
          loadData: true,
          data: newLesson
        }
      });
    } else if (item.action === 'template') {
      this.$eventBus.$emit('openSubPage', {
        type: 'template',
        modal: true,
        input: {
          action: 'A'
        }
      });
    } else if (item.action === 'unit') {
      this.$eventBus.$emit('openSubPage', {
        type: 'unit',
        modal: true,
        input: {}
      });
    } else if (item.action === 'schoolYear') {
      this.editSchoolYear(true, null);
    } else if (item.action === 'subNotes') {
      this.$nextTick(() => {
        new Promise(resolve => setTimeout(resolve, 10)).then(() => {
          this.localShowSubstituteNotes = true;
        });
      })
    } else if (item.action === 'announcements') {
      this.$nextTick(() => {
        new Promise(resolve => setTimeout(resolve, 10)).then(() => {
          this.localShowAnnouncements = true;
        });
      })
    } else if (item.action === 'classSeating') {
      this.$router.push({ path: 'seating-charts', query: { currentMode: '3' } })
    } else if (item.action === 'attendance') {
      this.$router.push({ path: 'attendance' })
    } else if (item.action === 'toDo') {
      this.$eventBus.$emit('openSubPage', {
        type: 'notesTodoEditor',
        modal: true,
        input: { addWhat: 'TD', editWhat: null },
        plansAddNotesTodo: true
      });
    } else if (item.action === 'notes') {
      this.$eventBus.$emit('openSubPage', {
        type: 'notesTodoEditor',
        modal: true,
        input: { addWhat: 'NT', editWhat: null },
        plansAddNotesTodo: true
      });
    }
  }
}
