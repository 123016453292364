
import { Component, Watch, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import LessonManageStatus from '@/components/lessons/LessonManageStatus.vue';
import DateTimeUtils from '@/utils/date-time-utils';
import CommonUtils from '@/utils/common-utils';
import { VForm } from 'vuetify/lib';
import Confirm from '@/components/core/Confirm.vue';

const classes = namespace('classes');
const settings = namespace('settings');
const schoolstatus = namespace('schoolstatus');
const teachers = namespace('teachers');

@Component({
  components: {
    LessonManageStatus
  }
})
export default class SchoolStatusDialog extends Vue {
  localValue = false;

  $refs!: {
    manageStatus: LessonManageStatus,
    schoolStatusForm: InstanceType<typeof VForm>,
    observer: any,
    confirm: Confirm
  }

  @classes.Getter
  getClassesMap!: any;

  @settings.Getter('getSchoolStatuses')
  allSchoolStatus!: Array<any>;

  @settings.Getter('getCurrentYear')
  currentSchoolYear!: any;

  @teachers.Getter
  getCurrentAdminItem!: any;

  @settings.Getter('getUserMode')
  userMode!: string;

  @schoolstatus.Action
  applySchoolStatus!: (params?: any) => Promise<any>;

  @schoolstatus.Action
  removeSchoolStatus!: (params?: any) => Promise<any>;

  @schoolstatus.Action
  getTeacherSubmittedStatus!: (param?: any) => Promise<any>;

  classList:Array<any> = [];
  showPercentageError = false;
  addStatus = true;
  applyDay = '';
  applyStart = '';
  applyEnd = '';

  schoolStatusData: any = null;
  errorMessage = '';
  dateError: any = [];

  get localAllSchoolStatus() {
    return this.allSchoolStatus.filter((s: any) => !s.overrideDelete).map(s => {
      s.icon = this.selectedS.includes(s.schoolStatusId) ? 'fas fa-check-circle' : 'fas fa-circle';
      return s;
    });
  }

  get selectedS() {
    if (!this.schoolStatusData) {
      return [];
    } else {
      return this.schoolStatusData[this.applyStart] || [];
    }
  }

  @Watch('localValue')
  init() {
    if (this.localValue) {
      CommonUtils.showLoading();
      this.getTeacherSubmittedStatus({ yearId: this.getCurrentAdminItem.year.yearId }).then((d) => {
        this.schoolStatusData = d.data;
        CommonUtils.hideLoading();
      })
    }
  }

  addRemoveSchoolStatus(status: any) {
    if (status.schoolStatusId === 1 && this.selectedS.includes(1)) {
      this.$refs.confirm.confirm({
        title: this.$t('removeStatusLabel'),
        text: this.$t('removeReadyWarningAdmin', { name: status.statusText }),
        option1ButtonAlternativeText: this.$t('removeLabel')
      }).then((result) => {
        if (result === 1) {
          this.doAddRemoveStatus(status);
        }
      });
    } else {
      this.doAddRemoveStatus(status);
    }
  }

  doAddRemoveStatus(status: any) {
    const payload = {
      yearId: this.currentYear.yearId,
      subjectId: 0,
      type: 'W',
      startDate: this.applyStart,
      schoolStatusIds: status.schoolStatusId
    };
    if (this.selectedS.includes(status.schoolStatusId)) {
      this.schoolStatusData[this.applyStart] = this.schoolStatusData[this.applyStart].filter((s: any) => s !== status.schoolStatusId);
      this.removeSchoolStatus(payload)
    } else {
      this.schoolStatusData[this.applyStart].push(status.schoolStatusId)
      this.applySchoolStatus(payload)
    }
  }

  get currentYear() {
    if (this.userMode === 'A') {
      return this.getCurrentAdminItem.year;
    } else {
      return this.currentSchoolYear;
    }
  }

  validateDate() {
    const firstClassDay = this.currentYear.firstDay;
    const lastClassDay = this.currentYear.lastDay;
    this.dateError = [];

    if (DateTimeUtils.isThisDateBeforeThatDate(this.applyEnd, firstClassDay)) {
      this.dateError = [() => this.$t('statusDateError1')];
    } else if (DateTimeUtils.isThisDateAfterThatDate(this.applyStart, lastClassDay)) {
      this.dateError = [() => this.$t('statusDateError2')];
    }
  }

  openApplyStatus(startDate?: any) {
    this.errorMessage = '';
    this.getWeekOf(startDate)
    this.localValue = true;
  }

  manageStatus() {
    this.$nextTick(() => {
      new Promise(resolve => setTimeout(resolve, 10)).then(() => {
        this.$refs.manageStatus.manageStatus();
      });
    })
  }

  get hasPrevious() {
    return DateTimeUtils.isThisDateBeforeThatDate(this.currentYear.firstDay, this.applyStart);
  }

  prevWeek() {
    this.applyStart = DateTimeUtils.format(DateTimeUtils.addDays(DateTimeUtils.toDate(this.applyStart), -7));
    this.applyEnd = DateTimeUtils.format(DateTimeUtils.addDays(DateTimeUtils.toDate(this.applyEnd), -7));
  }

  get hasNext() {
    return DateTimeUtils.isThisDateAfterThatDate(this.currentYear.lastDay, this.applyEnd);
  }

  nextWeek() {
    this.applyStart = DateTimeUtils.format(DateTimeUtils.addDays(DateTimeUtils.toDate(this.applyStart), 7));
    this.applyEnd = DateTimeUtils.format(DateTimeUtils.addDays(DateTimeUtils.toDate(this.applyEnd), 7));
  }

  getWeekOf(weekOf: any) {
    if (DateTimeUtils.isDateFormatValid(weekOf)) {
      this.applyStart = DateTimeUtils.getStartOfWeek(weekOf);
      this.applyEnd = DateTimeUtils.getEndOfWeek(weekOf);
      this.validateDate();
    }
  }
}
