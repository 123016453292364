var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "pb-base-modal",
    {
      attrs: {
        maxWidth: 600,
        input: {},
        withActions: false,
        expandable: false
      },
      on: { apply: _vm.doApply },
      scopedSlots: _vm._u(
        [
          {
            key: "header",
            fn: function() {
              return [
                _vm._t("header", function() {
                  return [_vm._v(_vm._s(_vm.label))]
                })
              ]
            },
            proxy: true
          }
        ],
        null,
        true
      ),
      model: {
        value: _vm.localValue,
        callback: function($$v) {
          _vm.localValue = $$v
        },
        expression: "localValue"
      }
    },
    [
      _c(
        "v-container",
        { staticClass: "editor pt-3", class: _vm.contentClass },
        [
          _vm._t("prepend-filter"),
          _vm._t("filter", function() {
            return [
              _c("v-text-field", {
                staticClass: "mb-5",
                attrs: {
                  "hide-details": "auto",
                  outlined: "",
                  clearable: "",
                  label: _vm.$t("filterLabel"),
                  "prepend-inner-icon": "fal fa-search"
                },
                on: {
                  input: function($event) {
                    _vm.typing = true
                  }
                },
                model: {
                  value: _vm.localSearchText,
                  callback: function($$v) {
                    _vm.localSearchText = $$v
                  },
                  expression: "localSearchText"
                }
              })
            ]
          }),
          _c(
            "div",
            {
              staticStyle: {
                height: "400px",
                "border-top": "1px solid #e0e0e0"
              },
              style: _vm.infiniteScroll
                ? "overflow-y: hidden"
                : "overflow-y: auto"
            },
            [
              _c("editor-section-list", {
                key: _vm.localRefreshKey,
                attrs: {
                  items: _vm.isShowSelected
                    ? _vm.filteredSelectedItems
                    : _vm.filteredItems,
                  showShortValue: _vm.showShortValue,
                  rowStyle: { cursor: "pointer" },
                  infiniteScroll: _vm.infiniteScroll
                },
                on: {
                  itemClick: _vm.onItemTapped,
                  itemEnter: _vm.onItemTapped
                },
                scopedSlots: _vm._u([
                  {
                    key: "actions",
                    fn: function(actions) {
                      return [
                        _c("v-checkbox", {
                          staticClass: "section-editor-checkbox",
                          attrs: {
                            disabled: actions.item.disabled,
                            "input-value": _vm.isSelected(actions.item)
                          },
                          on: {
                            change: function($event) {
                              return _vm.onItemTapped(actions.item)
                            }
                          }
                        })
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c("div", { staticClass: "pt-2" }, [
            _c(
              "div",
              { staticClass: "d-inline-block" },
              [
                _c("span", { staticClass: "mr-3" }, [
                  _vm._v(
                    _vm._s(_vm.$t("selectedLabel")) +
                      ": " +
                      _vm._s(_vm.selectedCount)
                  )
                ]),
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-3",
                    attrs: {
                      small: "",
                      color: _vm.isShowSelected ? "" : "primary"
                    },
                    on: { click: _vm.showAll }
                  },
                  [_vm._v(_vm._s(_vm.$t("showAllLabel")))]
                ),
                _c(
                  "v-btn",
                  {
                    attrs: {
                      small: "",
                      color: _vm.isShowSelected ? "primary" : ""
                    },
                    on: { click: _vm.showSelected }
                  },
                  [_vm._v(_vm._s(_vm.$t("showSelectedLabel")))]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { float: "right" } },
              [
                _vm._t("prepend-action-buttons"),
                _vm.withRefresh
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "mr-3",
                        attrs: { small: "" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("refresh")
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("refreshLabel")))]
                    )
                  : _vm._e(),
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-3",
                    attrs: { small: "" },
                    on: { click: _vm.unselectAll }
                  },
                  [_vm._v(_vm._s(_vm.$t("unselectAllLabel")))]
                ),
                _vm._t("append-action-buttons")
              ],
              2
            )
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }