import axios from '@/axios';
import qs from 'qs';

export default class PaymentServices {
  public static getPaymentToken() {
    return axios.get('services/planbook/payment/token');
  }

  public static processPayment(params: any): Promise<any> {
    params = params || {};
    return axios.post('services/planbook/payment', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static applyVoucher(params: any): Promise<any> {
    params = params || {};
    return axios.post('services/planbook/applyVoucher', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static getPayments(): Promise<any> {
    return axios.get('services/planbook/payment/teacher');
  }

  public static getAdminPayments(): Promise<any> {
    return axios.get('services/planbook/payment/school');
  }

  public static calculate(params?: any): Promise<any> {
    params = params || {};
    return axios.post('services/api/payment/calculate', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static calculateAdd(params?: any): Promise<any> {
    params = params || {};
    return axios.post('services/api/payment/calculateAddAccounts', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }
}
