declare const google: any;
declare const gapi: any;
declare const window: any;
class GoogleServices {
    private clientId: string;
    private developerKey: string;
    private picker: any;
    private oauthToken: any;
    private oauthCode: any;

    constructor(options: any) {
      this.clientId = options.clientId;
      this.developerKey = options.developerKey;
    }

    public connect(params: any) {
      if (params.response_type === 'code') {
        const client = google.accounts.oauth2.initCodeClient({
          client_id: this.clientId,
          scope: params.scope.join(' '),
          ux_mode: 'popup',
          callback: (resp: any) => {
            this._onAuthCallback(params.onAuth, resp);
          }
        });
        client.requestCode();
      } else {
        const client = google.accounts.oauth2.initTokenClient({
          client_id: this.clientId,
          scope: params.scope.join(' '),
          ux_mode: 'popup',
          callback: (resp: any) => {
            this._onAuthCallback(params.onAuth, resp);
          }
        });
        client.requestAccessToken();
      }
    }

    public disconnect() {
      this._clearToken();
      google.accounts.id.disableAutoSelect();
    }

    private _onAuthCallback(onAuth: any, authResult: any) {
      if (authResult && !authResult.error) {
        this.oauthCode = authResult.code;
        this.oauthToken = authResult.access_token;
        window.setTimeout(this._clearToken, (authResult.expires_in - 900) * 1000);
        if (onAuth) {
          onAuth(authResult);
        }
      }
    }

    private _clearToken() {
      this.oauthToken = undefined;
      this.oauthCode = undefined;
    }

    public openPicker(onSelect: any) {
      if (this.oauthToken) {
        this._showPicker(onSelect);
      } else {
        this.connect({ scope: ['https://www.googleapis.com/auth/drive'], immediate: false, onAuth: () => this._showPicker(onSelect) });
      }
    }

    private _showPicker(onSelect: any) {
      gapi.load('picker', {
        callback: () => {
          this._onPickerApiLoad(onSelect)
        }
      });
    }

    private _onPickerApiLoad(onSelect: any) {
      if (!this.picker) {
        this.picker = new google.picker.PickerBuilder()
          .addView(new google.picker.DocsView().setParent('root').setIncludeFolders(true))
          .addView(new google.picker.DocsView().setIncludeFolders(true).setEnableTeamDrives(true))
          .addView(new google.picker.DocsView().setIncludeFolders(true).setOwnedByMe(false))
          .enableFeature(google.picker.Feature.SUPPORT_DRIVES)
          .setOrigin(window.location.protocol + '//' + window.location.host)
          .setOAuthToken(this.oauthToken)
          .setDeveloperKey(this.developerKey)
          .build();
      }
      this.picker.setCallback((data: any) => {
        this._pickerCallback(onSelect, data)
      });
      this.picker.setVisible(true);
    }

    private _pickerCallback(onSelect: any, data: any) {
      if (data[google.picker.Response.ACTION] === google.picker.Action.PICKED) {
        const file = data[google.picker.Response.DOCUMENTS][0];
        if (onSelect) {
          onSelect(file);
        }
      }
    }

    public setAuthToken(oauthToken: string) {
      this.oauthToken = oauthToken || this.oauthToken;
    }

    public setAuthCode(oauthCode: string) {
      this.oauthCode = oauthCode;
    }
}

const googleServices = new GoogleServices({
  clientId: '318112608367-dl5gkn0dqn1eo8pje9169khdp7hh617n.apps.googleusercontent.com',
  developerKey: 'AIzaSyDTeQByFfkcXzeWkODMkqJSaY-p_qJCbtg'
});

export const SITE_KEY = '6LfLS8AUAAAAAMbI-FGNazMxho4AEE1V0TUqB9mE';

export default googleServices;
