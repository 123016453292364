
import { Component, Vue } from 'vue-property-decorator';
import Confirm from '../core/Confirm.vue';
import { namespace } from 'vuex-class';
import ld from 'lodash';
import CommonUtils from '@/utils/common-utils';

const settings = namespace('settings');

@Component
export default class LessonManageStatus extends Vue {
  localValue = false;
  statusItems: Array<any> = [];
  schoolStatusItems: Array<any> = [];
  loading = false;

  $refs!: {
    confirm: Confirm
  }

  @settings.Getter('getUserMode')
  userMode!: any;

  @settings.Getter('getSchoolId')
  schoolId!: number;

  @settings.Action('saveStatus')
  doSaveStatus!: (params?: any) => Promise<any>;

  @settings.Action('saveSchoolStatus')
  doSaveSchoolStatus!: (params?: any) => Promise<any>;

  @settings.Action('deleteStatus')
  doDeleteStatus!: (params?: any) => Promise<any>;

  @settings.Action('restoreSchoolStatus')
  doRestoreSchoolStatus!: (params?: any) => Promise<any>;

  @settings.Action('deleteSchoolStatus')
  doDeleteSchoolStatus!: (params?: any) => Promise<any>;

  get statuses(): Array<any> {
    return this.$store.getters['settings/getStatuses'];
  }

  set statuses(val: Array<any>) {
    this.$store.commit('settings/setStatuses', val);
  }

  get schoolStatuses(): Array<any> {
    return this.$store.getters['settings/getSchoolStatuses'];
  }

  get showSchoolStatus() {
    return this.schoolId !== 0 && this.schoolStatuses.length > 0;
  }

  doApplyStatus(item: any) {
    this.loading = true;
    this.applyStatus(item).finally(() => {
      this.loading = false;
    })
  }

  doApplySchoolStatus(item: any) {
    this.loading = true;
    this.applySchoolStatus(item).finally(() => {
      this.loading = false;
    })
  }

  addStatus() {
    this.loading = true;
    if (this.userMode === 'A') {
      this.applySchoolStatus().then(async () => {
        return this.doSaveSchoolStatus({
          value: -999,
          color: CommonUtils.getRandomColor(),
          statusText: this.$t('newStatusLabel')
        }).then(() => {
          return this.refresh();
        }).then(() => {
          if (CommonUtils.isNotEmpty(this.schoolStatusItems)) {
            this.schoolStatusItems[0].edit = true;
          }
        });
      }).finally(() => {
        this.loading = false;
      });
    } else {
      this.applyStatus().then(async () => {
        return this.doSaveStatus({
          value: -999,
          color: CommonUtils.getRandomColor(),
          statusText: this.$t('newStatusLabel')
        }).then(() => {
          return this.refresh();
        }).then(() => {
          if (CommonUtils.isNotEmpty(this.statusItems)) {
            this.statusItems[0].edit = true;
          }
        });
      }).finally(() => {
        this.loading = false;
      });
    }
  }

  manageStatus() {
    this.localValue = true;
  }

  editStatus(item: any) {
    const statuses = ld.cloneDeep(this.statusItems);
    const toUpdate = statuses.find(s => s.lessonStatusId === item.lessonStatusId);
    statuses.forEach(s => { s.edit = false });
    toUpdate.edit = true;
    this.statusItems = statuses;
    this.$nextTick(() => {
      new Promise(resolve => setTimeout(resolve, 10)).then(() => {
        try {
          (this.$refs as any)[`text${item.lessonStatusId}`][0].$el.querySelector('input').focus()
        } catch (e) {
          console.log(this.$t('focusFailed'));
        }
      });
    })
  }

  editSchoolStatus(item: any) {
    const schoolStatuses = ld.cloneDeep(this.schoolStatusItems);
    const toUpdate = schoolStatuses.find(s => s.schoolStatusId === item.schoolStatusId);
    schoolStatuses.forEach(s => { s.edit = false });
    toUpdate.edit = true;
    this.schoolStatusItems = schoolStatuses;
    this.$nextTick(() => {
      new Promise(resolve => setTimeout(resolve, 10)).then(() => {
        try {
          (this.$refs as any)[`text${item.schoolStatusId}`][0].$el.querySelector('input').focus()
        } catch (e) {
          console.log(this.$t('focusFailed'));
        }
      });
    })
  }

  async deleteStatus(item: any) {
    this.loading = true;
    return this.$refs.confirm.confirm({
      title: this.$t('deleteStatusLabel'),
      text: this.$t('confirmDeleteStatusMsg'),
      option1ButtonAlternativeText: this.$t('continueLabel')
    }).then((result) => {
      if (result === 1) {
        this.loading = true;
        this.applyStatus().then(() => {
          return this.doDeleteStatus(item);
        }).then(() => {
          this.loading = false;
          return this.refresh();
        });
      } else {
        return Promise.resolve();
      }
    });
  }

  async restoreSchoolStatus(item: any) {
    this.loading = true;
    this.applySchoolStatus().then(() => {
      return this.doRestoreSchoolStatus(item);
    }).then(() => {
      this.loading = false;
      return this.refresh();
    });
  }

  async deleteSchoolStatus(item: any) {
    if (item.schoolStatusId < 3 && item.overrideDelete) {
      return this.restoreSchoolStatus(item);
    }
    this.loading = true;
    return this.$refs.confirm.confirm({
      title: ((item.schoolStatusId < 3) ? this.$t('disableStatusLabel') : this.$t('deleteStatusLabel')),
      text: ((item.schoolStatusId < 3) ? this.$t('confirmDisableStatusMsg') : this.$t('confirmDeleteStatusMsg')),
      option1ButtonAlternativeText: this.$t('continueLabel')
    }).then((result) => {
      if (result === 1) {
        this.loading = true;
        this.applySchoolStatus().then(() => {
          return this.doDeleteSchoolStatus(item);
        }).then(() => {
          this.loading = false;
          return this.refresh();
        });
      } else {
        return Promise.resolve();
      }
    });
  }

  refresh() {
    this.initStatusItems();
    return Promise.resolve({});
  }

  async applyStatus(toSave?: any) {
    const item = toSave || this.statusItems.find(item => item.edit);
    if (item != null) {
      return this.doSaveStatus(item).then(() => {
        return this.refresh();
      });
    } else {
      return Promise.resolve({});
    }
  }

  async applySchoolStatus(toSave?: any) {
    const item = toSave || this.schoolStatusItems.find(item => item.edit);
    if (item != null) {
      return this.doSaveSchoolStatus(item).then(() => {
        return this.refresh();
      });
    } else {
      return Promise.resolve({});
    }
  }

  initStatusItems() {
    this.statusItems = ld.cloneDeep(this.statuses);
    this.schoolStatusItems = ld.cloneDeep(this.schoolStatuses);
    for (const i in this.statusItems) {
      const status: any = this.statusItems[i];
      status.edit = false;
    }
    for (const i in this.schoolStatusItems) {
      const status: any = this.statusItems[i];
      if (status) status.edit = false;
    }
  }

  mounted() {
    this.initStatusItems();
  }
}
