import CommonUtils from '@/utils/common-utils';
import Vue from 'vue'
import VueRouter, { NavigationGuardNext, RouteConfig } from 'vue-router'
import CommonServices from '@/services/common-services';
import { UserSession } from '@/common/user-session';
import store from '@/store';
import eventBus from '@/events';
import AppError, { SessionExpiredError } from '@/errors';
import i18n from '@/plugins/i18n';
import AuthServices from '@/services/auth-services';
import { cmnSession } from '@/common/planbook-storage';
import WebUtils from '@/utils/web-utils';
import CurrentUser from '@/common/current-user';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/auth/callback',
    component: () => import('@/views/pages/auth/OAuth2Callback.vue'),
    props: route => ({
      code: route.query.code,
      state: route.query.state
    })
  },
  {
    path: '/',
    component: () => import('@/views/pages/Index.vue'),
    children: [
      {
        path: '',
        alias: 'session',
        component: () => import('@/views/pages/Session.vue')
      },
      {
        name: i18n.t('plansLabel') as string,
        path: 'plans',
        components: {
          default: () => import('@/views/pages/Plans.vue'),
          toolbar: () => import('@/components/plans/PlansToobar.vue'),
          bottomToolbar: () => import('@/components/plans/PlansBottomToolbar.vue')
        }
      },
      {
        name: i18n.t('teacherSubmissionsLabel') as string,
        path: 'reporting-plans',
        components: {
          default: () => import('@/views/pages/TeacherSubmissions.vue'),
          toolbar: () => import('@/components/teachers/TeacherSubmissionsToolbar.vue'),
          bottomToolbar: () => import('@/components/teachers/TeacherSubmissionsBottomToolbar.vue')
        }
      },
      {
        name: i18n.t('classworkLabel') as string,
        path: 'classwork',
        components: {
          default: () => import('@/views/pages/Classwork.vue'),
          toolbar: () => import('@/components/classwork/ClassworkToolbar.vue'),
          bottomToolbar: () => import('@/components/classwork/ClassworkBottomToolbar.vue')
        }
      },
      {
        name: i18n.t('messagesLabel') as string,
        path: 'messages',
        components: {
          default: () => import('@/views/pages/Messages.vue'),
          toolbar: () => import('@/components/messages/MessagesToolbar.vue'),
          bottomToolbar: () => import('@/components/messages/MessagesBottomToolbar.vue')
        }
      },
      {
        name: i18n.t('accountLabel') as string,
        path: 'account',
        components: {
          default: () => import('@/views/pages/Account.vue'),
          toolbar: () => import('@/components/core/BaseToolbar.vue')
        }
      },
      {
        name: i18n.t('templatesLabel') as string,
        path: 'templates',
        components: {
          default: () => import('@/views/pages/Templates.vue'),
          toolbar: () => import('@/components/templates/TemplatesToolbar.vue'),
          bottomToolbar: () => import('@/components/templates/TemplatesBottomToolbar.vue')
        }
      },
      {
        name: i18n.t('teachersLabel') as string,
        path: 'teachers',
        components: {
          default: () => import('@/views/pages/Teachers.vue'),
          toolbar: () => import('@/components/teachers/TeachersToolbar.vue'),
          bottomToolbar: () => import('@/components/teachers/TeachersBottomToolbar.vue')
        }
      },
      {
        name: i18n.t('studentsLabel') as string,
        path: 'students',
        components: {
          default: () => import('@/views/pages/Students.vue'),
          toolbar: () => import('@/components/students/StudentsToolbar.vue'),
          bottomToolbar: () => import('@/components/students/StudentsBottomToolbar.vue')
        }
      },
      {
        name: i18n.t('assignStudentsLabel') as string,
        path: 'assign-students',
        components: {
          default: () => import('@/views/pages/Students.vue'),
          toolbar: () => import('@/components/students/StudentsToolbar.vue'),
          bottomToolbar: () => import('@/components/students/StudentsBottomToolbar.vue')
        }
      },
      {
        name: i18n.t('displaySettingsLabel') as string,
        path: 'display-settings',
        components: {
          default: () => import('@/views/pages/DisplaySettings.vue'),
          toolbar: () => import('@/components/core/BaseToolbar.vue')
        }
      },
      {
        name: i18n.t('classesLabel') as string,
        path: 'classes',
        components: {
          default: () => import('@/views/pages/Classes.vue'),
          toolbar: () => import('@/components/classes/ClassesToolbar.vue'),
          bottomToolbar: () => import('@/components/classes/ClassesBottomToolbar.vue')
        }
      },
      {
        name: i18n.t('eventsLabel') as string,
        path: 'events',
        components: {
          default: () => import('@/views/pages/Events.vue'),
          toolbar: () => import('@/components/events/EventsToolbar.vue'),
          bottomToolbar: () => import('@/components/events/EventsBottomToolbar.vue')
        }
      },
      {
        name: i18n.t('unitsLabel') as string,
        path: 'units',
        components: {
          default: () => import('@/views/pages/Units.vue'),
          toolbar: () => import('@/components/units/UnitsToolbar.vue'),
          bottomToolbar: () => import('@/components/units/UnitsBottomToolbar.vue')
        }
      },
      {
        name: i18n.t('lessonLayoutsLabel') as string,
        path: 'lesson-layouts',
        components: {
          default: () => import('@/views/pages/LessonLayouts.vue'),
          toolbar: () => import('@/components/lessonlayouts/LessonLayoutsToolbar.vue'),
          bottomToolbar: () => import('@/components/lessonlayouts/LessonLayoutsBottomToolbar.vue')
        }
      },
      {
        name: i18n.t('resourcesLabel') as string,
        path: 'resources',
        components: {
          default: () => import('@/views/pages/Resources.vue'),
          toolbar: () => import('@/components/resources/ResourcesToolbar.vue'),
          bottomToolbar: () => import('@/components/resources/ResourcesBottomToolbar.vue')
        }
      },
      {
        name: i18n.t('notesTodosLabel') as string,
        path: 'notes-todo',
        components: {
          default: () => import('@/views/pages/NotesTodo.vue'),
          toolbar: () => import('@/components/notestodo/NotesTodoToolbar.vue'),
          bottomToolbar: () => import('@/components/notestodo/NotesTodoBottomToolbar.vue')
        }
      },
      {
        name: i18n.t('integrationsLabel') as string,
        path: 'integrations',
        components: {
          default: () => import('@/views/pages/Integrations.vue'),
          toolbar: () => import('@/components/core/BaseToolbar.vue')
        }
      },
      {
        name: i18n.t('myListLabel') as string,
        path: 'my-list',
        components: {
          default: () => import('@/views/pages/MyLists.vue'),
          toolbar: () => import('@/components/mylists/MyListToolbar.vue'),
          bottomToolbar: () => import('@/components/mylists/MyListBottomToolbar.vue')
        }
      },
      {
        name: i18n.t('schoolListLabel') as string,
        path: 'school-list',
        components: {
          default: () => import('@/views/pages/MyLists.vue'),
          toolbar: () => import('@/components/mylists/MyListToolbar.vue'),
          bottomToolbar: () => import('@/components/mylists/MyListBottomToolbar.vue')
        }
      },
      {
        name: i18n.t('strategiesLabel') as string,
        path: 'strategies',
        components: {
          default: () => import('@/views/pages/MyStrategies.vue'),
          toolbar: () => import('@/components/mystrategies/MyStrategiesToolbar.vue'),
          bottomToolbar: () => import('@/components/mystrategies/MyStrategiesBottomToolbar.vue')
        }
      },
      {
        name: i18n.t('schoolProfileLabel') as string,
        path: 'school-profile',
        components: {
          default: () => import('@/views/pages/SchoolProfile.vue'),
          toolbar: () => import('@/components/core/BaseToolbar.vue')
        }
      },
      {
        name: i18n.t('seatAssignmentsLabel') as string,
        path: 'class-seating',
        components: {
          default: () => import('@/views/pages/SeatingCharts.vue'),
          toolbar: () => import('@/components/seatingcharts/ClassSeatingToolbar.vue'),
          bottomToolbar: () => import('@/components/seatingcharts/ClassSeatingBottomToolbar.vue')
        }
      },
      {
        name: i18n.t('seatLayoutsLabel') as string,
        path: 'room-layouts',
        components: {
          default: () => import('@/views/pages/SeatingCharts.vue'),
          toolbar: () => import('@/components/seatingcharts/RoomLayoutsToolbar.vue'),
          bottomToolbar: () => import('@/components/seatingcharts/RoomLayoutsBottomToolbar.vue')
        }
      },
      {
        name: i18n.t('schoolYearsLabel') as string,
        path: 'school-years',
        components: {
          default: () => import('@/views/pages/SchoolYears.vue'),
          toolbar: () => import('@/components/schoolyears/SchoolYearsToolbar.vue'),
          bottomToolbar: () => import('@/components/schoolyears/SchoolYearsBottomToolbar.vue')
        }
      },
      {
        name: i18n.t('sharingOptionsLabel') as string,
        path: 'sharing-options',
        components: {
          default: () => import('@/views/pages/SharingOptions.vue'),
          toolbar: () => import('@/components/core/BaseToolbar.vue'),
          bottomToolbar: () => import('@/components/core/CommonBottomToolbar.vue')
        }
      },
      {
        name: i18n.t('attendanceLabel') as string,
        path: 'attendance',
        components: {
          default: () => import('@/views/pages/Attendance.vue'),
          toolbar: () => import('@/components/attendance/AttendanceToolbar.vue'),
          bottomToolbar: () => import('@/components/attendance/AttendanceBottomToolbar.vue')
        }
      },
      {
        name: i18n.t('lessonBanksLabel') as string,
        path: 'lesson-banks',
        components: {
          default: () => import('@/views/pages/LessonBanks.vue'),
          toolbar: () => import('@/components/lessonlists/LessonBanksToolbar.vue'),
          bottomToolbar: () => import('@/components/lessonlists/LessonBanksBottomToolbar.vue')
        }
      },
      {
        name: i18n.t('gradesLabel') as string,
        path: 'grades',
        components: {
          default: () => import('@/views/pages/Grades.vue'),
          toolbar: () => import('@/components/grades/GradesToolbar.vue'),
          bottomToolbar: () => import('@/components/grades/GradesBottomToolbar.vue')
        }
      },
      {
        name: i18n.t('lessonSearchLabel') as string,
        path: 'search',
        components: {
          default: () => import('@/views/pages/LessonSearch.vue'),
          toolbar: () => import('@/components/lessonlists/LessonSearchToolbar.vue'),
          bottomToolbar: () => import('@/components/lessonlists/LessonSearchBottomToolbar.vue')
        }
      },
      {
        name: ((UserSession.getCurrentUser()?.type === 'T') ? i18n.t('reportingLabel') : i18n.t('reportingStandardsLabel')) as string,
        path: 'reporting',
        components: {
          default: () => import('@/views/pages/Reporting.vue'),
          toolbar: () => import('@/components/reporting/ReportingToolbar.vue'),
          bottomToolbar: () => import('@/components/reporting/ReportingBottomToolbar.vue')
        }
      },
      {
        name: i18n.t('copyLabel') as string,
        path: 'copy',
        components: {
          default: () => import('@/views/pages/Copy.vue'),
          toolbar: () => import('@/components/core/BaseToolbar.vue')
        }
      },
      {
        name: i18n.t('changeHistoryLabel') as string,
        path: 'change-history',
        components: {
          default: () => import('@/views/pages/ChangeHistory.vue'),
          toolbar: () => import('@/components/changehistory/ChangeHistoryToolbar.vue')
        }
      }
    ]
  },
  {
    path: '/error',
    component: () => import('@/views/pages/Error.vue')
  },
  {
    path: '*',
    component: () => import('@/views/pages/Error.vue')
  },
  {
    path: '/login',
    redirect: '/auth/login'
  },
  {
    path: '/v1/login',
    redirect: '/auth/v1/login'
  },
  {
    path: '/signup',
    redirect: '/auth/signup'
  },
  {
    path: '/auth',
    component: () => import('@/views/pages/Auth.vue'),
    children: [
      {
        path: 'login',
        beforeEnter: (to) => {
          cmnSession.set('savedRedirectUri', to.query?.redirect);
          window.location.href = AuthServices.getOAuth2CodeAuthorizationUrl();
        }
      },
      {
        path: 'v1/login',
        component: () => import('@/views/pages/auth/Login.vue')
      },
      {
        path: 'signup',
        component: () => import('@/views/pages/auth/Register.vue')
      },
      {
        path: 'forgot',
        component: () => import('@/views/pages/auth/Forgot.vue')
      },
      {
        path: 'student-codes',
        component: () => import('@/views/pages/auth/StudentCodes.vue')
      },
      {
        path: 'reset',
        component: () => import('@/views/pages/auth/Reset.vue')
      },
      {
        path: 'onboarding',
        component: () => import('@/views/pages/auth/Onboarding.vue')
      }
    ]
  },
  {
    path: '/join',
    redirect: (to) => {
      let queryParams = '';
      if (to.query) {
        queryParams += Object.keys(to.query).map(key => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(to.query[key] as string || '');
        }).join('&');
      }
      window.location.href = `${WebUtils.getJoinBaseUrl()}?${queryParams}`;
      return '/auth/signup';
    }
  },
  {
    path: '/planbook.html',
    redirect: '/plans'
  },
  {
    path: '/welcome.html',
    redirect: '/'
  },
  {
    path: '/day-object',
    component: () => import('@/views/pages/DayObjectViewer.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.afterEach((to) => {
  store.commit('plans/setResetOnly', to.path !== '/plans', { root: true });
});

router.beforeEach(async (to, from, next) => {
  store.commit('drawer/setDrawerRightStateless', false, { root: true });
  store.commit('drawer/setDrawerLeftStateless', false, { root: true });
  eventBus.$emit('appBarEnable');
  if (to.path === '/signOut') {
    eventBus.$emit('closeSubPage', true);
    store.commit('reporting/reset', undefined, { root: true });
    CommonUtils.showLoading();
    const confirm = store.state.confirm;
    AuthServices.checkSession().then(resp => {
      if (resp?.data?.count > 1) {
        return confirm({
          title: i18n.t('logoutOptionsLabel'),
          text: i18n.t('logoutHeadsUpMsg'),
          option1ButtonAlternativeText: i18n.t('logoutAllTabsLabel'),
          option2ButtonAlternativeText: i18n.t('logoutJustThisTabLabel'),
          cancelButtonText: i18n.t('cancelLabel')
        });
      } else {
        return Promise.resolve(1);
      }
    }).then(async (result) => {
      if (result === 0) {
        return Promise.resolve().then(() => {
          next(from.fullPath);
        });
      } else {
        return store.dispatch('settings/signOut', { all: +result === 1 }).then(() => {
          next('/auth/login');
        })
      }
    }).finally(() => {
      CommonUtils.hideLoading();
    });
  } else {
    let hasToken = false;
    let hasWelcomeCode = false;
    let hasResetToken = false;
    if (CommonUtils.hasText(to.query.k) && +to.query.t > 0) {
      UserSession.setCurrentUser({
        teacherId: +to.query.t || 0,
        key: to.query.k as string || '',
        subjectId: +to.query.c || 0,
        currentSchoolYearId: +to.query.y || 0,
        view: to.query.v as string || 'W',
        type: 'S'
      });
    } else if (CommonUtils.hasText(to.query.token)) {
      const token = to.query.token as string;
      await AuthServices.initSession(token).catch(() => {
        next('/error');
      });
      UserSession.setCurrentUserFromToken(token as string);
      hasToken = true;
    } else if (CommonUtils.hasText(to.query.j)) {
      hasWelcomeCode = true;
      store.commit('welcome/setJoinCode', to.query.j, { root: true });
      store.commit('welcome/setStudentJoinCode', '', { root: true });
    } else if (CommonUtils.hasText(to.query.sc)) {
      hasWelcomeCode = true;
      store.commit('welcome/setJoinCode', '', { root: true });
      store.commit('welcome/setStudentJoinCode', to.query.sc, { root: true });
    } else if (CommonUtils.hasText(to.query.r)) {
      hasResetToken = true;
      store.commit('welcome/setResetCode', to.query.r, { root: true });
    } else if (CommonUtils.hasText(to.query.p)) {
      hasWelcomeCode = true;
      store.commit('welcome/setPromoCode', to.query.p, { root: true });
    }

    if (!hasResetToken && !hasWelcomeCode && !UserSession.hasSession() && to.path !== '/error') {
      await AuthServices.checkSession().catch(() => {
        next('/error');
      });
    }

    if (hasResetToken) {
      next({ path: '/auth/reset' });
    } else if (hasWelcomeCode) {
      next({ path: '/auth/signup' });
    } if (to.path === '/error') {
      eventBus.$emit('closeSubPage', true);
      store.commit('setLoading', false);
      next();
    } else if (to.path.startsWith('/auth')) {
      eventBus.$emit('closeSubPage', true);
      store.commit('reporting/reset', undefined, { root: true });
      store.commit('setLoading', false);
      next();
    } else if (!UserSession.hasSession()) {
      eventBus.$emit('closeSubPage', true);
      CommonUtils.hideLoading();
      if (to.path !== '/auth/login') {
        let query: any = {};
        console.log(to.fullPath);
        if (to.path !== '/' && to.path !== '/auth') {
          query.redirect = to.fullPath;
        } else {
          query = to.query;
        }
        next({ path: '/auth/login', query });
      } else {
        next();
      }
    } else {
      Promise.resolve().then(() => {
        if (CommonUtils.hasText(store.state.index.openSubpage) && !store.state.index.isModal) {
          const subpageReference = store.state.index.subpageReference;
          if (subpageReference && subpageReference.isDirty && subpageReference.isDirty()) {
            const confirm = store.state.index.confirmFunction;
            return confirm({
              title: i18n.t('unsavedChangesLabel'),
              text: i18n.t('unsavedChangesPageGenericMsg'),
              option1ButtonAlternativeText: i18n.t('saveChangesLabel'),
              option2ButtonAlternativeText: i18n.t('discardChangesLabel'),
              cancelButtonText: i18n.t('cancelLabel')
            }).then((result: any) => {
              if (result === 1) {
                return subpageReference.doApply().then((pageOpen: boolean) => {
                  if (pageOpen) {
                    return Promise.reject();
                  } else {
                    eventBus.$emit('closeSubPage', false)
                    return Promise.resolve();
                  }
                })
              } else if (result === 2) {
                eventBus.$emit('closeSubPage', false)
                return Promise.resolve();
              } else {
                return Promise.reject();
              }
            });
          } else {
            eventBus.$emit('closeSubPage', false)
            return Promise.resolve();
          }
        }
      }).then(() => {
        if (hasWelcomeCode || hasResetToken) {
          next();
          store.commit('setLoading', false);
        } else {
          CommonServices.initSession({ userMode: to.query.userMode }).then((data) => {
            if (data.newUser) {
              next({ path: '/join', query: { token: UserSession.getAccessToken(), referrer: window.location.origin } });
            } else if (hasToken) {
              const query = to.query;
              delete query.token;
              delete query.sessionOnly;
              if (to.path === '/') {
                next({ path: '/plans', params: to.params, query });
              } else {
                next({ path: to.path, params: to.params, query });
              }
            } else {
              if (to.path === '/plans') {
                if (CurrentUser.isManager) {
                  store.commit('settings/setNewUser', to.query.newUser, { root: true });
                  next('/teachers');
                } if (to.query.newUser) {
                  store.commit('settings/setNewUser', true, { root: true });
                  next('/plans');
                } else {
                  next();
                }
              } else if (to.path === '/' && to.query.replyTo) {
                const replyId: any = to.query.replyTo;
                store.commit('messages/setReplyId', replyId, { root: true });
                next({ path: '/messages', params: to.params });
              } else if (to.path === '/') {
                if (CommonUtils.hasText(to.query.k) && +to.query.t > 0) {
                  next({ path: '/plans', params: to.params, query: to.query });
                } else {
                  if (CurrentUser.isManager) {
                    next('/teachers');
                  } else {
                    next('/plans');
                  }
                }
              } else {
                next();
              }
            }
          }).catch((err) => {
            handleError(next, err);
          }).finally(() => {
            store.commit('setLoading', false);
          });
        }
      }).catch(() => {
        return false
      })
    }
  }
  eventBus.$emit('setTitle', to.name);
});

function handleError(next: NavigationGuardNext, err: Error) {
  if (err instanceof AppError) {
    if (err.cause instanceof SessionExpiredError) {
      next('/auth/login');
    } else {
      next('/error');
    }
  } else if (err instanceof SessionExpiredError) {
    next('/auth/login');
  } else {
    next('/error');
  }
}

export default router
