import CurrentUser from '@/common/current-user';
import AppError, { createErrOptions, SessionExpiredError } from '@/errors';
import IntegrationServices from '@/services/integration-services';
import CommonUtils from '@/utils/common-utils';
import Fuse from 'fuse.js';
import moment from 'moment';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

const RosteredItemType = {
  School: 'school',
  Teacher: 'teacher',
  SchoolYear: 'schoolyear',
  Subject: 'subject',
  Student: 'student',
  StudentEnrollment: 'studentenrollment'
} as const;

@Module({ namespaced: true })
export default class Integration extends VuexModule {
  addLabel = 'add';
  doNothingLabel = 'do-nothing';
  allRosteredData:allRosteredDataType = null;

  get getAllRosteredData():allRosteredDataType {
    return this.allRosteredData;
  }

  get allRosteredItems():any[] {
    return this.allRosteredData?.allRosteredItems || [];
  }

  get haveRosteredItems():boolean {
    return CommonUtils.hasValue(this.getAllRosteredData);
  }

  get lastSyncDate():any {
    return this.allRosteredData?.lastSyncDate || null;
  }

  get isSchoolRostered(): (params:any) => boolean {
    return (school:any) => {
      return this.haveRosteredItems && this.allRosteredItems.some(item => item.id === +school.schoolId && item.type === RosteredItemType.School);
    }
  }

  get isTeacherRostered(): (params:any) => boolean {
    return (teacher:any) => {
      return this.haveRosteredItems && this.allRosteredItems.some(item => item.id === +teacher.teacherId && item.type === RosteredItemType.Teacher);
    }
  }

  get isSchoolYearRostered(): (params:any) => boolean {
    return (year:any) => {
      return this.haveRosteredItems && this.allRosteredItems.some(item => item.id === +year.yearId && item.type === RosteredItemType.SchoolYear);
    }
  }

  get isSubjectRostered(): (params:any) => boolean {
    return (subject:any) => {
      const subjectId = +subject.cId || +subject.subjectId || +subject.classId;
      return this.haveRosteredItems && this.allRosteredItems.some(item => item.id === subjectId && item.type === RosteredItemType.Subject);
    }
  }

  get isStudentRostered(): (params:any) => boolean {
    return (student:any) => {
      return this.haveRosteredItems && this.allRosteredItems.some(item => item.id === +student.studentId && item.type === RosteredItemType.Student);
    }
  }

  get isStudentEnrollmentRostered(): (params:any) => boolean {
    return (student:any) => {
      return this.haveRosteredItems && this.allRosteredItems.some(item => item.id === +student.studentId && item.subjectid === +student.subjectId && item.type === RosteredItemType.StudentEnrollment);
    }
  }

  get isRosteredItemConnected(): (params:any) => boolean {
    return (item:any) => {
      let id:any;
      if (item.itemType === RosteredItemType.School) {
        id = item.value || item.schoolId;
      } else if (item.itemType === RosteredItemType.Teacher) {
        id = item.teacherId;
      } else if (item.itemType === RosteredItemType.SchoolYear) {
        id = item.yearId;
      } else if (item.itemType === RosteredItemType.Subject) {
        id = item.cId || item.subjectId || item.classId;
      } else if (item.itemType === RosteredItemType.Student) {
        id = item.studentId;
      } else if (item.itemType === RosteredItemType.StudentEnrollment) {
        return this.haveRosteredItems && !this.allRosteredItems.find(i => i.id === +item.studentId && i.subjectid === +item.subjectId).archivalInd;
      }
      return this.haveRosteredItems && !this.allRosteredItems.find(i => i.id === +id).archivalInd;
    }
  }

  @Mutation
  setRosteredData(items:allRosteredDataType) {
    this.allRosteredData = items;
  }

  @Mutation
  clearRosteredData() {
    this.allRosteredData = null;
  }

  // ACTIONS: START
  @Action({ rawError: true })
  async getOrgs(params?: any) {
    try {
      params = params || {};
      const resp = await IntegrationServices.getOrgs(params);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          console.log('GET ORGS EXPIRED');
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async getOrg(params?: any) {
    try {
      params = params || {};
      const resp = await IntegrationServices.getOrg(params);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          console.log('GET ORG EXPIRED');
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async getTeachersForSchool(params?: any) {
    try {
      params = params || {};
      const resp = await IntegrationServices.getTeachersForSchool(params);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          console.log('GET TEACHERS EXPIRED');
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async getTeacher(params?: any) {
    try {
      params = params || {};
      const resp = await IntegrationServices.getTeacher(params);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          console.log('GET TEACHER EXPIRED');
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async getAcademicSessions(params?: any) {
    try {
      params = params || {};
      const resp = await IntegrationServices.getAcademicSessions(params);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          console.log('GET ACADEMIC SESSIONS EXPIRED');
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async getAcademicSession(params?: any) {
    try {
      params = params || {};
      const resp = await IntegrationServices.getAcademicSession(params);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          console.log('GET ACADEMIC SESSIONS EXPIRED');
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async refOrgs(params?: any) {
    params = params || {};
    params.orgs = params.orgs.map((element:any) => {
      const eachParams: any = {};
      eachParams.endpoint = params.endpoint;
      eachParams.accessKey = params.accessKey;
      eachParams.secretKey = params.secretKey;
      eachParams.authorId = params.authorId;
      eachParams.syncTime = params.syncTime;
      eachParams.sourcedId = element.sourcedId;

      const url = new URL(params.endpoint);
      eachParams.domain = url.hostname;

      const options:any = {
        includeScore: true,
        keys: ['sourcedId']
      };
      const fuse = new Fuse(params.selectedOrgs, options);
      const output:any = fuse.search(element.sourcedId);
      eachParams.archivalInd = true;
      if (output.length !== 0) {
        if (output[0].item.sourcedId === element.sourcedId && element.setUpPBSchoolName !== this.doNothingLabel) {
          eachParams.archivalInd = false;
        } else {
          eachParams.archivalInd = true;
        }
      }

      try {
        if (element.type === 'district') {
          eachParams.districtId = element.setUpPBSchoolName.value.schoolId;
          eachParams.schoolId = 0;
        } else {
          eachParams.districtId = 0;
          eachParams.schoolId = element.setUpPBSchoolName.value.schoolId;
        }
      } catch (e) {
        eachParams.districtId = 0;
        eachParams.schoolId = 0;
      }

      if (element.setUpPBSchoolName === this.addLabel) {
        eachParams.isAdd = true;
      } else {
        eachParams.isAdd = false;
      }
      return eachParams;
    });
    params.selectedOrgs = [];
    try {
      const resp = await IntegrationServices.refOrgs(params);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          console.log('REF ORGS EXPIRED');
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async refUsers(params?: any) {
    params = params || {};
    let domain = '';
    params.teachers = params.teachers.map((element:any) => {
      const eachParams: any = {};
      eachParams.sourcedId = element.sourcedId;
      eachParams.studentId = 0;
      eachParams.orgSourcedId = element.orgSourcedId;
      eachParams.orgsSourcedIdArray = element.orgsSourcedIdArray;

      const options:any = {
        includeScore: true,
        keys: ['sourcedId']
      };
      const fuse = new Fuse(params.selectedTeachers, options);
      const output:any = fuse.search(element.sourcedId);
      eachParams.archivalInd = true;
      if (output.length !== 0) {
        if (output[0].item.sourcedId === element.sourcedId && element.setUpPBTeacherName !== this.doNothingLabel) {
          eachParams.archivalInd = false;
        } else {
          eachParams.archivalInd = true;
        }
      }

      try {
        eachParams.emailAddress = element.setUpPBTeacherName.value.teacherName;
        eachParams.teacherId = element.setUpPBTeacherName.value.teacherId;
      } catch (e) {
        eachParams.emailAddress = '';
        eachParams.teacherId = 0;
      }

      const url = new URL(params.endpoint);
      eachParams.domain = url.hostname;
      domain = eachParams.domain;
      if (element.setUpPBTeacherName === this.addLabel) {
        eachParams.isAdd = true;
      } else {
        eachParams.isAdd = false;
      }

      if (element.duplicate) {
        eachParams.emailAddress = '';
        eachParams.teacherId = 0;
        eachParams.archivalInd = true;
        eachParams.isAdd = false;
      }
      return eachParams;
    });
    params.selectedTeachers = [];
    params.domain = domain;
    try {
      const resp = await IntegrationServices.refUsers(params);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          console.log('REF USERS EXPIRED');
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async refOrgsRefUsers(params?: any) {
    params = params || {};
    params.teachers = params.teachers.map((element:any) => {
      const eachParams: any = {};
      eachParams.userSourcedId = element.sourcedId;
      eachParams.orgSourcedId = element.orgSourcedId;

      const url = new URL(params.endpoint);
      eachParams.domain = url.hostname;
      return eachParams;
    });

    try {
      const resp = await IntegrationServices.refOrgsRefUsers(params);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          console.log('REF ORGS REF USERS EXPIRED');
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async refAcademicSessions(params?: any) {
    params = params || {};
    params.schoolYears = params.schoolYears.map((element:any) => {
      const eachParams: any = {};
      eachParams.sourcedId = element.sourcedId;
      eachParams.gradingPeriodId = params.gradingPeriodId;
      eachParams.schoolYear = element.schoolYear;
      eachParams.schoolId = params.schoolId;
      eachParams.districtId = params.districtId;
      eachParams.withClasses = element.withClasses;
      eachParams.yearId = 0;
      eachParams.isRowSelectable = element.isRowSelectable;
      eachParams.title = element.title;

      if (element.orgSourcedId !== '') {
        eachParams.orgSourcedId = element.orgSourcedId;
      } else {
        eachParams.orgSourcedId = 0;
      }

      // const regExp = /\(([^)]+)\)/;
      // const matches:any = regExp.exec(element.setUpPBSchoolYear);
      // // matches[1] contains the value between the parentheses
      // try {
      //   eachParams.yearId = matches[1];
      // } catch (e) {
      //   eachParams.yearId = 0;
      // }

      const options:any = {
        includeScore: true,
        keys: ['sourcedId']
      };
      const fuse = new Fuse(params.selectedSchoolYears, options);
      const output:any = fuse.search(element.sourcedId);
      eachParams.archivalInd = true;
      if (output.length !== 0) {
        if (output[0].item.sourcedId === element.sourcedId && element.setUpPBSchoolYear !== this.doNothingLabel) {
          eachParams.archivalInd = false;
        } else {
          eachParams.archivalInd = true;
        }
      }

      const url = new URL(params.endpoint);
      eachParams.domain = url.hostname;

      if (element.setUpPBSchoolYear === this.addLabel) {
        eachParams.isAdd = true;
      } else {
        eachParams.isAdd = false;
      }

      return eachParams;
    });
    params.selectedSchoolYears = [];
    try {
      const resp = await IntegrationServices.refAcademicSessions(params);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          console.log('REF ACADEMIC SESSIONS EXPIRED');
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async updateRefOrgs(params?: any) {
    params = params || {};
    params.orgs = params.orgs.map((element:any) => {
      const eachParams: any = {};
      const options:any = {
        includeScore: true,
        keys: ['sourcedId']
      };
      const fuse = new Fuse(params.selectedOrgs, options);
      const output:any = fuse.search(element.sourcedId);
      eachParams.archivalInd = true;
      if (output.length !== 0) {
        if (output[0].item.sourcedId === element.sourcedId && element.manageMappingPBSchoolName !== this.doNothingLabel) {
          eachParams.archivalInd = false;
        } else {
          eachParams.archivalInd = true;
        }
      }
      //   eachParams.id = element.id;
      eachParams.sourcedId = element.sourcedId;
      eachParams.authorId = params.authorId;
      eachParams.isNew = element.isNew;

      try {
        if (element.manageMappingPBSchoolName.value.schoolId === params.districtId) {
          eachParams.districtId = element.manageMappingPBSchoolName.value.schoolId;
          eachParams.schoolId = 0;
        } else {
          eachParams.schoolId = element.manageMappingPBSchoolName.value.schoolId;
          eachParams.districtId = 0;
        }
      } catch (e) {
        eachParams.districtId = 0;
        eachParams.schoolId = 0;
      }

      const unfinishedFormat = moment(element.syncTime, ['YYYY-MM-DD HH']).format('h:mm A');
      const syncTime = moment(unfinishedFormat, ['h:mm A']).format('HH');
      eachParams.syncTime = syncTime;
      eachParams.endpoint = element.endPointURL;
      eachParams.accessKey = element.accessKey;
      eachParams.secretKey = element.secretKey;
      eachParams.domain = element.domain;

      if (element.manageMappingPBSchoolName === this.addLabel) {
        eachParams.isAdd = true;
      } else {
        eachParams.isAdd = false;
      }

      return eachParams;
    });
    params.selectedOrgs = [];
    try {
      const resp = await IntegrationServices.updateRefOrgs(params);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          console.log('UPDATE REF ORGS EXPIRED');
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async updateRefAcademicSession(params?: any) {
    params = params || {};
    params.schoolYears = params.schoolYears.map((element:any) => {
      const eachParams: any = {};
      eachParams.yearId = element.yearId;
      eachParams.gradingPeriodId = params.gradingPeriodId;
      eachParams.schoolId = params.schoolId;
      eachParams.districtId = params.districtId;
      eachParams.title = element.title;

      if (element.orgSourcedId !== '') {
        eachParams.orgSourcedId = element.orgSourcedId;
      } else {
        eachParams.orgSourcedId = 0;
      }

      // const regExp = /\(([^)]+)\)/;
      // const matches:any = regExp.exec(element.manageMappingPBSchoolYearName);
      // // matches[1] contains the value between the parentheses
      // try {
      //   eachParams.yearId = matches[1];
      // } catch (e) {
      //   eachParams.yearId = 0;
      // }

      const options:any = {
        includeScore: true,
        keys: ['sourcedId']
      };
      const fuse = new Fuse(params.selectedSchoolYears, options);
      const output:any = fuse.search(element.sourcedId);
      eachParams.archivalInd = true;
      if (output.length !== 0) {
        if (output[0].item.sourcedId === element.sourcedId && element.manageMappingPBSchoolYearName !== this.doNothingLabel) {
          eachParams.archivalInd = false;
        } else {
          eachParams.archivalInd = true;
        }
      }

      //   eachParams.id = element.id;
      eachParams.sourcedId = element.sourcedId;
      eachParams.schoolYear = element.schoolYear;
      eachParams.domain = element.domain;
      eachParams.isNew = element.isNew;
      eachParams.withClasses = element.withClasses;
      eachParams.isRowSelectable = element.isRowSelectable;
      if (element.manageMappingPBSchoolYearName === this.addLabel) {
        eachParams.isAdd = true;
      } else {
        eachParams.isAdd = false;
      }

      return eachParams;
    });
    params.selectedSchoolYears = [];
    try {
      const resp = await IntegrationServices.updateRefAcademicSession(params);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          console.log('UPDATE REF ACADEMIC SESSIONS EXPIRED');
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async updateRefUser(params?: any) {
    params = params || {};
    params.teachers = params.teachers.map((element:any) => {
      const eachParams: any = {};
      const options:any = {
        includeScore: true,
        keys: ['sourcedId']
      };
      const fuse = new Fuse(params.selectedUsers, options);
      const output:any = fuse.search(element.sourcedId);
      eachParams.archivalInd = true;
      if (output.length !== 0) {
        if (output[0].item.sourcedId === element.sourcedId && element.manageMappingPBTeacherName !== this.doNothingLabel) {
          eachParams.archivalInd = false;
        } else {
          eachParams.archivalInd = true;
        }
      }

      //   eachParams.id = element.id;
      eachParams.sourcedId = element.sourcedId;
      eachParams.studentId = element.studentId;
      eachParams.domain = element.domain;
      eachParams.orgSourcedId = element.orgSourcedId;
      eachParams.isNew = element.isNew;
      eachParams.orgsSourcedIdArray = element.orgsSourcedIdArray;

      try {
        eachParams.emailAddress = element.manageMappingPBTeacherName.value.teacherName;
        eachParams.teacherId = element.manageMappingPBTeacherName.value.teacherId;
      } catch (e) {
        eachParams.emailAddress = '';
        eachParams.teacherId = 0;
      }
      if (element.manageMappingPBTeacherName === this.addLabel) {
        eachParams.isAdd = true;
      } else {
        eachParams.isAdd = false;
      }

      if (element.duplicate) {
        eachParams.emailAddress = '';
        eachParams.teacherId = 0;
        eachParams.archivalInd = true;
        eachParams.isAdd = false;
      }
      return eachParams;
    });
    params.selectedUsers = [];
    try {
      const resp = await IntegrationServices.updateRefUser(params);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          console.log('UPDATE REF USERS EXPIRED');
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      CommonUtils.hideLoading();
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async sync(params?: any) {
    try {
      params = params || {};
      const resp = await IntegrationServices.sync(params);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          console.log('SYNC NOW EXPIRED');
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async changeRefOrgSettings(params?: any) {
    try {
      params = params || {};
      const resp = await IntegrationServices.changeRefOrgSettings(params);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          console.log('CHANGE REF ORG SETTINGS EXPIRED');
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async getrefOrgsByDistrictId(params?: any) {
    try {
      params = params || {};
      const resp = await IntegrationServices.getrefOrgsByDistrictId(params);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          console.log('GET REF ORGS BY DISTRICT ID EXPIRED');
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          this.context.commit('classlink/setMainOrgJsonObject', data[0], { root: true })
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async getrefOrgsBySchoolId(params?: any) {
    try {
      params = params || {};
      const resp = await IntegrationServices.getrefOrgsBySchoolId(params);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          console.log('GET REF ORGS BY SCHOOL ID EXPIRED');
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          this.context.commit('classlink/setMainOrgJsonObject', data[0], { root: true });
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async getAllRosteredItems() {
    try {
      const userId = this.context.rootGetters['settings/getUserId'];
      if (userId !== this.getAllRosteredData?.teacherId) this.context.commit('clearRosteredData');
      const payload = {
        userMode: this.context.rootGetters['settings/getUserMode'],
        districtId: this.context.rootGetters['settings/getDistrictId'],
        schoolId: this.context.rootGetters['settings/getSchoolId'],
        teacherId: userId,
        teacherEmail: this.context.rootGetters['settings/getUserInfo'].accountSettings.emailAddress,
        lastSyncDate: this.lastSyncDate,
        yearId: CurrentUser.isTeacher ? +this.context.rootGetters['settings/getCurrentSchoolYearId'] : +this.context.rootState.settings.currentYearId
      };
      let resp;
      if (CurrentUser.isTeacher || CurrentUser.isAdmin) {
        resp = await IntegrationServices.getAllRosteredItems(payload);
      } else {
        return Promise.resolve({ data: {} });
      }
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          console.log('GET ALL ROSTERED ITEM EXPIRED');
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          if (CommonUtils.hasText(data.domain)) {
            if (data.lastSyncDate !== this.lastSyncDate && CommonUtils.isNotEmpty(data.allRosteredItems)) {
              this.context.commit('setRosteredData', data);
            } else {
              return Promise.resolve({ data: this.allRosteredData });
            }
          } else if (CommonUtils.hasNoText(data.domain) && CommonUtils.isNotEmpty(this.allRosteredItems)) {
            this.context.commit('clearRosteredData');
          }
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }
}
