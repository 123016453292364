import store from '@/store';
import CommonUtils from '@/utils/common-utils';
import Vue from 'vue';
import vuetify from '@/plugins/vuetify';

const debug = process.env.NODE_ENV !== 'production';

const teacherSteps = [
  {
    intro: `
      <div class="overviewBox">
        <p class="overviewBoxHead">Welcome to Planbook!</p>
        <div class="overviewBoxContent">
            <div style="display:inline-block; margin-top:15px;">
              <img src="https://planbook-assets.s3.amazonaws.com/images/overview/planbook-overview-map.png" style="height: 60px; float: left; margin-right: 20px; clear:both;">
              <p style="vertical-align: middle;">This overview will introduce you to the main features of Planbook. It only takes a few minutes, and is a great way to become familiar with how Planbook works.</p>
            </div>
            <div style="display: inline-block; margin-top:15px;">
              <img src="https://planbook-assets.s3.amazonaws.com/images/overview/planbook-overview-direction.png" style="height: 60px; float: left; margin-right: 20px; clear:both;">
              <p style="vertical-align: middle;">Note that you can also use the <strong>forward and back arrow keys</strong> on your keyboard to navigate through the overview.</p>
            </div>
            <div style="display: inline-block; margin-top:15px;">
              <img src="https://planbook-assets.s3.amazonaws.com/images/overview/planbook-overview-pin.png" style="height: 60px; float: left; margin-right: 20px; clear:both;">
              <p style="vertical-align: middle;">You can exit the overview at any time, and can always view it later by selecting Overview under User Settings.</p>
            </div>
            <div class="introjs-button-nav-container">
              <div class="introjs-button-nav introjs-button-nav-next"><span>Continue >></span></div>
            </div>
        </div>
      </div>
    `
  },
  {
    intro: `
      <div class="overviewBox">
        <p class="overviewBoxHead">Add or Edit Lessons</p>
        <div class="overviewBoxContent">
           <p>To add or edit a lesson, you can either single click on the lesson title (class name) or double click anywhere on the lesson.</p>
           <p style="margin-top:15px;">You can also right click anywhere in a lesson, to quickly perform a wide array of actions, such as copy/paste, bump (move to next day), extend, or delete your lesson.</p>
           <p style="margin-top:15px;">Note that before you add lessons, you’ll want to create your classes (the subjects you teach). Your first class was automatically added when you created your Planbook account, and we’ll show you how to add additional classes later in this overview.</p>
           <div class="introjs-button-nav-container">
              <div class="introjs-button-nav introjs-button-nav-prev"><span>&lt;&lt; Previous</span></div>
              <div class="introjs-button-nav introjs-button-nav-next"><span>Continue >></span></div>
           </div>
        </div>
      </div>
    `
  },
  {
    element: '#filterPlans',
    intro: `
      <div class="overviewBox">
        <p class="overviewBoxHead">View Buttons</p>
        <div class="overviewBoxContent">
           <p>The View Buttons allow you to control the information you see in your plans.</p>
           <ul class="tutorList">
              <li><strong>Timeframe</strong> - View your plans by Day, Week, Month, Cycle or List</li>
              <li><strong>Filters</strong> - Select the classes, lesson status, lesson sections and linked sections to show in your plans.</li>
              <li><strong>Hide/Show </strong> - Quickly hide/show the content of all your lessons.</li>
           </ul>
           <p style="margin-top:15px;">Note that you can also hide/show the contents of <b>individual lessons</b> using the icon at the bottom of each lesson.</p>
           <div class="introjs-button-nav-container">
              <div class="introjs-button-nav introjs-button-nav-prev"><span>&lt;&lt; Previous</span></div>
              <div class="introjs-button-nav introjs-button-nav-next"><span>Continue >></span></div>
           </div>
        </div>
      </div>
    `,
    position: 'right'
  },
  {
    element: '#plansNav',
    intro: `
      <div class="overviewBox">
        <p class="overviewBoxHead">Date Buttons</p>
        <div class="overviewBoxContent">
            <p>There are four buttons in Planbook, to help you navigate to various dates in your plans:</p>
            <ul style="line-height:25px;">
              <li><strong>Arrows</strong> - Takes you to the prior/next day, week or month</li>
              <li><strong>School Year</strong> - Switch between school years in your plans. This is a quick way to view lessons taught in prior years.</li>
              <li><strong>Today</strong> - Return to the current date</li>
              <li><strong>Calendar</strong> - Jump to a specific date in your plans</li>
            </ul>
            <div class="introjs-button-nav-container">
              <div class="introjs-button-nav introjs-button-nav-prev"><span>&lt;&lt; Previous</span></div>
              <div class="introjs-button-nav introjs-button-nav-next"><span>Continue >></span></div>
            </div>
        </div>
      </div>
    `,
    position: 'bottom'
  },
  {
    element: '#actionButtons',
    intro: `
      <div class="overviewBox">
        <p class="overviewBoxHead">Action Buttons</p>
        <div class="overviewBoxContent">
          <p>The Action Buttons allows you to complete actions on your plans.</p>
          <ul style="line-height:25px;">
              <li><strong>Add</strong> - Quickly add items (events, extra lessons, To Do, etc.) to your plans. </li>
              <li><strong>Copy</strong> - Copy/link lessons and other items in your plans. You can copy/link lessons within or between classes in your own school years, as well as from other teachers and your school or district.</li>
              <li><strong>Search</strong> - Search all your lessons for a specific word or phrase. The search can also include prior school years.</li>
              <li><strong>Send To </strong> - You can send your plans to a PDF, Microsoft Word, Google Document, or Google Calendar.</li>
          </ul>
          <div class="introjs-button-nav-container">
              <div class="introjs-button-nav introjs-button-nav-prev"><span>&lt;&lt; Previous</span></div>
              <div class="introjs-button-nav introjs-button-nav-next"><span>Continue >></span></div>
          </div>
        </div>
      </div>
    `,
    position: 'left'
  },
  {
    element: '#account',
    intro: `
      <div class="overviewBox">
        <p class="overviewBoxHead">User Settings</p>
        <div class="overviewBoxContent">
          <p>User Settings allow you to configure your plans to maximize usability. This includes:</p>
          <ul style="line-height:25px;">
              <li><strong>Display Settings</strong> - Customize the look (fonts, colors, etc.) of each component in your plans.</li>
              <li><strong>Lesson Layouts</strong> - Build unique layouts (lesson outlines) for all or individual classes.</li>
              <li><strong>Sharing Options</strong> - Set permissions to allow various groups to view your plans, and to allow colleagues to collaborate with you on classes and lessons.</li>
          </ul>
          <div class="introjs-button-nav-container">
              <div class="introjs-button-nav introjs-button-nav-prev"><span>&lt;&lt; Previous</span></div>
              <div class="introjs-button-nav introjs-button-nav-next"><span>Continue >></span></div>
          </div>
        </div>
      </div>
    `,
    position: 'left'
  },
  {
    element: '#reminders',
    intro: `
      <div class="overviewBox">
        <p class="overviewBoxHead">Reminders</p>
        <div class="overviewBoxContent">
          <p>The Reminders Panel is a great way to keep track of upcoming work. You can include student birthdays, assignments due, team meetings, tasks to complete, and more!</p>
          <p style="margin-top:15px;">Reminders will show all items due in the next 7 days, as well as any high priority To Do tasks that are not yet completed. You can filter your reminders to include: Assessments, Assignments, Birthdays, Events, Notes, and To Do.</p>
          <div class="introjs-button-nav-container">
              <div class="introjs-button-nav introjs-button-nav-prev"><span>&lt;&lt; Previous</span></div>
              <div class="introjs-button-nav introjs-button-nav-next"><span>Continue >></span></div>
          </div>
        </div>
      </div>
    `,
    position: 'left'
  },
  {
    element: '#attendance',
    intro: `
      <div class="overviewBox">
        <p class="overviewBoxHead">Attendance</p>
        <div class="overviewBoxContent">
          <p>The Attendance page allows you to track daily attendance for your students for the entire day, or for individual classes.</p>
          <p style="margin-top:15px;">You can quickly set attendance for all students at once, as well as individually mark each student as Present, Absent or Tardy.</p>
          <p style="margin-top:15px;">Attendance information can also be included in Student Performance Reports.</p>
          <div class="introjs-button-nav-container">
              <div class="introjs-button-nav introjs-button-nav-prev"><span>&lt;&lt; Previous</span></div>
              <div class="introjs-button-nav introjs-button-nav-next"><span>Continue >></span></div>
          </div>
        </div>
      </div>
    `,
    position: 'right',
    drawer: 'left'
  },
  {
    element: '#classes',
    intro: `
      <div class="overviewBox">
        <p class="overviewBoxHead">Classes</p>
        <div class="overviewBoxContent">
          <p>The Classes page allows you to adjust your class schedule for the current school year. You can add new classes, make a change to an existing class, or remove a class.</p>
          <p style="margin-top:15px;">Adding classes (subjects) to your plans is how you create your schedule in Planbook, and you’ll need to do this before you can enter lessons.</p>
          <p style="margin-top:15px;">For each class, you can select a class color, specify the class start and end date, and enter the days and times you teach the class.</p>
          <p style="margin-top:15px;"><a href="https://planbook.uservoice.com/knowledgebase/articles/1919602-setting-up-your-classes-schedule-view" target="_blank" style="color:#58595b;">Click here</a> to learn more about how to schedule classes!</p>
          <div class="introjs-button-nav-container">
              <div class="introjs-button-nav introjs-button-nav-prev"><span>&lt;&lt; Previous</span></div>
              <div class="introjs-button-nav introjs-button-nav-next"><span>Continue >></span></div>
          </div>
        </div>
      </div>
    `,
    position: 'right',
    drawer: 'left'
  },
  {
    element: '#classwork',
    intro: `
      <div class="overviewBox">
        <p class="overviewBoxHead">Classwork</p>
        <div class="overviewBoxContent">
          <p>The Classwork page allows you to manage the assignments and assessments for your classes. You can select to view all of your classwork, all classwork for a single class, or all classwork for a single class unit.</p>
          <p style="margin-top:15px;">Classwork allows you to track student performance in your classroom, and can be used to generate Performance Reports to share with students and parents.</p>
          <div class="introjs-button-nav-container">
              <div class="introjs-button-nav introjs-button-nav-prev"><span>&lt;&lt; Previous</span></div>
              <div class="introjs-button-nav introjs-button-nav-next"><span>Continue >></span></div>
          </div>
        </div>
      </div>
    `,
    position: 'right',
    drawer: 'left'
  },
  {
    element: '#events',
    intro: `
      <div class="overviewBox">
        <p class="overviewBoxHead">Events</p>
        <div class="overviewBoxContent">
          <p>Events in Planbook are similar to most calendar systems, and provide a way to capture activities not directly tied to your classes and lessons. You may want to include events for team meetings, student birthdays, etc.  You can add stickers to your special events, too!</p>
          <p style="margin-top:15px;">Planbook events also allow you to set specific dates as <strong style="white-space: nowrap;">No School</strong> or <strong>Alternate Schedule</strong> days, which will then automatically adjust your daily class schedule accordingly. Your events will show in your Week, Day and Month plan views, as well as in your Reminders panel.</p>
          <p style="margin-top:15px;">Your school or district can also create shared events for all teachers.</p>
          <div class="introjs-button-nav-container">
              <div class="introjs-button-nav introjs-button-nav-prev"><span>&lt;&lt; Previous</span></div>
              <div class="introjs-button-nav introjs-button-nav-next"><span>Continue >></span></div>
          </div>
        </div>
      </div>
    `,
    position: 'right',
    drawer: 'left'
  },
  {
    element: '#grades',
    intro: `
      <div class="overviewBox">
        <p class="overviewBoxHead">Grades</p>
        <div class="overviewBoxContent">
          <p>The Grades page allows you to capture student performance on each assignment and assessment.</p>
          <p style="margin-top:15px;">You can customize the score weighting, type of assignments, and score weight percentage. Grade reports may also be made under the “reporting page” You can also define and track performance on standards, to measure if your students are meeting standards-based learning targets.</p>
          <div class="introjs-button-nav-container">
              <div class="introjs-button-nav introjs-button-nav-prev"><span>&lt;&lt; Previous</span></div>
              <div class="introjs-button-nav introjs-button-nav-next"><span>Continue >></span></div>
          </div>
        </div>
      </div>
    `,
    position: 'right',
    drawer: 'left'
  },
  {
    element: '#lesson-banks',
    intro: `
      <div class="overviewBox">
        <p class="overviewBoxHead">Lesson Banks</p>
        <div class="overviewBoxContent">
          <p>Lesson Banks provide a way to capture lessons and units that are independent of any specific class or dates. Banks can be used for planning purposes, to build your curriculum without knowing exactly when you’ll choose to insert it into your lesson schedule.</p>
          <p style="margin-top:15px;">Banks can also provide a central repository for your classes, units, and lessons. Rather than replicating lessons into each class and year of your plans, you can store the lessons once in a bank, and then simply link them into your schedule as needed.</p>
          <div class="introjs-button-nav-container">
              <div class="introjs-button-nav introjs-button-nav-prev"><span>&lt;&lt; Previous</span></div>
              <div class="introjs-button-nav introjs-button-nav-next"><span>Continue >></span></div>
          </div>
        </div>
      </div>
    `,
    position: 'right',
    drawer: 'left'
  },
  {
    element: '#messages',
    intro: `
      <div class="overviewBox">
        <p class="overviewBoxHead">Messages</p>
        <div class="overviewBoxContent">
          <p>Messages allow two-way communication between teachers and administrators, as well as between teachers and students.   A notification icon will appear when a message has been received.</p>
          <div class="introjs-button-nav-container">
              <div class="introjs-button-nav introjs-button-nav-prev"><span>&lt;&lt; Previous</span></div>
              <div class="introjs-button-nav introjs-button-nav-next"><span>Continue >></span></div>
          </div>
        </div>
      </div>
    `,
    position: 'right',
    drawer: 'left'
  },
  {
    element: '#notes-todo',
    intro: `
      <div class="overviewBox">
        <p class="overviewBoxHead">Notes & To Do</p>
        <div class="overviewBoxContent">
          <p>Notes provide a simple way to capture information tied to individual students or classes. For students, you may want to record student behavior, strengths, and challenges to help prepare for conferences. For classes, you could add reflections on unit or lesson effectiveness, ideas for next year, etc.</p>
          <p style="margin-top:15px;">And we encourage you to try out our <strong>repeating To Do</strong> feature!  For those tasks that you perform each week (or even each day) you can add a repeating To Do. These will show in your reminders panel, until you mark as complete.</p>
          <div class="introjs-button-nav-container">
              <div class="introjs-button-nav introjs-button-nav-prev"><span>&lt;&lt; Previous</span></div>
              <div class="introjs-button-nav introjs-button-nav-next"><span>Continue >></span></div>
          </div>
        </div>
      </div>
    `,
    position: 'right',
    drawer: 'left'
  },
  {
    element: '#reporting',
    intro: `
      <div class="overviewBox">
        <p class="overviewBoxHead">Reporting</p>
        <div class="overviewBoxContent">
          <p>Reporting allows you to track the learning standards covered in your lessons.</p>
          <p style="margin-top:15px;">Reports are generated by including the national standards, subject, and date range of your lessons, for a record of the number of times the standard was taught.</p>
          <div class="introjs-button-nav-container">
              <div class="introjs-button-nav introjs-button-nav-prev"><span>&lt;&lt; Previous</span></div>
              <div class="introjs-button-nav introjs-button-nav-next"><span>Continue >></span></div>
          </div>
        </div>
      </div>
    `,
    position: 'right',
    drawer: 'left'
  },
  {
    element: '#resources',
    intro: `
      <div class="overviewBox">
        <p class="overviewBoxHead">Resources</p>
        <div class="overviewBoxContent">
          <p>Planbook allows you to upload any number of <strong>files</strong> (videos, documents, etc) to your account, and then attach these files to your individual lessons, units, events, and classwork. To simplify storage and retrieval, files can also be grouped into <strong>folders</strong> on your Resources page.</p>
          <p style="margin-top:15px;">Planbook also allows you to attach files directly from your Google drive into your lesson plans.</p>
          <div class="introjs-button-nav-container">
              <div class="introjs-button-nav introjs-button-nav-prev"><span>&lt;&lt; Previous</span></div>
              <div class="introjs-button-nav introjs-button-nav-next"><span>Continue >></span></div>
          </div>
        </div>
      </div>
    `,
    position: 'right',
    drawer: 'left'
  },
  {
    element: '#school-years',
    intro: `
      <div class="overviewBox">
        <p class="overviewBoxHead">School Year</p>
        <div class="overviewBoxContent">
          <p>A school year in Planbook will typically map directly to your contracted year, but you can also create school years for summer school, after school programs, or anything else you need.  For each school year, you will specify the schedule type (weekly, two-week, cycle), year start and end dates, and school start and end times.</p>
          <p style="margin-top:15px;">Note that your school or district may also pre-load your account with each school year. </p>
          <div class="introjs-button-nav-container">
              <div class="introjs-button-nav introjs-button-nav-prev"><span>&lt;&lt; Previous</span></div>
              <div class="introjs-button-nav introjs-button-nav-next"><span>Continue >></span></div>
          </div>
        </div>
      </div>
    `,
    position: 'right',
    drawer: 'left'
  },
  {
    element: '#seating-charts',
    intro: `
      <div class="overviewBox">
        <p class="overviewBoxHead">Seating Charts</p>
        <div class="overviewBoxContent">
          <p>Seating Charts allow you to add room layouts for the rooms you teach in and create seating charts for your students.</p>
          <!-- <p style="margin-top:15px;">Administrators are able to view seating charts for all teachers in their school.</p> -->
          <div class="introjs-button-nav-container">
              <div class="introjs-button-nav introjs-button-nav-prev"><span>&lt;&lt; Previous</span></div>
              <div class="introjs-button-nav introjs-button-nav-next"><span>Continue >></span></div>
          </div>
        </div>
      </div>
    `,
    position: 'right',
    drawer: 'left'
  },
  {
    element: '#manage-students',
    intro: `
      <div class="overviewBox">
        <p class="overviewBoxHead">Students</p>
        <div class="overviewBoxContent">
          <p>This will allow you to add students to your account, as well as assign students to your classes. For administrators, this view will allow you to add students to your school, and assign students to your teachers.  This step is required to record student grades, seating charts, and attendance in Planbook.</p>
          <p style="margin-top:15px;">Once students are added, you can then allow them to access Planbook via their accounts, to view teacher lesson plans and student performance.</p>
          <div class="introjs-button-nav-container">
              <div class="introjs-button-nav introjs-button-nav-prev"><span>&lt;&lt; Previous</span></div>
              <div class="introjs-button-nav introjs-button-nav-next"><span>Continue >></span></div>
          </div>
        </div>
      </div>
    `,
    position: 'right',
    drawer: 'left'
  },
  {
    element: '#templates',
    intro: `
      <div class="overviewBox">
        <p class="overviewBoxHead">Templates</p>
        <div class="overviewBoxContent">
          <p>Templates allow you to add repeating information to your plans (e.g. "Spelling Quiz" each Friday for your Spelling class) to save you from entering this information into each lesson.</p>
          <div class="introjs-button-nav-container">
              <div class="introjs-button-nav introjs-button-nav-prev"><span>&lt;&lt; Previous</span></div>
              <div class="introjs-button-nav introjs-button-nav-next"><span>Continue >></span></div>
          </div>
        </div>
      </div>
    `,
    position: 'right',
    drawer: 'left'
  },
  {
    element: '#units',
    intro: `
      <div class="overviewBox">
        <p class="overviewBoxHead">Units</p>
        <div class="overviewBoxContent">
          <p>Units provide a way to structure and group your lessons, assignments, and assessments, and help facilitate long term planning efforts.</p>
          <div class="introjs-button-nav-container">
              <div class="introjs-button-nav introjs-button-nav-prev"><span>&lt;&lt; Previous</span></div>
              <div class="introjs-button-nav introjs-button-nav-next"><span>Continue >></span></div>
          </div>
        </div>
      </div>
    `,
    position: 'right',
    drawer: 'left'
  },
  {
    intro: `
      <div class="overviewBox">
        <p class="overviewBoxHead">Thanks for watching!</p>
        <div class="overviewBoxContent">
          <p>For more detailed explanations and support, visit <a target="_blank" href="https://planbook.uservoice.com/knowledgebase" style="color:#58595b;">Planbook's Knowledge Base</a></p>
          <p style="margin-top:20px;">If you have any questions, please contact us at <br/>888-205-5528 or support@planbook.com.</p>
          <p style="margin-top:20px;">And please join us on Social Media!</p>
          <p style="margin-top:20px; margin-bottom:20px;"><a href="http://www.facebook.com/planbookcom" target="_blank" title="Follow us on Facebook" style="margin-left:10px; margin-right:30px; cursor:pointer; color:#58595b;"><i class="fab fa-facebook-f" style="font-size:14pt;"></i></a><a href="http://www.twitter.com/planbookcom" target="_blank" title="Follow us on Twitter" style="margin-right:30px; cursor:pointer; color:#58595b;"><i class="fab fa-twitter" style="font-size:14pt;"></i></a><a href="http://www.instagram.com/planbookcom" target="_blank" title="Follow us on Instagram" style="margin-right:30px; cursor:pointer; color:#58595b;"><i class="fab fa-instagram" style="font-size:14pt;"></i></a></p>
          <div class="introjs-button-nav-container">
             <div class="introjs-button-nav introjs-button-nav-prev"><span>&lt;&lt; Previous</span></div>
          </div>
        </div>
      </div>
    `
  }
];

const adminSteps = [
  {
    intro: '<div class="overviewBox"><p class="overviewBoxHead">Administrators - Welcome to Planbook!</p><div class="overviewBoxContent"><div style="display:inline-block; margin-top:15px;"><img src="https://planbook-assets.s3.amazonaws.com/images/overview/planbook-overview-map.png" style="height: 60px; float: left; margin-right: 20px; clear:both;"><p style="vertical-align: middle;">This overview will introduce you to the main features of Planbook. It only takes a few minutes, and is a great way to become familiar with how Planbook works.</p></div><div style="display: inline-block; margin-top:15px;"><img src="https://planbook-assets.s3.amazonaws.com/images/overview/planbook-overview-direction.png" style="height: 60px; float: left; margin-right: 20px; clear:both;"><p style="vertical-align: middle;">Note that you can also use the forward and back arrow keys on your keyboard to navigate through the overview.</p></div><div style="display: inline-block; margin-top:15px;"><img src="https://planbook-assets.s3.amazonaws.com/images/overview/planbook-overview-pin.png" style="height: 60px; float: left; margin-right: 20px; clear:both;"><p style="vertical-align: middle;">You can exit the overview at any time, and can always view it later by clicking on your display name (upper right corner) and selecting Overview.</p></div><div class="introjs-button-nav-container"><div class="introjs-button-nav introjs-button-nav-next"><span>Continue >></span></div></div></div></div>'
  },
  {
    element: '#filterPlans',
    intro: `
      <div class="overviewBox">
        <p class="overviewBoxHead">View Buttons</p>
        <div class="overviewBoxContent">
           <p>The View Buttons allow you to control the information you see in your plans.</p>
           <ul class="tutorList">
              <li><strong>Timeframe</strong> - View your plans by Day, Week, Month, Cycle or List</li>
              <li><strong>Filters</strong> - Select the classes, lesson status, lesson sections and linked sections to show in your plans.</li>
              <li><strong>Hide/Show </strong> - Quickly hide/show the content of all your lessons.</li>
           </ul>
           <p style="margin-top:15px;">Note that you can also hide/show the contents of <b>individual lessons</b> using the icon at the bottom of each lesson.</p>
           <div class="introjs-button-nav-container">
              <div class="introjs-button-nav introjs-button-nav-prev"><span>&lt;&lt; Previous</span></div>
              <div class="introjs-button-nav introjs-button-nav-next"><span>Continue >></span></div>
           </div>
        </div>
      </div>
    `,
    position: 'right'
  },
  {
    element: '#plansNav',
    intro: `
      <div class="overviewBox">
        <p class="overviewBoxHead">Date Buttons</p>
        <div class="overviewBoxContent">
            <p>The Date Buttons allow you to navigate between dates in teacher plans.</p>
            <ul style="line-height:25px;">
              <li><strong>Arrows</strong> - Takes you to the prior/next day, week or month</li>
              <li><strong>School Year</strong> - Switch between school years in your plans. This is a quick way to view lessons taught in prior years.</li>
              <li><strong>Today</strong> - Return to the current date</li>
              <li><strong>Calendar</strong> - Jump to a specific date in your plans</li>
            </ul>
            <div class="introjs-button-nav-container">
              <div class="introjs-button-nav introjs-button-nav-prev"><span>&lt;&lt; Previous</span></div>
              <div class="introjs-button-nav introjs-button-nav-next"><span>Continue >></span></div>
            </div>
        </div>
      </div>
    `,
    position: 'bottom'
  },
  {
    element: '#actionButtons',
    intro: `
      <div class="overviewBox">
        <p class="overviewBoxHead">Action Buttons</p>
        <div class="overviewBoxContent">
          <p>The Action Buttons allow you to complete actions on teacher plans.</p>
          <ul style="line-height:25px;">
              <li><strong>Apply Tags</strong> - You can define lesson "tags", and use this button to apply the tags to teacher plans. By default, Planbook includes a "Reviewed" tag, to allow administrators to notify teachers when weekly plans have been reviewed. </li>
              <li><strong>Send To</strong> - You can send plans to a PDF, Microsoft Word, Google Document, or Google Calendar.</li>
          </ul>
          <div class="introjs-button-nav-container">
              <div class="introjs-button-nav introjs-button-nav-prev"><span>&lt;&lt; Previous</span></div>
              <div class="introjs-button-nav introjs-button-nav-next"><span>Continue >></span></div>
          </div>
        </div>
      </div>
    `,
    position: 'left'
  },
  {
    element: '#account',
    intro: `
      <div class="overviewBox">
        <p class="overviewBoxHead">User Settings</p>
        <div class="overviewBoxContent">
          <p>User Settings allow you to configure your plans to maximize usability. This includes:</p>
          <ul style="line-height:25px;">
              <li><strong>Display Settings</strong> - Customize the look (fonts, colors, etc.) of each component in your account.</li>
              <li><strong>Lesson Layouts</strong> - Build unique layouts (lesson outlines) for teachers to use in their plans.</li>
              <li><strong>Sharing Options</strong> - Set permissions to allow teachers to view and collaborate on school curriculum (lesson banks).</li>
          </ul>
          <div class="introjs-button-nav-container">
              <div class="introjs-button-nav introjs-button-nav-prev"><span>&lt;&lt; Previous</span></div>
              <div class="introjs-button-nav introjs-button-nav-next"><span>Continue >></span></div>
          </div>
        </div>
      </div>
    `,
    position: 'left'
  },
  {
    element: '.teacher-list-content',
    intro: '<div class="overviewBox"><p class="overviewBoxHead">Teacher Bar</p><div class="overviewBoxContent"><p>The Teacher Bar allows you to view individual teacher lesson plans.</p><p style="margin-top:15px;">To view a teacher, simply select his or her icon from the list. When you sign in to your account, the first teacher in your list will display automatically.</p><div class="introjs-button-nav-container"><div class="introjs-button-nav introjs-button-nav-prev"><span>&lt;&lt; Previous</span></div><div class="introjs-button-nav introjs-button-nav-next"><span>Continue >></span></div></div></div></div>',
    position: 'right'
  },
  {
    element: '#events',
    intro: '<div class="overviewBox"><p class="overviewBoxHead">Events</p><div class="overviewBoxContent"><p>Events in Planbook are similar to most calendar systems, and provide a way to capture activities not directly tied to classes or lessons. This can include school holidays, planning days, team meetings, snow days, etc.</p><p style="margin-top:15px;">School Events will automatically show in teacher plans, and teachers will be notified if any school event (such as a new No School day) will alter their lesson plans.</p><div class="introjs-button-nav-container"><div class="introjs-button-nav introjs-button-nav-prev"><span>&lt;&lt; Previous</span></div><div class="introjs-button-nav introjs-button-nav-next"><span>Continue >></span></div></div></div></div>',
    position: 'right',
    drawer: 'left'
  },
  {
    element: '#grades',
    intro: '<div class="overviewBox"><p class="overviewBoxHead">Grades</p><div class="overviewBoxContent"><p>Grades allow you to view the performance of the students in your school. Grades are broken down by teacher for each student, as well as by grading periods.</p><p style="margin-top:15px;">You can also create Performance Reports for your students, which can include grades by teacher, class and individual assignments/assessments, learning standard achievements, and attendance information.</p><div class="introjs-button-nav-container"><div class="introjs-button-nav introjs-button-nav-prev"><span>&lt;&lt; Previous</span></div><div class="introjs-button-nav introjs-button-nav-next"><span>Continue >></span></div></div></div></div>',
    position: 'right',
    drawer: 'left'
  },
  {
    element: '#lesson-banks',
    intro: '<div class="overviewBox"><p class="overviewBoxHead">Lesson Banks</p><div class="overviewBoxContent"><p>Lesson Banks provide an easy way to coordinate and manage your curriculum. Subjects, with unit and lesson detail, can be stored in banks, and schools have full control over who will be able to access this information (view or edit).</p><p style="margin-top:15px;">Teachers who have access to a lesson bank can either COPY the information into their plans, or LINK to the lesson bank detail. A copy creates a brand new information set for the teacher, while a link keeps the teacher connected to the primary lesson bank, such that any future changes will show automatically in the teacher\'s plans.</p><div class="introjs-button-nav-container"><div class="introjs-button-nav introjs-button-nav-prev"><span>&lt;&lt; Previous</span></div><div class="introjs-button-nav introjs-button-nav-next"><span>Continue >></span></div></div></div></div>',
    position: 'right',
    drawer: 'left'
  },
  {
    element: '#messages',
    intro: `
      <div class="overviewBox">
        <p class="overviewBoxHead">Messages</p>
        <div class="overviewBoxContent">
          <p>Messages allows for quick communication between teachers and administrators, as well as between teachers and students. A notification icon will appear when a message has been received.</p>
          <div class="introjs-button-nav-container">
              <div class="introjs-button-nav introjs-button-nav-prev"><span>&lt;&lt; Previous</span></div>
              <div class="introjs-button-nav introjs-button-nav-next"><span>Continue >></span></div>
          </div>
        </div>
      </div>
    `,
    position: 'right',
    drawer: 'left'
  },
  {
    element: '#reporting',
    intro: `
      <div class="overviewBox">
        <p class="overviewBoxHead">Reporting</p>
        <div class="overviewBoxContent">
          <p>Reporting allows you to track performance in the following areas:</p>
          <ul style="line-height:25px;">
              <li><strong>Plans</strong> - Monitor lesson plan submissions, to ensure all teachers have plans completed when due each week. Plan submits can be viewed by individual week, or as a yearly summary.</li>
              <li><strong>Standards</strong> - Measure coverage of student learning standards, to ensure standards aligned instruction. Coverage can be viewed by standard framework, subject, and grade level.</li>
          </ul>
          <div class="introjs-button-nav-container">
              <div class="introjs-button-nav introjs-button-nav-prev"><span>&lt;&lt; Previous</span></div>
              <div class="introjs-button-nav introjs-button-nav-next"><span>Continue >></span></div>
          </div>
        </div>
      </div>
    `,
    position: 'right',
    drawer: 'left'
  },
  {
    element: '#school-years',
    intro: '<div class="overviewBox"><p class="overviewBoxHead">School Years</p><div class="overviewBoxContent"><p>School Years allow you to define school years for your school or district, which teachers can then select to use in their plans. This ensures that all teachers have a consistent school year, including first and last day for students, schedule type (one week, two week, cycle), and school day start and end times.</p><p style="margin-top:15px;">You can also include Grading Periods (quarters, semesters, etc.) and Grading Scales for teachers who track student performance in Planbook.</p><div class="introjs-button-nav-container"><div class="introjs-button-nav introjs-button-nav-prev"><span>&lt;&lt; Previous</span></div><div class="introjs-button-nav introjs-button-nav-next"><span>Continue >></span></div></div></div></div>',
    position: 'right',
    drawer: 'left'
  },
  {
    element: '#manage-students',
    intro: '<div class="overviewBox"><p class="overviewBoxHead">Students</p><div class="overviewBoxContent"><p>Including students in Planbook allows them to become more actively engaged in the learning process. Students can view plans, check grades, download assignments, and communicate with teachers.</p><p style="margin-top:15px;">Administrators are able to add or import students (including automated rostering), assign students to teachers, promote students to new grade levels, and monitor student performance.</p><div class="introjs-button-nav-container"><div class="introjs-button-nav introjs-button-nav-prev"><span>&lt;&lt; Previous</span></div><div class="introjs-button-nav introjs-button-nav-next"><span>Continue >></span></div></div></div></div>',
    position: 'right',
    drawer: 'left'
  },
  {
    element: '#teachers',
    intro: `
      <div class="overviewBox">
        <p class="overviewBoxHead">Teachers</p>
        <div class="overviewBoxContent">
          <p>The Teachers page allows you to manage your teachers. You can:</p>
          <ul style="line-height:25px;">
              <li>Add teachers, via Email Invites or a Join Code</li>
              <li>Remove teachers from your school account</li>
              <li>Reset teacher account passwords</li>
              <li>Copy School Years between teacher accounts</li>
          </ul>
          <div class="introjs-button-nav-container">
              <div class="introjs-button-nav introjs-button-nav-prev"><span>&lt;&lt; Previous</span></div>
              <div class="introjs-button-nav introjs-button-nav-next"><span>Continue >></span></div>
          </div>
        </div>
      </div>
    `,
    position: 'right',
    drawer: 'left'
  },
  {
    element: '#templates',
    intro: '<div class="overviewBox"><p class="overviewBoxHead">Templates</p><div class="overviewBoxContent"><p>Templates allow you to take the structure of lesson layouts to another level, and provide specific outlines for each lesson section, helping to ensure that all key information is captured and presented in a consistent manner.</p><p style="margin-top:15px;">Templates also save time, allowing you to enter information only once, and have it repeated for any or all lessons as appropriate.</p><div class="introjs-button-nav-container"><div class="introjs-button-nav introjs-button-nav-prev"><span>&lt;&lt; Previous</span></div><div class="introjs-button-nav introjs-button-nav-next"><span>Continue >></span></div></div></div></div>',
    position: 'right',
    drawer: 'left'
  },
  {
    intro: '<div class="overviewBox"><p class="overviewBoxHead">Thanks for watching!</p><div class="overviewBoxContent"><p>To learn more, please access our <a target="_blank" href="https://planbook.uservoice.com/knowledgebase" style="color:#58595b;">Knowledge Base</a>.</p><p style="margin-top:20px;">If you have any questions, please contact us at <br/>888-205-5528 or support@planbook.com.</p><p style="margin-top:20px;">And please join us on Social Media!</p><p style="margin-top:20px; margin-bottom:20px;"><a href="http://www.facebook.com/planbookcom" target="_blank" title="Follow us on Facebook" style="margin-left:10px; margin-right:30px; cursor:pointer; color:#58595b;"><i class="fab fa-facebook-f" style="font-size:14pt;"></i></a><a href="http://www.twitter.com/planbookcom" target="_blank" title="Follow us on Twitter" style="margin-right:30px; cursor:pointer; color:#58595b;"><i class="fab fa-twitter" style="font-size:14pt;"></i></a><a href="http://www.instagram.com/planbookcom" target="_blank" title="Follow us on Instagram" style="margin-right:30px; cursor:pointer; color:#58595b;"><i class="fab fa-instagram" style="font-size:14pt;"></i></a></p><div class="introjs-button-nav-container"><div class="introjs-button-nav introjs-button-nav-prev"><span>&lt;&lt; Previous</span></div></div></div></div>'
  }
]

declare const introJs: () => any;
declare const window: any;

class OverviewServices {
  intro: any;

  init() {
    store.commit('drawer/setDrawerRightMode', 'account', { root: true });
    this.intro.onbeforechange(() => {
      const step = this.getCurrentStep();

      store.commit('drawer/setDrawerRightStateless', true, { root: true });
      store.commit('drawer/setDrawerLeftStateless', true, { root: true });
      store.commit('drawer/setDrawerLeft', false, { root: true });
      store.commit('drawer/setDrawerRight', false, { root: true });

      try {
        const introContainer = document.querySelector('.introjs-tooltip') as HTMLElement;
        const introArrow = introContainer.querySelector('.introjs-arrow') as HTMLElement;
        introArrow.style.top = '';
        introArrow.style.left = '';
        introArrow.style.marginLeft = '';
        introContainer.style.top = '';
      } catch (e) {
      }

      if (step !== 6) {
        window.scrollTo(0, 0);
      }
      if (CommonUtils.hasText(step.drawer) && step.drawer === 'left') {
        const targetElement = this.getCurrentTargetElement() as HTMLElement;
        if (this.intro._currentStep >= 14) {
          targetElement?.scrollIntoView();
        } else {
          const gotoItemsElement = document.querySelector('#goto-items') as HTMLElement;
          gotoItemsElement.scrollTop = 0;
        }
      }

      Vue.nextTick(() => {
        new Promise(resolve => setTimeout(resolve, 10)).then(() => {
          if (CommonUtils.hasText(step.drawer)) {
            if (step.drawer === 'right') {
              store.commit('drawer/setDrawerLeft', false, { root: true });
              store.commit('drawer/setDrawerRight', true, { root: true });
            } else if (step.drawer === 'left') {
              store.commit('drawer/setDrawerRight', false, { root: true });
              store.commit('drawer/setDrawerLeft', true, { root: true });
            }
          }
        });
      });
    });

    this.intro.onafterchange((t: any) => {
      if (t) {
        Vue.nextTick(() => {
          new Promise(resolve => setTimeout(resolve, 500)).then(() => {
            const step = this.getCurrentStep();
            const introContainer = document.querySelector('.introjs-tooltip') as HTMLElement;
            const introArrow = introContainer.querySelector('.introjs-arrow') as HTMLElement;
            if (t.id === 'plansNav') {
              if (introContainer.classList.contains('introjs-floating')) {
                introContainer.style.marginTop = '44px';
                introArrow.classList.remove('right');
                introArrow.classList.remove('left');
                introArrow.classList.remove('bottom');
                introArrow.classList.add('top');
                introArrow.style.display = 'inherit';
                introArrow.style.top = '-15px';
                introArrow.style.marginLeft = '45%';
              }
            } else if (step.drawer === 'left') {
              const topOffset = introContainer?.getBoundingClientRect().top
              if (topOffset < 0 && introArrow.classList.contains('left-bottom')) {
                introArrow.classList.remove('left-bottom');
                introArrow.classList.add('left');
                introArrow.style.top = '80px';
                introContainer.style.top = '-70px';
              }
            }
          });
        })
      }
    })

    this.intro.oncomplete((e: HTMLElement) => {
      console.log(e);
    });

    this.intro.onexit(() => {
      const gotoItemsElement = document.querySelector('#goto-items') as HTMLElement;
      gotoItemsElement.scrollTop = 0;
      store.commit('drawer/setDrawerRightStateless', false, { root: true });
      store.commit('drawer/setDrawerLeftStateless', false, { root: true });
      store.commit('drawer/setDrawerLeft', false, { root: true });
      store.commit('drawer/setDrawerRight', false, { root: true });
    });
  }

  getCurrentStep() {
    return this.intro._introItems[this.intro._currentStep] || { ignore: true };
  }

  getCurrentTargetElement() {
    return this.getCurrentStep().element;
  }

  start() {
    this.intro = introJs();
    this.intro.setOptions({
      keyboardNavigation: false
    })
    this.init();
    const userMode = store.state.settings.userMode;
    if (userMode === 'T') {
      this.intro.setOptions({
        skipLabel: 'Exit Overview',
        doneLabel: 'Exit Overview',
        hidePrev: true,
        hideNext: true,
        disableInteraction: true,
        exitOnOverlayClick: false,
        keyboardNavigation: false,
        showBullets: false,
        steps: [
          {
            intro: `<div class="overviewBox"><p class="overviewBoxHead">How would you like to get started?</p><div class="overviewBoxContent start"><div class="overview-thumbs introjs-button-action introjs-button-class-tutorial"><h4 style="color: ${vuetify.framework.theme.currentTheme.primary};">Video guide</h4><img src="https://planbook-assets.s3.amazonaws.com/images/overview/planbook-overview-video-4.png" alt="Show me how to get started" width="244" height="146"/><p><a class="overview-thumbs" href="javascript:void(0)">Show me how to get started >></a></p></div><div class="overview-thumbs introjs-button-action introjs-continue-overview"><h4 style="color: ${vuetify.framework.theme.currentTheme.primary}">Take the tour</h4><img src="https://planbook-assets.s3.amazonaws.com/images/overview/planbook-overview-tour.png" alt="Show me an overview of the platform" width="244" height="146"><p><a class="overview-thumbs" href="javascript:void(0)">Show me an overview of the platform >></a></p></div></div></div>`,
            ignore: true,
            ignoreExit: true
          }
        ]
      });
      this.intro.start();
      (document.querySelector('.introjs-tooltiptext') as HTMLElement).style.minHeight = '150px';
      (document.querySelector('.introjs-tooltipbuttons > a.introjs-skipbutton') as HTMLElement).style.display = 'none';
      (document.querySelector('.introjs-tooltipbuttons') as HTMLElement).prepend(this.createExitElement());
      (document.querySelector('.introjs-tooltip.introjs-floating') as HTMLElement).onclick = this.onClick.bind(this);
    } else {
      this.continue();
    }
  }

  startVideo() {
    this.intro.exit();
    this.intro = introJs();
    this.init();
    this.intro.setOptions({
      skipLabel: 'Exit Overview',
      doneLabel: 'Exit Overview',
      keyboardNavigation: false,
      hidePrev: true,
      hideNext: true,
      disableInteraction: true,
      exitOnOverlayClick: false,
      showBullets: false,
      steps: [
        {
          intro: `<div class="overviewBox">
                    <p class="overviewBoxHead">Getting Started</p>
                    <div style="height: 400px;">
                      <iframe style="width: calc(100% - 10px); border-style: hidden; border-width: 0px; height: calc(100% - 10px); padding: 5px;" src="https://www.youtube.com/embed/BwIXPR1sqM0?autoplay=1&muted=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                  </div>`,
          ignore: true,
          ignoreExit: true
        }
      ]
    });
    this.intro.start();
    (document.querySelector('.introjs-tooltiptext') as HTMLElement).style.minHeight = '150px';
    (document.querySelector('.introjs-tooltipbuttons > a.introjs-skipbutton') as HTMLElement).style.display = 'none';
    (document.querySelector('.introjs-tooltipbuttons') as HTMLElement).prepend(this.createExitElement());
    (document.querySelector('.introjs-tooltip.introjs-floating') as HTMLElement).onclick = this.onClick.bind(this);
  }

  createExitElement() {
    const el = document.createElement('div');
    el.classList.add('introjs-button-skip');
    el.classList.add('introjs-button-action');
    el.innerHTML = '<img src="https://planbook-assets.s3.amazonaws.com/images/overview/planbook-overview-exit-icon.png" height="36px"><span>Exit Overview</span>';
    return el;
  }

  continue() {
    this.intro.exit();
    const userMode = store.state.settings.userMode;
    store.commit('drawer/setDrawerRightStateless', true, { root: true });
    store.commit('drawer/setDrawerLeftStateless', true, { root: true });
    this.intro.setOptions({
      skipLabel: 'Exit Overview',
      doneLabel: 'Exit Overview',
      hidePrev: true,
      hideNext: true,
      keyboardNavigation: true,
      disableInteraction: true,
      exitOnOverlayClick: false,
      showBullets: true,
      steps: userMode === 'T' ? teacherSteps : adminSteps
    });

    this.intro.start();
    (document.querySelector('.introjs-tooltipbuttons > a.introjs-skipbutton') as HTMLElement).style.display = 'none';
    (document.querySelector('.introjs-tooltipbuttons > a.introjs-prevbutton') as HTMLElement).style.display = 'none';
    (document.querySelector('.introjs-tooltipbuttons > a.introjs-nextbutton') as HTMLElement).style.display = 'none';
    (document.querySelector('.introjs-bullets') as HTMLElement).prepend(this.createExitElement());
    (document.querySelector('.introjs-tooltip.introjs-floating') as HTMLElement).onclick = this.onClick.bind(this);
  }

  onClick(e: Event) {
    const target = e.target as HTMLElement;
    if (target?.matches('.introjs-button-skip') || target?.closest('.introjs-button-action')?.matches('.introjs-button-skip')) {
      (document.querySelector('.introjs-skipbutton') as HTMLElement).click()
    } else if (target?.matches('.introjs-button-nav-next') || target?.closest('.introjs-button-nav')?.matches('.introjs-button-nav-next')) {
      (document.querySelector('.introjs-nextbutton') as HTMLElement).click();
    } else if (target?.matches('.introjs-button-nav-prev') || target?.closest('.introjs-button-nav')?.matches('.introjs-button-nav-prev')) {
      (document.querySelector('.introjs-prevbutton') as HTMLElement).click();
    } else if (target?.matches('.introjs-continue-overview') || target?.closest('.introjs-button-action')?.matches('.introjs-continue-overview')) {
      this.continue();
    } else if (target?.matches('.introjs-button-class-tutorial') || target?.closest('.introjs-button-action')?.matches('.introjs-button-class-tutorial')) {
      this.startVideo();
    }
  }
}

const overview = new OverviewServices();
if (debug) window.overview = overview;
export default overview;
