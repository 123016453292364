import store from '@/store';
import { genSaltSync, hashSync, setRandomFallback } from 'bcryptjs';
import isaac from 'isaac';
import ld from 'lodash';
import Vue from 'vue';
import { emailFormat, fonts, fontSizes, planbookColors, planbookFonts } from '../constants';
import { Route } from 'vue-router';
import jwtDecode from 'jwt-decode';
import moment from 'moment-timezone';
import vuetify from '@/plugins/vuetify';
const tinycolor = require('tinycolor2');

setRandomFallback((len: number): number[] => {
  const buf = new Uint8Array(len);
  return Array.from<number>(buf.map(() => Math.floor(isaac.random() * 256)));
});

type IteratorCallback = {
  (item: any, i: number, arr: Array<any>): void;
};

type ValueConverter = {
  (value: any): any;
}

export default class CommonUtils {
  public static getColor(color: string): string {
    if (isNaN(+color)) {
      return color;
    } else {
      return planbookColors[+color];
    }
  }

  public static hasValue(value: any) {
    return value !== undefined && value !== null;
  }

  public static hasText(text: string | any): boolean {
    return CommonUtils.hasValue(text) && text.trim() !== '';
  }

  public static hasNoText(text: string | any): boolean {
    return !CommonUtils.hasText(text);
  }

  public static getFontFamily(font: string): string {
    if (isNaN(+font)) {
      return font;
    } else {
      return planbookFonts[+font];
    }
  }

  public static getFontSizeLabel(size: string) {
    if (isNaN(+size)) {
      return size;
    } else {
      for (const i in fontSizes) {
        const f = fontSizes[i];
        if (f.value === '' + size) {
          return f.text;
        }
      }
      return '10pt';
    }
  }

  public static getFontSizeValue(size: string) {
    for (const i in fontSizes) {
      const f = fontSizes[i];
      if (f.text === '' + size) {
        return f.value;
      }
    }
    return '10';
  }

  public static getFontLabel(font: string) {
    if (isNaN(+font)) {
      return font;
    } else {
      for (const i in fonts) {
        const f = fonts[i];
        if (f.value === '' + font) {
          return f.text;
        }
      }
      return 'Arial';
    }
  }

  public static getFontValue(font: string) {
    for (const i in fonts) {
      const f = fonts[i];
      if (f.text === '' + font) {
        return f.value;
      }
    }
    return '1';
  }

  public static isIn(needle: any, haystack: Array<any>) {
    if (!needle || !haystack) {
      return false;
    }
    return haystack.includes(needle);
  }

  public static isArrayEqual(a: Array<any>, b: Array<any>) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    a.sort();
    b.sort();

    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }

  public static isArrayNotEqual(a: Array<any>, b: Array<any>) {
    return !CommonUtils.isArrayEqual(a, b);
  }

  public static isEmpty = (arr: Array<any>) => {
    return !arr || arr.length === 0;
  }

  public static isNotEmpty(arr: Array<any>) {
    return !CommonUtils.isEmpty(arr);
  }

  public static forEach(arr: Array<any>, fn: IteratorCallback) {
    if (!arr || !arr.length || !fn) return
    let i = -1
    const len = arr.length
    while (++i < len) {
      const item = arr[i]
      fn(item, i, arr)
    }
  }

  public static forEachValues(o: any, fn: IteratorCallback) {
    if (!o || Array.isArray(o)) {
      return;
    }
    const arr = [];
    for (const key in o) {
      arr.push(o[key]);
    }
    CommonUtils.forEach(arr, fn);
  }

  public static getOrDefaultTo(v: any, d?: any) {
    return CommonUtils.get(v, d);
  }

  public static get(v: any, d?: any) {
    if (v === undefined || v === null) {
      return d;
    }
    return v;
  }

  public static isTrue(v: boolean | string | number | any) {
    return v !== null && v !== undefined && (v === true || v === 'TRUE' || v === 'true' || v === 1 || v === 'Y' || v === 'y' || v === '1');
  }

  public static isFalse(v: boolean | string) {
    return !this.isTrue(v);
  }

  public static compareString(a: string, b: string) {
    if (!a && b) {
      return -1;
    } else if (a && !b) {
      return 1;
    } else {
      return a.localeCompare(b);
    }
  }

  public static getIconForMimeType(mimeType: string) {
    const iconClasses = {
      image: { icon: 'fa-solid fa-file-image', class: 'far', color: '#dd5044' },
      audio: { icon: 'fa-solid fa-file-audio', class: 'far', color: '#f9a824' },
      video: { icon: 'fa-solid fa-file-video', class: 'far', color: '#5f6368' },
      'application/pdf': { icon: 'fa-solid fa-file-pdf', class: 'far', color: '#ea4235' },
      'application/msword': { icon: 'fa-solid fa-file-image', class: 'far', color: '#4185f4' },
      'application/vnd.ms-word': { icon: 'fa-solid fa-file-word', class: 'far', color: '#4185f4' },
      'application/vnd.oasis.opendocument.text': { icon: 'fa-solid fa-file-lines', class: 'far', color: '#4185f4' },
      'application/vnd.openxmlformats-officedocument.wordprocessingml': { icon: 'mdi-xml', class: 'far', color: '#4185f4' },
      'application/vnd.ms-excel': { icon: 'fa-solid fa-file-excel', class: 'far', color: '#0b9d58' },
      'application/vnd.openxmlformats-officedocument.spreadsheetml': { icon: 'fa-solid fa-file-spreadsheet', class: 'far', color: '#0b9d58' },
      'application/vnd.oasis.opendocument.spreadsheet': { icon: 'fa-solid fa-file-excel', class: 'far', color: '#0b9d58' },
      'application/vnd.ms-powerpoint': { icon: 'fa-solid fa-file-powerpoint', class: 'far', color: '#f4b927' },
      'application/vnd.openxmlformats-officedocument.presentationml': { icon: 'fa-solid fa-file-powerpoint', class: 'far', color: '#f4b927' },
      'application/vnd.oasis.opendocument.presentation': { icon: 'fa-solid fa-file-powerpoint', class: 'far', color: '#f4b927' },
      'text/plain': { icon: 'fa-solid fa-file-lines', class: 'far', color: '#4185f4' },
      'text/html': { icon: 'fa-solid fa-file-code', class: 'far', color: '#4185f4' },
      'application/json': { icon: 'mdi-code-json', class: 'far', color: '#4185f4' },
      'application/gzip': { icon: 'fa-solid fa-file-zipper', class: 'far', color: '#737373' },
      'application/zip': { icon: 'mdi-folder-zip', class: 'far', color: '#737373' },
      'application/octet-stream': { icon: 'fa-solid fa-file-binary', class: 'far', color: '#737373' },
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': { icon: 'fa-solid fa-file-word', class: 'far', color: '#4185f4' }
    };
    for (const key in iconClasses) {
      if (Object.prototype.hasOwnProperty.call(iconClasses, key)) {
        if (mimeType && mimeType.search(key) === 0) {
          return ld.get(iconClasses, key);
        }
      } else {
        return { icon: String.fromCharCode(0xf15b), class: 'far', color: '#5f6368' };
      }
    }
    return { icon: 'fas fa-file', class: 'far', color: '#5f6368' };
  }

  public static generateUUID() {
    let dt = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }

  public static getPropertyValues(arr: Array<any>, property: string, valueConverter?: ValueConverter) {
    const values = [];
    if (arr && arr.length > 0) {
      for (const i in arr) {
        let value = ld.get(arr[i], property);
        if (valueConverter) {
          value = valueConverter(value);
        }
        values.push(value);
      }
    }
    return values;
  }

  public static booleanToString(value: any) {
    return value === true || value === 'true' || value === 'Y' || value === 1 || value === '1' || value === 'TRUE' || value === 'y' ? 'Y' : 'N';
  }

  public static getRandomInt(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  public static getRandomColor() {
    return planbookColors[CommonUtils.getRandomInt(0, planbookColors.length - 1)];
  }

  public static isArrayEqualDeepCompare(array1: Array<any>, array2: Array<any>) {
    return ld(array1).xorWith(array2, ld.isEqual).isEmpty();
  }

  public static isEmailValid(email: string) {
    return CommonUtils.hasText(email) && email.match(emailFormat);
  }

  public static hashPassword(password: string) {
    return hashSync(password, genSaltSync(5));
  }

  public static isEven(n: number) {
    return n % 2 === 0;
  }

  public static isHTMLEmpty(html: string) {
    if (CommonUtils.hasText(html)) {
      const el = document.createElement('div');
      el.innerHTML = html;
      if (el.querySelector('iframe') !== null) return CommonUtils.hasNoText(el.innerText) && el.querySelectorAll('img, video, object').length === 0 && !el.querySelector('iframe[src]:not([src=""])');
      return CommonUtils.hasNoText(el.innerText) && el.querySelectorAll('img, video, object').length === 0;
    }
    return true;
  }

  public static isHTMLNotEmpty(html: string) {
    return !CommonUtils.isHTMLEmpty(html);
  }

  public static getBrowserHeight() {
    return CommonUtils.getBrowserDimensions().height;
  }

  public static getBrowserWidth() {
    return CommonUtils.getBrowserDimensions().width;
  }

  public static getBrowserDimensions(): any {
    return {
      width: (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth),
      height: (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight)
    };
  }

  public static showLoading(): void {
    Vue.nextTick(() => {
      if (!store.state.settings.loading) {
        store.commit('settings/hideLoading', null, { root: true });
        store.commit('settings/showLoading', null, { root: true });
      }
    });
  }

  public static hideLoading(): void {
    Vue.nextTick(() => {
      if (store.state.settings.loading) {
        store.commit('settings/hideLoading', null, { root: true });
      }
    });
  }

  public static isLoading() {
    return store.state.settings.loading;
  }

  public static getMobileBreakpointSize() {
    return store.getters['index/getMobileBreakpoint'];
  }

  public static mobileTableClass() {
    return (store.getters['index/getIsMobileMode']) ? 'v-data-table__mobile-table-row' : '';
  }

  public static mobileRowClass() {
    return (store.getters['index/getIsMobileMode']) ? 'v-data-table__mobile-row' : '';
  }

  public static mobileHeaderClass() {
    return (store.getters['index/getIsMobileMode']) ? 'v-data-table__mobile-row__header' : '';
  }

  public static mobileCellClass() {
    return (store.getters['index/getIsMobileMode']) ? 'v-data-table__mobile-row__cell' : '';
  }

  public static isMobileMode() {
    return (store.getters['index/getIsMobileMode']);
  }

  public static mainContentBreakpoint() {
    return store.getters['index/getMainContentBreakpoint'];
  }

  public static isPaymentNeeded(): boolean {
    return store.getters['settings/isPaymentNeeded'];
  }

  public static extractValidEmails(emails: Array<string>) {
    return emails.filter(e => CommonUtils.isEmailValid(e));
  }

  public static split(str: string, delimiters: Array<any>) {
    for (let i = 1; i < delimiters.length; i++) {
      str = str.split(delimiters[i]).join(',');
    }
    return str.split(',');
  }

  public static getFontFamilyKey(customFont: any) {
    const fontFamily = customFont.fontFamily.toLowerCase();
    return fontFamily.replace(/[\W]+/g, '') + customFont.teacherId;
  }

  public static getPage(route: Route) {
    const paths = route.path.split('/');
    return paths.reverse().find(path => CommonUtils.hasText(path)) as string;
  }

  public static addAlpha(color: string, opacity: number): string {
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
  }

  public static isAlpha(v: string) {
    const regex = /^[A-Z]$/i;
    return regex.test(v);
  }

  public static getTokenData(token: string) {
    try {
      const decoded: any = jwtDecode(token || '');
      const exp = decoded.exp;
      if (!exp || (+exp * 1000) > new Date().getTime()) {
        return JSON.parse(decoded.sub)
      }
    } catch (e) {
      return null;
    }
  }

  public static getAppBaseUrl() {
    return window.location.protocol + '//' + window.location.host;
  }

  public static isNewPricing20240701() {
    return moment().isSameOrAfter(moment('07/01/2024', 'MM/DD/YYYY'));
  }

  public static monthlyRate() {
    return CommonUtils.isNewPricing20240701() ? (5 / 3).toFixed(5) : 1.25;
  }

  public static arrayAddOrRemove(array: Array<any>, item: any) {
    if (array.includes(item)) {
      const index = array.indexOf(item);
      array.splice(index, 1);
    } else {
      array.push(item);
    }
  }

  public static isColorReadable(fg: string, bg?: string) {
    if (typeof bg === 'undefined') {
      bg = String(vuetify.framework.theme.currentTheme.bg);
    }
    return tinycolor.readability(fg, bg) > 2.9;
  }
}
