var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "editor" },
    [
      _c(
        "v-form",
        { ref: "strategyEditorForm", attrs: { "lazy-validation": "" } },
        [
          _c(
            "v-container",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isTextFieldsVisible,
                  expression: "isTextFieldsVisible"
                }
              ],
              staticClass: "pb-1"
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("strategyIdLabel") + "*",
                          "aria-required": "true",
                          outlined: "",
                          clearable: "",
                          rules: _vm.localRules,
                          "validate-on-blur": "",
                          "hide-details": "auto",
                          disabled:
                            !_vm.$currentUser.isDistrictAdmin &&
                            _vm.localStrategy.strategyGroupType === "D",
                          autofocus: ""
                        },
                        model: {
                          value: _vm.localStrategy.strategyId,
                          callback: function($$v) {
                            _vm.$set(_vm.localStrategy, "strategyId", $$v)
                          },
                          expression: "localStrategy.strategyId"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("strategyTitleLabel") + "*",
                          "aria-required": "true",
                          outlined: "",
                          clearable: "",
                          rules: _vm.localRules,
                          "validate-on-blur": "",
                          "hide-details": "auto",
                          disabled:
                            !_vm.$currentUser.isDistrictAdmin &&
                            _vm.localStrategy.strategyGroupType === "D"
                        },
                        model: {
                          value: _vm.localStrategy.strategyTitle,
                          callback: function($$v) {
                            _vm.$set(_vm.localStrategy, "strategyTitle", $$v)
                          },
                          expression: "localStrategy.strategyTitle"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              key: _vm.localRefreshKey,
              staticStyle: { "margin-left": "1px" },
              on: {
                mouseenter: function($event) {
                  _vm.isSectionsHovered = true
                },
                mouseleave: function($event) {
                  _vm.isSectionsHovered = false
                }
              }
            },
            [
              _c("text-editor-full-screen-button", {
                attrs: {
                  isSectionsHovered: _vm.isSectionsHovered,
                  isTextFieldsVisible: _vm.isTextFieldsVisible
                },
                on: { hideOrShowTextFields: _vm.hideOrShowTextFields }
              }),
              _c("pb-text-editor", {
                staticStyle: { "border-top": "1px solid rgb(204, 204, 204)" },
                attrs: {
                  refreshKey: _vm.localRefreshKey,
                  config: _vm.localTextEditorConfig,
                  disabled:
                    !_vm.$currentUser.isDistrictAdmin &&
                    _vm.localStrategy.strategyGroupType === "D"
                },
                model: {
                  value: _vm.localStrategy.strategyDesc,
                  callback: function($$v) {
                    _vm.$set(_vm.localStrategy, "strategyDesc", $$v)
                  },
                  expression: "localStrategy.strategyDesc"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("pb-confirm", { ref: "strategyEditorConfirm" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }