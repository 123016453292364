
import CommonUtils from '@/utils/common-utils';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Confirm from '../core/Confirm.vue';
import moment from 'moment';
import ld from 'lodash';
import { occurrences } from '@/constants';
import DateTimeUtils from '@/utils/date-time-utils';
import NotesTodoServices from '@/services/notes-todo-services';
import { FormError } from '@/errors';
import TextEditorFullScreenButton from '@/components/common/TextEditorFullScreenButton.vue';

const notestodo = namespace('notestodo');
const classes = namespace('classes');
const students = namespace('students');
const settings = namespace('settings');

@Component({
  components: {
    TextEditorFullScreenButton
  }
})
export default class NotesTodoEditorForm extends Vue {
isUpdatingToDo = false;
isAddingToDo = false;
isToDoDone: any = null;
priorityChoices = 0;
notesTodoStartDate = moment().format('MM/DD/YYYY');
notesTodoEndDate = moment().format('MM/DD/YYYY');
isUpdatingNotes = false;
isAddingNotes = false;
localRefreshKey = CommonUtils.generateUUID();
eventText = '';
reminderData = null;
isSectionsHovered = false;
isTextFieldsVisible = true;
$refs!: {
    addNotesToDoConfirm: Confirm,
    observer: any
  };

  @Prop({
    type: Object,
    required: true,
    default: () => {
      return {};
    }
  })
  input!: any;

  @Prop({ type: Boolean, default: true })
  isModal!: boolean;

  get localTextEditorConfig() {
    return this.isModal ? { height: this.isTextFieldsVisible ? '370px' : 'fill' } : { height: 'fill' };
  }

  @notestodo.State('todosList')
  mytodosData!: any;

  @notestodo.State('notesList')
  mynotesData!: any;

  @notestodo.Mutation('setTodos')
  setTodos!: any;

  @notestodo.Mutation('setNotes')
  setNotes!: any;

  @notestodo.Mutation('setHaveTodos')
  setHaveTodos!: any;

  @notestodo.Mutation('setHaveNotes')
  setHaveNotes!: any;

  @notestodo.Action
  loadTodos!: () => Promise<any>;

  @notestodo.Action
  loadNotes!: (params?: any) => Promise<any>;

  @notestodo.Action
  addTodo!: (params?: any) => Promise<any>;

  @notestodo.Action
  updateTodo!: (params?: any) => Promise<any>;

  @notestodo.Action
  addOrUpdateNote!: (params?: any) => Promise<any>;

  @classes.Getter('getClassItems')
  classItems!: Array<any>;

  @classes.Getter
  getClassName!: (param?: string | number) => string;

  @students.Getter('getNotesStudentsItems')
  studentItems!: Array<any>;

  @students.Getter('getHasNotesStudents')
  hasNotesStudents!: boolean;

  @students.Action
  loadNotesStudents!: (params?: any) => Promise<any>;

  @settings.Getter('getCurrentYear')
  currentSchoolYear!: any;

  @settings.Getter('getShowSnackbarNotifications')
  showSnackbarNotifications!: boolean;

  @Watch('input')
  doInit() {
    this.initialize();
  }

  isDirty():boolean {
    if (this.isUpdatingToDo || this.isAddingToDo) {
      return !ld.isEqual(this.initialTodoData, this.localTodo);
    } else {
      return !ld.isEqual(this.initialNoteData, this.localNote);
    }
  }

  get localNote() {
    return this.$store.state.notestodo.localNote;
  }

  set localNote(note: any) {
    this.$store.commit('notestodo/setLocalNote', note);
  }

  get localTodo() {
    return this.$store.state.notestodo.localToDo;
  }

  set localTodo(todo: any) {
    this.$store.commit('notestodo/setLocalToDo', todo);
  }

  get initialNoteData() {
    return this.$store.state.notestodo.originalNote;
  }

  set initialNoteData(note: any) {
    this.$store.commit('notestodo/setOriginalNote', note);
  }

  get initialTodoData() {
    return this.$store.state.notestodo.originalToDo;
  }

  set initialTodoData(todo: any) {
    this.$store.commit('notestodo/setOriginalToDo', todo);
  }

  get occurrences() {
    return occurrences.filter(o => o.value !== 'biweekly');
  }

  get showRepeats() {
    return CommonUtils.hasText(this.notesTodoStartDate) && CommonUtils.hasText(this.notesTodoEndDate) && DateTimeUtils.daysDiff(this.notesTodoStartDate, this.notesTodoEndDate) > 7;
  }

  get didRecurringTodoChanged() {
    return (this.localTodo.startDate !== this.initialTodoData.startDate || this.localTodo.dueDate !== this.initialTodoData.dueDate) && CommonUtils.hasText(this.localTodo.currentDate);
  }

  get shouldUpdateCurrentTodo() {
    return CommonUtils.hasText(this.localTodo.currentDate);
  }

  get notesClassItems() {
    return this.classItems;
  }

  get getNotesStudentItems() {
    let studentItems = ld.cloneDeep(this.studentItems);
    studentItems = studentItems.filter(e => e.value !== '0');
    return studentItems;
  }

  get notesClassId() {
    return this.$store.state.prints.notesClassId || 0;
  }

  set notesClassId(val) {
    this.$store.commit('prints/setNotesClassId', val);
  }

  get notesStudentId() {
    return this.$store.state.prints.notesStudentId || '0';
  }

  set notesStudentId(val) {
    this.$store.commit('prints/setNotesStudentId', val);
  }

  get localPage() {
    return CommonUtils.getPage(this.$route);
  }

  refreshReminder() {
    this.$eventBus.$emit('remindersRefresh', true);
    this.refreshFields();
  }

  refreshFields() {
    this.eventText = '';
    this.priorityChoices = 0;
    this.isToDoDone = null;
    this.notesTodoStartDate = '';
    this.notesTodoEndDate = '';
    this.notesClassId = 0;
    this.notesStudentId = 0;
    this.localRefreshKey = CommonUtils.generateUUID();
    this.localTodo = {
      startDate: this.notesTodoStartDate,
      dueDate: this.notesTodoEndDate,
      toDoText: '',
      priority: 1,
      done: 0,
      toDoId: null,
      repeats: 'daily',
      currentDate: '',
      updateCurrentTodo: false,
      customTodo: [],
      customTodoId: undefined,
      customToDoText: undefined,
      customPriority: undefined,
      customDone: undefined
    };
    this.setLocalNoteValues();
  }

  async applyNotesToDos() {
    const dateFieldsValid = await this.$refs.observer.validate();
    if (!dateFieldsValid) {
      return Promise.reject(new FormError());
    }

    const that = this;
    this.localPage === 'notes-todo' ? this.$store.commit('notestodo/setListLoading', true) : CommonUtils.showLoading();
    this.updateNotesToDosData();
    if (this.isUpdatingToDo) {
      that.localTodo.customTodo = JSON.stringify(that.localTodo.customTodo);
      if (!that.localTodo.startDate && !that.localTodo.dueDate) {
        that.localTodo.repeats = null;
      }
      if (that.didRecurringTodoChanged) {
        return that.$refs.addNotesToDoConfirm.confirm({
          title: that.$t('editToDoDialogTitle'),
          text: that.$t('editTodoMsg1'),
          option1ButtonAlternativeText: that.$t('continueLabel')
        }).then((result) => {
          if (result === 1) {
            that.localTodo.toDoText = that.localTodo.customToDoText || that.localTodo.toDoText;
            that.localTodo.priority = that.localTodo.customPriority || that.localTodo.priority;
            that.localTodo.done = that.localTodo.customDone || that.localTodo.done;
            delete that.localTodo.customToDoText;
            delete that.localTodo.customPriority;
            delete that.localTodo.customDone;
            return that.doUpdateTodo();
          } else {
            CommonUtils.hideLoading();
            this.$store.commit('notestodo/setListLoading', false);
            return Promise.resolve();
          }
        });
      } else if (that.shouldUpdateCurrentTodo) {
        return that.$refs.addNotesToDoConfirm.confirm({
          title: that.$t('editToDoDialogTitle'),
          text: that.$t('editTodoMsg2', { text: DateTimeUtils.formatToShow(that.localTodo.currentDate) }),
          option1ButtonAlternativeText: that.$t('allTodosLabel'),
          option2ButtonAlternativeText: that.$t('onlyThisTodoLabel')
        }).then((result:number) => {
          if (result === 1) {
            that.localTodo.toDoText = that.localTodo.customToDoText || that.localTodo.toDoText;
            that.localTodo.priority = that.localTodo.customPriority || that.localTodo.priority;
            that.localTodo.done = that.localTodo.customDone || that.localTodo.done;
            delete that.localTodo.customToDoText;
            delete that.localTodo.customPriority;
            delete that.localTodo.customDone;
            return that.doUpdateTodo();
          } else if (result === 2) {
            that.updateNotesToDosData(true);
            that.localTodo.updateCurrentTodo = true;
            // adding new custom todo
            if (that.localTodo.customTodoId === undefined && CommonUtils.isNotEmpty(JSON.parse(that.localTodo.customTodo || '[]'))) {
              NotesTodoServices.createNewCustomTodo(that.localTodo, that.initialTodoData);
            }
            that.localTodo.done = that.initialTodoData.done;
            that.localTodo.priority = that.initialTodoData.priority;
            that.localTodo.toDoText = that.initialTodoData.toDoText;
            return that.doUpdateTodo();
          } else {
            CommonUtils.hideLoading();
            this.$store.commit('notestodo/setListLoading', false);
            return Promise.resolve();
          }
        });
      } else {
        return this.updateTodo(this.localTodo).then(() => {
          if (this.showSnackbarNotifications) {
            this.$snotify.success(this.$t('statusMsg66') as string);
          }
          this.refreshReminder();
          return Promise.resolve();
        });
      }
    } else if (this.isAddingToDo) {
      return await this.addTodo(this.localTodo).then(() => {
        if (this.showSnackbarNotifications) {
          this.$snotify.success(this.$t('statusMsg64') as string);
        }
        this.refreshReminder();
        return Promise.resolve();
      });
    } else if (this.isAddingNotes) {
      return await this.addOrUpdateNote(this.localNote).then(() => {
        if (this.showSnackbarNotifications) {
          this.$snotify.success(this.$t('statusMsg65') as string);
        }
        this.refreshReminder();
        return Promise.resolve();
      });
    } else if (this.isUpdatingNotes) {
      return this.addOrUpdateNote(this.localNote).then(() => {
        if (this.showSnackbarNotifications) {
          this.$snotify.success(this.$t('statusMsg67') as string);
        }
        this.refreshReminder();
        return Promise.resolve();
      });
    }
  }

  setLocalNoteValues() {
    this.localNote = {
      subjectId: this.notesClassId,
      studentId: this.notesStudentId,
      yearId: this.currentSchoolYear.yearId,
      noteDate: this.notesTodoStartDate,
      noteText: this.eventText
    }
  }

  async doUpdateTodo() {
    return this.updateTodo(this.localTodo).then(() => {
      if (this.showSnackbarNotifications) {
        this.$snotify.success(this.$t('statusMsg66') as string);
      }
      this.refreshReminder();
      return Promise.resolve();
    }).catch(() => {
      CommonUtils.hideLoading();
      this.$store.commit('notestodo/setListLoading', false);
      return Promise.reject();
    });
  }

  @Watch('isToDoDone')
  updateDone() {
    if (this.localTodo.customTodoId >= 0 || this.localTodo.onlyThisTodo) {
      this.isToDoDone === null || this.isToDoDone === undefined
        ? (this.localTodo.customDone = 0)
        : (this.localTodo.customDone = 1);
    } else {
      this.isToDoDone === null || this.isToDoDone === undefined
        ? (this.localTodo.done = 0)
        : (this.localTodo.done = 1);
    }
  }

  @Watch('priorityChoices')
  updatePriority() {
    if (this.localTodo.customTodoId >= 0 || this.localTodo.onlyThisTodo) {
      this.localTodo.customPriority = this.priorityChoices + 1;
    } else {
      this.localTodo.priority = this.priorityChoices + 1;
    }
  }

  @Watch('notesTodoStartDate')
  updateStart() {
    this.localTodo.startDate = this.notesTodoStartDate;
    this.localNote.noteDate = this.notesTodoStartDate;
  }

  @Watch('notesTodoEndDate')
  updateEnd() {
    this.localTodo.dueDate = this.notesTodoEndDate;
  }

  @Watch('notesClassId')
  updateClass() {
    this.localNote.subjectId = this.notesClassId;
  }

  @Watch('notesStudentId')
  updateStudent() {
    this.localNote.studentId = this.notesStudentId;
  }

  @Watch('eventText')
  updateText() {
    if (this.localTodo.customTodoId >= 0 || this.localTodo.onlyThisTodo) {
      this.localTodo.customToDoText = this.eventText;
    } else {
      this.localTodo.toDoText = this.eventText;
    }
    this.localNote.noteText = this.eventText;
  }

  updateNotesToDosData(onlyThisTodo?:boolean) {
    this.localTodo.dueDate = this.notesTodoEndDate;
    this.localTodo.startDate = this.notesTodoStartDate

    if (this.localTodo.customTodoId >= 0 || onlyThisTodo) {
      this.isToDoDone === null || this.isToDoDone === undefined
        ? (this.localTodo.customDone = 0)
        : (this.localTodo.customDone = 1);
      this.localTodo.customToDoText = this.eventText;
      this.localTodo.customPriority = this.priorityChoices + 1;
    } else {
      this.isToDoDone === null || this.isToDoDone === undefined
        ? (this.localTodo.done = 0)
        : (this.localTodo.done = 1);
      this.localTodo.toDoText = this.eventText;
      this.localTodo.priority = this.priorityChoices + 1;
    }

    if (this.isAddingNotes) {
      this.setLocalNoteValues();
      delete this.localNote.noteId;
    }
  }

  created() {
    this.doInit();
    this.$nextTick(() => {
      new Promise(resolve => setTimeout(resolve, 100)).then(() => {
        if (this.isUpdatingToDo || this.isAddingToDo) {
          (this.$refs as any).doneButton.$el.focus();
        } else {
          (this.$refs as any).dateField.focus();
        }
      });
    });
  }

  addTodoInitialize() {
    this.isAddingToDo = true;
    this.isAddingNotes = false;
    this.isUpdatingToDo = false;
    this.isUpdatingNotes = false;
  }

  addNotesInitialize() {
    this.isAddingNotes = true;
    this.isAddingToDo = false;
    this.isUpdatingToDo = false;
    this.isUpdatingNotes = false;
  }

  editTodoInitialize(params:any) {
    this.isUpdatingToDo = true;
    this.isUpdatingNotes = false;
    this.isAddingToDo = false;
    this.isAddingNotes = false;
    this.reminderData = params.reminderData;
    // SET DATA
    this.eventText = ld.cloneDeep(params.reminderData.customToDoText || params.reminderData.toDoText);
    if (params.reminderData.customDone !== undefined) {
      this.isToDoDone = params.reminderData.customDone ? 0 : null;
      this.localTodo.customDone = CommonUtils.isTrue(params.reminderData.customDone) ? 1 : 0;
    } else {
      this.isToDoDone = params.reminderData.done ? 0 : null;
      this.localTodo.customDone = undefined;
    }
    switch (params.reminderData.customPriority || params.reminderData.priority) {
      case 1: {
        this.priorityChoices = 0;
        break;
      }
      case 2: {
        this.priorityChoices = 1;
        break;
      }
      case 3: {
        this.priorityChoices = 2;
        break;
      }
    }
    this.notesTodoEndDate = params.reminderData.dueDate
    this.notesTodoStartDate = params.reminderData.startDate;
    this.localTodo.toDoText = params.reminderData.toDoText
    this.localTodo.priority = params.reminderData.priority;
    this.localTodo.toDoId = params.reminderData.toDoId;
    this.localTodo.repeats = params.reminderData.repeats || 'daily';
    this.localTodo.currentDate = params.reminderData.currentDate;
    this.localTodo.customTodo = params.reminderData.customTodo;
    this.localTodo.customTodoId = params.reminderData.customTodoId;
    this.localTodo.customToDoText = params.reminderData.customToDoText;
    this.localTodo.customPriority = params.reminderData.customPriority;
    this.localTodo.done = CommonUtils.isTrue(params.reminderData.done) ? 1 : 0;
    this.localTodo.dueDate = params.reminderData.dueDate
    this.localTodo.startDate = params.reminderData.startDate;
  }

  editNotesInitialize(params:any) {
    this.isUpdatingNotes = true;
    this.isUpdatingToDo = false;
    this.isAddingToDo = false;
    this.isAddingNotes = false;
    this.reminderData = params.reminderData;
    // SET DATA
    this.eventText = params.reminderData.noteText;
    this.notesTodoStartDate = params.reminderData.noteDate === '9999-12-31' ? '' : moment(params.reminderData.noteDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
    this.notesClassId = params.reminderData.subjectId;
    this.notesStudentId = params.reminderData.studentId.toString();
    this.setLocalNoteValues();
    this.localNote.noteId = params.reminderData.noteId;
  }

  destroyed() {
    this.$eventBus.$off('deleteReminders');
  }

  setLocalValues() {
    if (this.input.addWhat === 'TD') {
      this.addTodoInitialize();
    } else if (this.input.addWhat === 'NT') {
      this.addNotesInitialize();
    } else if (this.input.editWhat === 'TD') {
      this.isUpdatingToDo = true;
      this.isUpdatingNotes = false;
      this.isAddingToDo = false;
      this.isAddingNotes = false;
    } else if (this.input.editWhat === 'NT') {
      this.isUpdatingNotes = true;
      this.isUpdatingToDo = false;
      this.isAddingToDo = false;
      this.isAddingNotes = false;
    }

    if (this.input.addWhat === 'TD' || this.input.editWhat === 'TD') {
      this.isToDoDone = (this.localTodo.customDone === 1 || this.localTodo.done === 1) ? 0 : undefined;
      this.priorityChoices = (this.localTodo.customPriority > 0) ? this.localTodo.customPriority - 1 : this.localTodo.priority - 1;
      this.notesTodoStartDate = this.localTodo.startDate;
      this.notesTodoEndDate = this.localTodo.dueDate;
      this.eventText = this.localTodo.customToDoText || this.localTodo.toDoText;
    } else {
      this.notesTodoStartDate = this.localNote.noteDate;
      this.notesClassId = this.localNote.subjectId;
      this.notesStudentId = this.localNote.studentId;
      this.eventText = this.localNote.noteText;
    }
  }

  initialize() {
    this.localRefreshKey = CommonUtils.generateUUID();

    this.$eventBus.$on('deleteReminders', (reminders: any) => {
      if (this.isUpdatingToDo) {
        for (const i in reminders) {
          if (reminders[i].type === 'TD' && reminders[i].id === this.localTodo.toDoId) {
            this.$eventBus.$emit('closeSubPage');
          }
        }
      } else if (this.isUpdatingNotes) {
        for (const i in reminders) {
          if (reminders[i].type === 'TD' && reminders[i].id === this.localNote.noteId) {
            this.$eventBus.$emit('closeSubPage');
          }
        }
      }
    })

    if (this.input.loadData === false) {
      this.setLocalValues();
      return;
    }
    const that = this;
    this.refreshFields();
    if (!this.hasNotesStudents) {
      CommonUtils.showLoading();
      that.loadNotesStudents({}).then(() => {
        CommonUtils.hideLoading();
      });
    }
    if (this.input.addWhat === 'TD') {
      that.addTodoInitialize();
    } else if (this.input.addWhat === 'NT') {
      that.addNotesInitialize();
    } else if (this.input.editWhat === 'TD') {
      that.editTodoInitialize(this.input);
    } else if (this.input.editWhat === 'NT') {
      that.editNotesInitialize(this.input);
    }
    that.initialTodoData = ld.cloneDeep(that.localTodo);
    that.initialNoteData = ld.cloneDeep(that.localNote);
  }

  hideOrShowTextFields() {
    this.isTextFieldsVisible = !this.isTextFieldsVisible;
    this.localRefreshKey = CommonUtils.generateUUID();
  }
}
