import store from '@/store';
declare class JSStorage {
  get(...key: string[] | any[]): any;
  set(...key: string[] | any[]): any;
  keys(...key: string[] | any[]): any;
  remove(...key: string[] | any[]): any;
  isEmpty(...key: string[] | any[]): boolean;
  isSet(...key: string[] | any[]): boolean;
  removeAll(yes?: boolean): boolean;
  setExpires(expires: number): JSStorage;
  setPath(path: string): JSStorage;
  setDomain(domain: string): JSStorage;
  setSecure(secure: boolean): JSStorage;
  setConf(conf: any): JSStorage;
  setDefaultConf(): JSStorage;
}

const debug = process.env.NODE_ENV !== 'production';
declare const window: any;
export const storage = window.Storages.initNamespaceStorage('PlanbookCommonStorage');
export const cmnSession: JSStorage = window.cmnSession = storage.sessionStorage;
export const cmnLocal: JSStorage = window.cmnLocal = storage.localStorage;
export const cmnCookie: JSStorage = window.cmnCookie = storage.cookieStorage;
export const cmnMap = new Map();

export class PlanbookStorage {
  public static getStorage(): JSStorage {
    return window.Storages.initNamespaceStorage(PlanbookStorage.getStorageKey()).localStorage;
  }

  public static getStorageKey() {
    let key = 'Planbook';
    const studentId = store.state.settings.studentId || 0;
    const userMode = store.state.settings.userMode;
    if (userMode === 'S') {
      key += userMode;
      if (studentId > 0) {
        key += studentId;
      } else {
        key += store.state.settings.teacherId || '';
        key += '-';
        key += store.state.settings.studentKey || ''
      }
    } else {
      key += store.state.settings.userId;
    }
    return key;
  }

  public static get(...key: string[] | any[]): any {
    try {
      return PlanbookStorage.getStorage().get(...key);
    } catch (e) {
      return undefined;
    }
  }

  public static set(...key: string[] | any[]): any {
    return PlanbookStorage.getStorage().set(...key);
  }
}

if (debug) {
  window.PlanbookStorage = PlanbookStorage;
}
