import qs from 'qs';
import axios from '@/axios';

export default class SchoolStatusServices {
  public static applyStatus(params?: any) {
    params = params || {};
    return axios.post('services/planbook/schoolstatus/applyStatus', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static removeStatus(params?: any) {
    params = params || {};
    return axios.post('services/planbook/schoolstatus/removeStatus', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static schoolYearSubmissions(params?: any) {
    params = params || {};
    return axios.post('services/planbook/schoolstatus/schoolYear', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static teacherYearSubmissions(params?: any) {
    params = params || {};
    return axios.post('services/planbook/schoolstatus/teacherYear', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static teacherYearStatuses(params?: any) {
    params = params || {};
    return axios.post('services/planbook/schoolstatus/teacherYearStatuses', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static weekSubmissions(params?: any) {
    params = params || {};
    return axios.post('services/planbook/schoolstatus/weekSubmissions', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }
}
