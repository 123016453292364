




























































import CommonUtils from '@/utils/common-utils';
import { namespace } from 'vuex-class';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import LessonLayoutEditorForm from '@/components/lessonlayouts/LessonLayoutEditorForm.vue';
import Confirm from '../core/Confirm.vue';
import { Editor } from '@/types/global.types';

const lessonlayouts = namespace('lessonlayouts');
const settings = namespace('settings');

@Component({
  components: {
    LessonLayoutEditorForm
  }
})
export default class LessonLayoutEditor extends Vue implements Editor {
  @Prop({ type: Boolean })
  value!: boolean;

  @Prop()
  input!: any;

  @Prop({ type: Boolean, default: true })
  isModal!: boolean;

  $refs!: {
    form: LessonLayoutEditorForm,
    lessonLayoutEditorConfirm: Confirm
  }

  get localValue() {
    return this.value;
  }

  set localValue(val: boolean) {
    this.$emit('input', val);
  }

  get localPage() {
    return CommonUtils.getPage(this.$route);
  }

  @lessonlayouts.Action
  deleteLayouts!: (params?: any) => Promise<any>;

  @lessonlayouts.State
  listLoading!: boolean;

  @lessonlayouts.State
  selectedLayout!: any;

  @settings.Getter('getShowSnackbarNotifications')
  showSnackbarNotifications!: boolean;

  applyLessonLayout(closeOnComplete?: boolean) {
    return this.$refs.form.applyLessonLayout().then((res) => {
      if (!res && closeOnComplete) {
        this.$eventBus.$emit('closeSubPage');
      }
      return Promise.resolve(res);
    });
  }

  applyLessonLayoutTo(arg: string, closeOnComplete?: boolean) {
    return this.$refs.form.applyLessonLayoutTo(arg).then((res) => {
      if (!res && closeOnComplete) {
        this.$eventBus.$emit('closeSubPage');
      }
      return Promise.resolve(res);
    });
  }

  doApply() {
    if (!this.$currentUser.isDistrictAdmin) {
      return this.applyLessonLayout();
    } else {
      this.districtMenu = true;
      return Promise.resolve(true);
    }
  }

  cancelEditor() {
    const form: any = this.$refs.form;
    if (form) {
      form.resetValidation();
    }
  }

  districtMenu = false;

  isDirty() {
    return this.$refs.form && this.$refs.form.isDirty();
  }

  typeChange() {
    this.$store.commit('lessonlayouts/setLoadSelectedLayout', false);
  }

  isEditable() {
    return this.$refs.form && this.$refs.form.isEditable();
  }

  get isNewLayout() {
    if (this.$refs.form) {
      return this.$refs.form.isNewLayout;
    }
    return false;
  }

  getLessonLayoutLevel() {
    if (this.selectedLayout.districtId > 0) {
      return this.$t('districtLabel');
    } else if (this.selectedLayout.schoolId > 0) {
      return this.$t('schoolLabel');
    } else if (this.selectedLayout.teacherId > 0) {
      return this.$t('teacherLabel');
    } else if (this.$currentUser.isDistrictAdmin) {
      return this.$t('districtLabel');
    } else if (this.$currentUser.isAdmin) {
      return this.$t('schoolLabel');
    } else {
      return this.$t('teacherLabel');
    }
  }

  removeLessonLayout() {
    const that = this;
    this.$refs.lessonLayoutEditorConfirm.confirm({
      title: this.$t('lessonLayoutLabel'),
      text: this.selectedLayout.teacherId > 0 ? this.$t('deleteLessonLayoutFromTeacherConfirmMsg') : this.$t('deleteLessonLayoutFromSchoolOrDistrictConfirmMsg', { org: this.selectedLayout.districtId > 0 ? this.$t('districtLabel') : this.$t('schoolLabel') }),
      option1ButtonAlternativeText: this.$t('continueLabel')
    }).then((result) => {
      if (result === 1) {
        this.localPage === 'lesson-layouts' ? this.$store.commit('lessonlayouts/setListLoading', true) : CommonUtils.showLoading();
        that.deleteLayouts({ lessonLayoutIds: this.selectedLayout.lessonLayoutId }).then(() => {
          if (this.showSnackbarNotifications) {
            this.$snotify.success(this.$t('statusMsg57') as string);
          }

          this.localPage === 'lesson-layouts' ? this.$store.commit('lessonlayouts/setListLoading', false) : CommonUtils.hideLoading();
          that.$eventBus.$emit('closeSubPage');
          that.$eventBus.$emit('refreshLessonLayouts');
        });
      }
    });
  }

  created() {
    this.$eventBus.$on('deleteLessonLayout', (layoutIds: any) => {
      if (this.isNewLayout) {
        return;
      }
      for (const i in layoutIds) {
        if (layoutIds[i] === this.selectedLayout.lessonLayoutId) {
          this.$eventBus.$emit('closeSubPage');
        }
      }
    })
  }

  destroyed() {
    this.$eventBus.$off('deleteLessonLayout');
  }

  @Watch('input')
  init() {
    this.$refs.form.initialize();
  }
}
