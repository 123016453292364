var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$currentUser.isDualOrAllowViewOnly && _vm.canSwitchModes
    ? _c(
        "v-col",
        { staticClass: "my-auto text-center", attrs: { cols: "auto" } },
        [
          _c(
            "v-menu",
            {
              attrs: {
                disabled: _vm.localToolbarDisabled,
                "offset-y": "",
                "close-on-content-click": true
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "v-avatar",
                          _vm._g(
                            _vm._b(
                              { attrs: { color: "grey darken-1", size: "36" } },
                              "v-avatar",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [
                            _c("span", { staticClass: "white--text" }, [
                              _vm._v(_vm._s(_vm.userModeFirstLetter))
                            ])
                          ]
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                2846788524
              )
            },
            [
              _c(
                "v-list",
                _vm._l(_vm.localUserModeList, function(item, index) {
                  return _c(
                    "v-list-item",
                    {
                      key: index,
                      class: {
                        "primary--text v-list-item--active v-list-item--link theme--light v-list-item--highlighted":
                          _vm.userMode == item.value
                      },
                      on: {
                        click: function($event) {
                          return _vm.userModeChanged(item.value)
                        }
                      }
                    },
                    [_c("v-list-item-title", [_vm._v(_vm._s(item.text))])],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }