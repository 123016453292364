var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "editor" },
    [
      _c(
        "v-form",
        { ref: "permissionEditorForm", attrs: { "lazy-validation": "" } },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          "hide-details": "auto",
                          label: _vm.$t("permissionTitleLabel") + "*",
                          "aria-required": "true",
                          outlined: "",
                          clearable: "",
                          rules: _vm.localRules,
                          "validate-on-blur": "",
                        },
                        model: {
                          value: _vm.localTitle,
                          callback: function ($$v) {
                            _vm.localTitle = $$v
                          },
                          expression: "localTitle",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-select", {
                        attrs: {
                          "hide-details": "auto",
                          items: _vm.type,
                          outlined: "",
                          label: _vm.$t("typesLabel"),
                          rules: _vm.localRules,
                          "validate-on-blur": "",
                        },
                        model: {
                          value: _vm.localSelectedType,
                          callback: function ($$v) {
                            _vm.localSelectedType = $$v
                          },
                          expression: "localSelectedType",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.localSelectedType === _vm.$t("teacherLabel")
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("teacher-tags-input", {
                            attrs: { dynamicAccountType: false },
                            model: {
                              value: _vm.localSelectedTeacherEmails,
                              callback: function ($$v) {
                                _vm.localSelectedTeacherEmails = $$v
                              },
                              expression: "localSelectedTeacherEmails",
                            },
                          }),
                          _vm.teacherEmailNotValid
                            ? _c(
                                "v-alert",
                                {
                                  staticClass: "mb-0",
                                  attrs: {
                                    dense: "",
                                    border: "left",
                                    type: "error",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.teacherEmailErrorMessage))]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$currentUser.isTeacher
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("multi-auto-complete", {
                            attrs: {
                              selected: _vm.localSelectedClasses,
                              items: _vm.localClassItems,
                              error: _vm.noClassesError,
                              errorMessages:
                                _vm.noCLassOrLessonBankErrorMessage,
                              itemText: "formattedName",
                              itemId: "value",
                              label: _vm.$t("classesLabel") + "*",
                              required: "",
                            },
                            on: {
                              "update:selected": function ($event) {
                                _vm.localSelectedClasses = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("multi-auto-complete", {
                        attrs: {
                          selected: _vm.localSelectedLessonBanks,
                          items: _vm.localLessonBankItems,
                          error: _vm.noClassesError,
                          errorMessages: _vm.noCLassOrLessonBankErrorMessage,
                          itemText: "text",
                          itemId: "value",
                          label: _vm.$t("lessonBankLabel") + "*",
                          required: "",
                        },
                        on: {
                          "update:selected": function ($event) {
                            _vm.localSelectedLessonBanks = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._l(_vm.localPermission.information, function (item, i) {
                return _c(
                  "v-row",
                  { key: i },
                  [
                    _c(
                      "v-col",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: item.enabledValue === "Y",
                            expression: "item.enabledValue === 'Y'",
                          },
                        ],
                      },
                      [
                        _c(
                          "v-radio-group",
                          {
                            staticClass: "section-permission-radio",
                            attrs: {
                              "hide-details": "auto",
                              mandatory: "",
                              row: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.changeRadioValue(item)
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c("span", [_vm._v(_vm._s(item.label))]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: item.selectedValue,
                              callback: function ($$v) {
                                _vm.$set(item, "selectedValue", $$v)
                              },
                              expression: "item.selectedValue",
                            },
                          },
                          [
                            _c("v-radio", {
                              attrs: {
                                label: _vm.$t("viewOnlyLabel"),
                                value: "read",
                              },
                            }),
                            _c("v-radio", {
                              attrs: {
                                label: _vm.$t("viewEditLabel"),
                                value: "write",
                              },
                            }),
                            _c("v-radio", {
                              attrs: {
                                label: _vm.$t("noneLabel"),
                                value: "none",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c("pb-confirm", { ref: "permissionEditorConfirm" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }