var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-menu",
    {
      attrs: {
        bottom: "",
        left: "",
        "close-on-click": true,
        "close-on-content-click": false,
        absolute: _vm.type === "absolute",
        "position-x": _vm.x,
        "position-y": _vm.y,
      },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function (data) {
              return [_vm._t("activator", null, null, data)]
            },
          },
        ],
        null,
        true
      ),
      model: {
        value: _vm.showMenu,
        callback: function ($$v) {
          _vm.showMenu = $$v
        },
        expression: "showMenu",
      },
    },
    [
      _c(
        "v-card",
        [
          _vm.localIsCalledByStickers
            ? _c("v-card-text", [
                _c("div", { staticClass: "format-options pl-4 py-0" }, [
                  _c("div", [
                    _c("div", [
                      _vm._v(_vm._s(_vm.$t("stickerDescriptionLabel"))),
                    ]),
                    _c("div", { staticClass: "f-container pr-5" }, [
                      _c("div", { staticClass: "f-field-container mr-5" }, [
                        _c(
                          "div",
                          {
                            staticClass: "f-field py-1",
                            staticStyle: { "max-width": "160px" },
                          },
                          [
                            _c("v-select", {
                              staticClass: "pa-0 ma-0",
                              attrs: {
                                height: "26px",
                                flat: "",
                                solo: "",
                                "hide-details": "",
                                items: _vm.stickerFonts,
                                "aria-label": _vm.$t("itemFontLabel", {
                                  item: _vm.$t("stickerDescriptionLabel"),
                                }),
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "selection",
                                    fn: function (data) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            style: {
                                              fontFamily: data.item.text,
                                            },
                                          },
                                          [_vm._v(_vm._s(data.item.text))]
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "item",
                                    fn: function (data) {
                                      return [
                                        _c(
                                          "v-list-item",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                style: {
                                                  fontFamily: data.item.text,
                                                },
                                                attrs: {
                                                  value: data.item.value,
                                                },
                                              },
                                              "v-list-item",
                                              data.attrs,
                                              false
                                            ),
                                            data.on
                                          ),
                                          [_vm._v(_vm._s(data.item.text))]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3790022054
                              ),
                              model: {
                                value: _vm.stickerDescriptionFont,
                                callback: function ($$v) {
                                  _vm.stickerDescriptionFont = $$v
                                },
                                expression: "stickerDescriptionFont",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "f-field py-1",
                            staticStyle: { "max-width": "80px" },
                          },
                          [
                            _c("v-select", {
                              staticClass: "pa-0 ma-0",
                              attrs: {
                                height: "26px",
                                flat: "",
                                solo: "",
                                "hide-details": "",
                                items: _vm.fontSizes,
                                "aria-label": _vm.$t("itemFontSizeLabel", {
                                  item: _vm.$t("stickerDescriptionLabel"),
                                }),
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "selection",
                                    fn: function (data) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            style: { fontSize: data.item.text },
                                          },
                                          [_vm._v(_vm._s(data.item.text))]
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "item",
                                    fn: function (data) {
                                      return [
                                        _c(
                                          "v-list-item",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                style: {
                                                  fontSize: data.item.text,
                                                },
                                                attrs: {
                                                  value: data.item.value,
                                                },
                                              },
                                              "v-list-item",
                                              data.attrs,
                                              false
                                            ),
                                            data.on
                                          ),
                                          [_vm._v(_vm._s(data.item.text))]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                4156867078
                              ),
                              model: {
                                value: _vm.stickerDescriptionFontSize,
                                callback: function ($$v) {
                                  _vm.stickerDescriptionFontSize = $$v
                                },
                                expression: "stickerDescriptionFontSize",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "f-field-container mr-5" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "f-field f-field-3 font-weight-bold text-center",
                            class: {
                              "blue-grey": _vm.isTrue(
                                _vm.stickerDescriptionBold
                              ),
                              "lighten-5":
                                !_vm.$vuetify.theme.dark &&
                                _vm.isTrue(_vm.stickerDescriptionBold),
                              "darken-4":
                                _vm.$vuetify.theme.dark &&
                                _vm.isTrue(_vm.stickerDescriptionBold),
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.toggleStickerDescriptionBold.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [_vm._v("B")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "f-field f-field-3 font-weight-bold font-italic text-center",
                            class: {
                              "blue-grey": _vm.isTrue(
                                _vm.stickerDescriptionItalic
                              ),
                              "lighten-5":
                                !_vm.$vuetify.theme.dark &&
                                _vm.isTrue(_vm.stickerDescriptionItalic),
                              "darken-4":
                                _vm.$vuetify.theme.dark &&
                                _vm.isTrue(_vm.stickerDescriptionItalic),
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.toggleStickerDescriptionItalic.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [_vm._v("I")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "f-field f-field-3 font-weight-bold text-decoration-underline text-center",
                            class: {
                              "blue-grey": _vm.isTrue(
                                _vm.stickerDescriptionUnderline
                              ),
                              "lighten-5":
                                !_vm.$vuetify.theme.dark &&
                                _vm.isTrue(_vm.stickerDescriptionUnderline),
                              "darken-4":
                                _vm.$vuetify.theme.dark &&
                                _vm.isTrue(_vm.stickerDescriptionUnderline),
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.toggleStickerDescriptionUnderline.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [_vm._v("U")]
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "f-field-container" },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "px-1",
                              attrs: {
                                "max-height": "35",
                                elevation: "0",
                                "max-width": "800px",
                              },
                            },
                            [
                              _c("v-card-text", { staticClass: "px-1 py-0" }, [
                                _c(
                                  "div",
                                  { staticClass: "f-field f-field-2" },
                                  [
                                    _c("pb-color-picker", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            attrs: {
                                                              small: "",
                                                            },
                                                          },
                                                          "v-icon",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          "fas fa-fill-drip"
                                                        ),
                                                      ]
                                                    ),
                                                    _c("div", {
                                                      staticStyle: {
                                                        height: "3px",
                                                        margin: "1px 0px",
                                                        width: "16px",
                                                      },
                                                      style: {
                                                        backgroundColor:
                                                          _vm.stickerDescriptionColor,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1075622266
                                      ),
                                      model: {
                                        value: _vm.stickerDescriptionColor,
                                        callback: function ($$v) {
                                          _vm.stickerDescriptionColor = $$v
                                        },
                                        expression: "stickerDescriptionColor",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("div", [
                    _c("div", [_vm._v(_vm._s(_vm.$t("stickersLabel")))]),
                    _c(
                      "div",
                      { staticClass: "f-container pr-5" },
                      [
                        _c(
                          "v-row",
                          { attrs: { align: "center" } },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "10" } },
                              [
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "mt-2",
                                    attrs: { outlined: "", width: "100%" },
                                  },
                                  [
                                    _c(
                                      "v-card-text",
                                      { staticClass: "pa-1" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "title-on-border" },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("stickersSizeLabel")
                                                )
                                              ),
                                            ]),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "font-weight": "bold",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    ` (${_vm.stickerSize}px)`
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c("v-slider", {
                                          attrs: {
                                            dense: "",
                                            "hide-details": "auto",
                                            min: "100",
                                            max: "150",
                                          },
                                          model: {
                                            value: _vm.stickerSize,
                                            callback: function ($$v) {
                                              _vm.stickerSize = $$v
                                            },
                                            expression: "stickerSize",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "auto" } },
                              [
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "px-1 mt-1 stickerBorder",
                                    attrs: {
                                      elevation: "0",
                                      "max-width": "800px",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-card-text",
                                      { staticClass: "px-1 py-1" },
                                      [
                                        _c("pb-color-picker", {
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  small: "",
                                                                },
                                                              },
                                                              "v-icon",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              "fas fa-fill-drip"
                                                            ),
                                                          ]
                                                        ),
                                                        _c("div", {
                                                          staticStyle: {
                                                            height: "3px",
                                                            margin: "1px 0px",
                                                            width: "16px",
                                                          },
                                                          style: {
                                                            backgroundColor:
                                                              _vm.stickerBackgroundColor,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1530283122
                                          ),
                                          model: {
                                            value: _vm.stickerBackgroundColor,
                                            callback: function ($$v) {
                                              _vm.stickerBackgroundColor = $$v
                                            },
                                            expression:
                                              "stickerBackgroundColor",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ])
            : _c("v-card-text", { staticClass: "px-0 pt-2 pb-0" }, [
                _c("div", { staticClass: "format-options pl-4 py-0" }, [
                  _c("div", [
                    _vm.formatTitle
                      ? _c("div", [
                          _c("div", [_vm._v(_vm._s(_vm.$t("titleLabel")))]),
                          _c("div", { staticClass: "f-container pr-5" }, [
                            _c(
                              "div",
                              { staticClass: "f-field-container mr-5" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "f-field py-1",
                                    staticStyle: { "max-width": "160px" },
                                  },
                                  [
                                    _c("v-select", {
                                      ref: "selectFontType",
                                      staticClass: "pa-0 ma-0",
                                      attrs: {
                                        height: "26px",
                                        flat: "",
                                        solo: "",
                                        "hide-details": "",
                                        items: _vm.fonts,
                                        "aria-label": _vm.$t("itemFontLabel", {
                                          item: _vm.$t("titleLabel"),
                                        }),
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "selection",
                                            fn: function (data) {
                                              return [
                                                _c(
                                                  "span",
                                                  {
                                                    style: {
                                                      fontFamily:
                                                        data.item.text,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(data.item.text)
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item",
                                            fn: function (data) {
                                              return [
                                                _c(
                                                  "v-list-item",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        style: {
                                                          fontFamily:
                                                            data.item.text,
                                                        },
                                                        attrs: {
                                                          value:
                                                            data.item.value,
                                                        },
                                                      },
                                                      "v-list-item",
                                                      data.attrs,
                                                      false
                                                    ),
                                                    data.on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      _vm._s(data.item.text)
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3790022054
                                      ),
                                      model: {
                                        value: _vm.titleFont,
                                        callback: function ($$v) {
                                          _vm.titleFont = $$v
                                        },
                                        expression: "titleFont",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "f-field py-1",
                                    staticStyle: { "max-width": "80px" },
                                  },
                                  [
                                    _c("v-select", {
                                      staticClass: "pa-0 ma-0",
                                      attrs: {
                                        height: "26px",
                                        flat: "",
                                        solo: "",
                                        "hide-details": "",
                                        items: _vm.fontSizes,
                                        "aria-label": _vm.$t(
                                          "itemFontSizeLabel",
                                          { item: _vm.$t("titleLabel") }
                                        ),
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "selection",
                                            fn: function (data) {
                                              return [
                                                _c(
                                                  "span",
                                                  {
                                                    style: {
                                                      fontSize: data.item.text,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(data.item.text)
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item",
                                            fn: function (data) {
                                              return [
                                                _c(
                                                  "v-list-item",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        style: {
                                                          fontSize:
                                                            data.item.text,
                                                        },
                                                        attrs: {
                                                          value:
                                                            data.item.value,
                                                        },
                                                      },
                                                      "v-list-item",
                                                      data.attrs,
                                                      false
                                                    ),
                                                    data.on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      _vm._s(data.item.text)
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        4156867078
                                      ),
                                      model: {
                                        value: _vm.titleFontSize,
                                        callback: function ($$v) {
                                          _vm.titleFontSize = $$v
                                        },
                                        expression: "titleFontSize",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "f-field-container mr-5" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "f-field f-field-3 font-weight-bold text-center",
                                    class: {
                                      "blue-grey": _vm.isTrue(_vm.titleBold),
                                      "lighten-5":
                                        !_vm.$vuetify.theme.dark &&
                                        _vm.isTrue(_vm.titleBold),
                                      "darken-4":
                                        _vm.$vuetify.theme.dark &&
                                        _vm.isTrue(_vm.titleBold),
                                    },
                                    attrs: {
                                      tabindex: "0",
                                      role: "button",
                                      "aria-label": _vm.$t("fontStyleBold"),
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.toggleTitleBold.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.toggleTitleBold.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("B")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "f-field f-field-3 font-weight-bold font-italic text-center",
                                    class: {
                                      "blue-grey": _vm.isTrue(_vm.titleItalic),
                                      "lighten-5":
                                        !_vm.$vuetify.theme.dark &&
                                        _vm.isTrue(_vm.titleItalic),
                                      "darken-4":
                                        _vm.$vuetify.theme.dark &&
                                        _vm.isTrue(_vm.titleItalic),
                                    },
                                    attrs: {
                                      tabindex: "0",
                                      role: "button",
                                      "aria-label": _vm.$t("fontStyleItalic"),
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.toggleTitleItalic.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.toggleTitleItalic.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("I")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "f-field f-field-3 font-weight-bold text-decoration-underline text-center",
                                    class: {
                                      "blue-grey": _vm.isTrue(
                                        _vm.titleUnderline
                                      ),
                                      "lighten-5":
                                        !_vm.$vuetify.theme.dark &&
                                        _vm.isTrue(_vm.titleUnderline),
                                      "darken-4":
                                        _vm.$vuetify.theme.dark &&
                                        _vm.isTrue(_vm.titleUnderline),
                                    },
                                    attrs: {
                                      tabindex: "0",
                                      role: "button",
                                      "aria-label":
                                        _vm.$t("fontStyleUnderline"),
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.toggleTitleUnderline.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.toggleTitleUnderline.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("U")]
                                ),
                              ]
                            ),
                            _c("div", { staticClass: "f-field-container" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "f-field f-field-2",
                                  attrs: {
                                    tabindex: "0",
                                    role: "button",
                                    "aria-label": _vm.$t("pickItemColorLabel", {
                                      item: _vm.$t("titleLabel"),
                                    }),
                                  },
                                  on: {
                                    keyup: [
                                      function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.focusColorPicker("T1")
                                      },
                                      function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "down",
                                            40,
                                            $event.key,
                                            ["Down", "ArrowDown"]
                                          )
                                        )
                                          return null
                                        return _vm.focusColorPicker("T1")
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c("pb-color-picker", {
                                    ref: "colorPickerT1",
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c("div", [
                                                _c(
                                                  "i",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass:
                                                          "fas fa-pen",
                                                        attrs: {
                                                          "aria-hidden": "true",
                                                        },
                                                      },
                                                      "i",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  )
                                                ),
                                                _c("div", {
                                                  staticClass:
                                                    "f-field-2-color-bar",
                                                  style: {
                                                    backgroundColor:
                                                      _vm.titleColor,
                                                  },
                                                }),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3364770844
                                    ),
                                    model: {
                                      value: _vm.titleColor,
                                      callback: function ($$v) {
                                        _vm.titleColor = $$v
                                      },
                                      expression: "titleColor",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "f-field f-field-2",
                                  attrs: {
                                    tabindex: "0",
                                    role: "button",
                                    "aria-label": _vm.$t("pickItemColorLabel", {
                                      item: _vm.$t("fillLabel"),
                                    }),
                                  },
                                  on: {
                                    keyup: [
                                      function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.focusColorPicker("T2")
                                      },
                                      function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "down",
                                            40,
                                            $event.key,
                                            ["Down", "ArrowDown"]
                                          )
                                        )
                                          return null
                                        return _vm.focusColorPicker("T2")
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c("pb-color-picker", {
                                    ref: "colorPickerT2",
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c("div", [
                                                _c(
                                                  "i",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass:
                                                          "fas fa-fill-drip",
                                                        attrs: {
                                                          "aria-hidden": "true",
                                                        },
                                                      },
                                                      "i",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  )
                                                ),
                                                _c("div", {
                                                  staticClass:
                                                    "f-field-2-color-bar",
                                                  style: {
                                                    backgroundColor:
                                                      _vm.titleFillColor,
                                                  },
                                                }),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3132832421
                                    ),
                                    model: {
                                      value: _vm.titleFillColor,
                                      callback: function ($$v) {
                                        _vm.titleFillColor = $$v
                                      },
                                      expression: "titleFillColor",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                  _vm.withShowTitle && _vm.formatTitle
                    ? _c(
                        "div",
                        [
                          _c("v-switch", {
                            staticClass: "pa-0 ma-0 mb-3",
                            attrs: {
                              dense: "",
                              inset: "",
                              "hide-details": "",
                              label: _vm.$t("showTitleInPlanViewLabel"),
                            },
                            model: {
                              value: _vm.showTitle,
                              callback: function ($$v) {
                                _vm.showTitle = $$v
                              },
                              expression: "showTitle",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.formatBody
                    ? _c("div", [
                        _c("div", [_vm._v(_vm._s(_vm.$t("bodyLabel")))]),
                        _c("div", { staticClass: "f-container" }, [
                          _c("div", { staticClass: "f-field-container mr-5" }, [
                            _c(
                              "div",
                              {
                                staticClass: "f-field py-1",
                                staticStyle: { "max-width": "160px" },
                              },
                              [
                                _c("v-select", {
                                  staticClass: "pa-0 ma-0",
                                  attrs: {
                                    height: "26px",
                                    flat: "",
                                    solo: "",
                                    "hide-details": "",
                                    items: _vm.fonts,
                                    "aria-label": _vm.$t("itemFontLabel", {
                                      item: _vm.$t("bodyLabel"),
                                    }),
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "selection",
                                        fn: function (data) {
                                          return [
                                            _c(
                                              "span",
                                              {
                                                style: {
                                                  fontFamily: data.item.text,
                                                },
                                              },
                                              [_vm._v(_vm._s(data.item.text))]
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "item",
                                        fn: function (data) {
                                          return [
                                            _c(
                                              "v-list-item",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    style: {
                                                      fontFamily:
                                                        data.item.text,
                                                    },
                                                    attrs: {
                                                      value: data.item.value,
                                                    },
                                                  },
                                                  "v-list-item",
                                                  data.attrs,
                                                  false
                                                ),
                                                data.on
                                              ),
                                              [_vm._v(_vm._s(data.item.text))]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3790022054
                                  ),
                                  model: {
                                    value: _vm.bodyFont,
                                    callback: function ($$v) {
                                      _vm.bodyFont = $$v
                                    },
                                    expression: "bodyFont",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "f-field py-1",
                                staticStyle: { "max-width": "80px" },
                              },
                              [
                                _c("v-select", {
                                  staticClass: "pa-0 ma-0",
                                  attrs: {
                                    height: "26px",
                                    flat: "",
                                    solo: "",
                                    "hide-details": "",
                                    items: _vm.fontSizes,
                                    "aria-label": _vm.$t("itemFontSizeLabel", {
                                      item: _vm.$t("bodyLabel"),
                                    }),
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "selection",
                                        fn: function (data) {
                                          return [
                                            _c(
                                              "span",
                                              {
                                                style: {
                                                  fontSize: data.item.text,
                                                },
                                              },
                                              [_vm._v(_vm._s(data.item.text))]
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "item",
                                        fn: function (data) {
                                          return [
                                            _c(
                                              "v-list-item",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    style: {
                                                      fontSize: data.item.text,
                                                    },
                                                    attrs: {
                                                      value: data.item.value,
                                                    },
                                                  },
                                                  "v-list-item",
                                                  data.attrs,
                                                  false
                                                ),
                                                data.on
                                              ),
                                              [_vm._v(_vm._s(data.item.text))]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    4156867078
                                  ),
                                  model: {
                                    value: _vm.bodyFontSize,
                                    callback: function ($$v) {
                                      _vm.bodyFontSize = $$v
                                    },
                                    expression: "bodyFontSize",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "f-field-container mr-5" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "f-field f-field-3 font-weight-bold text-center",
                                class: {
                                  "blue-grey": _vm.isTrue(_vm.bodyBold),
                                  "lighten-5":
                                    !_vm.$vuetify.theme.dark &&
                                    _vm.isTrue(_vm.bodyBold),
                                  "darken-4":
                                    _vm.$vuetify.theme.dark &&
                                    _vm.isTrue(_vm.bodyBold),
                                },
                                attrs: {
                                  tabindex: "0",
                                  role: "button",
                                  "aria-label": _vm.$t("fontStyleBold"),
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.toggleBodyBold.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.toggleBodyBold.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [_vm._v("B")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "f-field f-field-3 font-weight-bold font-italic text-center",
                                class: {
                                  "blue-grey": _vm.isTrue(_vm.bodyItalic),
                                  "lighten-5":
                                    !_vm.$vuetify.theme.dark &&
                                    _vm.isTrue(_vm.bodyItalic),
                                  "darken-4":
                                    _vm.$vuetify.theme.dark &&
                                    _vm.isTrue(_vm.bodyItalic),
                                },
                                attrs: {
                                  tabindex: "0",
                                  role: "button",
                                  "aria-label": _vm.$t("fontStyleItalic"),
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.toggleBodyItalic.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.toggleBodyItalic.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [_vm._v("I")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "f-field f-field-3 font-weight-bold text-decoration-underline text-center",
                                class: {
                                  "blue-grey": _vm.isTrue(_vm.bodyUnderline),
                                  "lighten-5":
                                    !_vm.$vuetify.theme.dark &&
                                    _vm.isTrue(_vm.bodyUnderline),
                                  "darken-4":
                                    _vm.$vuetify.theme.dark &&
                                    _vm.isTrue(_vm.bodyUnderline),
                                },
                                attrs: {
                                  tabindex: "0",
                                  role: "button",
                                  "aria-label": _vm.$t("fontStyleUnderline"),
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.toggleBodyUnderline.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.toggleBodyUnderline.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [_vm._v("U")]
                            ),
                          ]),
                          _c("div", { staticClass: "f-field-container mr-5" }, [
                            _c(
                              "div",
                              {
                                staticClass: "f-field f-field-2",
                                attrs: {
                                  tabindex: "0",
                                  role: "button",
                                  "aria-label": "pick body text color",
                                },
                                on: {
                                  keyup: [
                                    function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.focusColorPicker("B1")
                                    },
                                    function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "down",
                                          40,
                                          $event.key,
                                          ["Down", "ArrowDown"]
                                        )
                                      )
                                        return null
                                      return _vm.focusColorPicker("B1")
                                    },
                                  ],
                                },
                              },
                              [
                                _c("pb-color-picker", {
                                  ref: "colorPickerB1",
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c("div", [
                                              _c(
                                                "i",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass: "fas fa-pen",
                                                      attrs: {
                                                        "aria-hidden": "true",
                                                      },
                                                    },
                                                    "i",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                )
                                              ),
                                              _c("div", {
                                                staticClass:
                                                  "f-field-2-color-bar",
                                                style: {
                                                  backgroundColor:
                                                    _vm.bodyColor,
                                                },
                                              }),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2640476204
                                  ),
                                  model: {
                                    value: _vm.bodyColor,
                                    callback: function ($$v) {
                                      _vm.bodyColor = $$v
                                    },
                                    expression: "bodyColor",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "f-field f-field-2",
                                attrs: {
                                  tabindex: "0",
                                  role: "button",
                                  "aria-label": _vm.$t("pickItemColorLabel", {
                                    item: _vm.$t("fillLabel"),
                                  }),
                                },
                                on: {
                                  keyup: [
                                    function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.focusColorPicker("B2")
                                    },
                                    function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "down",
                                          40,
                                          $event.key,
                                          ["Down", "ArrowDown"]
                                        )
                                      )
                                        return null
                                      return _vm.focusColorPicker("B2")
                                    },
                                  ],
                                },
                              },
                              [
                                _c("pb-color-picker", {
                                  ref: "colorPickerB2",
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c("div", [
                                              _c(
                                                "i",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "fas fa-fill-drip",
                                                      attrs: {
                                                        "aria-hidden": "true",
                                                      },
                                                    },
                                                    "i",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                )
                                              ),
                                              _c("div", {
                                                staticClass:
                                                  "f-field-2-color-bar",
                                                style: {
                                                  backgroundColor:
                                                    _vm.bodyFillColor,
                                                },
                                              }),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1943314805
                                  ),
                                  model: {
                                    value: _vm.bodyFillColor,
                                    callback: function ($$v) {
                                      _vm.bodyFillColor = $$v
                                    },
                                    expression: "bodyFillColor",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.stickerId > 0 || _vm.showStickerFormatOption
                    ? _c("div", [
                        _c("div", [_vm._v(_vm._s(_vm.$t("stickerLabel")))]),
                        _c("div", { staticClass: "f-container" }, [
                          _c("div", { staticClass: "f-field-container mr-5" }, [
                            _c(
                              "div",
                              {
                                staticClass: "f-field py-1",
                                staticStyle: { "max-width": "160px" },
                              },
                              [
                                _c("v-select", {
                                  staticClass: "pa-0 ma-0",
                                  attrs: {
                                    height: "26px",
                                    flat: "",
                                    solo: "",
                                    "hide-details": "",
                                    items: _vm.stickerBackgroundTypes,
                                    "aria-label": _vm.$t("stickerStyleLabel"),
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "selection",
                                        fn: function (data) {
                                          return [
                                            _c("span", [
                                              _vm._v(_vm._s(data.item.text)),
                                            ]),
                                          ]
                                        },
                                      },
                                      {
                                        key: "item",
                                        fn: function (data) {
                                          return [
                                            _c(
                                              "v-list-item",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      value: data.item.value,
                                                    },
                                                  },
                                                  "v-list-item",
                                                  data.attrs,
                                                  false
                                                ),
                                                data.on
                                              ),
                                              [_vm._v(_vm._s(data.item.text))]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    129172960
                                  ),
                                  model: {
                                    value: _vm.stickerBackgroundStyle,
                                    callback: function ($$v) {
                                      _vm.stickerBackgroundStyle = $$v
                                    },
                                    expression: "stickerBackgroundStyle",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "f-field py-1",
                                staticStyle: { "max-width": "80px" },
                              },
                              [
                                _c("v-select", {
                                  staticClass: "pa-0 ma-0",
                                  attrs: {
                                    height: "26px",
                                    flat: "",
                                    solo: "",
                                    "hide-details": "",
                                    items: _vm.stickerSizes,
                                    "aria-label": _vm.$t("stickersSizeLabel"),
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "selection",
                                        fn: function (data) {
                                          return [
                                            _c(
                                              "span",
                                              { style: { fontSize: "12pt" } },
                                              [_vm._v(_vm._s(data.item.text))]
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "item",
                                        fn: function (data) {
                                          return [
                                            _c(
                                              "v-list-item",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    style: { fontSize: "12pt" },
                                                    attrs: {
                                                      value: data.item.value,
                                                    },
                                                  },
                                                  "v-list-item",
                                                  data.attrs,
                                                  false
                                                ),
                                                data.on
                                              ),
                                              [_vm._v(_vm._s(data.item.text))]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3804304422
                                  ),
                                  model: {
                                    value: _vm.stickerSize,
                                    callback: function ($$v) {
                                      _vm.stickerSize = $$v
                                    },
                                    expression: "stickerSize",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "f-field-container-single-picker mr-5",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "f-field f-field-1",
                                  attrs: {
                                    tabindex: "0",
                                    role: "button",
                                    "aria-label": _vm.$t("pickItemColorLabel", {
                                      item: _vm.$t("stickerLabel"),
                                    }),
                                  },
                                  on: {
                                    keyup: [
                                      function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.focusColorPicker("S1")
                                      },
                                      function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "down",
                                            40,
                                            $event.key,
                                            ["Down", "ArrowDown"]
                                          )
                                        )
                                          return null
                                        return _vm.focusColorPicker("S1")
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c("pb-color-picker", {
                                    ref: "colorPickerS1",
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c("div", [
                                                _c(
                                                  "i",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass:
                                                          "fas fa-fill-drip",
                                                        attrs: {
                                                          "aria-hidden": "true",
                                                        },
                                                      },
                                                      "i",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  )
                                                ),
                                                _c("div", {
                                                  staticClass:
                                                    "f-field-2-color-bar",
                                                  style: {
                                                    backgroundColor:
                                                      _vm.stickerBackgroundColor,
                                                  },
                                                }),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      784534453
                                    ),
                                    model: {
                                      value: _vm.stickerBackgroundColor,
                                      callback: function ($$v) {
                                        _vm.stickerBackgroundColor = $$v
                                      },
                                      expression: "stickerBackgroundColor",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ]),
          _c(
            "v-card-actions",
            { staticClass: "justify-center" },
            [
              _c(
                "v-btn",
                {
                  attrs: { small: "" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      _vm.showMenu = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("doneLabel")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }