
import CommonUtils from '@/utils/common-utils';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace, State } from 'vuex-class';

const settings = namespace('settings');
const drawer = namespace('drawer');
const plans = namespace('plans');
const teachers = namespace('teachers');
const students = namespace('students');
const prints = namespace('prints');

@Component
export default class BaseToolbar extends Vue {
  localToolbarDisabled = false;
  localRefreshKey = CommonUtils.generateUUID();
  localRawSearchText = '';

  @Prop({ required: false, type: Boolean, default: false })
  searchable!: boolean;

  @Prop({ type: String })
  search!: string;

  @settings.State
  studentId!: number;

  @settings.State
  studentPhotoUrl!: string;

  @settings.State
  teacherEmail!: string;

  @settings.Getter('getUserMode')
  userMode!: string;

  @settings.Getter('getUserType')
  userType!: string;

  @settings.Getter
  allowViewTeachers!: string;

  @settings.Getter('getDisplayName')
  displayName!: any;

  @drawer.State('drawerLeft')
  drawerLeft!: boolean;

  @drawer.Mutation
  setDrawerLeft!: (v: boolean) => void;

  @drawer.Mutation
  setDrawerRight!: (v: boolean) => void;

  @drawer.Mutation
  setDrawerRightMode!: (v: string) => void;

  @settings.Getter('getUserInfo')
  userInfo!: any;

  @settings.Getter('getBrowserWidth')
  browserWidth!: number;

  @settings.Getter
  getDisplaySettings!:any;

  @plans.Action
  changeUserMode!: (param?: any) => Promise<any>;

  @State
  currentPageInstance!: any;

  @drawer.State('drawerRightMode')
  drawerRightMode!: string;

  @settings.Getter
  hasNewAdminComment!: boolean;

  @settings.Getter
  hasNewTeacherComment!: boolean;

  @teachers.Getter('getTeachersOnly')
  adminItems!: any[];

  @Watch('search')
  updateLocalValue(v: string) {
    if (CommonUtils.hasText(v)) {
      this.localRawSearchText = v;
    } else {
      this.search = '';
    }
  }

  get localSearchText():any {
    if (this.searchable) {
      return this.localRawSearchText;
    } else {
      return this.localTitle;
    }
  }

  set localSearchText(val:any) {
    this.localRawSearchText = val;
    this.$emit('update:search', this.localRawSearchText);
  }

  get withBadge():boolean {
    return ((this.hasNewAdminComment && this.userMode === 'T') || (this.hasNewTeacherComment && this.userMode === 'S') || (this.hasNewTeacherCommentForAdmin));
  }

  get hasNewTeacherCommentForAdmin() {
    if (this.$currentUser.isAdmin) {
      return (this.adminItems || []).findIndex(item => {
        return CommonUtils.isTrue(item.newCommentT);
      }) > -1;
    }
    return false;
  }

  @Watch('browserWidth')
  onBrowserWidthChanged() {
    this.localRefreshKey = CommonUtils.generateUUID();
  }

  @Watch('browserHeight')
  onBrowserHeightChanged() {
    this.localRefreshKey = CommonUtils.generateUUID();
  }

  @prints.Action
  initPrint!: (value?: any) => Promise<any>;

  get isGenericView() {
    return CommonUtils.hasText(this.$route?.query.t) && CommonUtils.hasText(this.$route?.query.k);
  }

  get userSettingsLabel() {
    if (this.isGenericView) {
      if (this.$currentUser.isStudent) {
        if (CommonUtils.hasText(this.displayName)) {
          return this.displayName;
        } else if (CommonUtils.hasText(this.userInfo.accountSettings.firstName) && CommonUtils.hasText(this.userInfo.accountSettings.lastName)) {
          return this.userInfo.accountSettings.firstName + ' ' + this.userInfo.accountSettings.lastName;
        } else {
          return this.teacherEmail;
        }
      } else {
        return this.teacherEmail;
      }
    } else {
      return this.$t('userSettingsLabel');
    }
  }

  get localDrawerRight() {
    return this.$store.state.drawer.drawerRight && this.drawerRightMode === 'account';
  }

  set localDrawerRight(value: boolean) {
    this.setDrawerRightMode('account');
    this.$nextTick(() => {
      this.setDrawerRight(value);
    });
  }

  get profilePhotoUrl() {
    try {
      if (this.userMode === 'S' && this.studentId > 0) {
        return this.studentPhotoUrl;
      } else {
        return this.userInfo.accountSettings.photoUrl;
      }
    } catch (e) {
      return '';
    }
  }

  get canSwitchModes() {
    if (this.userMode === 'S') {
      return false;
    }
    if (
      this.userType === 'T' &&
      this.allowViewTeachers &&
      this.userMode !== 'S'
    ) {
      return ['plans', 'account'].includes(this.localPage);
    }
    return [
      'plans',
      'events',
      'grades',
      'lesson-banks',
      'messages',
      'school-years',
      'reporting',
      'students',
      'assign-students',
      'templates',
      'account',
      'lesson-layouts',
      'strategies',
      'sharing-options',
      'my-list',
      'school-list'
    ].includes(this.localPage);
  }

  get hasProfilePhoto() {
    return CommonUtils.hasText(this.profilePhotoUrl);
  }

  get localUserModeList() {
    return [
      {
        text: ['A', 'B', 'D'].includes(this.$currentUser.userType) ? this.$t('adminLabel') as string : this.$t('schoolLabel') as string,
        value: 'A'
      },
      {
        text: this.$t('teacherLabel') as string,
        value: 'T'
      }
    ];
  }

  get userModeFirstLetter() {
    for (let x = 0; x < this.localUserModeList.length; x++) {
      if (this.localUserModeList[x].value === this.userMode) {
        return this.localUserModeList[x].text.substr(0, 1);
      }
    }
    return '..';
  }

  get isCompact() {
    return this.browserWidth < 1230;
  }

  get localTitle() {
    return this.$route.name;
  }

  get localPage() {
    return CommonUtils.getPage(this.$route);
  }

  get isEmbedded(): boolean {
    return (CommonUtils.hasText(this.$router.currentRoute.query.embedded) && this.$router.currentRoute.query.embedded === 'true');
  }

  get mobileMode() {
    return (this.browserWidth < 960)
  }

  get leftDrawerMenuType():number {
    return this.getDisplaySettings.leftDrawerMenuType;
  }

  get isLeftDrawerMenuTypeHidden(): boolean {
    return this.leftDrawerMenuType === 2;
  }

  emptyFunction(...args: any[]) {
    console.log(`Calling BaseToolbar.emptyFunction(${args || ''})`);
  }

  hideRightDrawer() {
    if (this.localDrawerRight) {
      this.localDrawerRight = false;
    }
  }

  showPrintOptions(printOptions: any, printView: string) {
    return printOptions.print({ printView });
  }

  async userModeChanged(param: string) {
    if (this.userMode !== param) {
      if (param === 'A' && this.localPage === 'my-list') {
        this.$router.replace('school-list');
      } else if (param === 'T' && this.localPage === 'school-list') {
        this.$router.replace('my-list');
      }
      await this.changeUserMode(param);
      this.$eventBus.$emit('userModeChanged', param);
    }
  }

  created() {
    this.$nextTick(() => {
      this.$eventBus.$on('toolbarDisable', () => {
        this.localToolbarDisabled = true;
      });
      this.$eventBus.$on('toolbarEnable', () => {
        this.localToolbarDisabled = false;
      });
    });
  }

  beforeDestroy() {
    this.$eventBus.$off('toolbarDisable');
    this.$eventBus.$off('toolbarEnable');
  }
}
