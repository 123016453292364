var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "ma-1", attrs: { align: "center" } },
        [
          _c("v-select", {
            ref: "selectFontType",
            staticClass: "pa-1",
            attrs: {
              dense: _vm.dense1,
              items: _vm.fontList,
              "hide-details": "",
              outlined: "",
            },
            model: {
              value: _vm.localFont1,
              callback: function ($$v) {
                _vm.localFont1 = $$v
              },
              expression: "localFont1",
            },
          }),
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c("v-select", {
                staticClass: "pa-1",
                attrs: {
                  dense: _vm.dense1,
                  items: _vm.fontSizesList,
                  "hide-details": "",
                  outlined: "",
                },
                model: {
                  value: _vm.localFontSize1,
                  callback: function ($$v) {
                    _vm.localFontSize1 = $$v
                  },
                  expression: "localFontSize1",
                },
              }),
            ],
            1
          ),
          _c(
            "v-btn-toggle",
            {
              staticClass: "px-2 py-1",
              attrs: { color: "primary", multiple: "", dense: _vm.dense1 },
              model: {
                value: _vm.localToggleValues1,
                callback: function ($$v) {
                  _vm.localToggleValues1 = $$v
                },
                expression: "localToggleValues1",
              },
            },
            [
              _c(
                "v-btn",
                { attrs: { "aria-label": _vm.$t("fontStyleBold") } },
                [_c("v-icon", [_vm._v("fal fa-bold")])],
                1
              ),
              _c(
                "v-btn",
                { attrs: { "aria-label": _vm.$t("fontStyleItalic") } },
                [_c("v-icon", [_vm._v("fal fa-italic")])],
                1
              ),
              _c(
                "v-btn",
                { attrs: { "aria-label": _vm.$t("fontStyleUnderline") } },
                [_c("v-icon", [_vm._v("fal fa-underline")])],
                1
              ),
            ],
            1
          ),
          _c("pb-color-picker", {
            staticClass: "px-2 py-1",
            attrs: {
              icon: "fas fa-paint-brush",
              origin: _vm.origin1,
              label: _vm.$t("textColorLabel"),
            },
            model: {
              value: _vm.localForegroundColor1,
              callback: function ($$v) {
                _vm.localForegroundColor1 = $$v
              },
              expression: "localForegroundColor1",
            },
          }),
          _vm.textAndFill
            ? _c("pb-color-picker", {
                staticClass: "px-2 py-1",
                attrs: { icon: "fas fa-fill", origin: _vm.origin2 },
                model: {
                  value: _vm.localBackgroundColor1,
                  callback: function ($$v) {
                    _vm.localBackgroundColor1 = $$v
                  },
                  expression: "localBackgroundColor1",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.dualRow
        ? _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c("v-select", {
                staticClass: "pa-1",
                attrs: {
                  dense: _vm.dense2,
                  items: _vm.fontList,
                  "hide-details": "",
                  outlined: "",
                },
                model: {
                  value: _vm.localFont2,
                  callback: function ($$v) {
                    _vm.localFont2 = $$v
                  },
                  expression: "localFont2",
                },
              }),
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c("v-select", {
                    staticClass: "pa-1",
                    attrs: {
                      dense: _vm.dense2,
                      items: _vm.fontSizesList,
                      "hide-details": "",
                      outlined: "",
                    },
                    model: {
                      value: _vm.localFontSize2,
                      callback: function ($$v) {
                        _vm.localFontSize2 = $$v
                      },
                      expression: "localFontSize2",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-btn-toggle",
                {
                  staticClass: "px-2 py-1",
                  attrs: { color: "primary", multiple: "", dense: _vm.dense2 },
                  model: {
                    value: _vm.localToggleValues2,
                    callback: function ($$v) {
                      _vm.localToggleValues2 = $$v
                    },
                    expression: "localToggleValues2",
                  },
                },
                [
                  _c(
                    "v-btn",
                    { attrs: { "aria-label": _vm.$t("fontStyleBold") } },
                    [_c("v-icon", [_vm._v("fal fa-bold")])],
                    1
                  ),
                  _c(
                    "v-btn",
                    { attrs: { "aria-label": _vm.$t("fontStyleItalic") } },
                    [_c("v-icon", [_vm._v("fal fa-italic")])],
                    1
                  ),
                  _c(
                    "v-btn",
                    { attrs: { "aria-label": _vm.$t("fontStyleUnderline") } },
                    [_c("v-icon", [_vm._v("fal fa-underline")])],
                    1
                  ),
                ],
                1
              ),
              _c("pb-color-picker", {
                staticClass: "px-2 py-1",
                attrs: {
                  icon: "fas fa-paint-brush",
                  origin: _vm.origin1,
                  label: _vm.$t("textColorLabel"),
                },
                model: {
                  value: _vm.localForegroundColor2,
                  callback: function ($$v) {
                    _vm.localForegroundColor2 = $$v
                  },
                  expression: "localForegroundColor2",
                },
              }),
              _vm.textAndFill
                ? _c("pb-color-picker", {
                    staticClass: "px-2 py-1",
                    attrs: { icon: "fas fa-fill", origin: _vm.origin2 },
                    model: {
                      value: _vm.localBackgroundColor2,
                      callback: function ($$v) {
                        _vm.localBackgroundColor2 = $$v
                      },
                      expression: "localBackgroundColor2",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }