



















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const integration = namespace('integration');
type RosteredItemType = {
  School: 'school',
  Teacher: 'teacher',
  SchoolYear: 'schoolyear',
  Subject: 'subject',
  Student: 'student'
};

@Component
export default class RosteredItemIndicator extends Vue {
  @Prop({
    required: true,
    type: Object,
    default: { isItemRostered: false }
  })
  item!: any;

  @Prop({
    required: true,
    type: String
  })
  itemType!: RosteredItemType;

  @integration.Getter
  isRosteredItemConnected!: (param: any) => boolean;

  localItem:any = this.item;

  get localLabel() {
    return 'rostered' + this.itemType + 'Label';
  }

  get localItemsAndType() {
    return {
      itemType: this.itemType,
      ...this.localItem
    }
  }

  @Watch('item')
  onItemChanged(item:any) {
    this.localItem = item;
  }
}
