


















































































import { Component, Vue, Prop } from 'vue-property-decorator';
import MyStrategiesEditorForm from '@/components/mystrategies/MyStrategiesEditorForm.vue';
import Confirm from '../core/Confirm.vue';
import { namespace } from 'vuex-class';
import CommonUtils from '@/utils/common-utils';
import { Editor } from '@/types/global.types';

const mystrategies = namespace('mystrategies');
const settings = namespace('settings');

@Component({
  components: {
    MyStrategiesEditorForm
  }
})
export default class MyStrategiesEditor extends Vue implements Editor {
  @Prop({ type: Boolean })
  value!: boolean;

  @Prop({ type: Boolean, default: true })
  isModal!: boolean;

  @Prop({
    type: Object,
    default: () => {
      return { loadData: true, data: {} };
    }
  })
  input!: any

  $refs!: {
    form: MyStrategiesEditorForm,
    strategyEditorConfirm: Confirm
  }

  @mystrategies.State
  listLoading!: boolean;

  @mystrategies.Action
  deleteStrategy!: (params: any) => Promise<any>;

  @settings.Getter('getShowSnackbarNotifications')
  showSnackbarNotifications!: boolean;

  districtMenu = false;

  get localValue() {
    return this.value;
  }

  set localValue(val: boolean) {
    this.$emit('input', val);
  }

  get isEditing() {
    return (this.input.action === 'edit')
  }

  get localPage() {
    return CommonUtils.getPage(this.$route);
  }

  get localStrategy() {
    return this.$store.state.mystrategies.localStrategy;
  }

  isDirty() {
    return this.$refs.form && this.$refs.form.isDirty();
  }

  typeChange() {
    this.input.loadData = false;
  }

  close() {
    this.$refs.form.resetValidation();
    this.$eventBus.$emit('closeSubPage');
  }

  cancelEditor() {
    this.$eventBus.$emit('closeStrategyEditor', false);
  }

  addNewStrategy(closeOnComplete?: boolean) {
    this.districtMenu = false;
    return this.$refs.form.addNewStrategy().then((res) => {
      if (!res && closeOnComplete) {
        this.$eventBus.$emit('closeSubPage');
      }
      return Promise.resolve(res);
    });
  }

  addNewStrategyTo(arg: string, closeOnComplete?: boolean) {
    this.districtMenu = false;
    return this.$refs.form.addNewStrategyTo(arg).then((res) => {
      if (!res && closeOnComplete) {
        this.$eventBus.$emit('closeSubPage');
      }
      return Promise.resolve(res);
    });
  }

  doApply() {
    this.districtMenu = false;
    if (!this.$currentUser.isDistrictAdmin) {
      return this.addNewStrategy();
    } else {
      return Promise.resolve(true);
    }
    // return this.$refs.form.addNewStrategy();
  }

  deleteSelectedStrategy() {
    const id: Array<any> = [this.input.strategyObject.dbId];
    const that = this;
    this.$refs.strategyEditorConfirm.confirm({
      title: this.$t('strategyLabel'),
      text: this.$t('deleteStrategyLabel'),
      option1ButtonAlternativeText: this.$t('continueLabel')
    }).then((result) => {
      if (result === 1) {
        this.localPage === 'strategies' ? this.$store.commit('mystrategies/setListLoading', true) : CommonUtils.showLoading();
        that.deleteStrategy({ dbId: id }).then(() => {
          this.$eventBus.$emit('closeSubPage');
          this.$eventBus.$emit('closeStrategyEditor', true);
          if (this.showSnackbarNotifications) {
            if (this.$currentUser.isTeacher) {
              this.$snotify.success(this.$t('statusMsg54') as string);
            } else {
              this.$snotify.success(this.$t('statusMsg54b') as string);
            }
          }
        });
        return Promise.resolve(false);
      }
    });
  }

  created() {
    this.$eventBus.$on('deleteStrategy', (strategyIds: any) => {
      if (!this.input.strategyObject) {
        return;
      }
      for (const i in strategyIds) {
        if (strategyIds[i] === this.input.strategyObject.dbId) {
          this.$eventBus.$emit('closeSubPage');
        }
      }
    })
  }

  destroyed() {
    this.$eventBus.$off('deleteStrategy');
  }
}
