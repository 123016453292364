import { permissionsMapping, sections } from '@/constants';
import AppError, { createErrOptions, SessionExpiredError } from '@/errors';
import LessonServices from '@/services/lesson-services';
import { DeleteUnitRequest, SaveUnitRequest, UnitServices } from '@/services/unit-services';
import store from '@/store';
import CommonUtils from '@/utils/common-utils';
import ld from 'lodash';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true })
export default class Units extends VuexModule {
    private editorInitialized = false;
    private units: Array<any> = [];
    private haveUnits = false;
    private sections: Array<any> = [];
    private unit: any = Units.createUnit();
    private updatedFields: Set<string> = new Set<string>();
    listLoading = false;
    editMode = false;
    searchText = '';
    selectedRows: Array<any> = [];

    private static createUnit(rawUnit?: any): any {
      rawUnit = rawUnit || {};
      const unit = {
        unitNum: rawUnit.unitNum || '',
        unitTitle: rawUnit.unitTitle || '',
        unitDesc: rawUnit.unitDesc || '',
        unitColor: rawUnit.unitColor,
        color: rawUnit.color,
        unitStart: rawUnit.unitStart || '',
        unitEnd: rawUnit.unitEnd || '',
        unitLessonText: Units.getContent('L', rawUnit),
        unitHomeworkText: Units.getContent('H', rawUnit),
        unitNotesText: Units.getContent('N', rawUnit),
        unitSection4Text: Units.getContent('4', rawUnit),
        unitSection5Text: Units.getContent('5', rawUnit),
        unitSection6Text: Units.getContent('6', rawUnit),
        standards: rawUnit.standards || [],
        myStandards: rawUnit.myStandards || [],
        schoolStandards: rawUnit.schoolStandards || [],
        strategies: rawUnit.strategies || [],
        attachments: Units.copyAttachmentUrls(rawUnit.attachments || []),
        unitId: rawUnit.unitId || 0,
        subjectId: rawUnit.subjectId || 0,
        yearId: rawUnit.yearId || 0
      }
      return unit;
    }

    private static copyAttachmentUrls(attachments: Array<any>) {
      attachments.forEach(a => {
        a.url = a.url || a.attachmentUrl;
        a.attachmentUrl = a.attachmentUrl || a.url;
      });
      return attachments;
    }

    private static getContent(section: string, rawUnit: any) {
      return ((rawUnit.contents || []).find((c: any) => c.section === section) || {}).value || '';
    }

    get getUnits() {
      return this.units;
    }

    public get sectionsMap(): any {
      const map: any = {};
      this.sections.forEach(item => {
        map[item.section] = item;
      });
      return map;
    }

    public get enabledSections(): Array<any> {
      return this.sections.filter(s => s.enabled);
    }

    public get types(): Array<string> {
      const items: Array<any> = [];
      const contentTypes: any = {
        lesson: 'L',
        tab2: 'H',
        tab3: 'N',
        tab4: '4',
        tab5: '5',
        tab6: '6',
        myStandards: 'MS',
        schoolStandards: 'SS',
        standards: 'S'
      }
      this.sections.forEach(s => {
        const type = contentTypes[s.section];
        if (CommonUtils.hasText(type)) {
          items.push(type)
        }
      });
      return items;
    }

    public get getPermission(): (section: string) => string {
      return (section: string) => {
        const userMode = store.state.settings.userMode;
        const sharedClasses = store.getters['settings/getSharedClasses'];
        const userId = store.state.settings.userId;
        const sharedClass = sharedClasses[this.unit.subjectId];
        if (userMode === 'T' && sharedClass) {
          if (sharedClass.hasFullPermissions || sharedClass.teacherId === userId) {
            return 'write';
          } else if (sharedClass.permissionSettings) {
            return sharedClass.permissionSettings[permissionsMapping[section]];
          }
        } else {
          return 'write';
        }
      }
    }

    public get hasNoPermission(): (section: string) => boolean {
      return (section: string) => {
        const userMode = store.state.settings.userMode;
        const sharedClasses = store.getters['settings/getSharedClasses'];
        const userId = store.state.settings.userId;
        const sharedClass = sharedClasses[this.unit.subjectId];
        if (userMode === 'T' && sharedClass) {
          if (sharedClass.hasFullPermissions || sharedClass.teacherId === userId) {
            return false;
          } else if (sharedClass.permissionSettings) {
            return !['read', 'write'].includes(sharedClass.permissionSettings[permissionsMapping[section]]);
          } else {
            return true;
          }
        } else {
          return false;
        }
      }
    }

    public get canEdit(): (section: string) => boolean {
      return (section: string) => {
        const userMode = store.state.settings.userMode;
        const sharedClasses = store.getters['settings/getSharedClasses'];
        const userId = store.state.settings.userId;
        const sharedClass = sharedClasses[this.unit.subjectId];
        if (userMode === 'T' && sharedClass) {
          if (sharedClass.hasFullPermissions || sharedClass.teacherId === userId) {
            return true;
          } else if (sharedClass.permissionSettings) {
            return ['write'].includes(sharedClass.permissionSettings[permissionsMapping[section]]);
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    }

    public get canView(): (section: string) => boolean {
      return (section: string) => {
        const userMode = store.state.settings.userMode;
        const sharedClasses = store.getters['settings/getSharedClasses'];
        const userId = store.state.settings.userId;
        const sharedClass = sharedClasses[this.unit.subjectId];
        if (userMode === 'T' && sharedClass) {
          if (sharedClass.hasFullPermissions || sharedClass.teacherId === userId) {
            return true;
          } else if (sharedClass.permissionSettings) {
            return ['write', 'read'].includes(sharedClass.permissionSettings[permissionsMapping[section]]);
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    }

    get getListLoading() {
      return this.listLoading;
    }

    get currentSubjectId(): number {
      return (this.unit || { subjectId: 0 }).subjectId;
    }

    @Mutation
    public setEditorInitialized(editorInitialized: boolean): void {
      this.editorInitialized = editorInitialized;
    }

    @Mutation
    public addUpdatedField(value: string): void {
      if (this.editorInitialized) {
        const updatedFields = ld.cloneDeep(this.updatedFields);
        updatedFields.add(value);
        this.updatedFields = updatedFields;
      }
    }

    @Mutation
    public clearUpdatedFields(): void {
      this.updatedFields.clear();
    }

    @Mutation
    public setActiveTab(tabId: string): void {
      const clone = ld.cloneDeep(this.sections);
      for (const i in clone) {
        if (clone[i].section === tabId) {
          clone[i].active = true;
        } else {
          clone[i].active = false;
        }
      }
      this.sections = clone;
    }

    @Mutation
    initSections() {
      const currentSubjectId = store.getters['units/currentSubjectId'];
      const lessonLayoutId = store.getters['classes/getClassLessonLayoutId'](currentSubjectId);
      const userInfo: any = store.getters['settings/getUserInfo'];
      const defaultLessonSections = store.getters['settings/getLessonSections'];
      const displaySettings: any = userInfo.displaySettings || {};
      const lessonSections: any = LessonServices.getLessonSectionsByLayoutId(lessonLayoutId) || defaultLessonSections;
      const lessonStylings: any = LessonServices.getLessonStylingByLayoutId(lessonLayoutId) || displaySettings.lessonStyling;
      const canEdit: (section: string) => boolean = store.getters['units/canEdit'];
      const canView: (section: string) => boolean = store.getters['units/canView'];
      this.sections = [{
        section: 'description',
        displayOrder: -1,
        label: 'Description',
        enabled: true,
        active: false
      }];
      for (const i in sections) {
        const key = sections[i];
        const editable = canEdit(key);
        const viewable = canView(key);
        let lessonSection = lessonSections[key];
        if (lessonSection) {
          this.sections.push({
            section: key,
            displayOrder: lessonSection[key + 'DisplayOrder'],
            label: lessonSection[key + 'Label'],
            enabled: lessonSection[key + 'Enabled'] === 'Y',
            active: false,
            disabled: !editable && viewable,
            noAccess: !editable && !viewable,
            textStyle: {
              fontFamily: CommonUtils.getFontFamily(lessonSection[key + 'TextFont']),
              fontSize: (lessonSection[key + 'TextSize'] || '10') + 'pt'
            }
          });
        } else {
          lessonSection = lessonStylings[key];
          this.sections.push({
            section: key,
            displayOrder: lessonSection[key + 'DisplayOrder'],
            label: lessonSection[key + 'Label'],
            enabled: true,
            active: false,
            disabled: !editable && viewable,
            noAccess: !editable && !viewable,
            textStyle: {
              fontFamily: CommonUtils.getFontFamily(lessonSection[key + 'TextFont']),
              fontSize: (lessonSection[key + 'TextSize'] || '10') + 'pt'
            }
          });
        }
      }
      this.sections.sort((a, b) => +a.displayOrder - +b.displayOrder);
      const enabledSections = this.sections.filter(s => s.enabled);
      if (CommonUtils.isNotEmpty(enabledSections)) {
        enabledSections[0].active = true;
      }
    }

    @Mutation
    public initUnit(rawUnit: any): void {
      this.unit = Units.createUnit(rawUnit);
      this.updatedFields = new Set<string>();
    }

    @Mutation
    public setUnit(unit: any): void {
      this.unit = unit;
    }

    @Mutation
    public setUnits(units: Array<any>) {
      this.units = units;
    }

    @Mutation
    public setHaveUnits(haveUnits: boolean) {
      this.haveUnits = haveUnits;
    }

    @Mutation
    setListLoading(listLoading: boolean) {
      this.listLoading = listLoading;
    }

    @Mutation
    setEditMode(v: boolean) {
      this.editMode = v;
    }

    @Mutation
    setSearchText(v: string) {
      this.searchText = v;
    }

    @Mutation
    setSelectedRows(v: Array<any>) {
      this.selectedRows = v;
    }

    @Action({ rawError: true })
    public init(rawUnit: any): Promise<any> {
      if (rawUnit.loadData !== false) {
        this.context.commit('initUnit', rawUnit);
        this.context.commit('initSections');
        this.context.commit('clearUpdatedFields');
      }
      return Promise.resolve();
    }

    @Action({ rawError: true })
    public async loadUnits(): Promise<any> {
      try {
        if (this.haveUnits) {
          return Promise.resolve({ units: this.units });
        }
        const resp = await UnitServices.list('all', this.context.rootState.settings.userMode);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            const units = data.units || [];
            this.context.commit('setHaveUnits', true);
            this.context.commit('setUnits', units);
            return Promise.resolve({ units });
          }
        } else {
          return Promise.resolve({ units: this.units });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
      return Promise.resolve();
    }

    @Action({ rawError: true })
    public async loadYearsUnits(params: any): Promise<any> {
      try {
        const resp = await UnitServices.getUnits(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    public async getUnit(unitId: number): Promise<any> {
      try {
        const resp = await UnitServices.getUnit(unitId);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    private get createDeleteRequest(): DeleteUnitRequest {
      const request = this.createSaveRequest;
      request.action = 'D';
      return request as DeleteUnitRequest;
    }

    private get createSaveRequest(): SaveUnitRequest {
      const request: SaveUnitRequest = {
        unitId: this.unit.unitId,
        subjectId: this.unit.subjectId,
        unitNum: this.unit.unitNum,
        unitTitle: this.unit.unitTitle,
        action: this.unit.unitId > 0 ? 'C' : 'A',
        unitDesc: this.unit.unitDesc,
        unitColor: this.unit.unitColor,
        unitStart: this.unit.unitStart,
        unitEnd: this.unit.unitEnd,
        unitLessonText: this.unit.unitLessonText,
        unitHomeworkText: this.unit.unitHomeworkText,
        unitNotesText: this.unit.unitNotesText,
        unitSection4Text: this.unit.unitSection4Text,
        unitSection5Text: this.unit.unitSection5Text,
        unitSection6Text: this.unit.unitSection6Text,
        userMode: store.state.settings.userMode,
        types: this.types,
        standardDBIds: this.unit.standards.map((s:any) => s.dbId),
        myStandardDBIds: this.unit.myStandards.map((s:any) => s.dbId),
        schoolStandardDBIds: this.unit.schoolStandards.map((s:any) => s.dbId),
        strategiesIds: this.unit.strategies.map((s:any) => s.strategyId),
        attachmentNames: this.unit.attachments.map((s:any) => s.filename),
        attachmentPrivate: this.unit.attachments.map((s:any) => CommonUtils.booleanToString(s.privateFlag)),
        attachmentURL: this.unit.attachments.map((s:any) => s.attachmentUrl || s.url)
      };
      return request;
    }

    @Action({ rawError: true })
    public async deleteUnits(params: any): Promise<any> {
      try {
        params.userMode = store.state.settings.userMode;
        const resp = await UnitServices.deleteUnits(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            const units = data.units || [];
            this.context.commit('setHaveUnits', true);
            this.context.commit('setUnits', units);
            this.context.commit('classes/setHaveClass', false, { root: true });
            await this.context.dispatch('classes/loadClasses', null, { root: true });
            await this.context.dispatch('plans/reloadPlans', {}, { root: true });
            return Promise.resolve({ units });
          }
        } else {
          return Promise.resolve({ units: this.units });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
      return Promise.resolve();
    }

    @Action({ rawError: true })
    public async deleteUnitsFromLessonlist(params: any): Promise<any> {
      try {
        params.userMode = store.state.settings.userMode;
        const resp = await UnitServices.save(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            const units = data.units || [];
            this.context.commit('setHaveUnits', true);
            this.context.commit('setUnits', units);
            this.context.commit('classes/setHaveClass', false, { root: true });
            await this.context.dispatch('classes/loadClasses', null, { root: true });
            return Promise.resolve({ units });
          }
        } else {
          return Promise.resolve({ units: this.units });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    public async delete(): Promise<any> {
      try {
        const resp = await UnitServices.save(this.context.getters.createDeleteRequest);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            const units = data.units || [];
            this.context.commit('setHaveUnits', true);
            this.context.commit('setUnits', units);
            this.context.commit('classes/setHaveClass', false, { root: true });
            await this.context.dispatch('classes/loadClasses', null, { root: true });
            await this.context.dispatch('plans/reloadPlans', {}, { root: true });
            return Promise.resolve({ units });
          }
        } else {
          return Promise.resolve({ units: this.units });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
      return Promise.resolve();
    }

    @Action({ rawError: true })
    public async save(): Promise<any> {
      try {
        const resp = await UnitServices.save(this.context.getters.createSaveRequest);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            const units = data.units || [];
            const unit = data.unit;
            this.context.commit('setHaveUnits', true);
            this.context.commit('setUnit', unit);
            this.context.commit('setUnits', units);
            this.context.commit('classes/setHaveClass', false, { root: true });
            await this.context.dispatch('classes/loadClasses', null, { root: true });
            await this.context.dispatch('plans/reloadPlans', false, { root: true });
            return Promise.resolve({ unit, units });
          }
        } else {
          return Promise.resolve({ units: this.units });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
      return Promise.resolve();
    }
}
