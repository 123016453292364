var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "pb-base-editor",
    {
      attrs: {
        isModal: _vm.isModal,
        withActions: false,
        onApply: _vm.doApply,
        isDirty: _vm.isDirty,
        typeLabel: _vm.$t("permissionLabel")
      },
      on: { close: _vm.cancelEditor },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _vm.input.doWhat === "add"
                ? _c("span", [_vm._v(_vm._s(_vm.$t("addLabel")))])
                : _c("span", [_vm._v(_vm._s(_vm.$t("editLabel")))]),
              _c("span", [_vm._v(" " + _vm._s(_vm.$t("permissionLabel")))])
            ]
          },
          proxy: true
        },
        {
          key: "action-button",
          fn: function() {
            return [
              _vm.input.doWhat === "edit"
                ? _c(
                    "pb-btn",
                    {
                      attrs: { icon: "", label: _vm.$t("deleteLabel") },
                      on: { click: _vm.deletePermission }
                    },
                    [_c("v-icon", [_vm._v("fal fa-trash-alt")])],
                    1
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.localValue,
        callback: function($$v) {
          _vm.localValue = $$v
        },
        expression: "localValue"
      }
    },
    [
      _c("permissions-editor-form", {
        ref: "form",
        attrs: { input: _vm.input }
      }),
      _c("pb-confirm", { ref: "permissionEditorConfirm" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }