import qs from 'qs';
import axios from '@/axios';

export default class GoogleDriveServices {
  public static getGoogleDriveFiles() {
    const params = {};
    return axios.post('services/planbook/googledrive/files', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }
}
