
import { Component, Vue } from 'vue-property-decorator'
import { settingsItems, helpItems } from '../../constants'
import { State, namespace } from 'vuex-class';
import CommonUtils from '@/utils/common-utils';
import ld from 'lodash';
import os from '@/services/overview-services';
declare const SnapEngage: any;
declare const UserVoice: any;
const settings = namespace('settings');

@Component
export default class AccountMenu extends Vue {
  @settings.Getter('getDisplayName')
  displayName!: any;

  @settings.Getter('getEmailAddress')
  emailAddress!: any;

  @State('drawerRight')
  drawerRight!: boolean;

  @settings.Action
  signOut!: () => Promise<any>;

  @settings.Getter('getUserInfo')
  userInfo!: any;

  @settings.Getter('getUserMode')
  userMode!: string;

  @settings.Getter('getUserType')
  userType!: string;

  @settings.Getter
  allowViewTeachers!: string;

  @settings.State
  studentId!: number;

  @settings.State
  studentFirstName!: string;

  @settings.State
  studentLastName!: string;

  @settings.State
  studentEmailAddress!: string;

  @settings.State
  studentPhotoUrl!: string;

  userCardHeight = 210;

  get localDisplayName() {
    return this.displayName;
  }

  get localEmailAddress() {
    if (this.userMode === 'S' && this.studentId > 0) {
      if (CommonUtils.hasText(this.studentEmailAddress)) {
        return this.studentEmailAddress;
      } else {
        return 'Account';
      }
    } else {
      return this.emailAddress.replaceAll('@', '&#8203;@');
    }
  }

  get settingsItems(): Array<any> {
    return settingsItems.filter(s => {
      if (this.userMode === 'A' && this.userType === 'T' && this.allowViewTeachers) {
        return ['/account'].includes(s.to);
      }
      return s.userModes.includes(this.userMode);
    });
  }

  get helpItems(): Array<any> {
    return helpItems.filter(h => h.userModes.includes(this.userMode)).map(h => {
      const nh: any = ld.cloneDeep(h);
      nh.click = () => {
        console.log(nh.text);
      };
      if (h.to === '/refresh') {
        nh.to = '';
        nh.click = () => {
          location.reload();
          console.log(nh.text);
        }
      } else if (h.to === '/chat-us') {
        nh.to = '';
        nh.click = () => {
          SnapEngage.startLink();
          console.log(nh.text);
        }
      } else if (h.to === '/contact-us') {
        nh.to = '';
        return nh;
      } else if (h.to === '/overview') {
        nh.to = '';
        nh.click = () => {
          os.start();
        }
      }
      return nh;
    });
  }

  get profilePhotoUrl() {
    try {
      if (this.userMode === 'S' && this.studentId > 0) {
        return this.studentPhotoUrl;
      } else {
        return this.userInfo.accountSettings.photoUrl;
      }
    } catch (e) {
      return '';
    }
  }

  get hasProfilePhoto() {
    return CommonUtils.hasText(this.profilePhotoUrl);
  }

  get localAccountName() {
    if (this.userMode === 'S' && this.studentId > 0) {
      return this.studentFirstName + ' ' + this.studentLastName;
    } else {
      return this.displayName;
    }
  }

  get isGenericView() {
    return CommonUtils.hasText(this.$route?.query.t) && CommonUtils.hasText(this.$route?.query.k);
  }

  getId(item: any) {
    return item.to.replaceAll('/', '');
  }

  mounted() {
    try {
      UserVoice.push(['addTrigger', '#contact-us', { mode: 'contact', target: false, position: 'bottom-right' }]);
    } catch (e) {
    }
  }

  focusOnOpen() {
    this.$nextTick(() => {
      new Promise(resolve => setTimeout(resolve, 100)).then(() => {
        try {
          let i = (this.$refs as any).gotoItems.$children.findIndex((listItem: any) => {
            return listItem.$el.classList.contains('v-list-item--active');
          })
          if (i < 0) {
            i = 0;
          }
          (this.$refs as any)['menuItem' + i][0].$el.focus();
        } catch (e) {
          console.log(this.$t('focusFailed'));
        }
      });
      try {
        this.userCardHeight = (this.$refs as any).userCard.clientHeight;
      } catch (e) {}
    });
  }

  closeMenu() {
    this.$emit('close');
  }
}
