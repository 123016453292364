import AppError, { createErrOptions, SessionExpiredError } from '@/errors';
import SeatingChartServices from '@/services/seating-chart-services';
import DateTimeUtils from '@/utils/date-time-utils';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

interface Layout {
  rows: number;
  cols: number;
  id: number;
  name: string;
  settings: {
    flexible?: boolean;
    seatSettings: any[];
    flexibleCanvas: any[];
  };
}

interface SeatingAssignment {
  start: string;
  end: string;
  name: string;
  layoutId: number;
  subjectId: number;
  yearId: number;
  id: number;
  extraClass: number;
  cols: number;
  rows: number;
  layoutName: string;
  className: string;
  startToShow: string;
  endToShow: string;
  settings: {
    seatSettings: any[];
  }
}

@Module({ namespaced: true })
export default class SeatingCharts extends VuexModule {
  layouts: Layout[] = [];
  seatingAssignments: SeatingAssignment[] = [];
  students = [];

  get getRoomLayouts() {
    return this.layouts;
  }

  get getSeatingAssignments() {
    return this.seatingAssignments;
  }

  get getStudents() {
    return this.students;
  }

  @Mutation
  addLayout(layout: Layout) {
    this.layouts.push(layout);
  }

  @Mutation
  resetLayouts() {
    this.layouts = [];
  }

  @Mutation
  addSeating(seating: SeatingAssignment) {
    this.seatingAssignments.push(seating);
  }

  @Mutation
  resetSeating() {
    this.seatingAssignments = [];
  }

  @Mutation
  setStudents(students: []) {
    this.students = students;
  }

  @Action({ rawError: true })
  async loadRoomLayouts() {
    try {
      const resp = await SeatingChartServices.getRoomLayouts();

      if (resp) {
        const data = resp.data;

        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          this.context.commit('resetLayouts');

          for (const layout of data) {
            const typedLayout: Layout = {
              rows: +layout.rows,
              cols: +layout.cols,
              id: +layout.id,
              name: layout.name,
              settings: {
                flexible: layout.settings.flexible,
                seatSettings: [],
                flexibleCanvas: layout.settings.flexibleCanvas
              }
            };

            for (const seat of layout.settings.seatSettings) {
              typedLayout.settings.seatSettings.push({ id: +seat.id, disabled: seat.disabled === 'true' })
            }

            this.context.commit('addLayout', typedLayout);
          }

          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async loadStudents(params?: any) {
    try {
      const resp = await SeatingChartServices.getStudents(params);

      if (resp) {
        const data = resp.data;

        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          this.context.commit('setStudents', data);

          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async addRoomLayout(params?: any) {
    try {
      if (params) {
        const layout = {
          cols: params.cols,
          id: params.id,
          name: params.name,
          rows: params.rows,
          settings: {
            flexible: params.settings.flexible,
            seatSettings: params.settings.seatSettings,
            flexibleCanvas: params.settings.flexibleCanvas
          }
        }
        const resp = await SeatingChartServices.postRoomLayout(layout);

        if (resp) {
          const data = resp.data

          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async deleteRoomLayout(params?: any) {
    try {
      if (params) {
        const layout = {
          cols: params.cols,
          id: params.id,
          name: params.name,
          rows: params.rows,
          settings: {
            seatSettings: params.settings.seatSettings
          },
          allowDisabling: true
        }
        const resp = await SeatingChartServices.deleteRoomLayout(layout);

        if (resp) {
          const data = resp.data

          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async loadSeatingAssignments() {
    try {
      const resp = await SeatingChartServices.getSeatingAssignments();

      if (resp) {
        const data = resp.data;

        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          this.context.commit('resetSeating');

          for (const seating of data) {
            const typedSeating: SeatingAssignment = {
              start: seating.start,
              end: seating.end,
              name: seating.name,
              layoutId: +seating.layoutId,
              subjectId: +seating.subjectId,
              yearId: +seating.yearId,
              id: +seating.id,
              cols: +seating.cols,
              rows: +seating.rows,
              extraClass: +seating.extraClass,
              className: seating.className,
              layoutName: seating.layoutName,
              startToShow: DateTimeUtils.formatToDisplay(seating.start, true),
              endToShow: DateTimeUtils.formatToDisplay(seating.end, true),
              settings: {
                seatSettings: []
              }
            };

            for (const seat of seating.settings.seatSettings) {
              typedSeating.settings.seatSettings.push({ id: +seat.id, assignedId: +seat.assignedId, color: seat.color });
            }

            this.context.commit('addSeating', typedSeating);
          }

          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async addSeatingAssignment(params?: any) {
    try {
      if (params) {
        const resp = await SeatingChartServices.postSeatingAssignment(params);

        if (resp) {
          const data = resp.data;

          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async deleteSeatingAssignment(params?: any) {
    try {
      if (params) {
        const resp = await SeatingChartServices.deleteSeatingAssignment(params);

        if (resp) {
          const data = resp.data;

          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }
}
