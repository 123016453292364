
import CommonUtils from '@/utils/common-utils';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Confirm from './../../components/core/Confirm.vue';
import MessageEditorForm from '@/components/messages/MessageEditorForm.vue';
import { FormError } from '@/errors';
import { Editor } from '@/types/global.types';

const messages = namespace('messages');
const settings = namespace('settings');
const plans = namespace('plans');

@Component({
  components: {
    MessageEditorForm
  }
})
export default class MessageEditor extends Vue implements Editor {
  localValue = false;
  localInput: any = null;
  isViewing = false;
  isFromUser = true;
  isToUser = false;

  $refs!: {
    form: MessageEditorForm,
    confirm: Confirm
  }

  @Prop({ type: Boolean })
  value!: boolean;

  @Prop({ type: Boolean, default: false })
  isModal!: boolean;

  @Prop({
    type: Object,
    default: () => {
      return {};
    }
  })
  input!: any;

  @messages.Getter
  getRecipientsList!: any;

  @messages.Action
  loadRecipients!: () => Promise<any>;

  @messages.Action
  deleteMessage!: (params?: any) => Promise<any>;

  @messages.Action
  loadMessage!: (params?: any) => Promise<any>;

  @messages.State
  listLoading!: boolean;

  @settings.Getter('getUserId')
  userId!: any;

  @settings.Getter('getShowSnackbarNotifications')
  showSnackbarNotifications!: boolean;

  @plans.Action
  reloadPlans!: () => void;

  get localPage() {
    return CommonUtils.getPage(this.$route);
  }

  @Watch('value')
  updateLocalValue(v: boolean) {
    this.localValue = v;
  }

  @Watch('localValue')
  updateValue(v: boolean) {
    this.$emit('update:value', v);
  }

  created() {
    this.$eventBus.$on('deleteMessage', (messageIds: any) => {
      for (const i in messageIds) {
        if (this.input.objectId === messageIds[i]) {
          this.$eventBus.$emit('closeSubPage');
        }
      }
    })
    this.initialize();
  }

  destroyed() {
    this.$eventBus.$off('deleteMessage');
  }

  @Watch('input')
  initialize() {
    this.localInput = this.input;

    if (this.getRecipientsList.length === 0) {
      this.loadRecipients().then(() => {
        this.loadFormOptions();
      });
    } else {
      this.loadFormOptions();
    }
  }

  loadFormOptions() {
    if (this.input.action === 'view') {
      this.isViewing = true;
      if (!this.input.messageObject) {
        CommonUtils.showLoading();
        this.loadMessageInfo(this.input.objectId);
      } else {
        this.isFromUser = (this.input.messageObject.authorId === this.userId);
        this.isToUser = (this.input.messageObject.recipientId === this.userId);
      }
    } else {
      this.isViewing = false;
    }
  }

  loadMessageInfo(commentId: any) {
    this.loadMessage(commentId).then((d) => {
      this.localInput.messageObject = d.data.comments[0];
      this.isFromUser = (this.localInput.messageObject.authorId === this.userId);
      this.isToUser = (this.localInput.messageObject.recipientId === this.userId);
      this.$refs.form.initialize();
      CommonUtils.hideLoading();
    })
  }

  async doApply() {
    this.localPage === 'messages' ? this.$store.commit('messages/setListLoading', true) : CommonUtils.showLoading();
    return this.$refs.form.sendMessage().then(() => {
      if (this.showSnackbarNotifications) {
        this.$snotify.success(this.$t('statusMsg61') as string);
      }
      this.localValue = false;
      this.$eventBus.$emit('closeMessageEditor', true);
    }).catch((error: any) => {
      if (!(error instanceof FormError)) {
        return Promise.reject(error);
      } else {
        return Promise.resolve(true);
      }
    }).finally(() => {
      CommonUtils.hideLoading();
      this.$store.commit('messages/setListLoading', false);
      return Promise.resolve(false);
    });
  }

  replyToMessage() {
    this.$eventBus.$emit('openSubPage', {
      type: 'message',
      width: 600,
      input: {
        action: 'reply',
        recipient: this.input.messageObject.authorId,
        messageId: this.input.messageObject.commentId,
        schoolId: this.input.messageObject.schoolId
      }
    });
  }

  deleteSelectedMessage() {
    const that = this;
    this.$refs.confirm.confirm({
      title: this.$t('messageLabel'),
      text: this.$t('deleteMessageLabel'),
      option1ButtonAlternativeText: this.$t('continueLabel')
    }).then((result) => {
      if (result === 1) {
        CommonUtils.showLoading();
        that.deleteMessage({ commentId: this.input.objectId }).then(() => {
          if (this.showSnackbarNotifications) {
            this.$snotify.success(this.$t('statusMsg63') as string);
          }
          if (this.localPage === 'plans') {
            this.reloadPlans();
          }
          CommonUtils.hideLoading();
          this.$eventBus.$emit('closeMessageEditor', true);
          this.$eventBus.$emit('closeSubPage');
        });
      }
    });
  }

  isDirty() {
    const form: any = this.$refs.form;
    return form.changesMade();
  }

  typeChange() {
    this.input.loadData = false;
  }

  cancelEditor() {
    this.$eventBus.$emit('closeMessageEditor', false);
  }
}
