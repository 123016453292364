import ld from 'lodash';
import moment from 'moment-timezone';
import store from '../store';
import { timeZoneLocations } from './../constants/time-zone-location';
import CommonUtils from './common-utils';

export default class DateTimeUtils {
  public static formatToSave(s: string, userInfo?: any) {
    try {
      if (CommonUtils.hasText(s)) {
        userInfo = userInfo || store.getters['settings/getUserInfo'] || { displaySettings: { dateStyling: { dateFormat: 1, dateDelimiter: '/' } } };
        const displaySettings = userInfo.displaySettings;
        const dateFormat = +displaySettings.dateStyling.dateFormat || 1;
        const dateDelimiter = displaySettings.dateStyling.dateDelimiter || '/';
        let showFormat = 'MM/DD/YYYY';
        if (dateFormat === 2) {
          showFormat = 'DD/MM/YYYY';
        }
        showFormat = showFormat.replace(/\//g, dateDelimiter);
        const mToSaveDate = moment(s, showFormat);
        return mToSaveDate.format('MM/DD/YYYY');
      } else {
        return '';
      }
    } catch (e) {
      return '';
    }
  }

  public static formatTimeToSave(s: string, userInfo?: any) {
    try {
      if (CommonUtils.hasText(s)) {
        userInfo = userInfo || store.getters['settings/getUserInfo'] || { otherSettings: { timeFormat: 1 } };
        const otherSettings = userInfo.otherSettings;
        const timeFormat = +otherSettings.timeFormat || 1;
        let showFormat = 'h:mm A';
        if (timeFormat === 1) {
          showFormat = 'HH:mm';
        }
        const mToShowTime = moment(s, showFormat);
        return mToShowTime.format('h:mm A');
      }
      return '';
    } catch (e) {
      return '';
    }
  }

  public static formatToShow(s: string, userInfo?: any) {
    try {
      if (CommonUtils.hasText(s)) {
        userInfo = userInfo || store.getters['settings/getUserInfo'] || { displaySettings: { dateStyling: { dateFormat: 1, dateDelimiter: '/' } } };
        const displaySettings = userInfo.displaySettings;
        const dateFormat = +displaySettings.dateStyling.dateFormat || 1;
        const dateDelimiter = displaySettings.dateStyling.dateDelimiter || '/';
        let showFormat = 'MM/DD/YYYY';
        if (dateFormat === 2) {
          showFormat = 'DD/MM/YYYY';
        }
        showFormat = showFormat.replace(/\//g, dateDelimiter);
        const mToShowDate = moment(s, 'MM/DD/YYYY');
        return mToShowDate.format(showFormat);
      }
      return '';
    } catch (e) {
      return '';
    }
  }

  public static formatTimeToShow(s: string, userInfo?: any) {
    try {
      if (CommonUtils.hasText(s)) {
        userInfo = userInfo || store.getters['settings/getUserInfo'] || { otherSettings: { timeFormat: 1 } };
        const otherSettings = userInfo.otherSettings;
        const timeFormat = +otherSettings.timeFormat;
        let showFormat = 'h:mm A';
        if (timeFormat === 1) {
          showFormat = 'HH:mm';
        }
        const mToShowTime = moment(s, 'h:mm A');
        return mToShowTime.format(showFormat);
      }
      return '';
    } catch (e) {
      return '';
    }
  }

  public static stringifyDate(s: string, includeYear?: boolean) {
    return DateTimeUtils.formatToDisplay(s, includeYear);
  }

  public static formatToSort(s: string) {
    try {
      if (CommonUtils.hasText(s)) {
        return moment(s, 'MM/DD/YYYY').format('YYYY-MM-DD')
      }
      return '';
    } catch (e) {
      return '';
    }
  }

  public static formatToDisplay(s: string, includeYear?: boolean, excludeDay?: boolean) {
    try {
      const dateFormat = +store.getters['settings/getDateFormat'];
      if (CommonUtils.hasText(s)) {
        let format = excludeDay ? '' : 'ddd ';
        if (dateFormat === 2) {
          format += 'DD-MMM';
        } else {
          format += 'MMM DD';
        }
        if (includeYear) {
          format += ', YYYY';
        }
        return moment(s, 'MM/DD/YYYY').format(format);
      }
      return '';
    } catch (e) {
      return '';
    }
  }

  public static formatTimestampToDisplay(s: string, includeYear?: boolean) {
    try {
      if (CommonUtils.hasText(s)) {
        const userInfo = store.getters['settings/getUserInfo'] || { displaySettings: { dateStyling: { dateFormat: 1, dateDelimiter: '/' } } };
        const displaySettings = userInfo.displaySettings;
        const dateFormat = +displaySettings.dateStyling.dateFormat || '/';
        const otherSettings = userInfo.otherSettings;
        const timeFormat = +otherSettings.timeFormat || 1;
        let format = '';
        if (dateFormat === 2) {
          format = 'ddd DD-MMM';
        } else {
          format = 'ddd MMM DD';
        }
        if (includeYear) {
          format += ', YYYY';
        }
        if (timeFormat === 1) {
          format += ' HH:mm';
        } else {
          format += ' h:mm A';
        }
        const mToShowTime = moment(s, 'MM/DD/YYYY HH:mm:ss');
        return mToShowTime.format(format);
      }
      return '';
    } catch (e) {
      return '';
    }
  }

  public static addDays(d: Date, days: number) {
    d.setDate(d.getDate() + days);
    return d;
  }

  public static format(d: Date) {
    try {
      return DateTimeUtils.padLeftZero((d.getMonth() + 1), 2) + '/' + DateTimeUtils.padLeftZero(d.getDate(), 2) + '/' + d.getFullYear();
    } catch (e) {
    }
    return '';
  }

  public static toDate(s: string): Date {
    if (!s || s.length < 10) {
      return undefined as any;
    }
    if (s.includes('/')) {
      const values = s.split('/');
      if (values.length === 3) {
        return new Date(values[2] + '-' + values[0] + '-' + values[1] + 'T00:00:00');
      }
    } else if (s.includes('-')) {
      return new Date(s + 'T00:00:00');
    }
    return undefined as any;
  }

  static padLeftZero(str: any, len: number): string {
    str = str.toString();
    return str.length < len ? DateTimeUtils.padLeftZero('0' + str, len) : str;
  }

  public static getMonday(date: Date): Date {
    const monday = new Date();
    monday.setTime(date.getTime())
    const day = monday.getDay();
    switch (day) {
      case 0: monday.setDate(monday.getDate() + 1); break;
      case 1: monday.setDate(monday.getDate() + 0); break;
      case 2: monday.setDate(monday.getDate() - 1); break;
      case 3: monday.setDate(monday.getDate() - 2); break;
      case 4: monday.setDate(monday.getDate() - 3); break;
      case 5: monday.setDate(monday.getDate() - 4); break;
      default: monday.setDate(monday.getDate() + 2);
    }
    return monday;
  }

  public static isTimeFormatValid(time: string) {
    return time === '' || moment(time, 'h:mmA', true).isValid() || moment(time, 'h:mm A', true).isValid();
  }

  public static isDateFormatValid(date: string) {
    return date === '' || moment(date, 'MM/DD/YYYY', true).isValid() || moment(date, 'YYYY-MM-DD', true).isValid();
  }

  public static isValidUserDate(date: string) {
    const userInfo = store.getters['settings/getUserInfo'] || { displaySettings: { dateStyling: { dateFormat: 1, dateDelimiter: '/' } } };
    const displaySettings = userInfo.displaySettings;
    const dateFormat = +displaySettings.dateStyling.dateFormat || 1;
    const dateDelimiter = displaySettings.dateStyling.dateDelimiter || '/';
    let showFormat = 'MM/DD/YYYY';
    if (dateFormat === 2) {
      showFormat = 'DD/MM/YYYY';
    }
    showFormat = showFormat.replace(/\//g, dateDelimiter);

    return date === '' || moment(date, 'MM/DD/YYYY', true).isValid() || moment(date, 'YYYY-MM-DD', true).isValid() || moment(date, showFormat, true).isValid();
  }

  public static toTimestamp(text: string) {
    const m = moment(text, 'MM/DD/YYYY h:mmA', true);
    if (m.isValid()) {
      return m.toDate();
    } else {
      return moment(text, 'MM/DD/YYYY h:mm A', true).toDate();
    }
  }

  public static toTime(text: string) {
    let m = moment(text, 'h:mmA', true);
    if (m.isValid()) {
      const date = m.toDate();
      return {
        minute: date.getMinutes(),
        hour: date.getHours()
      };
    } else {
      m = moment(text, 'h:mm A', true);
      if (m.isValid()) {
        const date = m.toDate();
        return {
          minute: date.getMinutes(),
          hour: date.getHours()
        };
      }
    }
    const date = new Date();
    return {
      minute: date.getMinutes(),
      hour: date.getHours()
    };
  }

  public static getWeekNum(date: string, start: string, end: string) {
    const d = moment(date, 'MM/DD/YYYY');
    let s = moment(start, 'MM/DD/YYYY').startOf('week');
    const e = moment(end, 'MM/DD/YYYY').endOf('week');
    let c = 0;
    if (d.isBetween(s, e, undefined, '[]')) {
      c = 1;
      while (!s.isSame(d, 'week')) {
        c++;
        s = s.add(1, 'week');
      }
    }
    return c;
  }

  public static areTimesEqual(text1: string, text2: string) {
    const time1 = DateTimeUtils.toTime(text1);
    const time2 = DateTimeUtils.toTime(text2);
    return ld.isEqual(time1, time2);
  }

  public static daysDiff(dStart: string, dEnd: string) {
    const mStart = moment(dStart, 'MM/DD/YYYY');
    const mEnd = moment(dEnd, 'MM/DD/YYYY');
    return moment.duration(mEnd.diff(mStart)).asDays();
  }

  public static isInWeeklySchedule(date: string, dStart: string, dEnd: string) {
    const mStart = moment(dStart, 'MM/DD/YYYY');
    const mEnd = moment(dEnd, 'MM/DD/YYYY');
    const mDate = moment(date, 'MM/DD/YYYY');
    return mDate.isBetween(mStart, mEnd, undefined, '[]') && mDate.weekday() === mStart.weekday();
  }

  public static isInBiweeklySchedule(date: string, dStart: string, dEnd: string) {
    const mStart = moment(dStart, 'MM/DD/YYYY');
    const mEnd = moment(dEnd, 'MM/DD/YYYY');
    const mDate = moment(date, 'MM/DD/YYYY');
    return mDate.isBetween(mStart, mEnd, undefined, '[]') && CommonUtils.isEven(mDate.diff(mStart, 'week')) && mDate.weekday() === mStart.weekday();
  }

  public static isBetween(date: string, dStart: string, dEnd: string) {
    return moment(date, 'MM/DD/YYYY').isBetween(moment(dStart, 'MM/DD/YYYY'), moment(dEnd, 'MM/DD/YYYY'), undefined, '[]');
  }

  public static formatDate(date: Date | string, pattern: string) {
    return moment(date, 'MM/DD/YYYY').tz(moment.tz.guess()).format(pattern);
  }

  public static getCurrentYearRange() {
    const currentYear = new Date().getFullYear();
    return {
      start: currentYear,
      end: currentYear + 1,
      range: (currentYear + '-' + (currentYear + 1))
    };
  }

  public static currentDateAddDays(days: number) {
    return DateTimeUtils.formatDate(moment().add(days, 'days').toDate(), 'MM/DD/YYYY');
  }

  public static addMonths(date: string, months: number) {
    return moment(date, 'MM/DD/YYYY').add(months, 'months').format('MM/DD/YYYY');
  }

  public static getFirstMonday(date: string) {
    let m = moment(date, 'MM/DD/YYYY');
    if (m.day() < 1) {
      m = m.add(1, 'day');
    } else if (m.day() > 1) {
      m = m.add(1, 'week').startOf('isoWeek');
    }
    return m.format('MM/DD/YYYY');
  }

  public static getStartOfWeek(date: string) {
    return moment(date, 'MM/DD/YYYY').startOf('week').format('MM/DD/YYYY');
  }

  public static getEndOfWeek(date: string) {
    return moment(date, 'MM/DD/YYYY').endOf('week').format('MM/DD/YYYY');
  }

  public static getStartOfMonth(date: string) {
    return moment(date, 'MM/DD/YYYY').startOf('month').format('MM/DD/YYYY');
  }

  public static getEndOfMonth(date: string) {
    return moment(date, 'MM/DD/YYYY').endOf('month').format('MM/DD/YYYY');
  }

  public static getFirstActiveStart(date: string, days: number[]) {
    let m = moment(date, 'MM/DD/YYYY');
    while (CommonUtils.isNotEmpty(days) && !days.includes(m.day())) {
      m = m.add(1, 'day');
    }
    return m.format('MM/DD/YYYY');
  }

  public static getLastActiveEnd(date: string, days: number[]) {
    let m = moment(date, 'MM/DD/YYYY');
    while (CommonUtils.isNotEmpty(days) && !days.includes(m.day())) {
      m = m.add(-1, 'day');
    }
    return m.format('MM/DD/YYYY');
  }

  public static getNextDay(date: string, days: number[]) {
    let m = moment(date, 'MM/DD/YYYY');
    do {
      m = m.add(1, 'day');
    } while (!days.includes(m.day()));
    return m.format('MM/DD/YYYY');
  }

  public static getPrevDay(date: string, days: number[]) {
    let m = moment(date, 'MM/DD/YYYY');
    do {
      m = m.add(-1, 'day');
    } while (!days.includes(m.day()));
    return m.format('MM/DD/YYYY');
  }

  public static isThisDateBeforeThatDate(date: string, referenceDate: string) {
    if (CommonUtils.hasText(date) && CommonUtils.hasText(referenceDate)) {
      const d1 = moment(date, 'MM/DD/YYYY');
      const d2 = moment(referenceDate, 'MM/DD/YYYY');
      return d1.isBefore(d2);
    }
  }

  public static isThisDateAfterThatDate(date: string, referenceDate: string) {
    if (CommonUtils.hasText(date) && CommonUtils.hasText(referenceDate)) {
      const d1 = moment(date, 'MM/DD/YYYY');
      const d2 = moment(referenceDate, 'MM/DD/YYYY');
      return d1.isAfter(d2);
    }
  }

  public static isThisDateEqualToThatDate(date: string, referenceDate: string) {
    if (CommonUtils.hasText(date) && CommonUtils.hasText(referenceDate)) {
      const d1 = moment(date, 'MM/DD/YYYY');
      const d2 = moment(referenceDate, 'MM/DD/YYYY');
      return d1.isSame(d2);
    }
  }

  public static compareDates(dateA: string, dateB: string) {
    if (CommonUtils.hasText(dateA) && CommonUtils.hasText(dateB)) {
      const d1 = moment(dateA, 'MM/DD/YYYY');
      const d2 = moment(dateB, 'MM/DD/YYYY');

      if (d1.isAfter(d2)) {
        return 1;
      } else if (d1.isBefore(d2)) {
        return -1;
      } else {
        return 0;
      }
    } else if (CommonUtils.hasText(dateA)) {
      return -1;
    } else if (CommonUtils.hasText(dateB)) {
      return 1;
    } else {
      return 0;
    }
  }

  public static isPast(date: string) {
    return moment(date, 'MM/DD/YYYY').isBefore(moment().startOf('day'))
  }

  public static getTzSunriseAndSunset(timeZoneId?: string) {
    const defaultTzId = moment.tz.guess();
    const location = DateTimeUtils.getTzLocation(timeZoneId || defaultTzId);
    const mDate = moment.tz(timeZoneId || defaultTzId);
    return DateTimeUtils.getSunriseAndSunset(mDate.toDate(), location.latitude, location.longitude, (mDate.utcOffset() / 60));
  }

  public static isDaylight() {
    const data = DateTimeUtils.getTzSunriseAndSunset(moment.tz.guess());
    const sunrise = moment(data.sunrise, 'HH:mm A');
    const sunset = moment(data.sunset, 'HH:mm A');
    const current = moment();
    return current.isBetween(sunrise, sunset, undefined, '[]');
  }

  public static getSunriseAndSunset(date: Date, lat: number, lng: number, tz: number) {
    // convert the date to Julian days
    const julianDays = date.getTime() / 86400000 - date.getTimezoneOffset() / 1440 + 2440587.5;

    // calculate the equation of time
    const equationOfTime = 229.18 * (0.000075 + 0.001868 * Math.cos(2 * Math.PI / 365 * (julianDays - 81)) - 0.032077 * Math.sin(2 * Math.PI / 365 * (julianDays - 81)) - 0.014615 * Math.cos(4 * Math.PI / 365 * (julianDays - 81)) - 0.040849 * Math.sin(4 * Math.PI / 365 * (julianDays - 81)));

    // calculate the solar noon
    const solarNoon = (720 - 4 * lng - equationOfTime + tz * 60) / 1440;

    // calculate the sun's mean anomaly
    const sunMeanAnomaly = (julianDays - 2451545 - solarNoon) / 36525;

    // calculate the sun's true longitude
    const sunTrueLongitude = 280.460 + sunMeanAnomaly * 36000.771;
    const sunTrueLongitudeRad = sunTrueLongitude * Math.PI / 180;

    // calculate the sun's declination
    const sunDeclination = Math.asin(Math.sin(sunTrueLongitudeRad) * Math.sin(23.45 * Math.PI / 180)) / Math.PI * 180;

    // calculate the hour angle
    const hourAngle = Math.acos((Math.sin(-0.83 * Math.PI / 180) - Math.sin(lat * Math.PI / 180) * Math.sin(sunDeclination * Math.PI / 180)) / (Math.cos(lat * Math.PI / 180) * Math.cos(sunDeclination * Math.PI / 180))) / Math.PI * 180;

    // calculate the sunrise and sunset
    const sunrise = solarNoon - hourAngle * 4 / 1440;
    const sunset = solarNoon + hourAngle * 4 / 1440;

    const sunriseHour = Math.floor(sunrise * 24);
    const sunriseMinute = Math.floor((sunrise * 24 - sunriseHour) * 60);
    const sunsetHour = Math.floor(sunset * 24);
    const sunsetMinute = Math.floor((sunset * 24 - sunsetHour) * 60);

    const sunriseTime = `${DateTimeUtils.pad(sunriseHour % 12 || 12)}:${DateTimeUtils.pad(sunriseMinute)} ${sunriseHour >= 12 ? 'PM' : 'AM'}`;
    const sunsetTime = `${DateTimeUtils.pad(sunsetHour % 12 || 12)}:${DateTimeUtils.pad(sunsetMinute)} ${sunsetHour >= 12 ? 'PM' : 'AM'}`;

    return { sunrise: sunriseTime, sunset: sunsetTime };
  }

  private static pad(num: number) {
    return num.toString().padStart(2, '0');
  }

  public static getTzLocation(timeZoneId: string) {
    const location = timeZoneLocations[timeZoneId];
    return location || { latitude: 51.477928, longitude: -0.001545 };
  }

  public static startOfWeek(date: moment.Moment) {
    const weekdays = store.getters['plans/getEnabledDayNums'];
    if (date.weekday() === 0) {
      date = date.clone();
      return date.add(1, 'day');
    } else if (date.weekday() === 6 && !weekdays.includes(date.day())) {
      date = date.clone();
      return date.add(2, 'day');
    } else {
      return date.startOf('isoWeek');
    }
  }

  public static getTawDay(date: moment.Moment) {
    const tawWeek = moment().month(4).startOf('month').add(6 - moment().day(1).day(), 'days').week();

    if (date.week() === tawWeek && date.day() !== 0 && date.day() !== 6) {
      return date.day() - 1;
    }

    return -1;
  }
}
