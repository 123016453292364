
import CommonUtils from '@/utils/common-utils';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Confirm from './../../components/core/Confirm.vue';
import ld from 'lodash';
import { VForm } from 'vuetify/lib';
import draggable from 'vuedraggable';
import { lessonLayoutSectionsBasic, lessonLayoutSectionsInstructional, lessonLayoutSectionsDetailed } from '@/data/sample';
import DisplayFormatOptions from '../../components/core/DisplayFormatOptions.vue';

const settings = namespace('settings');
const lessonlayouts = namespace('lessonlayouts');
const index = namespace('index');

@Component({
  props: {
    value: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  components: {
    draggable,
    DisplayFormatOptions
  }
})
export default class LessonLayoutEditorForm extends Vue {
  localValue = false;
  localRefreshKey = CommonUtils.generateUUID();
  localSelectedLayoutCloneOrig: any = {};
  localSelectedLayoutDefaultLayout = false;
  localSelectedLayoutDefaultLayoutOrig = false;
  localSaveTo = 'D';
  localDisableMarkAsCurrentOrDefault = false;
  localShowPanel = -1;
  itemFocused = ''
  localPlaceholderLabels: any = {
    tab1Label: this.$t('textLabel') + ' 1',
    tab2Label: this.$t('textLabel') + ' 2',
    tab3Label: this.$t('textLabel') + ' 3',
    tab4Label: this.$t('textLabel') + ' 4',
    tab5Label: this.$t('textLabel') + ' 5',
    tab6Label: this.$t('textLabel') + ' 6',
    strategiesLabel: this.$t('strategiesLabel').toString(),
    tab7Label: this.$t('myListLabel').toString(),
    tab8Label: this.$t('standardsLabel').toString(),
    tab9Label: this.$t('schoolListLabel').toString(),
    tab10Label: this.$t('attachmentsLabel').toString(),
    tab11Label: this.$t('assignmentsLabel').toString(),
    tab12Label: this.$t('assessmentsLabel').toString()
  }

  localRules = [
    (v: any) => !!v || this.$t('requiredLabel')
  ];

  $refs!: {
    lessonLayoutEditorForm: InstanceType<typeof VForm>,
    lessonLayoutEditorConfirm: Confirm
  }

  @index.Action
  disableResizeable!: () => Promise<any>;

  @index.Action
  enableResizeable!: () => Promise<any>;

  @settings.Getter('getUserType')
  userType!: any;

  @settings.Getter('getCurrentYear')
  currentYear!: any;

  @settings.Getter('getShowSnackbarNotifications')
  showSnackbarNotifications!: boolean;

  @lessonlayouts.State
  selectedLayout!: any;

  @lessonlayouts.Action
  addOrUpdateLayout!: (params?: any) => Promise<any>;

  @Watch('value')
  updateLocalValue(v: boolean) {
    this.localValue = v;
  }

  @Watch('localValue')
  updateValue(v: boolean) {
    this.$emit('update:value', v);
  }

  draggableDisabled = true;

  get darkMode(): boolean {
    return this.$vuetify.theme.dark;
  }

  get isNewLayout(): boolean {
    return !(this.localSelectedLayoutClone.lessonLayoutId && this.localSelectedLayoutClone.lessonLayoutId > 0);
  }

  get basic(): Array<any> {
    return lessonLayoutSectionsBasic;
  }

  get instructional(): Array<any> {
    return lessonLayoutSectionsInstructional;
  }

  get detailed(): Array<any> {
    return lessonLayoutSectionsDetailed;
  }

  get action(): string {
    if (this.$currentUser.isTeacher) {
      if (this.localSelectedLayoutClone.schoolId > 0 || this.localSelectedLayoutClone.districtId > 0) {
        return 'CT';
      } else {
        return 'T';
      }
    } else if (this.$currentUser.isAdmin) {
      if (this.userType === 'B' || this.userType === 'A') {
        return 'S';
      } else {
        return this.localSaveTo;
      }
    }
    return '';
  }

  get hasCustomizations(): boolean {
    if (CommonUtils.isNotEmpty(this.localSelectedLayoutClone.sections)) {
      for (let j = 0; j < this.localSelectedLayoutClone.sections.length; j++) {
        if (this.localSelectedLayoutClone.sections[j].type === 3) {
          return true;
        }
      }
    }
    return false;
  }

  get localPage() {
    return CommonUtils.getPage(this.$route);
  }

  get localSelectedLayoutClone() {
    return this.$store.state.lessonlayouts.localLayout;
  }

  set localSelectedLayoutClone(val: any) {
    this.$store.commit('lessonlayouts/setLocalLayout', val);
  }

  resetDisplaySettings(index: number) {
    if (index > -1) {
      const defaultDisplaySettings: any = ld.cloneDeep(this.localSelectedLayoutClone.sectionsForReset[index]);
      defaultDisplaySettings.displaySettingsId = null;
      const currentDisplaySettings = ld.cloneDeep(this.localSelectedLayoutClone.sections[index]);
      const mergedDisplaySettings = ld.merge(currentDisplaySettings, defaultDisplaySettings);
      this.localSelectedLayoutClone.sections.splice(index, 1, mergedDisplaySettings);
      this.localRefreshKey = CommonUtils.generateUUID();
    } else {
      this.localSelectedLayoutClone.sections = ld.merge(ld.cloneDeep(this.localSelectedLayoutClone.sections), ld.cloneDeep(this.localSelectedLayoutClone.sectionsForReset));
      this.localRefreshKey = CommonUtils.generateUUID();
    }
  }

  getTitleStyle(item: any) {
    return {
      fontFamily: CommonUtils.getFontFamily(item.titleFont),
      fontSize: item.titleFontSize + 'pt',
      color: CommonUtils.getColor(item.titleColor),
      backgroundColor: CommonUtils.getColor(item.titleFillColor),
      fontWeight: CommonUtils.isTrue(item.titleBold) ? 'bold' : 'normal',
      fontStyle: CommonUtils.isTrue(item.titleItalic) ? 'italic' : 'normal',
      textDecoration: CommonUtils.isTrue(item.titleUnderline) ? 'underline' : 'none',
      width: '100%'
    }
  }

  getBodyStyle(item: any) {
    return {
      fontFamily: CommonUtils.getFontFamily(item.bodyFont),
      fontSize: item.bodyFontSize + 'pt',
      color: CommonUtils.getColor(item.bodyColor),
      backgroundColor: CommonUtils.getColor(item.bodyFillColor),
      fontWeight: CommonUtils.isTrue(item.bodyBold) ? 'bold' : 'normal',
      fontStyle: CommonUtils.isTrue(item.bodyItalic) ? 'italic' : 'normal',
      textDecoration: CommonUtils.isTrue(item.bodyUnderline) ? 'underline' : 'none',
      width: '100%'
    }
  }

  closeLabelEditor(event: KeyboardEvent, section: any) {
    if (event.key === 'Enter') {
      section.isEditingLabel = false;
      this.localRefreshKey = CommonUtils.generateUUID();
    }
  }

  enableDisableSection(section: any) {
    section.isEditingLabel = false;
    section.enabled = !section.enabled
    this.localRefreshKey = CommonUtils.generateUUID();
  }

  isEditable() {
    return this.isNewLayout || (this.$currentUser.isTeacher && this.localSelectedLayoutClone.teacherId > 0) ||
      (this.$currentUser.isAdmin && this.localSelectedLayoutClone.schoolId > 0) ||
      (this.$currentUser.isDistrictAdmin && (this.localSelectedLayoutClone.schoolId > 0 || this.localSelectedLayoutClone.districtId > 0));
  }

  setLessonSectionFormat(index: number, section: any, val: any) {
    if (section.section === 'lessonTitle') {
      section.titleColor = val.titleColor;
      section.titleFont = val.titleFont;
      section.showTitle = val.showTitle;
      section.titleBold = val.titleBold;
      section.titleFillColor = val.titleFillColor;
      section.titleItalic = val.titleItalic;
      section.titleUnderline = val.titleUnderline;
      section.titleFontSize = val.titleFontSize;
    } else {
      section.titleColor = val.titleColor;
      section.titleFont = val.titleFont;
      section.showTitle = val.showTitle;
      section.titleBold = val.titleBold;
      section.titleFillColor = val.titleFillColor;
      section.titleItalic = val.titleItalic;
      section.titleUnderline = val.titleUnderline;
      section.titleFontSize = val.titleFontSize;
      section.bodyBold = val.bodyBold;
      section.bodyColor = val.bodyColor;
      section.bodyFillColor = val.bodyFillColor;
      section.bodyFont = val.bodyFont;
      section.bodyItalic = val.bodyItalic;
      section.bodyFontSize = val.bodyFontSize;
      section.bodyUnderline = val.bodyUnderline;
    }
    if (!this.isEditable()) {
      const defaultDisplaySettings: any = ld.merge(ld.cloneDeep(section), this.localSelectedLayoutClone.sectionsForReset[index]);
      if (!ld.isEqual(defaultDisplaySettings, section)) {
        section.type = 3;
      } else {
        section.type = 1;
      }
    }
  }

  applyLessonLayoutTo(level: string) {
    this.localSaveTo = level;
    return this.applyLessonLayout();
  }

  applyLessonLayout() {
    const form: any = this.$refs.lessonLayoutEditorForm;
    if (form.validate()) {
      this.localPage === 'lesson-layouts' ? this.$store.commit('lessonlayouts/setListLoading', true) : CommonUtils.showLoading();
      const lessonLayout: any = {
        action: this.action,
        name: this.localSelectedLayoutClone.name,
        lessonLayoutId: this.localSelectedLayoutClone.lessonLayoutId !== 0 ? this.localSelectedLayoutClone.lessonLayoutId : null,
        defaultLayout: this.localSelectedLayoutDefaultLayout ? 'Y' : 'N'
      };
      if ((this.$currentUser.isAdmin && !this.$currentUser.isDistrictAdmin) && this.localSelectedLayoutClone.districtId !== 0) {
        lessonLayout.lessonLayoutId = null;
      }
      this.localSelectedLayoutClone.sections.forEach((section: any, index: number) => {
        lessonLayout[section.displaySettingsIdKey] = section.displaySettingsId;
        lessonLayout[section.parentIdKey] = section.parentId;
        lessonLayout[section.labelKey] = section.label;
        lessonLayout[section.orderKey] = index;
        lessonLayout[section.enabledKey] = section.enabled;
        lessonLayout[section.titleColorKey] = section.titleColor;
        lessonLayout[section.titleFontKey] = section.titleFont;
        lessonLayout[section.showTitleKey] = !CommonUtils.isTrue(section.showTitle);
        lessonLayout[section.titleBoldKey] = section.titleBold;
        lessonLayout[section.titleFillColorKey] = section.titleFillColor;
        lessonLayout[section.titleItalicKey] = section.titleItalic;
        lessonLayout[section.titleUnderlineKey] = section.titleUnderline;
        lessonLayout[section.titleFontSizeKey] = section.titleFontSize;
        lessonLayout[section.bodyBoldKey] = section.bodyBold;
        lessonLayout[section.bodyColorKey] = section.bodyColor;
        lessonLayout[section.bodyFillColorKey] = section.bodyFillColor;
        lessonLayout[section.bodyFontKey] = section.bodyFont;
        lessonLayout[section.bodyItalicKey] = section.bodyItalic;
        lessonLayout[section.bodyFontSizeKey] = section.bodyFontSize;
        lessonLayout[section.bodyUnderlineKey] = section.bodyUnderline;
        if ((this.$currentUser.isAdmin && !this.$currentUser.isDistrictAdmin) && this.localSelectedLayoutClone.districtId !== 0) {
          delete lessonLayout[section.displaySettingsIdKey];
          delete lessonLayout[section.parentIdKey];
        }
      });

      return this.addOrUpdateLayout(lessonLayout).then(() => {
        if (this.showSnackbarNotifications) {
          if (this.localSelectedLayoutClone.lessonLayoutId !== 0) {
            this.$snotify.success(this.$t('statusMsg58') as string);
          } else {
            this.$snotify.success(this.$t('statusMsg59') as string);
          }
        }
        return Promise.resolve(false);
      }).finally(() => {
        this.localPage === 'lesson-layouts' ? this.$store.commit('lessonlayouts/setListLoading', false) : CommonUtils.hideLoading();
        form.resetValidation();
        this.$eventBus.$emit('refreshLessonLayouts');
        return Promise.resolve(false);
      });
    } else {
      return Promise.resolve(true);
    }
  }

  isDirty() {
    return (this.isEditable() && !ld.isEqual(this.localSelectedLayoutCloneOrig, this.localSelectedLayoutClone));
  }

  resetValidation() {
    try {
      const form: any = this.$refs.lessonLayoutEditorForm;
      form.resetValidation();
    } catch (e) {
    }
  }

  selectSampleLayout(sections: Array<any>) {
    CommonUtils.showLoading();
    this.localSelectedLayoutClone.sections = sections;
    this.localSelectedLayoutClone.sections.forEach((section: any) => {
      section.placeholderLabel = this.localPlaceholderLabels[section.labelKey];
    });
    CommonUtils.hideLoading();
    this.localShowPanel = -1;
    this.localRefreshKey = CommonUtils.generateUUID();
  }

  initialize() {
    if (this.$store.state.lessonlayouts.loadSelectedLayout === false) {
      return;
    }
    CommonUtils.showLoading();
    this.localSelectedLayoutClone = ld.cloneDeep(this.selectedLayout);
    this.localSelectedLayoutClone.sections.forEach((section: any) => {
      section.isEditingLabel = false;
      section.placeholderLabel = this.localPlaceholderLabels[section.labelKey];
      switch (section.subType) {
        case 1: section.section = 'tab1'; break;
        case 2: section.section = 'tab2'; break;
        case 3: section.section = 'tab3'; break;
        case 4: section.section = 'tab4'; break;
        case 5: section.section = 'tab5'; break;
        case 6: section.section = 'tab6'; break;
        case 7: section.section = 'myStandards'; break;
        case 8: section.section = 'sharedStandards'; break;
        case 9: section.section = 'schoolStandards'; break;
        case 10: section.section = 'attachments'; break;
        case 11: section.section = 'assignments'; break;
        case 12: section.section = 'assessments'; break;
        case 15: section.section = 'lessonTitle'; break;
        case 16: section.section = 'strategies'; break;
        default: break;
      }
    });
    this.localSelectedLayoutCloneOrig = ld.cloneDeep(this.localSelectedLayoutClone);
    this.localSelectedLayoutDefaultLayout = CommonUtils.isTrue(this.localSelectedLayoutClone.defaultLayout);
    if (this.$currentUser.isTeacher) {
      if ((this.currentYear.lessonLayoutId === this.localSelectedLayoutClone.lessonLayoutId) || (this.currentYear.lessonLayoutId === 0 && this.localSelectedLayoutClone.lessonLayoutId === -1)) {
        this.localSelectedLayoutDefaultLayout = true;
      } else {
        this.localSelectedLayoutDefaultLayout = false;
      }
    }
    this.localSelectedLayoutDefaultLayoutOrig = CommonUtils.isTrue(this.localSelectedLayoutDefaultLayout);
    this.localDisableMarkAsCurrentOrDefault = CommonUtils.isTrue(this.localSelectedLayoutDefaultLayout);
    CommonUtils.hideLoading();
    this.localRefreshKey = CommonUtils.generateUUID();
  }

  disableDraggable() {
    this.draggableDisabled = true;
  }

  enableDraggable() {
    this.draggableDisabled = false;
  }

  created() {
    this.draggableDisabled = false;
    this.initialize();
  }

  moveSectionUp(index: any) {
    const clone = ld.cloneDeep(this.localSelectedLayoutClone);
    clone.sections[index - 1] = this.localSelectedLayoutClone.sections[index];
    clone.sections[index] = this.localSelectedLayoutClone.sections[index - 1];
    this.localSelectedLayoutClone = clone;
    (this.$refs as any)['moveButton' + (index - 1)][0].$el.focus();
  }

  moveSectionDown(index: any) {
    const clone = ld.cloneDeep(this.localSelectedLayoutClone);
    clone.sections[index + 1] = this.localSelectedLayoutClone.sections[index];
    clone.sections[index] = this.localSelectedLayoutClone.sections[index + 1];
    this.localSelectedLayoutClone = clone;
    (this.$refs as any)['moveButton' + (index + 1)][0].$el.focus();
  }
}
