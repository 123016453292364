var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "editor",
      on: {
        drop: _vm.dropAttachment,
        dragover: function($event) {
          $event.preventDefault()
        },
        dragleave: function($event) {
          $event.preventDefault()
        }
      }
    },
    [
      _c(
        "v-form",
        { ref: "messageEditorForm", attrs: { "lazy-validation": "" } },
        [
          _c(
            "v-container",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isTextFieldsVisible,
                  expression: "isTextFieldsVisible"
                }
              ],
              staticClass: "px-5 pt-4"
            },
            [
              !_vm.isViewing
                ? _c(
                    "div",
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          items: _vm.activeRecipients,
                          outlined: "",
                          "return-object": "",
                          "hide-details": "auto",
                          chips: "",
                          label: _vm.$t("recipientsLabel") + "*",
                          "aria-label": _vm.$t("recipientsLabel") + "*",
                          "aria-required": "true",
                          rules: _vm.localRules,
                          multiple: "",
                          "item-disabled": "disabled",
                          "item-text": "recipientName",
                          "item-value": "recipientId",
                          autofocus: ""
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "selection",
                              fn: function(data) {
                                return [
                                  _c(
                                    "v-chip",
                                    _vm._b(
                                      {
                                        attrs: {
                                          "input-value": data.selected,
                                          close: ""
                                        },
                                        on: {
                                          click: data.select,
                                          "click:close": function($event) {
                                            return _vm.remove(data.item)
                                          }
                                        }
                                      },
                                      "v-chip",
                                      data.attrs,
                                      false
                                    ),
                                    [
                                      _c(
                                        "v-avatar",
                                        {
                                          attrs: { color: "primary", left: "" }
                                        },
                                        [
                                          data.item.photoUrl &&
                                          data.item.photoUrl != ""
                                            ? _c("v-img", {
                                                attrs: {
                                                  src: data.item.photoUrl
                                                }
                                              })
                                            : data.item.photoUrl == ""
                                            ? _c(
                                                "span",
                                                { staticClass: "white--text" },
                                                [
                                                  _vm._v(
                                                    _vm._s(data.item.initials)
                                                  )
                                                ]
                                              )
                                            : _c("v-img", {
                                                attrs: {
                                                  src:
                                                    "https://cdn.planbook.com/images/planbook-icon.svg"
                                                }
                                              })
                                        ],
                                        1
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(data.item.recipientName) +
                                          " "
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            },
                            {
                              key: "prepend-item",
                              fn: function() {
                                return [
                                  _vm.$currentUser.isDistrictAdmin ||
                                  _vm.haveMultipleSchools
                                    ? _c("v-select", {
                                        staticClass: "mx-2",
                                        attrs: {
                                          label: _vm.$t("schoolLabel"),
                                          "hide-details": "",
                                          outlined: "",
                                          items: _vm.schoolItems
                                        },
                                        model: {
                                          value: _vm.currentSchoolId,
                                          callback: function($$v) {
                                            _vm.currentSchoolId = $$v
                                          },
                                          expression: "currentSchoolId"
                                        }
                                      })
                                    : _vm._e()
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "item",
                              fn: function(ref) {
                                var active = ref.active
                                var item = ref.item
                                var attrs = ref.attrs
                                var on = ref.on
                                return [
                                  _c(
                                    "v-list-item",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var active = ref.active
                                                  return [
                                                    _c(
                                                      "v-list-item-action",
                                                      [
                                                        _c("v-checkbox", {
                                                          attrs: {
                                                            disabled:
                                                              item.disabled,
                                                            "input-value": active
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-list-item-icon",
                                                      { attrs: { small: "" } },
                                                      [
                                                        _c(
                                                          "v-avatar",
                                                          {
                                                            staticClass:
                                                              "mr-2 ml-0",
                                                            attrs: {
                                                              size: "2.0rem",
                                                              color: "primary",
                                                              left: ""
                                                            }
                                                          },
                                                          [
                                                            item.photoUrl &&
                                                            item.photoUrl != ""
                                                              ? _c("v-img", {
                                                                  attrs: {
                                                                    src:
                                                                      item.photoUrl
                                                                  }
                                                                })
                                                              : item.photoUrl ==
                                                                ""
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "white--text"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.initials
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              : _c("v-img", {
                                                                  attrs: {
                                                                    src:
                                                                      "https://cdn.planbook.com/images/planbook-icon.svg"
                                                                  }
                                                                })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c("v-list-item-content", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.recipientName
                                                          ) +
                                                          " "
                                                      )
                                                    ])
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        "v-list-item",
                                        attrs,
                                        false
                                      ),
                                      on
                                    )
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2374281486
                        ),
                        model: {
                          value: _vm.recipientsSelected,
                          callback: function($$v) {
                            _vm.recipientsSelected = $$v
                          },
                          expression: "recipientsSelected"
                        }
                      }),
                      _vm.userMode == "A" &&
                      _vm.input.action != "submissions" &&
                      _vm.localSelectedMessage.attachToLesson == 0
                        ? _c("v-checkbox", {
                            attrs: {
                              "hide-details": "auto",
                              dense: "",
                              label: _vm.$t("privateLabel")
                            },
                            model: {
                              value: _vm.localSelectedMessage.messagePrivate,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.localSelectedMessage,
                                  "messagePrivate",
                                  $$v
                                )
                              },
                              expression: "localSelectedMessage.messagePrivate"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          !_vm.isFromUser || _vm.userMode == "A"
                            ? _c("v-text-field", {
                                attrs: {
                                  "hide-details": "auto",
                                  outlined: "",
                                  readonly: "",
                                  label: _vm.$t("fromLabel"),
                                  autofocus: ""
                                },
                                model: {
                                  value: _vm.localSelectedMessage.authorName,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.localSelectedMessage,
                                      "authorName",
                                      $$v
                                    )
                                  },
                                  expression: "localSelectedMessage.authorName"
                                }
                              })
                            : _vm._e(),
                          _vm.userMode == "A"
                            ? _c("v-icon", { staticClass: "mx-4" }, [
                                _vm._v("fa fa-right")
                              ])
                            : _vm._e(),
                          _vm.isFromUser || _vm.userMode == "A"
                            ? _c("v-text-field", {
                                attrs: {
                                  "hide-details": "auto",
                                  outlined: "",
                                  readonly: "",
                                  label: _vm.$t("toLabel"),
                                  autofocus: ""
                                },
                                model: {
                                  value: _vm.localSelectedMessage.recipientName,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.localSelectedMessage,
                                      "recipientName",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "localSelectedMessage.recipientName"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
              _vm.isReply
                ? _c(
                    "v-row",
                    {
                      staticClass: "mt-4",
                      attrs: { justify: "center", align: "center" }
                    },
                    [
                      _vm.localSelectedMessage.replyType === "LESSON"
                        ? _c("div", [
                            _vm.replyInfo.className
                              ? _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "messageLessonReply",
                                        _vm.replyInfo
                                      )
                                    )
                                  )
                                ])
                              : _c("div", [
                                  _vm._v(
                                    _vm._s(_vm.$t("messageLessonUnloaded"))
                                  )
                                ])
                          ])
                        : _vm.localSelectedMessage.replyType === "COMMENT"
                        ? _c(
                            "v-col",
                            {
                              staticClass: "mx-2",
                              attrs: { justify: "center", align: "center" }
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  attrs: { justify: "center", align: "center" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("messageCommentReply", {
                                          authorName: _vm.replyInfo.authorName,
                                          commentDate: _vm.stringifyDate(
                                            _vm.replyInfo.commentDate
                                          )
                                        })
                                      ) +
                                      " "
                                  ),
                                  _c(
                                    "pb-btn",
                                    {
                                      attrs: {
                                        text: "",
                                        color: "primary",
                                        small: "",
                                        label: _vm.showReplyText
                                          ? _vm.$t("hideLabel")
                                          : _vm.$t("showLabel")
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.showReplyText = !_vm.showReplyText
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.showReplyText
                                              ? "fal fa-eye"
                                              : "fal fa-eye-slash"
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.showReplyText
                                ? _c(
                                    "v-row",
                                    {
                                      staticStyle: {
                                        border: "1px solid gray",
                                        "border-radius": "5px"
                                      },
                                      attrs: {
                                        justify: "center",
                                        align: "center"
                                      }
                                    },
                                    [
                                      _c("div", {
                                        staticClass: "mt-2 mb-0 pb-0",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.replyInfo.commentText
                                          )
                                        }
                                      })
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            {
              key: _vm.localRefreshKey,
              on: {
                mouseenter: function($event) {
                  _vm.isSectionsHovered = true
                },
                mouseleave: function($event) {
                  _vm.isSectionsHovered = false
                }
              }
            },
            [
              _c("text-editor-full-screen-button", {
                attrs: {
                  isSectionsHovered: _vm.isSectionsHovered,
                  isTextFieldsVisible: _vm.isTextFieldsVisible
                },
                on: { hideOrShowTextFields: _vm.hideOrShowTextFields }
              }),
              _c(
                "v-tabs",
                {
                  staticClass: "editor-section-tabs",
                  attrs: {
                    "center-active": "",
                    centered: "",
                    "show-arrows": "",
                    "prev-icon": "fal fa-chevron-left",
                    "next-icon": "fal fa-chevron-right"
                  },
                  model: {
                    value: _vm.localActiveTab,
                    callback: function($$v) {
                      _vm.localActiveTab = $$v
                    },
                    expression: "localActiveTab"
                  }
                },
                _vm._l(_vm.filteredTabs, function(section) {
                  return _c("v-tab", { key: section.section }, [
                    _vm._v(_vm._s(section.label))
                  ])
                }),
                1
              ),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.localActiveTab,
                    callback: function($$v) {
                      _vm.localActiveTab = $$v
                    },
                    expression: "localActiveTab"
                  }
                },
                [
                  _vm._l(_vm.filteredTabs, function(section, index) {
                    return [
                      section.section === "description"
                        ? _c(
                            "v-tab-item",
                            { key: section.section },
                            [
                              _vm.localActiveTab === index
                                ? _c("pb-text-editor", {
                                    attrs: {
                                      disabled: _vm.isViewing,
                                      refreshKey: _vm.localRefreshKey,
                                      config: _vm.localTextEditorConfig
                                    },
                                    model: {
                                      value:
                                        _vm.localSelectedMessage.commentText,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.localSelectedMessage,
                                          "commentText",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "localSelectedMessage.commentText"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      section.section === "attachments"
                        ? _c(
                            "v-tab-item",
                            { key: section.section },
                            [
                              !_vm.isViewing && _vm.localActiveTab === index
                                ? _c(
                                    "editor-section",
                                    {
                                      attrs: {
                                        label: section.label,
                                        config: _vm.localEditorSectionConfig
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "actions",
                                            fn: function() {
                                              return [
                                                _vm.hasAttachments
                                                  ? _c(
                                                      "v-btn",
                                                      {
                                                        attrs: { icon: "" },
                                                        on: {
                                                          click:
                                                            _vm.clearAttachments
                                                        }
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "fal fa-trash-alt"
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: { icon: "" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.openDialog(
                                                          "myFiles"
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("fal fa-paperclip")
                                                    ])
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: { icon: "" },
                                                    on: {
                                                      click:
                                                        _vm.addGoogleDriveAttachments
                                                    }
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v(
                                                        "fab fa-google-drive"
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _c("editor-section-list", {
                                        attrs: {
                                          items: _vm.attachmentItems,
                                          showShortValue: false,
                                          clickable: true
                                        },
                                        on: {
                                          otherAction:
                                            _vm.toggleAttachmentPrivateFlag,
                                          deleteItem: _vm.deleteAttachment
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "value",
                                              fn: function(ref) {
                                                var value = ref.value
                                                var data = ref.data
                                                return [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "section-editor-link",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.openLink(
                                                            data
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v(_vm._s(value))]
                                                  )
                                                ]
                                              }
                                            },
                                            {
                                              key: "other-actions",
                                              fn: function(ref) {
                                                var item = ref.item
                                                var index = ref.index
                                                return [
                                                  _c("v-checkbox", {
                                                    staticClass:
                                                      "section-editor-checkbox",
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "privateLabel"
                                                      ),
                                                      "input-value":
                                                        item.data.privateFlag
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.toggleAttachmentPrivateFlag(
                                                          item,
                                                          index
                                                        )
                                                      }
                                                    }
                                                  })
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                : _vm.localActiveTab === index
                                ? _c("editor-section-list", {
                                    attrs: {
                                      items: _vm.attachmentItems,
                                      showShortValue: false,
                                      clickable: true,
                                      showDelete: false
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "value",
                                          fn: function(ref) {
                                            var value = ref.value
                                            var data = ref.data
                                            return [
                                              !data.privateFlag
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "section-editor-link",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.openLink(
                                                            data
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v(_vm._s(value))]
                                                  )
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c("pb-confirm", { ref: "confirm" }),
      _c("my-files-picker", {
        ref: "filesPicker",
        attrs: { attachments: _vm.attachments },
        on: {
          "update:attachments": function($event) {
            _vm.attachments = $event
          }
        },
        model: {
          value: _vm.dialogs.myFiles,
          callback: function($$v) {
            _vm.$set(_vm.dialogs, "myFiles", $$v)
          },
          expression: "dialogs.myFiles"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }