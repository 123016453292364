






























import { Component, Vue, Watch, Prop } from 'vue-property-decorator';

@Component
export default class LessonBankCsvEditorForm extends Vue {
 @Prop({
   required: true,
   type: Object,
   default: () => {
     return {};
   }
 })
 input!: any;

 uploadedFile: any = null;
 errorUploadFile = false;
 localRules = [(v: any) => !!v || 'Required'];

 $refs!: {
    CSVEditorForm: any;
  };

 @Watch('uploadedFile')
 fileChange(val: any) {
   this.$emit('fileChange', val);
 }

 created() {
   this.$eventBus.$on('resetField', () => {
     this.uploadedFile = null;
   });
 }

 destroyed() {
   this.$eventBus.$off('resetField');
 }
}
