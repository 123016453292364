var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-sheet",
    _vm._g(
      _vm._b(
        {
          directives: [
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: {
                handler: _vm.handleClickOutside,
                localDisableHandleClickOutside:
                  _vm.localDisableHandleClickOutside
              },
              expression:
                "{ handler: handleClickOutside, localDisableHandleClickOutside }"
            }
          ],
          class:
            "sheet" +
            (_vm.localValue ? " open" : "") +
            (_vm.localPersistent && _vm.localAnimate ? " sheet-animated" : ""),
          attrs: { id: _vm.localId }
        },
        "v-sheet",
        Object.assign({}, _vm.$props, _vm.$attrs),
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "div",
        {
          ref: "container",
          staticClass: "ma-5",
          on: {
            mouseover: _vm.handleOnMouseOver,
            mouseleave: _vm.handleOnMouseLeave
          }
        },
        [
          _vm.showCloseButton
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "text-right mb-5", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              _vm.localValue = !_vm.localValue
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("fal fa-times")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._t("default")
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }