


























import CommonUtils from '@/utils/common-utils';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Confirm from './../../components/core/Confirm.vue';
import ld from 'lodash';
import { VForm } from 'vuetify/lib';
import draggable from 'vuedraggable';
import EditorSection from '@/components/common/EditorSection.vue';
import TextEditorFullScreenButton from '@/components/common/TextEditorFullScreenButton.vue';

const myLists = namespace('mylists');
const settings = namespace('settings');

@Component({
  props: {
    input: {
      type: Object,
      required: false
    }
  },
  components: {
    draggable,
    EditorSection,
    TextEditorFullScreenButton
  }
})
export default class MyListEditorForm extends Vue {
  @Prop({ type: Boolean, default: true })
  isModal!: boolean;

  localRefreshKey = CommonUtils.generateUUID();
  localStandardClone: any = {};
  isEditing = false;
  isSectionsHovered = false;
  isTextFieldsVisible = true;

  localRules = [
    (v: any) => !!v || this.$t('requiredLabel')
  ];

  tabs = [
    { label: 'Description', active: true, section: 'description' }
  ];

  $refs!: {
    standardEditorForm: InstanceType<typeof VForm>,
    standardEditorConfirm: Confirm
  }

  @myLists.Getter
  getDefaultStandard!: any;

  @myLists.Action
  addStandard!: (params?: any) => Promise<any>;

  @myLists.Action
  updateStandard!: (params?: any) => Promise<any>;

  @myLists.Action
  loadStandards!: () => Promise<any>;

  @settings.Getter('getBrowserHeight')
  browserHeight!: number;

  @settings.Getter('getShowSnackbarNotifications')
  showSnackbarNotifications!: boolean;

  get localPage() {
    return CommonUtils.getPage(this.$route);
  }

  get localStandard() {
    return this.$store.state.mylists.localStandard;
  }

  set localStandard(val: any) {
    this.$store.commit('mylists/setLocalStandard', val)
  }

  get localTextEditorConfig() {
    return this.isModal ? { height: this.isTextFieldsVisible ? '370px' : 'fill' } : { height: 'fill' };
  }

  addNewStandard() {
    const form: any = this.$refs.standardEditorForm;
    if (form.validate()) {
      this.localPage === 'my-list' ? this.$store.commit('mylists/setListLoading', true) : CommonUtils.showLoading();
      if (this.isEditing) {
        return this.updateStandard({ dbId: this.localStandard.dbId, standardId: this.localStandard.standardId, standardDescription: this.localStandard.standardDescription }).then(() => {
          this.localPage === 'my-list' ? this.$store.commit('mylists/setListLoading', false) : CommonUtils.hideLoading();
          form.resetValidation();
          this.$eventBus.$emit('closeStandardEditor', true);
          if (this.showSnackbarNotifications) {
            if (this.$currentUser.isTeacher) {
              this.$snotify.success(this.$t('statusMsg18') as string);
            } else {
              this.$snotify.success(this.$t('statusMsg18b') as string);
            }
          }
          return Promise.resolve(false);
        });
      } else {
        return this.addStandard({ standardId: this.localStandard.standardId, standardDescription: this.localStandard.standardDescription }).then(() => {
          this.localPage === 'my-list' ? this.$store.commit('mylists/setListLoading', false) : CommonUtils.hideLoading();
          form.resetValidation();
          this.$eventBus.$emit('closeStandardEditor', true);
          if (this.showSnackbarNotifications) {
            if (this.$currentUser.isTeacher) {
              this.$snotify.success(this.$t('statusMsg17') as string);
            } else {
              this.$snotify.success(this.$t('statusMsg17b') as string);
            }
          }
          return Promise.resolve(false);
        });
      }
    } else {
      return Promise.resolve(true);
    }
  }

  @Watch('input')
  inputChange() {
    this.initialize();
  }

  created() {
    this.initialize();
  }

  initialize() {
    if (this.$props.input.loadData === false) {
      return;
    }
    CommonUtils.showLoading();
    this.clearFields();
    this.isEditing = (this.$props.input.action === 'edit')
    if (!this.isEditing) {
      this.localStandard = ld.cloneDeep(this.getDefaultStandard);
    } else {
      this.localStandard = ld.cloneDeep(this.$props.input.standardObject);
    }
    this.localStandardClone = ld.cloneDeep(this.localStandard);
    this.localRefreshKey = CommonUtils.generateUUID();
    CommonUtils.hideLoading();
  }

  isDirty() {
    return !ld.isEqual(this.localStandard, this.localStandardClone);
  }

  clearFields() {
    this.localStandard.standardId = '';
    this.localStandard.standardDescription = '';
  }

  resetValidation() {
    try {
      const form: any = this.$refs.standardEditorForm;
      form.resetValidation();
    } catch (e) {
    }
  }

  hideOrShowTextFields() {
    this.isTextFieldsVisible = !this.isTextFieldsVisible;
    this.localRefreshKey = CommonUtils.generateUUID();
  }
}
