import { BASE_URL } from '@/axios/index';
import { UserSession } from '@/common/user-session';
import AppError, { createErrOptions, SessionExpiredError } from '@/errors';
import FileServices from '@/services/file-services';
import GradeServices from '@/services/grade-services';
import { Action, Mutation, Module, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true })
export default class Grades extends VuexModule {
    allAssessments: Array<any> = [];
    localGradePeriods: Array<any> = [];
    localLetterGrades: Array<any> = [];
    origGradePeriods: Array<any> = [];
    origLetterGrades: Array<any> = [];
    selectedClass: Array<any> = [];
    ascendingDateOrder = true;
    showStandards = true;

    @Mutation
    setLocalGradePeriods(periods: any) {
      this.localGradePeriods = periods;
    }

    @Mutation
    setLocalLetterGrades(grades: any) {
      this.localLetterGrades = grades;
    }

    @Mutation
    setOriginalGradePeriods(periods: any) {
      this.origGradePeriods = periods;
    }

    @Mutation
    setOriginalLetterGrades(grades: any) {
      this.origLetterGrades = grades;
    }

    @Mutation
    setAscendingDateOrder(ascendingDateOrder: boolean) {
      this.ascendingDateOrder = ascendingDateOrder;
    }

    @Mutation
    setSelectedClass(selectedClass: Array<any>) {
      this.selectedClass = selectedClass;
    }

    @Mutation
    setShowStandards(showStandards: boolean) {
      this.showStandards = showStandards;
    }

    @Action({ rawError: true })
    async updateLetterGrades(param: any) {
      const request: any = {};
      request.letterGrades = param.letterGrades.map((a: any) => {
        return JSON.stringify({ id: a.letterGradeId || 0, name: a.name, percentage: a.percentage });
      })
      if (param.yearId) {
        request.yearId = param.yearId;
      }

      try {
        const resp = await GradeServices.updateLetterGrades(request);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve({ resp });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async loadYearsLetterGrades(params: any) {
      try {
        const resp = await GradeServices.getLetterGrades(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve(resp.data);
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async updateScoreWeights(param: any) {
      const request = new URLSearchParams();

      param.assessmentWeights.map((a: any) => {
        request.append('assessmentWeights', JSON.stringify({ id: a.scoreWeightId, name: a.name, percentage: a.percentage }))
      })
      param.assignmentWeights.map((a: any) => {
        request.append('assignmentWeights', JSON.stringify({ id: a.scoreWeightId, name: a.name, percentage: a.percentage }));
      })
      param.standardWeights.map((a: any) => {
        request.append('standardWeights', JSON.stringify({ id: a.scoreWeightId, code: a.code, name: a.name }));
      })

      if (param.subjectId) {
        request.append('subjectId', param.subjectId)
      }

      try {
        const resp = await GradeServices.updateScoreWeights(request);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            this.context.commit('settings/updateScoreWeights', { scores: resp.data.scoreWeights, standards: resp.data.standardWeights }, { root: true });
            return Promise.resolve({ resp });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async addCustomScoreWeight(params: any) {
      try {
        const resp = await GradeServices.addCustomScoreWeight(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve({ resp });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async printGradeReports(param: any) {
      const requestParams = [{ name: 'authToken', value: UserSession.getAccessToken() },
        { name: 'X-PB-ACCESS-TOKEN', value: UserSession.getAccessToken() },
        { name: 'X-PB-CLIENT-YEAR-ID', value: UserSession.getCurrentYearId() },
        { name: 'exportType', value: param.exportType }];

      const mode = this.context.rootState.settings.userMode;
      requestParams.push({ name: 'userMode', value: mode });

      requestParams.push({ name: 'gradeReportOverall', value: 'true' });
      requestParams.push({ name: 'gradeReportExportTo', value: param.exportType });

      if (mode === 'S') {
        requestParams.push({ name: 'gradeReportTeacher', value: param.teacher });
      }
      requestParams.push({ name: 'gradeReportPeriod', value: param.period });
      requestParams.push({ name: 'gradeReportClass', value: param.class });

      for (const i in param.items) {
        requestParams.push({ name: 'gradeReportItems', value: param.items[i].code });
      }

      const payload = {
        url: BASE_URL + '/exportGrades',
        params: requestParams
      };
      const formData = new URLSearchParams();
      payload.params.forEach((p: any) => {
        formData.append(p.name, p.value);
      });
      return fetch(payload.url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        credentials: 'include',
        body: formData
      }).then(async (response) => {
        const type = response.headers.get('Content-Type') as string;
        const disposition = response.headers.get('Content-Disposition') as string;
        const fileName = FileServices.getFileName(disposition);
        if (type.includes('json')) {
          const data = await response.json();
          window.open(data.documentLink, '_blank');
        } else {
          const blob = await response.blob();
          FileServices.downloadFile(blob, fileName, type);
        }
        return Promise.resolve();
      });
    }

    @Action({ rawError: true })
    async getPerformanceReport(param: any) {
      const requestParams = [
        { name: 'authToken', value: UserSession.getAccessToken() },
        { name: 'X-PB-ACCESS-TOKEN', value: UserSession.getAccessToken() },
        { name: 'X-PB-CLIENT-YEAR-ID', value: UserSession.getCurrentYearId() },
        { name: 'exportType', value: 'pdf' }
      ];

      const mode = this.context.rootState.settings.userMode;
      requestParams.push({ name: 'userMode', value: mode });
      if (mode === 'T') {
        // students
        if (param.students.type === 'All' || param.students.type === 'Class') {
          requestParams.push({ name: 'gradeReportClass', value: param.students.id });
          requestParams.push({ name: 'gradeReportStudent', value: '0' });
        } else {
          requestParams.push({ name: 'gradeReportClass', value: '0' });
          requestParams.push({ name: 'gradeReportStudent', value: param.students.id });
        }

        // items
        requestParams.push({ name: 'reportItems', value: 'Y' });
        for (const i in param.items) {
          requestParams.push({ name: param.items[i].field, value: param.items[i].code });
        }
      } else {
        requestParams.push({ name: 'gradeReportTeacher', value: param.teacherId });
        requestParams.push({ name: 'gradeReportClass', value: param.classId });
        if (mode === 'A') {
          requestParams.push({ name: 'gradeReportAsOf', value: param.date });
          requestParams.push({ name: 'gradeReportAdmin', value: 'true' });
          requestParams.push({ name: 'gradeReportStudent', value: param.studentId });
          requestParams.push({ name: 'includeAttendanceSchoolDays', value: 'Y' });
          requestParams.push({ name: 'includeAttendanceTardy', value: 'Y' });
          requestParams.push({ name: 'includeAttendanceAbsent', value: 'Y' });

          if (param.includeNotes) {
            requestParams.push({ name: 'includeNotes', value: 'Y' });
          }
        } else if (mode === 'S') {
          if (param.includeNotes) {
            requestParams.push({ name: 'gradeReportClassDetail', value: 'Y' });
          }
        }
      }

      // grade period
      requestParams.push({ name: 'gradeReportPeriod', value: param.period.gradePeriodId });

      // type
      let totalType = 0;
      for (const i in param.types) {
        requestParams.push({ name: param.types[i].field, value: param.types[i].code });
        totalType += param.types[i].code;
      }
      if (param.types.length === 3) {
        requestParams.push({ name: 'gradeReportTypeMultiselect', value: '0' });
      }
      if (param.bySubject) {
        requestParams.push({ name: 'attendancePerSubject', value: 'Y' });
      }
      requestParams.push({ name: 'gradeReportType', value: totalType.toString() });

      const payload = {
        url: BASE_URL + '/exportGrades',
        params: requestParams
      };
      const formData = new URLSearchParams();
      payload.params.forEach((p: any) => {
        formData.append(p.name, p.value);
      });
      return fetch(payload.url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        credentials: 'include',
        body: formData
      }).then(async (response) => {
        const type = response.headers.get('Content-Type') as string;
        const disposition = response.headers.get('Content-Disposition') as string;
        const fileName = FileServices.getFileName(disposition);
        if (type.includes('json')) {
          const data = await response.json();
          window.open(data.documentLink, '_blank');
        } else {
          const blob = await response.blob();
          FileServices.downloadFile(blob, fileName, type);
        }
        return Promise.resolve();
      });
    }

    @Action({ rawError: true })
    async getPerformanceInfo() {
      try {
        const resp = await GradeServices.getPerformanceInfo({ userMode: this.context.rootState.settings.userMode });
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async getPerformanceInfoAdmin(params?: any) {
      try {
        const resp = await GradeServices.getPerformanceInfoAdmin(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async loadYearsGradePeriods(params: any) {
      try {
        const resp = await GradeServices.getGradePeriods(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve(resp.data);
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async setGradePeriods(params: any) {
      const request: any = {};
      request.gradePeriods = params.gradePeriods.map((a: any) => {
        return JSON.stringify({ gradePeriodId: a.gradePeriodId || 0, name: a.name, startDate: a.startDate, endDate: a.endDate });
      })
      if (params.yearId) {
        request.yearId = params.yearId;
      }
      try {
        const resp = await GradeServices.setGradePeriods(request);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve(resp.data);
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async updateStudentScore(params?: any) {
      try {
        const resp = await GradeServices.updateStudentScore(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async getScoresForStudent(params?: any) {
      try {
        const resp = await GradeServices.getScoresForStudent(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async addNote(params?: any) {
      try {
        const resp = await GradeServices.addNote(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async overrideScore(params?: any) {
      try {
        const resp = await GradeServices.overrideScore(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async updateStandardScore(params?: any) {
      try {
        const resp = await GradeServices.updateStandardScore(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }
}
