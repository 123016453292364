var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "pb-base-editor",
    {
      attrs: {
        isModal: _vm.isModal,
        withActions: false,
        onApply: _vm.doApply,
        typeLabel: _vm.$t("listLabel"),
        isDirty: _vm.isDirty,
      },
      on: { close: _vm.cancelEditor },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              !_vm.isEditing
                ? _c("span", [_vm._v(_vm._s(_vm.$t("addLabel")))])
                : _c("span", [_vm._v(_vm._s(_vm.$t("editLabel")))]),
              _c("span", [_vm._v(" " + _vm._s(_vm.$t("listLabel")))]),
            ]
          },
          proxy: true,
        },
        {
          key: "action-button",
          fn: function () {
            return [
              _vm.isEditing
                ? _c(
                    "pb-btn",
                    {
                      attrs: { icon: "", label: _vm.$t("deleteLabel") },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.deleteSelectedStandard.apply(
                            null,
                            arguments
                          )
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("fal fa-trash-alt")])],
                    1
                  )
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.localValue,
        callback: function ($$v) {
          _vm.localValue = $$v
        },
        expression: "localValue",
      },
    },
    [
      _c("my-list-editor-form", {
        ref: "form",
        attrs: { input: _vm.input, isModal: _vm.isModal },
      }),
      _c("pb-confirm", { ref: "standardEditorConfirm" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }