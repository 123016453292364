var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "pb-base-modal",
    {
      attrs: {
        withActions: false,
        expandable: false,
        withApply: false,
        maxWidth: 1000
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.$t("manageLessonsLabel")) + " ")]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.localValue,
        callback: function($$v) {
          _vm.localValue = $$v
        },
        expression: "localValue"
      }
    },
    [
      _c(
        "v-container",
        { staticStyle: { "max-height": "calc(100vh - 100px) !important" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("pb-date-time-range-field", {
                    attrs: {
                      hideDetails: "auto",
                      start: _vm.localStartDate,
                      end: _vm.localEndDate
                    },
                    on: {
                      "update:start": function($event) {
                        _vm.localStartDate = $event
                      },
                      "update:end": function($event) {
                        _vm.localEndDate = $event
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c("v-text-field", {
                    attrs: {
                      "hide-details": "auto",
                      label: _vm.$t("searchTextOptionalLabel"),
                      outlined: ""
                    },
                    model: {
                      value: _vm.searchText,
                      callback: function($$v) {
                        _vm.searchText = $$v
                      },
                      expression: "searchText"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2",
                      attrs: { color: "primary", text: "", outlined: "" },
                      on: {
                        click: function($event) {
                          return _vm.doShowLessons()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("showLessonsLabel")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", outlined: "" },
                      on: {
                        click: function($event) {
                          ;(_vm.manageLessonsList = []), (_vm.searchText = "")
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("clearLabel")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticClass: "mb-0",
              staticStyle: { "background-color": "#e7e7e7" }
            },
            [
              _c("v-col", { staticClass: "ml-5", attrs: { align: "start" } }, [
                _c("div", { staticClass: "mt-2 black--text" }, [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("lessonsLabel")) +
                        ":  " +
                        _vm._s(_vm.manageLessonsList.length) +
                        ": "
                    )
                  ]),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("selectedLabel")) +
                        ":  " +
                        _vm._s(_vm.selectedRows.length)
                    )
                  ])
                ])
              ]),
              _c(
                "v-col",
                { staticClass: "mr-5", attrs: { align: "end" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2",
                      style: {
                        "background-color": _vm.highContrastMode
                          ? "white"
                          : "transparent"
                      },
                      attrs: { color: "error", text: "", outlined: "" },
                      on: { click: _vm.doDeleteLessons }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("deleteLabel")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      style: {
                        "background-color": _vm.highContrastMode
                          ? "white"
                          : "transparent"
                      },
                      attrs: { color: "primary", text: "", outlined: "" },
                      on: { click: _vm.doUnlinkLessons }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("unlinkLabel")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.manageLessonsList,
              "show-select": "",
              "fixed-header": "",
              "item-key": "lessonId"
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.lessonDate",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("td", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.formatToDisplay(
                                _vm
                                  .moment(item.lessonDate)
                                  .format("MM/DD/YYYY"),
                                true
                              )
                            ) +
                            " "
                        )
                      ])
                    ]
                  }
                },
                {
                  key: "item.hasLinkLessons",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "td",
                        [
                          _vm.hasLinkedLesson(item.lessonAddendums)
                            ? _c("v-icon", [_vm._v("far fa-check")])
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "item.lastUpdate",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("td", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.formatToDisplay(
                                _vm
                                  .moment(item.lastUpdate)
                                  .format("MM/DD/YYYY"),
                                true
                              )
                            ) +
                            " "
                        )
                      ])
                    ]
                  }
                },
                {
                  key: "header.hasLinkLessons",
                  fn: function() {
                    return [
                      _c("v-icon", { staticClass: "mt-4" }, [
                        _vm._v("far fa-link")
                      ])
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              true
            ),
            model: {
              value: _vm.selectedRows,
              callback: function($$v) {
                _vm.selectedRows = $$v
              },
              expression: "selectedRows"
            }
          })
        ],
        1
      ),
      _c("pb-confirm", { ref: "manageLessonsConfirm" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }