var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "editor", staticStyle: { "overflow-x": "hidden" } },
    [
      _c(
        "validation-observer",
        { ref: "observer" },
        [
          _vm.isUpdatingToDo || _vm.isAddingToDo
            ? _c(
                "v-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isTextFieldsVisible,
                      expression: "isTextFieldsVisible"
                    }
                  ]
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "ml-4", attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-chip-group",
                        {
                          attrs: {
                            "active-class": "accent3--text font-weight-bold",
                            column: ""
                          },
                          model: {
                            value: _vm.isToDoDone,
                            callback: function($$v) {
                              _vm.isToDoDone = $$v
                            },
                            expression: "isToDoDone"
                          }
                        },
                        [
                          _c(
                            "pb-chip",
                            {
                              ref: "doneButton",
                              attrs: { filter: "", outlined: "" },
                              on: {
                                keyup: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  _vm.isToDoDone =
                                    _vm.isToDoDone === 0 ? null : 0
                                }
                              }
                            },
                            [_c("span", [_vm._v(_vm._s(_vm.$t("doneLabel")))])]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-col",
                    { staticClass: "mr-4", attrs: { cols: "auto" } },
                    [
                      _c(
                        "h3",
                        {
                          staticClass: "my-auto mr-4",
                          staticStyle: { display: "inline" }
                        },
                        [_vm._v(_vm._s(_vm.$t("priorityLabel")))]
                      ),
                      _c(
                        "v-btn-toggle",
                        {
                          attrs: { color: "primary", mandatory: "", dense: "" },
                          model: {
                            value: _vm.priorityChoices,
                            callback: function($$v) {
                              _vm.priorityChoices = $$v
                            },
                            expression: "priorityChoices"
                          }
                        },
                        [
                          _c("v-btn", [
                            _c("span", [_vm._v(_vm._s(_vm.$t("lowLabel")))])
                          ]),
                          _c("v-btn", [
                            _c("span", [_vm._v(_vm._s(_vm.$t("mediumLabel")))])
                          ]),
                          _c("v-btn", [
                            _c("span", [_vm._v(_vm._s(_vm.$t("highLabel")))])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isTextFieldsVisible,
                  expression: "isTextFieldsVisible"
                }
              ],
              staticClass: "mt-2",
              attrs: { cols: "auto" }
            },
            [
              _vm.isUpdatingNotes || _vm.isAddingNotes
                ? _c("pb-date-field", {
                    ref: "dateField",
                    attrs: {
                      label: _vm.$t("dateLabel"),
                      value: _vm.notesTodoStartDate,
                      hideDetails: "auto",
                      clearable: true
                    },
                    on: {
                      "update:value": function($event) {
                        _vm.notesTodoStartDate = $event
                      }
                    }
                  })
                : _c("pb-date-time-range-field", {
                    attrs: {
                      label: _vm.$t("dateLabel"),
                      start: _vm.notesTodoStartDate,
                      end: _vm.notesTodoEndDate,
                      hideDetails: "auto",
                      required: ""
                    },
                    on: {
                      "update:start": function($event) {
                        _vm.notesTodoStartDate = $event
                      },
                      "update:end": function($event) {
                        _vm.notesTodoEndDate = $event
                      }
                    }
                  }),
              _vm.isUpdatingNotes || _vm.isAddingNotes
                ? _c("v-select", {
                    staticClass: "mt-3",
                    attrs: {
                      label: _vm.$t("classLabel"),
                      items: _vm.notesClassItems,
                      clearable: "",
                      outlined: "",
                      "hide-details": "auto"
                    },
                    model: {
                      value: _vm.notesClassId,
                      callback: function($$v) {
                        _vm.notesClassId = $$v
                      },
                      expression: "notesClassId"
                    }
                  })
                : _vm._e(),
              _vm.isUpdatingNotes || _vm.isAddingNotes
                ? _c("v-select", {
                    staticClass: "mt-3",
                    attrs: {
                      label: _vm.$t("studentLabel"),
                      items: _vm.getNotesStudentItems,
                      clearable: "",
                      outlined: "",
                      "hide-details": "auto"
                    },
                    model: {
                      value: _vm.notesStudentId,
                      callback: function($$v) {
                        _vm.notesStudentId = $$v
                      },
                      expression: "notesStudentId"
                    }
                  })
                : _vm._e(),
              _vm.showRepeats && (_vm.isUpdatingToDo || _vm.isAddingToDo)
                ? _c("v-select", {
                    staticClass: "mt-3",
                    attrs: {
                      "hide-details": "auto",
                      outlined: "",
                      items: _vm.occurrences
                    },
                    model: {
                      value: _vm.localTodo.repeats,
                      callback: function($$v) {
                        _vm.$set(_vm.localTodo, "repeats", $$v)
                      },
                      expression: "localTodo.repeats"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: { "margin-left": "1px" },
              on: {
                mouseenter: function($event) {
                  _vm.isSectionsHovered = true
                },
                mouseleave: function($event) {
                  _vm.isSectionsHovered = false
                }
              }
            },
            [
              _c("text-editor-full-screen-button", {
                attrs: {
                  isSectionsHovered: _vm.isSectionsHovered,
                  isTextFieldsVisible: _vm.isTextFieldsVisible
                },
                on: { hideOrShowTextFields: _vm.hideOrShowTextFields }
              }),
              _c("pb-text-editor", {
                staticStyle: { "border-top": "1px solid #cccccc" },
                attrs: {
                  refreshKey: _vm.localRefreshKey,
                  config: _vm.localTextEditorConfig
                },
                model: {
                  value: _vm.eventText,
                  callback: function($$v) {
                    _vm.eventText = $$v
                  },
                  expression: "eventText"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("pb-confirm", { ref: "addNotesToDoConfirm" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }