var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "pb-base-editor",
    {
      attrs: {
        isModal: _vm.isModal,
        withActions: false,
        onClose: _vm.cancelEditor,
        withApply: false,
        onApply: _vm.doApply,
        typeLabel: _vm.$t("lessonLayoutLabel"),
        isDirty: _vm.isDirty
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _c("span", { staticStyle: { "font-weight": "600" } }, [
                _vm._v(_vm._s(_vm.getLessonLayoutLevel()))
              ])
            ]
          },
          proxy: true
        },
        {
          key: "action-button",
          fn: function() {
            return [
              _vm.isEditable() && !_vm.isNewLayout
                ? _c(
                    "pb-btn",
                    {
                      attrs: { label: _vm.$t("deleteLabel"), icon: "" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.removeLessonLayout()
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("fal fa-trash-alt")])],
                    1
                  )
                : _vm._e(),
              !_vm.$currentUser.isDistrictAdmin
                ? _c(
                    "pb-btn",
                    {
                      attrs: {
                        label: _vm.$t("applyLabel"),
                        icon: "",
                        right: ""
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          !_vm.listLoading ? _vm.applyLessonLayout(true) : null
                        }
                      }
                    },
                    [
                      _vm.listLoading
                        ? _c("v-icon", [_vm._v("fal fa-sync fa-spin")])
                        : _c("v-icon", [_vm._v("fal fa-check")])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$currentUser.isDistrictAdmin
                ? _c(
                    "v-menu",
                    {
                      attrs: { "offset-y": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "pb-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          label: _vm.$t("applyLabel"),
                                          icon: "",
                                          right: "",
                                          disabled: _vm.listLoading
                                        }
                                      },
                                      "pb-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _vm.listLoading
                                      ? _c("v-icon", [
                                          _vm._v("fal fa-sync fa-spin")
                                        ])
                                      : _c("v-icon", [_vm._v("fal fa-check")])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        222031279
                      ),
                      model: {
                        value: _vm.districtMenu,
                        callback: function($$v) {
                          _vm.districtMenu = $$v
                        },
                        expression: "districtMenu"
                      }
                    },
                    [
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.applyLessonLayoutTo("S", true)
                                }
                              }
                            },
                            [
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(_vm.$t("saveToSchoolLabel")))
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.applyLessonLayoutTo("D", true)
                                }
                              }
                            },
                            [
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(_vm.$t("saveToDistrictLabel")))
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.localValue,
        callback: function($$v) {
          _vm.localValue = $$v
        },
        expression: "localValue"
      }
    },
    [
      _c("pb-confirm", { ref: "lessonLayoutEditorConfirm" }),
      _c("lesson-layout-editor-form", { ref: "form" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }