import DateTimeUtils from '@/utils/date-time-utils';
import { permissionsMapping, permissionsMappingMirrored } from '@/constants';
import AppError, { createErrOptions, SessionExpiredError } from '@/errors';
import eventBus from '@/events';
import LessonServices from '@/services/lesson-services';
import CommonUtils from '@/utils/common-utils';
import ld from 'lodash';
import moment from 'moment-timezone';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '..';
import ChangeHistoryServices from '@/services/change-history-services';

@Module({ namespaced: true })
export default class Lessons extends VuexModule {
    sections: any[] = [];
    loadData = false;
    editLink = false;
    linkedLesson = null;
    lesson: any = null;
    // lesson data
    dayOfWeek = '';
    collaborateSubjectId = 0;
    date = '';
    className = '';
    classId = 0;
    extraLesson = 0;
    lessonTitle = '';
    lessonText = '';
    tab2Text = '';
    tab3Text = '';
    tab4Text = '';
    tab5Text = '';
    tab6Text = '';
    standards: any[] = [];
    myStandards: any[] = [];
    lessonLock = '';
    addClassDaysCode = '';
    schoolStandards: any[] = [];
    strategies: any[] = [];
    attachments: any[] = [];
    origStartTime = '';
    startTime = '';
    origEndTime = '';
    endTime = '';
    unitId = 0;
    unitItems: any[] = [];
    statuses: any[] = [];
    schoolStatuses: any[] = [];
    assignments: any[] = [];
    assessments: any[] = [];
    updatedFields: Set<string> = new Set<string>();
    linkedLessonId = 0;
    lessonId = 0;
    toCopy: any = {};
    saving = false;
    dragging = false;
    changeHistory: any[] = [];
    oldLesson: any = {};
    stackMode = false;
    listStackMode = true;

    // GETTERS: START
    get getSectionsMap() {
      const map: any = {};
      for (const i in this.sections) {
        const s: any = this.sections[i];
        map[s.section] = s;
      }
      return map;
    }

    get getPermission() {
      return (section: string) => {
        const lesson = this.lesson;
        const editLink = this.editLink;
        const userMode = this.context.rootState.settings.userMode;
        const sharedClasses = this.context.rootGetters['settings/getSharedClasses'];
        const userId = this.context.rootState.settings.userId;
        if (userMode === 'S' || userMode === 'A' || lesson.linkedTeacherId === userId || !editLink) {
          return 'write';
        } else {
          const sharedClass = sharedClasses[lesson.linkedClassId || lesson.classId];
          if (sharedClass) {
            if (sharedClass.teacherId === userId) {
              return 'write';
            } else if (sharedClass.hasFullPermissions) {
              return 'write';
            } else if (sharedClass.permissionSettings) {
              return sharedClass.permissionSettings[permissionsMapping[section]];
            }
          }
          return 'none';
        }
      }
    }

    get hasNoPermission() {
      return (section: string) => {
        const lesson = this.lesson;
        const editLink = this.editLink;
        const userMode = this.context.rootState.settings.userMode;
        const sharedClasses = this.context.rootGetters['settings/getSharedClasses'];
        const userId = this.context.rootState.settings.userId;
        if (userMode === 'S' || userMode === 'A' || lesson.linkedTeacherId === userId || !editLink) {
          return false;
        } else {
          const sharedClass = sharedClasses[lesson.linkedClassId || lesson.classId];
          if (sharedClass) {
            if (sharedClass.teacherId === userId) {
              return false;
            } else if (sharedClass.hasFullPermissions) {
              return false;
            } else if (sharedClass.permissionSettings) {
              return !['read', 'write'].includes(sharedClass.permissionSettings[permissionsMapping[section]]);
            }
          }
          return true;
        }
      }
    }

    get canEdit() {
      return (section: string) => {
        const lesson = this.lesson;
        const editLink = this.editLink;
        const userMode = this.context.rootState.settings.userMode;
        const sharedClasses = this.context.rootGetters['settings/getSharedClasses'];
        const userId = this.context.rootState.settings.userId;
        if (userMode === 'S' || userMode === 'A' || lesson.linkedTeacherId === userId) {
          return true;
        } else {
          const sharedClass = sharedClasses[lesson.linkedClassId || lesson.classId];
          if (sharedClass) {
            if (sharedClass.teacherId === userId) {
              return true;
            } if (sharedClass.hasFullPermissions) {
              return true;
            } else if (sharedClass.permissionSettings) {
              return ['write'].includes(sharedClass.permissionSettings[permissionsMapping[section]]);
            }
          } else if (!editLink) {
            return true;
          }
          return false;
        }
      }
    }

    get canView() {
      return (section: string) => {
        const lesson = this.lesson;
        const editLink = this.editLink;
        const userMode = this.context.rootState.settings.userMode;
        const sharedClasses = this.context.rootGetters['settings/getSharedClasses'];
        const userId = this.context.rootState.settings.userId;
        if (userMode === 'S' || userMode === 'A' || lesson.linkedTeacherId === userId) {
          return true;
        } else {
          const sharedClass = sharedClasses[lesson.linkedClassId || lesson.classId];
          if (sharedClass) {
            if (sharedClass.teacherId === userId) {
              return true;
            } if (sharedClass.hasFullPermissions) {
              return true;
            } else if (sharedClass.permissionSettings) {
              return ['write', 'read'].includes(sharedClass.permissionSettings[permissionsMapping[section]]);
            }
          } else if (!editLink) {
            return true;
          }
          return false;
        }
      }
    }

    get hasData() {
      return LessonServices.hasLessonData(this);
    }

    get createNewLesson() {
      return () => {
        return {
          lessonId: 0,
          date: '',
          dayOfWeek: '',
          assignments: [],
          tab4Text: '',
          type: 'L',
          homeworkText: '',
          customStart: '',
          tab5Text: '',
          customEnd: '',
          lessonLock: 'N',
          lessonText: '',
          tab6Text: '',
          standards: [],
          lessonTitle: '',
          notesText: '',
          attachments: [],
          extraLesson: 999,
          className: '',
          classId: 0,
          startTime: '',
          assessments: [],
          addClassDaysCode: '',
          strategies: [],
          myStandards: [],
          addendums: [],
          endTime: ''
        }
      }
    }

    get cloneState() {
      return () => {
        return ld.cloneDeep(this.context.state);
      }
    }

    get headerTitle() {
      if ((this.extraLesson > 0 && this.extraLesson !== 999) && (CommonUtils.hasNoText(this.date) || this.date === '12/31/9999')) {
        return `Lesson #${this.extraLesson} - ${this.className}`;
      } else {
        return `${DateTimeUtils.formatToDisplay(this.date)} - ${this.className}`;
      }
    }
    // GETTERS: END

    // MUTATIONS: START
    @Mutation
    setDayOfWeek(dayOfWeek: string) {
      this.dayOfWeek = dayOfWeek;
    }

    @Mutation
    setDate(date: string) {
      this.date = date;
    }

    @Mutation
    setClassId(classId: number) {
      this.classId = classId;
    }

    @Mutation
    setStartTime(startTime: string) {
      this.startTime = startTime;
    }

    @Mutation
    setEndTime(endTime: string) {
      this.endTime = endTime;
    }

    @Mutation
    setClassName(className: string) {
      this.className = className;
    }

    @Mutation
    setLessonTitle(lessonTitle: string) {
      this.lessonTitle = lessonTitle;
    }

    @Mutation
    setLessonText(lessonText: string) {
      this.lessonText = lessonText;
    }

    @Mutation
    setTab2Text(tab2Text: string) {
      this.tab2Text = tab2Text;
    }

    @Mutation
    setTab3Text(tab3Text: string) {
      this.tab3Text = tab3Text;
    }

    @Mutation
    setTab4Text(tab4Text: string) {
      this.tab4Text = tab4Text;
    }

    @Mutation
    setTab5Text(tab5Text: string) {
      this.tab5Text = tab5Text;
    }

    @Mutation
    setTab6Text(tab6Text: string) {
      this.tab6Text = tab6Text;
    }

    @Mutation
    setStandards(standards: Array<any>) {
      this.standards = standards;
    }

    @Mutation
    removeStandard(index: number) {
      if (this.standards) {
        this.standards.splice(index, 1);
      }
    }

    @Mutation
    setMyStandards(myStandards: Array<any>) {
      this.myStandards = myStandards;
    }

    @Mutation
    removeMyStandard(index: number) {
      if (this.myStandards) {
        this.myStandards.splice(index, 1);
      }
    }

    @Mutation
    setLessonLock(lessonLock: string) {
      this.lessonLock = lessonLock;
    }

    @Mutation
    setAddClassDaysCode(addClassDaysCode: string) {
      this.addClassDaysCode = addClassDaysCode;
    }

    @Mutation
    setSchoolStandards(schoolStandards: Array<any>) {
      this.schoolStandards = schoolStandards;
    }

    @Mutation
    removeSchoolStandard(index: number) {
      if (this.schoolStandards) {
        this.schoolStandards.splice(index, 1);
      }
    }

    @Mutation
    setStrategies(strategies: Array<any>) {
      this.strategies = strategies;
    }

    @Mutation
    removeStrategy(index: number) {
      if (this.strategies) {
        this.strategies.splice(index, 1);
      }
    }

    @Mutation
    setAttachments(attachments: Array<any>) {
      this.attachments = attachments;
    }

    @Mutation
    removeAttachment(index: number) {
      if (this.attachments) {
        this.attachments.splice(index, 1);
      }
    }

    @Mutation
    setUnitId(unitId: number) {
      this.unitId = unitId;
    }

    @Mutation
    setExtraLesson(extraLesson: number) {
      this.extraLesson = extraLesson;
    }

    @Mutation
    setUnitItems(unitItems: Array<any>) {
      this.unitItems = unitItems;
    }

    @Mutation
    setStatuses(statuses: Array<any>) {
      this.statuses = statuses;
    }

    @Mutation
    removeStatus(index: number) {
      if (this.statuses) {
        this.statuses.splice(index, 1);
      }
    }

    @Mutation
    setSchoolStatuses(statuses: Array<any>) {
      this.schoolStatuses = statuses;
    }

    @Mutation
    setUpdatedFields(updatedFields: Set<any>) {
      this.updatedFields = updatedFields;
    }

    @Mutation
    setAssignments(assignments: Array<any>) {
      this.assignments = assignments;
    }

    @Mutation
    setAssessments(assessments: Array<any>) {
      this.assessments = assessments;
    }

    @Mutation
    setLessonId(lessonId: number) {
      if (this.lesson) {
        this.lesson.lessonId = lessonId;
      }
    }

    @Mutation
    initLesson(params: any) {
      if (!params.loadData) {
        return;
      }
      LessonServices.populateLesson(this, params);
      this.updatedFields = new Set<string>();
    }

    @Mutation
    resetSections() {
      this.sections = [];
      this.lesson = null;
      this.linkedLesson = null;
    }

    @Mutation
    initSections() {
      LessonServices.initLessonSections(this);
    }

    @Mutation
    setActiveTab(tabId: string) {
      const clone: any[] = ld.cloneDeep(this.sections);
      for (const i in clone) {
        if (clone[i].section === tabId) {
          clone[i].active = true;
        } else {
          clone[i].active = false;
        }
      }
      this.sections = clone;
    }

    @Mutation
    setPrivateFlag(params: any) {
      this.attachments[params.index].privateFlag = params.value;
    }

    @Mutation
    copyLesson() {
      const newLesson = LessonServices.newLesson();
      for (const key in newLesson) {
        this.toCopy[key] = store.state.lessons[key];
      }
      console.log('TO COPY: ' + JSON.stringify(this.toCopy));
    }

    @Mutation
    clearCopy() {
      this.toCopy = {};
    }

    @Mutation
    setLinkedLesson(linkedLesson: any) {
      this.linkedLesson = linkedLesson;
    }

    @Mutation
    setLesson(lesson: any) {
      this.lesson = lesson;
      if (this.lesson && this.lesson.assessments) {
        this.lesson.assessments = (this.lesson.assessments || []).concat(this.lesson.googleAssessments || [])
        this.lesson.assignments = (this.lesson.assignments || []).concat(this.lesson.googleAssignments || [])
      }
    }

    @Mutation
    setEditLink(editLink: boolean) {
      this.editLink = editLink;
    }

    @Mutation
    setSaving(saving: boolean) {
      this.saving = saving;
    }

    @Mutation
    setLessonData(dayObject: any) {
      this.className = dayObject.className;
      this.classId = dayObject.classId;
      this.dayOfWeek = dayObject.dayOfWeek;
      this.date = dayObject.date;
      this.extraLesson = dayObject.extraLesson;
    }

    @Mutation
    setDragging(dragging: boolean) {
      this.dragging = dragging;
    }

    @Mutation
    setOldLesson(oldLesson: any) {
      this.oldLesson = oldLesson;
    }

    @Mutation
    prepareUndoFromState(data: any) {
      if (data.oldLesson) {
        const oldLesson = ld.cloneDeep(data);
        LessonServices.cleanUpAttrs(oldLesson);
        const lessonKeys = Object.keys(LessonServices.newLesson());
        for (const key in this) {
          if (lessonKeys.includes(key)) this[key] = data.oldLesson[key];
        }
        this.oldLesson = oldLesson;
      }
    }

    @Mutation
    prepareRestoreFromPayload(payload: any) {
      if (payload && CommonUtils.hasText(payload.oldLesson)) {
        const oldLesson = JSON.parse(payload.oldLesson);
        const lessonKeys = Object.keys(oldLesson);
        for (const key in this) {
          if (lessonKeys.includes(key)) this[key] = oldLesson[key];
        }
        this.updatedFields = new Set<string>();
        this.updatedFields.add('RESTORE');
      }
    }

    @Mutation
    prepareFromState(data: any) {
      const keys = Object.keys(data);
      for (const key in this) {
        if (keys.includes(key)) this[key] = data[key];
      }
    }

    @Mutation
    prepareStateFromPayload(payload: any) {
      if (payload) {
        this.classId = payload.classId;
        this.date = payload.customDate;
        this.unitId = payload.unitId;
        this.extraLesson = payload.extraLesson;
        this.lessonText = payload.lessonText;
        this.tab2Text = payload.homeworkText;
        this.tab3Text = payload.notesText;
        this.tab4Text = payload.tab4Text;
        this.tab5Text = payload.tab5Text;
        this.tab6Text = payload.tab6Text;
        this.addClassDaysCode = payload.addClassDaysCode;
        this.startTime = payload.customStart;
        this.endTime = payload.customEnd;
        this.lessonTitle = payload.lessonTitle;
        this.lessonLock = payload.lessonLock;
        this.strategies = LessonServices.unflatten(payload.strategyIds, 'strategyId');
        this.standards = LessonServices.unflatten(payload.standardDBIds, 'dbId');
        this.myStandards = LessonServices.unflatten(payload.myStandardDBIds, 'dbId');
        this.schoolStandards = LessonServices.unflatten(payload.schoolStandardDBIds, 'dbId');
        this.statuses = LessonServices.unflatten(payload.statuses, 'lessonStatusId');
        this.schoolStatuses = LessonServices.unflatten(payload.schoolStatuses, 'schoolStatusId');
        this.linkedLessonId = payload.linkedLessonId;
        const attachmentNames = LessonServices.paramStringValueToArray(payload.attachmentNames);
        const attachmentPrivate = LessonServices.paramStringValueToArray(payload.attachmentPrivate);
        const attachmentURL = LessonServices.paramStringValueToArray(payload.attachmentURL);
        if (CommonUtils.isNotEmpty(attachmentNames)) {
          this.attachments = attachmentNames.map((an: string, i: number) => {
            return {
              filename: an,
              privateFlag: CommonUtils.isTrue(attachmentPrivate[i]),
              url: attachmentURL[i]
            }
          });
        }
        if (CommonUtils.hasText(payload.schoolWorks)) {
          const schoolWorks: Array<any> = JSON.parse(payload.schoolWorks);
          const assessments = [];
          const assignments = [];
          schoolWorks.forEach(sw => {
            const isGoogle = sw.type.startsWith('GOOGLE');
            if (sw.type.endsWith('ASSIGNMENT')) {
              assignments.push({
                source: isGoogle ? 'gc' : '',
                assignmentId: isGoogle ? undefined : sw.typeId,
                classworkId: isGoogle ? sw.typeId : undefined,
                sourceid: sw.shortValueText
              });
            } else {
              assessments.push({
                source: isGoogle ? 'gc' : '',
                assessmentId: isGoogle ? undefined : sw.typeId,
                classworkId: isGoogle ? sw.typeId : undefined,
                sourceid: sw.shortValueText
              });
            }
          })
        }
        if (typeof payload.oldLesson === 'string') {
          this.oldLesson = JSON.parse(payload.oldLesson);
        } else {
          this.oldLesson = payload.oldLesson;
        }
      }
    }

    @Mutation
    setChangeHistory(changeHistory: any[]) {
      this.changeHistory = changeHistory;
    }

    @Mutation
    setIsStackMode(isStackMode: boolean) {
      this.stackMode = isStackMode;
    }

    @Mutation
    setIsListStackMode(listStackMode: boolean) {
      this.listStackMode = listStackMode;
    }
    // MUTATIONS: END

    // ACTIONS: START
    @Action({ rawError: true })
    async init(params?: any) {
      const data = params.data;
      if (data) {
        this.context.commit('setLesson', params.data);
        if (this.editLink) {
          try {
            const resp = await LessonServices.getLesson({ lessonId: params.data.linkedLessonId });
            if (resp) {
              const data = resp.data;
              if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
                return Promise.reject(new SessionExpiredError());
              } else if (data.error === 'true' || data.error === true) {
                return Promise.reject(new AppError('contactSupport', { data }));
              } else {
                const rawDay = data.day;
                const rawDayObject = CommonUtils.isNotEmpty(rawDay.objects) ? rawDay.objects[0] : null;
                const dayKey = 'day' + (moment(rawDay.date, 'MM/DD/YYYY').toDate().getDay() + 1);
                rawDayObject.day = dayKey;
                rawDayObject.date = rawDay.date;
                rawDayObject.dayOfWeek = rawDay.dayOfWeek;
                this.context.commit('setLinkedLesson', rawDayObject);
                this.context.commit('initLesson', { loadData: true, data: rawDayObject });
                return Promise.resolve({ data });
              }
            } else {
              return Promise.resolve({ data: {} });
            }
          } catch (e) {
            return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
          }
        } else {
          this.context.commit('initLesson', params);
        }
      } else {
        this.context.commit('initLesson', params);
      }
      return Promise.resolve({ data: {} });
    }

    @Action({ rawError: true })
    async getLessonById(lessonId: any) {
      try {
        const resp = await LessonServices.getLesson({ lessonId: lessonId });
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve(data);
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async saveLesson(params?: any) {
      try {
        let request;
        if (params && params.isCalledByLessonNavigation) {
          request = LessonServices.createRequest(params.state, true);
        } else {
          request = LessonServices.createRequest(undefined, true);
        }
        if (!params || !params.isCalledByLessonBank) {
          request.fetchDay = true;
        }
        const resp = await LessonServices.updateLesson(request);
        if (resp) {
          const data = resp.data;
          if (CommonUtils.isTrue(data.notLoggedIn)) {
            return Promise.reject(new SessionExpiredError());
          } else if (CommonUtils.isTrue(data.error)) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else if (CommonUtils.isTrue(data.isOutsideOfSchedule)) {
            return Promise.reject(new AppError('lessonOutsideOfScheduleMsg', { data }));
          } else {
            data.day.objects.forEach((o: any) => {
              o.dayOfWeek = data.day.dayOfWeek;
              o.date = data.day.date;
            });
            const rawDayObject = data.day.objects.find((o: any) => (o.classId === data.subjectId || (o.collaborateSubjectId > 0 && o.collaborateSubjectId === data.subjectId)) && o.extraLesson === data.extraLesson);
            if (!params || params.isCalledByLessonBank || params.isCalledByLessonNavigation) {
              if (params && !params.isCalledByLessonNavigation) {
                this.context.commit('setLessonData', rawDayObject);
                this.context.commit('setUpdatedFields', new Set<string>());
              }
              if (!params && rawDayObject) {
                this.context.commit('setLessonId', rawDayObject.lessonId)
              }
              this.context.commit('plans/updateLessons', data, { root: true });
              if (this.context.rootState.plans.viewType === 'L' || data.linkedCount > 0) {
                console.log('save lesson from listview -> reload plans')
                this.context.commit('plans/setHaveList', false, { root: true });
                await this.context.dispatch('plans/reloadPlans', { showLoading: false }, { root: true });
              }
            }
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        console.log(e);
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async save(params?: any) {
      this.context.commit('setSaving', true);
      return this.context.dispatch('saveLesson', params).finally(() => {
        this.context.commit('setSaving', false);
      })
    }

    @Action({ rawError: true })
    async undoSave(params: any) {
      if (!params) {
        return Promise.reject(new AppError('contactSupport'));
      }
      this.context.commit('prepareUndoFromState', params);
      return this.context.dispatch('save');
    }

    @Action({ rawError: true })
    async restore(params: any) {
      if (!params) {
        return Promise.reject(new AppError('contactSupport'));
      }
      const payload = LessonServices.getLessonPayload(params);
      if (params.initStateFromPayload) {
        const updatedFields = new Set<string>();
        updatedFields.add('RESTORE');
        this.context.commit('prepareStateFromPayload', payload);
        this.context.commit('prepareUndoFromState', this.context.state);
        this.context.commit('setUpdatedFields', updatedFields);
      } else {
        this.context.commit('prepareRestoreFromPayload', payload);
      }
      return this.context.dispatch('save');
    }

    @Action({ rawError: true })
    async undoBumpForward(params: any) {
      if (!params) {
        return Promise.reject(new AppError('contactSupport'));
      }
      const payload = LessonServices.getLessonPayload(params);
      if (params.initStateFromPayload) {
        this.context.commit('prepareStateFromPayload', payload);
      } else {
        this.context.commit('prepareFromState', params);
      }
      if (params?.deleteCustomDay) {
        this.context.dispatch('deleteCustomClassDay');
      }
      return this.context.dispatch('delete', {
        shiftLessons: 'Y',
        numDays: CommonUtils.get(params.numDays, (payload || {}).numDays),
        action: CommonUtils.get(params.action, (payload || {}).action),
        bumpedLessonId: CommonUtils.get(params.bumpedLessonId, (payload || {}).bumpedLessonId),
        deleteCustomDay: params?.deleteCustomDay
      });
    }

    @Action({ rawError: true })
    async undoBumpBackward(params: any) {
      if (!params) {
        return Promise.reject(new AppError('contactSupport'));
      }
      const payload = LessonServices.getLessonPayload(params);
      if (params.initStateFromPayload) {
        this.context.commit('prepareStateFromPayload', payload);
      } else {
        this.context.commit('prepareFromState', params);
      }
      return this.context.dispatch('undoDelete', {
        shiftLessons: 'Y',
        bumpBack: 'Y',
        numDays: CommonUtils.get(params.numDays, -1 * (payload || {}).numDays),
        bumpedLessonId: CommonUtils.get(params.bumpedLessonId, (payload || {}).bumpedLessonId),
        lessonIds: CommonUtils.get(params.lessonIds, (payload || {}).lessonIds)
      });
    }

    @Action({ rawError: true })
    async undoDeleteLesson(params: any) {
      if (!params) {
        return Promise.reject(new AppError('contactSupport'));
      }
      const payload = LessonServices.getLessonPayload(params);
      if (params.initStateFromPayload) {
        this.context.commit('prepareStateFromPayload', payload);
      } else {
        this.context.commit('prepareFromState', params);
      }
      if (params?.deleteCustomDay) {
        this.context.dispatch('deleteCustomClassDay');
      }
      return this.context.dispatch('undoDelete', {
        shiftLessons: CommonUtils.get(params.shiftLessons, (payload || {}).shiftLessons),
        lessonIds: CommonUtils.get(params.lessonIds, (payload || {}).lessonIds) || CommonUtils.get(params.lessonId, (payload || {}).lessonId),
        bumpedLessonId: CommonUtils.get(params.bumpedLessonId, (payload || {}).bumpedLessonId),
        deleteCustomDay: params?.deleteCustomDay
      });
    }

    @Action({ rawError: true })
    async deleteCustomClassDay(params?: any) {
      try {
        const request = LessonServices.createRequest();
        const resp = await LessonServices.deleteCustomClassDay(request);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            if (params?.reloadPlans) {
              await this.context.dispatch('plans/reloadPlans', {}, { root: true });
            }
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async bump(params?: any) {
      try {
        const request = LessonServices.createRequest();
        request.fetchDay = false;
        request.action = 'bump';
        request.numDays = params.numDays;
        request.verify = params.verify;
        const resp = await LessonServices.bump(request);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else if (data.count > 0) {
            eventBus.$emit('confirmDelete', data);
            return Promise.resolve({ data });
          } else {
            await this.context.dispatch('plans/reloadPlans', {}, { root: true });
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async extendStandards(params?: any) {
      try {
        const request = LessonServices.createRequest();
        request.fetchDay = false;
        request.numDays = params.numDays;
        const resp = await LessonServices.extendStandards(request);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            await this.context.dispatch('plans/reloadPlans', {}, { root: true });
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async extend(params?: any) {
      try {
        const request = LessonServices.createRequest();
        request.fetchDay = false;
        request.numDays = params.numDays;
        const resp = await LessonServices.extend(request);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            await this.context.dispatch('plans/reloadPlans', {}, { root: true });
            console.log('new lessonid ', data.extendedLessons[0].lessonId)
            this.context.commit('setLessonId', data.extendedLessons[0].lessonId)
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async delete(params?: any) {
      try {
        const request = LessonServices.createRequest();
        request.shiftLessons = params.shiftLessons;
        request.numDays = params.numDays || 1;
        request.action = params.action || 'DELETELESSON';
        request.bumpedLessonId = params.bumpedLessonId;
        request.deleteCustomDay = params.deleteCustomDay;
        const resp = await LessonServices.delete(request);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            await this.context.dispatch('plans/reloadPlans', {}, { root: true });
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async undoDelete(params?: any) {
      try {
        const request = LessonServices.createRequest();
        request.shiftLessons = params.shiftLessons;
        request.numDays = params.numDays || 1;
        request.bumpBack = params.bumpBack;
        request.lessonIds = params.lessonIds;
        request.bumpedLessonId = params.bumpedLessonId;
        request.deleteCustomDay = params.deleteCustomDay;
        const resp = await LessonServices.undoDelete(request);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            await this.context.dispatch('plans/reloadPlans', {}, { root: true });
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async removeClassDay(params?: any) {
      try {
        const request = LessonServices.createRequest();
        request.shiftLessons = params.shiftLessons;
        request.numDays = 1;
        request.action = 'REMOVECLASSFROMDAY';
        request.bumpedLessonId = params.bumpedLessonId;
        const resp = await LessonServices.removeClassDay(request);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            await this.context.dispatch('plans/reloadPlans', {}, { root: true });
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async move(params?: any) {
      try {
        const request = LessonServices.createRequest();
        request.fromClassId = request.classId;
        request.fromLessonDate = request.customDate;
        request.fromExtraLesson = request.extraLesson;
        request.copyIt = params.copyIt
        request.overwriteIt = params.overwriteIt;
        request.classId = params.classId;
        request.extraLesson = params.extraLesson;
        request.customDate = params.date;
        request.numDays = 1;
        const resp = await LessonServices.move(request);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else if (data.returnMsg === 'lessonExists') {
            eventBus.$emit('confirmMove', data);
            return Promise.resolve({ data });
          } else {
            await this.context.dispatch('plans/reloadPlans', {}, { root: true });
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async swap(params?: any) {
      try {
        const request = {
          lessonDate: this.date,
          subjectId: this.classId,
          extraLesson: this.extraLesson,
          swapWithSubjectId: params.subjectId,
          swapWithLessonDate: params.date,
          swapWithExtraLesson: params.extraLesson || 0
        };
        const resp = await LessonServices.swap(request);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            if (!data.invalidDate) {
              await this.context.dispatch('plans/reloadPlans', {}, { root: true });
            }
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async paste(page?: string) {
      try {
        if (!this.toCopy.classId || this.toCopy.classId === 0) {
          return Promise.resolve({});
        }
        const userId = this.context.rootState.settings.userId;
        const isCopyAllSections = this.context.rootGetters['lessonactions/isCopyAllSections'];
        const sectionsToCopy = this.context.rootGetters['lessonactions/getSectionsToCopy'];
        const target: any = ld.cloneDeep(this.context.state);
        const source = ld.cloneDeep(this.toCopy);
        const sharedClasses = this.context.rootGetters['settings/getSharedClasses'];
        const sharedClass = sharedClasses[target.classId];
        source.startTime = (target.extraLesson) ? target.startTime : '';
        source.endTime = (target.extraLesson) ? target.endTime : '';
        let request = null;
        if (isCopyAllSections) {
          source.classId = target.classId;
          source.date = target.date;
          source.extraLesson = target.extraLesson;
          source.unitId = target.unitId;
          source.collaborateSubjectId = target.collaborateSubjectId;
          if (sharedClass && sharedClass.teacherId !== userId && sharedClass.permissionSettings) {
            for (const [key, value] of Object.entries(sharedClass.permissionSettings)) {
              if (value === 'read') {
                const section = permissionsMappingMirrored[String(key)];
                source[section] = target[section];
              }
            }
          }
          request = LessonServices.createRequest(source);
        } else {
          for (const i in sectionsToCopy) {
            const section = sectionsToCopy[i];
            const sectionShort = section.includes('Text') ? section.replace('Text', '') : section;
            if (sharedClass && sharedClass.teacherId !== userId) {
              if (sharedClass.permissionSettings[permissionsMapping[sectionShort]] === 'write') {
                target[section] = source[section];
              }
            } else {
              target[section] = source[section];
            }
          }
          request = LessonServices.createRequest(target);
        }
        request.fetchDay = true;
        request.pasteSource = page === 'lesson-banks';
        const resp = await LessonServices.paste(request);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            if (this.context.rootState.plans.viewType === 'L') {
              this.context.commit('plans/setHaveList', false, { root: true });
              await this.context.dispatch('plans/reloadPlans', { showLoading: false }, { root: true });
            } else if (data.linkedCount) {
              await this.context.dispatch('plans/reloadPlans', {}, { root: true });
            } else if (data.day) {
              this.context.commit('plans/updateLessons', data, { root: true });
            }
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async search(params?: any) {
      try {
        const resp = await LessonServices.search(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async deleteLessons(params?: any) {
      try {
        const resp = await LessonServices.deleteLessons(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            await this.context.dispatch('plans/reloadPlans', {}, { root: true });
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async doUnlink(params?: any) {
      try {
        const resp = await LessonServices.doUnlink(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            await this.context.dispatch('plans/reloadPlans', {}, { root: true });
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    copy() {
      this.context.commit('clearCopy');
      this.context.commit('copyLesson');
      return new Promise(resolve => setTimeout(resolve, 200));
    }

    @Action({ rawError: true })
    async link(params?: any) {
      try {
        if (this.linkedLessonId > 0) {
          params.lessonAddendums = [{
            type: 'LINKEDLESSON',
            typeId: this.linkedLessonId
          }];
        } else if (this.lessonId > 0) {
          params.lessonAddendums = [{
            type: 'LINKEDLESSON',
            typeId: this.lessonId
          }];
        } else {
          Promise.resolve({});
        }
        const resp = await LessonServices.link(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            if (!data.confirmLinkAction) {
              await this.context.dispatch('plans/reloadPlans', {}, { root: true });
            }
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async linkTo(params: any) {
      try {
        params.lessonAddendums = params.lessonId.map((l: any) => {
          if (l !== 0) {
            return { type: 'LINKEDLESSON', typeId: l }
          } else {
            return { type: 'LINKEDLESSON' }
          }
        });
        const resp = await LessonServices.link(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async unlink(params?: any) {
      try {
        params = CommonUtils.getOrDefaultTo(params, {});
        params.lessonAddendums = [{
          extraLessonId: this.extraLesson,
          lessonDate: this.date,
          subjectId: this.classId
        }];
        const resp = await LessonServices.unlink(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            await this.context.dispatch('plans/reloadPlans', {}, { root: true });
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async importLessons(params: any) {
      try {
        const resp = await LessonServices.importLessons(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve({ resp });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async loadChangeHistory() {
      try {
        const resp = await ChangeHistoryServices.getLessonChangeHistory(this.lessonId);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            this.context.commit('setChangeHistory', data);
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }
    // ACTIONS: END
}
