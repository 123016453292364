import axios from '@/axios';
import CommonUtils from '@/utils/common-utils';
import qs from 'qs';

export interface SaveTeacherRequest {
  teacherType: string;
  teacherEmail: string;
  teacherFirst: string;
  teacherLast: string;
  teacherDisplayName: string;
  teacherId: number;
  inviteId: number;
  getAdmin: string;
  getInvited: string;
}

export interface RemoveTeacherRequest {
  teacherId: string;
  schoolId: number;
  inviteId: string;
  getAdmin: string;
  getInvited: string;
}

export interface RemoveTeachersRequest {
  teacherIds: Array<number>;
  inviteIds: Array<number>;
  getAdmin?: string;
  getInvited?: string;
}

export class TeacherServices {
  public static getTeachers(params?: any) {
    params = params || {};
    return axios.post('getTeachersAll', qs.stringify(params));
  }

  public static save(params?: SaveTeacherRequest) {
    params = params || <SaveTeacherRequest>{};
    return axios.post('updateTeacher', qs.stringify({ ...params, baseUrl: CommonUtils.getAppBaseUrl() }, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static inviteTeachers(params?: any) {
    params = params || {};
    return axios.post('services/planbook/school/inviteTeachers', qs.stringify({ ...params, inviteBaseUrl: CommonUtils.getAppBaseUrl() }));
  }

  public static findTeacherByEmails(params?: any) {
    params = params || {};
    return axios.post('services/planbook/teacher/findTeacherByEmails', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static updateGlobalFontStyle(params?: any): Promise<any> {
    params = params || {};
    return axios.post('services/planbook/teacher/updateGlobalFontStyle', qs.stringify(params));
  }

  public static connectToSchool(params?: any): Promise<any> {
    params = params || {};
    return axios.post('services/planbook/teacher/connectToSchool', qs.stringify(params));
  }
}

export default TeacherServices;
