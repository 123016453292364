import { BASE_URL } from '@/axios/index';
import { UserSession } from '@/common/user-session';
import { printItems, printToDoItems } from '@/constants/index';
import FileServices from '@/services/file-services';
import CommonUtils from '@/utils/common-utils';
import ld from 'lodash';
import moment from 'moment-timezone';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import CurrentUser from '@/common/current-user';

@Module({ namespaced: true })
export default class Prints extends VuexModule {
    exportType = 'pdf';
    dayOrWeekPrintView = 'Day';
    printView = 'Day';
    selectedPrintItems: any = null;
    selectedClassItems: any = null;
    selectedToDoItems: any = null;
    lessonBankId = '';
    startDate = '';
    endDate = '';
    todoStartDate = '';
    todoEndDate = '';
    commentsStartDate = '';
    commentsEndDate = '';
    notesClassId = '';
    notesStudentId = '';
    notesStartDate = '';
    notesEndDate = '';
    startTime = '';
    endTime = '';
    orientation = 'portrait';
    weekPages = '1';
    equalSize = false;
    avoidPageBreak = false;
    grayScale = false;
    oneLessonPerPage = false;
    twoColumns = false;
    initialized = false;
    encrypted = true;
    classId = '';
    extraLesson = '';
    skipClasses: Array<any> = [];

    // GETTERS: START
    get getPrintItems() {
      const getLessonSectionLabel = this.context.rootGetters['settings/getLessonSectionLabel'];
      const getOtherLessonLayoutLabels = this.context.rootGetters['settings/getOtherLessonLayoutLabels'];
      const items = ld.cloneDeep(printItems);
      for (const i in items) {
        const item = items[i];
        if (item.section) {
          item.text = getLessonSectionLabel(item.section);
          const otherTexts = getOtherLessonLayoutLabels(item.section);
          if (CommonUtils.isNotEmpty(otherTexts)) {
            (item as any).tooltip = otherTexts.join(', ');
          }
        }
      }
      return items;
    }

    get getAllPrintItemValues() {
      return this.getPrintItems.map(item => item.value);
    }

    get getPrintToDoItems() {
      return printToDoItems;
    }

    get getAllPrintToDoItemValues() {
      return this.getPrintToDoItems.map(item => item.value);
    }

    get createPrintRequest() {
      const userMode = this.context.rootState.settings.userMode;
      const studentId = this.context.rootState.settings.studentId;
      const studentKey = this.context.rootState.settings.studentKey;
      const studentTeacherId = this.context.rootState.settings.teacherId;
      const currentTeacherId = this.context.rootState.settings.currentTeacherId;
      const currentYearId = this.context.rootState.settings.currentYearId;
      let printTeacherId = 0;
      // let printYearId = 0;
      let printYearId = currentYearId;
      let printStudentKey = '';
      if (userMode === 'A') {
        printTeacherId = currentTeacherId;
        printYearId = currentYearId;
      } else if (userMode === 'S') {
        if (studentId > 0) {
          printTeacherId = 0;
          printStudentKey = '';
          printYearId = 0;
        } else {
          printStudentKey = studentKey;
          printTeacherId = studentTeacherId;
          printYearId = currentYearId;
        }
      }
      return (params?: any) => {
        params = params || {};
        let payload: any = {};
        const printView = this.printView;
        if (CommonUtils.hasText(params.printType)) {
          payload = {
            url: BASE_URL + '/createPDFinvoice',
            params: [
              { name: 'printId', value: params.printId || 0 },
              { name: 'printType', value: params.printType || 'I' },
              { name: 'printSchoolId', value: params.printSchoolId },
              { name: 'exportType', value: params.exportType },
              { name: 'quoteTeachers', value: params.quoteTeachers },
              { name: 'quoteMonths', value: params.quoteMonths },
              { name: 'authToken', value: params.authToken || UserSession.getAccessToken() },
              { name: 'X-PB-ACCESS-TOKEN', value: params.authToken || UserSession.getAccessToken() },
              { name: 'X-PB-CLIENT-YEAR-ID', value: UserSession.getCurrentYearId() || printYearId }
            ]
          };
        } else if (['Week', 'Day', 'Class', 'ClassMonth', 'LessonBank', 'Event', 'EventMonth'].includes(this.printView) || params.unitId > 0) {
          const printPath = userMode === 'S' ? '/services/print/studentPlans' : '/exportPlans';
          payload = {
            url: BASE_URL + printPath,
            exportType: this.exportType,
            params: [
              { name: 'exportType', value: this.exportType },
              { name: 'printView', value: this.printView },
              { name: 'printStartDate', value: CommonUtils.getOrDefaultTo(this.startDate, '01/01/0001') },
              { name: 'printEndDate', value: CommonUtils.getOrDefaultTo(this.endDate, '12/31/9999') },
              { name: 'printStartTime', value: CommonUtils.getOrDefaultTo(this.startTime, '') },
              { name: 'printEndTime', value: CommonUtils.getOrDefaultTo(this.endTime, '') },
              { name: 'printLayout', value: CommonUtils.getOrDefaultTo(this.orientation, 'portrait') },
              { name: 'weekPages', value: CommonUtils.getOrDefaultTo(this.weekPages, '1') },
              { name: 'printDayInTwoColumns', value: CommonUtils.booleanToString(this.twoColumns) },
              { name: 'printEqualSize', value: CommonUtils.booleanToString(this.equalSize) },
              { name: 'avoidPageBreakInside', value: CommonUtils.booleanToString(this.avoidPageBreak) },
              { name: 'printInGrayscale', value: CommonUtils.booleanToString(this.grayScale) },
              { name: 'printUserMode', value: userMode },
              { name: 'printClass', value: CommonUtils.getOrDefaultTo(this.classId ? this.classId : null, '0') },
              { name: 'printExtraLesson', value: CommonUtils.getOrDefaultTo(this.extraLesson, '0') },
              { name: 'printSkipClass', value: this.skipClasses.join(',') },
              { name: 'printLessonBank', value: CommonUtils.getOrDefaultTo(this.lessonBankId, '') },
              { name: 'printUnit', value: params.unitId || '0' },
              { name: 'printTeacherId', value: printTeacherId },
              { name: 'printStudentKey', value: printStudentKey },
              { name: 'printYearId', value: printYearId },
              { name: 'encrypted', value: this.encrypted },
              { name: 'authToken', value: params.authToken || UserSession.getAccessToken() },
              { name: 'gsAccessToken', value: UserSession.getGoogleAuthToken() },
              { name: 'printOneLessonPerPage', value: CommonUtils.booleanToString(this.oneLessonPerPage) },
              { name: 'X-PB-ACCESS-TOKEN', value: params.authToken || UserSession.getAccessToken() },
              { name: 'X-PB-CLIENT-YEAR-ID', value: UserSession.getCurrentYearId() || printYearId }
            ]
          };
          const printItems = this.context.getters.getPrintItems;
          for (const i in printItems) {
            if (this.selectedPrintItems.includes(printItems[i].value)) {
              payload.params.push({
                name: printItems[i].value,
                value: 'Y'
              })
            } else {
              payload.params.push({
                name: printItems[i].value,
                value: 'N'
              })
            }
          }
          for (const i in this.selectedClassItems) {
            payload.params.push({
              name: 'classesToPrint',
              value: this.selectedClassItems[i]
            });
          }
        } else if (printView === 'Comments') {
          const printPath = userMode === 'S' ? '/services/print/comments' : '/services/planbook/print/comments';
          payload = {
            url: BASE_URL + printPath,
            params: [
              { name: 'userMode', value: userMode },
              { name: 'teacherId', value: (userMode === 'T') ? currentTeacherId : printTeacherId },
              { name: 'startDate', value: CommonUtils.getOrDefaultTo(this.commentsStartDate, '') },
              { name: 'endDate', value: CommonUtils.getOrDefaultTo(this.commentsEndDate, '') },
              { name: 'exportType', value: this.exportType },
              { name: 'authToken', value: UserSession.getAccessToken() },
              { name: 'X-PB-ACCESS-TOKEN', value: UserSession.getAccessToken() },
              { name: 'X-PB-CLIENT-YEAR-ID', value: UserSession.getCurrentYearId() || printYearId }
            ]
          };
        } else if (printView === 'Notes') {
          payload = {
            url: BASE_URL + '/services/planbook/print/notes',
            params: [
              { name: 'userMode', value: userMode },
              { name: 'teacherId', value: this.context.rootState.settings.userId },
              { name: 'startDate', value: CommonUtils.getOrDefaultTo(this.notesStartDate, '') },
              { name: 'endDate', value: CommonUtils.getOrDefaultTo(this.notesEndDate, '') },
              { name: 'subjectId', value: CommonUtils.getOrDefaultTo(this.notesClassId, '') },
              { name: 'studentId', value: CommonUtils.getOrDefaultTo(this.notesStudentId, '') },
              { name: 'exportType', value: this.exportType },
              { name: 'authToken', value: UserSession.getAccessToken() },
              { name: 'X-PB-ACCESS-TOKEN', value: UserSession.getAccessToken() },
              { name: 'X-PB-CLIENT-YEAR-ID', value: UserSession.getCurrentYearId() || printYearId }
            ]
          };
        } else if (printView === 'ToDo') {
          payload = {
            url: BASE_URL + '/services/planbook/print/todos',
            params: [
              { name: 'userMode', value: userMode },
              { name: 'teacherId', value: this.context.rootState.settings.userId },
              { name: 'startDate', value: CommonUtils.getOrDefaultTo(this.todoStartDate, '') },
              { name: 'endDate', value: CommonUtils.getOrDefaultTo(this.todoEndDate, '') },
              { name: 'exportType', value: this.exportType },
              { name: 'authToken', value: UserSession.getAccessToken() },
              { name: 'X-PB-ACCESS-TOKEN', value: UserSession.getAccessToken() },
              { name: 'X-PB-CLIENT-YEAR-ID', value: UserSession.getCurrentYearId() || printYearId }
            ]
          };
          const printToDoItems = this.context.getters.getPrintToDoItems;
          for (const i in printToDoItems) {
            if (this.selectedToDoItems.includes(printToDoItems[i].value)) {
              payload.params.push({
                name: printToDoItems[i].checkedName,
                value: printToDoItems[i].checkedValue
              });
            }
          }
        }
        payload.params = payload.params || [];
        payload.params.push({ name: 'X-PB-MOMENT-TZ', value: moment.tz.guess() });
        return payload;
      };
    }
    // GETTERS: END

    // MUTATIONS: START
    @Mutation
    setExportType(exportType: string) {
      this.exportType = exportType;
    }

    @Mutation
    setPrintView(printView: string) {
      this.printView = printView;
      if (['Day', 'Week'].includes(printView)) {
        this.dayOrWeekPrintView = printView;
      }
    }

    @Mutation
    setDayOrWeekPrintView(dayOrWeekPrintView: string) {
      this.dayOrWeekPrintView = dayOrWeekPrintView;
    }

    @Mutation
    setSelectedPrintItems(selectedPrintItems: any) {
      this.selectedPrintItems = selectedPrintItems;
    }

    @Mutation
    setSelectedClassItems(selectedClassItems: any) {
      this.selectedClassItems = selectedClassItems;
    }

    @Mutation
    setSelectedToDoItems(selectedToDoItems: any) {
      this.selectedToDoItems = selectedToDoItems;
    }

    @Mutation
    setLessonBankId(lessonBankId: string) {
      this.lessonBankId = lessonBankId;
    }

    @Mutation
    setStartDate(startDate: string) {
      this.startDate = startDate;
    }

    @Mutation
    setEndDate(endDate: string) {
      this.endDate = endDate;
    }

    @Mutation
    setStartTime(startTime: string) {
      this.startTime = startTime;
    }

    @Mutation
    setEndTime(endTime: string) {
      this.endTime = endTime;
    }

    @Mutation
    setOrientation(orientation: string) {
      this.orientation = orientation;
    }

    @Mutation
    setWeekPages(weekPages: string) {
      this.weekPages = weekPages;
    }

    @Mutation
    setEqualSize(equalSize: boolean) {
      this.equalSize = equalSize;
    }

    @Mutation
    setAvoidPageBreak(avoidPageBreak: boolean) {
      this.avoidPageBreak = avoidPageBreak;
    }

    @Mutation
    setGrayScale(grayScale: boolean) {
      this.grayScale = grayScale;
    }

    @Mutation
    setOneLessonPerPage(oneLessonPerPage: boolean) {
      this.oneLessonPerPage = oneLessonPerPage;
    }

    @Mutation
    setTwoColumns(twoColumns: boolean) {
      this.twoColumns = twoColumns;
    }

    @Mutation
    setInitialized(initialized: boolean) {
      this.initialized = initialized;
    }

    @Mutation
    setTodoStartDate(todoStartDate: string) {
      this.todoStartDate = todoStartDate;
    }

    @Mutation
    setTodoEndDate(todoEndDate: string) {
      this.todoEndDate = todoEndDate;
    }

    @Mutation
    setCommentsStartDate(commentsStartDate: string) {
      this.commentsStartDate = commentsStartDate;
    }

    @Mutation
    setCommentsEndDate(commentsEndDate: string) {
      this.commentsEndDate = commentsEndDate;
    }

    @Mutation
    setNotesStartDate(notesStartDate: string) {
      this.notesStartDate = notesStartDate;
    }

    @Mutation
    setNotesEndDate(notesEndDate: string) {
      this.notesEndDate = notesEndDate;
    }

    @Mutation
    setNotesClassId(notesClassId: string) {
      this.notesClassId = notesClassId;
    }

    @Mutation
    setNotesStudentId(notesStudentId: string) {
      this.notesStudentId = notesStudentId;
    }

    @Mutation
    setClassId(classId: string) {
      this.classId = classId;
    }

    @Mutation
    setExtraLesson(extraLesson: string) {
      this.extraLesson = extraLesson;
    }

    @Mutation
    setSkipClasses(skipClasses: Array<any>) {
      this.skipClasses = skipClasses;
    }

    @Mutation
    setEncrypted(encrypted: boolean) {
      this.encrypted = encrypted;
    }

    @Mutation
    init(params: any) {
      if (this.selectedPrintItems == null) {
        this.selectedPrintItems = params.allPrintItemValues;
      }
      if (this.selectedToDoItems == null) {
        this.selectedToDoItems = params.allTodoItemValues;
      }
      if (this.selectedClassItems == null) {
        this.selectedClassItems = params.allClassItemValues;
      } else {
        const selected = [];
        for (const i in this.selectedClassItems) {
          if (params.allClassItemValues.includes(this.selectedClassItems[i])) {
            selected.push(this.selectedClassItems[i]);
          }
        }
        this.selectedClassItems = selected;
      }
      const isDayOrWeek = ['D', 'W', 'C'].includes(params.viewType);
      let printView = ''
      if (CommonUtils.hasText(params.printView)) {
        printView = params.printView;
      } else if (isDayOrWeek) {
        printView = this.dayOrWeekPrintView;
      } else if (params.viewType === 'M') {
        if (CommonUtils.isNotEmpty(store.getters['classes/getClassItems'])) {
          printView = 'ClassMonth';
        } else {
          printView = 'EventMonth';
        }
      } else if (params.viewType === 'L') {
        if (CommonUtils.isNotEmpty(store.getters['classes/getClassItems'])) {
          printView = 'Class';
        } else {
          printView = 'Event';
        }
      } else {
        printView = this.printView;
      }
      this.printView = printView;

      if (printView === 'ClassMonth') {
        this.startDate = params.monthDateRange.startDate;
        this.endDate = params.monthDateRange.endDate;
      } else {
        this.startDate = params.weekDateRange.startDate;
        this.endDate = params.weekDateRange.endDate;
      }

      this.todoStartDate = '';
      this.todoEndDate = '';
      this.notesStartDate = params.weekDateRange.startDate;
      this.notesEndDate = params.weekDateRange.endDate;
      this.notesClassId = this.notesClassId || '0';
      this.notesStudentId = this.notesStudentId || '0';
      this.commentsStartDate = '';
      this.commentsEndDate = '';
      this.startTime = '';
      this.endTime = '';
      this.skipClasses = params.skipClasses || [];
      this.extraLesson = '';
      this.classId = '';
      if (this.lessonBankId === '' && params.allLessonBankItemValues && params.allLessonBankItemValues.length > 0) {
        this.lessonBankId = params.allLessonBankItemValues[0];
      }
    }

    @Mutation
    setDayPrint(params: any) {
      this.classId = params.classId;
      this.extraLesson = CommonUtils.get(params.extraLesson, 0);
      this.startDate = params.startDate || params.lessonDate || params.date;
      this.endDate = params.endDate || params.lessonDate || params.date;
      this.printView = 'Day';
      this.startTime = params.startTime || '';
      this.endTime = params.endTime || '';
      this.skipClasses = params.classIds.filter((c: any) => c !== params.classId);
    }

    @Mutation
    setBankPrint(params: any) {
      this.classId = '';
      this.extraLesson = '';
      this.startDate = params.startDate || params.lessonDate || params.date;
      this.endDate = params.endDate || params.lessonDate || params.date;
      this.printView = 'LessonBank';
      this.startTime = '';
      this.endTime = '';
      this.skipClasses = [];
    }
    // MUTATIONS: END

    // ACTIONS: START
    @Action({ rawError: true })
    initPrint(params?: any) {
      this.context.commit('init', {
        allPrintItemValues: this.context.getters.getAllPrintItemValues,
        allTodoItemValues: this.context.getters.getAllPrintToDoItemValues,
        allClassItemValues: this.context.rootGetters['classes/getAllClassItemValues'],
        allLessonBankItemValues: this.context.rootGetters['classes/getAllLessonBankItemValues'],
        printView: params?.printView,
        viewType: this.context.rootState.plans.viewType,
        weekDateRange: this.context.rootGetters['plans/getWeekDateRange'],
        monthDateRange: this.context.rootGetters['plans/getMonthDateRange'],
        skipClasses: this.context.rootGetters['settings/getHideClassIds']
      });
      return Promise.resolve();
    }

    @Action({ rawError: true })
    async initDayPrint(params?: any) {
      return this.context.dispatch('initPrint').then(() => {
        this.context.commit('setDayPrint', params);
      });
    }

    @Action({ rawError: true })
    async initBankPrint(params?: any) {
      return this.context.dispatch('initPrint').then(() => {
        this.context.commit('setBankPrint', params);
        this.context.commit('setLessonBankId', this.context.rootGetters['lessonlists/getSelectedLessonBankClassId'])
      });
    }

    @Action({ rawError: true })
    async print(params?: any) {
      const request = this.context.getters.createPrintRequest(params);
      const formData = new URLSearchParams();
      request.params.forEach((p: any) => {
        formData.append(p.name, p.value);
      });
      return fetch(request.url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-PB-MOMENT-TZ': moment.tz.guess()
        },
        credentials: !(CurrentUser.isStudent && CurrentUser.id === 0) ? 'include' : 'omit',
        body: formData
      }).then(async (response) => {
        const type = response.headers.get('Content-Type') as string;
        const disposition = response.headers.get('Content-Disposition') as string;
        const fileName = FileServices.getFileName(disposition);
        if (type.includes('json')) {
          const data = await response.json();
          console.log(data.documentLink);
          window.open(data.documentLink, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes');
        } else {
          const blob = await response.blob();
          FileServices.downloadFile(blob, fileName, type);
        }
        return Promise.resolve();
      });
    }
    // ACTIONS: END
}
