import qs from 'qs';
import axios from '@/axios';

export default class StudentAssignmentServices {
  public static async removeStudentFromTeacher(params?: any) {
    params = params || {};
    return axios.post('deleteTeacherStudentServlet', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static async assignStudentToTeacher(params?: any) {
    params = params || {};
    return axios.post('addTeacherStudentServlet', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static async removeAllStudentsFromTeacher(params?: any) {
    params = params || {};
    return axios.post('deleteTeacherAllStudentsServlet', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static async assignAllStudentsToTeacher(params?: any) {
    params = params || {};
    return axios.post('addTeacherAllStudentsServlet', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static async getStudentTeacherAssignment(params?: any) {
    params = params || {};
    return axios.get('getStudentTeacherServlet', { params });
  }

  public static async getStudentSubjectAssignment(params?: any) {
    params = params || {};
    return axios.get('services/planbook/studentsubject', { params });
  }

  public static async assignStudentToClass(params?: any) {
    params = params || {};
    return axios.post('services/planbook/studentsubject/assignStudent', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static async removeStudentFromClass(params?: any) {
    params = params || {};
    return axios.post('services/planbook/studentsubject/removeStudent', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static async assignAllStudentsToClass(params?: any) {
    params = params || {};
    return axios.post('services/planbook/studentsubject/assignAllStudents', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static async removeAllStudentsFromClass(params?: any) {
    params = params || {};
    return axios.post('services/planbook/studentsubject/removeAllStudents', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }
}
