
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class LabeledButton extends Vue {
@Prop()
label!: string;

@Prop({ default: '', required: false })
id!: string;
}
