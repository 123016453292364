import AppError, { createErrOptions, SessionExpiredError } from '@/errors';
import SchoolProfileServices from '@/services/school-profile-services';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import ld from 'lodash';

const debug = process.env.NODE_ENV !== 'production';

@Module({ namespaced: true })
export default class SchoolProfile extends VuexModule {
    schoolProfile = SchoolProfile.schoolProfileDefault();

    private static schoolProfileDefault() {
      return {
        schoolId: 0,
        schoolName: '',
        schoolDistrict: '',
        schoolAddress: '',
        schoolCity: '',
        schoolState: '',
        contactName: '',
        contactEmail: '',
        schoolCountry: '',
        schoolZip: '',
        contactNumber: '',
        billingContact: '',
        billingEmail: '',
        billingAddress: '',
        billingCity: '',
        billingState: '',
        billingCountry: '',
        billingZipCode: '',
        billingPhoneNumber: '',
        billingFax: '',
        settings: { allowViewTeachers: false, plansDueDate: '1970-01-08 23:59:00', preventCreateStudents: false }
      }
    }

    get getDefaultSchoolProfile() {
      return SchoolProfile.schoolProfileDefault();
    }

    @Mutation
    resetSchoolProfile() {
      this.schoolProfile = SchoolProfile.schoolProfileDefault();
    }

    @Action({ rawError: true })
    async updateSchoolProfile(params?: any) {
      try {
        if (params) {
          const payload = ld.cloneDeep(params);
          delete payload.billingCountryObj
          delete payload.billingStateObj
          delete payload.schoolCountryObj
          delete payload.schoolStateObj
          payload.settings = JSON.stringify(payload.settings);
          const resp = await SchoolProfileServices.updateSchoolProfile(payload);
          if (resp) {
            const data = resp.data;
            if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
              console.log('UPDATE SCHOOLPROFILE EXPIRED');
              return Promise.reject(new SessionExpiredError());
            } else if (data.error === 'true' || data.error === true) {
              return Promise.reject(new AppError('contactSupport', { data }));
            } else {
              if (debug) {
                console.log('UPDATE SCHOOLPROFILE RESPONSE --> ' + JSON.stringify(data));
              }
              return Promise.resolve({ data });
            }
          } else {
            return Promise.resolve({ data: {} });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async updateSchoolSettings(params?: any) {
      try {
        const payload = { settings: JSON.stringify(params) };
        const resp = await SchoolProfileServices.updateSchoolSettings(payload);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            console.log('UPDATE SCHOOLPROFILESETTINGS EXPIRED');
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            if (debug) {
              console.log('UPDATE SCHOOLPROFILESETTINGS RESPONSE --> ' + JSON.stringify(data));
            }
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async updateDistrictSettings(params?: any) {
      try {
        const resp = await SchoolProfileServices.updateDistrictSettings(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            console.log('UPDATE DISTRICTPROFILESETTINGS EXPIRED');
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            if (debug) {
              console.log('UPDATE DISTRICTPROFILESETTINGS RESPONSE --> ' + JSON.stringify(data));
            }
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }
}
