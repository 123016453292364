import axios from '@/axios';

export default class ReferenceDataServices {
  public static async getCountriesStates() {
    const params = { apiKey: '2e6c6353-3891-4e8a-b579-f6ef074dea34' };
    return axios.get('services/api/referencedata/iSOCountriesStates', { params });
  }

  public static async getHolidays() {
    return axios.get('services/planbook/holidays');
  }
}
