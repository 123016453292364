var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        persistent: _vm.persistent,
        "no-click-animation": _vm.persistent,
        "max-width": _vm.maxWidth,
        fullscreen: _vm.isFullScreen,
        "content-class": "base-modal",
        "retain-focus": false
      },
      on: {
        "click:outside": function($event) {
          $event.preventDefault()
          $event.stopPropagation()
          return _vm.doClose(true)
        }
      },
      model: {
        value: _vm.localValue,
        callback: function($$v) {
          _vm.localValue = $$v
        },
        expression: "localValue"
      }
    },
    [
      _c(
        "v-card",
        {
          ref: "dialogCard",
          staticClass: "base-modal-card",
          class: _vm.cardClass
        },
        [
          _c(
            "v-card-title",
            {
              ref: "toolbar",
              staticClass: "px-0 pt-0",
              staticStyle: { cursor: "move" },
              on: { mousedown: _vm.mouseDown }
            },
            [
              _c(
                "v-toolbar",
                {
                  attrs: { color: "primary", dark: "" },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k(
                          $event.keyCode,
                          "escape",
                          undefined,
                          $event.key,
                          undefined
                        )
                      ) {
                        return null
                      }
                      return _vm.doClose()
                    }
                  }
                },
                [
                  _c(
                    "pb-btn",
                    {
                      staticClass: "ml-1",
                      attrs: {
                        icon: "",
                        right: "",
                        label: _vm.$t("closeLabel")
                      },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.doClose()
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("fal fa-times")])],
                    1
                  ),
                  _c("v-spacer"),
                  _c("div", [_vm._t("header", null, null, _vm.input)], 2),
                  _c("v-spacer"),
                  _vm._t(
                    "actions",
                    function() {
                      return [
                        _vm.isExpandable
                          ? _c(
                              "pb-btn",
                              {
                                attrs: {
                                  icon: "",
                                  right: "",
                                  label: _vm.$t("fullscreenLabel")
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return _vm.toggleFullScreen.apply(
                                      null,
                                      arguments
                                    )
                                  }
                                }
                              },
                              [
                                _vm.fullScreen
                                  ? _c("v-icon", [
                                      _vm._v("fal fa-compress-wide")
                                    ])
                                  : _c("v-icon", [_vm._v("fal fa-expand-wide")])
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._t(
                          "action-button",
                          function() {
                            return [
                              _vm.withActions
                                ? _c(
                                    "pb-btn",
                                    {
                                      attrs: {
                                        icon: "",
                                        right: "",
                                        label: _vm.$t("actionsLabel")
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          !_vm.applying &&
                                            _vm.$emit("actions", $event)
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v("fal fa-ellipsis-v-alt")
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          },
                          null,
                          _vm.input
                        ),
                        _vm.withApply
                          ? _c(
                              "pb-btn",
                              {
                                attrs: {
                                  icon: "",
                                  right: "",
                                  label: _vm.$t("saveLabel")
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return _vm.doApply.apply(null, arguments)
                                  }
                                }
                              },
                              [
                                _vm.applying
                                  ? _c("v-icon", [
                                      _vm._v("fal fa-sync fa-spin")
                                    ])
                                  : _c("v-icon", [_vm._v("fal fa-check")])
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("div", { staticClass: "mr-1" })
                      ]
                    },
                    null,
                    _vm.input
                  )
                ],
                2
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            {
              staticClass: "px-0 py-0",
              style: {
                "overflow-x": "hidden",
                "max-height": _vm.maxContentHeight
              }
            },
            [
              _c(
                "div",
                { staticClass: "dialog-content fill-height" },
                [_vm._t("default", null, null, _vm.input)],
                2
              )
            ]
          ),
          _c("div", { ref: "bottombar" }, [_vm._t("bottom-actions")], 2)
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }