var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isGenericView
    ? _c(
        "div",
        {
          staticClass: "account-menu",
          on: {
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k(
                  $event.keyCode,
                  "escape",
                  undefined,
                  $event.key,
                  undefined
                )
              ) {
                return null
              }
              return _vm.closeMenu.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "div",
            { ref: "userCard", staticStyle: { height: "fit-content" } },
            [
              _c(
                "v-card",
                { staticClass: "ma-0 pa-0", attrs: { width: "290" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "ma-0 pa-0 justify-center" },
                    [
                      _c(
                        "v-list",
                        { staticClass: "ma-0 pa-2 text-center" },
                        [
                          _c(
                            "v-list-item",
                            { staticClass: "px-2 pt-5" },
                            [
                              _c(
                                "v-list-item-avatar",
                                {
                                  staticClass: "mx-auto",
                                  attrs: { size: "46" }
                                },
                                [
                                  _vm.hasProfilePhoto
                                    ? _c("img", {
                                        staticStyle: { "object-fit": "cover" },
                                        attrs: {
                                          src: _vm.profilePhotoUrl,
                                          alt: _vm.localAccountName
                                        }
                                      })
                                    : _c("v-icon", { attrs: { size: "46" } }, [
                                        _vm._v(" fas fa-user-circle ")
                                      ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            { staticClass: "pl-0 pr-0" },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-list-item-title",
                                    {
                                      staticClass: "title",
                                      staticStyle: {
                                        "white-space": "normal",
                                        "word-break": "break-word"
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.localAccountName))]
                                  ),
                                  _c("v-list-item-subtitle", {
                                    staticStyle: {
                                      "white-space": "normal",
                                      "word-break": "break-word"
                                    },
                                    domProps: {
                                      innerHTML: _vm._s(_vm.localEmailAddress)
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          !_vm.isGenericView
                            ? _c(
                                "v-list-item",
                                {
                                  ref: "signOut",
                                  attrs: { dense: "", to: "/signOut" }
                                },
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c(
                                        "v-list-item-title",
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "list-item-icon",
                                              attrs: { color: "info lighten-1" }
                                            },
                                            [_vm._v(" fal fa-sign-out-alt ")]
                                          ),
                                          _c("span", { staticClass: "ml-1" }, [
                                            _vm._v(
                                              _vm._s(_vm.$t("signOutLabel"))
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "perfect-scrollbar",
            { style: "height: calc(100vh - " + _vm.userCardHeight + "px)" },
            [
              _c(
                "v-card",
                { staticClass: "ma-0 pa-0", attrs: { width: "290" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-list",
                        { attrs: { nav: "", dense: "" } },
                        [
                          _vm.userMode !== "S"
                            ? _c(
                                "v-list-item-subtitle",
                                { staticClass: "mt-1" },
                                [_vm._v(_vm._s(_vm.$t("settingsLabel")))]
                              )
                            : _vm._e(),
                          _vm.userMode !== "S"
                            ? _c("v-divider", { staticClass: "my-3" })
                            : _vm._e(),
                          _c(
                            "v-list-item-group",
                            {
                              ref: "gotoItems",
                              attrs: { "aria-label": _vm.$t("settingsLabel") }
                            },
                            _vm._l(_vm.settingsItems, function(item, i) {
                              return _c(
                                "v-list-item",
                                {
                                  key: i,
                                  ref: "menuItem" + i,
                                  refInFor: true,
                                  attrs: {
                                    to: item.to,
                                    dense: "",
                                    id: _vm.getId(item)
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c("v-icon", {
                                        staticClass: "list-item-icon",
                                        attrs: { color: item.color },
                                        domProps: {
                                          textContent: _vm._s(item.icon)
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", {
                                        domProps: {
                                          textContent: _vm._s(item.text)
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          ),
                          _c("v-list-item-subtitle", { staticClass: "mt-5" }, [
                            _vm._v(_vm._s(_vm.$t("helpLabel")))
                          ]),
                          _c("v-divider", { staticClass: "my-3" }),
                          _c(
                            "v-list-item-group",
                            {
                              staticClass: "help-items",
                              attrs: { "aria-label": _vm.$t("helpLabel") }
                            },
                            _vm._l(_vm.helpItems, function(item, i) {
                              return _c(
                                "v-list-item",
                                {
                                  key: i,
                                  ref:
                                    "menuItem" + (_vm.settingsItems.length + i),
                                  refInFor: true,
                                  attrs: {
                                    id: item.id,
                                    to: item.to,
                                    href: item.href,
                                    target: item.target,
                                    dense: ""
                                  },
                                  on: { click: item.click }
                                },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c("v-icon", {
                                        staticClass: "list-item-icon",
                                        attrs: { color: item.color },
                                        domProps: {
                                          textContent: _vm._s(item.icon)
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", {
                                        domProps: {
                                          textContent: _vm._s(item.text)
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          ),
                          _c("v-list-item-subtitle", { staticClass: "mt-5" })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }