



























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import i18n from '@/plugins/i18n';
import ld from 'lodash';
import CommonUtils from '@/utils/common-utils';
import { namespace } from 'vuex-class';

const index = namespace('index');

@Component
export default class EditorSection extends Vue {
    @Prop({ required: true })
    label!: string;

    @Prop({ type: Boolean, required: false, default: false })
    clearable!: boolean;

    @Prop({ type: Boolean, required: false, default: false })
    disabled!: boolean;

    @Prop({ required: false, type: Boolean, default: false })
    noAccess!: boolean;

    @Prop({ required: false, type: Boolean, default: false })
    readonly!: boolean;

    @Prop({ required: false, type: Boolean, default: false })
    stackMode!: boolean;

    @Prop({ required: false, default: () => { return {} } })
    config?: any;

    @Prop({
      required: false,
      type: String,
      default:
        `<div class="text-center" style="color: #b5bac1;">
            <i style="font-size: 60px;" class="fas fa-user-lock"></i>
            <div>${i18n.t('noPermissionMsg')}</div>
        </div>`
    })
    noAccessText!: string;

    @Prop({ required: false, type: String, default: CommonUtils.generateUUID() })
    refreshKey!: string;

    $refs!: {
        editorSection: HTMLElement
    }

    @index.State
    fullScreen!: boolean;

    updatedConfig: any = {};
    localRefreshKey: string = CommonUtils.generateUUID();

    get localConfig() {
      return ld.merge(this.baseConfig, this.updatedConfig);
    }

    get baseConfig() {
      return {
        height: '370px'
      }
    }

    get baseHeight() {
      return '370px';
    }

    @Watch('fullScreen')
    adjustHeight() {
      this.$nextTick(() => {
        new Promise(resolve => setTimeout(resolve, 1)).then(() => {
          const updatedConfig = ld.cloneDeep(this.updatedConfig);
          if (this.fullScreen && !this.stackMode) {
            if (this.config && this.config.minHeight && window.innerHeight - this.$refs.editorSection.getBoundingClientRect().top - 28 < this.updatedConfig.minHeight) {
              updatedConfig.height = this.updatedConfig.minHeight + 'px';
            } else {
              updatedConfig.height = 'calc(100vh - ' + (this.$refs.editorSection.getBoundingClientRect().top + 28) + 'px)';
            }
          } else {
            CommonUtils.hasValue(this.config?.height) ? updatedConfig.height = this.config?.height : updatedConfig.height = this.baseHeight;
          }
          this.updatedConfig = updatedConfig;
          this.localRefreshKey = CommonUtils.generateUUID();
        });
      })
    }

    mounted() {
      this.adjustHeight();
      if (this.updatedConfig.height === 'fill') {
        if (this.updatedConfig.minHeight && window.innerHeight - this.$refs.editorSection.getBoundingClientRect().top < this.updatedConfig.minHeight) {
          this.updatedConfig.height = this.updatedConfig.minHeight + 'px';
        } else {
          this.updatedConfig.height = 'calc(100vh - ' + (this.$refs.editorSection.getBoundingClientRect().top + 20) + 'px)';
        }
      }
    }

    created() {
      this.updatedConfig = this.config;
      this.localRefreshKey = this.refreshKey;
    }
}
