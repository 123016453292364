var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      ref: _vm.localRef,
      attrs: {
        "offset-y": "",
        transition: "scale-transition",
        "min-width": "auto",
        "close-on-content-click": false,
        origin: _vm.origin,
        disabled: _vm.disabled || _vm.readonly,
        "nudge-left": _vm.shift + "px",
        "offset-overflow": ""
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "validation-provider",
                { attrs: { name: "dateField", rules: "validDate" } },
                [
                  _c("v-text-field", {
                    ref: "textField",
                    attrs: {
                      label: _vm.label,
                      "aria-label": _vm.ariaLabel || _vm.label,
                      "hide-details": _vm.hideDetails,
                      rules: _vm.localRules,
                      messages: _vm.messages,
                      "error-messages": _vm.localErrorMessage,
                      dense: _vm.dense,
                      disabled: _vm.disabled,
                      outlined: "",
                      readonly: _vm.readonly,
                      clearable: _vm.clearable,
                      "aria-required": _vm.required
                    },
                    on: {
                      click: _vm.handleClick,
                      keyup: function($event) {
                        _vm.localMenu = false
                      },
                      blur: _vm.handleOnBlur
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "prepend-inner",
                          fn: function() {
                            return [
                              _vm.prependInnerIcon &&
                              _vm.prependInnerIcon !== ""
                                ? _c(
                                    "v-icon",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "focus-icon-border",
                                          attrs: {
                                            "aria-label":
                                              _vm.$t("selectLabel") +
                                              " " +
                                              _vm.label
                                          }
                                        },
                                        "v-icon",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [_vm._v(_vm._s(_vm.prependInnerIcon))]
                                  )
                                : _c(
                                    "v-icon",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "focus-icon-border",
                                          attrs: {
                                            "aria-label":
                                              _vm.$t("selectLabel") +
                                              " " +
                                              _vm.label
                                          }
                                        },
                                        "v-icon",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [_vm._v("fal fa-calendar-day")]
                                  )
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "append-outer",
                          fn: function() {
                            return [
                              _vm.hasAppendOuterIcon
                                ? [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "focus-icon-border",
                                        attrs: {
                                          "aria-label": _vm.appendOuterLabel
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.$emit(
                                              "click:append-outer"
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.appendOuterIcon))]
                                    )
                                  ]
                                : _vm._e()
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: _vm.localValueDisplay,
                      callback: function($$v) {
                        _vm.localValueDisplay = $$v
                      },
                      expression: "localValueDisplay"
                    }
                  })
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.localMenu,
        callback: function($$v) {
          _vm.localMenu = $$v
        },
        expression: "localMenu"
      }
    },
    [
      _c("v-date-picker", {
        ref: "datePicker",
        attrs: { locale: _vm.getGlobalLanguage },
        on: {
          input: function($event) {
            _vm.localMenu = false
          }
        },
        model: {
          value: _vm.localPickerValue,
          callback: function($$v) {
            _vm.localPickerValue = $$v
          },
          expression: "localPickerValue"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }