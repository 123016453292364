import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from './i18n'
import '@mdi/font/css/materialdesignicons.css'
import '@fortawesome/fontawesome-pro/css/all.min.css'

const Ripple = require('vuetify/lib/directives/ripple');

function isInIFrame() {
  let isInIframe = false;
  try {
    isInIframe = window.self !== window.top;
  } catch (e) {
    isInIframe = true;
  }
  return isInIframe;
}

Vue.use(Vuetify, {
  directives: {
    Ripple
  }
})

const light = {
  primary: '#0057c1',
  secondary: '#333333',
  accent1: '#1d96c0',
  accent2: '#603f99',
  accent3: '#3e985c',
  accent4: '#f29a25',
  error: '#d81632',
  warning: '#f29a25',
  info: '#757575',
  success: '#3e985c',
  fg: '#000000',
  bg: '#ffffff'
}

const dark = {
  primary: '#0057c1',
  secondary: '#333333',
  accent1: '#1d96c0',
  accent2: '#603f99',
  accent3: '#3e985c',
  accent4: '#f29a25',
  error: '#d81632',
  warning: '#f29a25',
  info: '#757575',
  success: '#3e985c',
  fg: '#ffffff',
  bg: '#000000'
}

const thresholds = isInIFrame()
  ? {
    xs: 0,
    sm: 0,
    md: 0,
    lg: 0
  } : {
    xs: 600,
    sm: 960,
    md: 1280,
    lg: 1920
  };

const oVuetify = new Vuetify({
  breakpoint: {
    mobileBreakpoint: 'sm',
    thresholds
  },
  lang: {
    t: (key, ...params): string => {
      return i18n.t(key, params) as string
    }
  },
  theme: {
    themes: {
      dark,
      light
    },
    options: {
      customProperties: true
    }
  },
  icons: {
    iconfont: 'fa' || 'mdi',
    values: {
      dropdown: 'mdi-chevron-down',
      sort: 'fal fa-arrow-down'
    }
  }
});

export default oVuetify;
