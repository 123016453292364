import Vue from 'vue';
import Vuex from 'vuex';
import plans from './modules/plans';
import classes from './modules/classes';
import settings from './modules/settings';
import files from './modules/files';
import firebase from './modules/firebase';
import units from './modules/units';
import teachers from './modules/teachers';
import events from './modules/events';
import referencedata from './modules/referencedata';
import standards from './modules/standards';
import lessons from './modules/lessons';
import notestodo from './modules/notestodo';
import students from './modules/students';
import studenttags from './modules/studenttags';
import drawer from './modules/drawer';
import google from './modules/google';
import grades from './modules/grades'
import lessonactions from './modules/lessonactions';
import prints from './modules/prints';
import lessonlayouts from './modules/lessonlayouts';
import messages from './modules/messages';
import mylists from './modules/mylist';
import mystrategies from './modules/mystrategies';
import classwork from './modules/classwork';
import templates from './modules/templates';
import schoolprofile from './modules/schoolprofile';
import integration from './modules/integration';
import seatingcharts from './modules/seatingcharts';
import email from './modules/email';
import studentassignment from './modules/studentassignment'
import attendance from './modules/attendance';
import lessonlists from './modules/lesson-lists';
import dayactions from './modules/dayactions';
import reporting from './modules/reporting';
import copy from './modules/copy';
import reminders from './modules/reminders';
import permissions from './modules/permissions';
import stickers from './modules/stickers';
import changehistory from './modules/changehistory';
import schoolstatus from './modules/schoolstatus';
import welcome from './modules/welcome';
import local from './modules/local';
import index from './modules/index';
import classlink from './modules/classlink';

import VuexPersistence from 'vuex-persist';

declare const window: Window;

const sessionStore = new VuexPersistence({
  storage: window.sessionStorage,
  modules: ['welcome'],
  key: 'planbook-session'
});

const localStore = new VuexPersistence({
  storage: window.localStorage,
  key: 'planbook-local',
  reducer: (state:any) => ({
    local: state.local,
    prints: state.prints,
    grades: state.grades,
    integration: state.integration,
    classlink: {
      orgsMappingArray: state.classlink.orgsMappingArray,
      selectedOrgs: state.classlink.selectedOrgs,
      setupStepCount: state.classlink.setupStepCount,
      setupWizard: state.classlink.setupWizard,
      setUpEndPoint: state.classlink.setUpEndPoint,
      setUpAccessKey: state.classlink.setUpAccessKey,
      setUpSecretKey: state.classlink.setUpSecretKey,
      setUpSyncTime: state.classlink.setUpSyncTime
    }
  })
});

Vue.use(Vuex);

const store = new Vuex.Store<any>({
  plugins: [sessionStore.plugin, localStore.plugin],
  modules: {
    settings,
    plans,
    classes,
    classwork,
    templates,
    files,
    firebase,
    units,
    teachers,
    events,
    welcome,
    referencedata,
    standards,
    lessons,
    notestodo,
    students,
    studenttags,
    drawer,
    google,
    grades,
    lessonactions,
    prints,
    lessonlayouts,
    messages,
    mylists,
    mystrategies,
    schoolprofile,
    integration,
    seatingcharts,
    email,
    studentassignment,
    attendance,
    lessonlists,
    dayactions,
    reporting,
    copy,
    permissions,
    reminders,
    stickers,
    schoolstatus,
    changehistory,
    index,
    local,
    classlink
  },
  state: {
    loading: true,
    currentPageInstance: {},
    currentPage: '',
    confirm: {}
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setCurrentPageInstance(state, currentPageInstance: Vue) {
      state.currentPageInstance = currentPageInstance;
    },
    setCurrentPage(state, currentPage: string) {
      state.currentPage = currentPage;
    },
    setRedirect(state, redirect: string) {
      state.redirect = redirect;
    },
    setConfirm(state, confirm: any) {
      state.confirm = confirm;
    }
  },
  actions: {
  }
});

export default store;
