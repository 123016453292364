import axios from '@/axios';
import { UserSession } from '@/common/user-session';
import { imageFileExtensions } from '@/constants';
import CommonUtils from '@/utils/common-utils';
import { AxiosRequestConfig } from 'axios';
import ld from 'lodash';
import qs from 'qs';
import { Deferred } from './../common/deferred';

export default class FileServices {
  public static getMyFiles(params?: any) {
    params = params || {};
    return axios.post('getAttachmentList', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static async getFile(key: string) {
    return await axios.get('getS3Credentials', {
      params: {
        createSignedGetURL: true,
        fileName: key
      }
    });
  }

  public static async uploadFiles(files: Array<File>, uploadProgressCallback?: (file: File, percent?: number, event?: any) => void) {
    const deferred = new Deferred();
    let completed = 0;
    files.forEach(file => {
      let config;
      if (uploadProgressCallback) {
        config = {
          onUploadProgress: (event: any) => {
            const percentCompleted = Math.round((event.loaded * 100) / event.total);
            uploadProgressCallback(file, percentCompleted, event);
            if (percentCompleted === 100) completed++;
            if (completed === files.length) {
              deferred.resolve();
            }
          }
        }
      } else {
        config = {
          onUploadProgress: (event: any) => {
            const percentCompleted = Math.round((event.loaded * 100) / event.total);
            if (percentCompleted === 100) completed++;
            if (completed === files.length) {
              deferred.resolve();
            }
            console.log(`${file.name}: ${percentCompleted}% complete.`);
          }
        }
      }
      FileServices.uploadFile(file, file.name, config);
    });
    return deferred.promise;
  }

  public static async uploadFile(file: File, customFileName?: string, customConfig?: AxiosRequestConfig) {
    const resp = await axios.get('getS3Credentials', {
      params: {
        fileName: customFileName || file.name
      }
    });
    const data = resp.data;
    const params = data.params;
    const url = data.url;
    const formData = new FormData();
    Object.keys(params).forEach(key => formData.append(key, params[key]));
    formData.append('file', file);
    const baseConfig: AxiosRequestConfig = {
      onUploadProgress: (event: any) => {
        const percentCompleted = Math.round((event.loaded * 100) / event.total)
        console.log(`${data.fileName || customFileName || file.name}: ${percentCompleted}% complete.`);
      },
      withCredentials: false,
      transformRequest: (data, headers) => {
        delete headers.Authorization;
        return data;
      }
    };
    await axios.post(url, formData, ld.merge(baseConfig, customConfig || {}));
    return FileServices.getFile(data.fileName || customFileName || file.name);
  }

  public static async uploadFilesNotRoot(files: Array<File>, folderName:string, uploadProgressCallback?: (file: File, percent?: number, event?: any) => void) {
    const deferred = new Deferred();
    let completed = 0;
    console.log(folderName);
    files.forEach(file => {
      let config;
      if (uploadProgressCallback) {
        config = {
          onUploadProgress: (event: any) => {
            const percentCompleted = Math.round((event.loaded * 100) / event.total);
            uploadProgressCallback(file, percentCompleted, event);
            if (percentCompleted === 100) completed++;
            if (completed === files.length) {
              deferred.resolve();
            }
          }
        }
      } else {
        config = {
          onUploadProgress: (event: any) => {
            const percentCompleted = Math.round((event.loaded * 100) / event.total);
            if (percentCompleted === 100) completed++;
            if (completed === files.length) {
              deferred.resolve();
            }
            console.log(`${file.name}: ${percentCompleted}% complete.`);
          }
        }
      }
      FileServices.uploadFileWithFolder(file, folderName, file.name, config);
    });
    return deferred.promise;
  }

  public static async uploadFileWithFolder(file: File, folder: string, customFileName?: string, customConfig?: AxiosRequestConfig) {
    const resp = await axios.post('services/planbook/files/generateSignedUploadUrl', qs.stringify({
      fileName: customFileName || file.name,
      folder
    }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
    const data = resp.data;
    const params = data.params;
    const url = data.url;
    const formData = new FormData();
    Object.keys(params).forEach(key => formData.append(key, params[key]));
    formData.append('file', file);
    const baseConfig: AxiosRequestConfig = {
      onUploadProgress: (event: any) => {
        const percentCompleted = Math.round((event.loaded * 100) / event.total)
        console.log(`${data.fileName || customFileName || file.name}: ${percentCompleted}% complete.`);
      },
      withCredentials: false,
      transformRequest: (data, headers) => {
        delete headers.Authorization;
        return data;
      }
    };
    await axios.post(url, formData, ld.merge(baseConfig, customConfig || {}));
    return FileServices.getFile(data.fileName || customFileName || file.name);
  }

  public static async createFolder(folder: string) {
    const params: any = {};
    params.folder = folder;
    return axios.post('services/planbook/files/createFolder', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static async editFile(key: string, oldFile: string) {
    const currentUser: any = UserSession.getCurrentUser() || {};
    const params: any = {};
    params.teacherId = currentUser.teacherId;
    params.newFileName = key;
    params.oldFileName = oldFile;
    params.resources = 'resources';

    return axios.post('updateAttachment', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static guessGoogleDriveId(url: string) {
    if (url) {
      let id = url;
      id = id.substring(id.indexOf('/d/') + 3);
      id = id.substring(0, id.indexOf('/'));
      return id;
    }
    return '';
  }

  public static isGoogleDriveFile(url: string) {
    return url && url.includes('google.com/');
  }

  public static async editFiles(key: Array<string>, oldFile: Array<string>) {
    const currentUser: any = UserSession.getCurrentUser() || {};
    const filesToEditArray = [];
    for (let i = 0; i < key.length; i++) {
      filesToEditArray.push({
        newFileName: key[i],
        oldFileName: oldFile[i]
      })
    }
    const deletePromises = filesToEditArray.map(file => {
      const params: any = {};
      params.teacherId = currentUser.teacherId;
      params.newFileName = file.newFileName;
      params.oldFileName = file.oldFileName;
      params.resources = 'resources';
      return axios.post('updateAttachment', qs.stringify(params), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
    });
    return Promise.all(deletePromises);
  }

  public static async deleteMultipleFiles(files: Array<any>) {
    const deletePromises = files.map(file => {
      const params: any = {};
      params.fileName = file.key;
      return axios.post('services/planbook/files/deleteMultipleFile', qs.stringify(params), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
    });
    return Promise.all(deletePromises);
  }

  public static async deleteSingleFile(file: string) {
    const params: any = {};
    params.fileName = file;
    return axios.post('services/planbook/files/deleteMultipleFile', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static async readTextFile(file: File): Promise<string> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsText(file, 'UTF-8');
      reader.onload = (evt) => {
        const data = evt.target?.result as string;
        resolve(data);
      }
    })
  }

  public static downloadFile(data: Blob | any, name: string = CommonUtils.generateUUID(), type: string) {
    let blob: Blob;
    if (data instanceof Blob) {
      blob = data;
    } else {
      blob = new Blob([data], { type });
    }
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = name;
    document.body.appendChild(link);
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );
    document.body.removeChild(link);
    URL.revokeObjectURL(blobUrl);
  }

  public static getFileName(disposition: string) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    let fileName = CommonUtils.generateUUID();
    if (matches != null && matches[1]) {
      fileName = matches[1].replace(/['"]/g, '');
    }
    return fileName;
  }

  public static getFileNameFromFile(file: File): string {
    let fileName: any;
    if (file) {
      fileName = file.name;
      if (CommonUtils.hasNoText(fileName)) {
        fileName = CommonUtils.generateUUID() + (imageFileExtensions[file.type] || '');
      }
    }
    if (CommonUtils.hasText(fileName)) {
      let name = fileName.substring(0, fileName.lastIndexOf('.'));
      const ext = fileName.substring(fileName.lastIndexOf('.'));
      if (name === 'image') {
        name += CommonUtils.generateUUID();
      }
      fileName = name + ext;
    }
    return fileName;
  }
}
