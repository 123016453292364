import AppError, { createErrOptions, SessionExpiredError } from '@/errors';
import LessonServices from '@/services/lesson-services';
import ReportingServices from '@/services/reporting-services';
import store from '@/store';
import CommonUtils from '@/utils/common-utils';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { UserSession } from '../../common/user-session';
import Vue from 'vue';
import ld from 'lodash';

@Module({ namespaced: true })
export default class Reporting extends VuexModule {
  reportGrade = 0;
  reportState = '';
  reportSubject = '';
  reportCategory = '';
  reportClassId = 0;
  reportStart = '';
  reportEnd = '';
  teacherId = 0;
  className = '';
  subjectName = '';
  stateName = '';
  fetchStandardsReport = 'initial';
  standardsReport: any = {};
  adminStandardsReport: any = {};
  standardLessons: any = { searchList: [] };
  showStandardLessons = false;
  showStandardTeachers = true;

  // toolbar attributes
  searchText = '';
  teacherSearchText = '';
  lessonListSearchText = '';

  get categories() {
    return this.standardsReport.categories || [];
  }

  get standards() {
    return this.standardsReport.standards || [];
  }

  get subjects() {
    return this.standardsReport.subjects || [];
  }

  get adminSubjects() {
    return this.adminStandardsReport.cSubjects || [];
  }

  get adminTeachers() {
    return this.adminStandardsReport.teachers || [];
  }

  get userMode() {
    return this.context.rootGetters['settings/getUserMode'];
  }

  get createRequest(): any {
    return {
      reportGrade: this.reportGrade,
      reportState: this.reportState,
      reportSubject: this.reportSubject,
      reportCategory: this.reportCategory,
      reportClassId: this.reportClassId,
      reportStart: this.reportStart,
      reportEnd: this.reportEnd,
      teacherId: this.teacherId,
      userMode: this.userMode,
      requestId: this.fetchStandardsReport,
      className: this.className,
      subjectName: this.subjectName,
      stateName: this.stateName
    }
  }

  get standardLessonList() {
    const classLessons = {
      objects: this.standardLessons.searchList.map((lesson:any) => {
        lesson.type = 'L';
        lesson.dayOfWeek = lesson.searchDayOfWeek;
        lesson.date = lesson.searchDate;
        lesson.yearName = lesson.searchYear;
        lesson.yearId = lesson.searchYearId;
        lesson.className = lesson.searchClassName;
        lesson.color = lesson.searchColor;
        return lesson;
      })
    }
    const settings = store.getters['plans/getPlanSettings'];
    const userMode = store.getters['settings/getUserMode'];
    let dataObjects = LessonServices.createDay(classLessons, 'search', {
      settings: settings,
      lessonSections: LessonServices.createLessonSections(settings),
      eventSections: LessonServices.createEventSections(settings),
      googleClassSections: LessonServices.createGoogleClassroomSections(),
      lessonHeadings: settings.displaySettings.lessonHeadings,
      statuses: settings.accountSettings.statuses,
      eventStyling: settings.displaySettings.eventStyling,
      otherSettings: settings.otherSettings,
      userMode: userMode,
      sharedClasses: settings.sharingOptionsSettings.sharedClasses,
      viewSettings: settings.viewSettings
    }).dayObjects || [];
    dataObjects = dataObjects.map((dayObject: any) => {
      dayObject.isExpanded = true;
      dayObject.dayOfWeek = dayObject.rawDayObject.dayOfWeek;
      dayObject.yearName = dayObject.rawDayObject.yearName;
      dayObject.yearId = dayObject.rawDayObject.yearId;
      dayObject.className = dayObject.rawDayObject.className;
      dayObject.sections.forEach((section:any) => {
        section.hide = 'N';
      });
      dayObject.search = JSON.stringify(dayObject);
      return dayObject;
    });
    return dataObjects;
  }

  get printRequest(): Array<any> {
    return Object.keys(this.createRequest).map(key => {
      return {
        name: key,
        value: this.createRequest[key]
      };
    }).concat({
      name: 'X-PB-ACCESS-TOKEN',
      value: UserSession.getAccessToken()
    }).concat({
      name: 'X-PB-CLIENT-YEAR-ID',
      value: UserSession.getCurrentYearId()
    });
  }

  @Mutation
  setTeacherSearchText(teacherSearchText: string) {
    this.teacherSearchText = teacherSearchText;
  }

  @Mutation
  setLessonListSearchText(lessonListSearchText: string) {
    this.lessonListSearchText = lessonListSearchText;
  }

  @Mutation
  setSearchText(searchText: string) {
    this.searchText = searchText;
  }

  @Mutation
  setReportGrade(reportGrade: number) {
    this.reportGrade = reportGrade;
  }

  @Mutation
  setReportState(reportState: string) {
    this.reportState = reportState;
  }

  @Mutation
  setReportSubject(reportSubject: string) {
    this.reportSubject = reportSubject;
  }

  @Mutation
  setReportCategory(reportCategory: string) {
    this.reportCategory = reportCategory;
  }

  @Mutation
  setReportClassId(reportClassId: number) {
    this.reportClassId = reportClassId;
  }

  @Mutation
  setReportStart(reportStart: string) {
    this.reportStart = reportStart;
  }

  @Mutation
  setReportEnd(reportEnd: string) {
    this.reportEnd = reportEnd;
  }

  @Mutation
  setTeacherId(teacherId: number) {
    this.teacherId = teacherId;
  }

  @Mutation
  setClassName(className: string) {
    this.className = className;
  }

  @Mutation
  setSubjectName(subjectName: string) {
    this.subjectName = subjectName;
  }

  @Mutation
  setStateName(stateName: string) {
    this.stateName = stateName;
  }

  @Mutation
  setStandardsReport(standardsReport: any) {
    this.standardsReport = standardsReport;
  }

  @Mutation
  setAdminStandardsReport(adminStandardsReport: any) {
    this.adminStandardsReport = adminStandardsReport;
  }

  @Mutation
  setStandardLessons(standardLessons: any) {
    this.standardLessons = standardLessons;
  }

  @Mutation
  updateStandardLesson(data: any) {
    Vue.set(this.standardLessons.searchList, data.index, data.updatedData);
  }

  @Mutation
  addLinkedStandardLessons(linkedStandardLessons: any[]) {
    this.standardLessons.searchList = this.standardLessons.searchList.concat(linkedStandardLessons);
  }

  @Mutation
  setShowStandardLessons(showStandardLessons: boolean) {
    this.showStandardLessons = showStandardLessons;
  }

  @Mutation
  setShowStandardTeachers(showStandardTeachers: boolean) {
    this.showStandardTeachers = showStandardTeachers;
  }

  @Mutation
  setFetchStandardsReport(fetchStandardsReport: string) {
    this.fetchStandardsReport = fetchStandardsReport;
  }

  @Mutation
  reset() {
    this.reportGrade = 0;
    this.reportState = '';
    this.reportSubject = '';
    this.reportCategory = '';
    this.reportClassId = 0;
    this.reportStart = '';
    this.reportEnd = '';
    this.teacherId = 0;
    this.adminStandardsReport = {};
    this.standardsReport = {};
  }

  @Mutation
  init() {
    const currentSelectedSchoolYear = store.getters['teachers/currentSelectedSchoolYear'];
    const userMode = store.getters['settings/getUserMode'];
    const settings = store.getters['settings/getStandardSettings'];
    const firstDay = store.getters['settings/getFirstDay'];
    const lastDay = store.getters['settings/getLastDay'];
    const clone = ld.cloneDeep(store.state.reporting);
    this.reportGrade = clone.reportGrade || +settings.currentGrade || 0;
    this.reportState = clone.reportState || settings.currentState;
    this.reportSubject = clone.reportSubject || (userMode === 'T' ? settings.currentSubject : undefined) || 'VA2';
    this.reportCategory = clone.reportCategory || 'VA';
    this.reportClassId = clone.reportClassId || 0;
    this.showStandardTeachers = userMode === 'A';
    if (userMode === 'T') {
      this.reportStart = clone.reportStart || firstDay;
      this.reportEnd = clone.reportEnd || lastDay;
    } else if (userMode === 'A') {
      this.reportStart = currentSelectedSchoolYear.firstDay;
      this.reportEnd = currentSelectedSchoolYear.lastDay;
    }
    this.teacherId = clone.teacherId || 0;
    this.fetchStandardsReport = CommonUtils.generateUUID();
  }

  @Action({ rawError: true })
  async loadStandardsReport() {
    try {
      const request = this.createRequest;
      if (CommonUtils.hasNoText(request.reportStart) || CommonUtils.hasNoText(request.reportEnd)) {
        return Promise.resolve();
      }
      const resp = await ReportingServices.getStandardsReport(this.createRequest);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          if (this.userMode === 'T' || (request.teacherId > 0 && this.userMode === 'A')) {
            this.context.commit('setStandardsReport', data);
          } else {
            this.context.commit('setAdminStandardsReport', data);
          }
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async loadStandardLessons(params: any) {
    try {
      const resp = await ReportingServices.getStandardLessons(params);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          this.context.commit('setStandardLessons', data);
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async exportCSV() {
    try {
      const request = this.createRequest;
      if (CommonUtils.hasNoText(request.reportStart) || CommonUtils.hasNoText(request.reportEnd)) {
        return Promise.resolve();
      }
      return ReportingServices.downloadStandards(this.printRequest);
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async exportPDF() {
    try {
      const request = this.createRequest;
      if (CommonUtils.hasNoText(request.reportStart) || CommonUtils.hasNoText(request.reportEnd)) {
        return Promise.resolve();
      }
      return ReportingServices.createPDFStandards(this.printRequest);
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }
}
