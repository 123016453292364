var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm.noAccess
      ? _c("div", { staticStyle: { padding: "35px" } }, [
          _c("div", {
            staticClass: "d-flex justify-center align-center",
            staticStyle: { height: "300px" },
            domProps: { innerHTML: _vm._s(_vm.noAccessText) },
          }),
        ])
      : _c(
          "div",
          {
            key: _vm.localRefreshKey,
            ref: "editorSection",
            staticClass: "editor-section-container",
            style: { height: _vm.localConfig.height },
          },
          [
            !_vm.stackMode
              ? _c("div", { staticClass: "editor-section-container-header" }, [
                  _c("div", { staticClass: "label" }, [
                    _vm._v(_vm._s(_vm.label)),
                  ]),
                  !_vm.readonly
                    ? _c(
                        "div",
                        { staticClass: "actions" },
                        [
                          _vm.clearable
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    icon: "",
                                    disabled: _vm.disabled,
                                    "aria-label": _vm.$t("clearAllLabel", {
                                      text: _vm.label,
                                    }),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit("clear")
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("fal fa-trash-alt")])],
                                1
                              )
                            : _vm._e(),
                          _vm._t(
                            "actions",
                            function () {
                              return [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      icon: "",
                                      disabled: _vm.disabled,
                                      "aria-label": _vm.$t("addItemLabel", {
                                        text: _vm.label,
                                      }),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$emit("add")
                                      },
                                    },
                                  },
                                  [_c("v-icon", [_vm._v("fal fa-plus")])],
                                  1
                                ),
                              ]
                            },
                            null,
                            { disabled: _vm.disabled, noAccess: _vm.noAccess }
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "editor-section-container-body" },
              [
                _vm._t("default", null, null, {
                  disabled: _vm.disabled,
                  noAccess: _vm.noAccess,
                }),
              ],
              2
            ),
          ]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }