var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "pb-base-editor",
    {
      attrs: {
        isModal: _vm.isModal,
        input: _vm.input,
        withActions: !_vm.isNewClass,
        expandable: _vm.isModal,
        onApply: _vm.doApply,
        cardClass: _vm.isModal ? "class-editor-card" : "class-editor-sub-page",
        typeLabel: _vm.$t("classLabel"),
        isDirty: _vm.isDirty
      },
      on: { close: _vm.doOnClose },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.input.action === "A"
                      ? _vm.$t("addClassLabel")
                      : _vm.$t("editClassLabel")
                  )
                )
              ])
            ]
          },
          proxy: true
        },
        {
          key: "action-button",
          fn: function() {
            return [
              !_vm.isNewClass
                ? _c("class-actions", {
                    attrs: {
                      type: "button",
                      input: {
                        data: _vm.input,
                        isNewClass: _vm.isNewClass,
                        selectedClass: _vm.selectedClass
                      }
                    }
                  })
                : _vm._e()
            ]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.localValue,
        callback: function($$v) {
          _vm.localValue = $$v
        },
        expression: "localValue"
      }
    },
    [
      _c("v-container", { staticClass: "pb-7" }, [
        _c(
          "div",
          [
            _c("class-editor-form", {
              ref: "classEditorForm",
              attrs: { input: _vm.input, onEditorClose: _vm.doEditorClose }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }