
import CommonUtils from '@/utils/common-utils';
import { Component, Prop, Vue } from 'vue-property-decorator';

const tinycolor = require('tinycolor2');

@Component
export default class MultiAutoComplete extends Vue {
  @Prop({ required: false, type: String, default: '' })
  label!: string;

  @Prop({ required: true, type: Array })
  items!: Array<any>;

  @Prop({ required: false, type: String })
  itemText!: string;

  @Prop({ required: false, type: String })
  itemColor!: string;

  @Prop({ required: false, type: String })
  itemId!: string;

  @Prop({ required: true, type: Array })
  selected!: Array<any>;

  @Prop({ required: false, type: Boolean, default: false })
  error!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  required!: boolean;

  @Prop({ required: false, type: String, default: '' })
  errorMessages!: string;

  get localSelected() {
    return this.selected;
  }

  set localSelected(val: Array<any>) {
    this.$emit('update:selected', val);
  }

  get hasText() {
    return CommonUtils.hasText;
  }

  get isAllSelected() {
    if (this.localSelected.length > this.items.length) {
      const itemValues = this.items.map(item => item[this.itemId]);
      return itemValues.every(value => this.localSelected.includes(value));
    }
    return this.localSelected.length === this.items.length;
  }

  getItemTextColor(item: any) {
    const itemColor = CommonUtils.getColor(item[this.itemColor]);
    if (CommonUtils.hasText(itemColor)) {
      const c = tinycolor(itemColor);
      if (c.getBrightness() < 220) {
        return '#FFF';
      } else {
        return '#000';
      }
    }
  }

  selectAllItems() {
    if (this.localSelected.length === this.items.length) {
      this.localSelected = [];
    } else {
      this.localSelected = this.items.map((i: any) => { return i[this.itemId] });
    }
  }

  remove(toRemove: any) {
    this.localSelected = this.localSelected.filter((i: any) => i !== toRemove[this.itemId]);
  }
}
