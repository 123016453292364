import AppError, { createErrOptions, SessionExpiredError } from '@/errors';
import i18n from '@/plugins/i18n';
import router from '@/router';
import ClassServices from '@/services/class-services';
import LessonServices from '@/services/lesson-services';
import store from '@/store';
import DateTimeUtils from '@/utils/date-time-utils';
import Vue from 'vue';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import vuetify from '@/plugins/vuetify';

@Module({ namespaced: true })
export default class LessonLists extends VuexModule {
    classLessons:any[] = [];
    selectedLessonBankClassId = 0;
    lessonBanksView = i18n.t('allLessonsLabel');
    lessonBanksTitle = '';
    lessonBankLayoutId = 0;
    saveToSchoolOrDistrict = 0;
    updatedFields: Set<string> = new Set<string>();
    selectedClassName = '';
    lessonListView = i18n.t('weekLessonsLabel');
    currentSelectedClassColor = vuetify.framework.theme.currentTheme.primary;
    sharedLessonLayout: any = null;

    get getCurrentSelectedClassColor() {
      return this.currentSelectedClassColor;
    }

    get getSelectedLessonsInLessonBanks():any[] {
      return this.getLessonBanksClassLessons;
    }

    get getSelectedLessonsInLessonList():any[] {
      return this.getLessonListClassLessons;
    }

    get getLessonBanksClassLessons() {
      const classLessons = {
        objects: this.classLessons.map(lesson => {
          lesson.type = 'L'
          return lesson;
        })
      }
      return LessonLists.getClassLessons(classLessons);
    }

    get getLessonListClassLessons() {
      const listData = store.state.plans.currentListData || { lessons: [] };
      const classLessons = {
        objects: (listData.lessons || []).map((lesson:any) => {
          lesson.type = 'L'
          return lesson;
        })
      }
      return LessonLists.getClassLessons(classLessons);
    }

    get getCopyListSharedClassLessons() {
      const listData = store.state.plans.currentListData || { lessons: [] };
      const classLessons = {
        objects: (listData.lessons || []).map((lesson:any) => {
          lesson.type = 'L'
          return lesson;
        })
      }
      const showAllLessons = (classLessons.objects.length > 0) ? (classLessons.objects[0].teacherId !== this.context.rootGetters['settings/getUserId']) : false;
      return LessonLists.getClassLessons(classLessons, { displaySettings: this.sharedLessonLayout }, showAllLessons);
    }

    get getSelectedClassName() {
      return this.selectedClassName;
    }

    get getSelectedLessonBankClassId() {
      return this.selectedLessonBankClassId;
    }

    get getLessonBanksView () {
      return this.lessonBanksView;
    }

    get getLessonListView () {
      return this.lessonListView;
    }

    get getLessonBanksTitle () {
      return this.lessonBanksTitle;
    }

    get getSaveToSchoolOrDistrict () {
      return this.saveToSchoolOrDistrict;
    }

    // MUTATIONS: START

    @Mutation
    setCurrentSelectedClassColor(color: any) {
      this.currentSelectedClassColor = color || vuetify.framework.theme.currentTheme.primary;
    }

    @Mutation
    setUpdatedFields(updatedFields: Set<string>) {
      this.updatedFields = updatedFields;
    }

    @Mutation
    setLessonBanksTitle(lessonBanksTitle: string) {
      this.lessonBanksTitle = lessonBanksTitle;
    }

    @Mutation
    setSaveToSchoolOrDistrict(val: any) {
      this.saveToSchoolOrDistrict = val;
    }

    @Mutation
    setClassLessonsForLessonBanks(classLessons: any) {
      this.classLessons = classLessons || [];
    }

    @Mutation
    updateLessonBanksLessons(data: any) {
      Vue.set(this.classLessons, data.index, data.updatedData);
    }

    @Mutation
    setClassLessonsForListView(classLessons: any) {
      store.commit('plans/setCurrentListData', classLessons || null);
    }

    @Mutation
    setSelectedLessonListName(lessonBankName: string) {
      this.selectedClassName = lessonBankName;
    }

    @Mutation
    setSelectedLessonBankClassId(classId: number) {
      this.selectedLessonBankClassId = classId;
    }

    @Mutation
    setLessonBanksView(view: any):void {
      this.lessonBanksView = view;
    }

    @Mutation
    setLessonListView(view: any):void {
      this.lessonListView = view;
    }

    @Mutation
    setLessonBankLayoutId(lessonBankLayoutId: number) {
      this.lessonBankLayoutId = lessonBankLayoutId;
    }

    @Mutation
    resetLessonBanks() {
      this.lessonBanksView = i18n.t('allLessonsLabel');
      this.lessonBanksTitle = '';
      this.classLessons = [];
      this.selectedLessonBankClassId = 0;
    }

    @Mutation
    resetLessonList() {
      this.lessonListView = i18n.t('weekLessonsLabel');
      this.selectedClassName = '';
      store.commit('plans/setCurrentListData', null);
      store.commit('settings/setCurrentSubjectId', 0);
      this.currentSelectedClassColor = vuetify.framework.theme.currentTheme.primary;
    }

    @Mutation
    resetLessonBankList() {
      this.classLessons = [];
      this.selectedLessonBankClassId = 0;
    }

    @Mutation
    setSharedLessonLayout(layout: any) {
      this.sharedLessonLayout = {
        lessonSections: {
          lesson: {},
          tab2: {},
          tab3: {},
          tab4: {},
          tab5: {},
          tab6: {},
          myStandards: {},
          sharedStandards: {},
          strategies: {},
          schoolStandards: {}
        },
        lessonStyling: {
          lessonTitle: {},
          assignments: {},
          assessments: {},
          attachments: {}
        }
      };

      this.sharedLessonLayout.lessonSections.lesson.lessonType = 'section-text';
      this.sharedLessonLayout.lessonSections.lesson.lessonColor = layout.tab1Color;
      this.sharedLessonLayout.lessonSections.lesson.lessonDisplayOrder = layout.tab1DisplayOrder;
      this.sharedLessonLayout.lessonSections.lesson.lessonEnabled = layout.tab1Enabled;
      this.sharedLessonLayout.lessonSections.lesson.lessonFont = layout.tab1Font;
      this.sharedLessonLayout.lessonSections.lesson.lessonHide = layout.tab1Hide;
      this.sharedLessonLayout.lessonSections.lesson.lessonLabel = layout.tab1Label;
      this.sharedLessonLayout.lessonSections.lesson.lessonLabelBold = layout.tab1LabelBold;
      this.sharedLessonLayout.lessonSections.lesson.lessonLabelFillColor = layout.tab1LabelFillColor;
      this.sharedLessonLayout.lessonSections.lesson.lessonLabelItalic = layout.tab1LabelItalic;
      this.sharedLessonLayout.lessonSections.lesson.lessonLabelUnderline = layout.tab1LabelUnderline;
      this.sharedLessonLayout.lessonSections.lesson.lessonSize = layout.tab1Size;
      this.sharedLessonLayout.lessonSections.lesson.lessonTextBold = layout.tab1TextBold;
      this.sharedLessonLayout.lessonSections.lesson.lessonTextColor = layout.tab1TextColor;
      this.sharedLessonLayout.lessonSections.lesson.lessonTextFillColor = layout.tab1TextFillColor;
      this.sharedLessonLayout.lessonSections.lesson.lessonTextFont = layout.tab1TextFont;
      this.sharedLessonLayout.lessonSections.lesson.lessonTextItalic = layout.tab1TextItalic;
      this.sharedLessonLayout.lessonSections.lesson.lessonTextSize = layout.tab1Size;
      this.sharedLessonLayout.lessonSections.lesson.lessonTextUnderline = layout.tab1TextUnderline;

      this.sharedLessonLayout.lessonSections.tab2.tab2Type = 'section-text';
      this.sharedLessonLayout.lessonSections.tab2.tab2Color = layout.tab2Color;
      this.sharedLessonLayout.lessonSections.tab2.tab2DisplayOrder = layout.tab2DisplayOrder;
      this.sharedLessonLayout.lessonSections.tab2.tab2Enabled = layout.tab2Enabled;
      this.sharedLessonLayout.lessonSections.tab2.tab2Font = layout.tab2Font;
      this.sharedLessonLayout.lessonSections.tab2.tab2Hide = layout.tab2Hide;
      this.sharedLessonLayout.lessonSections.tab2.tab2Label = layout.tab2Label;
      this.sharedLessonLayout.lessonSections.tab2.tab2LabelBold = layout.tab2LabelBold;
      this.sharedLessonLayout.lessonSections.tab2.tab2LabelFillColor = layout.tab2LabelFillColor;
      this.sharedLessonLayout.lessonSections.tab2.tab2LabelItalic = layout.tab2LabelItalic;
      this.sharedLessonLayout.lessonSections.tab2.tab2LabelUnderline = layout.tab2LabelUnderline;
      this.sharedLessonLayout.lessonSections.tab2.tab2Size = layout.tab2Size;
      this.sharedLessonLayout.lessonSections.tab2.tab2TextBold = layout.tab2TextBold;
      this.sharedLessonLayout.lessonSections.tab2.tab2TextColor = layout.tab2TextColor;
      this.sharedLessonLayout.lessonSections.tab2.tab2TextFillColor = layout.tab2TextFillColor;
      this.sharedLessonLayout.lessonSections.tab2.tab2TextFont = layout.tab2TextFont;
      this.sharedLessonLayout.lessonSections.tab2.tab2TextItalic = layout.tab2TextItalic;
      this.sharedLessonLayout.lessonSections.tab2.tab2TextSize = layout.tab2TextSize;
      this.sharedLessonLayout.lessonSections.tab2.tab2TextUnderline = layout.tab2TextUnderline;

      this.sharedLessonLayout.lessonSections.tab3.tab3Type = 'section-text';
      this.sharedLessonLayout.lessonSections.tab3.tab3Color = layout.tab3Color;
      this.sharedLessonLayout.lessonSections.tab3.tab3DisplayOrder = layout.tab3DisplayOrder;
      this.sharedLessonLayout.lessonSections.tab3.tab3Enabled = layout.tab3Enabled;
      this.sharedLessonLayout.lessonSections.tab3.tab3Font = layout.tab3Font;
      this.sharedLessonLayout.lessonSections.tab3.tab3Hide = layout.tab3Hide;
      this.sharedLessonLayout.lessonSections.tab3.tab3Label = layout.tab3Label;
      this.sharedLessonLayout.lessonSections.tab3.tab3LabelBold = layout.tab3LabelBold;
      this.sharedLessonLayout.lessonSections.tab3.tab3LabelFillColor = layout.tab3LabelFillColor;
      this.sharedLessonLayout.lessonSections.tab3.tab3LabelItalic = layout.tab3LabelItalic;
      this.sharedLessonLayout.lessonSections.tab3.tab3LabelUnderline = layout.tab3LabelUnderline;
      this.sharedLessonLayout.lessonSections.tab3.tab3Size = layout.tab3Size;
      this.sharedLessonLayout.lessonSections.tab3.tab3TextBold = layout.tab3TextBold;
      this.sharedLessonLayout.lessonSections.tab3.tab3TextColor = layout.tab3TextColor;
      this.sharedLessonLayout.lessonSections.tab3.tab3TextFillColor = layout.tab3TextFillColor;
      this.sharedLessonLayout.lessonSections.tab3.tab3TextFont = layout.tab3TextFont;
      this.sharedLessonLayout.lessonSections.tab3.tab3TextItalic = layout.tab3TextItalic;
      this.sharedLessonLayout.lessonSections.tab3.tab3TextSize = layout.tab3TextSize;
      this.sharedLessonLayout.lessonSections.tab3.tab3TextUnderline = layout.tab3TextUnderline;

      this.sharedLessonLayout.lessonSections.tab4.tab4Type = 'section-text';
      this.sharedLessonLayout.lessonSections.tab4.tab4Color = layout.tab4Color;
      this.sharedLessonLayout.lessonSections.tab4.tab4DisplayOrder = layout.tab4DisplayOrder;
      this.sharedLessonLayout.lessonSections.tab4.tab4Enabled = layout.tab4Enabled;
      this.sharedLessonLayout.lessonSections.tab4.tab4Font = layout.tab4Font;
      this.sharedLessonLayout.lessonSections.tab4.tab4Hide = layout.tab4Hide;
      this.sharedLessonLayout.lessonSections.tab4.tab4Label = layout.tab4Label;
      this.sharedLessonLayout.lessonSections.tab4.tab4LabelBold = layout.tab4LabelBold;
      this.sharedLessonLayout.lessonSections.tab4.tab4LabelFillColor = layout.tab4LabelFillColor;
      this.sharedLessonLayout.lessonSections.tab4.tab4LabelItalic = layout.tab4LabelItalic;
      this.sharedLessonLayout.lessonSections.tab4.tab4LabelUnderline = layout.tab4LabelUnderline;
      this.sharedLessonLayout.lessonSections.tab4.tab4Size = layout.tab4Size;
      this.sharedLessonLayout.lessonSections.tab4.tab4TextBold = layout.tab4TextBold;
      this.sharedLessonLayout.lessonSections.tab4.tab4TextColor = layout.tab4TextColor;
      this.sharedLessonLayout.lessonSections.tab4.tab4TextFillColor = layout.tab4TextFillColor;
      this.sharedLessonLayout.lessonSections.tab4.tab4TextFont = layout.tab4TextFont;
      this.sharedLessonLayout.lessonSections.tab4.tab4TextItalic = layout.tab4TextItalic;
      this.sharedLessonLayout.lessonSections.tab4.tab4TextSize = layout.tab4TextSize;
      this.sharedLessonLayout.lessonSections.tab4.tab4TextUnderline = layout.tab4TextUnderline;

      this.sharedLessonLayout.lessonSections.tab5.tab5Type = 'section-text';
      this.sharedLessonLayout.lessonSections.tab5.tab5Color = layout.tab5Color;
      this.sharedLessonLayout.lessonSections.tab5.tab5DisplayOrder = layout.tab5DisplayOrder;
      this.sharedLessonLayout.lessonSections.tab5.tab5Enabled = layout.tab5Enabled;
      this.sharedLessonLayout.lessonSections.tab5.tab5Font = layout.tab5Font;
      this.sharedLessonLayout.lessonSections.tab5.tab5Hide = layout.tab5Hide;
      this.sharedLessonLayout.lessonSections.tab5.tab5Label = layout.tab5Label;
      this.sharedLessonLayout.lessonSections.tab5.tab5LabelBold = layout.tab5LabelBold;
      this.sharedLessonLayout.lessonSections.tab5.tab5LabelFillColor = layout.tab5LabelFillColor;
      this.sharedLessonLayout.lessonSections.tab5.tab5LabelItalic = layout.tab5LabelItalic;
      this.sharedLessonLayout.lessonSections.tab5.tab5LabelUnderline = layout.tab5LabelUnderline;
      this.sharedLessonLayout.lessonSections.tab5.tab5Size = layout.tab5Size;
      this.sharedLessonLayout.lessonSections.tab5.tab5TextBold = layout.tab5TextBold;
      this.sharedLessonLayout.lessonSections.tab5.tab5TextColor = layout.tab5TextColor;
      this.sharedLessonLayout.lessonSections.tab5.tab5TextFillColor = layout.tab5TextFillColor;
      this.sharedLessonLayout.lessonSections.tab5.tab5TextFont = layout.tab5TextFont;
      this.sharedLessonLayout.lessonSections.tab5.tab5TextItalic = layout.tab5TextItalic;
      this.sharedLessonLayout.lessonSections.tab5.tab5TextSize = layout.tab5TextSize;
      this.sharedLessonLayout.lessonSections.tab5.tab5TextUnderline = layout.tab5TextUnderline;

      this.sharedLessonLayout.lessonSections.tab6.tab6Type = 'section-text';
      this.sharedLessonLayout.lessonSections.tab6.tab6Color = layout.tab6Color;
      this.sharedLessonLayout.lessonSections.tab6.tab6DisplayOrder = layout.tab6DisplayOrder;
      this.sharedLessonLayout.lessonSections.tab6.tab6Enabled = layout.tab6Enabled;
      this.sharedLessonLayout.lessonSections.tab6.tab6Font = layout.tab6Font;
      this.sharedLessonLayout.lessonSections.tab6.tab6Hide = layout.tab6Hide;
      this.sharedLessonLayout.lessonSections.tab6.tab6Label = layout.tab6Label;
      this.sharedLessonLayout.lessonSections.tab6.tab6LabelBold = layout.tab6LabelBold;
      this.sharedLessonLayout.lessonSections.tab6.tab6LabelFillColor = layout.tab6LabelFillColor;
      this.sharedLessonLayout.lessonSections.tab6.tab6LabelItalic = layout.tab6LabelItalic;
      this.sharedLessonLayout.lessonSections.tab6.tab6LabelUnderline = layout.tab6LabelUnderline;
      this.sharedLessonLayout.lessonSections.tab6.tab6Size = layout.tab6Size;
      this.sharedLessonLayout.lessonSections.tab6.tab6TextBold = layout.tab6TextBold;
      this.sharedLessonLayout.lessonSections.tab6.tab6TextColor = layout.tab6TextColor;
      this.sharedLessonLayout.lessonSections.tab6.tab6TextFillColor = layout.tab6TextFillColor;
      this.sharedLessonLayout.lessonSections.tab6.tab6TextFont = layout.tab6TextFont;
      this.sharedLessonLayout.lessonSections.tab6.tab6TextItalic = layout.tab6TextItalic;
      this.sharedLessonLayout.lessonSections.tab6.tab6TextSize = layout.tab6TextSize;
      this.sharedLessonLayout.lessonSections.tab6.tab6TextUnderline = layout.tab6TextUnderline;

      this.sharedLessonLayout.lessonSections.myStandards.myStandardsType = 'section-standards';
      this.sharedLessonLayout.lessonSections.myStandards.myStandardsColor = layout.myStandardsColor;
      this.sharedLessonLayout.lessonSections.myStandards.myStandardsDisplayOrder = layout.myStandardsDisplayOrder;
      this.sharedLessonLayout.lessonSections.myStandards.myStandardsEnabled = layout.myStandardsEnabled;
      this.sharedLessonLayout.lessonSections.myStandards.myStandardsFont = layout.myStandardsFont;
      this.sharedLessonLayout.lessonSections.myStandards.myStandardsHide = layout.myStandardsHide;
      this.sharedLessonLayout.lessonSections.myStandards.myStandardsLabel = layout.myStandardsLabel;
      this.sharedLessonLayout.lessonSections.myStandards.myStandardsLabelBold = layout.myStandardsLabelBold;
      this.sharedLessonLayout.lessonSections.myStandards.myStandardsLabelFillColor = layout.myStandardsLabelFillColor;
      this.sharedLessonLayout.lessonSections.myStandards.myStandardsLabelItalic = layout.myStandardsLabelItalic;
      this.sharedLessonLayout.lessonSections.myStandards.myStandardsLabelUnderline = layout.myStandardsLabelUnderline;
      this.sharedLessonLayout.lessonSections.myStandards.myStandardsSize = layout.myStandardsSize;
      this.sharedLessonLayout.lessonSections.myStandards.myStandardsTextBold = layout.myStandardsTextBold;
      this.sharedLessonLayout.lessonSections.myStandards.myStandardsTextColor = layout.myStandardsTextColor;
      this.sharedLessonLayout.lessonSections.myStandards.myStandardsTextFillColor = layout.myStandardsTextFillColor;
      this.sharedLessonLayout.lessonSections.myStandards.myStandardsTextFont = layout.myStandardsTextFont;
      this.sharedLessonLayout.lessonSections.myStandards.myStandardsTextItalic = layout.myStandardsTextItalic;
      this.sharedLessonLayout.lessonSections.myStandards.myStandardsTextSize = layout.myStandardsSize;
      this.sharedLessonLayout.lessonSections.myStandards.myStandardsTextUnderline = layout.myStandardsTextUnderline;

      this.sharedLessonLayout.lessonSections.sharedStandards.sharedStandardsType = 'section-standards';
      this.sharedLessonLayout.lessonSections.sharedStandards.sharedStandardsColor = layout.sharedStandardsColor;
      this.sharedLessonLayout.lessonSections.sharedStandards.sharedStandardsDisplayOrder = layout.sharedStandardsDisplayOrder;
      this.sharedLessonLayout.lessonSections.sharedStandards.sharedStandardsEnabled = layout.sharedStandardsEnabled;
      this.sharedLessonLayout.lessonSections.sharedStandards.sharedStandardsFont = layout.sharedStandardsFont;
      this.sharedLessonLayout.lessonSections.sharedStandards.sharedStandardsHide = layout.sharedStandardsHide;
      this.sharedLessonLayout.lessonSections.sharedStandards.sharedStandardsLabel = layout.sharedStandardsLabel;
      this.sharedLessonLayout.lessonSections.sharedStandards.sharedStandardsLabelBold = layout.sharedStandardsLabelBold;
      this.sharedLessonLayout.lessonSections.sharedStandards.sharedStandardsLabelFillColor = layout.sharedStandardsLabelFillColor;
      this.sharedLessonLayout.lessonSections.sharedStandards.sharedStandardsLabelItalic = layout.sharedStandardsLabelItalic;
      this.sharedLessonLayout.lessonSections.sharedStandards.sharedStandardsLabelUnderline = layout.sharedStandardsLabelUnderline;
      this.sharedLessonLayout.lessonSections.sharedStandards.sharedStandardsSize = layout.sharedStandardsSize;
      this.sharedLessonLayout.lessonSections.sharedStandards.sharedStandardsTextBold = layout.sharedStandardsTextBold;
      this.sharedLessonLayout.lessonSections.sharedStandards.sharedStandardsTextColor = layout.sharedStandardsTextColor;
      this.sharedLessonLayout.lessonSections.sharedStandards.sharedStandardsTextFillColor = layout.sharedStandardsTextFillColor;
      this.sharedLessonLayout.lessonSections.sharedStandards.sharedStandardsTextFont = layout.sharedStandardsTextFont;
      this.sharedLessonLayout.lessonSections.sharedStandards.sharedStandardsTextItalic = layout.sharedStandardsTextItalic;
      this.sharedLessonLayout.lessonSections.sharedStandards.sharedStandardsTextSize = layout.sharedStandardsTextSize;
      this.sharedLessonLayout.lessonSections.sharedStandards.sharedStandardsTextUnderline = layout.sharedStandardsTextUnderline;

      this.sharedLessonLayout.lessonSections.strategies.strategiesType = 'section-standards';
      this.sharedLessonLayout.lessonSections.strategies.strategiesColor = layout.strategiesColor;
      this.sharedLessonLayout.lessonSections.strategies.strategiesDisplayOrder = layout.strategiesDisplayOrder;
      this.sharedLessonLayout.lessonSections.strategies.strategiesEnabled = layout.strategiesEnabled;
      this.sharedLessonLayout.lessonSections.strategies.strategiesFont = layout.strategiesFont;
      this.sharedLessonLayout.lessonSections.strategies.strategiesHide = layout.strategiesHide;
      this.sharedLessonLayout.lessonSections.strategies.strategiesLabel = layout.strategiesLabel;
      this.sharedLessonLayout.lessonSections.strategies.strategiesLabelBold = layout.strategiesLabelBold;
      this.sharedLessonLayout.lessonSections.strategies.strategiesLabelFillColor = layout.strategiesLabelFillColor;
      this.sharedLessonLayout.lessonSections.strategies.strategiesLabelItalic = layout.strategiesLabelItalic;
      this.sharedLessonLayout.lessonSections.strategies.strategiesLabelUnderline = layout.strategiesLabelUnderline;
      this.sharedLessonLayout.lessonSections.strategies.strategiesSize = layout.strategiesSize;
      this.sharedLessonLayout.lessonSections.strategies.strategiesTextBold = layout.strategiesTextBold;
      this.sharedLessonLayout.lessonSections.strategies.strategiesTextColor = layout.strategiesTextColor;
      this.sharedLessonLayout.lessonSections.strategies.strategiesTextFillColor = layout.strategiesTextFillColor;
      this.sharedLessonLayout.lessonSections.strategies.strategiesTextFont = layout.strategiesTextFont;
      this.sharedLessonLayout.lessonSections.strategies.strategiesTextItalic = layout.strategiesTextItalic;
      this.sharedLessonLayout.lessonSections.strategies.strategiesTextSize = layout.strategiesSize;
      this.sharedLessonLayout.lessonSections.strategies.strategiesTextUnderline = layout.strategiesTextUnderline;

      this.sharedLessonLayout.lessonSections.schoolStandards.schoolStandardsType = 'section-standards';
      this.sharedLessonLayout.lessonSections.schoolStandards.schoolStandardsColor = layout.schoolStandardsColor;
      this.sharedLessonLayout.lessonSections.schoolStandards.schoolStandardsDisplayOrder = layout.schoolStandardsDisplayOrder;
      this.sharedLessonLayout.lessonSections.schoolStandards.schoolStandardsEnabled = layout.schoolStandardsEnabled;
      this.sharedLessonLayout.lessonSections.schoolStandards.schoolStandardsFont = layout.schoolStandardsFont;
      this.sharedLessonLayout.lessonSections.schoolStandards.schoolStandardsHide = layout.schoolStandardsHide;
      this.sharedLessonLayout.lessonSections.schoolStandards.schoolStandardsLabel = layout.schoolStandardsLabel;
      this.sharedLessonLayout.lessonSections.schoolStandards.schoolStandardsLabelBold = layout.schoolStandardsLabelBold;
      this.sharedLessonLayout.lessonSections.schoolStandards.schoolStandardsLabelFillColor = layout.schoolStandardsLabelFillColor;
      this.sharedLessonLayout.lessonSections.schoolStandards.schoolStandardsLabelItalic = layout.schoolStandardsLabelItalic;
      this.sharedLessonLayout.lessonSections.schoolStandards.schoolStandardsLabelUnderline = layout.schoolStandardsLabelUnderline;
      this.sharedLessonLayout.lessonSections.schoolStandards.schoolStandardsSize = layout.schoolStandardsSize;
      this.sharedLessonLayout.lessonSections.schoolStandards.schoolStandardsTextBold = layout.schoolStandardsTextBold;
      this.sharedLessonLayout.lessonSections.schoolStandards.schoolStandardsTextColor = layout.schoolStandardsTextColor;
      this.sharedLessonLayout.lessonSections.schoolStandards.schoolStandardsTextFillColor = layout.schoolStandardsTextFillColor;
      this.sharedLessonLayout.lessonSections.schoolStandards.schoolStandardsTextFont = layout.schoolStandardsTextFont;
      this.sharedLessonLayout.lessonSections.schoolStandards.schoolStandardsTextItalic = layout.schoolStandardsTextItalic;
      this.sharedLessonLayout.lessonSections.schoolStandards.schoolStandardsTextSize = layout.schoolStandardsTextSize;
      this.sharedLessonLayout.lessonSections.schoolStandards.schoolStandardsTextUnderline = layout.schoolStandardsTextUnderline;

      this.sharedLessonLayout.lessonStyling.lessonTitle.lessonTitleType = 'section-title';
      this.sharedLessonLayout.lessonStyling.lessonTitle.lessonTitleColor = layout.lessonTitleColor;
      this.sharedLessonLayout.lessonStyling.lessonTitle.lessonTitleFont = layout.lessonTitleFont;
      this.sharedLessonLayout.lessonStyling.lessonTitle.lessonTitleLabelBold = layout.lessonTitleLabelBold;
      this.sharedLessonLayout.lessonStyling.lessonTitle.lessonTitleLabelFillColor = layout.lessonTitleLabelFillColor;
      this.sharedLessonLayout.lessonStyling.lessonTitle.lessonTitleLabelItalic = layout.lessonTitleLabelItalic;
      this.sharedLessonLayout.lessonStyling.lessonTitle.lessonTitleLabelUnderline = layout.lessonTitleLabelUnderline;
      this.sharedLessonLayout.lessonStyling.lessonTitle.lessonTitleSize = layout.lessonTitleSize;

      this.sharedLessonLayout.lessonStyling.assessments.assessmentsType = 'section-actions';
      this.sharedLessonLayout.lessonStyling.assessments.assessmentsColor = layout.assessmentsColor;
      this.sharedLessonLayout.lessonStyling.assessments.assessmentsFont = layout.assessmentsFont;
      this.sharedLessonLayout.lessonStyling.assessments.assessmentsLabel = 'Assessments';
      this.sharedLessonLayout.lessonStyling.assessments.assessmentsHide = layout.assessmentsHide;
      this.sharedLessonLayout.lessonStyling.assessments.assessmentsLabelBold = layout.assessmentsLabelBold;
      this.sharedLessonLayout.lessonStyling.assessments.assessmentsLabelFillColor = layout.assessmentsLabelFillColor;
      this.sharedLessonLayout.lessonStyling.assessments.assessmentsLabelItalic = layout.assessmentsLabelItalic;
      this.sharedLessonLayout.lessonStyling.assessments.assessmentsLabelUnderline = layout.assessmentsLabelUnderline;
      this.sharedLessonLayout.lessonStyling.assessments.assessmentsSize = layout.assessmentsSize;
      this.sharedLessonLayout.lessonStyling.assessments.assessmentsTextBold = layout.assessmentsTextBold;
      this.sharedLessonLayout.lessonStyling.assessments.assessmentsTextColor = layout.assessmentsTextColor;
      this.sharedLessonLayout.lessonStyling.assessments.assessmentsTextFillColor = layout.assessmentsTextFillColor;
      this.sharedLessonLayout.lessonStyling.assessments.assessmentsTextFont = layout.assessmentsTextFont;
      this.sharedLessonLayout.lessonStyling.assessments.assessmentsTextItalic = layout.assessmentsTextItalic;
      this.sharedLessonLayout.lessonStyling.assessments.assessmentsTextSize = layout.assessmentsSize;
      this.sharedLessonLayout.lessonStyling.assessments.assessmentsTextUnderline = layout.assessmentsTextUnderline;

      this.sharedLessonLayout.lessonStyling.assignments.assignmentsType = 'section-actions';
      this.sharedLessonLayout.lessonStyling.assignments.assignmentsColor = layout.assignmentsColor;
      this.sharedLessonLayout.lessonStyling.assignments.assignmentsFont = layout.assignmentsFont;
      this.sharedLessonLayout.lessonStyling.assignments.assignmentsHide = layout.assignmentsHide;
      this.sharedLessonLayout.lessonStyling.assignments.assignmentsLabel = 'Assignments';
      this.sharedLessonLayout.lessonStyling.assignments.assignmentsLabelBold = layout.assignmentsLabelBold;
      this.sharedLessonLayout.lessonStyling.assignments.assignmentsLabelFillColor = layout.assignmentsLabelFillColor;
      this.sharedLessonLayout.lessonStyling.assignments.assignmentsLabelItalic = layout.assignmentsLabelItalic;
      this.sharedLessonLayout.lessonStyling.assignments.assignmentsLabelUnderline = layout.assignmentsLabelUnderline;
      this.sharedLessonLayout.lessonStyling.assignments.assignmentsSize = layout.assignmentsSize;
      this.sharedLessonLayout.lessonStyling.assignments.assignmentsTextBold = layout.assignmentsTextBold;
      this.sharedLessonLayout.lessonStyling.assignments.assignmentsTextColor = layout.assignmentsTextColor;
      this.sharedLessonLayout.lessonStyling.assignments.assignmentsTextFillColor = layout.assignmentsTextFillColor;
      this.sharedLessonLayout.lessonStyling.assignments.assignmentsTextFont = layout.assignmentsTextFont;
      this.sharedLessonLayout.lessonStyling.assignments.assignmentsTextItalic = layout.assignmentsTextItalic;
      this.sharedLessonLayout.lessonStyling.assignments.assignmentsTextSize = layout.assignmentsTextSize;
      this.sharedLessonLayout.lessonStyling.assignments.assignmentsTextUnderline = layout.assignmentsTextUnderline;

      this.sharedLessonLayout.lessonStyling.attachments.attachmentsType = 'section-attachments';
      this.sharedLessonLayout.lessonStyling.attachments.attachmentsColor = layout.attachmentsColor;
      this.sharedLessonLayout.lessonStyling.attachments.attachmentsFont = layout.attachmentsFont;
      this.sharedLessonLayout.lessonStyling.attachments.attachmentsHide = layout.attachmentsHide;
      this.sharedLessonLayout.lessonStyling.attachments.attachmentsLabel = 'Attachments';
      this.sharedLessonLayout.lessonStyling.attachments.attachmentsLabelBold = layout.attachmentsLabelBold;
      this.sharedLessonLayout.lessonStyling.attachments.attachmentsLabelFillColor = layout.attachmentsLabelFillColor;
      this.sharedLessonLayout.lessonStyling.attachments.attachmentsLabelItalic = layout.attachmentsLabelItalic;
      this.sharedLessonLayout.lessonStyling.attachments.attachmentsLabelUnderline = layout.attachmentsLabelUnderline;
      this.sharedLessonLayout.lessonStyling.attachments.attachmentsSize = layout.attachmentsSize;
      this.sharedLessonLayout.lessonStyling.attachments.attachmentsTextBold = layout.attachmentsTextBold;
      this.sharedLessonLayout.lessonStyling.attachments.attachmentsTextColor = layout.attachmentsTextColor;
      this.sharedLessonLayout.lessonStyling.attachments.attachmentsTextFillColor = layout.attachmentsTextFillColor;
      this.sharedLessonLayout.lessonStyling.attachments.attachmentsTextFont = layout.attachmentsTextFont;
      this.sharedLessonLayout.lessonStyling.attachments.attachmentsTextItalic = layout.attachmentsTextItalic;
      this.sharedLessonLayout.lessonStyling.attachments.attachmentsTextSize = layout.attachmentsTextSize;
      this.sharedLessonLayout.lessonStyling.attachments.attachmentsTextUnderline = layout.attachmentsTextUnderline;
    }

    @Action({ rawError: true })
    async getListLessons(params?: any) {
      try {
        params = params || {};
        const resp = await LessonServices.getClassLessons(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            console.log('GET BANK LESSONS EXPIRED');
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            if (params.userMode === 'F') {
              this.context.commit('setSharedLessonLayout', data.defaultLayout);
            }
            if (router.currentRoute.name === 'Lesson Banks') {
              this.context.commit('setClassLessonsForLessonBanks', data.lessons);
              this.context.commit('setSelectedLessonBankClassId', data.classId);
            } else {
              this.context.commit('setClassLessonsForListView', data);
              this.context.commit('setSelectedLessonListName', data.className);
              // this.context.commit('setSelectedLessonListClassId', data.classId);
            }
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async getClassesLessons(params?: any) {
      try {
        params = params || {};
        const resp = await LessonServices.getClassesLessons(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            console.log('GET BANK LESSONS EXPIRED');
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            let allLessons: any[] = [];
            data.allLessons.map((a: any) => {
              allLessons = allLessons.concat(a);
            })
            allLessons.sort((a: any, b: any) => {
              if (DateTimeUtils.isThisDateBeforeThatDate(a.date, b.date)) {
                return -1;
              } else if (DateTimeUtils.isThisDateBeforeThatDate(b.date, a.date)) {
                return 1;
              } else if (a.className < b.className) {
                return -1;
              } else {
                return 1;
              }
            })
            this.context.commit('setClassLessonsForListView', { lessons: allLessons });
            if (params.userMode === 'F') {
              this.context.commit('setSharedLessonLayout', data.defaultLayout);
            }
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async changeSequence(params?: any) {
      try {
        params = params || {};
        const resp = await LessonServices.moveSequence(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            console.log('MOVE SEQUENCE EXPIRED');
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async saveSchoolLessonBank(params?: any) {
      try {
        params = params || {};
        const resp = await LessonServices.lessonBank(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            console.log('SAVE SCHOOL LESSON BANK EXPIRED');
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            let classesParams = {};
            if (params.mode === 'SLB') {
              classesParams = { userMode: params.userMode }
              await store.dispatch('settings/reloadSettings');
            } else if (params.mode === 'LB') {
              if (params.userMode === 'A') {
                classesParams = {
                  userMode: params.userMode,
                  teacherId: 0,
                  yearId: 0,
                  schoolId: params.schoolId
                }
              } else {
                classesParams = { userMode: params.userMode }
              }
            } else if (params.mode === 'DLB') {
              classesParams = {
                userMode: params.userMode,
                teacherId: 0,
                yearId: 0,
                schoolId: params.schoolId
              }
            }
            const resp = await ClassServices.getClasses(classesParams);
            store.commit('classes/setClasses', resp.data);
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async deleteLessonBank(params?: any) {
      try {
        params = params || {};
        const resp = await LessonServices.deleteLessonBank(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            console.log('DELETE LESSON BANK EXPIRED');
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            let classesParams = {};
            if (params.lessonBankType === 'D') {
              classesParams = {
                userMode: params.userMode,
                teacherId: 0,
                yearId: 0,
                schoolId: params.schoolId
              }
            } else {
              if (params.userMode === 'A') {
                classesParams = {
                  userMode: params.userMode,
                  teacherId: 0,
                  yearId: 0,
                  schoolId: params.schoolId
                }
              } else {
                classesParams = { userMode: params.userMode }
              }
            }
            const resp = await ClassServices.getClasses(classesParams);
            store.commit('classes/setClasses', resp.data);
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async uploadCSV(params?: any) {
      try {
        params = params || {};
        const resp = await LessonServices.uploadCSV(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            console.log('UPLOAD CSV EXPIRED');
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async save(params?: any) {
      return this.context.dispatch('saveSchoolLessonBank', params);
    }

    @Action({ rawError: true })
    async refreshFields(refreshAll:boolean) {
      if (refreshAll) {
        this.context.commit('setLessonBanksTitle', '');
        this.context.commit('setLessonBankLayoutId', 0);
      }
      this.context.commit('setUpdatedFields', new Set<string>());
      this.context.commit('setSaveToSchoolOrDistrict', 0);
    }

    private static getClassLessons(classLessons:any, layoutSettings?: any, showAll?: any) {
      const settings = store.getters['plans/getPlanSettings'];
      const userMode = store.getters['settings/getUserMode'];
      const dataObjects = LessonServices.createDay(classLessons, 'list', {
        settings: settings,
        lessonSections: LessonServices.createLessonSections(layoutSettings || settings),
        eventSections: LessonServices.createEventSections(settings),
        googleClassSections: LessonServices.createGoogleClassroomSections(),
        lessonHeadings: settings.displaySettings.lessonHeadings,
        statuses: settings.accountSettings.statuses,
        eventStyling: settings.displaySettings.eventStyling,
        otherSettings: settings.otherSettings,
        userMode: userMode,
        sharedClasses: settings.sharingOptionsSettings.sharedClasses,
        viewSettings: settings.viewSettings,
        showAllLessons: showAll
      }, layoutSettings).dayObjects || [];
      return dataObjects.map((dayObject: any) => {
        const lesson = dayObject.rawDayObject;
        delete dayObject.rawDayObject;
        lesson.dayObject = dayObject;
        lesson.isExpanded = false;
        return lesson;
      });
    }
}
