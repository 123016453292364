

























































































import CommonUtils from '@/utils/common-utils';
import DateTimeUtils from '@/utils/date-time-utils';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Confirm from '../core/Confirm.vue';
import moment from 'moment';
const lessons = namespace('lessons');
const settings = namespace('settings');
@Component
export default class ManageLessons extends Vue {
  @Prop({ type: Boolean })
  value!: boolean;

  @Prop({ required: true, type: Number, default: 0 })
  classId!: number;

  @Prop({ required: true, type: String })
  startDate!: string;

  @Prop({ required: true, type: String })
  endDate!: string;

  localStartDate = '';
  localEndDate = '';
  searchText = '';
  manageLessonsList:any[] = [];
  selectedRows:any[] = [];
  headers = [
    { text: this.$t('classLabel'), value: 'className', width: 250 },
    { text: this.$t('dateLabel'), value: 'lessonDate', width: 150 },
    { text: this.$t('titleLabel'), value: 'lessonTitle' },
    { text: this.$t(''), value: 'hasLinkLessons', width: 20 },
    { text: this.$t('lastUpdatedLabel'), value: 'lastUpdate', width: 150 }
  ];

  @Watch('value')
  valueOnChange() {
    this.localStartDate = this.startDate;
    this.localEndDate = this.endDate;
    this.manageLessonsList = [];
  }

  @lessons.Action('search')
  search!: (params: any) => Promise<any>;

  @lessons.Action('deleteLessons')
  deleteLessons!: (params: any) => Promise<any>;

  @lessons.Action('doUnlink')
  doUnlink!: (params: any) => Promise<any>;

  @settings.Getter
  highContrastMode!: boolean;

  $refs!: {
    manageLessonsConfirm: Confirm,
  }

  get moment() {
    return moment;
  }

  get formatToDisplay() {
    return DateTimeUtils.formatToDisplay;
  }

  get localValue() {
    return this.value;
  }

  set localValue(val: boolean) {
    this.$emit('input', val);
  }

  get yearId() {
    return this.$store.state.settings.updatedSettings.yearId;
  }

  async doDeleteLessons() {
    const that = this;
    if (CommonUtils.isNotEmpty(that.selectedRows)) {
      return this.$refs.manageLessonsConfirm.confirm({
        title: this.$t('deleteLessonLabel'),
        text: this.$t('confirmDeleteLesson2'),
        option1ButtonAlternativeText: this.$t('deleteLessonLabel')
      }).then((result) => {
        if (result === 1) {
          CommonUtils.showLoading();
          const lessonIds:any = [];
          const subjectIds:any = [];
          that.selectedRows.forEach((lesson) => {
            lessonIds.push(lesson.lessonId);
          });
          that.selectedRows.forEach((lesson) => {
            subjectIds.push(lesson.subjectId);
          });
          return that.deleteLessons({ lessonIds, subjectIds });
        } else {
          return Promise.resolve();
        }
      }).finally(() => {
        that.doShowLessons();
      });
    }
  }

  async doUnlinkLessons() {
    const that = this;
    if (CommonUtils.isNotEmpty(that.selectedRows)) {
      return this.$refs.manageLessonsConfirm.confirm({
        title: this.$t('unlinkLessonsActionLabel'),
        text: this.$t('retainLessonContentsMessage'),
        option1ButtonAlternativeText: this.$t('yesLabel'),
        option2ButtonAlternativeText: this.$t('noLabel')
      }).then((result) => {
        const lessonAddendums:any = [];
        if (result === 1 || result === 2) {
          CommonUtils.showLoading();
          that.selectedRows.forEach((lesson) => {
            lessonAddendums.push({ lessonId: lesson.lessonId });
          });
        }
        if (result === 1) {
          return that.doUnlink({ lessonAddendums, linkAction: 1 });
        } else if (result === 2) {
          return that.doUnlink({ lessonAddendums });
        } else {
          return Promise.resolve();
        }
      }).finally(() => {
        that.doShowLessons();
      });
    }
  }

  doShowLessons() {
    CommonUtils.showLoading();
    this.manageLessonsList = [];
    this.selectedRows = [];
    this.search({
      yearId: this.yearId,
      subjectId: this.classId !== 0 ? this.classId : null,
      lessonStart: this.localStartDate,
      lessonEnd: this.localEndDate,
      searchText: this.searchText
    }).then((resp:any) => {
      const data = resp.data;
      this.manageLessonsList = data.lessons;
      CommonUtils.hideLoading();
    })
  }

  hasLinkedLesson(addendums:any) {
    if (CommonUtils.isNotEmpty(addendums)) {
      for (var i = 0; i < addendums.length; i++) {
        if (addendums[i].type === 'LINKEDLESSON') {
          return true;
        }
      }
    }
    return false;
  }
}
