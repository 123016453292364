import { render, staticRenderFns } from "./BottomDrawer.vue?vue&type=template&id=1f207dac&scoped=true"
import script from "./BottomDrawer.vue?vue&type=script&lang=ts"
export * from "./BottomDrawer.vue?vue&type=script&lang=ts"
import style0 from "./BottomDrawer.vue?vue&type=style&index=0&id=1f207dac&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f207dac",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VBottomSheet,VBtn,VBtnToggle,VCol,VIcon,VRow,VSheet})


/* hot reload */
if (module.hot) {
  var api = require("/Users/adriandaluz/Development/git/planbook-web-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1f207dac')) {
      api.createRecord('1f207dac', component.options)
    } else {
      api.reload('1f207dac', component.options)
    }
    module.hot.accept("./BottomDrawer.vue?vue&type=template&id=1f207dac&scoped=true", function () {
      api.rerender('1f207dac', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/core/BottomDrawer.vue"
export default component.exports