import AppError, { createErrOptions, SessionExpiredError } from '@/errors';
import NotesTodoServices from '@/services/notes-todo-services';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

const debug = process.env.NODE_ENV !== 'production';

@Module({ namespaced: true })
export default class NotesTodo extends VuexModule {
    haveTodos = false;
    todosList: Array<any> = [];
    haveNotes = false;
    notesList: Array<any> = [];
    remindersList: Array<any> = [];
    haveReminders = false;
    listLoading = false;

    localNote: any = {};
    localToDo: any = {};
    originalNote: any = {};
    originalToDo: any = {};

    searchText = '';
    selectedRows: Array<any> = [];
    editMode = false;

    // GETTERS: START

    get getHaveTodos() {
      return this.haveTodos;
    }

    get getTodos() {
      return this.todosList;
    }

    get getHaveNotes() {
      return this.haveNotes;
    }

    get getNotes() {
      return this.notesList;
    }

    get getListLoading() {
      return this.listLoading;
    }
    // GETTERS: END

    // MUTATIONS: START
    @Mutation
    setEditMode(editMode: boolean) {
      this.editMode = editMode;
    }

    @Mutation
    setSelectedRows(selectedRows: Array<any>) {
      this.selectedRows = selectedRows;
    }

    @Mutation
    setSearchText(searchText: string) {
      this.searchText = searchText;
    }

    @Mutation
    setLocalNote(note: any) {
      this.localNote = note;
    }

    @Mutation
    setLocalToDo(todo: any) {
      this.localToDo = todo;
    }

    @Mutation
    setOriginalNote(note: any) {
      this.originalNote = note;
    }

    @Mutation
    setOriginalToDo(todo: any) {
      this.originalToDo = todo;
    }

    @Mutation
    setHaveTodos(haveTodos: boolean) {
      this.haveTodos = haveTodos;
    }

    @Mutation
    setTodos(todos: Array<any>) {
      this.todosList = todos;
    }

    @Mutation
    setHaveNotes(haveNotes: boolean) {
      this.haveNotes = haveNotes;
    }

    @Mutation
    setNotes(notes: Array<any>) {
      this.notesList = notes;
    }

    @Mutation
    setReminders(reminders: Array<any>) {
      this.remindersList = reminders;
    }

    @Mutation
    setHaveReminders(haveReminders: boolean) {
      this.haveReminders = haveReminders;
    }

    @Mutation
    setListLoading(listLoading: boolean) {
      this.listLoading = listLoading;
    }
    // MUTATIONS: END

    // ACTIONS: START
    @Action({ rawError: true })
    async loadTodos() {
      try {
        const userMode = this.context.rootState.settings.userMode;
        if (userMode === 'S' || this.haveTodos) {
          return Promise.resolve({ data: {} });
        } else {
          const resp = await NotesTodoServices.getTodos();
          if (resp) {
            const data = resp.data;
            if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
              console.log('GET TODOS EXPIRED');
              return Promise.reject(new SessionExpiredError());
            } else if (data.error === 'true' || data.error === true) {
              return Promise.reject(new AppError('contactSupport', { data }));
            } else {
              if (debug) {
                console.log('GET TODOS RESPONSE --> ' + JSON.stringify(data));
              }
              this.context.commit('setHaveTodos', true);
              this.context.commit('setTodos', data.toDos || []);
              return Promise.resolve({ data });
            }
          } else {
            return Promise.resolve({ data: {} });
          }
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async addTodo(params?: any) {
      try {
        let data: any = {};
        if (params) {
          const resp = await NotesTodoServices.updateTodo({ action: 'A' });
          if (resp) {
            data = resp.data;
            if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
              console.log('ADD TODO EXPIRED');
              return Promise.reject(new SessionExpiredError());
            } else if (data.error === 'true' || data.error === true) {
              return Promise.reject(new AppError('contactSupport', { data }));
            } else {
              if (debug) {
                console.log('ADD TODO RESPONSE --> ' + JSON.stringify(data));
              }
              if (data && data.toDoId) {
                params.toDoId = data.toDoId;
                await this.context.dispatch('updateTodo', params);
              }
              return Promise.resolve({ data });
            }
          } else {
            return Promise.resolve({ data: {} });
          }
        } else {
          return Promise.resolve({ data });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async updateTodo(params?: any) {
      try {
        let data: any = {};
        if (params) {
          if (params.action !== 'D') {
            params.action = 'U';
          }
          const resp = await NotesTodoServices.updateTodo(params);
          if (resp) {
            data = resp.data;
            if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
              console.log('UPDATE TODO EXPIRED');
              return Promise.reject(new SessionExpiredError());
            } else if (data.error === 'true' || data.error === true) {
              return Promise.reject(new AppError('contactSupport', { data }));
            } else {
              if (debug) {
                console.log('UPDATE TODO RESPONSE --> ' + JSON.stringify(data));
              }
              return Promise.resolve({ data });
            }
          } else {
            return Promise.resolve({ data: {} });
          }
        } else {
          return Promise.resolve({ data });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async loadNotes(params?: any) {
      try {
        const userMode = this.context.rootState.settings.userMode;
        if (userMode === 'S' || this.haveNotes) {
          return Promise.resolve({ data: {} });
        } else {
          const resp = await NotesTodoServices.getNotes({
            startDate: params.notesSearchStartDate || '01/01/0001',
            endDate: params.notesSearchEndDate || '12/31/9999',
            subjectId: params.notesSearchSubjectId || 0,
            studentId: params.notesSearchStudentId || 0
          });
          if (resp) {
            const data = resp.data;
            if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
              console.log('GET NOTES EXPIRED');
              return Promise.reject(new SessionExpiredError());
            } else if (data.error === 'true' || data.error === true) {
              return Promise.reject(new AppError('contactSupport', { data }));
            } else {
              if (debug) {
                console.log('GET NOTES RESPONSE --> ' + JSON.stringify(data));
              }
              this.context.commit('setHaveNotes', true);
              this.context.commit('setNotes', data || []);
              return Promise.resolve({ data });
            }
          } else {
            return Promise.resolve({ data: {} });
          }
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async loadEventsReminders(params?: any) {
      try {
        const userMode = this.context.rootState.settings.userMode;
        if (userMode === 'S' || this.haveReminders) {
          return Promise.resolve({ data: {} });
        } else {
          const resp = await NotesTodoServices.getRemindersEvents({
            startDate: params.notesSearchStartDate || '01/01/0001',
            endDate: params.notesSearchEndDate || '12/31/9999',
            subjectId: params.notesSearchSubjectId || 0,
            studentId: params.notesSearchStudentId || 0
          });
          if (resp) {
            const data = resp.data;
            if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
              console.log('GET NOTES EXPIRED');
              return Promise.reject(new SessionExpiredError());
            } else if (data.error === 'true' || data.error === true) {
              return Promise.reject(new AppError('contactSupport', { data }));
            } else {
              if (debug) {
                console.log('GET EVENTS REMINDERS RESPONSE --> ' + JSON.stringify(data));
              }
              this.context.commit('setHaveReminders', true);
              this.context.commit('setReminders', data || []);
              return Promise.resolve({ data });
            }
          } else {
            return Promise.resolve({ data: {} });
          }
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async addOrUpdateNote(params?: any) {
      try {
        let data: any = {};
        if (params) {
          const resp = await NotesTodoServices.addNote({
            noteId: params.noteId,
            yearId: params.yearId,
            subjectId: params.subjectId,
            studentId: params.studentId,
            noteDate: params.noteDate,
            noteText: params.noteText
          });
          if (resp) {
            data = resp.data;
            if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
              console.log('ADD OR UPDATE NOTE EXPIRED');
              return Promise.reject(new SessionExpiredError());
            } else if (data.error === 'true' || data.error === true) {
              return Promise.reject(new AppError('contactSupport', { data }));
            } else {
              if (debug) {
                console.log('ADD OR UPDATE RESPONSE --> ' + JSON.stringify(data));
              }
              return Promise.resolve({ data });
            }
          } else {
            return Promise.resolve({ data: {} });
          }
        } else {
          return Promise.resolve({ data });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async deleteNote(params?: any) {
      try {
        let data: any = {};
        if (params) {
          const resp = await NotesTodoServices.deleteNote({
            noteId: params.noteId
          });
          if (resp) {
            data = resp.data;
            if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
              console.log('DELETE NOTE EXPIRED');
              return Promise.reject(new SessionExpiredError());
            } else if (data.error === 'true' || data.error === true) {
              return Promise.reject(new AppError('contactSupport', { data }));
            } else {
              if (debug) {
                console.log('DELETE NOTE RESPONSE --> ' + JSON.stringify(data));
              }
              return Promise.resolve({ data });
            }
          } else {
            return Promise.resolve({ data: {} });
          }
        } else {
          return Promise.resolve({ data });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async deleteMultipleNotesTodos(params?: any) {
      try {
        let data: any = {};
        if (params) {
          const resp = await NotesTodoServices.deleteMultipleNotesTodos(params);
          if (resp) {
            data = resp.data;
            if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
              console.log('DELETE NOTESTODO EXPIRED');
              return Promise.reject(new SessionExpiredError());
            } else if (data.error === 'true' || data.error === true) {
              return Promise.reject(new AppError('contactSupport', { data }));
            } else {
              if (debug) {
                console.log('DELETE NOTESTODO RESPONSE --> ' + JSON.stringify(data));
              }
              return Promise.resolve({ data });
            }
          } else {
            return Promise.resolve({ data: {} });
          }
        } else {
          return Promise.resolve({ data });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }
    // ACTIONS: START
}
