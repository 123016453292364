import axios from '@/axios/index';
import qs from 'qs';

export default class SeatingChartServices {
  public static getRoomLayouts() {
    return axios.get('/services/planbook/seatinglayout', {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static postRoomLayout(params?: any) {
    params = params || {};
    return axios.post('/services/planbook/seatinglayout', params, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  public static deleteRoomLayout(params?: any) {
    params = params || {};
    return axios.delete('/services/planbook/seatinglayout', {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: qs.stringify(params)
    });
  }

  public static getStudents(params?: any) {
    params = params || {};
    return axios.get('/services/planbook/seatingassignment/students', {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      params: {
        subjectId: params.subjectId,
        color: params.color
      }
    });
  }

  public static getSeatingAssignments() {
    return axios.get('/services/planbook/seatingassignment', {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static postSeatingAssignment(params?: any) {
    params = params || {};
    return axios.post('/services/planbook/seatingassignment', params, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  public static deleteSeatingAssignment(params?: any) {
    params = params || {};
    return axios.delete('/services/planbook/seatingassignment', {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: qs.stringify(params)
    });
  }
}
