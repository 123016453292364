var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "pb-base-modal",
    {
      attrs: {
        withActions: false,
        expandable: false,
        maxWidth: 500,
        withApply: false
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.$t("manageStatusLabel")) + " ")]
          },
          proxy: true
        },
        {
          key: "actions",
          fn: function() {
            return [
              _c(
                "pb-btn",
                {
                  staticClass: "mr-1",
                  attrs: {
                    icon: "",
                    right: "",
                    label: _vm.$t("addStatusLabel")
                  },
                  on: { click: _vm.addStatus }
                },
                [_c("v-icon", [_vm._v("fal fa-plus")])],
                1
              )
            ]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.localValue,
        callback: function($$v) {
          _vm.localValue = $$v
        },
        expression: "localValue"
      }
    },
    [
      _c(
        "div",
        [
          _vm.loading
            ? _c("v-progress-linear", {
                staticStyle: { "margin-top": "-10px", "margin-bottom": "6px" },
                attrs: { indeterminate: "", color: "blue darken-1" }
              })
            : _vm._e(),
          _vm.userMode == "T"
            ? _c(
                "v-list",
                { staticClass: "pt-0 pb-5" },
                _vm._l(_vm.statusItems, function(item) {
                  return _c(
                    "v-list-item",
                    { key: item.lessonStatusId, attrs: { dense: "" } },
                    [
                      _c(
                        "v-list-item-action",
                        { staticClass: "mr-3" },
                        [
                          _c("pb-color-picker", {
                            on: {
                              change: function($event) {
                                return _vm.doApplyStatus(item)
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: {
                                                color: item.color,
                                                "aria-label": _vm.$t(
                                                  "pickColorLabel"
                                                )
                                              }
                                            },
                                            "v-icon",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_vm._v("fas fa-circle")]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: item.color,
                              callback: function($$v) {
                                _vm.$set(item, "color", $$v)
                              },
                              expression: "item.color"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          !item.edit
                            ? _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function($event) {
                                      return _vm.editStatus(item)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(item.statusText))]
                              )
                            : _c("v-text-field", {
                                ref: "text" + item.lessonStatusId,
                                refInFor: true,
                                staticClass: "pt-0 mr-5",
                                attrs: {
                                  "hide-details": "auto",
                                  disabled: _vm.loading
                                },
                                model: {
                                  value: item.statusText,
                                  callback: function($$v) {
                                    _vm.$set(item, "statusText", $$v)
                                  },
                                  expression: "item.statusText"
                                }
                              })
                        ],
                        1
                      ),
                      !item.edit
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "mr-3",
                              attrs: {
                                icon: "",
                                "aria-label": _vm.$t("editStatusLabel")
                              },
                              on: {
                                click: function($event) {
                                  return _vm.editStatus(item)
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { color: "info" } }, [
                                _vm._v("fal fa-pencil")
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      !item.edit
                        ? _c("pb-color-picker", {
                            on: {
                              change: function($event) {
                                return _vm.doApplyStatus(item)
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass:
                                                "mr-3 focus-icon-border",
                                              attrs: {
                                                tabindex: "0",
                                                role: "button",
                                                color: item.color,
                                                "aria-label": _vm.$t(
                                                  "pickColorLabel"
                                                )
                                              }
                                            },
                                            "v-icon",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_vm._v("fas fa-eye-dropper")]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: item.color,
                              callback: function($$v) {
                                _vm.$set(item, "color", $$v)
                              },
                              expression: "item.color"
                            }
                          })
                        : _vm._e(),
                      !item.edit
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                icon: "",
                                "aria-label": _vm.$t("deleteStatusLabel")
                              },
                              on: {
                                click: function($event) {
                                  return _vm.deleteStatus(item)
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { color: "info" } }, [
                                _vm._v("fal fa-trash-alt")
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      item.edit
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                icon: "",
                                "aria-label": _vm.$t("saveStatusLabel")
                              },
                              on: {
                                click: function($event) {
                                  return _vm.doApplyStatus(null)
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { color: "info" } }, [
                                _vm._v("fal fa-check")
                              ])
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
          _vm.userMode == "A"
            ? _c(
                "v-list",
                { staticClass: "pt-0 pb-5" },
                _vm._l(_vm.schoolStatusItems, function(item) {
                  return _c(
                    "v-list-item",
                    { key: "s" + item.schoolStatusId, attrs: { dense: "" } },
                    [
                      _c(
                        "v-list-item-action",
                        { staticClass: "mr-3" },
                        [
                          !item.overrideDelete
                            ? _c("pb-color-picker", {
                                on: {
                                  change: function($event) {
                                    return _vm.doApplySchoolStatus(item)
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    color: item.color,
                                                    "aria-label": _vm.$t(
                                                      "pickColorLabel"
                                                    )
                                                  }
                                                },
                                                "v-icon",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [_vm._v("fas fa-circle")]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: item.color,
                                  callback: function($$v) {
                                    _vm.$set(item, "color", $$v)
                                  },
                                  expression: "item.color"
                                }
                              })
                            : _c("v-icon", { attrs: { color: item.color } }, [
                                _vm._v("fas fa-circle")
                              ])
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          item.overrideDelete
                            ? _c("span", [_vm._v(_vm._s(item.statusText))])
                            : !item.edit
                            ? _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function($event) {
                                      return _vm.editSchoolStatus(item)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(item.statusText))]
                              )
                            : _c("v-text-field", {
                                ref: "text" + item.schoolStatusId,
                                refInFor: true,
                                staticClass: "pt-0 mr-5",
                                attrs: {
                                  "hide-details": "auto",
                                  disabled: _vm.loading
                                },
                                model: {
                                  value: item.statusText,
                                  callback: function($$v) {
                                    _vm.$set(item, "statusText", $$v)
                                  },
                                  expression: "item.statusText"
                                }
                              })
                        ],
                        1
                      ),
                      !item.edit && !item.overrideDelete
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "mr-3",
                              attrs: {
                                icon: "",
                                "aria-label": _vm.$t("editStatusLabel")
                              },
                              on: {
                                click: function($event) {
                                  return _vm.editSchoolStatus(item)
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { color: "info" } }, [
                                _vm._v("fal fa-pencil")
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      !item.edit && !item.overrideDelete
                        ? _c("pb-color-picker", {
                            on: {
                              change: function($event) {
                                return _vm.doApplySchoolStatus(item)
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass:
                                                "mr-3 focus-icon-border",
                                              attrs: {
                                                tabindex: "0",
                                                role: "button",
                                                color: item.color,
                                                "aria-label": _vm.$t(
                                                  "pickColorLabel"
                                                )
                                              }
                                            },
                                            "v-icon",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_vm._v("fas fa-eye-dropper")]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: item.color,
                              callback: function($$v) {
                                _vm.$set(item, "color", $$v)
                              },
                              expression: "item.color"
                            }
                          })
                        : _vm._e(),
                      !item.edit && !item.overrideDelete
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "mr-3",
                              attrs: {
                                icon: "",
                                "aria-label": _vm.$t("adminOnlyLabel")
                              },
                              on: {
                                click: function($event) {
                                  item.adminOnly = !item.adminOnly
                                }
                              }
                            },
                            [
                              item.adminOnly
                                ? _c("v-icon", [_vm._v("far fa-lock")])
                                : _c("v-icon", [_vm._v("far fa-unlock")])
                            ],
                            1
                          )
                        : _vm._e(),
                      !item.edit
                        ? _c(
                            "pb-btn",
                            {
                              attrs: {
                                label:
                                  item.schoolStatusId < 3
                                    ? _vm.$t("disableStatusLabel")
                                    : _vm.$t("deleteLabel"),
                                icon: "",
                                "aria-label": _vm.$t("deleteStatusLabel")
                              },
                              on: {
                                click: function($event) {
                                  return _vm.deleteSchoolStatus(item)
                                }
                              }
                            },
                            [
                              item.overrideDelete
                                ? _c("v-icon", [_vm._v("fal fa-users-slash")])
                                : item.schoolStatusId < 3
                                ? _c("v-icon", { attrs: { color: "info" } }, [
                                    _vm._v("fal fa-users")
                                  ])
                                : _c("v-icon", { attrs: { color: "info" } }, [
                                    _vm._v("fal fa-trash-alt")
                                  ])
                            ],
                            1
                          )
                        : _vm._e(),
                      item.edit
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                icon: "",
                                "aria-label": _vm.$t("saveStatusLabel")
                              },
                              on: {
                                click: function($event) {
                                  return _vm.doApplySchoolStatus(null)
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { color: "info" } }, [
                                _vm._v("fal fa-check")
                              ])
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("pb-confirm", { ref: "confirm" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }