var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("multi-list-picker", {
    attrs: {
      label: _vm.$t("myFilesLabel"),
      items: _vm.localMyFilesItems,
      selected: _vm.selected,
      showShortValue: false,
      withRefresh: true,
      infiniteScroll: true,
      contentClass: "my-files-picker-content"
    },
    on: { refresh: _vm.refresh, apply: _vm.doApply },
    scopedSlots: _vm._u([
      {
        key: "prepend-action-buttons",
        fn: function() {
          return [
            _c("v-file-input", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: false,
                  expression: "false"
                }
              ],
              ref: "fileInput",
              staticClass: "pa-2",
              attrs: {
                placeholder: _vm.$t("uploadYourDocsLabel"),
                label: _vm.$t("uploadLabel"),
                multiple: "",
                outlined: "",
                "hide-details": "auto",
                "prepend-inner-icon": "mdi-paperclip",
                error: _vm.errorUploadFile,
                "prepend-icon": ""
              },
              on: { change: _vm.saveFiles },
              model: {
                value: _vm.files,
                callback: function($$v) {
                  _vm.files = $$v
                },
                expression: "files"
              }
            }),
            _c("folder-picker", {
              attrs: {
                moveToCurrentOpenedFolder: _vm.uploadToCurrentOpenedFolder,
                folders: _vm.filteredAllAvailableFolders,
                isTableLoading: _vm.isUploadToTableLoading,
                moveToFolder: _vm.uploadToFolder,
                moveToOpenedFolder: _vm.uploadToOpenedFolder,
                hasSelectedRows: true,
                moveToIsAddingFolder: _vm.uploadToIsAddingFolder,
                moveToNewFolderName: _vm.uploadToNewFolderName,
                uploadMode: true
              },
              on: {
                openMoveToFolder: _vm.openMoveToFolder,
                moveToCreateNewFolder: _vm.uploadToCreateNewFolder,
                doMove: _vm.doUpload,
                "update:moveToFolder": function($event) {
                  _vm.uploadToFolder = $event
                },
                "update:moveToOpenedFolder": function($event) {
                  _vm.uploadToOpenedFolder = $event
                },
                "update:moveToIsAddingFolder": function($event) {
                  _vm.uploadToIsAddingFolder = $event
                },
                "update:moveToNewFolderName": function($event) {
                  _vm.uploadToNewFolderName = $event
                }
              },
              model: {
                value: _vm.uploadMenu,
                callback: function($$v) {
                  _vm.uploadMenu = $$v
                },
                expression: "uploadMenu"
              }
            })
          ]
        },
        proxy: true
      }
    ]),
    model: {
      value: _vm.localValue,
      callback: function($$v) {
        _vm.localValue = $$v
      },
      expression: "localValue"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }