



























import CommonUtils from '@/utils/common-utils';
import moment from 'moment';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { timeFormat12HrWithAMPM, timeFormat12Hr, timeFormat24Hr } from '@/constants';
import { namespace } from 'vuex-class';
import { VTextField } from 'vuetify/lib';
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'

const settings = namespace('settings');

@Component({
  components: {
    VueTimepicker
  }
})
export default class TimeField extends Vue {
  @Prop({ required: false, default: false })
  dense!: boolean;

  @Prop({ required: false, default: false })
  disabled!: boolean;

  @Prop({ required: false, default: '' })
  label!: string;

  @Prop({ required: false, default: () => [] })
  messages!: Array<any>;

  @Prop({ required: false, default: 'top left' })
  origin!: string;

  @Prop({ required: false, default: false })
  rangeField!: boolean;

  @Prop({ required: false, default: '' })
  prependInnerIcon!: string;

  @Prop({ required: false, default: false })
  required!: boolean;

  @Prop({ required: false, default: '' })
  value!: string;

  @Prop({ required: false, default: '' })
  min!: string;

  @Prop({ required: false, default: '' })
  max!: string;

  @Prop({ required: false, default: false })
  outlined!: boolean

  @Prop({ required: false, default: () => [] })
  rules!: Array<any>;

  @Prop({ required: false, default: () => [] })
  errors!: Array<any>;

  localRef = CommonUtils.generateUUID();
  localTimePickerParent = 'parent_' + this.localRef;
  localMenu = false;
  hideClearButton = true;
  localValue = '';
  localPickerValue = '';
  shift = 0;

  $refs!: {
    textField: InstanceType<typeof VTextField>,
  }

  @settings.Getter('getTimeFormat')
  timeFormat!: number;

  @Watch('value')
  updateLocalValue(v: string) {
    console.log(v, this.localValue)
    this.localValue = v;
    if (CommonUtils.hasText(v)) {
      if (this.localTimeFormat === '24hr') {
        this.localPickerValue = v;
      } else if (this.localTimeFormat === 'ampm') {
        this.localPickerValue = moment(v, 'h:mm A').format('HH:mm');
      }
    }
  }

  get localTimeFormat() {
    return this.timeFormat === 1 ? '24hr' : 'ampm';
  }

  get vueTimepickerFormat() {
    return this.timeFormat === 1 ? 'HH:mm' : 'h:mm A';
  }

  get minHour() {
    return moment(this.min, 'hh:mm:ss').hour();
  }

  get maxHour() {
    return moment(this.max, 'hh:mm:ss').hour();
  }

  get minMinutes() {
    return moment(this.min, 'hh:mm:ss').minute();
  }

  get maxMinutes() {
    return moment(this.max, 'hh:mm:ss').minute();
  }

  get localHour() {
    return moment(this.localValue, this.vueTimepickerFormat).hour();
  }

  get localMinutes() {
    return moment(this.localValue, this.vueTimepickerFormat).minute();
  }

  get hourRange() {
    let localMin = this.minHour;
    let localMax = this.maxHour;

    if (localMin !== localMax) {
      if (this.localMinutes < this.minMinutes) {
        localMin = (localMin + 1) % 24;
      }

      if (this.localMinutes > this.maxMinutes) {
        localMax = ((((localMax - 1) % 24) + 24) % 24);
      }
    }

    let localMinForParam = '';
    let localMaxForParam = '';
    if (this.localTimeFormat === '24hr') {
      localMinForParam = localMin.toString();
      localMaxForParam = localMax.toString();
    } else {
      localMinForParam = moment(localMin.toString(), 'H').format('ha')
      localMinForParam = localMinForParam.slice(0, localMinForParam.length - 1);
      localMaxForParam = moment(localMax.toString(), 'H').format('ha')
      localMaxForParam = localMaxForParam.slice(0, localMaxForParam.length - 1);
    }

    return [[localMinForParam, localMaxForParam]];
  }

  get minuteRange() {
    let localMin = 0;
    let localMax = 59;

    if (this.localHour === this.minHour) {
      localMin = this.minMinutes;
    }

    if (this.localHour === this.maxHour) {
      localMax = this.maxMinutes;
    }

    return [[localMin, localMax]];
  }

  @Watch('localValue')
  updateValue(v: string) {
    if (CommonUtils.hasText(v) && this.isValid(v)) {
      this.$emit('update:value', this.localValue);
      this.$emit('change');
    }
  }

  hoverEnterTimepicker() {
    this.hideClearButton = false;
  }

  hoverExitTimepicker() {
    this.hideClearButton = true;
  }

  isValid(v: string) {
    if (v && v.length > 0) {
      return this.localTimeFormat === '24hr' ? timeFormat24Hr.test(v) : timeFormat12HrWithAMPM.test(v)
    }
    return true;
  }

  created() {
    if (CommonUtils.hasText(this.$props.value)) {
      this.localValue = this.$props.value;
    }
  }
}
