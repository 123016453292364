
import CommonUtils from '@/utils/common-utils';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import ClassEditorForm from '@/components/classes/ClassEditorForm.vue';
import ClassActions from '@/components/classes/ClassActions.vue';
import { namespace } from 'vuex-class';
import { classEditMoreOptions } from '../../constants';
import { Editor } from '@/types/global.types';

const classes = namespace('classes');

@Component({
  components: {
    ClassEditorForm,
    ClassActions
  }
})
export default class ClassEditor extends Vue implements Editor {
  @Prop({ type: Boolean })
  value!: boolean;

  @Prop({ type: Boolean, default: false })
  isModal!: boolean;

  @Prop({
    type: Object,
    default: () => {
      return {};
    }
  })
  input!: any;

  @classes.State
  selectedClass!: any;

  @classes.Mutation
  resetClass!: any;

  $refs!: {
    classEditorForm: ClassEditorForm
  }

  get localValue() {
    return this.value;
  }

  set localValue(val: boolean) {
    this.$emit('input', val);
  }

  localClassOptions = classEditMoreOptions;
  localRefreshKey = CommonUtils.generateUUID();

  doEditorClose() {
    this.localValue = false;
    if (this.isModal) {
      this.$eventBus.$emit('closeClassEditorMain', false);
    } else {
      this.$eventBus.$emit('closeClassEditorMain', true);
    }
  }

  get isNewClass() {
    if (this.$refs.classEditorForm) {
      return this.$refs.classEditorForm.isNewClass;
    } else if (this.input.action === 'A') {
      return true;
    } else {
      return false;
    }
  }

  @Watch('input')
  onInput() {
    if (this.input && this.input.action === 'A') {
      this.resetClass();
      if (this.$refs.classEditorForm) {
        this.$refs.classEditorForm.initialize();
      }
    }
  }

  isDirty() {
    return this.$refs.classEditorForm.changesMade();
  }

  doOnClose() {
    this.$eventBus.$emit('closeClassEditorMain', false);
  }

  typeChange() {
    return null;
  }

  doApply() {
    return this.$refs.classEditorForm.applyClass();
  }
}
