var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "pb-base-modal",
    {
      attrs: {
        maxWidth: 500,
        input: _vm.input,
        expandable: false,
        subpage: true,
        withActions: false,
        onClose: _vm.doOnClose
      },
      on: { apply: _vm.doApply },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _c("div", [
                _c("span", [_vm._v(_vm._s(_vm.$t("importLabel")))]),
                _c("span", [_vm._v(" " + _vm._s(_vm.input.type))])
              ])
            ]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.localValue,
        callback: function($$v) {
          _vm.localValue = $$v
        },
        expression: "localValue"
      }
    },
    [
      _c(
        "div",
        [
          _c("lesson-bank-csv-editor-form", {
            ref: "form",
            attrs: { input: _vm.input },
            on: { fileChange: _vm.fileOnChange }
          }),
          _c("pb-confirm", { ref: "confirm" })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }