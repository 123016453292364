var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "teacher-tags-input" },
    [
      _c("v-combobox", {
        ref: "tagsInput",
        attrs: {
          chips: "",
          clearable: "",
          multiple: "",
          outlined: "",
          "append-icon": "",
          "hide-details": "auto",
          autofocus: "",
          label: _vm.$t("teachersLabel") + "*",
          "aria-required": "true",
          placeholder: _vm.$t("inviteTeachersInputLabel"),
          "search-input": _vm.tagsInputText,
          rules: _vm.localRules,
          "validate-on-blur": "",
        },
        on: {
          "update:searchInput": function ($event) {
            _vm.tagsInputText = $event
          },
          "update:search-input": function ($event) {
            _vm.tagsInputText = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "append",
            fn: function () {
              return [
                _c(
                  "v-btn",
                  {
                    staticStyle: { "margin-top": "-2px" },
                    attrs: {
                      icon: "",
                      small: "",
                      loading: _vm.selectingFile,
                      "aria-label": _vm.$t("uploadLabel"),
                    },
                    on: { click: _vm.uploadFile },
                  },
                  [
                    _c("v-icon", { attrs: { small: "" } }, [
                      _vm._v("fa-solid fa-upload"),
                    ]),
                  ],
                  1
                ),
                _c("input", {
                  ref: "fileInput",
                  staticClass: "d-none",
                  attrs: { type: "file", accept: ".txt" },
                  on: { change: _vm.onFileChanged },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "selection",
            fn: function ({ attrs, item, select, selected }) {
              return [
                _c(
                  "v-chip",
                  _vm._b(
                    {
                      staticClass: "my-1",
                      attrs: {
                        "input-value": selected,
                        close: "",
                        label: "",
                        color: "info",
                      },
                      on: {
                        click: select,
                        "click:close": function ($event) {
                          return _vm.remove(item)
                        },
                      },
                    },
                    "v-chip",
                    attrs,
                    false
                  ),
                  [
                    _vm.dynamicAccountType
                      ? _c(
                          "v-menu",
                          {
                            attrs: { "offset-y": "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ attrs, on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mr-2",
                                              staticStyle: {
                                                "margin-left": "-5px",
                                              },
                                              attrs: {
                                                "x-small": "",
                                                icon: "",
                                                elevation: "0",
                                              },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-avatar",
                                            {
                                              staticClass: "font-weight-bold",
                                              staticStyle: {
                                                height: "18px",
                                                width: "18px",
                                                "min-width": "18px",
                                              },
                                              attrs: { color: "success" },
                                            },
                                            [_vm._v(_vm._s(_vm.getType(item)))]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c(
                              "v-list",
                              _vm._l(_vm.accountTypes, function (accountType) {
                                return _c(
                                  "v-list-item",
                                  {
                                    key: accountType.value,
                                    class: _vm.getSelectedClass(
                                      item,
                                      accountType.value
                                    ),
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectType(
                                          item,
                                          accountType.value
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(_vm._s(accountType.text)),
                                    ]),
                                  ],
                                  1
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("span", [_vm._v(_vm._s(item))]),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.localEmails,
          callback: function ($$v) {
            _vm.localEmails = $$v
          },
          expression: "localEmails",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }