var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "textEditor", staticClass: "text-editor" }, [
    _vm.noAccess
      ? _c("div", { staticStyle: { padding: "35px" } }, [
          _c("div", {
            staticClass: "d-flex justify-center align-center",
            style: { height: _vm.localConfig.height },
            domProps: { innerHTML: _vm._s(_vm.noAccessText) }
          })
        ])
      : _vm.disabled
      ? _c(
          "div",
          { style: { height: _vm.localConfig.height, "overflow-y": "auto" } },
          [
            _c("div", {
              directives: [{ name: "linkified", rawName: "v-linkified" }],
              domProps: { innerHTML: _vm._s(_vm.value) }
            })
          ]
        )
      : _vm._e(),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.noAccess && !_vm.disabled,
            expression: "!noAccess && !disabled"
          }
        ],
        style: { height: _vm.localConfig.height }
      },
      [
        _c("editor", {
          key: _vm.localRefreshKey,
          attrs: { init: _vm.localConfig },
          model: {
            value: _vm.localValue,
            callback: function($$v) {
              _vm.localValue = $$v
            },
            expression: "localValue"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }