


























































import CommonUtils from '@/utils/common-utils';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import Confirm from '../core/Confirm.vue';
import { VForm } from 'vuetify/lib';
import draggable from 'vuedraggable';
import { namespace } from 'vuex-class';
import TeacherTagsInput from '../../components/teachers/TeacherTagsInput.vue';
import MultiAutoComplete from './../../components/core/MultiAutoComplete.vue';
import ld from 'lodash';
import { sections } from '@/constants';

const settings = namespace('settings');
const classes = namespace('classes');
const teachers = namespace('teachers');
const permissions = namespace('permissions');

@Component({
  props: {
    value: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  components: {
    draggable,
    Confirm,
    TeacherTagsInput,
    MultiAutoComplete
  }
})

export default class PermissionsEditorForm extends Vue {
  @Prop({
    required: true,
    type: Object,
    default: () => {
      return {};
    }
  })
  input!: any;

  localRefreshKey = CommonUtils.generateUUID();
  type = [this.$t('teacherLabel'), this.$t('schoolLabel'), this.$t('districtLabel')];
  localRules = [
    (v: any) => !!v || this.$t('requiredLabel'),
    (v: any) => v.length > 0 || this.$t('requiredLabel')
  ];

  localPermissionClone: any;

  allClasses = false;
  allLessonBank = false;
  teacherEmailNotValid = false;
  teacherEmailErrorMessage:any = '';
  localInput:any = {};
  noClassesError = false;
  noCLassOrLessonBankErrorMessage:any = '';
  localSchoolYearNamesMap = new Map();

  $refs!: {
    permissionEditorForm: InstanceType<typeof VForm>,
    permissionEditorConfirm: Confirm
  }

  @settings.State
  adminSchoolYears!: Array<any>;

  @settings.Getter('getUserMode')
  userMode!: string;

  @settings.Getter('getLessonSections')
  getTeacherLessonSections!: any;

  @settings.Getter
  getAdminLessonSections!: any;

  @settings.Action('save')
  doSave!: (params?:any) => Promise<any>;

  @settings.Getter('getSchoolId')
  schoolId!: number;

  @settings.Getter('getDistrictId')
  userDistrictId!: number;

  @settings.Getter('getSchoolYears')
  teacherSchoolYears!: Array<any>;

  @classes.Getter('getClassItems')
  classItems!: any[];

  @classes.Getter('getLessonBankItems')
  lessonBankItems!: Array<any>;

  @classes.Getter('getDistrictLessonBankItems')
  districtLessonBankItems!: Array<any>;

  @teachers.Action('findTeacherByEmails')
  findTeacherByEmails!: (params?:any) => Promise<any>;

  @permissions.Action('getPermissions')
  getPermissions!: (params?: any) => Promise<any>;

  @permissions.Action
  saveAdminPermissions!: (params?: any) => Promise<any>;

  @permissions.Getter('getPermissionsData')
  lessonPermissions!: any[];

  get getLessonSections() {
    if (this.$currentUser.isAdmin) {
      return this.getAdminLessonSections;
    } else {
      return this.getTeacherLessonSections;
    }
  }

  get localLessonBankItems() {
    return this.$currentUser.isDistrictAdmin ? this.lessonBankItems.concat(this.districtLessonBankItems) : this.lessonBankItems;
  }

  get localPermission() {
    return this.$store.state.permissions.localPermission || {};
  }

  set localPermission(permission: any) {
    this.$store.commit('permissions/setLocalPermission', permission)
  }

  get localSelectedClasses() {
    return this.localPermission.selectedClasses;
  }

  set localSelectedClasses(value: any) {
    this.setValue('selectedClasses', value);
  }

  get localSelectedLessonBanks() {
    return this.localPermission.selectedLessonBanks;
  }

  set localSelectedLessonBanks(value: any) {
    this.setValue('selectedLessonBanks', value);
  }

  get localSelectedTeacherEmails() {
    return this.localPermission.selectedTeacherEmails;
  }

  set localSelectedTeacherEmails(value: any) {
    this.setValue('selectedTeacherEmails', value);
  }

  get localSelectedType() {
    return this.localPermission.selectedType;
  }

  set localSelectedType(value: any) {
    this.setValue('selectedType', value);
  }

  get localTitle() {
    return this.localPermission.title;
  }

  set localTitle(value: any) {
    this.setValue('title', value);
  }

  setValue(path: string, value: any) {
    const clone = ld.cloneDeep(this.localPermission);
    ld.set(clone, path, value);
    this.localPermission = clone;
  }

  @Watch('input')
  async onInputChange(v:any) {
    if (v.loadData === false) {
      return;
    }
    this.localInput = v.data;
    if (CommonUtils.isNotEmpty(Object.keys(this.localInput))) {
      CommonUtils.showLoading();
      this.refreshFields();
      this.localPermission.title = this.localInput.title;
      this.localPermission.selectedType = this.getPermissionType(this.localInput);
      if (this.localPermission.selectedType === this.$t('teacherLabel')) {
        const techerEmails = this.localInput.teacherEmailAddress.split(',');
        for (const email of techerEmails) {
          this.localPermission.selectedTeacherEmails.push({
            email: email,
            userType: 'T'
          })
        }
      }
      const subjectIds = this.localInput.subjectIds.split(',');
      for (const subjectId of subjectIds) {
        const found = this.localClassItems.some((e:any) => +e.value === +subjectId);
        if (found) {
          this.localPermission.selectedClasses.push(+subjectId);
        } else {
          this.localPermission.selectedLessonBanks.push(+subjectId);
        }
      }
      this.localPermission.information = this.getSharingSections(JSON.parse(this.localInput.settings));
      this.localPermissionClone = ld.cloneDeep(this.localPermission);
      CommonUtils.hideLoading();
    } else {
      this.initialize();
    }
  }

  get currentLessonLayoutId() {
    return this.getLessonSections.currentLessonLayoutId;
  }

  get currentTeacherId(): number {
    if (this.$currentUser.isTeacher) {
      return +this.$store.state.settings.userId;
    } else {
      return +this.$store.state.settings.currentTeacherId;
    }
  }

  get getSchoolId() {
    if (this.localSelectedType === this.$t('schoolLabel')) {
      return this.schoolId;
    } else {
      return '';
    }
  }

  get getDistrictId() {
    if (this.localSelectedType === this.$t('districtLabel')) {
      return this.userDistrictId;
    } else {
      return '';
    }
  }

  get currentYearId(): number {
    if (this.$currentUser.isTeacher) {
      return +this.$store.getters['settings/getCurrentSchoolYearId'];
    } else {
      return +this.$store.state.settings.currentYearId;
    }
  }

  get localClassItems() {
    const enhancedClassItems: any = [];
    this.classItems.forEach((ci: any) => {
      const classItem = ld.cloneDeep(ci);
      classItem.formattedName = ci.text + ' (' + this.localSchoolYearNamesMap.get(ci.yearId) + ')';
      enhancedClassItems.push(classItem);
    });
    return enhancedClassItems.filter((c: any) => !c.isGoogleClass);
  }

  get getCurrentLessonLayoutId() {
    if (this.$currentUser.isTeacher) {
      return this.getLessonSections.currentLessonLayoutId;
    }
    return 0;
  }

  get schoolYears() {
    return (this.userMode === 'A' ? this.adminSchoolYears : this.teacherSchoolYears) || [];
  }

  resetValidation() {
    try {
      const form: any = this.$refs.permissionEditorForm;
      form.resetValidation();
    } catch (e) {
    }
  }

  refreshFields() {
    this.noClassesError = false;
    this.teacherEmailNotValid = false;
    this.localPermission.title = '';
    this.localSelectedType = this.$t('teacherLabel');
    this.localPermission.selectedTeacherEmails = [];
    this.localPermission.selectedClasses = [];
    this.localPermission.selectedLessonBanks = [];
    this.localPermission.information = [];
    this.noCLassOrLessonBankErrorMessage = '';
  }

  getPermissionType(data:any):string {
    if (CommonUtils.hasText(data.teacherIds) && CommonUtils.hasText(data.teacherEmailAddress)) {
      return this.$t('teacherLabel').toString();
    } else if (CommonUtils.hasText(data.districtIds)) {
      return this.$t('districtLabel').toString();
    } else if (CommonUtils.hasText(data.schoolIds)) {
      return this.$t('schoolLabel').toString();
    } else {
      return '';
    }
  }

  changeRadioValue(data:any) {
    if (data.type === 'All') {
      const information = [];
      for (const section of this.localPermission.information) {
        information.push({ ...section, selectedValue: data.selectedValue });
      }
      this.setValue('information', information);
    }
  }

  isDirty() {
    return (!ld.isEqual(this.localPermission, this.localPermissionClone));
  }

  getPermissionValue(s: any, data: any) {
    switch (s) {
      case 'lesson': return data.permissions1;
      case 'tab2': return data.permissions2;
      case 'tab3': return data.permissions3;
      case 'tab4': return data.permissions4;
      case 'tab5': return data.permissions5;
      case 'tab6': return data.permissions6;
      case 'strategies': return data.permissions7;
      case 'myStandards': return data.permissions8;
      case 'sharedStandards': return data.permissions9;
      case 'schoolStandards': return data.permissions10;
    }
  }

  getSharingSections(data?: any) {
    const dynamicSections = [
      { label: this.$t('allSectionsLabel'), displayOrder: -1, type: 'All', selectedValue: 'none', enabledValue: 'Y' },
      { label: this.$t('titleLabel'), displayOrder: 0, type: 'title', selectedValue: data ? data.permissions11 : 'none', enabledValue: 'Y' },
      { label: this.$t('attachmentsLabel'), displayOrder: 15, type: 'Attachments', selectedValue: data ? data.permissions12 : 'none', enabledValue: 'Y' }
    ];
    const userSections: Array<any> = sections.filter((s: any) => s !== 'attachments').map((s: string) => {
      const section = this.getLessonSections[s];
      const selectedValue = data ? this.getPermissionValue(s, data) : 'none'
      return {
        type: s,
        displayOrder: section[s + 'DisplayOrder'],
        enabledValue: section[s + 'Enabled'],
        label: section[s + 'Label'],
        selectedValue: selectedValue
      }
    });
    const allRead = userSections.every((s: any) => s.selectedValue === 'read');
    const allWrite = userSections.every((s: any) => s.selectedValue === 'write');
    dynamicSections[0].selectedValue = allRead ? 'read' : allWrite ? 'write' : 'none';
    return dynamicSections.concat(userSections).sort((a, b) => a.displayOrder - b.displayOrder);
  }

  initialize() {
    CommonUtils.showLoading();
    this.refreshFields();
    this.localPermission.information = this.getSharingSections();
    this.localPermissionClone = ld.cloneDeep(this.localPermission);
    this.localRefreshKey = CommonUtils.generateUUID();
    CommonUtils.hideLoading();
  }

  getSaveValueFor(s: any) {
    const permissionSection = this.localPermission.information.find((p: any) => p.type === s);
    return permissionSection.selectedValue;
  }

  storePermission(data?:any) {
    const subjects = this.localPermission.selectedClasses.concat(this.localPermission.selectedLessonBanks);
    let permissionSettings:any = {
      permissions1: this.getSaveValueFor('lesson'),
      permissions2: this.getSaveValueFor('tab2'),
      permissions3: this.getSaveValueFor('tab3'),
      permissions4: this.getSaveValueFor('tab4'),
      permissions5: this.getSaveValueFor('tab5'),
      permissions6: this.getSaveValueFor('tab6'),
      permissions7: this.getSaveValueFor('strategies'),
      permissions8: this.getSaveValueFor('myStandards'),
      permissions9: this.getSaveValueFor('sharedStandards'),
      permissions10: this.getSaveValueFor('schoolStandards'),
      permissions11: this.getSaveValueFor('title'),
      permissions12: this.getSaveValueFor('Attachments')
    };

    permissionSettings = JSON.stringify(permissionSettings);
    const payload = ld.cloneDeep(this.lessonPermissions);
    if (CommonUtils.isEmpty(Object.keys(this.localInput))) {
      payload.push({
        settings: permissionSettings,
        subjectIds: CommonUtils.isEmpty(subjects) ? '' : subjects.join(','),
        title: this.localPermission.title,
        teacherEmailAddress: data ? data.emailsToDisplay : '',
        teacherIds: data ? data.teacherIdsToSave : '',
        ownerYearId: this.currentYearId,
        schoolIds: this.getSchoolId.toString(),
        districtIds: this.getDistrictId.toString()
      });
    } else {
      const objIndex = payload.findIndex(permission => permission.id === this.localInput.id);
      payload[objIndex] = {
        settings: permissionSettings,
        subjectIds: CommonUtils.isEmpty(subjects) ? '' : subjects.join(','),
        title: this.localPermission.title,
        teacherEmailAddress: data ? data.emailsToDisplay : '',
        teacherIds: data ? data.teacherIdsToSave : '',
        ownerYearId: this.currentYearId,
        schoolIds: this.getSchoolId.toString(),
        districtIds: this.getDistrictId.toString()
      };
    }
    if (this.$currentUser.isAdmin) {
      return this.savePermission({ permissions: payload, adminOptions: {} });
    } else {
      return this.savePermission(payload);
    }
  }

  savePermission(payload:any) {
    if (this.$currentUser.isAdmin) {
      return this.saveAdminPermissions({ permissions: JSON.stringify(payload.permissions), sharingOptions: payload.adminOptions }).then(() => {
        return this.getPermissions({
          teacherId: this.currentTeacherId,
          schoolId: this.schoolId,
          userMode: this.userMode
        }).then(() => {
          CommonUtils.hideLoading();
          return this.closeEditor();
        });
      });
    } else {
      return this.doSave({
        permissions: JSON.stringify(payload)
      }).then(() => {
        return this.getPermissions({
          teacherId: this.currentTeacherId,
          schoolId: this.schoolId,
          userMode: this.userMode
        }).then(() => {
          CommonUtils.hideLoading();
          return this.closeEditor();
        });
      });
    }
  }

  closeEditor() {
    this.$eventBus.$emit('closePermissionEditor', false);
    return Promise.resolve(false);
  }

  doApply() {
    const form:any = this.$refs.permissionEditorForm;
    this.teacherEmailNotValid = false;

    if (CommonUtils.isEmpty(this.localPermission.selectedClasses) && CommonUtils.isEmpty(this.localPermission.selectedLessonBanks)) {
      this.noClassesError = true;
      this.noCLassOrLessonBankErrorMessage = this.$t('noCLassOrLessonBankErrorMessage');
    } else {
      this.noClassesError = false;
      this.noCLassOrLessonBankErrorMessage = '';
    }

    if (form.validate() && !this.noClassesError) {
      CommonUtils.showLoading();
      if (this.localSelectedType === this.$t('teacherLabel')) {
        const teacherEmailsPayload = [];
        for (const email of this.localPermission.selectedTeacherEmails) {
          teacherEmailsPayload.push(email.email);
        }
        return this.findTeacherByEmails({ emails: teacherEmailsPayload.join(',') }).then((resp:any) => {
          const data = resp.data;
          if (data.teacherIdsNotSaved !== '') {
            this.teacherEmailNotValid = true;
            this.teacherEmailErrorMessage = this.$t('invalidTeacherEmails', { teachers: data.teacherIdsNotSaved });
            CommonUtils.hideLoading();
            return Promise.reject();
          }
          return this.storePermission(data);
        });
      } else {
        return this.storePermission();
      }
    }
    return Promise.resolve(true);
  }

  created() {
    this.schoolYears.forEach((sy: any) => {
      this.localSchoolYearNamesMap.set(sy.yearId, sy.yearName);
    });
    this.onInputChange(this.input);
  }
}
