






























import CommonUtils from '@/utils/common-utils';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class LabeledButton extends Vue {
    @Prop()
    label!: string;

    @Prop({ default: '', required: false })
    hiddenLabel!: string;

    @Prop({ default: '', required: false })
    id!: string;

    @Prop({ default: true, required: false })
    focusBorder!: boolean;

    tooltipOpen = false;

    get ariaLabel() {
      return this.hiddenLabel || this.label;
    }

    get hasLabel() {
      return CommonUtils.hasText(this.label) && !this.$vuetify.breakpoint.smAndDown;
    }
}
