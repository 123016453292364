import store from '@/store';
import CommonUtils from '@/utils/common-utils';

export default class CurrentUser {
  public static get settings() {
    return store.getters['settings/getUserInfo'];
  }

  public static get userType() {
    return store.getters['settings/getUserType'];
  }

  public static get userMode() {
    return store.getters['settings/getUserMode'];
  }

  public static get isAdmin() {
    return CurrentUser.userMode === 'A';
  }

  public static get isTeacher() {
    return CurrentUser.userMode === 'T';
  }

  public static get isDualUser() {
    return CurrentUser.userType === 'B';
  }

  public static get isDualOrAllowViewOnly() {
    return CurrentUser.isDualUser || (store.getters['settings/allowViewTeachers'] && CurrentUser.userType === 'T' && CurrentUser.userMode !== 'S');
  }

  public static get isDistrictAdmin() {
    return CurrentUser.userType === 'D';
  }

  public static get isStudent() {
    return CurrentUser.userMode === 'S';
  }

  public static get id() {
    return store.getters['settings/getUserId'];
  }

  public static get schoolId() {
    return CurrentUser.settings?.schoolSettings?.schoolId;
  }

  public static get districtId(): number {
    return CurrentUser.settings?.schoolSettings?.districtId;
  }

  public static get isPaymentNeeded(): boolean {
    return CommonUtils.isTrue(CurrentUser.settings?.schoolSettings?.paymentNeeded || CurrentUser.settings?.accountSettings?.paymentNeeded);
  }

  public static get isSchoolSuspended(): boolean {
    return CommonUtils.isTrue(CurrentUser.settings?.schoolSettings?.schoolSuspendAccount);
  }

  public static get defaultEditorMode(): string {
    return CurrentUser.settings?.otherSettings?.defaultEditorMode || 'split';
  }
}
