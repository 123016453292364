
import AppError, { createErrOptions, SessionExpiredError } from '@/errors';
import ChangeHistoryServices from '@/services/change-history-services';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true })
export default class ChangeHistory extends VuexModule {
    changeHistory: Array<any> = [];

    @Mutation
    setChangeHistory(changeHistory: Array<any>) {
      this.changeHistory = changeHistory;
    }

    @Action({ rawError: true })
    async loadChangeHistory() {
      try {
        const resp = await ChangeHistoryServices.getChangeHistory();
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            this.context.commit('setChangeHistory', resp.data);
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }
}
