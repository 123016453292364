var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.label && _vm.label != ""
    ? _c(
        "div",
        { attrs: { id: _vm.id } },
        [
          _c(
            "v-tooltip",
            {
              attrs: { "open-delay": "200", bottom: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on }) {
                      return [
                        _c(
                          "div",
                          _vm._g({}, on),
                          [
                            _c(
                              "v-icon",
                              _vm._g(
                                _vm._b(
                                  {
                                    scopedSlots: _vm._u(
                                      [
                                        _vm._l(
                                          _vm.$scopedSlots,
                                          function (_, scopedSlotName) {
                                            return {
                                              key: scopedSlotName,
                                              fn: function (slotData) {
                                                return [
                                                  _vm._t(
                                                    scopedSlotName,
                                                    null,
                                                    null,
                                                    slotData
                                                  ),
                                                ]
                                              },
                                            }
                                          }
                                        ),
                                        _vm._l(
                                          _vm.$slots,
                                          function (_, slotName) {
                                            return {
                                              key: slotName,
                                              fn: function () {
                                                return [_vm._t(slotName)]
                                              },
                                              proxy: true,
                                            }
                                          }
                                        ),
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  "v-icon",
                                  { ..._vm.$attrs },
                                  false
                                ),
                                _vm.$listeners
                              )
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                225068309
              ),
            },
            [_c("span", [_vm._v(_vm._s(_vm.label))])]
          ),
        ],
        1
      )
    : _c(
        "div",
        { attrs: { id: _vm.id } },
        [
          _c(
            "v-icon",
            _vm._g(
              _vm._b(
                {
                  scopedSlots: _vm._u(
                    [
                      _vm._l(_vm.$scopedSlots, function (_, scopedSlotName) {
                        return {
                          key: scopedSlotName,
                          fn: function (slotData) {
                            return [
                              _vm._t(scopedSlotName, null, null, slotData),
                            ]
                          },
                        }
                      }),
                      _vm._l(_vm.$slots, function (_, slotName) {
                        return {
                          key: slotName,
                          fn: function () {
                            return [_vm._t(slotName)]
                          },
                          proxy: true,
                        }
                      }),
                    ],
                    null,
                    true
                  ),
                },
                "v-icon",
                { ..._vm.$attrs },
                false
              ),
              _vm.$listeners
            )
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }