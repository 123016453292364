import axios from '@/axios';
import CurrentUser from '@/common/current-user';
import { lessonSectionsMapping, lessonStylingMapping, permissionsMapping, sections } from '@/constants';
import i18n from '@/plugins/i18n';
import store from '@/store';
import CommonUtils from '@/utils/common-utils';
import DateTimeUtils from '@/utils/date-time-utils';
import ld from 'lodash';
import qs from 'qs';
import vuetify from '@/plugins/vuetify';

export default class LessonServices {
  public static getLessonsEvents(params?: any): Promise<any> {
    params = params || {};
    return axios.post('getLessonsEvents', qs.stringify(params));
  }

  public static getMonthLessonsEvents(params?: any): Promise<any> {
    params = params || {};
    return axios.post('getMonthLessons', qs.stringify(params));
  }

  public static getStudentLessonsEvents(params?: any): Promise<any> {
    params = params || {};
    return axios.post('launchStudent', qs.stringify(params), {
      withCredentials: !(CurrentUser.isStudent && CurrentUser.id === 0)
    });
  }

  public static getNextLessonDate(params?: any): Promise<any> {
    params = params || {};
    return axios.post('services/planbook/lesson/getNextLessonDate', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static getPrevLessonDate(params?: any): Promise<any> {
    params = params || {};
    return axios.post('/services/planbook/lesson/getPreviousLessonDate', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static updateLesson(params?: any): Promise<any> {
    params = params || {};
    return axios.post('updateLesson', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static deleteCustomClassDay(params?: any): Promise<any> {
    params = params || {};
    return axios.post('services/planbook/lesson/deleteCustomClassDay', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static getClassLessons(params?: any): Promise<any> {
    params = params || {};
    return axios.post('getClassLessons', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static moveSequence(params: any) {
    params = params || {};
    return axios.post('services/planbook/lessonBank/moveSequence', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static lessonBank(params: any) {
    params = params || {};
    return axios.post('services/planbook/lessonBank', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static getSearchedLessons(params: any) {
    params = params || {};
    return axios.post('getSearchedLessons', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static uploadCSV(params: any) {
    const formData = new FormData();
    for (const key in params) {
      formData.append(key, params[key]);
    }
    return axios.post('uploadCSV', formData);
  }

  public static deleteLessonBank(params: any) {
    params = params || {};
    return axios.post('services/planbook/lessonBank/delete', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static getStudentClassLessons(params?: any) {
    params = params || {};
    return axios.get('services/common/student/getClassLessons', { params, withCredentials: !(CurrentUser.isStudent && CurrentUser.id === 0) });
  }

  public static getViewClassLessons(params?: any) {
    params = params || {};
    params = { apiKey: '2e6c6353-3891-4e8a-b579-f6ef074dea34', ...params }
    return axios.get('services/api/getClassLessons', { params, withCredentials: !(CurrentUser.isStudent && CurrentUser.id === 0) });
  }

  public static async paste(params?: any): Promise<any> {
    params = params || {};
    const shouldPasteSource = params.pasteSource;

    if (shouldPasteSource) {
      const linkParams = {
        lessonAddendums: [{
          extraLessonId: params.extraLesson,
          lessonDate: params.customDate,
          subjectId: params.classId,
          type: 'LINKEDLESSON',
          typeId: params.linkedLessonId,
          lessonId: params.lessonId
        }]
      };
      const linkSource = await axios.post('services/planbook/lesson/getLinkSource', linkParams, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (linkSource) {
        for (const key in linkSource.data) {
          params[key] = linkSource.data[key];
        }
      }
      params.linkedLessonId = 0;
    }

    let resp = await axios.post('updateLesson', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });

    let data = resp ? resp.data : null;
    if (!data || data.notLoggedIn === 'true' || data.notLoggedIn === true || data.error === 'true' || data.error === true || !params.linkedLessonId || params.linkedLessonId === 0 || shouldPasteSource) {
      return resp;
    } else {
      const linkParams = {
        lessonAddendums: [{
          extraLessonId: params.extraLesson,
          lessonDate: params.customDate,
          subjectId: params.classId,
          type: 'LINKEDLESSON',
          typeId: params.linkedLessonId
        }]
      };
      resp = await axios.post('services/planbook/lesson/link', linkParams, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      data = resp ? resp.data : null;
      if (!data || data.notLoggedIn === 'true' || data.notLoggedIn === true || data.error === 'true' || data.error === true) {
        return resp;
      } else {
        resp.data.linkedCount = 1
        return resp;
      }
    }
  }

  public static async bump(params?: any): Promise<any> {
    params = params || {};
    let r = null;
    if (params.verify) {
      r = await axios.post('services/planbook/lesson/verifyBumpLesson', qs.stringify(params, { arrayFormat: 'repeat' }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
    }
    const d = r ? r.data : null;
    if (d && (d.notLoggedIn === 'true' || d.notLoggedIn === true || d.error === 'true' || d.error === true || (d.lessonDates && d.lessonDates.length > 0))) {
      return r;
    } else {
      return axios.post('bumpLesson', qs.stringify(params, { arrayFormat: 'repeat' }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
    }
  }

  public static extendStandards(params?: any): Promise<any> {
    params = params || {};
    return axios.post('extendStandards', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static extend(params?: any): Promise<any> {
    params = params || {};
    return axios.post('extendLesson', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static delete(params?: any): Promise<any> {
    params = params || {};
    return axios.post('deleteLesson', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static undoDelete(params?: any): Promise<any> {
    params = params || {};
    return axios.post('undoDeleteLesson', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static getCycleSchedule(params?: any) {
    params = params || {};
    return axios.get('services/planbook/lesson/getCycleSchedule', { params });
  }

  public static getCycleLessonsEvents(params?: any) {
    params = params || {};
    return axios.post('services/planbook/lesson/getCycleLessonsEvents', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static removeClassDay(params?: any): Promise<any> {
    params = params || {};
    return axios.post('removeClassDay', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static move(params?: any): Promise<any> {
    params = params || {};
    return axios.post('moveLesson', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static swap(params?: any): Promise<any> {
    params = params || {};
    return axios.post('services/planbook/lesson/swapLesson', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static link(params?: any): Promise<any> {
    params = params || {};
    return axios.post('services/planbook/lesson/doLink', params, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  public static unlink(params?: any): Promise<any> {
    params = params || {};
    return axios.post('services/planbook/lesson/doUnlink', params, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  public static async getLesson(params?: any): Promise<any> {
    params = params || {};
    return await axios.post('services/planbook/lesson/getDays', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static async getClassesLessons(params?: any): Promise<any> {
    params = params || {};
    return await axios.post('services/planbook/lesson/getClassesLessons', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static search(params: any) {
    params = params || {};
    return axios.post('services/planbook/lesson/search', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static deleteLessons(params: any) {
    params = params || {};
    return axios.post('services/planbook/lesson/delete', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static doUnlink(params: any) {
    params = params || {};
    return axios.post('services/planbook/lesson/doUnlink', params, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  public static importLessons(params?: any) {
    params = params || {};
    return axios.get('importLessons', { params });
  }

  public static hasFullPermissions(settings: any) {
    if (settings) {
      for (const i in settings) {
        if (i.indexOf('permissions') > -1 && settings[i] !== 'write') {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  public static createDays(rawDays: Array<any>, settings: any, userMode: any) {
    const days: any = {};
    CommonUtils.forEach(rawDays, (rawDay, i) => {
      const dayKey = 'day' + (i + 1);
      days[dayKey] = LessonServices.createDay(rawDay, dayKey, {
        settings: settings,
        lessonSections: LessonServices.createLessonSections(settings),
        eventSections: LessonServices.createEventSections(settings),
        googleClassSections: LessonServices.createGoogleClassroomSections(),
        lessonHeadings: settings.displaySettings.lessonHeadings,
        statuses: settings.accountSettings.statuses,
        eventStyling: settings.displaySettings.eventStyling,
        otherSettings: settings.otherSettings,
        userMode: userMode,
        sharedClasses: settings.sharingOptionsSettings.sharedClasses,
        viewSettings: settings.viewSettings,
        stickerStyling: settings.displaySettings.stickerStyling
      });
    });
    return days;
  }

  public static createDay(rawDay: any, dayKey: string, params: any, sharedLayout?: any) {
    const settings = ld.cloneDeep(params.settings || CurrentUser.settings);
    const currentLayoutId = settings?.displaySettings?.lessonSections?.currentLessonLayoutId;
    settings.displaySettings.lessonSections = LessonServices.getLessonSectionsByLayoutId(currentLayoutId, settings);
    settings.displaySettings.lessonStyling = LessonServices.getLessonStylingByLayoutId(currentLayoutId, settings);
    let baseLessonSections = LessonServices.createLessonSections(sharedLayout || settings)
    let eventSections = CommonUtils.getOrDefaultTo(params.eventSections, LessonServices.createEventSections(settings));
    let lessonHeadings = CommonUtils.getOrDefaultTo(params.lessonHeadings, settings.displaySettings.lessonHeadings);
    let eventStyling = CommonUtils.getOrDefaultTo(params.eventStyling, settings.displaySettings.eventStyling);
    const defaultLayoutLessonSections = baseLessonSections;
    const stickerStyling = CommonUtils.getOrDefaultTo(params.stickerStyling, settings.displaySettings.stickerStyling);
    const googleClassSections = CommonUtils.getOrDefaultTo(params.googleClassSections, LessonServices.createGoogleClassroomSections());
    const statuses = CommonUtils.getOrDefaultTo(params.statuses, settings.accountSettings.statuses);
    const otherSettings = CommonUtils.getOrDefaultTo(params.otherSettings, settings.otherSettings);
    const sharedClasses = CommonUtils.getOrDefaultTo(params.sharedClasses, settings.sharingOptionsSettings.sharedClasses);
    const viewSettings = CommonUtils.getOrDefaultTo(params.viewSettings, settings.viewSettings);
    const hideClassIds = CommonUtils.getOrDefaultTo(viewSettings.hideClassIds, []);
    const grouping = LessonServices.getGrouping(otherSettings);
    const getSettingsByUserId = store.getters['settings/getSettingsByUserId'];
    const currentTeacherId = store.state.settings.currentTeacherId;
    const userMode = params.userMode ? params.userMode : 'T';
    const userId = settings.userId;
    const studentId = store.state.settings.studentId;
    const day: any = {};
    day.date = rawDay.date;
    day.cycleDay = CommonUtils.getOrDefaultTo(rawDay.cD, '');
    day.dayOfWeek = rawDay.dayOfWeek;
    day.noSchool = rawDay.count < 0 ? 'Y' : 'N';
    day.dayCount = rawDay.count;
    day.totalCount = rawDay.total;
    day.gradingPeriodCount = rawDay.gradingPeriodCount;
    day.weekCount = rawDay.weekCount;
    day.weekTotal = rawDay.weekTotal;
    day.dayObjects = [];
    const rawDayObjects = rawDay.objects.filter((r: any) => {
      if (r.type === 'L') {
        if (params.copyList) {
          return true;
        } else if (userMode === 'S') {
          return true;
        } if (hideClassIds.includes('' + r.classId) || hideClassIds.includes(+r.classId)) {
          return false;
        } else if (!params.showAllLessons && !LessonServices.displayDayObjectWithStatus(r, settings)) {
          return false;
        }
      } else if (r.type === 'E') {
        if (CommonUtils.hasText(r.googleId)) {
          return LessonServices.isDisplayLessonSection('googleEvents', viewSettings);
        } else {
          return LessonServices.isDisplayLessonSection('events', viewSettings);
        }
      } else if (r.type === 'GC') {
        return (!hideClassIds.includes('' + r.classId) && !hideClassIds.includes(+r.classId));
      }
      return true;
    });

    CommonUtils.forEach(rawDayObjects, (rawDayObject) => {
      baseLessonSections = defaultLayoutLessonSections;
      let currentObjectSettings = null;
      if (userMode === 'S' && (studentId > 0 || currentTeacherId > 0)) {
        currentObjectSettings = ld.cloneDeep(getSettingsByUserId((studentId > 0) ? rawDayObject.teacherId > 0 ? rawDayObject.teacherId : currentTeacherId : 0));
        if (settings) {
          try {
            const currentLayouts: Array<any> = currentObjectSettings.displaySettings.lessonSections.lessonLayouts || [];
            const paramLayouts: Array<any> = settings.displaySettings.lessonSections.lessonLayouts || [];
            const filteredParamLayouts = paramLayouts.filter(l => !rawDayObject.teacherId || l.districtId > 0 || l.schoolId > 0 || l.teacherId === rawDayObject.teacherId);
            ld.set(currentObjectSettings, 'displaySettings.lessonSections.lessonLayouts', filteredParamLayouts.concat(currentLayouts));
          } catch (e) {
            console.error('Unable to setup custom layouts');
          }
        }
        if (currentObjectSettings) {
          currentObjectSettings.viewSettings = viewSettings;
          baseLessonSections = LessonServices.createLessonSections(currentObjectSettings);
          eventSections = LessonServices.createEventSections(currentObjectSettings);
          eventStyling = currentObjectSettings.displaySettings.eventStyling;
          lessonHeadings = currentObjectSettings.displaySettings.lessonHeadings;
        }
      }
      if (rawDayObject.layoutId !== 0 && !sharedLayout) {
        const newSettings = ld.cloneDeep(currentObjectSettings || settings);
        newSettings.displaySettings.lessonSections = LessonServices.getLessonSectionsByLayoutId(rawDayObject.layoutId, newSettings);
        newSettings.displaySettings.lessonStyling = LessonServices.getLessonStylingByLayoutId(rawDayObject.layoutId, newSettings);
        baseLessonSections = LessonServices.createLessonSections(newSettings);
      }
      const date = rawDay.date || rawDayObject.date;
      rawDayObject.day = dayKey || '';
      rawDayObject.date = date;
      rawDayObject.dayOfWeek = rawDay.dayOfWeek || rawDayObject.dayOfWeek;
      const type = rawDayObject.type;
      let dayObject: any = {};
      if (type === 'L') {
        if (+grouping.value === 1) {
          dayObject = { sections: ld.cloneDeep(baseLessonSections) };
        } else {
          dayObject = LessonServices.createGroupedSectionsDayObject(rawDayObject, dayObject, grouping, baseLessonSections);
        }
        dayObject.schoolId = rawDayObject.schoolId;
        dayObject.teacherId = rawDayObject.teacherId;
        dayObject.date = date;
        dayObject.extraLesson = rawDayObject.extraLesson;
        dayObject.name = rawDayObject.className;
        dayObject.time = LessonServices.getTimeText(rawDayObject);
        dayObject.color = CommonUtils.getColor(rawDayObject.color);
        dayObject.showLock = rawDayObject.lessonLock;
        dayObject.showTemplates = LessonServices.isDisplayLessonSection('templates', viewSettings);
        dayObject.style = {
          backgroundColor: CommonUtils.getColor(rawDayObject.color),
          borderBottomColor: CommonUtils.getColor(rawDayObject.color),
          color: CommonUtils.getColor(rawDayObject.titleColor),
          textAlign: lessonHeadings.classHeadCentered === 'Y' ? 'center' : 'left',
          fontFamily: CommonUtils.getFontFamily(rawDayObject.titleFont),
          fontSize: rawDayObject.titleSize + 'pt',
          textDecoration: rawDayObject.classLabelUnderline === 'Y' ? 'underline' : '',
          fontWeight: rawDayObject.classLabelBold === 'Y' ? 'bold' : 'normal',
          fontStyle: rawDayObject.classLabelItalic === 'Y' ? 'italic' : 'normal'
        };
        if (+grouping.value === 1) {
          LessonServices.createDefaultSections(rawDayObject, dayObject, statuses, grouping, userMode, userId, sharedClasses, viewSettings, settings);
        } else {
          LessonServices.createGroupedSections(rawDayObject, dayObject, statuses, userMode, userId, sharedClasses, viewSettings, settings);
        }
      } else if (type === 'E') {
        dayObject = { sections: ld.cloneDeep(eventSections) };
        dayObject.schoolId = rawDayObject.schoolId;
        dayObject.teacherId = rawDayObject.teacherId;
        dayObject.date = date;
        dayObject.showGoogle = rawDayObject.googleId && rawDayObject.googleId !== '' ? 'Y' : 'N';
        dayObject.showActions = true;
        dayObject.showTimes = LessonServices.isDisplayLessonSection('times', viewSettings);
        dayObject.name = rawDayObject.eventTitle !== '' ? rawDayObject.eventTitle : 'Event';
        dayObject.time = LessonServices.getTimeText(rawDayObject);
        let labelStyle = null;
        if (rawDayObject.titleFillColor) {
          labelStyle = {
            backgroundColor: CommonUtils.getColor(rawDayObject.titleFillColor),
            borderBottomColor: CommonUtils.getColor(rawDayObject.titleFillColor),
            color: CommonUtils.getColor(rawDayObject.titleColor),
            textAlign: lessonHeadings.classHeadCentered === 'Y' ? 'center' : 'left',
            fontFamily: CommonUtils.getFontFamily(rawDayObject.titleFont),
            textDecoration: rawDayObject.titleUnderline === 'Y' ? 'underline' : '',
            fontStyle: rawDayObject.titleItalic === 'Y' ? 'italic' : 'normal',
            fontSize: rawDayObject.titleSize + 'pt',
            fontWeight: rawDayObject.titleBold === 'Y' ? 'bold' : 'normal'
          };
        } else {
          if (rawDayObject.stickerId > 0) {
            labelStyle = {
              backgroundColor: CommonUtils.getColor(stickerStyling.eventStickerLabelFillColor),
              borderBottomColor: CommonUtils.getColor(stickerStyling.eventStickerLabelFillColor),
              color: CommonUtils.getColor(stickerStyling.eventStickerLabelColor),
              textAlign: lessonHeadings.classHeadCentered === 'Y' ? 'center' : 'left',
              fontFamily: CommonUtils.getFontFamily(stickerStyling.eventStickerLabelFont),
              textDecoration: stickerStyling.eventStickerLabelUnderline === 'Y' ? 'underline' : '',
              fontStyle: stickerStyling.eventStickerLabelItalic === 'Y' ? 'italic' : 'normal',
              fontSize: stickerStyling.eventStickerLabelSize + 'pt',
              fontWeight: stickerStyling.eventStickerLabelBold === 'Y' ? 'bold' : 'normal'
            };
          } else {
            labelStyle = {
              backgroundColor: CommonUtils.getColor(eventStyling.eventLabelFillColor),
              borderBottomColor: CommonUtils.getColor(rawDayObject.eventLabelFillColor),
              color: CommonUtils.getColor(eventStyling.eventLabelColor),
              textAlign: lessonHeadings.classHeadCentered === 'Y' ? 'center' : 'left',
              fontFamily: CommonUtils.getFontFamily(eventStyling.eventLabelFont),
              textDecoration: eventStyling.eventLabelUnderline === 'Y' ? 'underline' : '',
              fontStyle: eventStyling.eventLabelItalic === 'Y' ? 'italic' : 'normal',
              fontSize: eventStyling.eventLabelSize + 'pt',
              fontWeight: eventStyling.eventLabelBold === 'Y' ? 'bold' : 'normal'
            };
          }
        }
        if (dayObject.showGoogle === 'Y') {
          labelStyle.backgroundColor = CommonUtils.getColor(rawDayObject.eventColor);
          labelStyle.color = CommonUtils.getColor('#FFFFFF');
        }
        dayObject.color = labelStyle.backgroundColor;
        dayObject.style = labelStyle;
        let section = LessonServices.extractLessonSection(dayObject, 'eventText');
        section.text = rawDayObject.eventText;
        let textStyle = null;
        if (rawDayObject.bodyFillColor) {
          textStyle = {
            backgroundColor: CommonUtils.getColor(rawDayObject.bodyFillColor),
            color: CommonUtils.getColor(rawDayObject.bodyColor),
            fontFamily: CommonUtils.getFontFamily(rawDayObject.bodyFont),
            textDecoration: rawDayObject.bodyUnderline === 'Y' ? 'underline' : '',
            fontStyle: rawDayObject.bodyItalic === 'Y' ? 'italic' : 'normal',
            fontSize: rawDayObject.bodySize + 'pt',
            fontWeight: rawDayObject.bodyBold === 'Y' ? 'bold' : 'normal'
          };
        } else {
          if (rawDayObject.stickerId > 0) {
            textStyle = {
              backgroundColor: CommonUtils.getColor(stickerStyling.eventStickerTextFillColor),
              color: CommonUtils.getColor(stickerStyling.eventStickerTextColor),
              fontFamily: CommonUtils.getFontFamily(stickerStyling.eventStickerTextFont),
              textDecoration: stickerStyling.eventStickerTextUnderline === 'Y' ? 'underline' : '',
              fontStyle: stickerStyling.eventStickerTextItalic === 'Y' ? 'italic' : 'normal',
              fontSize: stickerStyling.eventStickerTextSize + 'pt',
              fontWeight: stickerStyling.eventStickerTextBold === 'Y' ? 'bold' : 'normal'
            };
          } else {
            textStyle = {
              backgroundColor: CommonUtils.getColor(eventStyling.eventTextFillColor),
              color: CommonUtils.getColor(eventStyling.eventTextColor),
              fontFamily: CommonUtils.getFontFamily(eventStyling.eventTextFont),
              textDecoration: eventStyling.eventTextUnderline === 'Y' ? 'underline' : '',
              fontStyle: eventStyling.eventTextItalic === 'Y' ? 'italic' : 'normal',
              fontSize: eventStyling.eventTextSize + 'pt',
              fontWeight: eventStyling.eventTextBold === 'Y' ? 'bold' : 'normal'
            };
          }
        }
        section.textStyle = textStyle;
        section = LessonServices.extractLessonSection(dayObject, 'attachments');
        section.items = [];
        CommonUtils.forEach(rawDayObject.attachments, attachment => {
          section.items.push({
            name: attachment.name || attachment.filename,
            isPrivate: CommonUtils.isTrue(attachment.privateFlag),
            url: attachment.url || attachment.attachmentUrl
          });
        });
        dayObject.readonly = rawDayObject.readonly;
      } else {
        dayObject = { sections: ld.cloneDeep(googleClassSections) };
        dayObject.date = date;
        dayObject.showGoogle = 'Y';
        dayObject.showActions = true;
        dayObject.time = LessonServices.getTimeText(rawDayObject);
        dayObject.showTimes = LessonServices.isDisplayLessonSection('times', viewSettings);
        dayObject.color = CommonUtils.getColor(rawDayObject.color);
        dayObject.name = CommonUtils.getOrDefaultTo(rawDayObject.className, '') + ' ' + CommonUtils.getOrDefaultTo(rawDayObject.courseSection, '');
        const cwType = rawDayObject.courseWorkType !== 'ASSIGNMENT' ? 'Question: ' : 'Assignment: ';
        let section = LessonServices.extractLessonSection(dayObject, 'text');
        section.label = cwType + rawDayObject.courseTitle;
        section.text = rawDayObject.courseDescription;
        section.textType = 'GC';
        dayObject.style = {
          backgroundColor: CommonUtils.getColor(rawDayObject.color),
          borderBottomColor: CommonUtils.getColor(rawDayObject.color),
          color: CommonUtils.getColor(rawDayObject.titleColor),
          textAlign: lessonHeadings.classHeadCentered === 'Y' ? 'center' : 'left',
          fontFamily: CommonUtils.getFontFamily(rawDayObject.titleFont),
          fontSize: rawDayObject.titleSize + 'pt',
          textDecoration: rawDayObject.classLabelUnderline === 'Y' ? 'underline' : '',
          fontWeight: rawDayObject.classLabelBold === 'Y' ? 'bold' : 'normal',
          fontStyle: rawDayObject.classLabelItalic === 'Y' ? 'italic' : 'normal'
        };
        section = LessonServices.extractLessonSection(dayObject, 'multipleChoice');
        section.items = [];
        CommonUtils.forEach(rawDayObject.courseWorkChoices, choice => {
          section.items.push({
            name: choice
          });
        });

        section = LessonServices.extractLessonSection(dayObject, 'materials');
        section.items = [];
        CommonUtils.forEach(rawDayObject.courseAttachments, attachment => {
          section.items.push({
            name: attachment.name || attachment.filename,
            isPrivate: CommonUtils.isTrue(attachment.privateFlag),
            url: attachment.url || attachment.attachmentUrl
          });
        });
      }
      dayObject.type = type;
      dayObject.userMode = userMode;
      dayObject.rawDayObject = rawDayObject;
      dayObject.dayObjectId = CommonUtils.generateUUID();
      day.dayObjects.push(dayObject);
    });
    return day;
  }

  private static createGroupedSectionsDayObject(rawDayObject: any, dayObject: any, grouping: any, baseLessonSections: any) {
    const unit = rawDayObject.unit;
    const linkedUnit = rawDayObject.linkedUnit;
    const linkedLessonId = rawDayObject.linkedLessonId;
    const hasLesson = LessonServices.hasContent(rawDayObject);
    const count = LessonServices.countActiveSections(hasLesson, linkedLessonId, unit, linkedUnit);

    let sections: Array<any> = [{
      section: 'statuses',
      type: 'section-statuses',
      displayOrder: '0',
      hide: 'N'
    }];
    if (hasLesson) {
      if (count > 1) {
        sections.push({
          name: 'Lesson',
          type: 'section-group',
          dataType: 'lesson'
        });
      }
      sections = sections.concat(LessonServices.applyDataType(ld.cloneDeep(baseLessonSections), 'lesson'));
    }
    if (linkedLessonId) {
      if (count > 1) {
        sections.push({
          name: 'Linked Lesson',
          type: 'section-group',
          dataType: 'linkedLesson'
        });
      }
      sections = sections.concat(LessonServices.applyDataType(ld.cloneDeep(baseLessonSections), 'linkedLesson'));
    }
    if (unit) {
      if (count > 1) {
        sections.push({
          name: 'Unit',
          type: 'section-group',
          dataType: 'unit'
        });
      }
      sections = sections.concat(LessonServices.applyDataType(ld.cloneDeep(baseLessonSections), 'unit'));
    }
    if (linkedUnit) {
      if (count > 1) {
        sections.push({
          name: 'Linked Unit',
          type: 'section-group',
          dataType: 'linkedUnit'
        });
      }
      sections = sections.concat(LessonServices.applyDataType(ld.cloneDeep(baseLessonSections), 'linkedUnit'));
    }
    sections.sort((a, b) => {
      const aOrder = a.dataType ? +grouping.grouped[a.dataType] : 0;
      const bOrder = b.dataType ? +grouping.grouped[b.dataType] : 0;
      return aOrder - bOrder;
    })
    dayObject = { sections };
    return dayObject;
  }

  public static createLessonSections(settings: any) {
    if (!settings || !settings.displaySettings || !settings.displaySettings.lessonSections) {
      return {};
    }
    const viewSettings = settings.viewSettings;
    const oLessonSections = [{
      section: 'statuses',
      type: 'section-statuses',
      displayOrder: '0',
      hide: 'N'
    }];
    const lessonSections = settings.displaySettings.lessonSections;
    for (const key in lessonSections) {
      if (!CommonUtils.isIn(key, ['lessonLayouts', 'currentLessonLayoutId'])) {
        const lessonSection = lessonSections[key];
        const oLessonSection: any = {};
        oLessonSection.section = key;
        oLessonSection.displayOrder = lessonSection[key + 'DisplayOrder'];
        oLessonSection.hide = lessonSection[key + 'Enabled'] === 'Y' && LessonServices.isDisplayLessonSection(key, viewSettings) === true ? 'N' : 'Y';
        oLessonSection.hideItemId = (lessonSection[key + 'Enabled'] === 'Y' && (LessonServices.isDisplayLessonSection(key, viewSettings) || LessonServices.isDisplayLessonSection('standardsId', viewSettings))) ? 'N' : 'Y';
        oLessonSection.hideItemName = (lessonSection[key + 'Enabled'] === 'Y' && (LessonServices.isDisplayLessonSection(key, viewSettings) || LessonServices.isDisplayLessonSection('standardsDesc', viewSettings))) ? 'N' : 'Y';
        oLessonSection.showTag = LessonServices.isDisplayLessonSection('sectionTags', viewSettings);
        oLessonSection.hideLabel = lessonSection[key + 'Hide'];
        oLessonSection.type = lessonSection[key + 'Type'];
        oLessonSection.label = lessonSection[key + 'Label'];
        oLessonSection.labelStyle = {
          backgroundColor: CommonUtils.getColor(lessonSection[key + 'LabelFillColor']),
          color: CommonUtils.getColor(lessonSection[key + 'Color']),
          fontFamily: CommonUtils.getFontFamily(lessonSection[key + 'Font']),
          textDecoration: lessonSection[key + 'LabelUnderline'] === 'Y' ? 'underline' : '',
          fontStyle: lessonSection[key + 'LabelItalic'] === 'Y' ? 'italic' : 'normal',
          fontSize: lessonSection[key + 'Size'] + 'pt',
          fontWeight: lessonSection[key + 'LabelBold'] === 'Y' ? 'bold' : 'normal'
        };
        oLessonSection.textStyle = {
          backgroundColor: CommonUtils.getColor(lessonSection[key + 'TextFillColor']),
          color: CommonUtils.getColor(lessonSection[key + 'TextColor']),
          fontFamily: CommonUtils.getFontFamily(lessonSection[key + 'TextFont']),
          textDecoration: lessonSection[key + 'TextUnderline'] === 'Y' ? 'underline' : '',
          fontStyle: lessonSection[key + 'TextItalic'] === 'Y' ? 'italic' : 'normal',
          fontSize: lessonSection[key + 'TextSize'] + 'pt',
          fontWeight: lessonSection[key + 'TextBold'] === 'Y' ? 'bold' : 'normal'
        };
        oLessonSections.push(oLessonSection);
        if (key === 'sharedStandards') {
          const lsc = ld.cloneDeep(oLessonSection);
          lsc.subDisplayOrder = 1;
          lsc.section = 'unitStandards';
          oLessonSections.push(lsc);
        }
      }
    }
    const lessonStylings = settings.displaySettings.lessonStyling;
    for (const key in lessonStylings) {
      const lessonStyling = lessonStylings[key];
      if (!lessonStyling) continue;
      const oLessonSection = LessonServices.createLessonStylingSection(key, lessonStyling, viewSettings);
      oLessonSections.push(oLessonSection);
      if (key === 'lessonTitle') {
        oLessonSection.displayOrder = 0;
        oLessonSection.subDisplayOrder = 0;
        oLessonSection.textStyle = oLessonSection.labelStyle;
        oLessonSection.showTag = LessonServices.isDisplayLessonSection('sectionTags', viewSettings);
        delete oLessonSection.labelStyle;
      } else if (key === 'assignments') {
        oLessonSection.displayOrder = oLessonSections.length;
        const lsc = ld.cloneDeep(oLessonSection);
        lsc.subDisplayOrder = 1;
        lsc.section = 'googleAssignments';
        lsc.label = 'Google Assignments';
        oLessonSections.push(lsc);
      } else if (key === 'assessments') {
        oLessonSection.displayOrder = oLessonSections.length;
        const lsc = ld.cloneDeep(oLessonSection);
        lsc.subDisplayOrder = 1;
        lsc.section = 'googleAssessments';
        lsc.label = 'Google Assessments';
        oLessonSections.push(lsc);
      } else if (key === 'attachments') {
        oLessonSection.displayOrder = oLessonSections.length;
        const lsc = ld.cloneDeep(oLessonSection);
        lsc.subDisplayOrder = 1;
        oLessonSection.showTag = LessonServices.isDisplayLessonSection('sectionTags', viewSettings);
        lsc.section = 'googleAttachments';
        lsc.label = 'Google Attachments';
        oLessonSections.push(lsc);
      }
    }
    oLessonSections.sort((a: any, b: any) => {
      if ((+a.displayOrder || 0) === (+b.displayOrder || 0)) {
        return +a.subDisplayOrder - +b.subDisplayOrder;
      } else {
        return (+a.displayOrder || 0) - (+b.displayOrder || 0);
      }
    });
    return oLessonSections;
  }

  public static createEventSections(settings: any) {
    const viewSettings = settings.viewSettings;
    const eventStyling = settings.displaySettings.eventStyling;
    const oEventSections = [
      {
        type: 'section-text',
        section: 'eventText',
        hideLabel: 'Y',
        hide: 'N',
        textStyle: {
          backgroundColor: CommonUtils.getColor(eventStyling.eventTextFillColor),
          color: CommonUtils.getColor(eventStyling.eventTextColor),
          fontFamily: CommonUtils.getFontFamily(eventStyling.eventTextFont),
          textDecoration: eventStyling.eventTextUnderline === 'Y' ? 'underline' : '',
          fontStyle: eventStyling.eventTextItalic === 'Y' ? 'italic' : 'normal',
          fontSize: eventStyling.eventTextSize + 'pt',
          fontWeight: eventStyling.eventTextBold === 'Y' ? 'bold' : 'normal'
        }
      },
      LessonServices.createLessonStylingSection('attachments', settings.displaySettings.lessonStyling.attachments, viewSettings)
    ];
    return oEventSections;
  }

  public static createGoogleClassroomSections() {
    const oEventSections = [
      {
        type: 'section-text',
        section: 'text'
      },
      {
        type: 'section-actions',
        label: 'Multiple choice',
        section: 'multipleChoice'
      },
      {
        type: 'section-attachments',
        label: 'Materials',
        section: 'materials'
      }
    ];
    return oEventSections;
  }

  public static getLessonSectionsByLayoutId(layoutId: number, settings?: any) {
    const lessonSections = LessonServices.extractLessonSections(settings) || LessonServices.extractLessonSections(store.getters['settings/getUserInfo']);
    const layouts: Array<any> = lessonSections.lessonLayouts || store.getters['settings/getLessonLayouts'];
    const layout = LessonServices.getLayout(layoutId, layouts, lessonSections);
    if (layout) {
      lessonSections.currentLessonLayoutId = layout.lessonLayoutId;
      for (const key in lessonSectionsMapping) {
        const mappedKey = lessonSectionsMapping[key];
        lessonSections[key][key + 'DisplaySettingsId'] = layout[mappedKey + 'DisplaySettingsId'];
        lessonSections[key][key + 'Hide'] = layout[mappedKey + 'Hide'];
        lessonSections[key][key + 'Enabled'] = layout[mappedKey + 'Enabled'];
        lessonSections[key][key + 'DisplayOrder'] = layout[mappedKey + 'DisplayOrder'];
        lessonSections[key][key + 'Color'] = layout[mappedKey + 'Color'] || lessonSections[key][key + 'Color'];
        lessonSections[key][key + 'Font'] = layout[mappedKey + 'Font'] || lessonSections[key][key + 'Font'];
        lessonSections[key][key + 'Label'] = layout[mappedKey + 'Label'] || lessonSections[key][key + 'Label'];
        lessonSections[key][key + 'LabelBold'] = layout[mappedKey + 'LabelBold'] || lessonSections[key][key + 'LabelBold'];
        lessonSections[key][key + 'LabelFillColor'] = layout[mappedKey + 'LabelFillColor'] || lessonSections[key][key + 'LabelFillColor'];
        lessonSections[key][key + 'LabelItalic'] = layout[mappedKey + 'LabelItalic'] || lessonSections[key][key + 'LabelItalic'];
        lessonSections[key][key + 'LabelUnderline'] = layout[mappedKey + 'LabelUnderline'] || lessonSections[key][key + 'LabelUnderline'];
        lessonSections[key][key + 'Size'] = layout[mappedKey + 'Size'] || lessonSections[key][key + 'Size'];
        lessonSections[key][key + 'TextBold'] = layout[mappedKey + 'TextBold'] || lessonSections[key][key + 'TextBold'];
        lessonSections[key][key + 'TextColor'] = layout[mappedKey + 'TextColor'] || lessonSections[key][key + 'TextColor'];
        lessonSections[key][key + 'TextFillColor'] = layout[mappedKey + 'TextFillColor'] || lessonSections[key][key + 'TextFillColor'];
        lessonSections[key][key + 'TextFont'] = layout[mappedKey + 'TextFont'] || lessonSections[key][key + 'TextFont'];
        lessonSections[key][key + 'TextItalic'] = layout[mappedKey + 'TextItalic'] || lessonSections[key][key + 'TextItalic'];
        lessonSections[key][key + 'TextSize'] = layout[mappedKey + 'TextSize'] || lessonSections[key][key + 'TextSize'];
        lessonSections[key][key + 'TextUnderline'] = layout[mappedKey + 'TextUnderline'] || lessonSections[key][key + 'TextUnderline'];
      }
    }
    return lessonSections;
  }

  private static getLayout(layoutId: number, layouts: Array<any>, lessonSections: any) {
    let currentLayout;
    let defaultLayout;
    let theLayout;
    for (const i in layouts) {
      const layout = layouts[i];
      if (layout.lessonLayoutId === lessonSections?.currentLessonLayoutId) {
        currentLayout = layout;
      }
      if (layout.lessonLayoutId === -1) {
        defaultLayout = layout;
      }
      if (layout.lessonLayoutId === layoutId) {
        theLayout = layout;
      }
    }
    return theLayout || currentLayout || defaultLayout;
  }

  public static getLessonStylingByLayoutId(layoutId: number, settings?: any) {
    const lessonSections = LessonServices.extractLessonStyling(settings) || LessonServices.extractLessonStyling(store.getters['settings/getUserInfo']);
    const layouts: Array<any> = lessonSections.lessonLayouts || store.getters['settings/getLessonLayouts'] || [];
    const layout = LessonServices.getLayout(layoutId, layouts, lessonSections);
    if (layout) {
      lessonSections.currentLessonLayoutId = layout.lessonLayoutId;
      for (const key in lessonStylingMapping) {
        const mappedKey = lessonStylingMapping[key];
        lessonSections[key][key + 'DisplaySettingsId'] = layout[mappedKey + 'DisplaySettingsId'];
        lessonSections[key][key + 'Hide'] = layout[mappedKey + 'Hide'];
        lessonSections[key][key + 'Enabled'] = layout[mappedKey + 'Enabled'];
        lessonSections[key][key + 'DisplayOrder'] = layout[mappedKey + 'DisplayOrder'];
        lessonSections[key][key + 'Color'] = layout[mappedKey + 'Color'] || lessonSections[key][key + 'Color'];
        lessonSections[key][key + 'Font'] = layout[mappedKey + 'Font'] || lessonSections[key][key + 'Font'];
        lessonSections[key][key + 'Label'] = layout[mappedKey + 'Label'] || lessonSections[key][key + 'Label'];
        lessonSections[key][key + 'LabelBold'] = layout[mappedKey + 'LabelBold'] || lessonSections[key][key + 'LabelBold'];
        lessonSections[key][key + 'LabelFillColor'] = layout[mappedKey + 'LabelFillColor'] || lessonSections[key][key + 'LabelFillColor'];
        lessonSections[key][key + 'LabelItalic'] = layout[mappedKey + 'LabelItalic'] || lessonSections[key][key + 'LabelItalic'];
        lessonSections[key][key + 'LabelUnderline'] = layout[mappedKey + 'LabelUnderline'] || lessonSections[key][key + 'LabelUnderline'];
        lessonSections[key][key + 'Size'] = layout[mappedKey + 'Size'] || lessonSections[key][key + 'Size'];
        lessonSections[key][key + 'TextBold'] = layout[mappedKey + 'TextBold'] || lessonSections[key][key + 'TextBold'];
        lessonSections[key][key + 'TextColor'] = layout[mappedKey + 'TextColor'] || lessonSections[key][key + 'TextColor'];
        lessonSections[key][key + 'TextFillColor'] = layout[mappedKey + 'TextFillColor'] || lessonSections[key][key + 'TextFillColor'];
        lessonSections[key][key + 'TextFont'] = layout[mappedKey + 'TextFont'] || lessonSections[key][key + 'TextFont'];
        lessonSections[key][key + 'TextItalic'] = layout[mappedKey + 'TextItalic'] || lessonSections[key][key + 'TextItalic'];
        lessonSections[key][key + 'TextSize'] = layout[mappedKey + 'TextSize'] || lessonSections[key][key + 'TextSize'];
        lessonSections[key][key + 'TextUnderline'] = layout[mappedKey + 'TextUnderline'] || lessonSections[key][key + 'TextUnderline'];
      }
    }
    return lessonSections;
  }

  public static extractLessonSections(settings?: any) {
    try {
      return ld.cloneDeep(settings.displaySettings.lessonSections);
    } catch (e) {
      return undefined;
    }
  }

  public static extractLessonStyling(settings?: any) {
    try {
      return ld.cloneDeep(settings.displaySettings.lessonStyling);
    } catch (e) {
      return undefined;
    }
  }

  public static isDisplayLessonSection(section: string, viewSettings: any) {
    return !viewSettings || !viewSettings.viewItems || (viewSettings.viewItems[section] && viewSettings.viewItems[section] !== 'N');
  }

  private static createLessonStylingSection(key: string, lessonStyling: any, viewSettings: any) {
    const oLessonSection: any = {};
    oLessonSection.section = key;
    oLessonSection.hide = LessonServices.isDisplayLessonSection(key, viewSettings) === true ? 'N' : 'Y';
    oLessonSection.hideLabel = lessonStyling[key + 'Hide'];
    oLessonSection.type = lessonStyling[key + 'Type'];
    oLessonSection.label = lessonStyling[key + 'Label'];
    oLessonSection.labelStyle = {
      backgroundColor: CommonUtils.getColor(lessonStyling[key + 'LabelFillColor']),
      color: CommonUtils.getColor(lessonStyling[key + 'Color']),
      fontFamily: CommonUtils.getFontFamily(lessonStyling[key + 'Font']),
      textDecoration: lessonStyling[key + 'LabelUnderline'] === 'Y' ? 'underline' : '',
      fontStyle: lessonStyling[key + 'LabelItalic'] === 'Y' ? 'italic' : 'normal',
      fontSize: lessonStyling[key + 'Size'] + 'pt',
      fontWeight: lessonStyling[key + 'LabelBold'] === 'Y' ? 'bold' : 'normal'
    };
    oLessonSection.textStyle = {
      backgroundColor: CommonUtils.getColor(lessonStyling[key + 'TextFillColor']),
      color: CommonUtils.getColor(lessonStyling[key + 'TextColor']),
      fontFamily: CommonUtils.getFontFamily(lessonStyling[key + 'TextFont']),
      textDecoration: lessonStyling[key + 'TextUnderline'] === 'Y' ? 'underline' : '',
      fontStyle: lessonStyling[key + 'TextItalic'] === 'Y' ? 'italic' : 'normal',
      fontSize: lessonStyling[key + 'TextSize'] + 'pt',
      fontWeight: lessonStyling[key + 'TextBold'] === 'Y' ? 'bold' : 'normal'
    };
    return oLessonSection;
  }

  private static getGrouping(otherSettings: any) {
    let grouping = otherSettings.lessonSectionsGrouping;
    if (!grouping) {
      grouping = {};
      grouping.lessonSectionsGroupingValue = 1;
      grouping.defaultGroupingLessonSectionOrder = 1;
      grouping.defaultGroupingLinkedLessonSectionOrder = 2;
      grouping.defaultGroupingUnitSectionOrder = 3;
      grouping.defaultGroupingLinkedUnitSectionOrder = 4;
      grouping.groupedGroupingLessonOrder = 1;
      grouping.groupedGroupingLinkedLessonOrder = 2;
      grouping.groupedGroupingUnitOrder = 3;
      grouping.groupedGroupingLinkedUnitOrder = 4;
    }
    return {
      value: grouping.lessonSectionsGroupingValue,
      default: {
        lesson: grouping.defaultGroupingLessonSectionOrder,
        unit: grouping.defaultGroupingUnitSectionOrder,
        linkedLesson: grouping.defaultGroupingLinkedLessonSectionOrder,
        linkedUnit: grouping.defaultGroupingLinkedUnitSectionOrder
      },
      grouped: {
        lesson: grouping.groupedGroupingLessonOrder,
        unit: grouping.groupedGroupingUnitOrder,
        linkedLesson: grouping.groupedGroupingLinkedLessonOrder,
        linkedUnit: grouping.groupedGroupingLinkedUnitOrder
      }
    }
  }

  private static extractLessonSection(dayObject: any, key: string, type?: string) {
    for (let i = 0; i < dayObject.sections.length; i++) {
      if (dayObject.sections[i].section === key && (!type || type === dayObject.sections[i].dataType)) {
        return dayObject.sections[i];
      }
    }
    return {};
  }

  private static applyDataType(lessonSections: Array<any>, dataType: string) {
    CommonUtils.forEach(lessonSections, (item) => {
      item.dataType = dataType;
    });
    return lessonSections;
  }

  private static getTimeText(rawDayObject: any) {
    let result = '';
    let start = CommonUtils.getOrDefaultTo(rawDayObject.customStart, '');
    start = start !== '' ? start : CommonUtils.getOrDefaultTo(rawDayObject.startTime, '');

    if (start !== '') {
      start = DateTimeUtils.formatTimeToShow(start);
      result += start;
    }

    let end = CommonUtils.getOrDefaultTo(rawDayObject.customEnd, '');
    end = end !== '' ? end : CommonUtils.getOrDefaultTo(rawDayObject.endTime, '');

    if (end !== '') {
      end = DateTimeUtils.formatTimeToShow(end);
      if (result !== '') {
        result += '-';
      }
      result += end;
    }
    result = result.replace(/\s/gi, '');
    result = result.toLowerCase();
    return result;
  }

  private static countActiveSections(hasLesson: boolean, linkedLessonId: number, unit: any, linkedUnit: any) {
    let count = 0;
    count += hasLesson ? 1 : 0;
    count += linkedLessonId ? 1 : 0;
    count += unit ? 1 : 0;
    count += linkedUnit ? 1 : 0;
    return count;
  }

  public static hasContent(rawDayObject: any, checkStatus?: boolean): boolean {
    if (rawDayObject.type === 'L') {
      const allTexts = `<div>
        <div>${rawDayObject.lessonText || ''}</div>
        <div>${rawDayObject.homeworkText || ''}</div>
        <div>${rawDayObject.notesText || ''}</div>
        <div>${rawDayObject.tab4Text || ''}</div>
        <div>${rawDayObject.tab5Text || ''}</div>
        <div>${rawDayObject.tab6Text || ''}</div>
      </div>`;
      return CommonUtils.hasText(rawDayObject.lessonTitle) ||
        CommonUtils.isHTMLNotEmpty(allTexts) ||
        CommonUtils.isNotEmpty(rawDayObject.attachments) ||
        CommonUtils.isNotEmpty(rawDayObject.asgAttachments) ||
        CommonUtils.isNotEmpty(rawDayObject.asmAttachments) ||
        CommonUtils.isNotEmpty(rawDayObject.assessments) ||
        CommonUtils.isNotEmpty(rawDayObject.assignments) ||
        CommonUtils.isNotEmpty(rawDayObject.myStandards) ||
        CommonUtils.isNotEmpty(rawDayObject.schoolStandards) ||
        CommonUtils.isNotEmpty(rawDayObject.standards) ||
        CommonUtils.isNotEmpty(rawDayObject.strategies) ||
        CommonUtils.isNotEmpty(rawDayObject.googleAssessments) ||
        CommonUtils.isNotEmpty(rawDayObject.googleAssignments) ||
        (checkStatus === true && LessonServices.containsStatuses(rawDayObject));
    } else if (rawDayObject.type === 'E') {
      return CommonUtils.isHTMLNotEmpty(rawDayObject.eventText) ||
        CommonUtils.isNotEmpty(rawDayObject.attachments);
    } else {
      return CommonUtils.isHTMLNotEmpty(rawDayObject.courseDescription) ||
        CommonUtils.isNotEmpty(rawDayObject.courseWorkChoices) ||
        CommonUtils.isNotEmpty(rawDayObject.courseAttachments);
    }
  }

  private static containsStatuses(rawDayObject: any) {
    return CommonUtils.isNotEmpty(LessonServices.getStatusIds(rawDayObject));
  }

  private static getStatusIds(rawDayObject: any) {
    const items: Array<any> = [];
    CommonUtils.forEach(rawDayObject.addendums, addendum => {
      if (addendum.type === 'STATUS') {
        items.push(addendum.typeId);
      }
    });
    return items;
  }

  private static getSchoolStatusIds(rawDayObject: any, day: any) {
    const appliedStatuses = store.getters['plans/getWeekSchoolStatus'];
    const items = appliedStatuses.filter((s: any) => {
      if ((s.type === 'L' || s.type === 'D') && DateTimeUtils.daysDiff(day.date, s.startDate) !== 0) {
        return false;
      } else if ((s.type === 'L' || s.type === 'C') && s.subjectId !== rawDayObject.classId) {
        return false;
      } else {
        return true;
      }
    }).map((s: any) => { return s.schoolStatusId })
    return items;
  }

  private static displayDayObjectWithStatus(rawDayObject: any, settings: any) {
    const profile = CommonUtils.getOrDefaultTo(settings?.viewSettings?.statusProfileSettings, {});
    const statusSettings = CommonUtils.getOrDefaultTo(profile.statusSettings, []);
    const statuses = settings?.accountSettings?.statuses || [];
    const viewNoStatus = profile.viewNoStatus;
    const statusIds = LessonServices.getStatusIds(rawDayObject);
    if (CommonUtils.isNotEmpty(statuses)) {
      if (rawDayObject.collaborateSubjectId > 0) {
        return true;
      } else if (CommonUtils.isNotEmpty(statusIds)) {
        for (const i in statusSettings) {
          const ss = statusSettings[i];
          if (statusIds.includes(ss.lessonStatusId) && ss.view) {
            return true;
          }
        }
        return false;
      } else {
        return viewNoStatus;
      }
    }
    return true;
  }

  private static displayDayObjectWithSchoolStatus(rawDayObject: any, viewSettings: any, day: any, schooldId: number) {
    if (schooldId > 0) {
      const profile = CommonUtils.getOrDefaultTo(viewSettings.statusProfileSettings, {});
      const schoolStatusSettings = CommonUtils.getOrDefaultTo(profile.schoolStatusSettings, []);
      const viewNoSchoolStatus = profile.viewNoSchoolStatus;
      const schoolStatusIds = LessonServices.getSchoolStatusIds(rawDayObject, day);
      if (CommonUtils.isNotEmpty(schoolStatusIds)) {
        for (const i in schoolStatusSettings) {
          const ss = schoolStatusSettings[i];
          if (schoolStatusIds.includes(ss.schoolStatusId) && ss.view) {
            return true;
          }
        }
        if (schoolStatusSettings.length === 0) {
          return true;
        }
      } else {
        return viewNoSchoolStatus;
      }
    }
    return true;
  }

  private static createDefaultSections(rawDayObject: any, dayObject: any, statuses: Array<any>, grouping: any, userMode: string, userId: number, sharedClasses: any, viewSettings: any, settings: any) {
    const unit = rawDayObject.unit;
    const linkedUnit = rawDayObject.linkedUnit;
    const linkedLessonId = rawDayObject.linkedLessonId;
    const lessonStatusSettings = settings.displaySettings.lessonStatus || { displayStatusText: true, statusTextFontSize: '10pt' };
    const hasPermission = (key: string) => LessonServices.hasViewPermission(key, userMode, rawDayObject, userId, sharedClasses);
    const isDisplaySection = (key: string) => LessonServices.isDisplayLessonSection(key, viewSettings);
    dayObject.showActions = isDisplaySection('action');
    dayObject.showTimes = isDisplaySection('times');

    let section = LessonServices.extractLessonSection(dayObject, 'statuses');
    section.items = [];
    section.hideItemName = CommonUtils.booleanToString(!lessonStatusSettings.displayStatusText);
    section.labelStyle = {
      fontSize: lessonStatusSettings.statusTextFontSize
    };
    section.textStyle = {
      fontSize: lessonStatusSettings.statusTextFontSize
    }
    CommonUtils.forEach(rawDayObject.addendums, addendum => {
      if (addendum.type === 'STATUS') {
        let visible = true;
        const showLessonStatus = store.getters['settings/getShowLessonStatus'];

        if (Object.prototype.hasOwnProperty.call(showLessonStatus, addendum.typeId)) {
          visible = showLessonStatus[addendum.typeId];
        }

        if (rawDayObject.collaborateSubjectId > 0) {
          const getCollabStatuses = store.getters['classes/getCollabStatuses'];
          const status = getCollabStatuses[rawDayObject.collaborateSubjectId].find((c:any) => c.lessonStatusId === addendum.typeId);
          if (status) {
            section.items.push({
              name: status.statusText,
              color: status.color,
              type: 'teacher',
              visible: visible
            });
          }
        } else {
          const status = LessonServices.extractStatus(addendum.typeId, statuses);
          if (status) {
            section.items.push({
              name: status.statusText,
              color: status.color,
              type: 'teacher',
              id: addendum.typeId,
              visible: visible
            });
          }
        }
      }
    });

    // LESSON TITLE
    section = LessonServices.extractLessonSection(dayObject, 'lessonTitle');
    section.textStyle.borderColor = CommonUtils.getColor(rawDayObject.color);
    section.items = [];
    if (isDisplaySection('lessonTitle')) {
      section.items.push({
        dataType: 'lesson',
        text: rawDayObject.lessonTitle
      });
    }
    if (linkedLessonId && hasPermission('lessonTitle') && isDisplaySection('linkedLessonTitle')) {
      section.hide = 'N';
      section.items.push({
        dataType: 'linkedLesson',
        text: rawDayObject.linkedLessonTitle
      });
    }
    if (unit && (isDisplaySection('unitId') || isDisplaySection('unitTitle'))) {
      section.hide = 'N';
      section.items.push({
        dataType: 'unit',
        label: isDisplaySection('unitId') ? unit.unitNum : '',
        labelBackgroundColor: unit.unitColor || unit.color || vuetify.framework.theme.currentTheme.primary,
        text: isDisplaySection('unitTitle') ? unit.unitTitle : '',
        description: userMode === 'T' ? unit.unitDesc : '',
        unitId: isDisplaySection('unitId') ? unit.unitId : 0
      });
    }
    if (linkedUnit && (isDisplaySection('linkedUnitId') || isDisplaySection('linkedUnitTitle'))) {
      section.hide = 'N';
      section.items.push({
        dataType: 'linkedUnit',
        label: isDisplaySection('linkedUnitId') ? linkedUnit.unitNum : '',
        labelBackgroundColor: linkedUnit.unitColor || CommonUtils.getColor(rawDayObject.color) || vuetify.framework.theme.currentTheme.primary,
        text: hasPermission('lessonTitle') && isDisplaySection('linkedUnitTitle') ? linkedUnit.unitTitle : ''
      });
    }
    const showTemplates = LessonServices.isDisplayLessonSection('templates', viewSettings);
    // LESSON TEXT
    section = LessonServices.extractLessonSection(dayObject, 'lesson');
    section.items = [];
    section.items.push({
      dataType: 'lesson',
      template: rawDayObject.lTemplate === 'Y',
      text: rawDayObject.lessonText
    });
    if (linkedLessonId && hasPermission('lesson') && isDisplaySection('linkedLessonDetails')) {
      section.items.push({
        dataType: 'linkedLesson',
        text: rawDayObject.linkedLessonText
      });
    }
    if (unit && isDisplaySection('unitDetails')) {
      section.items.push({
        dataType: 'unit',
        text: LessonServices.getUnitSectionContent('L', unit)
      });
    }
    if (linkedUnit && hasPermission('lesson') && isDisplaySection('linkedUnitDetails')) {
      section.items.push({
        dataType: 'linkedUnit',
        text: LessonServices.getUnitSectionContent('L', linkedUnit)
      });
    }
    section.items = section.items.filter((item: any) => !item.template || (showTemplates && item.template));
    // TAB 2
    section = LessonServices.extractLessonSection(dayObject, 'tab2');
    section.items = [];
    section.items.push({
      dataType: 'lesson',
      template: rawDayObject.hTemplate === 'Y',
      text: rawDayObject.homeworkText
    });
    if (linkedLessonId && hasPermission('tab2') && isDisplaySection('linkedLessonDetails')) {
      section.items.push({
        dataType: 'linkedLesson',
        text: rawDayObject.linkedHomeworkText
      });
    }
    if (unit && isDisplaySection('unitDetails')) {
      section.items.push({
        dataType: 'unit',
        text: LessonServices.getUnitSectionContent('H', unit)
      });
    }
    if (linkedUnit && hasPermission('tab2') && isDisplaySection('linkedUnitDetails')) {
      section.items.push({
        dataType: 'linkedUnit',
        text: LessonServices.getUnitSectionContent('H', linkedUnit)
      });
    }
    section.items = section.items.filter((item: any) => !item.template || (showTemplates && item.template));
    // TAB 3
    section = LessonServices.extractLessonSection(dayObject, 'tab3');
    section.items = [];
    section.items.push({
      dataType: 'lesson',
      template: rawDayObject.nTemplate === 'Y',
      text: rawDayObject.notesText
    });
    if (linkedLessonId && hasPermission('tab3') && isDisplaySection('linkedLessonDetails')) {
      section.items.push({
        dataType: 'linkedLesson',
        text: rawDayObject.linkedNotesText
      });
    }
    if (unit && isDisplaySection('unitDetails')) {
      section.items.push({
        dataType: 'unit',
        text: LessonServices.getUnitSectionContent('N', unit)
      });
    }
    if (linkedUnit && hasPermission('tab3') && isDisplaySection('linkedUnitDetails')) {
      section.items.push({
        dataType: 'linkedUnit',
        text: LessonServices.getUnitSectionContent('N', linkedUnit)
      });
    }
    section.items = section.items.filter((item: any) => !item.template || (showTemplates && item.template));
    // TAB 4
    section = LessonServices.extractLessonSection(dayObject, 'tab4');
    section.items = [];
    section.items.push({
      dataType: 'lesson',
      template: rawDayObject.t4Template === 'Y',
      text: rawDayObject.tab4Text
    });
    if (linkedLessonId && hasPermission('tab4') && isDisplaySection('linkedLessonDetails')) {
      section.items.push({
        dataType: 'linkedLesson',
        text: rawDayObject.linkedTab4Text
      });
    }
    if (unit && isDisplaySection('unitDetails')) {
      section.items.push({
        dataType: 'unit',
        text: LessonServices.getUnitSectionContent('4', unit)
      });
    }
    if (linkedUnit && hasPermission('tab4') && isDisplaySection('linkedUnitDetails')) {
      section.items.push({
        dataType: 'linkedUnit',
        text: LessonServices.getUnitSectionContent('4', linkedUnit)
      });
    }
    section.items = section.items.filter((item: any) => !item.template || (showTemplates && item.template));
    // TAB 5
    section = LessonServices.extractLessonSection(dayObject, 'tab5');
    section.items = [];
    section.items.push({
      dataType: 'lesson',
      template: rawDayObject.t5Template === 'Y',
      text: rawDayObject.tab5Text
    });
    if (linkedLessonId && hasPermission('tab5') && isDisplaySection('linkedLessonDetails')) {
      section.items.push({
        dataType: 'linkedLesson',
        text: rawDayObject.linkedTab5Text
      });
    }
    if (unit && isDisplaySection('unitDetails')) {
      section.items.push({
        dataType: 'unit',
        text: LessonServices.getUnitSectionContent('5', unit)
      });
    }
    if (linkedUnit && hasPermission('tab5') && isDisplaySection('linkedUnitDetails')) {
      section.items.push({
        dataType: 'linkedUnit',
        text: LessonServices.getUnitSectionContent('5', linkedUnit)
      });
    }
    section.items = section.items.filter((item: any) => !item.template || (showTemplates && item.template));
    // TAB 6
    section = LessonServices.extractLessonSection(dayObject, 'tab6');
    section.items = [];
    section.items.push({
      dataType: 'lesson',
      template: rawDayObject.t6Template === 'Y',
      text: rawDayObject.tab6Text
    });
    if (linkedLessonId && hasPermission('tab6') && isDisplaySection('linkedLessonDetails')) {
      section.items.push({
        dataType: 'linkedLesson',
        text: rawDayObject.linkedTab6Text
      });
    }
    if (unit && isDisplaySection('unitDetails')) {
      section.items.push({
        dataType: 'unit',
        text: LessonServices.getUnitSectionContent('6', unit)
      });
    }
    if (linkedUnit && hasPermission('tab6') && isDisplaySection('linkedUnitDetails')) {
      section.items.push({
        dataType: 'linkedUnit',
        text: LessonServices.getUnitSectionContent('6', linkedUnit)
      });
    }
    section.items = section.items.filter((item: any) => !item.template || (showTemplates && item.template));
    // ATTACHMENTS
    section = LessonServices.extractLessonSection(dayObject, 'attachments');
    section.items = [];
    CommonUtils.forEach(rawDayObject.attachments, attachment => {
      section.items.push({
        dataType: 'lesson',
        template: rawDayObject.aTemplate === 'Y',
        name: attachment.filename || attachment.name,
        isPrivate: CommonUtils.isTrue(attachment.privateFlag),
        url: attachment.url || attachment.attachmentUrl
      });
    });
    CommonUtils.forEach(rawDayObject.asgAttachments, attachment => {
      section.items.push({
        dataType: 'lesson',
        name: attachment.filename || attachment.name,
        isPrivate: CommonUtils.isTrue(attachment.privateFlag),
        url: attachment.url || attachment.attachmentUrl
      });
    });
    CommonUtils.forEach(rawDayObject.asmAttachments, attachment => {
      section.items.push({
        dataType: 'lesson',
        name: attachment.filename || attachment.name,
        isPrivate: CommonUtils.isTrue(attachment.privateFlag),
        url: attachment.url || attachment.attachmentUrl
      });
    });
    if (linkedLessonId && hasPermission('attachments') && isDisplaySection('linkedLessonDetails')) {
      CommonUtils.forEach(rawDayObject.linkedAttachments, attachment => {
        section.items.push({
          dataType: 'linkedLesson',
          name: attachment.filename || attachment.name,
          isPrivate: CommonUtils.isTrue(attachment.privateFlag),
          url: attachment.url || attachment.attachmentUrl
        });
      });
    }
    if (unit && isDisplaySection('unitDetails')) {
      CommonUtils.forEach(unit.attachments, attachment => {
        section.items.push({
          dataType: 'unit',
          name: attachment.filename || attachment.name,
          isPrivate: CommonUtils.isTrue(attachment.privateFlag),
          url: attachment.url || attachment.attachmentUrl
        });
      });
    }
    if (linkedUnit && hasPermission('attachments') && isDisplaySection('linkedUnitDetails')) {
      CommonUtils.forEach(linkedUnit.attachments, attachment => {
        section.items.push({
          dataType: 'linkedUnit',
          name: attachment.filename || attachment.name,
          isPrivate: CommonUtils.isTrue(attachment.privateFlag),
          url: attachment.url || attachment.attachmentUrl
        });
      });
    }
    section = LessonServices.extractLessonSection(dayObject, 'assessments');
    section.items = [];
    section.collaborateSubjectId = rawDayObject.collaborateSubjectId;
    CommonUtils.forEach(rawDayObject.assessments, assessment => {
      section.items.push({
        dataType: 'lesson',
        name: assessment.assessmentTitle,
        id: assessment.assessmentId
      });
    });
    section = LessonServices.extractLessonSection(dayObject, 'assignments');
    section.items = [];
    section.collaborateSubjectId = rawDayObject.collaborateSubjectId;
    CommonUtils.forEach(rawDayObject.assignments, assignment => {
      section.items.push({
        dataType: 'lesson',
        name: assignment.assignmentTitle,
        id: assignment.assignmentId
      });
    });
    section.items = section.items.filter((item: any) => !item.template || (showTemplates && item.template));
    // MY STANDARDS
    section = LessonServices.extractLessonSection(dayObject, 'myStandards');
    section.items = [];
    CommonUtils.forEach(rawDayObject.myStandards, standard => {
      section.items.push({
        dataType: 'lesson',
        template: rawDayObject.msTemplate === 'Y',
        name: standard.desc,
        id: standard.id
      });
    });
    if (linkedLessonId && hasPermission('lesson') && isDisplaySection('linkedLessonDetails')) {
      CommonUtils.forEach(rawDayObject.linkedMyStandards, standard => {
        section.items.push({
          dataType: 'linkedLesson',
          name: standard.desc,
          id: standard.id
        });
      });
    }
    if (unit && isDisplaySection('unitDetails')) {
      CommonUtils.forEach(unit.myStandards, standard => {
        section.items.push({
          dataType: 'unit',
          name: standard.desc,
          id: standard.id
        });
      });
    }
    if (linkedUnit && hasPermission('lesson') && isDisplaySection('linkedUnitDetails')) {
      CommonUtils.forEach(linkedUnit.myStandards, standard => {
        section.items.push({
          dataType: 'linkedUnit',
          name: standard.desc,
          id: standard.id
        });
      });
    }
    section.items = section.items.filter((item: any) => !item.template || (showTemplates && item.template));
    // SCHOOL STANDARDS
    section = LessonServices.extractLessonSection(dayObject, 'schoolStandards');
    section.items = [];
    CommonUtils.forEach(rawDayObject.schoolStandards, standard => {
      section.items.push({
        dataType: 'lesson',
        template: rawDayObject.ssTemplate === 'Y',
        name: standard.desc,
        id: standard.id
      });
    });
    if (linkedLessonId && hasPermission('schoolStandards') && isDisplaySection('linkedLessonDetails')) {
      CommonUtils.forEach(rawDayObject.linkedSchoolStandards, standard => {
        section.items.push({
          dataType: 'linkedLesson',
          name: standard.desc,
          id: standard.id
        });
      });
    }
    if (unit && isDisplaySection('unitDetails')) {
      CommonUtils.forEach(unit.schoolStandards, standard => {
        section.items.push({
          dataType: 'unit',
          name: standard.desc,
          id: standard.id
        });
      });
    }
    if (linkedUnit && hasPermission('schoolStandards') && isDisplaySection('linkedUnitDetails')) {
      CommonUtils.forEach(linkedUnit.schoolStandards, standard => {
        section.items.push({
          dataType: 'linkedUnit',
          name: standard.desc,
          id: standard.id
        });
      });
    }
    section.items = section.items.filter((item: any) => !item.template || (showTemplates && item.template));
    // STANDARDS
    section = LessonServices.extractLessonSection(dayObject, 'sharedStandards');
    section.items = [];
    CommonUtils.forEach(rawDayObject.standards, standard => {
      section.items.push({
        dataType: 'lesson',
        itemType: 'standard',
        template: rawDayObject.sTemplate === 'Y',
        noExpressions: !CommonUtils.isTrue(standard.withMathExpressions),
        name: standard.desc,
        dbId: standard.dbId,
        id: standard.id
      });
    });
    if (linkedLessonId && hasPermission('sharedStandards') && isDisplaySection('linkedLessonDetails')) {
      CommonUtils.forEach(rawDayObject.linkedStandards, standard => {
        section.items.push({
          dataType: 'linkedLesson',
          itemType: 'standard',
          name: standard.desc,
          dbId: standard.dbId,
          id: standard.id
        });
      });
    }
    if (unit && isDisplaySection('unitDetails')) {
      CommonUtils.forEach(unit.standards, standard => {
        section.items.push({
          dataType: 'unit',
          itemType: 'standard',
          name: standard.desc,
          dbId: standard.dbId,
          noExpressions: !CommonUtils.isTrue(standard.withMathExpressions),
          id: standard.id
        });
      });
    }
    if (linkedUnit && hasPermission('sharedStandards') && isDisplaySection('linkedUnitDetails')) {
      CommonUtils.forEach(linkedUnit.standards, standard => {
        section.items.push({
          dataType: 'linkedUnit',
          itemType: 'standard',
          name: standard.desc,
          dbId: standard.dbId,
          noExpressions: !CommonUtils.isTrue(standard.withMathExpressions),
          id: standard.id
        });
      });
    }
    section.items = section.items.filter((item: any) => !item.template || (showTemplates && item.template));
    // STRATEGIES
    section = LessonServices.extractLessonSection(dayObject, 'strategies');
    section.items = [];
    CommonUtils.forEach(rawDayObject.strategies, standard => {
      section.items.push({
        dataType: 'lesson',
        itemType: 'strategy',
        template: rawDayObject.strTemplate === 'Y',
        name: standard.title,
        id: standard.code,
        dbId: standard.strategyId
      });
    });
    if (linkedLessonId && hasPermission('strategies') && isDisplaySection('linkedLessonDetails')) {
      CommonUtils.forEach(rawDayObject.linkedStrategies, standard => {
        section.items.push({
          dataType: 'linkedLesson',
          itemType: 'strategy',
          name: standard.title,
          id: standard.code,
          dbId: standard.strategyId
        });
      });
    }
    if (unit && isDisplaySection('unitDetails')) {
      CommonUtils.forEach(unit.strategies, standard => {
        section.items.push({
          dataType: 'unit',
          itemType: 'strategy',
          name: standard.title,
          id: standard.code,
          dbId: standard.strategyId
        });
      });
    }
    if (linkedUnit && hasPermission('strategies') && isDisplaySection('linkedUnitDetails')) {
      CommonUtils.forEach(linkedUnit.strategies, standard => {
        section.items.push({
          dataType: 'linkedUnit',
          itemType: 'strategy',
          name: standard.title,
          id: standard.code,
          dbId: standard.strategyId
        });
      });
    }
    section.items = section.items.filter((item: any) => !item.template || (showTemplates && item.template));
    const gaSection = LessonServices.extractLessonSection(dayObject, 'googleAttachments');
    gaSection.items = [];
    section = LessonServices.extractLessonSection(dayObject, 'googleAssessments');
    section.items = [];
    CommonUtils.forEach(rawDayObject.googleAssessments, assessment => {
      section.items.push({
        name: assessment.assessmentTitle,
        id: assessment.assessmentId,
        url: assessment.alternateLink
      });
      CommonUtils.forEach(assessment.attachments, attachment => {
        gaSection.items.push({
          name: attachment.filename || attachment.name,
          isPrivate: CommonUtils.isTrue(attachment.privateFlag),
          url: attachment.url || attachment.attachmentUrl
        });
      });
    });

    section = LessonServices.extractLessonSection(dayObject, 'googleAssignments');
    section.items = [];
    CommonUtils.forEach(rawDayObject.googleAssignments, assignment => {
      section.items.push({
        name: assignment.assignmentTitle,
        id: assignment.assignmentId,
        url: assignment.alternateLink
      });
      CommonUtils.forEach(assignment.attachments, attachment => {
        gaSection.items.push({
          name: attachment.filename || attachment.name,
          isPrivate: CommonUtils.isTrue(attachment.privateFlag),
          url: attachment.url || attachment.attachmentUrl
        });
      });
    });

    for (const i in dayObject.sections) {
      const section = dayObject.sections[i];
      if (CommonUtils.isNotEmpty(section.items)) {
        section.items.sort((a: any, b: any) => {
          const aOrder = a.dataType ? +grouping.default[a.dataType] : 0;
          const bOrder = b.dataType ? +grouping.default[b.dataType] : 0;
          return aOrder - bOrder;
        })
      }
    }
  }

  private static createGroupedSections(rawDayObject: any, dayObject: any, statuses: Array<any>, userMode: string, userId: number, sharedClasses: any, viewSettings: any, settings: any) {
    const unit = rawDayObject.unit;
    const linkedUnit = rawDayObject.linkedUnit;
    const linkedLessonId = rawDayObject.linkedLessonId;
    const lessonStatusSettings = settings.displaySettings.lessonStatus || { displayStatusText: true, statusTextFontSize: 10 };
    const hasPermission = (key: string) => LessonServices.hasViewPermission(key, userMode, rawDayObject, userId, sharedClasses);
    const isDisplaySection = (key: string) => LessonServices.isDisplayLessonSection(key, viewSettings);
    dayObject.showActions = isDisplaySection('action');
    dayObject.showTimes = isDisplaySection('times');

    let section = LessonServices.extractLessonSection(dayObject, 'statuses');
    section.items = [];
    section.hideItemName = CommonUtils.booleanToString(!lessonStatusSettings.displayStatusText);
    section.labelStyle = {
      fontSize: lessonStatusSettings.statusTextFontSize
    };
    section.textStyle = {
      fontSize: lessonStatusSettings.statusTextFontSize
    }
    CommonUtils.forEach(rawDayObject.addendums, addendum => {
      if (addendum.type === 'STATUS') {
        let visible = true;
        const showLessonStatus = store.getters['settings/getShowLessonStatus'];

        if (Object.prototype.hasOwnProperty.call(showLessonStatus, addendum.typeId)) {
          visible = showLessonStatus[addendum.typeId];
        }

        if (rawDayObject.collaborateSubjectId > 0) {
          const getCollabStatuses = store.getters['classes/getCollabStatuses'];
          const status = getCollabStatuses[rawDayObject.collaborateSubjectId].find((c:any) => c.lessonStatusId === addendum.typeId);
          section.items.push({
            name: status.statusText,
            color: status.color,
            type: 'teacher',
            id: addendum.typeId,
            visible: visible
          });
        } else {
          const status = LessonServices.extractStatus(addendum.typeId, statuses);
          section.items.push({
            name: status.statusText,
            color: status.color,
            type: 'teacher',
            id: addendum.typeId,
            visible: visible
          });
        }
      }
    });

    // LESSON SECTIONS
    if (LessonServices.hasContent(rawDayObject)) {
      // LESSON TITLE
      section = LessonServices.extractLessonSection(dayObject, 'lessonTitle', 'lesson');
      section.textStyle.borderColor = CommonUtils.getColor(rawDayObject.color);
      section.items = [{
        text: rawDayObject.lessonTitle
      }];

      // LESSON TEXT
      section = LessonServices.extractLessonSection(dayObject, 'lesson', 'lesson');
      section.items = [{
        text: rawDayObject.lessonText
      }];

      // TAB 2
      section = LessonServices.extractLessonSection(dayObject, 'tab2', 'lesson');
      section.items = [{
        text: rawDayObject.homeworkText
      }];

      // TAB 3
      section = LessonServices.extractLessonSection(dayObject, 'tab3', 'lesson');
      section.items = [{
        text: rawDayObject.notesText
      }];

      // TAB 4
      section = LessonServices.extractLessonSection(dayObject, 'tab4', 'lesson');
      section.items = [{
        text: rawDayObject.tab4Text
      }];

      // TAB 5
      section = LessonServices.extractLessonSection(dayObject, 'tab5', 'lesson');
      section.items = [{
        text: rawDayObject.tab5Text
      }];

      // TAB 6
      section = LessonServices.extractLessonSection(dayObject, 'tab6', 'lesson');
      section.items = [{
        text: rawDayObject.tab6Text
      }];

      // ATTACHMENTS
      section = LessonServices.extractLessonSection(dayObject, 'attachments', 'lesson');
      section.items = [];
      CommonUtils.forEach(rawDayObject.attachments, attachment => {
        section.items.push({
          name: attachment.filename || attachment.name,
          isPrivate: CommonUtils.isTrue(attachment.privateFlag),
          url: attachment.url || attachment.attachmentUrl
        });
      });
      CommonUtils.forEach(rawDayObject.asgAttachments, attachment => {
        section.items.push({
          name: attachment.filename || attachment.name,
          isPrivate: CommonUtils.isTrue(attachment.privateFlag),
          url: attachment.url || attachment.attachmentUrl
        });
      });
      CommonUtils.forEach(rawDayObject.asmAttachments, attachment => {
        section.items.push({
          name: attachment.filename || attachment.name,
          isPrivate: CommonUtils.isTrue(attachment.privateFlag),
          url: attachment.url || attachment.attachmentUrl
        });
      });

      section = LessonServices.extractLessonSection(dayObject, 'assessments', 'lesson');
      section.items = [];
      section.collaborateSubjectId = rawDayObject.collaborateSubjectId;
      CommonUtils.forEach(rawDayObject.assessments, assessment => {
        section.items.push({
          name: assessment.assessmentTitle,
          id: assessment.assessmentId
        });
      });
      section = LessonServices.extractLessonSection(dayObject, 'assignments', 'lesson');
      section.items = [];
      section.collaborateSubjectId = rawDayObject.collaborateSubjectId;
      CommonUtils.forEach(rawDayObject.assignments, assignment => {
        section.items.push({
          name: assignment.assignmentTitle,
          id: assignment.assignmentId
        });
      });

      // MY STANDARDS
      section = LessonServices.extractLessonSection(dayObject, 'myStandards', 'lesson');
      section.items = [];
      CommonUtils.forEach(rawDayObject.myStandards, standard => {
        section.items.push({
          name: standard.desc,
          id: standard.id
        });
      });

      // SCHOOL STANDARDS
      section = LessonServices.extractLessonSection(dayObject, 'schoolStandards', 'lesson');
      section.items = [];
      CommonUtils.forEach(rawDayObject.schoolStandards, standard => {
        section.items.push({
          name: standard.desc,
          id: standard.id
        });
      });

      // STANDARDS
      section = LessonServices.extractLessonSection(dayObject, 'sharedStandards', 'lesson');
      section.items = [];
      CommonUtils.forEach(rawDayObject.standards, standard => {
        section.items.push({
          itemType: 'standard',
          name: standard.desc,
          id: standard.id,
          noExpressions: !CommonUtils.isTrue(standard.withMathExpressions),
          dbId: standard.dbId
        });
      });

      // STRATEGIES
      section = LessonServices.extractLessonSection(dayObject, 'strategies', 'lesson');
      section.items = [];
      CommonUtils.forEach(rawDayObject.strategies, standard => {
        section.items.push({
          itemType: 'strategy',
          name: standard.title,
          id: standard.code,
          dbId: standard.strategyId
        });
      });

      const gaSection = LessonServices.extractLessonSection(dayObject, 'googleAttachments');
      gaSection.items = [];
      section = LessonServices.extractLessonSection(dayObject, 'googleAssessments');
      section.items = [];
      CommonUtils.forEach(rawDayObject.googleAssessments, assessment => {
        section.items.push({
          name: assessment.assessmentTitle,
          id: assessment.assessmentId,
          url: assessment.alternateLink
        });
        CommonUtils.forEach(assessment.attachments, attachment => {
          gaSection.items.push({
            name: attachment.filename || attachment.name,
            isPrivate: CommonUtils.isTrue(attachment.privateFlag),
            url: attachment.url || attachment.attachmentUrl
          });
        });
      });
      section = LessonServices.extractLessonSection(dayObject, 'googleAssignments');
      section.items = [];
      CommonUtils.forEach(rawDayObject.googleAssignments, assignment => {
        section.items.push({
          name: assignment.assignmentTitle,
          id: assignment.assignmentId,
          url: assignment.alternateLink
        });
        CommonUtils.forEach(assignment.attachments, attachment => {
          gaSection.items.push({
            name: attachment.filename || attachment.name,
            isPrivate: CommonUtils.isTrue(attachment.privateFlag),
            url: attachment.url || attachment.attachmentUrl
          });
        });
      });
    }

    // LINKED SECTIONS
    if (linkedLessonId) {
      // LESSON TITLE
      section = LessonServices.extractLessonSection(dayObject, 'lessonTitle', 'linkedLesson');
      section.textStyle.borderColor = CommonUtils.getColor(rawDayObject.color);
      section.items = [];
      if (hasPermission('lessonTitle') && isDisplaySection('linkedLessonTitle')) {
        section.hide = 'N'
        section.items = [{
          text: rawDayObject.linkedLessonTitle
        }];
      }

      // LESSON TEXT
      section = LessonServices.extractLessonSection(dayObject, 'lesson', 'linkedLesson');
      section.items = [];
      if (hasPermission('lesson') && isDisplaySection('linkedLessonDetails')) {
        section.items = [{
          text: rawDayObject.linkedLessonText
        }];
      }

      // TAB 2
      section = LessonServices.extractLessonSection(dayObject, 'tab2', 'linkedLesson');
      section.items = [];
      if (hasPermission('tab2') && isDisplaySection('linkedLessonDetails')) {
        section.items = [{
          text: rawDayObject.linkedHomeworkText
        }];
      }

      // TAB 3
      section = LessonServices.extractLessonSection(dayObject, 'tab3', 'linkedLesson');
      section.items = [];
      if (hasPermission('tab3') && isDisplaySection('linkedLessonDetails')) {
        section.items = [{
          text: rawDayObject.linkedNotesText
        }];
      }

      // TAB 4
      section = LessonServices.extractLessonSection(dayObject, 'tab4', 'linkedLesson');
      section.items = [];
      if (hasPermission('tab4') && isDisplaySection('linkedLessonDetails')) {
        section.items = [{
          text: rawDayObject.linkedTab4Text
        }];
      }

      // TAB 5
      section = LessonServices.extractLessonSection(dayObject, 'tab5', 'linkedLesson');
      section.items = [];
      if (hasPermission('tab5') && isDisplaySection('linkedLessonDetails')) {
        section.items = [{
          text: rawDayObject.linkedTab5Text
        }];
      }

      // TAB 6
      section = LessonServices.extractLessonSection(dayObject, 'tab6', 'linkedLesson');
      section.items = [];
      if (hasPermission('tab6') && isDisplaySection('linkedLessonDetails')) {
        section.items = [{
          text: rawDayObject.linkedTab6Text
        }];
      }

      // ATTACHMENTS
      section = LessonServices.extractLessonSection(dayObject, 'attachments', 'linkedLesson');
      section.items = [];
      if (hasPermission('attachments') && isDisplaySection('linkedLessonDetails')) {
        CommonUtils.forEach(rawDayObject.linkedAttachments, attachment => {
          section.items.push({
            name: attachment.filename || attachment.name,
            isPrivate: CommonUtils.isTrue(attachment.privateFlag),
            url: attachment.url || attachment.attachmentUrl
          });
        });
      }

      // MY STANDARDS
      section = LessonServices.extractLessonSection(dayObject, 'myStandards', 'linkedLesson');
      section.items = [];
      if (hasPermission('myStandards') && isDisplaySection('linkedLessonDetails')) {
        CommonUtils.forEach(rawDayObject.linkedMyStandards, standard => {
          section.items.push({
            name: standard.desc,
            id: standard.id
          });
        });
      }

      // SCHOOL STANDARDS
      section = LessonServices.extractLessonSection(dayObject, 'schoolStandards', 'linkedLesson');
      section.items = [];
      if (hasPermission('schoolStandards') && isDisplaySection('linkedLessonDetails')) {
        CommonUtils.forEach(rawDayObject.linkedSchoolStandards, standard => {
          section.items.push({
            name: standard.desc,
            id: standard.id
          });
        });
      }

      // STANDARDS
      section = LessonServices.extractLessonSection(dayObject, 'sharedStandards', 'linkedLesson');
      section.items = [];
      if (hasPermission('sharedStandards') && isDisplaySection('linkedLessonDetails')) {
        CommonUtils.forEach(rawDayObject.linkedStandards, standard => {
          section.items.push({
            itemType: 'standard',
            name: standard.desc,
            id: standard.id,
            dbId: standard.dbId
          });
        });
      }

      // STRATEGIES
      section = LessonServices.extractLessonSection(dayObject, 'strategies', 'linkedLesson');
      section.items = [];
      if (hasPermission('strategies') && isDisplaySection('linkedLessonDetails')) {
        CommonUtils.forEach(rawDayObject.linkedStrategies, standard => {
          section.items.push({
            itemType: 'strategy',
            name: standard.title,
            id: standard.code,
            dbId: standard.strategyId
          });
        });
      }
    }

    // UNIT SECTIONS
    if (unit) {
      // LESSON TITLE
      section = LessonServices.extractLessonSection(dayObject, 'lessonTitle', 'unit');
      section.textStyle.borderColor = CommonUtils.getColor(rawDayObject.color);
      section.items = [];
      if (isDisplaySection('unitTitle') || isDisplaySection('unitId')) {
        section.hide = 'N';
        section.items = [{
          label: isDisplaySection('unitId') ? unit.unitNum : '',
          labelBackgroundColor: unit.unitColor || unit.color || vuetify.framework.theme.currentTheme.primary,
          text: isDisplaySection('unitTitle') ? unit.unitTitle : '',
          description: userMode === 'T' ? unit.unitDesc : ''
        }];
      }

      // LESSON TEXT
      section = LessonServices.extractLessonSection(dayObject, 'lesson', 'unit');
      section.items = [];
      if (isDisplaySection('unitDetails')) {
        section.items = [{
          text: LessonServices.getUnitSectionContent('L', unit)
        }];
      }

      // TAB 2
      section = LessonServices.extractLessonSection(dayObject, 'tab2', 'unit');
      section.items = [];
      if (isDisplaySection('unitDetails')) {
        section.items = [{
          text: LessonServices.getUnitSectionContent('H', unit)
        }];
      }

      // TAB 3
      section = LessonServices.extractLessonSection(dayObject, 'tab3', 'unit');
      section.items = [];
      if (isDisplaySection('unitDetails')) {
        section.items = [{
          text: LessonServices.getUnitSectionContent('N', unit)
        }];
      }

      // TAB 4
      section = LessonServices.extractLessonSection(dayObject, 'tab4', 'unit');
      section.items = [];
      if (isDisplaySection('unitDetails')) {
        section.items.push({
          text: LessonServices.getUnitSectionContent('4', unit)
        });
      }

      // TAB 5
      section = LessonServices.extractLessonSection(dayObject, 'tab5', 'unit');
      section.items = [];
      if (isDisplaySection('unitDetails')) {
        section.items.push({
          text: LessonServices.getUnitSectionContent('5', unit)
        });
      }

      // TAB 6
      section = LessonServices.extractLessonSection(dayObject, 'tab6', 'unit');
      section.items = [];
      if (isDisplaySection('unitDetails')) {
        section.items = [{
          text: LessonServices.getUnitSectionContent('6', unit)
        }];
      }

      // ATTACHMENTS
      section = LessonServices.extractLessonSection(dayObject, 'attachments', 'unit');
      section.items = [];
      if (isDisplaySection('unitDetails')) {
        CommonUtils.forEach(unit.attachments, attachment => {
          section.items.push({
            name: attachment.filename || attachment.name,
            isPrivate: CommonUtils.isTrue(attachment.privateFlag),
            url: attachment.url || attachment.attachmentUrl
          });
        });
      }

      // MY STANDARDS
      section = LessonServices.extractLessonSection(dayObject, 'myStandards', 'unit');
      section.items = [];
      if (isDisplaySection('unitDetails')) {
        CommonUtils.forEach(unit.myStandards, standard => {
          section.items.push({
            name: standard.desc,
            id: standard.id
          });
        });
      }

      // SCHOOL STANDARDS
      section = LessonServices.extractLessonSection(dayObject, 'schoolStandards', 'unit');
      section.items = [];
      if (isDisplaySection('unitDetails')) {
        CommonUtils.forEach(unit.schoolStandards, standard => {
          section.items.push({
            name: standard.desc,
            id: standard.id
          });
        });
      }

      // STANDARDS
      section = LessonServices.extractLessonSection(dayObject, 'sharedStandards', 'unit');
      section.items = [];
      if (isDisplaySection('unitDetails')) {
        CommonUtils.forEach(unit.standards, standard => {
          section.items.push({
            itemType: 'standard',
            name: standard.desc,
            id: standard.id,
            noExpressions: !CommonUtils.isTrue(standard.withMathExpressions),
            dbId: standard.dbId
          });
        });
      }

      // STRATEGIES
      section = LessonServices.extractLessonSection(dayObject, 'strategies', 'unit');
      section.items = [];
      if (isDisplaySection('unitDetails')) {
        CommonUtils.forEach(unit.strategies, standard => {
          section.items.push({
            itemType: 'strategy',
            name: standard.title,
            id: standard.code,
            dbId: standard.strategyId
          });
        });
      }
    }

    // LINKED UNIT SECTIONS
    if (linkedUnit) {
      // LESSON TITLE
      section = LessonServices.extractLessonSection(dayObject, 'lessonTitle', 'linkedUnit');
      section.textStyle.borderColor = CommonUtils.getColor(rawDayObject.color);
      if (isDisplaySection('linkedUnitId') || isDisplaySection('linkedUnitTitle')) {
        section.hide = 'N';
        section.items = [{
          label: isDisplaySection('linkedUnitId') ? linkedUnit.unitNum : '',
          labelBackgroundColor: linkedUnit.unitColor || CommonUtils.getColor(rawDayObject.color) || vuetify.framework.theme.currentTheme.primary,
          text: hasPermission('lessonTitle') && isDisplaySection('linkedUnitTitle') ? linkedUnit.unitTitle : ''
        }];
      }

      // LESSON TEXT
      section = LessonServices.extractLessonSection(dayObject, 'lesson', 'linkedUnit');
      section.items = [];
      if (hasPermission('lesson') && isDisplaySection('linkedUnitDetails')) {
        section.items = [{
          text: LessonServices.getUnitSectionContent('L', linkedUnit)
        }];
      }

      // TAB 2
      section = LessonServices.extractLessonSection(dayObject, 'tab2', 'linkedUnit');
      section.items = [];
      if (hasPermission('tab2') && isDisplaySection('linkedUnitDetails')) {
        section.items = [{
          text: LessonServices.getUnitSectionContent('H', linkedUnit)
        }];
      }

      // TAB 3
      section = LessonServices.extractLessonSection(dayObject, 'tab3', 'linkedUnit');
      section.items = [];
      if (hasPermission('tab3') && isDisplaySection('linkedUnitDetails')) {
        section.items = [{
          text: LessonServices.getUnitSectionContent('N', linkedUnit)
        }];
      }

      // TAB 4
      section = LessonServices.extractLessonSection(dayObject, 'tab4', 'linkedUnit');
      section.items = [];
      if (hasPermission('tab4') && isDisplaySection('linkedUnitDetails')) {
        section.items.push({
          text: LessonServices.getUnitSectionContent('4', linkedUnit)
        });
      }

      // TAB 5
      section = LessonServices.extractLessonSection(dayObject, 'tab5', 'linkedUnit');
      section.items = [];
      if (hasPermission('tab5') && isDisplaySection('linkedUnitDetails')) {
        section.items.push({
          text: LessonServices.getUnitSectionContent('5', linkedUnit)
        });
      }

      // TAB 6
      section = LessonServices.extractLessonSection(dayObject, 'tab6', 'linkedUnit');
      section.items = [];
      if (hasPermission('tab6') && isDisplaySection('linkedUnitDetails')) {
        section.items = [{
          text: LessonServices.getUnitSectionContent('6', linkedUnit)
        }];
      }

      // ATTACHMENTS
      section = LessonServices.extractLessonSection(dayObject, 'attachments', 'linkedUnit');
      section.items = [];
      if (hasPermission('attachments') && isDisplaySection('linkedUnitDetails')) {
        CommonUtils.forEach(linkedUnit.attachments, attachment => {
          section.items.push({
            name: attachment.filename || attachment.name,
            isPrivate: CommonUtils.isTrue(attachment.privateFlag),
            url: attachment.url || attachment.attachmentUrl
          });
        });
      }

      // MY STANDARDS
      section = LessonServices.extractLessonSection(dayObject, 'myStandards', 'linkedUnit');
      section.items = [];
      if (hasPermission('myStandards') && isDisplaySection('linkedUnitDetails')) {
        CommonUtils.forEach(linkedUnit.myStandards, standard => {
          section.items.push({
            name: standard.desc,
            id: standard.id
          });
        });
      }

      // SCHOOL STANDARDS
      section = LessonServices.extractLessonSection(dayObject, 'schoolStandards', 'linkedUnit');
      section.items = [];
      if (hasPermission('schoolStandards') && isDisplaySection('linkedUnitDetails')) {
        CommonUtils.forEach(linkedUnit.schoolStandards, standard => {
          section.items.push({
            name: standard.desc,
            id: standard.id
          });
        });
      }

      // STANDARDS
      section = LessonServices.extractLessonSection(dayObject, 'sharedStandards', 'linkedUnit');
      section.items = [];
      if (hasPermission('sharedStandards') && isDisplaySection('linkedUnitDetails')) {
        CommonUtils.forEach(linkedUnit.standards, standard => {
          section.items.push({
            itemType: 'standard',
            name: standard.desc,
            id: standard.id,
            noExpressions: !CommonUtils.isTrue(standard.withMathExpressions),
            dbId: standard.dbId
          });
        });
      }

      // STRATEGIES
      section = LessonServices.extractLessonSection(dayObject, 'strategies', 'linkedUnit');
      section.items = [];
      if (hasPermission('strategies') && isDisplaySection('linkedUnitDetails')) {
        CommonUtils.forEach(linkedUnit.strategies, standard => {
          section.items.push({
            itemType: 'strategy',
            name: standard.title,
            id: standard.code,
            dbId: standard.strategyId
          });
        });
      }
    }
  }

  private static hasViewPermission(section: string, userMode: string, rawDayObject: any, userId: number, sharedClasses: any) {
    if (userMode === 'S' || userMode === 'A' || rawDayObject.linkedTeacherId === userId) {
      return true;
    } else {
      const sharedClass = sharedClasses[rawDayObject.linkedClassId];
      if (sharedClass) {
        if (sharedClass.hasFullPermissions) {
          return true;
        } else if (sharedClass.permissionSettings) {
          return ['write', 'read'].includes(sharedClass.permissionSettings[permissionsMapping[section]]);
        }
      }
      return false;
    }
  }

  private static getUnitSectionContent(section: string, unit: any) {
    for (const i in unit.contents) {
      const content = unit.contents[i];
      if (content.section === section) {
        return content.value;
      }
    }
    return '';
  }

  private static extractStatus = (id: number, statuses: Array<any>) => {
    for (let i = 0; i < statuses.length; i++) {
      if (statuses[i].lessonStatusId === id || statuses[i].schoolStatusId === id) {
        return statuses[i];
      }
    }
    return {};
  }

  public static hasLessonData(lesson: any) {
    return CommonUtils.hasText(lesson.lessonText) ||
      CommonUtils.hasText(lesson.tab2Text) ||
      CommonUtils.hasText(lesson.tab3Text) ||
      CommonUtils.hasText(lesson.tab4Text) ||
      CommonUtils.hasText(lesson.tab5Text) ||
      CommonUtils.hasText(lesson.tab6Text) ||
      CommonUtils.isNotEmpty(lesson.standards) ||
      CommonUtils.isNotEmpty(lesson.myStandards) ||
      CommonUtils.isNotEmpty(lesson.schoolStandards) ||
      CommonUtils.isNotEmpty(lesson.strategies) ||
      CommonUtils.isNotEmpty(lesson.attachments);
  }

  public static getLessonSections(lesson: any, paramsLessonSections?: any) {
    const lessonSections = paramsLessonSections || store.getters['settings/getLessonSections'];
    return LessonServices.getLessonSectionsByLayoutId(lesson.layoutId) || lessonSections;
  }

  public static initLessonSections(lesson: any, params?: any) {
    const lessonSections = LessonServices.getLessonSections(lesson.lesson, params?.lessonSections);
    const displaySettings = params?.displaySettings || store.getters['settings/getDisplaySettings'];
    const lessonStylings = displaySettings.lessonStyling;
    const canEdit: (section: string) => boolean = store.getters['lessons/canEdit'];
    const canView: (section: string) => boolean = store.getters['lessons/canView'];
    lesson.sections = [];
    lesson.updatedFields = new Set<string>();
    if (displaySettings && lessonSections && lessonStylings) {
      for (const i in sections) {
        const key = sections[i];
        const editable = canEdit(key);
        const viewable = canView(key);
        let lessonSection = lessonSections[key];
        if (lessonSection) {
          lesson.sections.push({
            section: key,
            displayOrder: lessonSection[key + 'DisplayOrder'],
            label: lessonSection[key + 'Label'],
            enabled: lessonSection[key + 'Enabled'] === 'Y',
            active: false,
            disabled: !editable && viewable,
            noAccess: !editable && !viewable,
            textStyle: {
              fontFamily: CommonUtils.getFontFamily(lessonSection[key + 'TextFont']),
              fontSize: (lessonSection[key + 'TextSize'] || '10') + 'pt'
            }
          });
        } else {
          lessonSection = lessonStylings[key];
          lesson.sections.push({
            section: key,
            displayOrder: lessonSection[key + 'DisplayOrder'],
            label: lessonSection[key + 'Label'],
            enabled: true,
            active: false,
            disabled: !editable && viewable,
            noAccess: !editable && !viewable,
            textStyle: {
              fontFamily: CommonUtils.getFontFamily(lessonSection[key + 'TextFont']),
              fontSize: (lessonSection[key + 'TextSize'] || '10') + 'pt'
            }
          });
        }
      }
      lesson.sections.sort((a: any, b: any) => {
        return +a.displayOrder - +b.displayOrder;
      });
      lesson.sections.push({
        active: false,
        disabled: false,
        noAccess: false,
        section: 'classwork',
        label: i18n.t('classworkLabel'),
        enabled: true
      })
      const enabledSections = lesson.sections.filter((section: any) => section.enabled);
      if (enabledSections.length > 0) {
        enabledSections[0].active = true;
      }
    }
  }

  public static populateLesson(lesson: any, params: any) {
    if (params.setLesson) {
      store.commit('lessons/setLesson', lesson, { root: true });
    }
    LessonServices.initLessonSections(lesson, params);
    const getStatus = params.getStatus || store.getters['settings/getStatus'];
    const getCollabStatuses = store.getters['classes/getCollabStatuses'];
    // const getSchoolStatus = params.getStatus || store.getters['settings/getSchoolStatus']
    // const appliedStatuses = store.getters['plans/getWeekSchoolStatus'];
    if (!lesson.editLink) lesson.linkedLesson = null;
    const lessonData = lesson.editLink && lesson.linkedLesson ? lesson.linkedLesson : lesson.lesson;
    if (lessonData) {
      lesson.className = lessonData.className;
      lesson.classId = lessonData.classId;
      lesson.dayOfWeek = lessonData.dayOfWeek;
      lesson.date = lessonData.date;
      lesson.unitId = lessonData.unitId;
      lesson.collaborateSubjectId = lessonData.collaborateSubjectId;
      lesson.extraLesson = lessonData.extraLesson;
      lesson.lessonText = lessonData.lessonText;
      lesson.tab2Text = lessonData.homeworkText;
      lesson.tab3Text = lessonData.notesText;
      lesson.tab4Text = lessonData.tab4Text;
      lesson.tab5Text = lessonData.tab5Text;
      lesson.tab6Text = lessonData.tab6Text;
      lesson.addClassDaysCode = lessonData.addClassDaysCode;
      lesson.origStartTime = lessonData.startTime;
      lesson.origEndTime = lessonData.endTime;
      lesson.linkedLessonId = lessonData.linkedLessonId;
      lesson.lessonId = lessonData.lessonId;
      if (CommonUtils.hasText(lessonData.customStart) || CommonUtils.hasText(lessonData.customEnd)) {
        lesson.startTime = lessonData.customStart;
        lesson.endTime = lessonData.customEnd;
      } else {
        lesson.startTime = lessonData.startTime;
        lesson.endTime = lessonData.endTime;
      }
      lesson.lessonTitle = lessonData.lessonTitle;
      lesson.lessonLock = lessonData.lessonLock;
      lesson.strategies = CommonUtils.getOrDefaultTo(lessonData.strategies, []);
      lesson.standards = CommonUtils.getOrDefaultTo(lessonData.standards, []);
      lesson.myStandards = CommonUtils.getOrDefaultTo(lessonData.myStandards, []);
      lesson.schoolStandards = CommonUtils.getOrDefaultTo(lessonData.schoolStandards, []);
      lesson.attachments = CommonUtils.getOrDefaultTo(lessonData.attachments, []);
      lesson.assignments = CommonUtils.getOrDefaultTo(lessonData.assignments, []);
      lesson.assessments = CommonUtils.getOrDefaultTo(lessonData.assessments, []);
      lesson.statuses = [];
      lesson.schoolStatuses = [];
      if (lessonData.addendums) {
        for (const i in lessonData.addendums) {
          const addendum = lessonData.addendums[i];
          if (addendum.type === 'STATUS') {
            if (lesson.collaborateSubjectId > 0) {
              lesson.statuses.push(getCollabStatuses[lesson.collaborateSubjectId].find((c:any) => c.lessonStatusId === addendum.typeId));
            } else {
              const status = getStatus(addendum.typeId);
              if (status) {
                lesson.statuses.push(status);
              } else {
                lesson.statuses.push({ lessonStatusId: addendum.typeId });
              }
            }
          }
        }
      }

      // const appliedStatusIds = appliedStatuses.filter((s: any) => {
      //   if ((s.type === 'L' || s.type === 'D') && DateTimeUtils.daysDiff(lesson.date, s.startDate) !== 0) {
      //     return false;
      //   } else if ((s.type === 'L' || s.type === 'C') && s.subjectId !== lesson.classId) {
      //     return false;
      //   } else {
      //     return true;
      //   }
      // }).map((s: any) => { return s.schoolStatusId })
      // for (const id in appliedStatusIds) {
      //   const status = getSchoolStatus(appliedStatusIds[id]);
      //   lesson.schoolStatuses.push(status);
      // }

      lesson.updatedFields = new Set<string>();
    } else {
      const newLesson = LessonServices.newLesson();
      for (const key in newLesson) {
        lesson[key] = newLesson[key];
      }
    }
    const oldLesson = ld.cloneDeep(lesson);
    LessonServices.cleanUpAttrs(oldLesson);
    lesson.oldLesson = oldLesson;
    return lesson;
  }

  public static cleanUpAttrs(lesson: any) {
    const lessonKeys = Object.keys(LessonServices.newLesson());
    for (const key in lesson) {
      if (!lessonKeys.includes(key)) {
        delete lesson[key];
      }
    }
  }

  public static newLesson(): any {
    return {
      dayOfWeek: '',
      date: '',
      className: '',
      classId: 0,
      collaborateSubjectId: 0,
      extraLesson: 0,
      lessonTitle: '',
      lessonText: '',
      tab2Text: '',
      tab3Text: '',
      tab4Text: '',
      tab5Text: '',
      tab6Text: '',
      standards: [],
      myStandards: [],
      lessonLock: '',
      addClassDaysCode: '',
      schoolStandards: [],
      strategies: [],
      attachments: [],
      origStartTime: '',
      startTime: '',
      origEndTime: '',
      endTime: '',
      unitId: '',
      statuses: [],
      assignments: [],
      assessments: [],
      updatedFields: [],
      schoolStatuses: [],
      linkedLessonId: 0,
      lessonId: 0
    };
  }

  public static createRequest(source?: any, withOldLesson?: boolean): any {
    let customStart;
    let customEnd;
    const state = source || store.state.lessons;

    if (!DateTimeUtils.areTimesEqual(state.origStartTime, state.startTime) || !DateTimeUtils.areTimesEqual(state.origEndTime, state.endTime) || +state.extraLesson > 0) {
      customStart = state.startTime;
      customEnd = state.endTime;
    }
    const request: any = {
      classId: state.classId,
      customDate: state.date || '',
      unitId: CommonUtils.get(state.unitId, 0),
      extraLesson: CommonUtils.get(state.extraLesson, 999),
      lessonText: state.lessonText || '',
      homeworkText: state.tab2Text || '',
      notesText: state.tab3Text || '',
      tab4Text: state.tab4Text || '',
      tab5Text: state.tab5Text || '',
      tab6Text: state.tab6Text || '',
      addClassDaysCode: state.addClassDaysCode || '',
      customStart: customStart || '',
      customEnd: customEnd || '',
      lessonTitle: state.lessonTitle || '',
      lessonLock: state.lessonLock || 'N',
      strategySent: 'Y',
      unitStandardsSent: 'Y',
      strategyIds: CommonUtils.getPropertyValues(state.strategies, 'strategyId'),
      standardDBIds: CommonUtils.getPropertyValues(state.standards, 'dbId'),
      myStandardDBIds: CommonUtils.getPropertyValues(state.myStandards, 'dbId'),
      schoolStandardDBIds: CommonUtils.getPropertyValues(state.schoolStandards, 'dbId'),
      statusesSent: 'Y',
      statuses: CommonUtils.getPropertyValues(state.statuses, 'lessonStatusId'),
      attachmentNames: CommonUtils.getPropertyValues(state.attachments, 'filename'),
      attachmentPrivate: CommonUtils.getPropertyValues(state.attachments, 'privateFlag', CommonUtils.booleanToString),
      attachmentURL: CommonUtils.getPropertyValues(state.attachments, 'url'),
      schoolWorks: LessonServices.getSchoolWorks(state.assignments, state.assessments),
      updatedFields: Array.from(state.updatedFields || new Set<string>()).join(','),
      linkedLessonId: CommonUtils.get(state.linkedLessonId, 0),
      oldLesson: withOldLesson ? LessonServices.stringify(state.oldLesson) : null,
      isEditingALinkedLesson: CommonUtils.booleanToString(state.editLink),
      lessonId: CommonUtils.get(state.lessonId, 0)
    };

    if (state.collaborateSubjectId > 0) {
      request.classId = state.collaborateSubjectId;
      request.customTimeClassId = state.classId;
    }
    return request;
  }

  private static stringify(o: any): string {
    if (o && (typeof o === 'object' || Array.isArray(o))) {
      return JSON.stringify(o);
    } else {
      return o || '';
    }
  }

  private static getSchoolWorks(assignments: Array<any>, assessments: Array<any>) {
    const schoolWorks = [];
    if (assignments) {
      for (const i in assignments) {
        schoolWorks.push({
          type: assignments[i].source === 'gc' ? 'GOOGLEASSIGNMENT' : 'ASSIGNMENT',
          typeId: assignments[i].source === 'gc' ? 0 : assignments[i].assignmentId || assignments[i].classworkId,
          shortValueText: assignments[i].sourceid || assignments[i].sourceId || ''
        });
      }
    }
    if (assessments) {
      for (const i in assessments) {
        schoolWorks.push({
          type: assessments[i].source === 'gc' ? 'GOOGLEASSESSMENT' : 'ASSESSMENT',
          typeId: assessments[i].source === 'gc' ? 0 : assessments[i].assessmentId || assessments[i].classworkId,
          shortValueText: assessments[i].sourceid || assessments[i].sourceId || ''
        });
      }
    }
    return JSON.stringify(schoolWorks);
  }

  public static getLessonUniqueIdentifier(lessonData:any) {
    const classId = lessonData.classId || lessonData.subjectId
    return classId + '-' + lessonData.date + '-' + lessonData.extraLesson;
  }

  public static hasAssignments(subjectId:number): boolean {
    const currentData = store.state.plans.currentData;
    if (currentData) {
      const ha = currentData.hasAssignments;
      return ha && ha[+subjectId] && ha[+subjectId] === true;
    } else {
      return false;
    }
  }

  public static hasAssessments(subjectId:number): boolean {
    const currentData = store.state.plans.currentData;
    if (currentData) {
      const ha = currentData.hasAssignments;
      return ha && ha[+subjectId] && ha[+subjectId] === true;
    } else {
      return false;
    }
  }

  public static excludedLessonActions(lessonData:any, userMode:any, localPage:string):Array<any> {
    let excludes:any[] = [];
    if (+lessonData.classYearId === 0) {
      excludes = [
        'lock',
        'forward',
        'backward',
        'extend',
        'extendStandards',
        'noClass',
        'applyTemplate',
        'removeTemplate',
        'restoreTemplate',
        'addClasswork',
        'comment'
      ]
      userMode === 'A' ? excludes = excludes.filter(e => e !== 'print') : excludes.push('print');
      userMode === 'A' ? excludes.push('move') : excludes = excludes.filter(e => e !== 'move');
    } else {
      if (+lessonData.collaborateSubjectId === 0) {
        excludes = [];
        if (!this.hasAssignments(lessonData.classId) && !this.hasAssessments(lessonData.classId)) {
          excludes.push('addClasswork');
        }
      } else {
        excludes = [
          'edit',
          'move',
          'paste',
          'forward',
          'backward',
          'extend',
          'extendStandards',
          'removeTemplate',
          'delete',
          'noClass',
          'applyTemplate',
          'removeTemplate',
          'restoreTemplate',
          'addClasswork'
        ];
        if (+lessonData.collaborateType === 2) {
          excludes = excludes.filter(e => e !== 'edit' && e !== 'print' && e !== 'paste');
        }
      }
    }

    if ((+lessonData.classYearId === 0 || +lessonData.collaborateSubjectId === 0) && lessonData.lessonId > 0) {
      excludes = excludes.filter(e => e !== 'changeHistory');
    } else {
      excludes.push('changeHistory');
    }
    if (userMode === 'T') {
      const sharedClass:any = (CommonUtils.isNotEmpty(store.getters['classes/getClassIds']) && (store.getters['classes/getClassIds'].includes(+lessonData.classId) || store.getters['classes/getClassIds'].includes(+lessonData.classId))) ? null : store.getters['settings/getSharedClasses'][lessonData.classId];
      // ld.find(this.sharedClass, { classId: lessonData.classId })
      if (sharedClass) {
        excludes = excludes.concat([
          'applyTemplate',
          'removeTemplate',
          'restoreTemplate',
          'addClasswork',
          'move',
          'lock',
          'forward',
          'backward',
          'extend',
          'extendStandards',
          'noClass'
          // 'copy',
          // 'paste',
          // 'editLink',
          // 'print',
          // 'unlink'
        ]);
        if (!this.hasSomePermission(sharedClass.permissionSettings, 'write')) {
          excludes.push('edit');
          excludes.push('paste');
        }
        if (sharedClass.hasFullPermissions) {
          excludes = excludes.filter(e => e !== 'delete');
        } else {
          excludes.push('delete');
        }
      }
    }
    if (localPage === 'search') {
      excludes = [
        'edit',
        'editLink',
        'lock',
        'move',
        'paste',
        'forward',
        'backward',
        'extend',
        'extendStandards',
        'removeTemplate',
        'delete',
        'noClass',
        'applyTemplate',
        'removeTemplate',
        'restoreTemplate',
        'addClasswork',
        'changeHistory',
        'swap'
      ];
    } else if (localPage === 'lesson-banks') {
      excludes = excludes.concat(['move', 'swap']);
    }
    return excludes;
  }

  public static hasSomePermission(settings:any, permission:any):boolean {
    if (settings) {
      for (const i in settings) {
        if (i.indexOf('permissions') > -1 && settings[i] === permission) {
          return true;
        }
      }
    }
    return false;
  }

  public static unflatten(data: string | Array<any>, property: string) {
    if (data) {
      let arr: Array<any>;
      if (typeof data === 'string') {
        if (data.startsWith('[') && data.endsWith(']')) {
          arr = JSON.parse(data);
        } else {
          arr = JSON.parse('[' + data + ']');
        }
      } else {
        arr = data;
      }
      return arr.map(item => {
        const a: any = {};
        a[property] = item;
        return a;
      });
    }
    return [];
  }

  public static paramStringValueToArray(param: any) {
    if (typeof param === 'string') {
      if (param.startsWith('[') && param.endsWith(']')) {
        return JSON.parse(param);
      } else {
        return JSON.parse('["' + param + '"]');
      }
    } else {
      return param;
    }
  }

  public static getLessonPayload(item: any) {
    return item.payload && CommonUtils.hasText(item.payload) ? JSON.parse(item.payload) : undefined;
  }
}
