
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ld from 'lodash';
import { fontSizes, fonts as stickerFonts, stickerBackgroundTypes, stickerSizes } from '@/constants';
import { Deferred } from '@/common/deferred';
import CommonUtils from '@/utils/common-utils';
import { namespace } from 'vuex-class';
const settings = namespace('settings');
@Component
export default class DisplayFormatOptions extends Vue {
    @Prop({
      type: Object,
      default: () => {
        return {
          titleFont: '2',
          titleFontSize: '10',
          titleColor: '#FFFFFF',
          titleFillColor: '#c3d9ff',
          titleBold: 'N',
          titleItalic: 'N',
          titleUnderline: 'N',
          bodyFont: '2',
          bodyFontSize: '10',
          bodyColor: '#FFFFFF',
          bodyFillColor: '#c3d9ff',
          bodyBold: 'N',
          bodyItalic: 'N',
          bodyUnderline: 'N',
          showTitle: 'Y'
        }
      }
    })
    value!: any;

    @Prop({ required: false, type: String, default: 'activator' })
    type!: string;

    @Prop({ required: false, type: Boolean, default: true })
    withShowTitle!: boolean;

    @Prop({ required: false, type: Boolean, default: true })
    formatTitle!: boolean;

    @Prop({ required: false, type: Boolean, default: true })
    formatBody!: boolean;

    @Prop({ required: false })
    stickerId!: any;

    @Prop({ required: false, default: false })
    showStickerFormatOption!: boolean;

    deferred = new Deferred();
    showMenu = false;
    localIsCalledByStickers = false;
    x = 0;
    y = 0;

    @settings.Getter('getFontItems')
    fonts!: Array<any>;

    get localPage() {
      return CommonUtils.getPage(this.$route);
    }

    get stickerBackgroundTypes() {
      return stickerBackgroundTypes;
    }

    get stickerSizes() {
      return stickerSizes;
    }

    get stickerFonts() {
      return stickerFonts;
    }

    get fontSizes() {
      return fontSizes;
    }

    get titleFont() {
      return this.value.titleFont;
    }

    set titleFont(val: string) {
      this.setValue('titleFont', val);
    }

    get titleFontSize() {
      return this.value.titleFontSize;
    }

    set titleFontSize(val: string) {
      this.setValue('titleFontSize', val);
    }

    get titleColor() {
      return this.value.titleColor;
    }

    set titleColor(val: string) {
      this.setValue('titleColor', val);
    }

    get titleFillColor() {
      return this.value.titleFillColor;
    }

    set titleFillColor(val: string) {
      this.setValue('titleFillColor', val);
    }

    get titleBold() {
      return this.value.titleBold;
    }

    set titleBold(val: string) {
      this.setValue('titleBold', val);
    }

    get titleItalic() {
      return this.value.titleItalic;
    }

    set titleItalic(val: string) {
      this.setValue('titleItalic', val);
    }

    get titleUnderline() {
      return this.value.titleUnderline;
    }

    set titleUnderline(val: string) {
      this.setValue('titleUnderline', val);
    }

    get stickerDescriptionFont() {
      return this.value.stickerDescriptionFont;
    }

    set stickerDescriptionFont(val: string) {
      this.setValue('stickerDescriptionFont', val);
    }

    get stickerDescriptionFontSize() {
      return this.value.stickerDescriptionFontSize;
    }

    set stickerDescriptionFontSize(val: string) {
      this.setValue('stickerDescriptionFontSize', val);
    }

    get stickerDescriptionColor() {
      return this.value.stickerDescriptionColor;
    }

    set stickerDescriptionColor(val: string) {
      this.setValue('stickerDescriptionColor', val);
    }

    get stickerDescriptionBold() {
      return this.value.stickerDescriptionBold;
    }

    set stickerDescriptionBold(val: string) {
      this.setValue('stickerDescriptionBold', val);
    }

    get stickerDescriptionItalic() {
      return this.value.stickerDescriptionItalic;
    }

    set stickerDescriptionItalic(val: string) {
      this.setValue('stickerDescriptionItalic', val);
    }

    get stickerDescriptionUnderline() {
      return this.value.stickerDescriptionUnderline;
    }

    set stickerDescriptionUnderline(val: string) {
      this.setValue('stickerDescriptionUnderline', val);
    }

    get stickerSize() {
      return this.value.stickerSize;
    }

    set stickerSize(val: string) {
      this.setValue('stickerSize', val);
    }

    get stickerBackgroundColor() {
      return this.value.stickerBackgroundColor;
    }

    set stickerBackgroundColor(val: string) {
      this.setValue('stickerBackgroundColor', val);
    }

    get stickerBackgroundStyle(): string {
      return this.value.stickerBackgroundStyle;
    }

    set stickerBackgroundStyle(value: string) {
      this.setValue('stickerBackgroundStyle', value);
    }

    get bodyFont() {
      return this.value.bodyFont;
    }

    set bodyFont(val: string) {
      this.setValue('bodyFont', val);
    }

    get bodyFontSize() {
      return this.value.bodyFontSize;
    }

    set bodyFontSize(val: string) {
      this.setValue('bodyFontSize', val);
    }

    get bodyColor() {
      return this.value.bodyColor;
    }

    set bodyColor(val: string) {
      this.setValue('bodyColor', val);
    }

    get bodyFillColor() {
      return this.value.bodyFillColor;
    }

    set bodyFillColor(val: string) {
      this.setValue('bodyFillColor', val);
    }

    get bodyBold() {
      return this.value.bodyBold;
    }

    set bodyBold(val: string) {
      this.setValue('bodyBold', val);
    }

    get bodyItalic() {
      return this.value.bodyItalic;
    }

    set bodyItalic(val: string) {
      this.setValue('bodyItalic', val);
    }

    get bodyUnderline() {
      return this.value.bodyUnderline;
    }

    set bodyUnderline(val: string) {
      this.setValue('bodyUnderline', val);
    }

    get showTitle() {
      return CommonUtils.isTrue(this.value.showTitle);
    }

    set showTitle(val: boolean) {
      this.setValue('showTitle', CommonUtils.booleanToString(val));
    }

    toggleTitleBold() {
      this.titleBold = CommonUtils.booleanToString(!CommonUtils.isTrue(this.titleBold));
    }

    toggleTitleItalic() {
      this.titleItalic = CommonUtils.booleanToString(!CommonUtils.isTrue(this.titleItalic));
    }

    toggleTitleUnderline() {
      this.titleUnderline = CommonUtils.booleanToString(!CommonUtils.isTrue(this.titleUnderline));
    }

    toggleStickerDescriptionBold() {
      this.stickerDescriptionBold = CommonUtils.booleanToString(!CommonUtils.isTrue(this.stickerDescriptionBold));
    }

    toggleStickerDescriptionItalic() {
      this.stickerDescriptionItalic = CommonUtils.booleanToString(!CommonUtils.isTrue(this.stickerDescriptionItalic));
    }

    toggleStickerDescriptionUnderline() {
      this.stickerDescriptionUnderline = CommonUtils.booleanToString(!CommonUtils.isTrue(this.stickerDescriptionUnderline));
    }

    toggleBodyBold() {
      this.bodyBold = CommonUtils.booleanToString(!CommonUtils.isTrue(this.bodyBold));
    }

    toggleBodyItalic() {
      this.bodyItalic = CommonUtils.booleanToString(!CommonUtils.isTrue(this.bodyItalic));
    }

    toggleBodyUnderline() {
      this.bodyUnderline = CommonUtils.booleanToString(!CommonUtils.isTrue(this.bodyUnderline));
    }

    isTrue(value: any) {
      return CommonUtils.isTrue(value);
    }

    setValue(key: string, val: any) {
      const format = ld.cloneDeep(this.value);
      if (this.localIsCalledByStickers) {
        format.stickerId = this.stickerId;
      }
      ld.set(format, key, val);
      ld.set(format, 'updatedField', key);
      this.$emit('input', format);
    }

    @Watch('showMenu')
    onShowMenuChange() {
      if (this.showMenu) {
        this.deferred = new Deferred();
        this.$nextTick(() => {
          new Promise(resolve => setTimeout(resolve, 100)).then(() => {
            (this.$refs as any).selectFontType.$el.querySelector('input').focus();
          });
        });
      } else {
        this.deferred.resolve();
      }
    }

    focusColorPicker(type: string) {
      (this.$refs as any)['colorPicker' + type].focusColorPicker();
    }

    public show(e: MouseEvent, isCalledByStickers?:boolean) {
      e.preventDefault()
      this.x = e.clientX;
      this.y = e.clientY;
      this.showMenu = true;
      this.localIsCalledByStickers = isCalledByStickers || false;
      return new Promise(resolve => setTimeout(resolve, 100)).then(() => {
        return this.deferred.promise;
      });
    }
}
