var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-bottom-sheet",
    {
      staticStyle: { "overflow-y": "auto", "max-height": "60%" },
      model: {
        value: _vm.localDrawerBottom,
        callback: function($$v) {
          _vm.localDrawerBottom = $$v
        },
        expression: "localDrawerBottom"
      }
    },
    [
      _c(
        "v-sheet",
        {
          staticClass: "pa-5 pb-10 bottom-drawer",
          attrs: { elevation: "0", flat: "" }
        },
        [
          _c(
            "v-row",
            { staticClass: "ma-0 pa-0" },
            [
              _c(
                "v-col",
                {
                  staticClass: "ma-0 pa-0 text-left",
                  class: { "px-5": !_vm.$vuetify.breakpoint.smAndDown },
                  attrs: { cols: "6" }
                },
                [
                  _c(
                    "v-btn-toggle",
                    {
                      attrs: {
                        rounded: "",
                        mandatory: "",
                        dense: "",
                        color: "primary"
                      },
                      model: {
                        value: _vm.drawerMode,
                        callback: function($$v) {
                          _vm.drawerMode = $$v
                        },
                        expression: "drawerMode"
                      }
                    },
                    [
                      _c(
                        "v-btn",
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                color:
                                  _vm.drawerMode === 0 ? "primary" : "secondary"
                              }
                            },
                            [_vm._v("fal fa-diagram-cells fa-rotate-90")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                color:
                                  _vm.drawerMode === 1 ? "primary" : "secondary"
                              }
                            },
                            [_vm._v("fal fa-diagram-cells")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "ma-0 pa-0 text-right", attrs: { cols: "6" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", "aria-label": _vm.$t("closeLabel") },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          _vm.localDrawerBottom = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("fal fa-times")])],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("view-settings")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }