import '@/errors';
import gs from '@/services/google-services';
import Vue from 'vue';
import App from './App.vue';
import axios from './axios';
import currentUser from './common/current-user';
import eventBus from './events';
import './plugins/braintree';
import './plugins/browser-detect';
import './plugins/components';
import './plugins/fullcalendar';
import i18n from './plugins/i18n';
import './plugins/perfectscrollbar';
import './plugins/masonrywall';
import './plugins/vee-validate';
import './plugins/vue-linkify';
import './plugins/vue-snotify';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import './styles/main.scss';
import './styles/overview.scss';
import ClientUtils from './utils/client-utils';
import AuthServices from './services/auth-services';

declare const window: any;
const debug = process.env.NODE_ENV !== 'production';

Vue.prototype.$axios = axios;
Vue.prototype.$eventBus = eventBus;
Vue.prototype.$currentUser = currentUser;
Vue.prototype.$google = gs;
Vue.config.productionTip = false;
Vue.config.performance = debug;

const app = new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
  beforeCreate() {
    ClientUtils.getClientViewId();
  },
  mounted() {
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  },
  destroyed() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  },
  methods: {
    async handleBeforeUnload() {
      AuthServices.invalidateSession(ClientUtils.removeClientViewId());
    }
  }
}).$mount('#app');

if (debug) {
  window.app = app;
}
