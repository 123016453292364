var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isModal
    ? _c(
        "pb-base-modal",
        {
          attrs: {
            persistent: "",
            subpage: _vm.isSubpage,
            expandable: _vm.expandable,
            withActions: _vm.withActions,
            withApply: _vm.withApply,
            input: _vm.input,
            onApply: _vm.onApply,
            onClose: _vm.doOnClose,
            cardClass: _vm.cardClass
          },
          on: { actions: _vm.onActions },
          model: {
            value: _vm.localValue,
            callback: function($$v) {
              _vm.localValue = $$v
            },
            expression: "localValue"
          }
        },
        [
          _vm._t("header", null, { slot: "header" }),
          _vm._t("actions", null, { slot: "actions" }),
          _vm._t("action-button", null, { slot: "action-button" }),
          _vm._t("bottom-actions", null, { slot: "bottom-actions" }),
          _c(
            "div",
            [
              _vm._t("default", null, null, _vm.input),
              _c("pb-confirm", { ref: "confirm" })
            ],
            2
          )
        ],
        2
      )
    : _c(
        "pb-base-sub-page",
        {
          attrs: {
            input: _vm.input,
            withActions: _vm.withActions,
            expandable: _vm.expandable,
            withApply: _vm.withApply,
            onApply: _vm.onApply,
            onClose: _vm.doOnClose,
            applying: _vm.applying,
            cardClass: _vm.cardClass
          },
          on: { actions: _vm.onActions },
          model: {
            value: _vm.localValue,
            callback: function($$v) {
              _vm.localValue = $$v
            },
            expression: "localValue"
          }
        },
        [
          _vm._t("header", null, { slot: "header" }),
          _vm._t("actions", null, { slot: "actions" }),
          _vm._t("action-button", null, { slot: "action-button" }),
          _vm._t("bottom-actions", null, { slot: "bottom-actions" }),
          _c(
            "div",
            [
              _vm._t("default", null, null, _vm.input),
              _c("pb-confirm", { ref: "confirm" })
            ],
            2
          )
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }