import { UserSession } from '@/common/user-session';
import AppError, { createErrOptions, SessionExpiredError } from '@/errors';
import MessageServices from '@/services/message-services';
import CommonUtils from '@/utils/common-utils';
import moment from 'moment';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '..';
import StudentUtils from '@/utils/student-utils';
import ld from 'lodash';
import CurrentUser from '@/common/current-user';

const debug = false;

@Module({ namespaced: true })
export default class Messages extends VuexModule {
    allMessages: Array<any> = [];
    recipientsList: Array<any> = [];
    selectedMessage = Messages.messageDefault();
    originalSelectedMessage = Messages.messageDefault();
    selectedRecipients: Array<any> = [];
    originalRecipients: Array<any> = [];
    attachments: any[] = [];
    activeTab = 0;
    listLoading = false;

    replyId = 0;

    // toolbar attributes
    searchText = '';
    selectedRows: Array<any> = [];
    editMode = false;

    private static messageDefault() {
      return {
        recipientType: [],
        authorType: '',
        authorName: '',
        commentDate: '',
        commentId: 0,
        recipientId: 0,
        recipientName: '',
        replyType: '',
        replyId: 0,
        authorId: 0,
        messagePrivate: false,
        attachToLesson: 0,
        attachToMessage: 0,
        commentText: '',
        attachments: []
      }
    }

    get getDefaultMessage() {
      return Messages.messageDefault();
    }

    get getAllAttachments() {
      return this.attachments;
    }

    get getAllMessages() {
      return this.allMessages;
    }

    get getMessage() {
      return this.selectedMessage;
    }

    get getRecipientsList() {
      return this.recipientsList;
    }

    get getListLoading() {
      return this.listLoading;
    }

    get getReplyId() {
      return this.replyId;
    }

    @Mutation
    setReplyId(id: number) {
      this.replyId = id;
    }

    @Mutation
    setEditMode(editMode: boolean) {
      this.editMode = editMode;
    }

    @Mutation
    setSelectedRows(selectedRows: Array<any>) {
      this.selectedRows = selectedRows;
    }

    @Mutation
    setSearchText(searchText: string) {
      this.searchText = searchText;
    }

    @Mutation
    resetMessage() {
      this.activeTab = 0;
      this.selectedMessage = Messages.messageDefault();
    }

    @Mutation
    setActiveTab(tab: number) {
      this.activeTab = tab;
    }

    @Mutation
    setMessage(message: any) {
      const newMessage: any = {};
      newMessage.recipientType = message.recipientType;
      newMessage.authorType = message.authorType;
      newMessage.authorName = message.authorName;
      newMessage.commentDate = message.commentDate;
      newMessage.commentId = message.commentId;
      newMessage.recipientId = message.recipientId;
      newMessage.recipientName = message.recipientName;
      newMessage.replyType = message.replyType;
      newMessage.replyId = message.replyId;
      newMessage.authorId = message.authorId;
      newMessage.messagePrivate = message.messagePrivate;
      newMessage.attachToLesson = message.attachToLesson;
      newMessage.attachToMessage = message.attachToMessage;
      newMessage.commentText = message.commentText;
      newMessage.attachments = CommonUtils.getOrDefaultTo(message.attachments, []);
      for (const i in newMessage.attachments) newMessage.attachments[i].filename = newMessage.attachments[i].name;
      this.selectedMessage = newMessage;
    }

    @Mutation
    setOriginalMessage(message: any) {
      const newMessage: any = {};
      newMessage.recipientType = message.recipientType;
      newMessage.authorType = message.authorType;
      newMessage.authorName = message.authorName;
      newMessage.commentDate = message.commentDate;
      newMessage.commentId = message.commentId;
      newMessage.recipientId = message.recipientId;
      newMessage.recipientName = message.recipientName;
      newMessage.replyType = message.replyType;
      newMessage.replyId = message.replyId;
      newMessage.authorId = message.authorId;
      newMessage.messagePrivate = message.messagePrivate;
      newMessage.attachToLesson = message.attachToLesson;
      newMessage.attachToMessage = message.attachToMessage;
      newMessage.commentText = message.commentText;
      newMessage.attachments = CommonUtils.getOrDefaultTo(message.attachments, []);
      for (const i in newMessage.attachments) newMessage.attachments[i].filename = newMessage.attachments[i].name;
      this.originalSelectedMessage = newMessage;
    }

    @Mutation
    setSelectedReceipients(recipients: any) {
      this.selectedRecipients = recipients;
    }

    @Mutation
    setOriginalRecipients() {
      this.originalRecipients = ld.cloneDeep(this.selectedRecipients);
    }

    @Mutation
    setMessages(messages: Array<any>) {
      this.allMessages = messages;
    }

    @Mutation
    setRecipientsList(recipientsList: Array<any>) {
      this.recipientsList = recipientsList;
    }

    @Mutation
    setAttachments(attachments: Array<any>) {
      this.attachments = attachments;
    }

    @Mutation
    setListLoading(listLoading: boolean) {
      this.listLoading = listLoading;
    }

    @Action({ rawError: true })
    async loadMessages(param: any) {
      try {
        const loggedInUserMode = this.context.rootState.settings.userMode;
        if (loggedInUserMode === 'A' && param.teacherId === 0) {
          param.yearId = this.context.rootState.settings.currentYearId;
        } else if (loggedInUserMode === 'T') {
          param.teacherId = UserSession.getCurrentUser()?.teacherId;
        } else if (loggedInUserMode === 'S') {
          param.studentId = UserSession.getCurrentUser()?.studentId;
        }
        const resp = await MessageServices.getMessages({
          userMode: loggedInUserMode,
          teacherId: param.teacherId,
          yearId: param.yearId,
          studentId: param.studentId || 0
        });
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            if (debug) {
              console.log('GET MESSAGES RESPONSE --> ' + JSON.stringify(data));
            }
            this.context.commit('setMessages', data.comments || []);
            this.context.commit('settings/setNewCommentA', 'N', { root: true });
            this.context.commit('settings/setNewCommentT', 'N', { root: true });
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async loadMessage(param: any) {
      try {
        const loggedInUserMode = this.context.rootState.settings.userMode;
        const resp = await MessageServices.getMessage({
          userMode: loggedInUserMode,
          commentId: param
        });
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async loadRecipients() {
      try {
        const userMode = this.context.rootState.settings.userMode;
        const studentId = this.context.rootState.settings.studentId || 0;
        if (userMode === 'S' && studentId === 0) {
          return Promise.resolve();
        }
        const request: any = {
          userMode,
          user1: 0,
          type: 'recipients',
          schoolIds: this.context.rootGetters['settings/getSchoolIds']
        }
        const resp = await MessageServices.getRecipients(request);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            if (debug) {
              console.log('GET RECIPIENTS RESPONSE --> ' + JSON.stringify(data));
            }
            this.context.commit('setRecipientsList', Messages.prepareRecipientsList(data, this.context) || []);
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    private static prepareRecipientsList(responseData: any, context: any) {
      const list: Array<any> = [];
      if (context.rootState.settings.userMode !== 'S' && CurrentUser.schoolId > 0) {
        list.push({
          recipientId: 0,
          recipientName: 'Administration',
          recipientType: 'A'
        })
      }
      responseData.teachers.forEach((teacher: any) => {
        list.push({
          recipientId: teacher.teacherId,
          recipientName: CommonUtils.hasText(teacher.displayName) ? teacher.displayName : teacher.lastName + ', ' + teacher.firstName,
          recipientType: (teacher.userType === 'T') ? 'T' : 'A',
          initials: StudentUtils.getStudentInitials(teacher),
          photoUrl: teacher.photoUrl || '',
          schoolId: teacher.schoolId
        })
      });
      if (context.rootState.settings.userMode === 'T') {
        list.push({
          recipientId: context.rootState.settings.currentYearId,
          recipientName: 'Students - All (' + responseData.studentCount + ')',
          recipientType: 'Y'
        })
        responseData.classes.forEach((c: any) => {
          list.push({
            recipientId: c.subjectId,
            recipientName: 'Students - ' + c.className + ' (' + c.numStudents + ')',
            recipientType: 'C'
          })
        });
        responseData.students.forEach((student: any) => {
          list.push({
            recipientId: student.studentId,
            recipientName: StudentUtils.getStudentLabel(student),
            recipientType: 'S',
            initials: StudentUtils.getStudentInitials(student),
            photoUrl: student.photoUrl || ''
          })
        });
      }
      return list;
    }

    @Action({ rawError: true })
    async addMessage(params?: any) {
      try {
        if (params) {
          const param = Messages.createParamsForNewMessage(params, this.context.rootState.settings.userMode)
          const resp = await MessageServices.addMessage(param);
          if (resp) {
            const data = resp.data;
            if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
              return Promise.reject(new SessionExpiredError());
            } else if (data.error === 'true' || data.error === true) {
              return Promise.reject(new AppError('contactSupport', { data }));
            } else {
              this.context.commit('setMessages', data.comments || []);
              if (param.lessonId > 0) {
                this.context.dispatch('plans/reloadPlans', { showLoading: false }, { root: true });
              }
              if (debug) {
                console.log('ADD MESSAGE RESPONSE --> ' + JSON.stringify(data));
              }
              return Promise.resolve({ data });
            }
          } else {
            return Promise.resolve({ data: {} });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async deleteMessage(params?: any) {
      try {
        if (params) {
          const resp = await MessageServices.deleteMessage({
            commentId: params.commentId,
            studentId: 0,
            teacherId: UserSession.getCurrentUser()?.teacherId,
            userMode: this.context.rootState.settings.userMode
          });
          if (resp) {
            const data = resp.data;
            if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
              return Promise.reject(new SessionExpiredError());
            } else if (data.error === 'true' || data.error === true) {
              return Promise.reject(new AppError('contactSupport', { data }));
            } else {
              this.context.commit('setMessages', data.comments || []);
              return Promise.resolve({ data });
            }
          } else {
            return Promise.resolve({ data: {} });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async deleteMessages(params?: any) {
      try {
        if (params) {
          const resp = await MessageServices.deleteMessages(params);
          if (resp) {
            const data = resp.data;
            if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
              return Promise.reject(new SessionExpiredError());
            } else if (data.error === 'true' || data.error === true) {
              return Promise.reject(new AppError('contactSupport', { data }));
            } else {
              // this.context.commit('setMessages', data.comments || []);
              return Promise.resolve({ data });
            }
          } else {
            return Promise.resolve({ data: {} });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    private static createParamsForNewMessage(params: any, mode: any) {
      const state = store.state.messages;
      const param: any = {
        commentText: params.commentText,
        recipientId: params.recipientId,
        authorType: params.private ? 'P' : mode,
        recipientType: params.recipientType,
        teacherId: UserSession.getCurrentUser()?.teacherId,
        authorId: UserSession.getCurrentUser()?.teacherId,
        studentId: 0,
        userMode: mode,
        commentDate: moment().format('MM/DD/YYYY'),
        attachments: []
      };
      if (mode === 'S') {
        param.teacherId = 0;
        param.authorId = UserSession.getCurrentUser()?.studentId;
        param.studentId = UserSession.getCurrentUser()?.studentId;
      }
      if (params.lessonId) {
        param.replyType = 'LESSON';
        param.replyId = params.lessonId;
      } else if (params.attachToMessage) {
        param.replyType = 'COMMENT';
        param.replyId = params.attachToMessage;
      } else {
        param.replyType = '';
        param.replyId = 0;
      }
      param.attachments = state.attachments;
      const attachments = CommonUtils.getOrDefaultTo(param.attachments, []);
      for (const i in attachments) {
        const attachment = attachments[i];
        param['attachments[' + i + '].url'] = attachment.url;
        param['attachments[' + i + '].name'] = attachment.name || attachment.filename;
        param['attachments[' + i + '].privateFlag'] = attachment.privateFlag;
        param['attachments[' + i + '].attachmentType'] = 'C';
      }
      return param;
    }
}
