











import CommonUtils from '@/utils/common-utils';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class FocusableChip extends Vue {
}
