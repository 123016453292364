















































import CommonUtils from '@/utils/common-utils';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const settings = namespace('settings');
const index = namespace('index');

@Component
export default class BaseModal extends Vue {
  @Prop({ required: false, type: [Object, Array], default: () => { return {} } })
  input!: any;

  @Prop({ type: Boolean })
  value!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  applying!: boolean;

  @Prop({ required: false, type: Boolean, default: true })
  withToolbar!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  preventClose!: boolean;

  @Prop({ required: false, type: Boolean, default: true })
  withActions!: boolean;

  @Prop({ required: false, type: Boolean, default: true })
  withApply!: boolean;

  @Prop({ required: false, type: Boolean, default: true })
  expandable!: boolean;

  @Prop({ required: false, type: Number, default: 800 })
  maxWidth!: number;

  @Prop({ required: false, type: [String, Object], default: '' })
  cardClass!: any;

  maxContentHeight = 'auto';

  @Prop({ required: false, type: Function })
  onClose?: () => Promise<any>;

  @Prop({ required: false, type: Function })
  onApply?: () => Promise<any>;

  @settings.State
  userMode!: string;

  @index.Getter
  isTeacherListFullWidth!: any;

  get localPage() {
    return CommonUtils.getPage(this.$route);
  }

  get teacherListContentWidth() {
    if (['plans', 'students', 'messages'].includes(this.localPage) && this.userMode === 'A') {
      return this.isTeacherListFullWidth ? 317 : 63;
    } else {
      return 0;
    }
  }

  get extraWidth() {
    return (this.teacherListContentWidth) + ((this.$vuetify.breakpoint.smAndDown) ? 2 : 82);
  }

  get fullScreen() {
    return this.$store.state.index.fullScreen;
  }

  set fullScreen(val: boolean) {
    this.$store.commit('index/setFullScreen', val, { root: true });
  }

  resize() {
    if (!this.applying) {
      this.fullScreen = !this.fullScreen
      const newWidth = (this.fullScreen) ? (window.innerWidth - this.extraWidth) : (600)
      this.$eventBus.$emit('resizeSubPage', {
        width: newWidth,
        fullScreen: this.fullScreen
      });
    }
  }

  @Watch('isTeacherListFullWidth')
  resizeWindow() {
    if (this.fullScreen) {
      this.$eventBus.$emit('resizeSubPage', {
        width: window.innerWidth - this.extraWidth,
        fullScreen: this.fullScreen
      });
    }
  }

  doClose() {
    if (this.onClose) {
      return this.onClose().then((result: boolean) => {
        this.localValue = result;
        if (!result) {
          this.reset();
          this.$eventBus.$emit('closeSubPage');
        }
      });
    } else {
      this.localValue = false;
      this.reset();
      this.$eventBus.$emit('closeSubPage');
    }
  }

  doApply() {
    if (this.applying) {
      return;
    }
    if (this.onApply) {
      return this.onApply().then((result: boolean) => {
        this.localValue = result;
        if (!result) {
          this.reset();
          this.$eventBus.$emit('closeSubPage');
        }
      })
    } else {
      this.$emit('apply');
      this.reset();
    }
  }

  @Watch('localValue')
  determineMaxContentHeight() {
    if (this.localValue) {
      try {
        this.$nextTick(() => {
          new Promise(resolve => setTimeout(resolve, 50)).then(() => {
            try {
              const toolbarElement = this.$refs.toolbar as HTMLElement;
              const bottombarElement = this.$refs.bottombar as HTMLElement;
              const trect = toolbarElement.getBoundingClientRect();
              const brect = bottombarElement.getBoundingClientRect();
              this.maxContentHeight = 'calc(100vh - ' + (trect.top + trect.height + brect.height) + 'px)';
            } catch (e) {
              this.maxContentHeight = 'auto';
            }
          });
        });
      } catch (e) {
        this.maxContentHeight = 'auto';
      }
    }
  }

  mounted() {
    this.determineMaxContentHeight();
  }

  created() {
    this.$nextTick(() => {
      this.$eventBus.$on('resize', this.resizeWindow);
    })
  }

  beforeDestroy() {
    this.$eventBus.$off('resize', this.resizeWindow);
  }

  reset() {
    this.fullScreen = false;
  }

  get localValue() {
    return this.value;
  }

  set localValue(val: boolean) {
    this.$emit('input', val);
  }
}
