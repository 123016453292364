
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import LessonBankCsvEditorForm from '@/components/lessonlists/LessonBankCsvEditorForm.vue';
import { namespace } from 'vuex-class';
import Confirm from '../core/Confirm.vue';
import CommonUtils from '@/utils/common-utils';

const lessonlists = namespace('lessonlists');
const plans = namespace('plans');
const settings = namespace('settings');
@Component({
  components: {
    LessonBankCsvEditorForm
  }
})
export default class LessonBankCsvEditor extends Vue {
  @Prop({ type: Boolean })
  value!: boolean;

  @Prop({ type: Boolean, default: true })
  isModal!: boolean;

  @Prop({
    required: true,
    type: Object,
    default: () => {
      return {};
    }
  })
  input!: any;

  localFile:any = null;

  $refs!: {
    form: LessonBankCsvEditorForm,
    confirm: Confirm
  }

  @plans.Mutation
  resetPlans!: () => void;

  @lessonlists.Action
  uploadCSV!: (params?:any) => Promise<any>;

  @lessonlists.Getter('getSelectedLessonBankClassId')
  selectedLessonBankClassId!: number;

  @settings.Getter('getShowSnackbarNotifications')
  showSnackbarNotifications!: boolean;

  get localPage() {
    return CommonUtils.getPage(this.$route);
  }

  get isDirty() {
    return this.localFile !== null;
  }

  get localValue() {
    return this.value;
  }

  set localValue(val: boolean) {
    this.$emit('input', val);
  }

  fileOnChange(val:any) {
    this.localFile = val;
  }

  showActions(event: any) {
    console.log(event)
  }

  @Watch('input')
  init() {
    const form = this.$refs.form;
    if (form) {
      form.$refs.CSVEditorForm.resetValidation();
    }
  }

  doApply() {
    const form = this.$refs.form;
    if (form.$refs.CSVEditorForm.validate()) {
      CommonUtils.showLoading();
      const payload:any = {
        importCSVFile: this.localFile,
        exportType: 'pdf'
      }
      if (this.localPage === 'lesson-banks') {
        payload.CSVbankId = this.selectedLessonBankClassId;
      } else if (this.input.CSVclassId) {
        payload.CSVclassId = this.input.CSVclassId;
      }
      this.uploadCSV(payload).then(() => {
        this.resetPlans();
        this.localValue = false;
        this.$eventBus.$emit('resetField');
        this.$eventBus.$emit('lessonHasBeenSave', true);
        if (!this.isModal) {
          this.$eventBus.$emit('closeSubPage');
        }
        if (this.localPage === 'lesson-banks') {
          this.$eventBus.$emit('resetLessonBankData', { autoScrollBottom: true });

          if (this.showSnackbarNotifications) {
            this.$snotify.success(this.$t('statusMsg8') as string);
          }
        } else if (this.localPage === 'copy') {
          this.$eventBus.$emit('CSVuploaded');
          CommonUtils.hideLoading();
        } else {
          CommonUtils.hideLoading();
        }
      });
    }
  }

  doOnClose() {
    if (this.isDirty) {
      return this.$refs.confirm.confirm({
        title: this.$t('lessonBanksLabel'),
        text: this.$t('confirmAbandonUnsavedChangesGenericMsg'),
        option1ButtonAlternativeText: this.$t('continueLabel')
      }).then((result) => {
        if (result === 1) {
          this.$eventBus.$emit('resetField');
          return Promise.resolve(false);
        } else {
          return Promise.resolve(true);
        }
      });
    } else {
      return Promise.resolve(false);
    }
  }
}
