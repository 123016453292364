var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "editor" },
    [
      _c(
        "v-form",
        { ref: "standardEditorForm", attrs: { "lazy-validation": "" } },
        [
          _c(
            "v-container",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isTextFieldsVisible,
                  expression: "isTextFieldsVisible",
                },
              ],
              staticClass: "pb-1",
            },
            [
              _c("v-text-field", {
                attrs: {
                  label: _vm.$t("listIdLabel") + "*",
                  "aria-required": "true",
                  outlined: "",
                  clearable: "",
                  "hide-details": "auto",
                  rules: _vm.localRules,
                  "validate-on-blur": "",
                  autofocus: "",
                },
                model: {
                  value: _vm.localStandard.standardId,
                  callback: function ($$v) {
                    _vm.$set(_vm.localStandard, "standardId", $$v)
                  },
                  expression: "localStandard.standardId",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              key: _vm.localRefreshKey,
              staticStyle: { "margin-left": "1px" },
              on: {
                mouseenter: function ($event) {
                  _vm.isSectionsHovered = true
                },
                mouseleave: function ($event) {
                  _vm.isSectionsHovered = false
                },
              },
            },
            [
              _c("text-editor-full-screen-button", {
                attrs: {
                  isSectionsHovered: _vm.isSectionsHovered,
                  isTextFieldsVisible: _vm.isTextFieldsVisible,
                },
                on: { hideOrShowTextFields: _vm.hideOrShowTextFields },
              }),
              _c("pb-text-editor", {
                staticStyle: { "border-top": "1px solid rgb(204, 204, 204)" },
                attrs: {
                  refreshKey: _vm.localRefreshKey,
                  config: _vm.localTextEditorConfig,
                },
                model: {
                  value: _vm.localStandard.standardDescription,
                  callback: function ($$v) {
                    _vm.$set(_vm.localStandard, "standardDescription", $$v)
                  },
                  expression: "localStandard.standardDescription",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("pb-confirm", { ref: "standardEditorConfirm" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }