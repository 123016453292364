export const timeZoneLocations: any = {
  'Africa/Abidjan': {
    countryCode: 'CI',
    countryName: "Côte d'Ivoire",
    comment: '',
    latitude: 5.316666666666666,
    longitude: -4.033333333333333
  },
  'Africa/Accra': {
    countryCode: 'CI',
    countryName: "Côte d'Ivoire",
    comment: '',
    latitude: 5.316666666666666,
    longitude: -4.033333333333333
  },
  'Africa/Bamako': {
    countryCode: 'CI',
    countryName: "Côte d'Ivoire",
    comment: '',
    latitude: 5.316666666666666,
    longitude: -4.033333333333333
  },
  'Africa/Banjul': {
    countryCode: 'CI',
    countryName: "Côte d'Ivoire",
    comment: '',
    latitude: 5.316666666666666,
    longitude: -4.033333333333333
  },
  'Africa/Conakry': {
    countryCode: 'CI',
    countryName: "Côte d'Ivoire",
    comment: '',
    latitude: 5.316666666666666,
    longitude: -4.033333333333333
  },
  'Africa/Dakar': {
    countryCode: 'CI',
    countryName: "Côte d'Ivoire",
    comment: '',
    latitude: 5.316666666666666,
    longitude: -4.033333333333333
  },
  'Africa/Freetown': {
    countryCode: 'CI',
    countryName: "Côte d'Ivoire",
    comment: '',
    latitude: 5.316666666666666,
    longitude: -4.033333333333333
  },
  'Africa/Lome': {
    countryCode: 'CI',
    countryName: "Côte d'Ivoire",
    comment: '',
    latitude: 5.316666666666666,
    longitude: -4.033333333333333
  },
  'Africa/Nouakchott': {
    countryCode: 'CI',
    countryName: "Côte d'Ivoire",
    comment: '',
    latitude: 5.316666666666666,
    longitude: -4.033333333333333
  },
  'Africa/Ouagadougou': {
    countryCode: 'CI',
    countryName: "Côte d'Ivoire",
    comment: '',
    latitude: 5.316666666666666,
    longitude: -4.033333333333333
  },
  'Africa/Timbuktu': {
    countryCode: 'CI',
    countryName: "Côte d'Ivoire",
    comment: '',
    latitude: 5.316666666666666,
    longitude: -4.033333333333333
  },
  'Atlantic/Reykjavik': {
    countryCode: 'CI',
    countryName: "Côte d'Ivoire",
    comment: '',
    latitude: 5.316666666666666,
    longitude: -4.033333333333333
  },
  'Atlantic/St_Helena': {
    countryCode: 'CI',
    countryName: "Côte d'Ivoire",
    comment: '',
    latitude: 5.316666666666666,
    longitude: -4.033333333333333
  },
  Iceland: {
    countryCode: 'CI',
    countryName: "Côte d'Ivoire",
    comment: '',
    latitude: 5.316666666666666,
    longitude: -4.033333333333333
  },
  'Africa/Algiers': {
    countryCode: 'DZ',
    countryName: 'Algeria',
    comment: '',
    latitude: 36.78333333333333,
    longitude: 3.05
  },
  'Africa/Bissau': {
    countryCode: 'GW',
    countryName: 'Guinea-Bissau',
    comment: '',
    latitude: 11.85,
    longitude: -15.583333333333334
  },
  'Africa/Cairo': {
    countryCode: 'EG',
    countryName: 'Egypt',
    comment: '',
    latitude: 30.05,
    longitude: 31.25
  },
  Egypt: {
    countryCode: 'EG',
    countryName: 'Egypt',
    comment: '',
    latitude: 30.05,
    longitude: 31.25
  },
  'Africa/Casablanca': {
    countryCode: 'MA',
    countryName: 'Morocco',
    comment: '',
    latitude: 33.65,
    longitude: -7.583333333333333
  },
  'Africa/Ceuta': {
    countryCode: 'ES',
    countryName: 'Spain',
    comment: 'Ceuta, Melilla',
    latitude: 35.88333333333333,
    longitude: -5.316666666666666
  },
  'Africa/El_Aaiun': {
    countryCode: 'EH',
    countryName: 'Western Sahara',
    comment: '',
    latitude: 27.15,
    longitude: -13.2
  },
  'Africa/Johannesburg': {
    countryCode: 'ZA',
    countryName: 'South Africa',
    comment: '',
    latitude: -26.25,
    longitude: 28
  },
  'Africa/Maseru': {
    countryCode: 'ZA',
    countryName: 'South Africa',
    comment: '',
    latitude: -26.25,
    longitude: 28
  },
  'Africa/Mbabane': {
    countryCode: 'ZA',
    countryName: 'South Africa',
    comment: '',
    latitude: -26.25,
    longitude: 28
  },
  'Africa/Juba': {
    countryCode: 'SS',
    countryName: 'South Sudan',
    comment: '',
    latitude: 4.85,
    longitude: 31.616666666666667
  },
  'Africa/Khartoum': {
    countryCode: 'SD',
    countryName: 'Sudan',
    comment: '',
    latitude: 15.6,
    longitude: 32.53333333333333
  },
  'Africa/Lagos': {
    countryCode: 'NG',
    countryName: 'Nigeria',
    comment: '',
    latitude: 6.45,
    longitude: 3.4
  },
  'Africa/Bangui': {
    countryCode: 'NG',
    countryName: 'Nigeria',
    comment: '',
    latitude: 6.45,
    longitude: 3.4
  },
  'Africa/Brazzaville': {
    countryCode: 'NG',
    countryName: 'Nigeria',
    comment: '',
    latitude: 6.45,
    longitude: 3.4
  },
  'Africa/Douala': {
    countryCode: 'NG',
    countryName: 'Nigeria',
    comment: '',
    latitude: 6.45,
    longitude: 3.4
  },
  'Africa/Kinshasa': {
    countryCode: 'NG',
    countryName: 'Nigeria',
    comment: '',
    latitude: 6.45,
    longitude: 3.4
  },
  'Africa/Libreville': {
    countryCode: 'NG',
    countryName: 'Nigeria',
    comment: '',
    latitude: 6.45,
    longitude: 3.4
  },
  'Africa/Luanda': {
    countryCode: 'NG',
    countryName: 'Nigeria',
    comment: '',
    latitude: 6.45,
    longitude: 3.4
  },
  'Africa/Malabo': {
    countryCode: 'NG',
    countryName: 'Nigeria',
    comment: '',
    latitude: 6.45,
    longitude: 3.4
  },
  'Africa/Niamey': {
    countryCode: 'NG',
    countryName: 'Nigeria',
    comment: '',
    latitude: 6.45,
    longitude: 3.4
  },
  'Africa/Porto-Novo': {
    countryCode: 'NG',
    countryName: 'Nigeria',
    comment: '',
    latitude: 6.45,
    longitude: 3.4
  },
  'Africa/Maputo': {
    countryCode: 'MZ',
    countryName: 'Mozambique',
    comment: '',
    latitude: -25.966666666666665,
    longitude: 32.583333333333336
  },
  'Africa/Blantyre': {
    countryCode: 'MZ',
    countryName: 'Mozambique',
    comment: '',
    latitude: -25.966666666666665,
    longitude: 32.583333333333336
  },
  'Africa/Bujumbura': {
    countryCode: 'MZ',
    countryName: 'Mozambique',
    comment: '',
    latitude: -25.966666666666665,
    longitude: 32.583333333333336
  },
  'Africa/Gaborone': {
    countryCode: 'MZ',
    countryName: 'Mozambique',
    comment: '',
    latitude: -25.966666666666665,
    longitude: 32.583333333333336
  },
  'Africa/Harare': {
    countryCode: 'MZ',
    countryName: 'Mozambique',
    comment: '',
    latitude: -25.966666666666665,
    longitude: 32.583333333333336
  },
  'Africa/Kigali': {
    countryCode: 'MZ',
    countryName: 'Mozambique',
    comment: '',
    latitude: -25.966666666666665,
    longitude: 32.583333333333336
  },
  'Africa/Lubumbashi': {
    countryCode: 'MZ',
    countryName: 'Mozambique',
    comment: '',
    latitude: -25.966666666666665,
    longitude: 32.583333333333336
  },
  'Africa/Lusaka': {
    countryCode: 'MZ',
    countryName: 'Mozambique',
    comment: '',
    latitude: -25.966666666666665,
    longitude: 32.583333333333336
  },
  'Africa/Monrovia': {
    countryCode: 'LR',
    countryName: 'Liberia',
    comment: '',
    latitude: 6.3,
    longitude: -10.783333333333333
  },
  'Africa/Nairobi': {
    countryCode: 'KE',
    countryName: 'Kenya',
    comment: '',
    latitude: -1.2833333333333334,
    longitude: 36.81666666666667
  },
  'Africa/Addis_Ababa': {
    countryCode: 'KE',
    countryName: 'Kenya',
    comment: '',
    latitude: -1.2833333333333334,
    longitude: 36.81666666666667
  },
  'Africa/Asmara': {
    countryCode: 'KE',
    countryName: 'Kenya',
    comment: '',
    latitude: -1.2833333333333334,
    longitude: 36.81666666666667
  },
  'Africa/Asmera': {
    countryCode: 'KE',
    countryName: 'Kenya',
    comment: '',
    latitude: -1.2833333333333334,
    longitude: 36.81666666666667
  },
  'Africa/Dar_es_Salaam': {
    countryCode: 'KE',
    countryName: 'Kenya',
    comment: '',
    latitude: -1.2833333333333334,
    longitude: 36.81666666666667
  },
  'Africa/Djibouti': {
    countryCode: 'KE',
    countryName: 'Kenya',
    comment: '',
    latitude: -1.2833333333333334,
    longitude: 36.81666666666667
  },
  'Africa/Kampala': {
    countryCode: 'KE',
    countryName: 'Kenya',
    comment: '',
    latitude: -1.2833333333333334,
    longitude: 36.81666666666667
  },
  'Africa/Mogadishu': {
    countryCode: 'KE',
    countryName: 'Kenya',
    comment: '',
    latitude: -1.2833333333333334,
    longitude: 36.81666666666667
  },
  'Indian/Antananarivo': {
    countryCode: 'KE',
    countryName: 'Kenya',
    comment: '',
    latitude: -1.2833333333333334,
    longitude: 36.81666666666667
  },
  'Indian/Comoro': {
    countryCode: 'KE',
    countryName: 'Kenya',
    comment: '',
    latitude: -1.2833333333333334,
    longitude: 36.81666666666667
  },
  'Indian/Mayotte': {
    countryCode: 'KE',
    countryName: 'Kenya',
    comment: '',
    latitude: -1.2833333333333334,
    longitude: 36.81666666666667
  },
  'Africa/Ndjamena': {
    countryCode: 'TD',
    countryName: 'Chad',
    comment: '',
    latitude: 12.116666666666667,
    longitude: 15.05
  },
  'Africa/Sao_Tome': {
    countryCode: 'ST',
    countryName: 'Sao Tome & Principe',
    comment: '',
    latitude: 0.3333333333333333,
    longitude: 6.733333333333333
  },
  'Africa/Tripoli': {
    countryCode: 'LY',
    countryName: 'Libya',
    comment: '',
    latitude: 32.9,
    longitude: 13.183333333333334
  },
  Libya: {
    countryCode: 'LY',
    countryName: 'Libya',
    comment: '',
    latitude: 32.9,
    longitude: 13.183333333333334
  },
  'Africa/Tunis': {
    countryCode: 'TN',
    countryName: 'Tunisia',
    comment: '',
    latitude: 36.8,
    longitude: 10.183333333333334
  },
  'Africa/Windhoek': {
    countryCode: 'NA',
    countryName: 'Namibia',
    comment: '',
    latitude: -22.566666666666666,
    longitude: 17.1
  },
  'America/Adak': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Aleutian Islands',
    latitude: 51.88,
    longitude: -176.65805555555556
  },
  'America/Atka': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Aleutian Islands',
    latitude: 51.88,
    longitude: -176.65805555555556
  },
  'US/Aleutian': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Aleutian Islands',
    latitude: 51.88,
    longitude: -176.65805555555556
  },
  'America/Anchorage': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Alaska (most areas)',
    latitude: 61.21805555555556,
    longitude: -149.90027777777777
  },
  'US/Alaska': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Alaska (most areas)',
    latitude: 61.21805555555556,
    longitude: -149.90027777777777
  },
  'America/Araguaina': {
    countryCode: 'BR',
    countryName: 'Brazil',
    comment: 'Tocantins',
    latitude: -7.2,
    longitude: -48.2
  },
  'America/Argentina/Buenos_Aires': {
    countryCode: 'AR',
    countryName: 'Argentina',
    comment: 'Buenos Aires (BA, CF)',
    latitude: -34.6,
    longitude: -58.45
  },
  'America/Buenos_Aires': {
    countryCode: 'AR',
    countryName: 'Argentina',
    comment: 'Buenos Aires (BA, CF)',
    latitude: -34.6,
    longitude: -58.45
  },
  'America/Argentina/Catamarca': {
    countryCode: 'AR',
    countryName: 'Argentina',
    comment: 'Catamarca (CT); Chubut (CH)',
    latitude: -28.466666666666665,
    longitude: -65.78333333333333
  },
  'America/Argentina/ComodRivadavia': {
    countryCode: 'AR',
    countryName: 'Argentina',
    comment: 'Catamarca (CT); Chubut (CH)',
    latitude: -28.466666666666665,
    longitude: -65.78333333333333
  },
  'America/Catamarca': {
    countryCode: 'AR',
    countryName: 'Argentina',
    comment: 'Catamarca (CT); Chubut (CH)',
    latitude: -28.466666666666665,
    longitude: -65.78333333333333
  },
  'America/Argentina/Cordoba': {
    countryCode: 'AR',
    countryName: 'Argentina',
    comment: 'Argentina (most areas: CB, CC, CN, ER, FM, MN, SE, SF)',
    latitude: -31.4,
    longitude: -64.18333333333334
  },
  'America/Cordoba': {
    countryCode: 'AR',
    countryName: 'Argentina',
    comment: 'Argentina (most areas: CB, CC, CN, ER, FM, MN, SE, SF)',
    latitude: -31.4,
    longitude: -64.18333333333334
  },
  'America/Rosario': {
    countryCode: 'AR',
    countryName: 'Argentina',
    comment: 'Argentina (most areas: CB, CC, CN, ER, FM, MN, SE, SF)',
    latitude: -31.4,
    longitude: -64.18333333333334
  },
  'America/Argentina/Jujuy': {
    countryCode: 'AR',
    countryName: 'Argentina',
    comment: 'Jujuy (JY)',
    latitude: -24.183333333333334,
    longitude: -65.3
  },
  'America/Jujuy': {
    countryCode: 'AR',
    countryName: 'Argentina',
    comment: 'Jujuy (JY)',
    latitude: -24.183333333333334,
    longitude: -65.3
  },
  'America/Argentina/La_Rioja': {
    countryCode: 'AR',
    countryName: 'Argentina',
    comment: 'La Rioja (LR)',
    latitude: -29.433333333333334,
    longitude: -66.85
  },
  'America/Argentina/Mendoza': {
    countryCode: 'AR',
    countryName: 'Argentina',
    comment: 'Mendoza (MZ)',
    latitude: -32.88333333333333,
    longitude: -68.81666666666666
  },
  'America/Mendoza': {
    countryCode: 'AR',
    countryName: 'Argentina',
    comment: 'Mendoza (MZ)',
    latitude: -32.88333333333333,
    longitude: -68.81666666666666
  },
  'America/Argentina/Rio_Gallegos': {
    countryCode: 'AR',
    countryName: 'Argentina',
    comment: 'Santa Cruz (SC)',
    latitude: -51.63333333333333,
    longitude: -69.21666666666667
  },
  'America/Argentina/Salta': {
    countryCode: 'AR',
    countryName: 'Argentina',
    comment: 'Salta (SA, LP, NQ, RN)',
    latitude: -24.783333333333335,
    longitude: -65.41666666666667
  },
  'America/Argentina/San_Juan': {
    countryCode: 'AR',
    countryName: 'Argentina',
    comment: 'San Juan (SJ)',
    latitude: -31.533333333333335,
    longitude: -68.51666666666667
  },
  'America/Argentina/San_Luis': {
    countryCode: 'AR',
    countryName: 'Argentina',
    comment: 'San Luis (SL)',
    latitude: -33.31666666666667,
    longitude: -66.35
  },
  'America/Argentina/Tucuman': {
    countryCode: 'AR',
    countryName: 'Argentina',
    comment: 'Tucuman (TM)',
    latitude: -26.816666666666666,
    longitude: -65.21666666666667
  },
  'America/Argentina/Ushuaia': {
    countryCode: 'AR',
    countryName: 'Argentina',
    comment: 'Tierra del Fuego (TF)',
    latitude: -54.8,
    longitude: -68.3
  },
  'America/Asuncion': {
    countryCode: 'PY',
    countryName: 'Paraguay',
    comment: '',
    latitude: -25.266666666666666,
    longitude: -57.666666666666664
  },
  'America/Bahia': {
    countryCode: 'BR',
    countryName: 'Brazil',
    comment: 'Bahia',
    latitude: -12.983333333333333,
    longitude: -38.516666666666666
  },
  'America/Bahia_Banderas': {
    countryCode: 'MX',
    countryName: 'Mexico',
    comment: 'Bahia de Banderas',
    latitude: 20.8,
    longitude: -105.25
  },
  'America/Barbados': {
    countryCode: 'BB',
    countryName: 'Barbados',
    comment: '',
    latitude: 13.1,
    longitude: -59.61666666666667
  },
  'America/Belem': {
    countryCode: 'BR',
    countryName: 'Brazil',
    comment: 'Para (east); Amapa',
    latitude: -1.45,
    longitude: -48.483333333333334
  },
  'America/Belize': {
    countryCode: 'BZ',
    countryName: 'Belize',
    comment: '',
    latitude: 17.5,
    longitude: -88.2
  },
  'America/Boa_Vista': {
    countryCode: 'BR',
    countryName: 'Brazil',
    comment: 'Roraima',
    latitude: 2.816666666666667,
    longitude: -60.666666666666664
  },
  'America/Bogota': {
    countryCode: 'CO',
    countryName: 'Colombia',
    comment: '',
    latitude: 4.6,
    longitude: -74.08333333333333
  },
  'America/Boise': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Mountain - ID (south); OR (east)',
    latitude: 43.61361111111111,
    longitude: -116.2025
  },
  'America/Cambridge_Bay': {
    countryCode: 'CA',
    countryName: 'Canada',
    comment: 'Mountain - NU (west)',
    latitude: 69.1138888888889,
    longitude: -105.05277777777778
  },
  'America/Campo_Grande': {
    countryCode: 'BR',
    countryName: 'Brazil',
    comment: 'Mato Grosso do Sul',
    latitude: -20.45,
    longitude: -54.61666666666667
  },
  'America/Cancun': {
    countryCode: 'MX',
    countryName: 'Mexico',
    comment: 'Quintana Roo',
    latitude: 21.083333333333332,
    longitude: -86.76666666666667
  },
  'America/Caracas': {
    countryCode: 'VE',
    countryName: 'Venezuela',
    comment: '',
    latitude: 10.5,
    longitude: -66.93333333333334
  },
  'America/Cayenne': {
    countryCode: 'GF',
    countryName: 'French Guiana',
    comment: '',
    latitude: 4.933333333333334,
    longitude: -52.333333333333336
  },
  'America/Chicago': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Central (most areas)',
    latitude: 41.85,
    longitude: -87.65
  },
  'US/Central': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Central (most areas)',
    latitude: 41.85,
    longitude: -87.65
  },
  'America/Chihuahua': {
    countryCode: 'MX',
    countryName: 'Mexico',
    comment: 'Chihuahua (most areas)',
    latitude: 28.633333333333333,
    longitude: -106.08333333333333
  },
  'America/Ciudad_Juarez': {
    countryCode: 'MX',
    countryName: 'Mexico',
    comment: 'Chihuahua (US border - west)',
    latitude: 31.733333333333334,
    longitude: -106.48333333333333
  },
  'America/Costa_Rica': {
    countryCode: 'CR',
    countryName: 'Costa Rica',
    comment: '',
    latitude: 9.933333333333334,
    longitude: -84.08333333333333
  },
  'America/Cuiaba': {
    countryCode: 'BR',
    countryName: 'Brazil',
    comment: 'Mato Grosso',
    latitude: -15.583333333333334,
    longitude: -56.083333333333336
  },
  'America/Danmarkshavn': {
    countryCode: 'GL',
    countryName: 'Greenland',
    comment: 'National Park (east coast)',
    latitude: 76.76666666666667,
    longitude: -18.666666666666668
  },
  'America/Dawson': {
    countryCode: 'CA',
    countryName: 'Canada',
    comment: 'MST - Yukon (west)',
    latitude: 64.06666666666666,
    longitude: -139.41666666666666
  },
  'America/Dawson_Creek': {
    countryCode: 'CA',
    countryName: 'Canada',
    comment: 'MST - BC (Dawson Cr, Ft St John)',
    latitude: 55.766666666666666,
    longitude: -120.23333333333333
  },
  'America/Denver': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Mountain (most areas)',
    latitude: 39.73916666666667,
    longitude: -104.98416666666667
  },
  'America/Shiprock': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Mountain (most areas)',
    latitude: 39.73916666666667,
    longitude: -104.98416666666667
  },
  Navajo: {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Mountain (most areas)',
    latitude: 39.73916666666667,
    longitude: -104.98416666666667
  },
  'US/Mountain': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Mountain (most areas)',
    latitude: 39.73916666666667,
    longitude: -104.98416666666667
  },
  'America/Detroit': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Eastern - MI (most areas)',
    latitude: 42.33138888888889,
    longitude: -83.04583333333333
  },
  'US/Michigan': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Eastern - MI (most areas)',
    latitude: 42.33138888888889,
    longitude: -83.04583333333333
  },
  'America/Edmonton': {
    countryCode: 'CA',
    countryName: 'Canada',
    comment: 'Mountain - AB; BC (E); SK (W)',
    latitude: 53.55,
    longitude: -113.46666666666667
  },
  'Canada/Mountain': {
    countryCode: 'CA',
    countryName: 'Canada',
    comment: 'Mountain - AB; BC (E); SK (W)',
    latitude: 53.55,
    longitude: -113.46666666666667
  },
  'America/Eirunepe': {
    countryCode: 'BR',
    countryName: 'Brazil',
    comment: 'Amazonas (west)',
    latitude: -6.666666666666667,
    longitude: -69.86666666666666
  },
  'America/El_Salvador': {
    countryCode: 'SV',
    countryName: 'El Salvador',
    comment: '',
    latitude: 13.7,
    longitude: -89.2
  },
  'America/Fort_Nelson': {
    countryCode: 'CA',
    countryName: 'Canada',
    comment: 'MST - BC (Ft Nelson)',
    latitude: 58.8,
    longitude: -122.7
  },
  'America/Fortaleza': {
    countryCode: 'BR',
    countryName: 'Brazil',
    comment: 'Brazil (northeast: MA, PI, CE, RN, PB)',
    latitude: -3.716666666666667,
    longitude: -38.5
  },
  'America/Glace_Bay': {
    countryCode: 'CA',
    countryName: 'Canada',
    comment: 'Atlantic - NS (Cape Breton)',
    latitude: 46.2,
    longitude: -59.95
  },
  'America/Goose_Bay': {
    countryCode: 'CA',
    countryName: 'Canada',
    comment: 'Atlantic - Labrador (most areas)',
    latitude: 53.333333333333336,
    longitude: -60.416666666666664
  },
  'America/Grand_Turk': {
    countryCode: 'TC',
    countryName: 'Turks & Caicos Is',
    comment: '',
    latitude: 21.466666666666665,
    longitude: -71.13333333333334
  },
  'America/Guatemala': {
    countryCode: 'GT',
    countryName: 'Guatemala',
    comment: '',
    latitude: 14.633333333333333,
    longitude: -90.51666666666667
  },
  'America/Guayaquil': {
    countryCode: 'EC',
    countryName: 'Ecuador',
    comment: 'Ecuador (mainland)',
    latitude: -2.1666666666666665,
    longitude: -79.83333333333333
  },
  'America/Guyana': {
    countryCode: 'GY',
    countryName: 'Guyana',
    comment: '',
    latitude: 6.8,
    longitude: -58.166666666666664
  },
  'America/Halifax': {
    countryCode: 'CA',
    countryName: 'Canada',
    comment: 'Atlantic - NS (most areas); PE',
    latitude: 44.65,
    longitude: -63.6
  },
  'Canada/Atlantic': {
    countryCode: 'CA',
    countryName: 'Canada',
    comment: 'Atlantic - NS (most areas); PE',
    latitude: 44.65,
    longitude: -63.6
  },
  'America/Havana': {
    countryCode: 'CU',
    countryName: 'Cuba',
    comment: '',
    latitude: 23.133333333333333,
    longitude: -82.36666666666666
  },
  Cuba: {
    countryCode: 'CU',
    countryName: 'Cuba',
    comment: '',
    latitude: 23.133333333333333,
    longitude: -82.36666666666666
  },
  'America/Hermosillo': {
    countryCode: 'MX',
    countryName: 'Mexico',
    comment: 'Sonora',
    latitude: 29.066666666666666,
    longitude: -110.96666666666667
  },
  'America/Indiana/Indianapolis': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Eastern - IN (most areas)',
    latitude: 39.76833333333333,
    longitude: -86.15805555555555
  },
  'America/Fort_Wayne': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Eastern - IN (most areas)',
    latitude: 39.76833333333333,
    longitude: -86.15805555555555
  },
  'America/Indianapolis': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Eastern - IN (most areas)',
    latitude: 39.76833333333333,
    longitude: -86.15805555555555
  },
  'US/East-Indiana': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Eastern - IN (most areas)',
    latitude: 39.76833333333333,
    longitude: -86.15805555555555
  },
  'America/Indiana/Knox': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Central - IN (Starke)',
    latitude: 41.295833333333334,
    longitude: -86.625
  },
  'America/Knox_IN': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Central - IN (Starke)',
    latitude: 41.295833333333334,
    longitude: -86.625
  },
  'US/Indiana-Starke': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Central - IN (Starke)',
    latitude: 41.295833333333334,
    longitude: -86.625
  },
  'America/Indiana/Marengo': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Eastern - IN (Crawford)',
    latitude: 38.37555555555556,
    longitude: -86.34472222222222
  },
  'America/Indiana/Petersburg': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Eastern - IN (Pike)',
    latitude: 38.49194444444444,
    longitude: -87.2786111111111
  },
  'America/Indiana/Tell_City': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Central - IN (Perry)',
    latitude: 37.95305555555556,
    longitude: -86.76138888888889
  },
  'America/Indiana/Vevay': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Eastern - IN (Switzerland)',
    latitude: 38.74777777777778,
    longitude: -85.06722222222223
  },
  'America/Indiana/Vincennes': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Eastern - IN (Da, Du, K, Mn)',
    latitude: 38.67722222222222,
    longitude: -87.5286111111111
  },
  'America/Indiana/Winamac': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Eastern - IN (Pulaski)',
    latitude: 41.05138888888889,
    longitude: -86.60305555555556
  },
  'America/Inuvik': {
    countryCode: 'CA',
    countryName: 'Canada',
    comment: 'Mountain - NT (west)',
    latitude: 68.34972222222223,
    longitude: -133.71666666666667
  },
  'America/Iqaluit': {
    countryCode: 'CA',
    countryName: 'Canada',
    comment: 'Eastern - NU (most areas)',
    latitude: 63.733333333333334,
    longitude: -68.46666666666667
  },
  'America/Pangnirtung': {
    countryCode: 'CA',
    countryName: 'Canada',
    comment: 'Eastern - NU (most areas)',
    latitude: 63.733333333333334,
    longitude: -68.46666666666667
  },
  'America/Jamaica': {
    countryCode: 'JM',
    countryName: 'Jamaica',
    comment: '',
    latitude: 17.968055555555555,
    longitude: -76.79333333333334
  },
  Jamaica: {
    countryCode: 'JM',
    countryName: 'Jamaica',
    comment: '',
    latitude: 17.968055555555555,
    longitude: -76.79333333333334
  },
  'America/Juneau': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Alaska - Juneau area',
    latitude: 58.301944444444445,
    longitude: -134.41972222222222
  },
  'America/Kentucky/Louisville': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Eastern - KY (Louisville area)',
    latitude: 38.25416666666667,
    longitude: -85.75944444444444
  },
  'America/Louisville': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Eastern - KY (Louisville area)',
    latitude: 38.25416666666667,
    longitude: -85.75944444444444
  },
  'America/Kentucky/Monticello': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Eastern - KY (Wayne)',
    latitude: 36.82972222222222,
    longitude: -84.84916666666666
  },
  'America/La_Paz': {
    countryCode: 'BO',
    countryName: 'Bolivia',
    comment: '',
    latitude: -16.5,
    longitude: -68.15
  },
  'America/Lima': {
    countryCode: 'PE',
    countryName: 'Peru',
    comment: '',
    latitude: -12.05,
    longitude: -77.05
  },
  'America/Los_Angeles': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Pacific',
    latitude: 34.05222222222222,
    longitude: -118.24277777777777
  },
  'US/Pacific': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Pacific',
    latitude: 34.05222222222222,
    longitude: -118.24277777777777
  },
  'America/Maceio': {
    countryCode: 'BR',
    countryName: 'Brazil',
    comment: 'Alagoas, Sergipe',
    latitude: -9.666666666666666,
    longitude: -35.71666666666667
  },
  'America/Managua': {
    countryCode: 'NI',
    countryName: 'Nicaragua',
    comment: '',
    latitude: 12.15,
    longitude: -86.28333333333333
  },
  'America/Manaus': {
    countryCode: 'BR',
    countryName: 'Brazil',
    comment: 'Amazonas (east)',
    latitude: -3.1333333333333333,
    longitude: -60.016666666666666
  },
  'Brazil/West': {
    countryCode: 'BR',
    countryName: 'Brazil',
    comment: 'Amazonas (east)',
    latitude: -3.1333333333333333,
    longitude: -60.016666666666666
  },
  'America/Martinique': {
    countryCode: 'MQ',
    countryName: 'Martinique',
    comment: '',
    latitude: 14.6,
    longitude: -61.083333333333336
  },
  'America/Matamoros': {
    countryCode: 'MX',
    countryName: 'Mexico',
    comment: 'Coahuila, Nuevo Leon, Tamaulipas (US border)',
    latitude: 25.833333333333332,
    longitude: -97.5
  },
  'America/Mazatlan': {
    countryCode: 'MX',
    countryName: 'Mexico',
    comment: 'Baja California Sur, Nayarit (most areas), Sinaloa',
    latitude: 23.216666666666665,
    longitude: -106.41666666666667
  },
  'Mexico/BajaSur': {
    countryCode: 'MX',
    countryName: 'Mexico',
    comment: 'Baja California Sur, Nayarit (most areas), Sinaloa',
    latitude: 23.216666666666665,
    longitude: -106.41666666666667
  },
  'America/Menominee': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Central - MI (Wisconsin border)',
    latitude: 45.10777777777778,
    longitude: -87.61416666666666
  },
  'America/Merida': {
    countryCode: 'MX',
    countryName: 'Mexico',
    comment: 'Campeche, Yucatan',
    latitude: 20.966666666666665,
    longitude: -89.61666666666666
  },
  'America/Metlakatla': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Alaska - Annette Island',
    latitude: 55.12694444444445,
    longitude: -131.57638888888889
  },
  'America/Mexico_City': {
    countryCode: 'MX',
    countryName: 'Mexico',
    comment: 'Central Mexico',
    latitude: 19.4,
    longitude: -99.15
  },
  'Mexico/General': {
    countryCode: 'MX',
    countryName: 'Mexico',
    comment: 'Central Mexico',
    latitude: 19.4,
    longitude: -99.15
  },
  'America/Miquelon': {
    countryCode: 'PM',
    countryName: 'St Pierre & Miquelon',
    comment: '',
    latitude: 47.05,
    longitude: -56.333333333333336
  },
  'America/Moncton': {
    countryCode: 'CA',
    countryName: 'Canada',
    comment: 'Atlantic - New Brunswick',
    latitude: 46.1,
    longitude: -64.78333333333333
  },
  'America/Monterrey': {
    countryCode: 'MX',
    countryName: 'Mexico',
    comment: 'Durango; Coahuila, Nuevo Leon, Tamaulipas (most areas)',
    latitude: 25.666666666666668,
    longitude: -100.31666666666666
  },
  'America/Montevideo': {
    countryCode: 'UY',
    countryName: 'Uruguay',
    comment: '',
    latitude: -34.909166666666664,
    longitude: -56.2125
  },
  'America/New_York': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Eastern (most areas)',
    latitude: 40.714166666666664,
    longitude: -74.00638888888889
  },
  'US/Eastern': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Eastern (most areas)',
    latitude: 40.714166666666664,
    longitude: -74.00638888888889
  },
  'America/Nome': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Alaska (west)',
    latitude: 64.50111111111111,
    longitude: -165.4063888888889
  },
  'America/Noronha': {
    countryCode: 'BR',
    countryName: 'Brazil',
    comment: 'Atlantic islands',
    latitude: -3.85,
    longitude: -32.416666666666664
  },
  'Brazil/DeNoronha': {
    countryCode: 'BR',
    countryName: 'Brazil',
    comment: 'Atlantic islands',
    latitude: -3.85,
    longitude: -32.416666666666664
  },
  'America/North_Dakota/Beulah': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Central - ND (Mercer)',
    latitude: 47.26416666666667,
    longitude: -101.77777777777777
  },
  'America/North_Dakota/Center': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Central - ND (Oliver)',
    latitude: 47.11638888888889,
    longitude: -101.29916666666666
  },
  'America/North_Dakota/New_Salem': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Central - ND (Morton rural)',
    latitude: 46.845,
    longitude: -101.41083333333333
  },
  'America/Nuuk': {
    countryCode: 'GL',
    countryName: 'Greenland',
    comment: 'Greenland (most areas)',
    latitude: 64.18333333333334,
    longitude: -51.733333333333334
  },
  'America/Godthab': {
    countryCode: 'GL',
    countryName: 'Greenland',
    comment: 'Greenland (most areas)',
    latitude: 64.18333333333334,
    longitude: -51.733333333333334
  },
  'America/Ojinaga': {
    countryCode: 'MX',
    countryName: 'Mexico',
    comment: 'Chihuahua (US border - east)',
    latitude: 29.566666666666666,
    longitude: -104.41666666666667
  },
  'America/Panama': {
    countryCode: 'PA',
    countryName: 'Panama',
    comment: '',
    latitude: 8.966666666666667,
    longitude: -79.53333333333333
  },
  'America/Atikokan': {
    countryCode: 'PA',
    countryName: 'Panama',
    comment: '',
    latitude: 8.966666666666667,
    longitude: -79.53333333333333
  },
  'America/Cayman': {
    countryCode: 'PA',
    countryName: 'Panama',
    comment: '',
    latitude: 8.966666666666667,
    longitude: -79.53333333333333
  },
  'America/Coral_Harbour': {
    countryCode: 'PA',
    countryName: 'Panama',
    comment: '',
    latitude: 8.966666666666667,
    longitude: -79.53333333333333
  },
  'America/Paramaribo': {
    countryCode: 'SR',
    countryName: 'Suriname',
    comment: '',
    latitude: 5.833333333333333,
    longitude: -55.166666666666664
  },
  'America/Phoenix': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'MST - Arizona (except Navajo)',
    latitude: 33.44833333333333,
    longitude: -112.07333333333334
  },
  'America/Creston': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'MST - Arizona (except Navajo)',
    latitude: 33.44833333333333,
    longitude: -112.07333333333334
  },
  'US/Arizona': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'MST - Arizona (except Navajo)',
    latitude: 33.44833333333333,
    longitude: -112.07333333333334
  },
  'America/Port-au-Prince': {
    countryCode: 'HT',
    countryName: 'Haiti',
    comment: '',
    latitude: 18.533333333333335,
    longitude: -72.33333333333333
  },
  'America/Porto_Velho': {
    countryCode: 'BR',
    countryName: 'Brazil',
    comment: 'Rondonia',
    latitude: -8.766666666666667,
    longitude: -63.9
  },
  'America/Puerto_Rico': {
    countryCode: 'PR',
    countryName: 'Puerto Rico',
    comment: '',
    latitude: 18.468333333333334,
    longitude: -66.1061111111111
  },
  'America/Anguilla': {
    countryCode: 'PR',
    countryName: 'Puerto Rico',
    comment: '',
    latitude: 18.468333333333334,
    longitude: -66.1061111111111
  },
  'America/Antigua': {
    countryCode: 'PR',
    countryName: 'Puerto Rico',
    comment: '',
    latitude: 18.468333333333334,
    longitude: -66.1061111111111
  },
  'America/Aruba': {
    countryCode: 'PR',
    countryName: 'Puerto Rico',
    comment: '',
    latitude: 18.468333333333334,
    longitude: -66.1061111111111
  },
  'America/Blanc-Sablon': {
    countryCode: 'PR',
    countryName: 'Puerto Rico',
    comment: '',
    latitude: 18.468333333333334,
    longitude: -66.1061111111111
  },
  'America/Curacao': {
    countryCode: 'PR',
    countryName: 'Puerto Rico',
    comment: '',
    latitude: 18.468333333333334,
    longitude: -66.1061111111111
  },
  'America/Dominica': {
    countryCode: 'PR',
    countryName: 'Puerto Rico',
    comment: '',
    latitude: 18.468333333333334,
    longitude: -66.1061111111111
  },
  'America/Grenada': {
    countryCode: 'PR',
    countryName: 'Puerto Rico',
    comment: '',
    latitude: 18.468333333333334,
    longitude: -66.1061111111111
  },
  'America/Guadeloupe': {
    countryCode: 'PR',
    countryName: 'Puerto Rico',
    comment: '',
    latitude: 18.468333333333334,
    longitude: -66.1061111111111
  },
  'America/Kralendijk': {
    countryCode: 'PR',
    countryName: 'Puerto Rico',
    comment: '',
    latitude: 18.468333333333334,
    longitude: -66.1061111111111
  },
  'America/Lower_Princes': {
    countryCode: 'PR',
    countryName: 'Puerto Rico',
    comment: '',
    latitude: 18.468333333333334,
    longitude: -66.1061111111111
  },
  'America/Marigot': {
    countryCode: 'PR',
    countryName: 'Puerto Rico',
    comment: '',
    latitude: 18.468333333333334,
    longitude: -66.1061111111111
  },
  'America/Montserrat': {
    countryCode: 'PR',
    countryName: 'Puerto Rico',
    comment: '',
    latitude: 18.468333333333334,
    longitude: -66.1061111111111
  },
  'America/Port_of_Spain': {
    countryCode: 'PR',
    countryName: 'Puerto Rico',
    comment: '',
    latitude: 18.468333333333334,
    longitude: -66.1061111111111
  },
  'America/St_Barthelemy': {
    countryCode: 'PR',
    countryName: 'Puerto Rico',
    comment: '',
    latitude: 18.468333333333334,
    longitude: -66.1061111111111
  },
  'America/St_Kitts': {
    countryCode: 'PR',
    countryName: 'Puerto Rico',
    comment: '',
    latitude: 18.468333333333334,
    longitude: -66.1061111111111
  },
  'America/St_Lucia': {
    countryCode: 'PR',
    countryName: 'Puerto Rico',
    comment: '',
    latitude: 18.468333333333334,
    longitude: -66.1061111111111
  },
  'America/St_Thomas': {
    countryCode: 'PR',
    countryName: 'Puerto Rico',
    comment: '',
    latitude: 18.468333333333334,
    longitude: -66.1061111111111
  },
  'America/St_Vincent': {
    countryCode: 'PR',
    countryName: 'Puerto Rico',
    comment: '',
    latitude: 18.468333333333334,
    longitude: -66.1061111111111
  },
  'America/Tortola': {
    countryCode: 'PR',
    countryName: 'Puerto Rico',
    comment: '',
    latitude: 18.468333333333334,
    longitude: -66.1061111111111
  },
  'America/Virgin': {
    countryCode: 'PR',
    countryName: 'Puerto Rico',
    comment: '',
    latitude: 18.468333333333334,
    longitude: -66.1061111111111
  },
  'America/Punta_Arenas': {
    countryCode: 'CL',
    countryName: 'Chile',
    comment: 'Region of Magallanes',
    latitude: -53.15,
    longitude: -70.91666666666667
  },
  'America/Rankin_Inlet': {
    countryCode: 'CA',
    countryName: 'Canada',
    comment: 'Central - NU (central)',
    latitude: 62.81666666666667,
    longitude: -92.08305555555556
  },
  'America/Recife': {
    countryCode: 'BR',
    countryName: 'Brazil',
    comment: 'Pernambuco',
    latitude: -8.05,
    longitude: -34.9
  },
  'America/Regina': {
    countryCode: 'CA',
    countryName: 'Canada',
    comment: 'CST - SK (most areas)',
    latitude: 50.4,
    longitude: -104.65
  },
  'Canada/Saskatchewan': {
    countryCode: 'CA',
    countryName: 'Canada',
    comment: 'CST - SK (most areas)',
    latitude: 50.4,
    longitude: -104.65
  },
  'America/Resolute': {
    countryCode: 'CA',
    countryName: 'Canada',
    comment: 'Central - NU (Resolute)',
    latitude: 74.69555555555556,
    longitude: -94.82916666666667
  },
  'America/Rio_Branco': {
    countryCode: 'BR',
    countryName: 'Brazil',
    comment: 'Acre',
    latitude: -9.966666666666667,
    longitude: -67.8
  },
  'America/Porto_Acre': {
    countryCode: 'BR',
    countryName: 'Brazil',
    comment: 'Acre',
    latitude: -9.966666666666667,
    longitude: -67.8
  },
  'Brazil/Acre': {
    countryCode: 'BR',
    countryName: 'Brazil',
    comment: 'Acre',
    latitude: -9.966666666666667,
    longitude: -67.8
  },
  'America/Santarem': {
    countryCode: 'BR',
    countryName: 'Brazil',
    comment: 'Para (west)',
    latitude: -2.433333333333333,
    longitude: -54.86666666666667
  },
  'America/Santiago': {
    countryCode: 'CL',
    countryName: 'Chile',
    comment: 'Chile (most areas)',
    latitude: -33.45,
    longitude: -70.66666666666667
  },
  'Chile/Continental': {
    countryCode: 'CL',
    countryName: 'Chile',
    comment: 'Chile (most areas)',
    latitude: -33.45,
    longitude: -70.66666666666667
  },
  'America/Santo_Domingo': {
    countryCode: 'DO',
    countryName: 'Dominican Republic',
    comment: '',
    latitude: 18.466666666666665,
    longitude: -69.9
  },
  'America/Sao_Paulo': {
    countryCode: 'BR',
    countryName: 'Brazil',
    comment: 'Brazil (southeast: GO, DF, MG, ES, RJ, SP, PR, SC, RS)',
    latitude: -23.533333333333335,
    longitude: -46.61666666666667
  },
  'Brazil/East': {
    countryCode: 'BR',
    countryName: 'Brazil',
    comment: 'Brazil (southeast: GO, DF, MG, ES, RJ, SP, PR, SC, RS)',
    latitude: -23.533333333333335,
    longitude: -46.61666666666667
  },
  'America/Scoresbysund': {
    countryCode: 'GL',
    countryName: 'Greenland',
    comment: 'Scoresbysund/Ittoqqortoormiit',
    latitude: 70.48333333333333,
    longitude: -21.966666666666665
  },
  'America/Sitka': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Alaska - Sitka area',
    latitude: 57.17638888888889,
    longitude: -135.30194444444444
  },
  'America/St_Johns': {
    countryCode: 'CA',
    countryName: 'Canada',
    comment: 'Newfoundland; Labrador (southeast)',
    latitude: 47.56666666666667,
    longitude: -52.71666666666667
  },
  'Canada/Newfoundland': {
    countryCode: 'CA',
    countryName: 'Canada',
    comment: 'Newfoundland; Labrador (southeast)',
    latitude: 47.56666666666667,
    longitude: -52.71666666666667
  },
  'America/Swift_Current': {
    countryCode: 'CA',
    countryName: 'Canada',
    comment: 'CST - SK (midwest)',
    latitude: 50.28333333333333,
    longitude: -107.83333333333333
  },
  'America/Tegucigalpa': {
    countryCode: 'HN',
    countryName: 'Honduras',
    comment: '',
    latitude: 14.1,
    longitude: -87.21666666666667
  },
  'America/Thule': {
    countryCode: 'GL',
    countryName: 'Greenland',
    comment: 'Thule/Pituffik',
    latitude: 76.56666666666666,
    longitude: -68.78333333333333
  },
  'America/Tijuana': {
    countryCode: 'MX',
    countryName: 'Mexico',
    comment: 'Baja California',
    latitude: 32.53333333333333,
    longitude: -117.01666666666667
  },
  'America/Ensenada': {
    countryCode: 'MX',
    countryName: 'Mexico',
    comment: 'Baja California',
    latitude: 32.53333333333333,
    longitude: -117.01666666666667
  },
  'America/Santa_Isabel': {
    countryCode: 'MX',
    countryName: 'Mexico',
    comment: 'Baja California',
    latitude: 32.53333333333333,
    longitude: -117.01666666666667
  },
  'Mexico/BajaNorte': {
    countryCode: 'MX',
    countryName: 'Mexico',
    comment: 'Baja California',
    latitude: 32.53333333333333,
    longitude: -117.01666666666667
  },
  'America/Toronto': {
    countryCode: 'CA',
    countryName: 'Canada',
    comment: 'Eastern - ON, QC (most areas)',
    latitude: 43.65,
    longitude: -79.38333333333334
  },
  'America/Montreal': {
    countryCode: 'CA',
    countryName: 'Canada',
    comment: 'Eastern - ON, QC (most areas)',
    latitude: 43.65,
    longitude: -79.38333333333334
  },
  'America/Nassau': {
    countryCode: 'CA',
    countryName: 'Canada',
    comment: 'Eastern - ON, QC (most areas)',
    latitude: 43.65,
    longitude: -79.38333333333334
  },
  'America/Nipigon': {
    countryCode: 'CA',
    countryName: 'Canada',
    comment: 'Eastern - ON, QC (most areas)',
    latitude: 43.65,
    longitude: -79.38333333333334
  },
  'America/Thunder_Bay': {
    countryCode: 'CA',
    countryName: 'Canada',
    comment: 'Eastern - ON, QC (most areas)',
    latitude: 43.65,
    longitude: -79.38333333333334
  },
  'Canada/Eastern': {
    countryCode: 'CA',
    countryName: 'Canada',
    comment: 'Eastern - ON, QC (most areas)',
    latitude: 43.65,
    longitude: -79.38333333333334
  },
  'America/Vancouver': {
    countryCode: 'CA',
    countryName: 'Canada',
    comment: 'Pacific - BC (most areas)',
    latitude: 49.266666666666666,
    longitude: -123.11666666666666
  },
  'Canada/Pacific': {
    countryCode: 'CA',
    countryName: 'Canada',
    comment: 'Pacific - BC (most areas)',
    latitude: 49.266666666666666,
    longitude: -123.11666666666666
  },
  'America/Whitehorse': {
    countryCode: 'CA',
    countryName: 'Canada',
    comment: 'MST - Yukon (east)',
    latitude: 60.71666666666667,
    longitude: -135.05
  },
  'Canada/Yukon': {
    countryCode: 'CA',
    countryName: 'Canada',
    comment: 'MST - Yukon (east)',
    latitude: 60.71666666666667,
    longitude: -135.05
  },
  'America/Winnipeg': {
    countryCode: 'CA',
    countryName: 'Canada',
    comment: 'Central - ON (west); Manitoba',
    latitude: 49.88333333333333,
    longitude: -97.15
  },
  'America/Rainy_River': {
    countryCode: 'CA',
    countryName: 'Canada',
    comment: 'Central - ON (west); Manitoba',
    latitude: 49.88333333333333,
    longitude: -97.15
  },
  'Canada/Central': {
    countryCode: 'CA',
    countryName: 'Canada',
    comment: 'Central - ON (west); Manitoba',
    latitude: 49.88333333333333,
    longitude: -97.15
  },
  'America/Yakutat': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Alaska - Yakutat',
    latitude: 59.54694444444444,
    longitude: -139.72722222222222
  },
  'America/Yellowknife': {
    countryCode: 'CA',
    countryName: 'Canada',
    comment: 'Mountain - NT (central)',
    latitude: 62.45,
    longitude: -114.35
  },
  'Antarctica/Casey': {
    countryCode: 'AQ',
    countryName: 'Antarctica',
    comment: 'Casey',
    latitude: -66.28333333333333,
    longitude: 110.51666666666667
  },
  'Antarctica/Davis': {
    countryCode: 'AQ',
    countryName: 'Antarctica',
    comment: 'Davis',
    latitude: -68.58333333333333,
    longitude: 77.96666666666667
  },
  'Antarctica/Macquarie': {
    countryCode: 'AU',
    countryName: 'Australia',
    comment: 'Macquarie Island',
    latitude: -54.5,
    longitude: 158.95
  },
  'Antarctica/Mawson': {
    countryCode: 'AQ',
    countryName: 'Antarctica',
    comment: 'Mawson',
    latitude: -67.6,
    longitude: 62.88333333333333
  },
  'Antarctica/Palmer': {
    countryCode: 'AQ',
    countryName: 'Antarctica',
    comment: 'Palmer',
    latitude: -64.8,
    longitude: -64.1
  },
  'Antarctica/Rothera': {
    countryCode: 'AQ',
    countryName: 'Antarctica',
    comment: 'Rothera',
    latitude: -67.56666666666666,
    longitude: -68.13333333333334
  },
  'Antarctica/Troll': {
    countryCode: 'AQ',
    countryName: 'Antarctica',
    comment: 'Troll',
    latitude: -72.01138888888889,
    longitude: 2.535
  },
  'Asia/Almaty': {
    countryCode: 'KZ',
    countryName: 'Kazakhstan',
    comment: 'Kazakhstan (most areas)',
    latitude: 43.25,
    longitude: 76.95
  },
  'Asia/Amman': {
    countryCode: 'JO',
    countryName: 'Jordan',
    comment: '',
    latitude: 31.95,
    longitude: 35.93333333333333
  },
  'Asia/Anadyr': {
    countryCode: 'RU',
    countryName: 'Russia',
    comment: 'MSK+09 - Bering Sea',
    latitude: 64.75,
    longitude: 177.48333333333332
  },
  'Asia/Aqtau': {
    countryCode: 'KZ',
    countryName: 'Kazakhstan',
    comment: 'Mangghystau/Mankistau',
    latitude: 44.516666666666666,
    longitude: 50.266666666666666
  },
  'Asia/Aqtobe': {
    countryCode: 'KZ',
    countryName: 'Kazakhstan',
    comment: 'Aqtobe/Aktobe',
    latitude: 50.28333333333333,
    longitude: 57.166666666666664
  },
  'Asia/Ashgabat': {
    countryCode: 'TM',
    countryName: 'Turkmenistan',
    comment: '',
    latitude: 37.95,
    longitude: 58.38333333333333
  },
  'Asia/Ashkhabad': {
    countryCode: 'TM',
    countryName: 'Turkmenistan',
    comment: '',
    latitude: 37.95,
    longitude: 58.38333333333333
  },
  'Asia/Atyrau': {
    countryCode: 'KZ',
    countryName: 'Kazakhstan',
    comment: "Atyrau/Atirau/Gur'yev",
    latitude: 47.11666666666667,
    longitude: 51.93333333333333
  },
  'Asia/Baghdad': {
    countryCode: 'IQ',
    countryName: 'Iraq',
    comment: '',
    latitude: 33.35,
    longitude: 44.416666666666664
  },
  'Asia/Baku': {
    countryCode: 'AZ',
    countryName: 'Azerbaijan',
    comment: '',
    latitude: 40.38333333333333,
    longitude: 49.85
  },
  'Asia/Bangkok': {
    countryCode: 'TH',
    countryName: 'Thailand',
    comment: '',
    latitude: 13.75,
    longitude: 100.51666666666667
  },
  'Asia/Phnom_Penh': {
    countryCode: 'TH',
    countryName: 'Thailand',
    comment: '',
    latitude: 13.75,
    longitude: 100.51666666666667
  },
  'Asia/Vientiane': {
    countryCode: 'TH',
    countryName: 'Thailand',
    comment: '',
    latitude: 13.75,
    longitude: 100.51666666666667
  },
  'Indian/Christmas': {
    countryCode: 'TH',
    countryName: 'Thailand',
    comment: '',
    latitude: 13.75,
    longitude: 100.51666666666667
  },
  'Asia/Barnaul': {
    countryCode: 'RU',
    countryName: 'Russia',
    comment: 'MSK+04 - Altai',
    latitude: 53.36666666666667,
    longitude: 83.75
  },
  'Asia/Beirut': {
    countryCode: 'LB',
    countryName: 'Lebanon',
    comment: '',
    latitude: 33.88333333333333,
    longitude: 35.5
  },
  'Asia/Bishkek': {
    countryCode: 'KG',
    countryName: 'Kyrgyzstan',
    comment: '',
    latitude: 42.9,
    longitude: 74.6
  },
  'Asia/Chita': {
    countryCode: 'RU',
    countryName: 'Russia',
    comment: 'MSK+06 - Zabaykalsky',
    latitude: 52.05,
    longitude: 113.46666666666667
  },
  'Asia/Choibalsan': {
    countryCode: 'MN',
    countryName: 'Mongolia',
    comment: 'Dornod, Sukhbaatar',
    latitude: 48.06666666666667,
    longitude: 114.5
  },
  'Asia/Colombo': {
    countryCode: 'LK',
    countryName: 'Sri Lanka',
    comment: '',
    latitude: 6.933333333333334,
    longitude: 79.85
  },
  'Asia/Damascus': {
    countryCode: 'SY',
    countryName: 'Syria',
    comment: '',
    latitude: 33.5,
    longitude: 36.3
  },
  'Asia/Dhaka': {
    countryCode: 'BD',
    countryName: 'Bangladesh',
    comment: '',
    latitude: 23.716666666666665,
    longitude: 90.41666666666667
  },
  'Asia/Dacca': {
    countryCode: 'BD',
    countryName: 'Bangladesh',
    comment: '',
    latitude: 23.716666666666665,
    longitude: 90.41666666666667
  },
  'Asia/Dili': {
    countryCode: 'TL',
    countryName: 'East Timor',
    comment: '',
    latitude: -8.55,
    longitude: 125.58333333333333
  },
  'Asia/Dubai': {
    countryCode: 'AE',
    countryName: 'United Arab Emirates',
    comment: '',
    latitude: 25.3,
    longitude: 55.3
  },
  'Asia/Muscat': {
    countryCode: 'AE',
    countryName: 'United Arab Emirates',
    comment: '',
    latitude: 25.3,
    longitude: 55.3
  },
  'Indian/Mahe': {
    countryCode: 'AE',
    countryName: 'United Arab Emirates',
    comment: '',
    latitude: 25.3,
    longitude: 55.3
  },
  'Indian/Reunion': {
    countryCode: 'AE',
    countryName: 'United Arab Emirates',
    comment: '',
    latitude: 25.3,
    longitude: 55.3
  },
  'Asia/Dushanbe': {
    countryCode: 'TJ',
    countryName: 'Tajikistan',
    comment: '',
    latitude: 38.583333333333336,
    longitude: 68.8
  },
  'Asia/Famagusta': {
    countryCode: 'CY',
    countryName: 'Cyprus',
    comment: 'Northern Cyprus',
    latitude: 35.11666666666667,
    longitude: 33.95
  },
  'Asia/Gaza': {
    countryCode: 'PS',
    countryName: 'Palestine',
    comment: 'Gaza Strip',
    latitude: 31.5,
    longitude: 34.46666666666667
  },
  'Asia/Hebron': {
    countryCode: 'PS',
    countryName: 'Palestine',
    comment: 'West Bank',
    latitude: 31.533333333333335,
    longitude: 35.095
  },
  'Asia/Ho_Chi_Minh': {
    countryCode: 'VN',
    countryName: 'Vietnam',
    comment: '',
    latitude: 10.75,
    longitude: 106.66666666666667
  },
  'Asia/Saigon': {
    countryCode: 'VN',
    countryName: 'Vietnam',
    comment: '',
    latitude: 10.75,
    longitude: 106.66666666666667
  },
  'Asia/Hong_Kong': {
    countryCode: 'HK',
    countryName: 'Hong Kong',
    comment: '',
    latitude: 22.283333333333335,
    longitude: 114.15
  },
  Hongkong: {
    countryCode: 'HK',
    countryName: 'Hong Kong',
    comment: '',
    latitude: 22.283333333333335,
    longitude: 114.15
  },
  'Asia/Hovd': {
    countryCode: 'MN',
    countryName: 'Mongolia',
    comment: 'Bayan-Olgiy, Govi-Altai, Hovd, Uvs, Zavkhan',
    latitude: 48.016666666666666,
    longitude: 91.65
  },
  'Asia/Irkutsk': {
    countryCode: 'RU',
    countryName: 'Russia',
    comment: 'MSK+05 - Irkutsk, Buryatia',
    latitude: 52.266666666666666,
    longitude: 104.33333333333333
  },
  'Asia/Jakarta': {
    countryCode: 'ID',
    countryName: 'Indonesia',
    comment: 'Java, Sumatra',
    latitude: -6.166666666666667,
    longitude: 106.8
  },
  'Asia/Jayapura': {
    countryCode: 'ID',
    countryName: 'Indonesia',
    comment: 'New Guinea (West Papua / Irian Jaya); Malukus/Moluccas',
    latitude: -2.533333333333333,
    longitude: 140.7
  },
  'Asia/Jerusalem': {
    countryCode: 'IL',
    countryName: 'Israel',
    comment: '',
    latitude: 31.780555555555555,
    longitude: 35.22388888888889
  },
  'Asia/Tel_Aviv': {
    countryCode: 'IL',
    countryName: 'Israel',
    comment: '',
    latitude: 31.780555555555555,
    longitude: 35.22388888888889
  },
  Israel: {
    countryCode: 'IL',
    countryName: 'Israel',
    comment: '',
    latitude: 31.780555555555555,
    longitude: 35.22388888888889
  },
  'Asia/Kabul': {
    countryCode: 'AF',
    countryName: 'Afghanistan',
    comment: '',
    latitude: 34.516666666666666,
    longitude: 69.2
  },
  'Asia/Kamchatka': {
    countryCode: 'RU',
    countryName: 'Russia',
    comment: 'MSK+09 - Kamchatka',
    latitude: 53.016666666666666,
    longitude: 158.65
  },
  'Asia/Karachi': {
    countryCode: 'PK',
    countryName: 'Pakistan',
    comment: '',
    latitude: 24.866666666666667,
    longitude: 67.05
  },
  'Asia/Kathmandu': {
    countryCode: 'NP',
    countryName: 'Nepal',
    comment: '',
    latitude: 27.716666666666665,
    longitude: 85.31666666666666
  },
  'Asia/Katmandu': {
    countryCode: 'NP',
    countryName: 'Nepal',
    comment: '',
    latitude: 27.716666666666665,
    longitude: 85.31666666666666
  },
  'Asia/Khandyga': {
    countryCode: 'RU',
    countryName: 'Russia',
    comment: 'MSK+06 - Tomponsky, Ust-Maysky',
    latitude: 62.65638888888889,
    longitude: 135.55388888888888
  },
  'Asia/Kolkata': {
    countryCode: 'IN',
    countryName: 'India',
    comment: '',
    latitude: 22.533333333333335,
    longitude: 88.36666666666666
  },
  'Asia/Calcutta': {
    countryCode: 'IN',
    countryName: 'India',
    comment: '',
    latitude: 22.533333333333335,
    longitude: 88.36666666666666
  },
  'Asia/Krasnoyarsk': {
    countryCode: 'RU',
    countryName: 'Russia',
    comment: 'MSK+04 - Krasnoyarsk area',
    latitude: 56.016666666666666,
    longitude: 92.83333333333333
  },
  'Asia/Kuching': {
    countryCode: 'MY',
    countryName: 'Malaysia',
    comment: 'Sabah, Sarawak',
    latitude: 1.55,
    longitude: 110.33333333333333
  },
  'Asia/Brunei': {
    countryCode: 'MY',
    countryName: 'Malaysia',
    comment: 'Sabah, Sarawak',
    latitude: 1.55,
    longitude: 110.33333333333333
  },
  'Asia/Macau': {
    countryCode: 'MO',
    countryName: 'Macau',
    comment: '',
    latitude: 22.197222222222223,
    longitude: 113.54166666666667
  },
  'Asia/Macao': {
    countryCode: 'MO',
    countryName: 'Macau',
    comment: '',
    latitude: 22.197222222222223,
    longitude: 113.54166666666667
  },
  'Asia/Magadan': {
    countryCode: 'RU',
    countryName: 'Russia',
    comment: 'MSK+08 - Magadan',
    latitude: 59.56666666666667,
    longitude: 150.8
  },
  'Asia/Makassar': {
    countryCode: 'ID',
    countryName: 'Indonesia',
    comment: 'Borneo (east, south); Sulawesi/Celebes, Bali, Nusa Tengarra; Timor (west)',
    latitude: -5.116666666666666,
    longitude: 119.4
  },
  'Asia/Ujung_Pandang': {
    countryCode: 'ID',
    countryName: 'Indonesia',
    comment: 'Borneo (east, south); Sulawesi/Celebes, Bali, Nusa Tengarra; Timor (west)',
    latitude: -5.116666666666666,
    longitude: 119.4
  },
  'Asia/Manila': {
    countryCode: 'PH',
    countryName: 'Philippines',
    comment: '',
    latitude: 14.583333333333334,
    longitude: 121
  },
  'Asia/Nicosia': {
    countryCode: 'CY',
    countryName: 'Cyprus',
    comment: 'Cyprus (most areas)',
    latitude: 35.166666666666664,
    longitude: 33.36666666666667
  },
  'Europe/Nicosia': {
    countryCode: 'CY',
    countryName: 'Cyprus',
    comment: 'Cyprus (most areas)',
    latitude: 35.166666666666664,
    longitude: 33.36666666666667
  },
  'Asia/Novokuznetsk': {
    countryCode: 'RU',
    countryName: 'Russia',
    comment: 'MSK+04 - Kemerovo',
    latitude: 53.75,
    longitude: 87.11666666666666
  },
  'Asia/Novosibirsk': {
    countryCode: 'RU',
    countryName: 'Russia',
    comment: 'MSK+04 - Novosibirsk',
    latitude: 55.03333333333333,
    longitude: 82.91666666666667
  },
  'Asia/Omsk': {
    countryCode: 'RU',
    countryName: 'Russia',
    comment: 'MSK+03 - Omsk',
    latitude: 55,
    longitude: 73.4
  },
  'Asia/Oral': {
    countryCode: 'KZ',
    countryName: 'Kazakhstan',
    comment: 'West Kazakhstan',
    latitude: 51.21666666666667,
    longitude: 51.35
  },
  'Asia/Pontianak': {
    countryCode: 'ID',
    countryName: 'Indonesia',
    comment: 'Borneo (west, central)',
    latitude: -0.03333333333333333,
    longitude: 109.33333333333333
  },
  'Asia/Pyongyang': {
    countryCode: 'KP',
    countryName: 'Korea (North)',
    comment: '',
    latitude: 39.016666666666666,
    longitude: 125.75
  },
  'Asia/Qatar': {
    countryCode: 'QA',
    countryName: 'Qatar',
    comment: '',
    latitude: 25.283333333333335,
    longitude: 51.53333333333333
  },
  'Asia/Bahrain': {
    countryCode: 'QA',
    countryName: 'Qatar',
    comment: '',
    latitude: 25.283333333333335,
    longitude: 51.53333333333333
  },
  'Asia/Qostanay': {
    countryCode: 'KZ',
    countryName: 'Kazakhstan',
    comment: 'Qostanay/Kostanay/Kustanay',
    latitude: 53.2,
    longitude: 63.61666666666667
  },
  'Asia/Qyzylorda': {
    countryCode: 'KZ',
    countryName: 'Kazakhstan',
    comment: 'Qyzylorda/Kyzylorda/Kzyl-Orda',
    latitude: 44.8,
    longitude: 65.46666666666667
  },
  'Asia/Riyadh': {
    countryCode: 'SA',
    countryName: 'Saudi Arabia',
    comment: '',
    latitude: 24.633333333333333,
    longitude: 46.71666666666667
  },
  'Antarctica/Syowa': {
    countryCode: 'SA',
    countryName: 'Saudi Arabia',
    comment: '',
    latitude: 24.633333333333333,
    longitude: 46.71666666666667
  },
  'Asia/Aden': {
    countryCode: 'SA',
    countryName: 'Saudi Arabia',
    comment: '',
    latitude: 24.633333333333333,
    longitude: 46.71666666666667
  },
  'Asia/Kuwait': {
    countryCode: 'SA',
    countryName: 'Saudi Arabia',
    comment: '',
    latitude: 24.633333333333333,
    longitude: 46.71666666666667
  },
  'Asia/Sakhalin': {
    countryCode: 'RU',
    countryName: 'Russia',
    comment: 'MSK+08 - Sakhalin Island',
    latitude: 46.96666666666667,
    longitude: 142.7
  },
  'Asia/Samarkand': {
    countryCode: 'UZ',
    countryName: 'Uzbekistan',
    comment: 'Uzbekistan (west)',
    latitude: 39.666666666666664,
    longitude: 66.8
  },
  'Asia/Seoul': {
    countryCode: 'KR',
    countryName: 'Korea (South)',
    comment: '',
    latitude: 37.55,
    longitude: 126.96666666666667
  },
  ROK: {
    countryCode: 'KR',
    countryName: 'Korea (South)',
    comment: '',
    latitude: 37.55,
    longitude: 126.96666666666667
  },
  'Asia/Shanghai': {
    countryCode: 'CN',
    countryName: 'China',
    comment: 'Beijing Time',
    latitude: 31.233333333333334,
    longitude: 121.46666666666667
  },
  'Asia/Chongqing': {
    countryCode: 'CN',
    countryName: 'China',
    comment: 'Beijing Time',
    latitude: 31.233333333333334,
    longitude: 121.46666666666667
  },
  'Asia/Chungking': {
    countryCode: 'CN',
    countryName: 'China',
    comment: 'Beijing Time',
    latitude: 31.233333333333334,
    longitude: 121.46666666666667
  },
  'Asia/Harbin': {
    countryCode: 'CN',
    countryName: 'China',
    comment: 'Beijing Time',
    latitude: 31.233333333333334,
    longitude: 121.46666666666667
  },
  PRC: {
    countryCode: 'CN',
    countryName: 'China',
    comment: 'Beijing Time',
    latitude: 31.233333333333334,
    longitude: 121.46666666666667
  },
  'Asia/Singapore': {
    countryCode: 'SG',
    countryName: 'Singapore',
    comment: '',
    latitude: 1.2833333333333334,
    longitude: 103.85
  },
  'Asia/Kuala_Lumpur': {
    countryCode: 'SG',
    countryName: 'Singapore',
    comment: '',
    latitude: 1.2833333333333334,
    longitude: 103.85
  },
  Singapore: {
    countryCode: 'SG',
    countryName: 'Singapore',
    comment: '',
    latitude: 1.2833333333333334,
    longitude: 103.85
  },
  'Asia/Srednekolymsk': {
    countryCode: 'RU',
    countryName: 'Russia',
    comment: 'MSK+08 - Sakha (E); North Kuril Is',
    latitude: 67.46666666666667,
    longitude: 153.71666666666667
  },
  'Asia/Taipei': {
    countryCode: 'TW',
    countryName: 'Taiwan',
    comment: '',
    latitude: 25.05,
    longitude: 121.5
  },
  ROC: {
    countryCode: 'TW',
    countryName: 'Taiwan',
    comment: '',
    latitude: 25.05,
    longitude: 121.5
  },
  'Asia/Tashkent': {
    countryCode: 'UZ',
    countryName: 'Uzbekistan',
    comment: 'Uzbekistan (east)',
    latitude: 41.333333333333336,
    longitude: 69.3
  },
  'Asia/Tbilisi': {
    countryCode: 'GE',
    countryName: 'Georgia',
    comment: '',
    latitude: 41.71666666666667,
    longitude: 44.81666666666667
  },
  'Asia/Tehran': {
    countryCode: 'IR',
    countryName: 'Iran',
    comment: '',
    latitude: 35.666666666666664,
    longitude: 51.43333333333333
  },
  Iran: {
    countryCode: 'IR',
    countryName: 'Iran',
    comment: '',
    latitude: 35.666666666666664,
    longitude: 51.43333333333333
  },
  'Asia/Thimphu': {
    countryCode: 'BT',
    countryName: 'Bhutan',
    comment: '',
    latitude: 27.466666666666665,
    longitude: 89.65
  },
  'Asia/Thimbu': {
    countryCode: 'BT',
    countryName: 'Bhutan',
    comment: '',
    latitude: 27.466666666666665,
    longitude: 89.65
  },
  'Asia/Tokyo': {
    countryCode: 'JP',
    countryName: 'Japan',
    comment: '',
    latitude: 35.654444444444444,
    longitude: 139.7447222222222
  },
  Japan: {
    countryCode: 'JP',
    countryName: 'Japan',
    comment: '',
    latitude: 35.654444444444444,
    longitude: 139.7447222222222
  },
  'Asia/Tomsk': {
    countryCode: 'RU',
    countryName: 'Russia',
    comment: 'MSK+04 - Tomsk',
    latitude: 56.5,
    longitude: 84.96666666666667
  },
  'Asia/Ulaanbaatar': {
    countryCode: 'MN',
    countryName: 'Mongolia',
    comment: 'Mongolia (most areas)',
    latitude: 47.916666666666664,
    longitude: 106.88333333333334
  },
  'Asia/Ulan_Bator': {
    countryCode: 'MN',
    countryName: 'Mongolia',
    comment: 'Mongolia (most areas)',
    latitude: 47.916666666666664,
    longitude: 106.88333333333334
  },
  'Asia/Urumqi': {
    countryCode: 'CN',
    countryName: 'China',
    comment: 'Xinjiang Time',
    latitude: 43.8,
    longitude: 87.58333333333333
  },
  'Antarctica/Vostok': {
    countryCode: 'CN',
    countryName: 'China',
    comment: 'Xinjiang Time',
    latitude: 43.8,
    longitude: 87.58333333333333
  },
  'Asia/Kashgar': {
    countryCode: 'CN',
    countryName: 'China',
    comment: 'Xinjiang Time',
    latitude: 43.8,
    longitude: 87.58333333333333
  },
  'Asia/Ust-Nera': {
    countryCode: 'RU',
    countryName: 'Russia',
    comment: 'MSK+07 - Oymyakonsky',
    latitude: 64.56027777777778,
    longitude: 143.22666666666666
  },
  'Asia/Vladivostok': {
    countryCode: 'RU',
    countryName: 'Russia',
    comment: 'MSK+07 - Amur River',
    latitude: 43.166666666666664,
    longitude: 131.93333333333334
  },
  'Asia/Yakutsk': {
    countryCode: 'RU',
    countryName: 'Russia',
    comment: 'MSK+06 - Lena River',
    latitude: 62,
    longitude: 129.66666666666666
  },
  'Asia/Yangon': {
    countryCode: 'MM',
    countryName: 'Myanmar (Burma)',
    comment: '',
    latitude: 16.783333333333335,
    longitude: 96.16666666666667
  },
  'Asia/Rangoon': {
    countryCode: 'MM',
    countryName: 'Myanmar (Burma)',
    comment: '',
    latitude: 16.783333333333335,
    longitude: 96.16666666666667
  },
  'Indian/Cocos': {
    countryCode: 'MM',
    countryName: 'Myanmar (Burma)',
    comment: '',
    latitude: 16.783333333333335,
    longitude: 96.16666666666667
  },
  'Asia/Yekaterinburg': {
    countryCode: 'RU',
    countryName: 'Russia',
    comment: 'MSK+02 - Urals',
    latitude: 56.85,
    longitude: 60.6
  },
  'Asia/Yerevan': {
    countryCode: 'AM',
    countryName: 'Armenia',
    comment: '',
    latitude: 40.18333333333333,
    longitude: 44.5
  },
  'Atlantic/Azores': {
    countryCode: 'PT',
    countryName: 'Portugal',
    comment: 'Azores',
    latitude: 37.733333333333334,
    longitude: -25.666666666666668
  },
  'Atlantic/Bermuda': {
    countryCode: 'BM',
    countryName: 'Bermuda',
    comment: '',
    latitude: 32.28333333333333,
    longitude: -64.76666666666667
  },
  'Atlantic/Canary': {
    countryCode: 'ES',
    countryName: 'Spain',
    comment: 'Canary Islands',
    latitude: 28.1,
    longitude: -15.4
  },
  'Atlantic/Cape_Verde': {
    countryCode: 'CV',
    countryName: 'Cape Verde',
    comment: '',
    latitude: 14.916666666666666,
    longitude: -23.516666666666666
  },
  'Atlantic/Faroe': {
    countryCode: 'FO',
    countryName: 'Faroe Islands',
    comment: '',
    latitude: 62.016666666666666,
    longitude: -6.766666666666667
  },
  'Atlantic/Faeroe': {
    countryCode: 'FO',
    countryName: 'Faroe Islands',
    comment: '',
    latitude: 62.016666666666666,
    longitude: -6.766666666666667
  },
  'Atlantic/Madeira': {
    countryCode: 'PT',
    countryName: 'Portugal',
    comment: 'Madeira Islands',
    latitude: 32.63333333333333,
    longitude: -16.9
  },
  'Atlantic/South_Georgia': {
    countryCode: 'GS',
    countryName: 'South Georgia & the South Sandwich Islands',
    comment: '',
    latitude: -54.266666666666666,
    longitude: -36.53333333333333
  },
  'Atlantic/Stanley': {
    countryCode: 'FK',
    countryName: 'Falkland Islands',
    comment: '',
    latitude: -51.7,
    longitude: -57.85
  },
  'Australia/Adelaide': {
    countryCode: 'AU',
    countryName: 'Australia',
    comment: 'South Australia',
    latitude: -34.916666666666664,
    longitude: 138.58333333333334
  },
  'Australia/South': {
    countryCode: 'AU',
    countryName: 'Australia',
    comment: 'South Australia',
    latitude: -34.916666666666664,
    longitude: 138.58333333333334
  },
  'Australia/Brisbane': {
    countryCode: 'AU',
    countryName: 'Australia',
    comment: 'Queensland (most areas)',
    latitude: -27.466666666666665,
    longitude: 153.03333333333333
  },
  'Australia/Queensland': {
    countryCode: 'AU',
    countryName: 'Australia',
    comment: 'Queensland (most areas)',
    latitude: -27.466666666666665,
    longitude: 153.03333333333333
  },
  'Australia/Broken_Hill': {
    countryCode: 'AU',
    countryName: 'Australia',
    comment: 'New South Wales (Yancowinna)',
    latitude: -31.95,
    longitude: 141.45
  },
  'Australia/Yancowinna': {
    countryCode: 'AU',
    countryName: 'Australia',
    comment: 'New South Wales (Yancowinna)',
    latitude: -31.95,
    longitude: 141.45
  },
  'Australia/Darwin': {
    countryCode: 'AU',
    countryName: 'Australia',
    comment: 'Northern Territory',
    latitude: -12.466666666666667,
    longitude: 130.83333333333334
  },
  'Australia/North': {
    countryCode: 'AU',
    countryName: 'Australia',
    comment: 'Northern Territory',
    latitude: -12.466666666666667,
    longitude: 130.83333333333334
  },
  'Australia/Eucla': {
    countryCode: 'AU',
    countryName: 'Australia',
    comment: 'Western Australia (Eucla)',
    latitude: -31.716666666666665,
    longitude: 128.86666666666667
  },
  'Australia/Hobart': {
    countryCode: 'AU',
    countryName: 'Australia',
    comment: 'Tasmania',
    latitude: -42.88333333333333,
    longitude: 147.31666666666666
  },
  'Australia/Currie': {
    countryCode: 'AU',
    countryName: 'Australia',
    comment: 'Tasmania',
    latitude: -42.88333333333333,
    longitude: 147.31666666666666
  },
  'Australia/Tasmania': {
    countryCode: 'AU',
    countryName: 'Australia',
    comment: 'Tasmania',
    latitude: -42.88333333333333,
    longitude: 147.31666666666666
  },
  'Australia/Lindeman': {
    countryCode: 'AU',
    countryName: 'Australia',
    comment: 'Queensland (Whitsunday Islands)',
    latitude: -20.266666666666666,
    longitude: 149
  },
  'Australia/Lord_Howe': {
    countryCode: 'AU',
    countryName: 'Australia',
    comment: 'Lord Howe Island',
    latitude: -31.55,
    longitude: 159.08333333333334
  },
  'Australia/LHI': {
    countryCode: 'AU',
    countryName: 'Australia',
    comment: 'Lord Howe Island',
    latitude: -31.55,
    longitude: 159.08333333333334
  },
  'Australia/Melbourne': {
    countryCode: 'AU',
    countryName: 'Australia',
    comment: 'Victoria',
    latitude: -37.81666666666667,
    longitude: 144.96666666666667
  },
  'Australia/Victoria': {
    countryCode: 'AU',
    countryName: 'Australia',
    comment: 'Victoria',
    latitude: -37.81666666666667,
    longitude: 144.96666666666667
  },
  'Australia/Perth': {
    countryCode: 'AU',
    countryName: 'Australia',
    comment: 'Western Australia (most areas)',
    latitude: -31.95,
    longitude: 115.85
  },
  'Australia/West': {
    countryCode: 'AU',
    countryName: 'Australia',
    comment: 'Western Australia (most areas)',
    latitude: -31.95,
    longitude: 115.85
  },
  'Australia/Sydney': {
    countryCode: 'AU',
    countryName: 'Australia',
    comment: 'New South Wales (most areas)',
    latitude: -33.86666666666667,
    longitude: 151.21666666666667
  },
  'Australia/ACT': {
    countryCode: 'AU',
    countryName: 'Australia',
    comment: 'New South Wales (most areas)',
    latitude: -33.86666666666667,
    longitude: 151.21666666666667
  },
  'Australia/Canberra': {
    countryCode: 'AU',
    countryName: 'Australia',
    comment: 'New South Wales (most areas)',
    latitude: -33.86666666666667,
    longitude: 151.21666666666667
  },
  'Australia/NSW': {
    countryCode: 'AU',
    countryName: 'Australia',
    comment: 'New South Wales (most areas)',
    latitude: -33.86666666666667,
    longitude: 151.21666666666667
  },
  'Europe/Andorra': {
    countryCode: 'AD',
    countryName: 'Andorra',
    comment: '',
    latitude: 42.5,
    longitude: 1.5166666666666666
  },
  'Europe/Astrakhan': {
    countryCode: 'RU',
    countryName: 'Russia',
    comment: 'MSK+01 - Astrakhan',
    latitude: 46.35,
    longitude: 48.05
  },
  'Europe/Athens': {
    countryCode: 'GR',
    countryName: 'Greece',
    comment: '',
    latitude: 37.96666666666667,
    longitude: 23.716666666666665
  },
  'Europe/Belgrade': {
    countryCode: 'RS',
    countryName: 'Serbia',
    comment: '',
    latitude: 44.833333333333336,
    longitude: 20.5
  },
  'Europe/Ljubljana': {
    countryCode: 'RS',
    countryName: 'Serbia',
    comment: '',
    latitude: 44.833333333333336,
    longitude: 20.5
  },
  'Europe/Podgorica': {
    countryCode: 'RS',
    countryName: 'Serbia',
    comment: '',
    latitude: 44.833333333333336,
    longitude: 20.5
  },
  'Europe/Sarajevo': {
    countryCode: 'RS',
    countryName: 'Serbia',
    comment: '',
    latitude: 44.833333333333336,
    longitude: 20.5
  },
  'Europe/Skopje': {
    countryCode: 'RS',
    countryName: 'Serbia',
    comment: '',
    latitude: 44.833333333333336,
    longitude: 20.5
  },
  'Europe/Zagreb': {
    countryCode: 'RS',
    countryName: 'Serbia',
    comment: '',
    latitude: 44.833333333333336,
    longitude: 20.5
  },
  'Europe/Berlin': {
    countryCode: 'DE',
    countryName: 'Germany',
    comment: 'Germany (most areas)',
    latitude: 52.5,
    longitude: 13.366666666666667
  },
  'Arctic/Longyearbyen': {
    countryCode: 'DE',
    countryName: 'Germany',
    comment: 'Germany (most areas)',
    latitude: 52.5,
    longitude: 13.366666666666667
  },
  'Atlantic/Jan_Mayen': {
    countryCode: 'DE',
    countryName: 'Germany',
    comment: 'Germany (most areas)',
    latitude: 52.5,
    longitude: 13.366666666666667
  },
  'Europe/Copenhagen': {
    countryCode: 'DE',
    countryName: 'Germany',
    comment: 'Germany (most areas)',
    latitude: 52.5,
    longitude: 13.366666666666667
  },
  'Europe/Oslo': {
    countryCode: 'DE',
    countryName: 'Germany',
    comment: 'Germany (most areas)',
    latitude: 52.5,
    longitude: 13.366666666666667
  },
  'Europe/Stockholm': {
    countryCode: 'DE',
    countryName: 'Germany',
    comment: 'Germany (most areas)',
    latitude: 52.5,
    longitude: 13.366666666666667
  },
  'Europe/Brussels': {
    countryCode: 'BE',
    countryName: 'Belgium',
    comment: '',
    latitude: 50.833333333333336,
    longitude: 4.333333333333333
  },
  'Europe/Amsterdam': {
    countryCode: 'BE',
    countryName: 'Belgium',
    comment: '',
    latitude: 50.833333333333336,
    longitude: 4.333333333333333
  },
  'Europe/Luxembourg': {
    countryCode: 'BE',
    countryName: 'Belgium',
    comment: '',
    latitude: 50.833333333333336,
    longitude: 4.333333333333333
  },
  'Europe/Bucharest': {
    countryCode: 'RO',
    countryName: 'Romania',
    comment: '',
    latitude: 44.43333333333333,
    longitude: 26.1
  },
  'Europe/Budapest': {
    countryCode: 'HU',
    countryName: 'Hungary',
    comment: '',
    latitude: 47.5,
    longitude: 19.083333333333332
  },
  'Europe/Chisinau': {
    countryCode: 'MD',
    countryName: 'Moldova',
    comment: '',
    latitude: 47,
    longitude: 28.833333333333332
  },
  'Europe/Tiraspol': {
    countryCode: 'MD',
    countryName: 'Moldova',
    comment: '',
    latitude: 47,
    longitude: 28.833333333333332
  },
  'Europe/Dublin': {
    countryCode: 'IE',
    countryName: 'Ireland',
    comment: '',
    latitude: 53.333333333333336,
    longitude: -6.25
  },
  Eire: {
    countryCode: 'IE',
    countryName: 'Ireland',
    comment: '',
    latitude: 53.333333333333336,
    longitude: -6.25
  },
  'Europe/Gibraltar': {
    countryCode: 'GI',
    countryName: 'Gibraltar',
    comment: '',
    latitude: 36.13333333333333,
    longitude: -5.35
  },
  'Europe/Helsinki': {
    countryCode: 'FI',
    countryName: 'Finland',
    comment: '',
    latitude: 60.166666666666664,
    longitude: 24.966666666666665
  },
  'Europe/Mariehamn': {
    countryCode: 'FI',
    countryName: 'Finland',
    comment: '',
    latitude: 60.166666666666664,
    longitude: 24.966666666666665
  },
  'Europe/Istanbul': {
    countryCode: 'TR',
    countryName: 'Turkey',
    comment: '',
    latitude: 41.016666666666666,
    longitude: 28.966666666666665
  },
  'Asia/Istanbul': {
    countryCode: 'TR',
    countryName: 'Turkey',
    comment: '',
    latitude: 41.016666666666666,
    longitude: 28.966666666666665
  },
  Turkey: {
    countryCode: 'TR',
    countryName: 'Turkey',
    comment: '',
    latitude: 41.016666666666666,
    longitude: 28.966666666666665
  },
  'Europe/Kaliningrad': {
    countryCode: 'RU',
    countryName: 'Russia',
    comment: 'MSK-01 - Kaliningrad',
    latitude: 54.71666666666667,
    longitude: 20.5
  },
  'Europe/Kirov': {
    countryCode: 'RU',
    countryName: 'Russia',
    comment: 'MSK+00 - Kirov',
    latitude: 58.6,
    longitude: 49.65
  },
  'Europe/Kyiv': {
    countryCode: 'UA',
    countryName: 'Ukraine',
    comment: 'Ukraine (most areas)',
    latitude: 50.43333333333333,
    longitude: 30.516666666666666
  },
  'Europe/Kiev': {
    countryCode: 'UA',
    countryName: 'Ukraine',
    comment: 'Ukraine (most areas)',
    latitude: 50.43333333333333,
    longitude: 30.516666666666666
  },
  'Europe/Uzhgorod': {
    countryCode: 'UA',
    countryName: 'Ukraine',
    comment: 'Ukraine (most areas)',
    latitude: 50.43333333333333,
    longitude: 30.516666666666666
  },
  'Europe/Zaporozhye': {
    countryCode: 'UA',
    countryName: 'Ukraine',
    comment: 'Ukraine (most areas)',
    latitude: 50.43333333333333,
    longitude: 30.516666666666666
  },
  'Europe/Lisbon': {
    countryCode: 'PT',
    countryName: 'Portugal',
    comment: 'Portugal (mainland)',
    latitude: 38.71666666666667,
    longitude: -9.133333333333333
  },
  Portugal: {
    countryCode: 'PT',
    countryName: 'Portugal',
    comment: 'Portugal (mainland)',
    latitude: 38.71666666666667,
    longitude: -9.133333333333333
  },
  'Europe/London': {
    countryCode: 'GB',
    countryName: 'Britain (UK)',
    comment: '',
    latitude: 51.50833333333333,
    longitude: -0.12527777777777777
  },
  'Europe/Belfast': {
    countryCode: 'GB',
    countryName: 'Britain (UK)',
    comment: '',
    latitude: 51.50833333333333,
    longitude: -0.12527777777777777
  },
  'Europe/Guernsey': {
    countryCode: 'GB',
    countryName: 'Britain (UK)',
    comment: '',
    latitude: 51.50833333333333,
    longitude: -0.12527777777777777
  },
  'Europe/Isle_of_Man': {
    countryCode: 'GB',
    countryName: 'Britain (UK)',
    comment: '',
    latitude: 51.50833333333333,
    longitude: -0.12527777777777777
  },
  'Europe/Jersey': {
    countryCode: 'GB',
    countryName: 'Britain (UK)',
    comment: '',
    latitude: 51.50833333333333,
    longitude: -0.12527777777777777
  },
  GB: {
    countryCode: 'GB',
    countryName: 'Britain (UK)',
    comment: '',
    latitude: 51.50833333333333,
    longitude: -0.12527777777777777
  },
  'GB-Eire': {
    countryCode: 'GB',
    countryName: 'Britain (UK)',
    comment: '',
    latitude: 51.50833333333333,
    longitude: -0.12527777777777777
  },
  'Europe/Madrid': {
    countryCode: 'ES',
    countryName: 'Spain',
    comment: 'Spain (mainland)',
    latitude: 40.4,
    longitude: -3.683333333333333
  },
  'Europe/Malta': {
    countryCode: 'MT',
    countryName: 'Malta',
    comment: '',
    latitude: 35.9,
    longitude: 14.516666666666667
  },
  'Europe/Minsk': {
    countryCode: 'BY',
    countryName: 'Belarus',
    comment: '',
    latitude: 53.9,
    longitude: 27.566666666666666
  },
  'Europe/Moscow': {
    countryCode: 'RU',
    countryName: 'Russia',
    comment: 'MSK+00 - Moscow area',
    latitude: 55.755833333333335,
    longitude: 37.617777777777775
  },
  'W-SU': {
    countryCode: 'RU',
    countryName: 'Russia',
    comment: 'MSK+00 - Moscow area',
    latitude: 55.755833333333335,
    longitude: 37.617777777777775
  },
  'Europe/Paris': {
    countryCode: 'FR',
    countryName: 'France',
    comment: '',
    latitude: 48.86666666666667,
    longitude: 2.3333333333333335
  },
  'Europe/Monaco': {
    countryCode: 'FR',
    countryName: 'France',
    comment: '',
    latitude: 48.86666666666667,
    longitude: 2.3333333333333335
  },
  'Europe/Prague': {
    countryCode: 'CZ',
    countryName: 'Czech Republic',
    comment: '',
    latitude: 50.083333333333336,
    longitude: 14.433333333333334
  },
  'Europe/Bratislava': {
    countryCode: 'CZ',
    countryName: 'Czech Republic',
    comment: '',
    latitude: 50.083333333333336,
    longitude: 14.433333333333334
  },
  'Europe/Riga': {
    countryCode: 'LV',
    countryName: 'Latvia',
    comment: '',
    latitude: 56.95,
    longitude: 24.1
  },
  'Europe/Rome': {
    countryCode: 'IT',
    countryName: 'Italy',
    comment: '',
    latitude: 41.9,
    longitude: 12.483333333333333
  },
  'Europe/San_Marino': {
    countryCode: 'IT',
    countryName: 'Italy',
    comment: '',
    latitude: 41.9,
    longitude: 12.483333333333333
  },
  'Europe/Vatican': {
    countryCode: 'IT',
    countryName: 'Italy',
    comment: '',
    latitude: 41.9,
    longitude: 12.483333333333333
  },
  'Europe/Samara': {
    countryCode: 'RU',
    countryName: 'Russia',
    comment: 'MSK+01 - Samara, Udmurtia',
    latitude: 53.2,
    longitude: 50.15
  },
  'Europe/Saratov': {
    countryCode: 'RU',
    countryName: 'Russia',
    comment: 'MSK+01 - Saratov',
    latitude: 51.56666666666667,
    longitude: 46.03333333333333
  },
  'Europe/Simferopol': {
    countryCode: 'UA',
    countryName: 'Ukraine',
    comment: 'Crimea',
    latitude: 44.95,
    longitude: 34.1
  },
  'Europe/Sofia': {
    countryCode: 'BG',
    countryName: 'Bulgaria',
    comment: '',
    latitude: 42.68333333333333,
    longitude: 23.316666666666666
  },
  'Europe/Tallinn': {
    countryCode: 'EE',
    countryName: 'Estonia',
    comment: '',
    latitude: 59.416666666666664,
    longitude: 24.75
  },
  'Europe/Tirane': {
    countryCode: 'AL',
    countryName: 'Albania',
    comment: '',
    latitude: 41.333333333333336,
    longitude: 19.833333333333332
  },
  'Europe/Ulyanovsk': {
    countryCode: 'RU',
    countryName: 'Russia',
    comment: 'MSK+01 - Ulyanovsk',
    latitude: 54.333333333333336,
    longitude: 48.4
  },
  'Europe/Vienna': {
    countryCode: 'AT',
    countryName: 'Austria',
    comment: '',
    latitude: 48.21666666666667,
    longitude: 16.333333333333332
  },
  'Europe/Vilnius': {
    countryCode: 'LT',
    countryName: 'Lithuania',
    comment: '',
    latitude: 54.68333333333333,
    longitude: 25.316666666666666
  },
  'Europe/Volgograd': {
    countryCode: 'RU',
    countryName: 'Russia',
    comment: 'MSK+00 - Volgograd',
    latitude: 48.733333333333334,
    longitude: 44.416666666666664
  },
  'Europe/Warsaw': {
    countryCode: 'PL',
    countryName: 'Poland',
    comment: '',
    latitude: 52.25,
    longitude: 21
  },
  Poland: {
    countryCode: 'PL',
    countryName: 'Poland',
    comment: '',
    latitude: 52.25,
    longitude: 21
  },
  'Europe/Zurich': {
    countryCode: 'CH',
    countryName: 'Switzerland',
    comment: '',
    latitude: 47.38333333333333,
    longitude: 8.533333333333333
  },
  'Europe/Busingen': {
    countryCode: 'CH',
    countryName: 'Switzerland',
    comment: '',
    latitude: 47.38333333333333,
    longitude: 8.533333333333333
  },
  'Europe/Vaduz': {
    countryCode: 'CH',
    countryName: 'Switzerland',
    comment: '',
    latitude: 47.38333333333333,
    longitude: 8.533333333333333
  },
  'Indian/Chagos': {
    countryCode: 'IO',
    countryName: 'British Indian Ocean Territory',
    comment: '',
    latitude: -7.333333333333333,
    longitude: 72.41666666666667
  },
  'Indian/Maldives': {
    countryCode: 'MV',
    countryName: 'Maldives',
    comment: '',
    latitude: 4.166666666666667,
    longitude: 73.5
  },
  'Indian/Kerguelen': {
    countryCode: 'MV',
    countryName: 'Maldives',
    comment: '',
    latitude: 4.166666666666667,
    longitude: 73.5
  },
  'Indian/Mauritius': {
    countryCode: 'MU',
    countryName: 'Mauritius',
    comment: '',
    latitude: -20.166666666666668,
    longitude: 57.5
  },
  'Pacific/Apia': {
    countryCode: 'WS',
    countryName: 'Samoa (western)',
    comment: '',
    latitude: -13.833333333333334,
    longitude: -171.73333333333332
  },
  'Pacific/Auckland': {
    countryCode: 'NZ',
    countryName: 'New Zealand',
    comment: 'New Zealand (most areas)',
    latitude: -36.86666666666667,
    longitude: 174.76666666666668
  },
  'Antarctica/McMurdo': {
    countryCode: 'NZ',
    countryName: 'New Zealand',
    comment: 'New Zealand (most areas)',
    latitude: -36.86666666666667,
    longitude: 174.76666666666668
  },
  'Antarctica/South_Pole': {
    countryCode: 'NZ',
    countryName: 'New Zealand',
    comment: 'New Zealand (most areas)',
    latitude: -36.86666666666667,
    longitude: 174.76666666666668
  },
  NZ: {
    countryCode: 'NZ',
    countryName: 'New Zealand',
    comment: 'New Zealand (most areas)',
    latitude: -36.86666666666667,
    longitude: 174.76666666666668
  },
  'Pacific/Bougainville': {
    countryCode: 'PG',
    countryName: 'Papua New Guinea',
    comment: 'Bougainville',
    latitude: -6.216666666666667,
    longitude: 155.56666666666666
  },
  'Pacific/Chatham': {
    countryCode: 'NZ',
    countryName: 'New Zealand',
    comment: 'Chatham Islands',
    latitude: -43.95,
    longitude: -176.55
  },
  'NZ-CHAT': {
    countryCode: 'NZ',
    countryName: 'New Zealand',
    comment: 'Chatham Islands',
    latitude: -43.95,
    longitude: -176.55
  },
  'Pacific/Easter': {
    countryCode: 'CL',
    countryName: 'Chile',
    comment: 'Easter Island',
    latitude: -27.15,
    longitude: -109.43333333333334
  },
  'Chile/EasterIsland': {
    countryCode: 'CL',
    countryName: 'Chile',
    comment: 'Easter Island',
    latitude: -27.15,
    longitude: -109.43333333333334
  },
  'Pacific/Efate': {
    countryCode: 'VU',
    countryName: 'Vanuatu',
    comment: '',
    latitude: -17.666666666666668,
    longitude: 168.41666666666666
  },
  'Pacific/Fakaofo': {
    countryCode: 'TK',
    countryName: 'Tokelau',
    comment: '',
    latitude: -9.366666666666667,
    longitude: -171.23333333333332
  },
  'Pacific/Fiji': {
    countryCode: 'FJ',
    countryName: 'Fiji',
    comment: '',
    latitude: -18.133333333333333,
    longitude: 178.41666666666666
  },
  'Pacific/Galapagos': {
    countryCode: 'EC',
    countryName: 'Ecuador',
    comment: 'Galapagos Islands',
    latitude: -0.9,
    longitude: -89.6
  },
  'Pacific/Gambier': {
    countryCode: 'PF',
    countryName: 'French Polynesia',
    comment: 'Gambier Islands',
    latitude: -23.133333333333333,
    longitude: -134.95
  },
  'Pacific/Guadalcanal': {
    countryCode: 'SB',
    countryName: 'Solomon Islands',
    comment: '',
    latitude: -9.533333333333333,
    longitude: 160.2
  },
  'Pacific/Pohnpei': {
    countryCode: 'SB',
    countryName: 'Solomon Islands',
    comment: '',
    latitude: -9.533333333333333,
    longitude: 160.2
  },
  'Pacific/Ponape': {
    countryCode: 'SB',
    countryName: 'Solomon Islands',
    comment: '',
    latitude: -9.533333333333333,
    longitude: 160.2
  },
  'Pacific/Guam': {
    countryCode: 'GU',
    countryName: 'Guam',
    comment: '',
    latitude: 13.466666666666667,
    longitude: 144.75
  },
  'Pacific/Saipan': {
    countryCode: 'GU',
    countryName: 'Guam',
    comment: '',
    latitude: 13.466666666666667,
    longitude: 144.75
  },
  'Pacific/Honolulu': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Hawaii',
    latitude: 21.306944444444444,
    longitude: -157.85833333333332
  },
  'Pacific/Johnston': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Hawaii',
    latitude: 21.306944444444444,
    longitude: -157.85833333333332
  },
  'US/Hawaii': {
    countryCode: 'US',
    countryName: 'United States',
    comment: 'Hawaii',
    latitude: 21.306944444444444,
    longitude: -157.85833333333332
  },
  'Pacific/Kanton': {
    countryCode: 'KI',
    countryName: 'Kiribati',
    comment: 'Phoenix Islands',
    latitude: -2.783333333333333,
    longitude: -171.71666666666667
  },
  'Pacific/Enderbury': {
    countryCode: 'KI',
    countryName: 'Kiribati',
    comment: 'Phoenix Islands',
    latitude: -2.783333333333333,
    longitude: -171.71666666666667
  },
  'Pacific/Kiritimati': {
    countryCode: 'KI',
    countryName: 'Kiribati',
    comment: 'Line Islands',
    latitude: 1.8666666666666667,
    longitude: -157.33333333333334
  },
  'Pacific/Kosrae': {
    countryCode: 'FM',
    countryName: 'Micronesia',
    comment: 'Kosrae',
    latitude: 5.316666666666666,
    longitude: 162.98333333333332
  },
  'Pacific/Kwajalein': {
    countryCode: 'MH',
    countryName: 'Marshall Islands',
    comment: 'Kwajalein',
    latitude: 9.083333333333334,
    longitude: 167.33333333333334
  },
  Kwajalein: {
    countryCode: 'MH',
    countryName: 'Marshall Islands',
    comment: 'Kwajalein',
    latitude: 9.083333333333334,
    longitude: 167.33333333333334
  },
  'Pacific/Marquesas': {
    countryCode: 'PF',
    countryName: 'French Polynesia',
    comment: 'Marquesas Islands',
    latitude: -9,
    longitude: -139.5
  },
  'Pacific/Nauru': {
    countryCode: 'NR',
    countryName: 'Nauru',
    comment: '',
    latitude: -0.5166666666666667,
    longitude: 166.91666666666666
  },
  'Pacific/Niue': {
    countryCode: 'NU',
    countryName: 'Niue',
    comment: '',
    latitude: -19.016666666666666,
    longitude: -169.91666666666666
  },
  'Pacific/Norfolk': {
    countryCode: 'NF',
    countryName: 'Norfolk Island',
    comment: '',
    latitude: -29.05,
    longitude: 167.96666666666667
  },
  'Pacific/Noumea': {
    countryCode: 'NC',
    countryName: 'New Caledonia',
    comment: '',
    latitude: -22.266666666666666,
    longitude: 166.45
  },
  'Pacific/Pago_Pago': {
    countryCode: 'AS',
    countryName: 'Samoa (American)',
    comment: '',
    latitude: -14.266666666666667,
    longitude: -170.7
  },
  'Pacific/Midway': {
    countryCode: 'AS',
    countryName: 'Samoa (American)',
    comment: '',
    latitude: -14.266666666666667,
    longitude: -170.7
  },
  'Pacific/Samoa': {
    countryCode: 'AS',
    countryName: 'Samoa (American)',
    comment: '',
    latitude: -14.266666666666667,
    longitude: -170.7
  },
  'US/Samoa': {
    countryCode: 'AS',
    countryName: 'Samoa (American)',
    comment: '',
    latitude: -14.266666666666667,
    longitude: -170.7
  },
  'Pacific/Palau': {
    countryCode: 'PW',
    countryName: 'Palau',
    comment: '',
    latitude: 7.333333333333333,
    longitude: 134.48333333333332
  },
  'Pacific/Pitcairn': {
    countryCode: 'PN',
    countryName: 'Pitcairn',
    comment: '',
    latitude: -25.066666666666666,
    longitude: -130.08333333333334
  },
  'Pacific/Port_Moresby': {
    countryCode: 'PG',
    countryName: 'Papua New Guinea',
    comment: 'Papua New Guinea (most areas)',
    latitude: -9.5,
    longitude: 147.16666666666666
  },
  'Antarctica/DumontDUrville': {
    countryCode: 'PG',
    countryName: 'Papua New Guinea',
    comment: 'Papua New Guinea (most areas)',
    latitude: -9.5,
    longitude: 147.16666666666666
  },
  'Pacific/Chuuk': {
    countryCode: 'PG',
    countryName: 'Papua New Guinea',
    comment: 'Papua New Guinea (most areas)',
    latitude: -9.5,
    longitude: 147.16666666666666
  },
  'Pacific/Truk': {
    countryCode: 'PG',
    countryName: 'Papua New Guinea',
    comment: 'Papua New Guinea (most areas)',
    latitude: -9.5,
    longitude: 147.16666666666666
  },
  'Pacific/Yap': {
    countryCode: 'PG',
    countryName: 'Papua New Guinea',
    comment: 'Papua New Guinea (most areas)',
    latitude: -9.5,
    longitude: 147.16666666666666
  },
  'Pacific/Rarotonga': {
    countryCode: 'CK',
    countryName: 'Cook Islands',
    comment: '',
    latitude: -21.233333333333334,
    longitude: -159.76666666666668
  },
  'Pacific/Tahiti': {
    countryCode: 'PF',
    countryName: 'French Polynesia',
    comment: 'Society Islands',
    latitude: -17.533333333333335,
    longitude: -149.56666666666666
  },
  'Pacific/Tarawa': {
    countryCode: 'KI',
    countryName: 'Kiribati',
    comment: 'Gilbert Islands',
    latitude: 1.4166666666666667,
    longitude: 173
  },
  'Pacific/Funafuti': {
    countryCode: 'KI',
    countryName: 'Kiribati',
    comment: 'Gilbert Islands',
    latitude: 1.4166666666666667,
    longitude: 173
  },
  'Pacific/Majuro': {
    countryCode: 'KI',
    countryName: 'Kiribati',
    comment: 'Gilbert Islands',
    latitude: 1.4166666666666667,
    longitude: 173
  },
  'Pacific/Wake': {
    countryCode: 'KI',
    countryName: 'Kiribati',
    comment: 'Gilbert Islands',
    latitude: 1.4166666666666667,
    longitude: 173
  },
  'Pacific/Wallis': {
    countryCode: 'KI',
    countryName: 'Kiribati',
    comment: 'Gilbert Islands',
    latitude: 1.4166666666666667,
    longitude: 173
  },
  'Pacific/Tongatapu': {
    countryCode: 'TO',
    countryName: 'Tonga',
    comment: '',
    latitude: -21.133333333333333,
    longitude: -175.2
  }
}
