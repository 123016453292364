import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

@Module({ namespaced: true })
export default class lessonActions extends VuexModule {
    copy = [
      { id: 'all', section: 'all', selected: true },
      { id: 'lessonTitle', section: 'lessonTitle', selected: false },
      { id: 'lessonText', section: 'lesson', selected: false },
      { id: 'tab2Text', section: 'tab2', selected: false },
      { id: 'tab3Text', section: 'tab3', selected: false },
      { id: 'tab4Text', section: 'tab4', selected: false },
      { id: 'tab5Text', section: 'tab5', selected: false },
      { id: 'tab6Text', section: 'tab6', selected: false },
      { id: 'standards', section: 'sharedStandards', selected: false },
      { id: 'myStandards', section: 'myStandards', selected: false },
      { id: 'schoolStandards', section: 'schoolStandards', selected: false },
      { id: 'strategies', section: 'strategies', selected: false },
      { id: 'attachments', section: 'attachments', selected: false },
      { id: 'linkedLessonId', section: 'linkedLessonId', selected: false }
    ];

    forward = 1;
    backward = 1;
    extend = 1;
    extendStandards = 1;
    printOptions: any = {};

    get getCopyLabel() {
      let copyCount = 0;
      for (const i in this.copy) {
        const c = this.copy[i];
        if (c.section !== 'all' && c.selected) {
          copyCount++;
        }
      }
      return copyCount > 0 ? '' + copyCount : 'All';
    }

    get isCopyAllSections() {
      return this.copy[0].selected;
    }

    get getSectionsToCopy() {
      const toCopy = [];
      if (!this.isCopyAllSections) {
        for (const i in this.copy) {
          if (this.copy[i].selected) {
            toCopy.push(this.copy[i].id);
          }
        }
      }
      return toCopy;
    }

    @Mutation
    setCopy(copy: Array<any>) {
      this.copy = copy;
    }

    @Mutation
    setForward(forward: number) {
      this.forward = forward;
    }

    @Mutation
    setBackward(backward: number) {
      this.backward = backward;
    }

    @Mutation
    setExtend(extend: number) {
      this.extend = extend;
    }

    @Mutation
    setExtendStandards(extendStandards: number) {
      this.extendStandards = extendStandards;
    }

    @Mutation
    setPrintOptions(printOptions: any) {
      this.printOptions = printOptions;
    }

    @Action({ rawError: true })
    init() {
      this.copy = [];
      this.forward = 1;
      this.backward = 1;
      this.extend = 1;
      this.extendStandards = 1;
      this.printOptions = {};
    }
}
