
import ViewSettings from '@/components/drawer/ViewSettings.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const drawer = namespace('drawer');

@Component({
  components: {
    ViewSettings
  }
})
export default class BottomDrawer extends Vue {
  localDrawerBottom = false;

  @drawer.State
  drawerBottom!: boolean;

  @drawer.Mutation
  setDrawerBottom!: (v:boolean) => void;

  @Watch('localDrawerBottom')
  updateDrawerBottom(v: boolean) {
    this.setDrawerBottom(v);
  }

  @Watch('drawerBottom')
  updateLocalDrawerBottom(v: boolean) {
    this.localDrawerBottom = v;
  }

  get drawerMode() {
    return this.$store.state.drawer.drawerMode;
  }

  set drawerMode(val: number) {
    this.$store.commit('drawer/setDrawerMode', val, { root: true });
  }
}
