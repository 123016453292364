var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "editor" },
    [
      _c(
        "v-form",
        { ref: "CSVEditorForm", attrs: { "lazy-validation": "" } },
        [
          _c(
            "v-container",
            { staticClass: "pt-4" },
            [
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "10" } },
                    [
                      _c("p", [_vm._v(_vm._s(_vm.$t("importCSVMsg1")))]),
                      _c("p", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("importCSVMsg2Gen", {
                              type: _vm.input.importWhat,
                            })
                          ),
                        },
                      }),
                      _c("v-file-input", {
                        staticClass: "mb-5",
                        attrs: {
                          rules: _vm.localRules,
                          "aria-required": "true",
                          clearable: "",
                          outlined: "",
                          "hide-details": "auto",
                          "prepend-inner-icon": "mdi-paperclip",
                          "prepend-icon": "",
                          accept: ".csv",
                          label: _vm.$t("csvFileLabel") + " (*.csv)",
                          placeholder: _vm.$t("csvFileLabel") + " (*.csv)",
                          error: _vm.errorUploadFile,
                        },
                        model: {
                          value: _vm.uploadedFile,
                          callback: function ($$v) {
                            _vm.uploadedFile = $$v
                          },
                          expression: "uploadedFile",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }