
























































import CommonUtils from '@/utils/common-utils';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { swatches } from '../../constants';

const settings = namespace('settings');

@Component
export default class ColorPicker extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  dense!: boolean;

  @Prop({ type: String, required: false, default: '' })
  icon!: string;

  @Prop({ type: Boolean, required: false, default: true })
  large!: boolean;

  @Prop({ type: Boolean, required: false, default: false })
  small!: boolean;

  @Prop({ type: String, required: false, default: 'top left' })
  origin!: string;

  @Prop({ type: String, required: false, default: '#FFFFFF' })
  value!: string;

  @Prop({ type: Boolean, required: false, default: true })
  closeOnClick!: boolean;

  @Prop({ type: String, required: false, default: '' })
  label!: string;

  localRef = CommonUtils.generateUUID();
  localMenu = false;

  @settings.Getter
  getSettings!: any;

  get colors(): any {
    return swatches;
  }

  get localValue() {
    return this.value;
  }

  set localValue(v: string) {
    this.$emit('update:value', v);
    this.$emit('input', v);
    this.$emit('change', v);
  }

  get localIcon() {
    if (this.localValue === '' || this.localValue === '#FFFFFF' || this.localValue === '#FFF') {
      return this.$props.icon.replace(/fas fa-/gm, 'fal fa-');
    } else {
      return this.icon;
    }
  }

  isColorReadable(color: string) {
    return CommonUtils.isColorReadable(color);
  }

  focusColorPicker() {
    if (!this.localMenu) {
      this.localMenu = true;
    }
    try {
      (this.$refs as any).colorPicker.$el.querySelector('.v-color-picker__input').querySelector('input').focus();
    } catch (e) {
      console.log(this.$t('focusFailed'));
    }
  }

  get localLabel() {
    if (CommonUtils.hasText(this.label)) {
      return this.label;
    } else {
      return this.$t('pickColorLabel');
    }
  }
}
