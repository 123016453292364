import { Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true })
export default class Local extends VuexModule {
    monthViewType = 'full'

    @Mutation
    setMonthViewType(monthViewType: string) {
      this.monthViewType = monthViewType;
    }
}
