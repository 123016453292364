import AppError, { createErrOptions, SessionExpiredError } from '@/errors';
import ReferenceDataServices from '@/services/referencedata-services';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true })
class ReferenceData extends VuexModule {
    holidays = [];
    countriesStates = [];
    haveCountriesStates = false;

    get getAllCountriesStates() {
      return this.countriesStates;
    }

    @Mutation
    setHolidays(holidays: any) {
      this.holidays = holidays;
    }

    @Mutation
    setCountriesStates(countriesStates: any) {
      this.countriesStates = countriesStates;
    }

    @Mutation
    setHaveCountriesStates(haveCountriesStates: boolean) {
      this.haveCountriesStates = haveCountriesStates;
    }

    @Action({ rawError: true })
    async loadCountriesStates() {
      try {
        if (this.haveCountriesStates) {
          return Promise.resolve({ data: this.countriesStates });
        }
        const resp = await ReferenceDataServices.getCountriesStates();
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            this.context.commit('setCountriesStates', resp.data);
            this.context.commit('setHaveCountriesStates', true);
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async loadHolidays() {
      try {
        const resp = await ReferenceDataServices.getHolidays();
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            this.context.commit('setHolidays', resp.data);
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }
}

export default ReferenceData;
