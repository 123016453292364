var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tooltip",
    {
      attrs: { bottom: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _vm.localItem.isItemRostered
                ? _c(
                    "v-icon",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "ml-2",
                          attrs: {
                            small: "",
                            color: _vm.isRosteredItemConnected(
                              _vm.localItemsAndType
                            )
                              ? "primary"
                              : "error"
                          }
                        },
                        "v-icon",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.isRosteredItemConnected(_vm.localItemsAndType)
                              ? "fal fa-bolt"
                              : "fal fa-bolt-slash"
                          ) +
                          " "
                      )
                    ]
                  )
                : _vm._e()
            ]
          }
        }
      ])
    },
    [
      _c("span", [_vm._v(_vm._s(_vm.$t(_vm.localLabel)))]),
      _vm.localItem.isItemRostered &&
      !_vm.isRosteredItemConnected(_vm.localItemsAndType)
        ? _c("span", [_vm._v(" (" + _vm._s(_vm.$t("disconnectedLabel")) + ")")])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }