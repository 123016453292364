var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-select", {
    attrs: {
      "hide-details": "auto",
      outlined: "",
      multiple: "",
      dense: "",
      "return-object": "",
      label: _vm.label,
      items: _vm.items,
      "item-text": _vm.itemText,
    },
    scopedSlots: _vm._u([
      {
        key: "prepend-item",
        fn: function () {
          return [
            _c(
              "v-list-item",
              {
                attrs: { ripple: "" },
                on: {
                  mousedown: function ($event) {
                    $event.preventDefault()
                  },
                  click: _vm.selectAllItems,
                },
              },
              [
                _c(
                  "v-list-item-action",
                  [
                    _c("v-checkbox", {
                      attrs: {
                        "input-value":
                          _vm.localSelected.length === _vm.items.length,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-list-item-content",
                  [
                    _c("v-list-item-title", [
                      _vm._v("All " + _vm._s(_vm.label)),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "selection",
        fn: function ({ item, index }) {
          return [
            index == 0
              ? _c("span", [
                  _vm.localSelected.length == 0
                    ? _c("span", [_vm._v("No " + _vm._s(_vm.label))])
                    : _vm.localSelected.length == 1
                    ? _c("span", [_vm._v(_vm._s(item.title))])
                    : _vm.localSelected.length == _vm.items.length
                    ? _c("span", [_vm._v("All " + _vm._s(_vm.label))])
                    : _c("span", [
                        _vm._v(
                          _vm._s(_vm.localSelected.length) +
                            " " +
                            _vm._s(_vm.label)
                        ),
                      ]),
                ])
              : _vm._e(),
          ]
        },
      },
    ]),
    model: {
      value: _vm.localSelected,
      callback: function ($$v) {
        _vm.localSelected = $$v
      },
      expression: "localSelected",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }