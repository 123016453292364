
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { gotoItems, seasonalIcons, employeeBirthdays, tawIcon } from '../../constants'
import { namespace } from 'vuex-class';
import moment from 'moment-timezone';
import CommonUtils from '@/utils/common-utils';
import DateTimeUtils from '@/utils/date-time-utils';

const settings = namespace('settings');
const drawer = namespace('drawer');
const teachers = namespace('teachers');

@Component
export default class LeftDrawer extends Vue {
  @Prop({ required: true })
  selected = '1';

  localSelected: string = this.selected;
  seasonalTooltip = 'app.name';
  isSeasonal = false;
  localMiniVariant = false;
  refreshKey = '';

  @settings.State
  studentId!: number;

  @settings.Getter('getLocationHost')
  private hostname!: string;

  @settings.Getter('getUserMode')
  userMode!: string;

  @settings.Getter('getUserType')
  userType!: string;

  @settings.Getter
  allowViewTeachers!: string;

  @settings.Action
  signOut!: () => Promise<any>;

  @drawer.State('drawerLeft')
  drawerLeft!: boolean;

  @drawer.State
  drawerLeftStateless!: boolean;

  @drawer.Mutation
  setDrawerLeft!: (v:boolean) => void;

  @settings.Getter
  hasNewAdminComment!: boolean;

  @settings.Getter
  hasNewTeacherComment!: boolean;

  @settings.Getter
  highContrastMode!: boolean;

  @settings.Getter
  getDisplaySettings!:any;

  @settings.Getter('getSchoolId')
  schoolId!: number;

  @teachers.Getter('getTeachersOnly')
  adminItems!: any[];

  get iconSrc() {
    const current = moment();
    // const current = moment('2023-01-01', 'YYYY-MM-DD')

    if (DateTimeUtils.getTawDay(current) !== -1) {
      this.seasonalTooltip = tawIcon.tooltip;
      this.isSeasonal = true;
      return `https://cdn.planbook.com/${tawIcon.src}`;
    }

    for (var si of this.seasonalIcons) {
      var start = moment(`${si.start} 00:00:00`, 'MM-DD HH:mm:ss');
      var end = moment(`${si.end} 23:59:59`, 'MM-DD HH:mm:ss');
      var src = `https://cdn.planbook.com/${si.src}`;

      if (si.tooltip === 'happyBirthday') {
        for (var bday of employeeBirthdays) {
          start = moment(`${bday.birthday} 00:00:00`, 'MM-DD HH:mm:ss');
          end = moment(`${bday.birthday} 23:59:59`, 'MM-DD HH:mm:ss');

          if (current.isBetween(start, end, null, '[]')) {
            this.seasonalTooltip = this.$t(si.tooltip) + ' to Planbook\'s ' + bday.name + '!';
            this.isSeasonal = true;
            return src;
          }
        }
      } else if (current.isBetween(start, end, null, '[]')) {
        this.seasonalTooltip = si.tooltip;
        this.isSeasonal = true;
        if (si.tooltip === 'newYearTagline') {
          src += String(moment().year()).slice(2) + '.png';
        }
        return src;
      }
    }
    this.isSeasonal = false;
    return 'https://cdn.planbook.com/images/planbook-icon.svg';
  }

  get seasonalIcons() {
    return seasonalIcons;
  }

  get isGenericView() {
    return CommonUtils.hasText(this.$route?.query.t) && CommonUtils.hasText(this.$route?.query.k);
  }

  get gotoItems(): Array<any> {
    return gotoItems.filter((g: any) => {
      g.withBadge = g.to === '/messages' && ((this.hasNewAdminComment && this.userMode === 'T') || (this.hasNewTeacherComment && this.userMode === 'S') || (this.hasNewTeacherCommentForAdmin));
      if (this.userMode === 'S' && ['/grades', '/messages'].includes(g.to)) {
        return this.studentId > 0;
      } else if (this.allowViewTeachers && this.userMode === 'A' && this.userType === 'T') {
        return ['/plans'].includes(g.to);
      } else if (g.to === '/messages' && this.userMode !== 'M') {
        return this.$currentUser.id > 0;
      }
      return g.userModes.includes(this.userMode);
    });
  }

  get hasNewTeacherCommentForAdmin() {
    if (this.$currentUser.isAdmin) {
      return (this.adminItems || []).findIndex(item => {
        return CommonUtils.isTrue(item.newCommentT);
      }) > -1;
    }
    return false;
  }

  get expandOnHover() {
    if (this.drawerLeftStateless) {
      return !this.drawerLeft;
    }
    return !this.$vuetify.breakpoint.smAndDown;
  }

  get drawer(): boolean {
    return this.drawerLeft;
  }

  set drawer(v: boolean) {
    this.setDrawerLeft(v);
  }

  get leftDrawerMenuType():number {
    return this.getDisplaySettings.leftDrawerMenuType;
  }

  get isDockedExpandOnHover(): boolean {
    return this.leftDrawerMenuType === 0;
  }

  get isDockedCompact(): boolean {
    return this.leftDrawerMenuType === 1;
  }

  get isLeftDrawerMenuTypeHidden(): boolean {
    return this.leftDrawerMenuType === 2;
  }

  get isDrawerPermanent(): boolean {
    if (this.isLeftDrawerMenuTypeHidden) {
      return false;
    }
    if (!this.$vuetify.breakpoint.smAndDown) {
      return true;
    } else {
      return false;
    }
  }

  get isMiniVariant() {
    if (this.isDockedCompact) {
      return this.isDockedCompact
    } else {
      return this.localMiniVariant;
    }
  }

  set isMiniVariant(val:boolean) {
    if (this.isDockedCompact) {
    } else {
      this.localMiniVariant = val;
    }
  }

  getId(item: any) {
    return item.to.replaceAll('/', '');
  }

  @Watch('localSelected')
  private onLocalSelectedChange(v: string): void {
    this.$emit('update:selected', v);
  }

  @Watch('userMode')
  onUserModeChange() {
    this.$nextTick(() => {
      this.refreshKey = CommonUtils.generateUUID();
    })
  }
}
