import AppError, { createErrOptions, SessionExpiredError } from '@/errors';
import RemindersServices from '@/services/reminders-services';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true })
export default class Reminders extends VuexModule {
  eventsReminderFilter = true;
  todosReminderFilter = true;
  notesReminderFilter = true;
  assignmentsReminderFilter = true;
  assessmentsReminderFilter = true;
  studentsBirthdayReminderFilter = true;
  remindersFilter = ['toDos', 'notes', 'events', 'assessments', 'assignments', 'students'];

  // MUTATIONS: START
  @Mutation
  setEventsReminderFilter(eventsReminderFilter: boolean) {
    this.eventsReminderFilter = eventsReminderFilter;
  }

  @Mutation
  setTodosReminderFilter(todosReminderFilter: boolean) {
    this.todosReminderFilter = todosReminderFilter;
  }

  @Mutation
  setNotesReminderFilter(notesReminderFilter: boolean) {
    this.notesReminderFilter = notesReminderFilter;
  }

  @Mutation
  setAssignmentsReminderFilter(assignmentsReminderFilter: boolean) {
    this.assignmentsReminderFilter = assignmentsReminderFilter;
  }

  @Mutation
  setAssessmentsReminderFilter(assessmentsReminderFilter: boolean) {
    this.assessmentsReminderFilter = assessmentsReminderFilter;
  }

  @Mutation
  setStudentsBirthdayReminderFilter(studentsBirthdayReminderFilter: boolean) {
    this.studentsBirthdayReminderFilter = studentsBirthdayReminderFilter;
  }

  @Mutation
  setRemindersFilter(remindersFilter: any[]) {
    this.remindersFilter = remindersFilter;
  }

  @Action({ rawError: true })
  async updateSubPlans(params: any) {
    try {
      const resp = await RemindersServices.updateSubPlans(params);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          this.context.commit('settings/setSubPlans', params.subPlans, { root: true });
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async emailSubNotes(params: any) {
    try {
      const resp = await RemindersServices.emailSubNotes(params);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async updateStudentNotes(params: any) {
    try {
      const resp = await RemindersServices.updateStudentNotes(params);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          this.context.commit('settings/setStudentNotes', params.studentNotes, { root: true });
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }
}
