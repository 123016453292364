
































































































import CommonUtils from '@/utils/common-utils';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Confirm extends Vue {
  zIndex = 99999999999;
  localResolve!: any;
  localConfirming = false;
  localConfirmTitle = 'Please Confirm';
  localConfirmSubtitle = '';
  localConfirmText = '';
  localConfirmCancelButtonText = 'Cancel';
  localConfirmOption1ButtonText = 'Continue';
  localConfirmOption2ButtonText = '';
  localConfirmOption3ButtonText = '';
  localConfirmOption4ButtonText = '';
  localType = 'confirm';

  @Prop({ required: false, type: Boolean, default: true })
  persistent!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  closeButtonOnHeader!: boolean;

  @Prop({ required: false, type: String, default: '' })
  contentClass!: string;

  $refs!: {
    cancelButton: any,
    optionButton4: any,
    optionButton3: any,
    optionButton2: any,
    confirmButton: any
  }

  get localContentClass() {
    return `confirm ${this.contentClass}`;
  }

  get localCardTextStyle() {
    if (this.closeButtonOnHeader) {
      return {
        'max-height': 'calc(100vh - 135px)',
        'overflow-y': 'auto'
      }
    }
    return {};
  }

  hasText(v: string) {
    return CommonUtils.hasText(v);
  }

  action(v: number) {
    this.localResolve(v);
    this.localConfirming = false;
  }

  determineZIndex() {
    let newZIndex = 0;
    document.querySelectorAll('[style*="z-index"]').forEach((e: Element) => {
      if (e instanceof HTMLElement) {
        if (+e.style.zIndex > newZIndex) {
          newZIndex = +e.style.zIndex;
        }
      }
    });
    newZIndex += 100;
    this.zIndex = newZIndex;
  }

  doCancel() {
    if (this.localType === 'confirm') {
      this.action(0)
    }
  }

  focusButton(index: any, increment: any) {
    try {
      while (index >= 0 && index <= 4) {
        index = index + increment;
        if ((index === 0 || index === 5) && this.localType === 'confirm') {
          this.$refs.cancelButton.$el.focus();
          return;
        } if (index === 1 && this.hasText(this.localConfirmOption4ButtonText)) {
          this.$refs.optionButton4.$el.focus();
          return;
        } else if (index === 2 && this.hasText(this.localConfirmOption3ButtonText)) {
          this.$refs.optionButton3.$el.focus();
          return;
        } else if (index === 3 && this.hasText(this.localConfirmOption2ButtonText)) {
          this.$refs.optionButton2.$el.focus();
          return;
        } else if ((index === 4 || index === -1) && this.hasText(this.localConfirmOption1ButtonText)) {
          this.$refs.confirmButton.$el.focus();
          return;
        }
      }
    } catch (e) {
      console.log(this.$t('focusFailed'));
    }
  }

  confirm(payload: any): Promise<number> {
    this.$nextTick(() => {
      new Promise(resolve => setTimeout(resolve, 100)).then(() => {
        try {
          this.$refs.cancelButton.$el.focus();
        } catch (e) {
          console.log(this.$t('focusFailed'));
        }
      });
    });
    this.determineZIndex();
    this.localType = 'confirm';
    this.localConfirmTitle = payload.title;
    this.localConfirmSubtitle = payload.subtitle;
    this.localConfirmText = payload.text;
    this.localConfirmOption1ButtonText = payload.option1ButtonAlternativeText;
    this.localConfirmOption2ButtonText = payload.option2ButtonAlternativeText;
    this.localConfirmOption3ButtonText = payload.option3ButtonAlternativeText;
    this.localConfirmOption4ButtonText = payload.option4ButtonAlternativeText;
    this.localConfirmCancelButtonText = payload.cancelButtonText || 'Cancel';
    this.localConfirming = true;
    return new Promise((resolve) => {
      this.localResolve = resolve;
    });
  }

  alert(payload: any): Promise<number> {
    this.$nextTick(() => {
      new Promise(resolve => setTimeout(resolve, 100)).then(() => {
        try {
          this.$refs.confirmButton.$el.focus();
        } catch (e) {
          console.log(this.$t('focusFailed'));
        }
      });
    });
    this.determineZIndex();
    this.localType = 'alert';
    this.localConfirmTitle = payload.title;
    this.localConfirmSubtitle = payload.subtitle;
    this.localConfirmText = payload.text;
    this.localConfirmOption1ButtonText = payload.option1ButtonAlternativeText;
    this.localConfirmOption2ButtonText = payload.option2ButtonAlternativeText;
    this.localConfirmOption3ButtonText = payload.option3ButtonAlternativeText;
    this.localConfirmOption4ButtonText = payload.option4ButtonAlternativeText;
    this.localConfirming = true;
    return new Promise((resolve) => {
      this.localResolve = resolve;
    });
  }
}
