import { initializeApp } from 'firebase/app'
import { getMessaging } from 'firebase/messaging'
const firebaseConfig = {
  apiKey: 'AIzaSyCMz6ue1Aw311fS1AtwhCKjkMkYFTe7z14',
  authDomain: 'planbook-1369.firebaseapp.com',
  projectId: 'planbook-1369',
  storageBucket: 'planbook-1369.appspot.com',
  messagingSenderId: '318112608367',
  appId: '1:318112608367:web:6de44e0125f7677a53dd9a',
  measurementId: 'G-12WZKCH9KK'
}

const firebaseapp = initializeApp(firebaseConfig)
const firebaseMessaging = getMessaging(firebaseapp);

export default {
  firebaseMessaging
}
