var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "pb-base-editor",
    {
      attrs: {
        withActions: false,
        withApply: true,
        applying: _vm.listLoading,
        onApply: _vm.doApply,
        isDirty: _vm.isDirty,
        input: _vm.input,
        typeLabel: _vm.$t("notesTodosLabel"),
        isModal: _vm.isModal,
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c("div", [
                _c("span", [_vm._v(_vm._s(_vm.notesToDoDialogTitle))]),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.localValue,
        callback: function ($$v) {
          _vm.localValue = $$v
        },
        expression: "localValue",
      },
    },
    [
      _c("notes-todo-editor-form", {
        ref: "form",
        attrs: { input: _vm.input, isModal: _vm.isModal },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }