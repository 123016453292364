import qs from 'qs';
import axios from '@/axios';

export default class SettingsServices {
  public static saveSharedTeacher(params: unknown) {
    params = params || {};
    return axios.post('saveSharedTeacher', qs.stringify(params));
  }

  public static saveStatus(params: unknown) {
    params = params || {};
    return axios.post('services/planbook/status', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static deleteStatus(params: unknown) {
    params = params || {};
    return axios.post('services/planbook/status/delete', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static saveSchoolStatus(params: unknown) {
    params = params || {};
    return axios.post('services/planbook/schoolstatus/update', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static restoreSchoolStatus(params: unknown) {
    params = params || {};
    return axios.post('services/planbook/schoolstatus/restoreStatus', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static deleteSchoolStatus(params: unknown) {
    params = params || {};
    return axios.post('services/planbook/schoolstatus/deleteStatus', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static saveViewSettings(params: unknown) {
    params = params || {};
    return axios.post('updateViewPreferences', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static changeTerm(params: unknown) {
    params = params || {};
    return axios.post('changeTerm', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static save(params: unknown) {
    params = params || {};
    return axios.post('updateSettings', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static editOtherSettings(params?: any) {
    params = params || {};
    return axios.post('services/planbook/teacher/otherSettings', qs.stringify(params));
  }

  public static updateBannerInfo(params?: any) {
    params = params || {};
    return axios.post('services/planbook/teacher/updateBannerInfo', qs.stringify(params));
  }

  public static connectTeacherSchools(params?: any) {
    params = params || {};
    return axios.post('services/planbook/school/connectTeacherSchools', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }
}
