var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_vm.isEmbedded
    ? _c(
        "v-toolbar",
        {
          staticClass: "py-0 app-bar",
          class: { compact: _vm.isCompact },
          attrs: {
            id: "baseToolbar",
            color: "transparent",
            elevation: _vm.$vuetify.breakpoint.smAndDown ? 0 : 1,
          },
        },
        [
          !_vm.$vuetify.breakpoint.smAndDown
            ? _c(
                "v-row",
                {
                  class: _vm.$vuetify.breakpoint.smAndDown
                    ? "app-bar-sm-down"
                    : "app-bar-not-sm",
                  attrs: { align: "center" },
                },
                [
                  (_vm.$vuetify.breakpoint.smAndDown ||
                    _vm.isLeftDrawerMenuTypeHidden) &&
                  !_vm.isGenericView
                    ? _c(
                        "pb-btn",
                        {
                          staticClass: "mr-1",
                          attrs: {
                            label: _vm.$t("goToLabel"),
                            icon: "",
                            id: "nav",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.setDrawerLeft(!_vm.drawerLeft)
                            },
                          },
                        },
                        [
                          _vm.withBadge
                            ? _c(
                                "v-badge",
                                {
                                  attrs: {
                                    dot: "",
                                    color: "error",
                                    "offset-x": "4",
                                    "offset-y": "2",
                                  },
                                },
                                [_c("v-icon", [_vm._v("fal fa-bars")])],
                                1
                              )
                            : _c("v-icon", [_vm._v("fal fa-bars")]),
                        ],
                        1
                      )
                    : !_vm.$vuetify.breakpoint.smAndDown && !_vm.isGenericView
                    ? _c(
                        "pb-btn",
                        {
                          staticClass: "mr-12",
                          attrs: {
                            label: _vm.$t("goToLabel"),
                            icon: "",
                            id: "nav",
                          },
                        },
                        [_c("v-icon", [_vm._v("fal fa-bars")])],
                        1
                      )
                    : _vm._e(),
                  _vm.localPage !== "plans" && !_vm.$currentUser.isManager
                    ? _c(
                        "pb-btn",
                        {
                          staticClass: "mr-4 my-1",
                          attrs: {
                            label: _vm.$t("plansLabel"),
                            color: "primary",
                            disabled: _vm.localToolbarDisabled,
                            id: "home",
                            text: "",
                            outlined: "",
                            to: "/plans",
                          },
                        },
                        [_c("v-icon", [_vm._v("fal fa-angles-left")])],
                        1
                      )
                    : _vm._e(),
                  _vm.$currentUser.isDualOrAllowViewOnly && _vm.canSwitchModes
                    ? _c(
                        "v-menu",
                        {
                          attrs: {
                            disabled: _vm.localToolbarDisabled,
                            "offset-y": "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-avatar",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              color: "grey darken-1 mr-1",
                                              size: "36",
                                            },
                                          },
                                          "v-avatar",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "white--text" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.userModeFirstLetter)
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            715227887
                          ),
                        },
                        [
                          _c(
                            "v-list",
                            _vm._l(
                              _vm.localUserModeList,
                              function (item, index) {
                                return _c(
                                  "v-list-item",
                                  {
                                    key: index,
                                    class: {
                                      "primary--text v-list-item--active v-list-item--link theme--light v-list-item--highlighted":
                                        _vm.userMode == item.value,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.userModeChanged(item.value)
                                      },
                                    },
                                  },
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(_vm._s(item.text)),
                                    ]),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._t("toolbar-left"),
                  _c("v-spacer"),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        id: "toolbarTitle",
                        cols: "auto",
                        align: "center",
                      },
                    },
                    [
                      _vm._t("page-title", function () {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "page-title",
                              attrs: { role: "heading", "aria-level": "1" },
                            },
                            [_vm._v(" " + _vm._s(_vm.localTitle) + " ")]
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                  _c("v-spacer"),
                  _vm._t("toolbar-right"),
                  _vm.$vuetify.breakpoint.smAndDown
                    ? _c(
                        "pb-btn",
                        {
                          directives: [
                            {
                              name: "click-outside",
                              rawName: "v-click-outside",
                              value: _vm.hideRightDrawer,
                              expression: "hideRightDrawer",
                            },
                          ],
                          staticClass: "mr-3",
                          staticStyle: { cursor: "pointer" },
                          attrs: {
                            label: _vm.$t("settingsLabel"),
                            color: "rgba(0, 0, 0, 0.64)",
                            disabled: _vm.localToolbarDisabled,
                            text: "",
                            id: "account",
                            width: "60",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              _vm.isGenericView
                                ? undefined
                                : (_vm.localDrawerRight = !_vm.localDrawerRight)
                            },
                          },
                        },
                        [
                          _vm.hasProfilePhoto
                            ? _c("v-avatar", { attrs: { size: "36" } }, [
                                _c("img", {
                                  staticStyle: { "object-fit": "cover" },
                                  attrs: {
                                    src: _vm.profilePhotoUrl,
                                    alt: _vm.displayName,
                                  },
                                }),
                              ])
                            : _c("v-icon", [_vm._v("fas fa-user-circle")]),
                        ],
                        1
                      )
                    : _c(
                        "pb-btn",
                        {
                          directives: [
                            {
                              name: "click-outside",
                              rawName: "v-click-outside",
                              value: _vm.hideRightDrawer,
                              expression: "hideRightDrawer",
                            },
                          ],
                          staticClass: "mr-3",
                          class:
                            _vm.userMode === "T" ? "account-md-and-up" : "",
                          staticStyle: { cursor: "pointer" },
                          attrs: {
                            label: _vm.userSettingsLabel,
                            color: _vm.$vuetify.theme.dark
                              ? undefined
                              : "rgba(0, 0, 0, 0.64)",
                            disabled: _vm.localToolbarDisabled,
                            text: "",
                            id: "account",
                            width: "60",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              _vm.isGenericView
                                ? undefined
                                : (_vm.localDrawerRight = !_vm.localDrawerRight)
                            },
                          },
                        },
                        [
                          _vm.hasProfilePhoto
                            ? _c("v-avatar", { attrs: { size: "36" } }, [
                                _c("img", {
                                  staticStyle: { "object-fit": "cover" },
                                  attrs: {
                                    src: _vm.profilePhotoUrl,
                                    alt: _vm.displayName,
                                  },
                                }),
                              ])
                            : _c("v-icon", [_vm._v("fas fa-user-circle")]),
                        ],
                        1
                      ),
                ],
                2
              )
            : _c(
                "v-row",
                {
                  staticClass: "mobile-toolbar",
                  class: _vm.$vuetify.breakpoint.smAndDown
                    ? "app-bar-sm-down"
                    : "app-bar-not-sm",
                  attrs: { align: "center" },
                },
                [
                  _vm._t("mobile-toolbar", function () {
                    return [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", align: "center" } },
                        [
                          _c(
                            "v-card",
                            { attrs: { elevation: "2" } },
                            [
                              _c(
                                "v-card-title",
                                {
                                  staticClass:
                                    "pa-2 d-flex justify-space-between",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "mobile-toolbar-left" },
                                    [
                                      (_vm.$vuetify.breakpoint.smAndDown ||
                                        _vm.isLeftDrawerMenuTypeHidden) &&
                                      !_vm.isGenericView
                                        ? _c(
                                            "pb-btn",
                                            {
                                              staticClass: "mr-1",
                                              attrs: {
                                                label: _vm.$t("goToLabel"),
                                                icon: "",
                                                id: "nav",
                                                small: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  return _vm.setDrawerLeft(
                                                    !_vm.drawerLeft
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm.withBadge
                                                ? _c(
                                                    "v-badge",
                                                    {
                                                      attrs: {
                                                        dot: "",
                                                        color: "error",
                                                        "offset-x": "4",
                                                        "offset-y": "2",
                                                      },
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v("fal fa-bars"),
                                                      ]),
                                                    ],
                                                    1
                                                  )
                                                : _c("v-icon", [
                                                    _vm._v("fal fa-bars"),
                                                  ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.localPage !== "plans"
                                        ? _c(
                                            "pb-btn",
                                            {
                                              staticClass: "mr-1",
                                              attrs: {
                                                label: _vm.$t("plansLabel"),
                                                color: "primary",
                                                disabled:
                                                  _vm.localToolbarDisabled,
                                                id: "home",
                                                text: "",
                                                to: "/plans",
                                                small: "",
                                              },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("fal fa-angles-left"),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._t("mobile-toolbar-left"),
                                    ],
                                    2
                                  ),
                                  !_vm.searchable
                                    ? _c(
                                        "div",
                                        { attrs: { id: "mobileToolbarTitle" } },
                                        [
                                          _vm._t("page-title", function () {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "page-title" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.localTitle) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          }),
                                        ],
                                        2
                                      )
                                    : _vm._t(
                                        "mobile-search-field",
                                        function () {
                                          return [
                                            _c("v-text-field", {
                                              key: _vm.localRefreshKey,
                                              staticClass: "mobile-search",
                                              attrs: {
                                                dense: "",
                                                width: "100%",
                                                label: _vm.searchable
                                                  ? _vm.$t("searchInLabel", {
                                                      pageName: _vm.localTitle,
                                                    })
                                                  : "",
                                                solo: "",
                                                flat: "",
                                                "hide-details": "",
                                                "single-line": "",
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.$emit("typing")
                                                },
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "append",
                                                    fn: function () {
                                                      return [
                                                        _vm._t(
                                                          "mobile-search-filters"
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value: _vm.localSearchText,
                                                callback: function ($$v) {
                                                  _vm.localSearchText = $$v
                                                },
                                                expression: "localSearchText",
                                              },
                                            }),
                                          ]
                                        }
                                      ),
                                  _c(
                                    "div",
                                    { staticClass: "mobile-toolbar-right" },
                                    [
                                      _vm._t("mobile-toolbar-right"),
                                      _c(
                                        "pb-btn",
                                        {
                                          directives: [
                                            {
                                              name: "click-outside",
                                              rawName: "v-click-outside",
                                              value: _vm.hideRightDrawer,
                                              expression: "hideRightDrawer",
                                            },
                                          ],
                                          staticStyle: { cursor: "pointer" },
                                          attrs: {
                                            label: _vm.$t("settingsLabel"),
                                            color: "rgba(0, 0, 0, 0.64)",
                                            disabled: _vm.localToolbarDisabled,
                                            text: "",
                                            id: "account",
                                            small: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              _vm.isGenericView
                                                ? undefined
                                                : (_vm.localDrawerRight =
                                                    !_vm.localDrawerRight)
                                            },
                                          },
                                        },
                                        [
                                          _vm.hasProfilePhoto
                                            ? _c(
                                                "v-avatar",
                                                { attrs: { size: "36" } },
                                                [
                                                  _c("img", {
                                                    staticStyle: {
                                                      "object-fit": "cover",
                                                    },
                                                    attrs: {
                                                      src: _vm.profilePhotoUrl,
                                                      alt: _vm.displayName,
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _c("v-icon", [
                                                _vm._v("fas fa-user-circle"),
                                              ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  }),
                ],
                2
              ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }