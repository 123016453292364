import { PlanbookStorage } from '@/common/planbook-storage';
import AppError, { createErrOptions, SessionExpiredError } from '@/errors';
import SchoolServices from '@/services/school-services';
import { RemoveTeachersRequest, SaveTeacherRequest, TeacherServices } from '@/services/teacher-services';
import CommonUtils from '@/utils/common-utils';
import { AxiosResponse } from 'axios';
import ld from 'lodash';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';

@Module({ namespaced: true })
export default class Teachers extends VuexModule {
    teachers: any = {};
    teacher: any = {};
    currentTeacher: any = {};
    haveTeachers = false;
    currentSchoolId: number | null = null;
    // toolbar attributes
    searchText = '';
    selectedRows: Array<any> = [];
    editMode = false;

    get getTotalAccounts() {
      return this.getTeachersForAdmin.length;
    }

    get getCurrentTeacher() {
      const teacherId = this.context.rootState.settings.currentTeacherId;
      const teachers = ld.cloneDeep(this.teachers.teachers || []);
      if (CommonUtils.isNotEmpty(teachers)) {
        return teachers.find((t: any) => t.teacherId === teacherId);
      }
    }

    get getTeacherSchoolYears() {
      return (teacherId: number) => {
        const teacher = this.getTeachersAll.find(t => t.teacherId === teacherId);
        return teacher ? teacher.years : [];
      }
    }

    get getCurrentTeacherYear() {
      const yearId = this.context.rootState.settings.currentYearId;
      const teacher = this.getCurrentTeacher;
      if (teacher) {
        teacher.years = teacher.years || [];
        return teacher.years.find((y: any) => y.yearId === yearId);
      }
    }

    get getCurrentTeacherClassCycle() {
      return (this.getCurrentTeacherYear || { classCyle: 'oneWeek' }).classCycle;
    }

    get isCurrentTeacherCycleSchedule() {
      return !isNaN(+this.getCurrentTeacherClassCycle)
    }

    get getCurrentAdminItem() {
      const item = {
        teacherId: this.context.rootState.settings.currentTeacherId,
        name: '',
        year: {
          yearId: this.context.rootState.settings.currentYearId
        }
      }
      const teachers = this.teachers.teachers;
      if (CommonUtils.isNotEmpty(teachers)) {
        for (const i in teachers) {
          const teacher = teachers[i];
          const years = teacher.years;
          if (teacher.teacherId === item.teacherId) {
            item.name = Teachers.getDisplayName(teacher);
            if (CommonUtils.isNotEmpty(years)) {
              for (const y in years) {
                const year = years[y];
                if (year.yearId === item.year.yearId) {
                  item.year = year;
                  break;
                }
              }
            }
            break;
          }
        }
      }
      return item;
    }

    get getAdminItems() {
      const items = []
      const teachers = this.getTeachersOnly;
      for (const i in teachers) {
        const teacher = teachers[i];
        items.push({
          value: +teacher.teacherId,
          text: Teachers.getDisplayName(teacher)
        });
      }
      return items;
    }

    get getFirstYearId() {
      return (teacherId: number) => {
        const years: any[] = this.getAdminYearItems(teacherId);
        return CommonUtils.isEmpty(years) ? 0 : years[0].value;
      }
    }

    get getAdminItemYears() {
      return (teacherId: number) => {
        const teachers = this.getTeachersOnly;
        for (const i in teachers) {
          const teacher = teachers[i];
          if (teacher.teacherId === teacherId) {
            return CommonUtils.getOrDefaultTo(teacher.years, []);
          }
        }
        return [];
      }
    }

    get getAdminYearItems() {
      return (teacherId: number) => {
        const teachers = this.getTeachersOnly;
        const items = []
        for (const i in teachers) {
          const teacher = teachers[i];
          const years = teacher.years;
          if (teacher.teacherId === teacherId) {
            if (CommonUtils.isNotEmpty(teacher.years)) {
              for (const y in years) {
                const year = years[y];
                items.push({
                  value: year.yearId,
                  text: year.yearName,
                  firstDay: year.firstDay,
                  lastDay: year.lastDay,
                  parentId: year.parentYearId,
                  parentSchoolId: year.parentYearSchoolId,
                  parentDistrictId: year.parentYearDistrictId
                });
              }
            }
            break;
          }
        }
        return items;
      }
    }

    get getTeachersOnly() {
      const teachers = [];
      const allTeachers = this.teachers.teachers;
      if (CommonUtils.isNotEmpty(allTeachers)) {
        for (const i in allTeachers) {
          const teacher = allTeachers[i];
          if (!['A', 'D'].includes(teacher.userType)) {
            teachers.push(teacher);
          }
        }
      }
      return teachers;
    }

    get getTeachersAll() {
      const teachers = [];
      const allTeachers = this.teachers.teachers;
      if (CommonUtils.isNotEmpty(allTeachers)) {
        for (const i in allTeachers) {
          const teacher = allTeachers[i];
          teachers.push(teacher);
        }
      }
      return teachers;
    }

    get getTeachersForAdmin() {
      return ld.cloneDeep(this.teachers.teachersA || []);
    }

    public static getDisplayName(teacher: any): string {
      let displayName = '';
      displayName += CommonUtils.getOrDefaultTo(teacher.displayName, '');
      if (CommonUtils.hasNoText(displayName)) {
        displayName += CommonUtils.getOrDefaultTo(teacher.firstName, '');
        if (CommonUtils.hasText(displayName)) {
          displayName += ' ' + CommonUtils.getOrDefaultTo(teacher.lastName, '');
        } else {
          displayName += CommonUtils.getOrDefaultTo(teacher.lastName, '');
        }
      }
      if (CommonUtils.hasNoText(displayName)) {
        displayName += teacher.emailAddress;
      }
      return displayName;
    }

    @Mutation
    setEditMode(editMode: boolean) {
      this.editMode = editMode;
    }

    @Mutation
    setSelectedRows(selectedRows: Array<any>) {
      this.selectedRows = selectedRows;
    }

    @Mutation
    setSearchText(searchText: string) {
      this.searchText = searchText;
    }

    @Mutation
    setTeachers(teachers: any) {
      this.teachers = teachers;
    }

    @Mutation
    setTeacher(teacher: any) {
      this.teacher = teacher;
    }

    @Mutation
    setCurrentTeacher(currentTeacher: any) {
      this.currentTeacher = currentTeacher;
    }

    @Mutation
    setHaveTeachers(haveTeachers: boolean) {
      this.haveTeachers = haveTeachers;
    }

    @Mutation
    setCurrentSchoolId(currentSchoolId: number | null) {
      this.currentSchoolId = currentSchoolId;
    }

    @Action({ rawError: true })
    async loadTeachers() {
      try {
        if (this.haveTeachers) {
          return Promise.resolve({ data: this.teachers });
        }
        const params = this.context.rootState.settings.userMode === 'A' ? { getAdmin: 'Y', getInvited: 'Y' } : {};
        const resp = await TeacherServices.getTeachers({ ...params, currentSchoolId: this.currentSchoolId });
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            Teachers.populateTeachersData(resp);
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    get getTeacherSchools(): Array<any> {
      return this.teacher.schools || [];
    }

    get getTeacherSchoolIds(): Array<number> {
      return this.getTeacherSchools.map(s => s.schoolId);
    }

    get getCurrentTeacherSchools(): Array<any> {
      return this.currentTeacher.schools || [];
    }

    get getCurrentTeacherSchoolIds(): Array<number> {
      return this.getCurrentTeacherSchools.map(s => s.schoolId);
    }

    get getPrimarySchool() {
      return this.getTeacherSchools.find(s => CommonUtils.isTrue(s.primary));
    }

    get getPrimarySchoolId() {
      return this.getPrimarySchool ? this.getPrimarySchool.schoolId : undefined;
    }

    get currentSelectedTeacher(): any {
      const currentTeacherId = +this.context.rootState.settings.currentTeacherId;
      return this.getTeachersOnly.find(t => t.teacherId === currentTeacherId) || {};
    }

    get currentSelectedSchoolYear() {
      const currentYearId = +this.context.rootState.settings.currentYearId;
      const years: Array<any> = this.currentSelectedTeacher.years || [];
      return years.find(sy => sy.yearId === currentYearId) || { firstDay: '', lastDay: '' };
    }

    get createSaveRequest() {
      const currentSchoolIds: Array<number> = this.getCurrentTeacherSchoolIds;
      const newSchoolIds: Array<number> = this.getTeacherSchoolIds;
      const schoolsToBeAdded: Array<number> = newSchoolIds.filter(n => !currentSchoolIds.includes(n));
      const schoolsToBeDeleted: Array<number> = currentSchoolIds.filter(c => !newSchoolIds.includes(c));
      return <SaveTeacherRequest>{
        teacherType: this.teacher.userType || 'T',
        teacherEmail: this.teacher.emailAddress || '',
        teacherFirst: this.teacher.firstName || '',
        teacherLast: this.teacher.lastName || '',
        teacherDisplayName: this.teacher.displayName || '',
        teacherPhotoUrl: this.teacher.photoUrl || '',
        teacherId: this.teacher.teacherId,
        inviteId: this.teacher.inviteId,
        favoriteSchool: this.getPrimarySchoolId,
        schoolsToBeAdded,
        schoolsToBeDeleted,
        getAdmin: 'Y',
        getInvited: 'Y',
        currentSchoolId: this.currentSchoolId || 0
      }
    }

    @Action({ rawError: true })
    public async save(): Promise<any> {
      try {
        const resp = await TeacherServices.save(this.context.getters.createSaveRequest);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else if (CommonUtils.isTrue(data.existingEmail)) {
            return Promise.resolve(new AppError('accountErrorMsg8', { data }));
          } else {
            this.context.commit('setTeachers', resp.data);
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
      return Promise.resolve();
    }

    @Action({ rawError: true })
    public async invite(params?: any): Promise<any> {
      try {
        const resp = await TeacherServices.inviteTeachers(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            Teachers.populateTeachersData(resp);
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
      return Promise.resolve();
    }

    @Action({ rawError: true })
    public async delete(params?: any): Promise<any> {
      try {
        const resp = await SchoolServices.removeTeachers(params as RemoveTeachersRequest);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            Teachers.populateTeachersData(resp);
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
      return Promise.resolve();
    }

    @Action({ rawError: true })
    async findTeacherByEmails(params?: any) {
      try {
        params = params || {};
        const resp = await TeacherServices.findTeacherByEmails(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async changeTeacher(param?: any) {
      const storedTeacherId = +PlanbookStorage.get('currentTeacherId');
      const storedYearId = PlanbookStorage.get('currentYearId', 'T' + param.teacherId);
      if (storedTeacherId !== +param.teacherId) {
        param.yearId = storedYearId ? +storedYearId : 0;
      }
      const teachers = this.context.rootGetters['teachers/getTeachersOnly'];
      let selected = { teacherId: param.teacherId, yearId: param.yearId };
      if (CommonUtils.isNotEmpty(teachers)) {
        let matchedTeacher;
        let firstTeacher;
        for (const t in teachers) {
          const teacher = teachers[t];
          if (!firstTeacher) {
            firstTeacher = teacher;
          }
          if (selected.teacherId === teacher.teacherId) {
            matchedTeacher = teacher;
            break;
          }
        }

        let firstYear;
        let matchedYear;
        const teacher = matchedTeacher || firstTeacher;
        const years = CommonUtils.getOrDefaultTo(teacher.years, []);
        if (CommonUtils.isNotEmpty(years)) {
          for (const y in years) {
            const year = years[y];
            if (!firstYear) {
              firstYear = year;
            }
            if (selected.yearId === year.yearId) {
              matchedYear = year;
              break;
            }
          }
        }
        const year = matchedYear || firstYear || { yearId: 0 };
        selected = {
          teacherId: teacher.teacherId,
          yearId: year.yearId
        }
        this.context.commit('settings/setCurrentTeacherId', +selected.teacherId, { root: true });
        this.context.commit('settings/setCurrentYearId', +selected.yearId, { root: true });
        PlanbookStorage.set('currentTeacherId', +param.teacherId);
        PlanbookStorage.set('currentYearId', 'T' + param.teacherId, +param.yearId);
      } else {
        this.context.commit('settings/setCurrentTeacherId', +param.teacherId, { root: true });
        this.context.commit('settings/setCurrentYearId', param.yearId || +param.yearId, { root: true });
        PlanbookStorage.set('currentTeacherId', +param.teacherId);
        PlanbookStorage.set('currentYearId', 'T' + param.teacherId, +param.yearId);
      }
      this.context.commit('classes/setHaveClass', false, { root: true });
      this.context.commit('plans/setHaveList', false, { root: true });
      this.context.commit('plans/setHaveMonth', false, { root: true });
      this.context.commit('plans/setHaveWeeks', false, { root: true });
      this.context.commit('plans/setHaveCycle', false, { root: true });
      this.context.commit('plans/setHaveCycleSchedule', false, { root: true });
      this.context.commit('plans/setCurrentListData', null, { root: true });
      this.context.commit('plans/setCurrentMonthData', null, { root: true });
      this.context.commit('plans/setCurrentData', null, { root: true });
      this.context.commit('plans/setCurrentCycleData', null, { root: true });
      this.context.commit('plans/setCycleSchedule', null, { root: true });
      this.context.commit('reporting/reset', {}, { root: true });
      this.context.commit('reporting/init', {}, { root: true });
      return Promise.resolve();
    }

    private static populateTeachersData(resp: AxiosResponse<any>) {
      store.commit('teachers/setTeachers', resp.data);
      const teachers = store.getters['teachers/getTeachersOnly'];
      let selected = { teacherId: store.state.settings.currentTeacherId, yearId: store.state.settings.currentYearId };
      if (CommonUtils.isNotEmpty(teachers)) {
        let matchedTeacher;
        let firstTeacher;
        for (const t in teachers) {
          const teacher = teachers[t];
          if (!firstTeacher) {
            firstTeacher = teacher;
          }
          if (selected.teacherId === teacher.teacherId) {
            console.log('Found Match');
            console.log(teacher);
            console.log(teachers.length);
            matchedTeacher = teacher;
            break;
          }
        }

        let firstYear;
        let matchedYear;
        const teacher = matchedTeacher || firstTeacher;
        const years = CommonUtils.getOrDefaultTo(teacher.years, []);
        if (CommonUtils.isNotEmpty(years)) {
          for (const y in years) {
            const year = years[y];
            if (!firstYear) {
              firstYear = year;
            }
            if (selected.yearId === year.yearId) {
              matchedYear = year;
              break;
            }
          }
        }
        const year = matchedYear || firstYear || { yearId: 0 };
        selected = {
          teacherId: teacher.teacherId,
          yearId: year.yearId
        }
        store.commit('teachers/setHaveTeachers', true, { root: true });
        store.commit('settings/setCurrentTeacherId', selected.teacherId, { root: true });
        store.commit('settings/setCurrentYearId', selected.yearId, { root: true });
      }
    }
}
