var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-menu",
    {
      attrs: {
        "offset-y": _vm.mode === "chip",
        bottom: _vm.mode === "chip",
        "close-on-content-click": false,
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on: onMenu }) {
            return [
              _c(
                "v-text-field",
                _vm._g(
                  {
                    attrs: {
                      "hide-details": _vm.hideDetails,
                      outlined: "",
                      readonly: "",
                      disabled: _vm.disabled,
                      label: _vm.label,
                      "aria-label": _vm.ariaLabel || _vm.label,
                      value: _vm.localTextValue,
                      rules: _vm.rules,
                      dense: _vm.dense,
                    },
                    on: {
                      keyup: [
                        function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          _vm.showList = !_vm.showList
                        },
                        function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "down", 40, $event.key, [
                              "Down",
                              "ArrowDown",
                            ])
                          )
                            return null
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.focusFirst.apply(null, arguments)
                        },
                      ],
                    },
                  },
                  { ...onMenu }
                )
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.showList,
        callback: function ($$v) {
          _vm.showList = $$v
        },
        expression: "showList",
      },
    },
    [
      _vm.mode === "list"
        ? _c(
            "v-list",
            { attrs: { dense: "" } },
            [
              _vm._l(_vm.localItems, function (item, i) {
                return _c(
                  "v-list-item",
                  {
                    key: i,
                    attrs: { dense: "", link: "" },
                    on: {
                      click: function ($event) {
                        return _vm.onItemClick(item)
                      },
                    },
                  },
                  [
                    _c(
                      "v-list-item-content",
                      [_c("v-list-item-title", [_vm._v(_vm._s(item.text))])],
                      1
                    ),
                    _c(
                      "v-list-item-action",
                      [
                        _c("v-checkbox", {
                          attrs: { dense: "", "input-value": item.selected },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              _c("v-divider"),
              _c(
                "v-list-item",
                {
                  attrs: { dense: "", link: "" },
                  on: {
                    click: function ($event) {
                      _vm.showList = false
                    },
                  },
                },
                [
                  _c("v-list-item-title", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.$t("doneLabel"))),
                  ]),
                ],
                1
              ),
            ],
            2
          )
        : _c(
            "v-card",
            { staticStyle: { "max-width": "500px" }, attrs: { outlined: "" } },
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-chip-group",
                    {
                      attrs: {
                        "active-class": "accent2--text font-weight-bold",
                        column: "",
                        multiple: "",
                        value: _vm.localValue,
                      },
                    },
                    _vm._l(_vm.localItems, function (item, i) {
                      return _c(
                        "span",
                        { key: `multiselect-field-item-${i}` },
                        [
                          _vm.hasText(item.tooltip)
                            ? [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { "open-delay": "100", bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on }) {
                                            return [
                                              _c(
                                                "pb-chip",
                                                _vm._g(
                                                  {
                                                    ref: "chip" + i,
                                                    refInFor: true,
                                                    attrs: {
                                                      filter: "",
                                                      value: item.value,
                                                      color: item.color,
                                                      "text-color":
                                                        _vm.getItemTextColor(
                                                          item.color
                                                        ),
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.onItemClick(
                                                          item
                                                        )
                                                      },
                                                      keyup: function ($event) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        )
                                                          return null
                                                        return _vm.onItemClick(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  on
                                                ),
                                                [_vm._v(_vm._s(item.text))]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [_c("span", [_vm._v(_vm._s(item.tooltip))])]
                                ),
                              ]
                            : [
                                _c(
                                  "pb-chip",
                                  {
                                    ref: "chip" + i,
                                    refInFor: true,
                                    attrs: {
                                      filter: "",
                                      value: item.value,
                                      color: item.color,
                                      "text-color": _vm.getItemTextColor(
                                        item.color
                                      ),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onItemClick(item)
                                      },
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.onItemClick(item)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(item.text))]
                                ),
                              ],
                        ],
                        2
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }