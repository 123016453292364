import { render, staticRenderFns } from "./DateTimeRangeField.vue?vue&type=template&id=5ddfd330&scoped=true&"
import script from "./DateTimeRangeField.vue?vue&type=script&lang=ts&"
export * from "./DateTimeRangeField.vue?vue&type=script&lang=ts&"
import style0 from "./DateTimeRangeField.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./DateTimeRangeField.vue?vue&type=style&index=1&id=5ddfd330&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ddfd330",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCard,VDatePicker,VFadeTransition,VIcon,VMenu,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/Users/adriandaluz/Development/git/planbook-web-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5ddfd330')) {
      api.createRecord('5ddfd330', component.options)
    } else {
      api.reload('5ddfd330', component.options)
    }
    module.hot.accept("./DateTimeRangeField.vue?vue&type=template&id=5ddfd330&scoped=true&", function () {
      api.rerender('5ddfd330', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/core/DateTimeRangeField.vue"
export default component.exports