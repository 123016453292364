import qs from 'qs';
import axios from '@/axios';

export default class TemplateServices {
  public static getTemplates(params: any) {
    return axios.get('services/planbook/template/get', { params: params });
  }

  public static updateTemplate(params: any) {
    return axios.post('services/planbook/template/save', qs.stringify(params, { arrayFormat: 'repeat' }));
  }

  public static deleteTemplate(params:any) {
    return axios.post('services/planbook/template/delete', qs.stringify(params));
  }

  public static deleteTemplates(params:any) {
    return axios.post('services/planbook/template/deleteBatch', qs.stringify(params, { arrayFormat: 'repeat' }));
  }

  public static applyTemplate(params: any) {
    return axios.post('services/planbook/template/apply', qs.stringify(params));
  }

  public static removeRestoreTemplate(params: any) {
    return axios.post('services/planbook/template/removeOrRestore', qs.stringify(params));
  }
}
