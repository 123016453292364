var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "d-flex justify-center align-center pb-4",
      staticStyle: { position: "relative" },
    },
    [
      _c(
        "v-slide-y-reverse-transition",
        [
          _c(
            "pb-btn",
            {
              attrs: {
                "x-small": "",
                icon: "",
                absolute: "",
                color:
                  _vm.isSectionsHovered || _vm.hasFocus
                    ? "primary"
                    : "transparent",
                hiddenLabel: _vm.$t("showFields"),
              },
              on: {
                focus: function ($event) {
                  _vm.hasFocus = true
                },
                blur: function ($event) {
                  _vm.hasFocus = false
                },
                click: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.hideOrShowTextFields.apply(null, arguments)
                },
              },
            },
            [
              _vm.isTextFieldsVisible
                ? _c("v-icon", [_vm._v("fal fa-chevron-up")])
                : _c("v-icon", [_vm._v("fal fa-chevron-down")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }