
import { Component, Vue, Prop } from 'vue-property-decorator';
import MyListEditorForm from '@/components/mylists/MyListEditorForm.vue';
import Confirm from '../core/Confirm.vue';
import { namespace } from 'vuex-class';
import CommonUtils from '@/utils/common-utils';
import { Editor } from '@/types/global.types';

const myLists = namespace('mylists');
const settings = namespace('settings');

@Component({
  components: {
    MyListEditorForm
  }
})
export default class MyListEditor extends Vue implements Editor {
  @Prop({ type: Boolean })
  value!: boolean;

  @Prop({ type: Boolean, default: true })
  isModal!: boolean;

  @Prop({
    type: Object,
    default: () => {
      return { loadData: true, data: {} };
    }
  })
  input!: any

  $refs!: {
    form: MyListEditorForm,
    standardEditorConfirm: Confirm
  }

  @myLists.State
  listLoading!: boolean;

  @myLists.Action
  deleteStandard!: (params: any) => Promise<any>;

  @settings.Getter('getShowSnackbarNotifications')
  showSnackbarNotifications!: boolean;

  get localValue() {
    return this.value;
  }

  set localValue(val: boolean) {
    this.$emit('input', val);
  }

  get isEditing() {
    return (this.input.action === 'edit')
  }

  get localPage() {
    return CommonUtils.getPage(this.$route);
  }

  isDirty() {
    return this.$refs.form && this.$refs.form.isDirty();
  }

  typeChange() {
    this.input.loadData = false;
  }

  doApply() {
    return this.$refs.form.addNewStandard();
  }

  cancelEditor() {
    this.$eventBus.$emit('closeStandardEditor', false);
  }

  deleteSelectedStandard() {
    const id: Array<any> = [this.input.standardObject.dbId];
    const that = this;
    this.$refs.standardEditorConfirm.confirm({
      title: this.$t('listLabel'),
      text: this.$t('deleteListLabel'),
      option1ButtonAlternativeText: this.$t('continueLabel')
    }).then((result) => {
      if (result === 1) {
        this.localPage === 'my-list' ? this.$store.commit('mylists/setListLoading', true) : CommonUtils.showLoading();
        that.deleteStandard({ dbId: id }).then(() => {
          if (this.showSnackbarNotifications) {
            if (this.$currentUser.isTeacher) {
              this.$snotify.success(this.$t('statusMsg19') as string);
            } else {
              this.$snotify.success(this.$t('statusMsg19b') as string);
            }
          }
          this.$eventBus.$emit('closeSubPage');
          this.$eventBus.$emit('closeStandardEditor', true);
          return Promise.resolve();
        });
        return Promise.resolve(false);
      }
    });
  }

  created() {
    this.$eventBus.$on('deleteList', (listIds: any) => {
      if (!this.input.standardObject) {
        return;
      }
      for (const i in listIds) {
        if (listIds[i] === this.input.standardObject.dbId) {
          this.$eventBus.$emit('closeSubPage');
        }
      }
    })
  }

  destroyed() {
    this.$eventBus.$off('deleteList');
  }
}
