import axios from '@/axios';
import qs from 'qs';

export default class PermissionsServices {
  public static getPermissions(params?: any) {
    params = params || {};
    return axios.get('services/planbook/permissions', { params: params });
  }

  public static saveAdminPermissions(params?: any) {
    params = params || {};
    return axios.post('services/planbook/teacher/admin', qs.stringify(params));
  }
}
