



















import CommonUtils from '@/utils/common-utils';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Editor from '@tinymce/tinymce-vue';
import ld from 'lodash';
import i18n from '@/plugins/i18n';
import FileServices from '@/services/file-services';
import { namespace } from 'vuex-class';

const settings = namespace('settings');
const index = namespace('index');

@Component({
  components: {
    editor: Editor
  }
})
export default class TextEditor extends Vue {
  @Prop({ required: false, default: () => { return {} } })
  config?: any;

  @Prop({ required: false, type: String, default: CommonUtils.generateUUID() })
  refreshKey!: string;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  noAccess!: boolean;

  @Prop({ required: false, type: Object, default: () => { return {} } })
  fontStyle!: any;

  @Prop({
    required: false,
    type: String,
    default:
      `<div class="text-center" style="color: #b5bac1;">
        <i style="font-size: 60px;" class="fas fa-user-lock"></i>
        <div>${i18n.t('noPermissionMsg')}</div>
      </div>`
  })
  noAccessText!: string;

  @Prop({ required: false, type: String, default: '' })
  value: any;

  @Prop({ required: false, type: Boolean, default: false })
  stackMode!: boolean;

  $refs!: {
    textEditor: HTMLElement
  }

  @settings.Getter('getCustomFonts')
  customFonts!: Array<any>;

  @settings.Getter('getUserMode')
  userMode!: string;

  @settings.Getter('getGlobalFontFamily')
  globalFontFamily!: string;

  @settings.Getter('getGlobalFontSize')
  globalFontSize!: string;

  @index.State
  fullScreen!: boolean;

  updatedConfig: any = {};
  localRefreshKey: string = CommonUtils.generateUUID();

  get localConfig() {
    return ld.merge(this.baseConfig, this.updatedConfig);
  }

  get localFontFamily() {
    return this.fontStyle.fontFamily || this.globalFontFamily;
  }

  get localFontSize() {
    return this.fontStyle.fontSize || this.globalFontSize + 'pt';
  }

  get bodyColorStyle() {
    if (this.$vuetify.theme.dark) {
      return '';
    } else {
      return `
        body{
            background:#fff
        }
        body.mceForceColors{
            background:#fff;
            color:#000
        }
      `;
    }
  }

  get editorContentStyle() {
    return `
    body,pre,td{
      font-family:${this.localFontFamily},Raleway,Roboto,Arial,Helvetica,sans-serif;
      font-size:${this.localFontSize};
      margin:2px
    }
    ${this.bodyColorStyle}
    body#tinymce.mce-content-body>table>tbody>tr{
        vertical-align:top
    }
    h1{
        font-size:2em
    }
    h2{
        font-size:1.5em
    }
    h3{
        font-size:1.17em
    }
    h4{
        font-size:1em
    }
    h5{
        font-size:.83em
    }
    h6{
        font-size:.75em
    }
    .mceItemTable,.mceItemTable caption,.mceItemTable td,.mceItemTable th,.mceItemVisualAid{
        border:1px dashed #bbb
    }
    a.mceItemAnchor{
        display:inline-block;
        width:11px!important;
        height:11px!important
    }
    td.mceSelected,th.mceSelected{
        background-color:#39f!important
    }
    img{
        border:0
    }
    table{
        cursor:default
    }
    table td,table th{
        cursor:text
    }
    ins{
        border-bottom:1px solid green;
        text-decoration:none;
        color:green
    }
    del{
        color:red;
        text-decoration:line-through
    }
    cite{
        border-bottom:1px dashed #00f
    }
    acronym{
        border-bottom:1px dotted #ccc;
        cursor:help
    }
    abbr{
        border-bottom:1px dashed #ccc;
        cursor:help
    }
    p{
        margin:0;
        padding:0
    }
    * html body{
        scrollbar-3dlight-color:#f0f0ee;
        scrollbar-arrow-color:#676662;
        scrollbar-base-color:#f0f0ee;
        scrollbar-darkshadow-color:#ddd;
        scrollbar-face-color:#e0e0dd;
        scrollbar-highlight-color:#f0f0ee;
        scrollbar-shadow-color:#f0f0ee;
        scrollbar-track-color:#f5f5f5
    }
    img:-moz-broken{
        -moz-force-broken-image-icon:1;
        width:24px;
        height:24px
    }
    font[face=mceinline]{
        font-family:inherit!important
    }
    `;
  }

  get baseConfig() {
    const that: TextEditor = this;
    return {
      placeholder: '',
      script_url: 'https://cdn.planbook.com/assets/tinymce6/js/tinymce/tinymce.min.js',
      content_style: this.editorContentStyle,
      content_css: this.$vuetify.theme.dark ? 'dark' : undefined,
      font_css: this.userMode === 'A' ? undefined : this.customFontsCSSUrl,
      automatic_uploads: true,
      link_assume_external_targets: true,
      images_reuse_filename: true,
      toolbar_mode: 'sliding',
      skin: this.$vuetify.theme.dark ? 'oxide-dark' : 'planbook',
      images_upload_handler: this.updloadEmbeddedFile,
      plugins: 'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons',
      paste_webkit_styles: 'all',
      paste_remove_styles_if_webkit: false,
      paste_data_images: true,
      paste_postprocess: (plugin: any, args: any) => {
        that.tinyMCEPastePostProcess(args.node.childNodes);
      },
      table_default_attributes: {
        border: '1',
        cellspacing: '0'
      },
      table_default_styles: {
        'border-collapse': 'collapse',
        width: '100%'
      },
      height: '300px',
      menubar: false,
      statusbar: false,
      browser_spellcheck: true,
      resize: true,
      font_size_formats: '8pt 9pt 10pt 11pt 12pt 13pt 14pt 16pt 18pt 20pt',
      font_family_formats: this.fontFamilyFormats,
      link_default_target: '_blank',
      image_list: this.imageList,
      toolbar: 'fontfamily fontsize | bold italic underline strikethrough | outdent indent | numlist bullist | forecolor backcolor | cut copy paste pastetext | undo redo | link image media table | subscript superscript | charmap emoticons | removeformat code | lineheight',
      contextmenu: false,
      quickbars_insert_toolbar: false,
      quickbars_selection_toolbar: 'bold link cut copy paste',
      text_patterns: false,
      color_map: [
        '#CCFFCC', 'Pale Green',
        '#FBEEB8', 'Pale Yellow',
        '#F8CAC6', 'Pale Red',
        '#ECCAFA', 'Pale Purple',
        '#C2E0F4', 'Pale Blue',
        '#ECF0F1', 'Light Gray',

        '#BFEDD2', 'Light Green',
        '#FFFF99', 'Light Yellow',
        '#FF7F7F', 'Light Red',
        '#CC99FF', 'Light Purple',
        '#99CCFF', 'Light Blue',
        '#CED4D9', 'Medium Gray',

        '#2DC26B', 'Green',
        '#FFFF00', 'Yellow',
        '#E03E2D', 'Red',
        '#B96AD9', 'Purple',
        '#3598DB', 'Blue',
        '#95A5A6', 'Gray',

        '#169179', 'Dark Turquoise',
        '#F1C40F', 'Gold',
        '#BA372A', 'Dark Red',
        '#843FA1', 'Cadmium Violet',
        '#236FA1', 'Dark Blue',
        '#7E8C8D', 'Dark Gray',

        '#003300', 'Dark Green',
        '#E67E23', 'Orange',
        '#800000', 'Maroon',
        '#800080', 'Dark Purple',
        '#333399', 'Indigo',
        '#34495E', 'Navy Blue',

        '#000000', 'Black',
        '#ffffff', 'White'
      ],
      color_cols: 6,
      setup: (editor: any) => {
        editor.on('keyup', (e: any) => {
          that.$emit('keyup', e);
        });
        editor.on('paste', (e: any) => {
          that.$emit('paste', e);
        });
        editor.on('drop', (e: any) => {
          that.$emit('drop', e);
        });
      }
    }
  }

  get customFontsCSSUrl() {
    return this.$store.getters['settings/getCustomFontsCSSUrl'];
  }

  get imageList() {
    const items = [];
    for (let i = 1; i <= 74; i++) {
      items.push({ title: `sticker-${(i + '').padStart(2, '0')}`, value: `https://cdn.planbook.com/images/planbook-stickers/sticker-${(i + '').padStart(2, '0')}.png` });
    }
    for (let i = 1; i <= 10; i++) {
      items.push({ title: `teacher-${(i + '').padStart(2, '0')}`, value: `https://cdn.planbook.com/images/teacheravatars/teacher-${(i + '').padStart(2, '0')}.png` });
    }
    for (let i = 1; i <= 25; i++) {
      items.push({ title: `student-${(i + '').padStart(2, '0')}`, value: `https://cdn.planbook.com/images/studentavatars/student-${(i + '').padStart(2, '0')}.png` });
    }
    return items;
  }

  get currentTeacherId(): number {
    if (this.userMode === 'T') {
      return +this.$store.state.settings.userId;
    } else {
      return +this.$store.state.settings.currentTeacherId;
    }
  }

  get fontFamilyFormats() {
    const fontFamilyFormats = `Andale Mono=andale mono,times;
     Arial=arial,helvetica,sans-serif;
     Arial Black=arial black,avant garde;
     Book Antiqua=book antiqua,palatino;
     Comic Sans MS=comic sans ms,sans-serif;
     Courier New=courier new,courier;
     Georgia=georgia,palatino;
     Helvetica=helvetica;
     Impact=impact,chicago;
     Symbol=symbol;
     Tahoma=tahoma,arial,helvetica,sans-serif;
     Terminal=terminal,monaco;
     Times New Roman=times new roman,times;
     Trebuchet MS=trebuchet ms,geneva;
     Verdana=verdana,geneva;
     Webdings=webdings;
     Wingdings=wingdings,zapf dingbats;`
    return fontFamilyFormats + this.customFonts.map(customFont => {
      return customFont.fontFamily + '=' + this.getFontFamilyStyle(customFont)
    }).join(';')
  }

  getFontFamilyStyle(customFont: any) {
    const fontFamily = customFont.fontFamily.toLowerCase();
    return fontFamily.replace(/[\W]+/g, '') + customFont.teacherId + ',' + fontFamily.replace(/[\W]+/g, '') + ',' + fontFamily + ',arial,helvetica,sans-serif';
  }

  get localValue() {
    return this.value || '<p></p>';
  }

  set localValue(val: string) {
    this.$emit('input', val);
  }

  async updloadEmbeddedFile(blobInfo: any, progress: any) {
    const fileName = this.getFileNameFromBlob(blobInfo);
    return FileServices.uploadFile(blobInfo.blob(), fileName, {
      onUploadProgress: (e: any) => {
        progress(e.loaded / e.total * 100);
      }
    }).then(resp => {
      const data = resp.data;
      if (data && CommonUtils.hasText(data.url)) {
        return Promise.resolve(data.url);
      } else {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Technical Error Occurred.');
      }
    }).catch(err => {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('Technical Error Occurred. ' + err);
    })
  }

  getFileNameFromBlob(blobInfo: any) {
    if (blobInfo.blob().name === 'image.png') {
      return 'image' + blobInfo.blob().lastModified + '.png';
    } else if (blobInfo.blob().name === 'image.jpg') {
      return 'image' + blobInfo.blob().lastModified + '.jpg';
    } else if (blobInfo.blob().type === 'image/jpeg' && blobInfo.filename().indexOf('blobid') === 0) {
      return 'image' + CommonUtils.generateUUID() + '.jpg';
    } else if (blobInfo.blob().type === 'image/png' && blobInfo.filename().indexOf('blobid') === 0) {
      return 'image' + CommonUtils.generateUUID() + '.png';
    } else {
      return blobInfo.blob().name ? blobInfo.blob().name : blobInfo.filename();
    }
  }

  tinyMCEPastePostProcess(childNodes: any) {
    if (childNodes && childNodes.length > 0) {
      childNodes.forEach((item: any) => {
        if (['LI', 'li', 'P', 'p', 'SPAN', 'span'].includes(item.tagName)) {
          if (item.style.getPropertyValue('color') === 'rgb(0, 0, 0)') {
            item.style.removeProperty('color')
          }
        }
        if (item.tagName === 'LI' || item.tagName === 'li') {
          item.style.removeProperty('text-indent');
          item.style.removeProperty('white-space');
          item.style.removeProperty('display');
        }
        this.tinyMCEPastePostProcess(item.childNodes);
      });
    }
  }

  @Watch('fullScreen')
  adjustHeight() {
    this.$nextTick(() => {
      new Promise(resolve => setTimeout(resolve, 1)).then(() => {
        try {
          const updatedConfig = ld.cloneDeep(this.updatedConfig);
          if (this.fullScreen && !this.stackMode) {
            if (this.config && this.config.minHeight && window.innerHeight - this.$refs.textEditor.getBoundingClientRect().top - 28 < this.updatedConfig.minHeight) {
              updatedConfig.height = this.updatedConfig.minHeight + 'px';
            } else {
              updatedConfig.height = 'calc(100vh - ' + (this.$refs.textEditor.getBoundingClientRect().top + 28) + 'px)';
            }
          } else {
            CommonUtils.hasValue(this.config?.height) ? updatedConfig.height = this.config?.height : updatedConfig.height = this.baseConfig.height;
          }
          this.updatedConfig = updatedConfig;
          this.localRefreshKey = CommonUtils.generateUUID();
        } catch (e) {
        }
      });
    })
  }

  mounted() {
    this.adjustHeight();
    try {
      if (this.updatedConfig.height === 'fill') {
        if (this.updatedConfig.minHeight && window.innerHeight - this.$refs.textEditor.getBoundingClientRect().top < this.updatedConfig.minHeight) {
          this.updatedConfig.height = this.updatedConfig.minHeight + 'px';
        } else {
          this.updatedConfig.height = 'calc(100vh - ' + (this.$refs.textEditor.getBoundingClientRect().top + 20) + 'px)';
        }
      }
    } catch (e) {
    }
  }

  created() {
    this.updatedConfig = this.config;
    this.localRefreshKey = this.refreshKey;
  }
}
