import AppError, { createErrOptions, SessionExpiredError } from '@/errors';
import PermissionsServices from '@/services/permissions-services';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true })
export default class Permissions extends VuexModule {
  lessonPermissions:any[] = [];
  localPermission: any = {};
  isFetchingData = false;

  get getPermissionsData() {
    return this.lessonPermissions;
  }

  get getIsFetchingData() {
    return this.isFetchingData;
  }

  get getLocalPermission() {
    return this.localPermission;
  }

  @Mutation
  setLocalPermission(permission: any) {
    this.localPermission = permission;
  }

  @Mutation
  setLessonPermissions(v: any) {
    this.lessonPermissions = v || [];
  }

  @Mutation
  setFetchingData(v: boolean) {
    this.isFetchingData = v;
  }

  @Action({ rawError: true })
  async saveAdminPermissions(params?: any) {
    try {
      params.allowCreateLessonBanks = params.sharingOptions.allowCreateLessonBanks
      params.allowViewLessonBanks = params.sharingOptions.allowViewLessonBanks
      delete params.sharingOptions;
      console.log('in save', params);
      const resp = await PermissionsServices.saveAdminPermissions(params);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          if (params.allowCreateLessonBanks !== undefined) {
            this.context.commit('settings/setAllowCreateLessonBanks', params.allowCreateLessonBanks, { root: true });
          }
          if (params.allowViewLessonBanks !== undefined) {
            this.context.commit('settings/setAllowViewLessonBanks', params.allowViewLessonBanks, { root: true });
          }
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async getPermissions(params?: any) {
    try {
      params = params || {};
      this.context.commit('setFetchingData', true);
      this.context.commit('setLessonPermissions');
      const resp = await PermissionsServices.getPermissions(params);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          this.context.commit('setLessonPermissions', data.lessonPermissions);
          this.context.commit('setFetchingData', false);
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }
}
