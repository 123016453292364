
import CommonUtils from '@/utils/common-utils';
import Fuse from 'fuse.js';
import { Debounce } from 'vue-debounce-decorator';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import EditorSectionList from '../common/EditorSectionList.vue';
import ld from 'lodash';

const files = namespace('files');

@Component({
  components: {
    EditorSectionList
  }
})
export default class StandardsPicker extends Vue {
    @Prop({ type: Boolean })
    value!: boolean;

    @Prop({ type: Array, required: true, default: () => [] })
    items!: Array<any>;

    @Prop({ type: Array, required: true, default: () => [] })
    selected!: Array<any>;

    @Prop({ type: Boolean, required: false, default: true })
    showShortValue!: boolean;

    @Prop({ type: Array, required: false, default: () => ['value', 'shortValue'] })
    filterKeys!: Array<any>;

    @Prop({ type: String, required: false, default: '' })
    label!: string;

    @Prop({ type: [String, Object], required: false, default: '' })
    contentClass!: string;

    @Prop({ type: Boolean, required: false, default: false })
    withRefresh!: boolean;

    @Prop({ type: Boolean, required: false, default: true })
    fuzzySearch!: boolean;

    @Prop({ type: Number, required: false, default: 40 })
    minimumSearchScore!: number;

    @Prop({ required: false, type: Boolean, default: false })
    infiniteScroll!:boolean;

    @files.State('files')
    myFilesData!: any;

    localSelected: Array<any> = [];
    filterText = '';
    isShowSelected = false;
    typing = false;
    searchText = '';
    localRefreshKey = CommonUtils.generateUUID();

    get filteredItems() {
      if (CommonUtils.hasText(this.filterText)) {
        if (this.fuzzySearch) {
          const fuse = new Fuse(this.items, { includeScore: true, keys: this.filterKeys });
          const output = fuse.search(this.filterText);
          return output.filter(i => {
            let score = 100;
            if (i.score && i.score > 0) {
              score = 100 - (i.score * 100);
            } else {
              score -= 100;
            }
            return score > this.minimumSearchScore;
          }).map(i => i.item);
        } else {
          return this.items.filter(item => {
            for (const i in this.filterKeys) {
              const key = this.filterKeys[i];
              const value = (item[key] as string || '').toLowerCase();
              if (value.includes(this.lowerCaseFilterText)) {
                return true;
              }
            }
            return false;
          });
        }
      }
      return this.items;
    }

    get filteredSelectedItems() {
      const that = this;

      return this.filteredItems.filter((item) => {
        return that.isSelected(item);
      });
    }

    get lowerCaseFilterText() {
      return (this.filterText || '').toLowerCase();
    }

    get localValue() {
      return this.value;
    }

    set localValue(val: boolean) {
      this.$emit('input', val);
    }

    get selectedCount() {
      return this.localSelected.length;
    }

    get localSearchText() {
      return this.infiniteScroll ? this.searchText : this.filterText;
    }

    set localSearchText(val:any) {
      if (this.infiniteScroll) {
        this.searchText = val;
      } else {
        this.filterText = val;
      }
    }

    @Watch('searchText')
    @Debounce(500)
    onSearchChange() {
      this.typing = false;
    }

    @Watch('typing')
    onTypingChange() {
      if (!this.typing && this.infiniteScroll) {
        this.filterText = ld.cloneDeep(this.searchText);
      }
    }

    @Watch('localValue')
    onValueChange() {
      if (this.value) {
        this.localSelected = this.selected;
        this.localRefreshKey = CommonUtils.generateUUID();
      } else {
        this.localSelected = [];
      }
    }

    public setLocalSelected(selected: Array<any>) {
      this.localSelected = selected;
    }

    onItemTapped(item: any) {
      if (!this.isSelected(item)) {
        this.localSelected.push(item.key);
      } else {
        this.localSelected = this.localSelected.filter((s: any) => s !== item.key);
      }
    }

    showAll() {
      this.isShowSelected = false;
    }

    showSelected() {
      this.isShowSelected = true;
    }

    isSelected(item: any) {
      return this.localSelected.includes(item.key);
    }

    unselectAll() {
      this.localSelected = [];
    }

    doApply() {
      this.$emit('apply', this.localSelected);
      this.localValue = false;
    }
}
