import { BASE_URL } from '@/axios';
import CurrentUser from '@/common/current-user';
import { UserSession } from '@/common/user-session';
import { englishAlphabet } from '@/constants';
import AppError, { createErrOptions, SessionExpiredError } from '@/errors';
import ClassServices from '@/services/class-services';
import FileServices from '@/services/file-services';
import LessonServices from '@/services/lesson-services';
import CommonUtils from '@/utils/common-utils';
import ld from 'lodash';
import moment from 'moment';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import vuetify from '@/plugins/vuetify';

const debug = process.env.NODE_ENV !== 'production';

@Module({ namespaced: true })
class Classes extends VuexModule {
  haveClass = false;
  currentYearId = 0;
  classes: Array<any> = [];
  studentClasses: Array<any> = [];
  teacherClasses: Array<any> = [];
  classesMap: any = {};
  attendanceClasses = [];
  schoolYearClasses = [];
  lessonBanks: Array<any> = [];
  currentStandards = {};
  classUnits: any = {};
  selectedClass = Classes.classDefault();
  sharedClasses = {};
  haveSpecialDays = false;
  specialDays = [];
  districtLessonBanks: Array<any> = [];
  editMode = false;
  fullEditorEditMode = false;
  scheduleName = '';
  isRegularSchedule = true;
  scheduleAsOfFC = '';
  isEditingFromClassStart = false;

  private static classDefault() {
    return {
      classDesc: '',
      wk2tuesdayStartTime: '',
      wk2wednesdayEndTime: '',
      classLabelUnderline: false,
      tuesdayStartTime: '',
      wk2tuesdayTeach: 'N',
      fridayStartTime: '',
      wk2saturdayStartTime: '',
      wednesdayEndTime: '',
      classStartDate: '',
      wk2mondayTeach: 'N',
      sundayEndTime: '',
      wk2sundayStartTime: '',
      mondayEndTime: '',
      saturdayStartTime: '',
      tuesdayEndTime: '',
      wk2fridayStartTime: '',
      wk2mondayEndTime: '',
      wednesdayTeach: 'N',
      collaborateSubjectId: 0,
      classLabelItalic: false,
      tuesdayTeach: 'N',
      noStudents: 'N',
      created: '',
      wk2fridayEndTime: '',
      wednesdayStartTime: '',
      wk2sundayEndTime: '',
      collaborateTeacherEmail: '',
      collaborateKey: '',
      wk2thursdayStartTime: '',
      mondayStartTime: '',
      wk2wednesdayStartTime: '',
      sundayTeach: 'N',
      fridayTeach: 'N',
      thursdayEndTime: '',
      saturdayEndTime: '',
      wk2saturdayTeach: 'N',
      color: '',
      collaborateYearId: 0,
      thursdayTeach: 'N',
      classLabelBold: false,
      className: '',
      titleSize: '10',
      wk2thursdayTeach: 'N',
      classId: 0,
      titleColor: '#ffffff',
      wk2fridayTeach: 'N',
      wk2sundayTeach: 'N',
      classEndDate: '',
      sundayStartTime: '',
      mondayTeach: 'N',
      collaborateType: 0,
      collaborateTeacherId: 0,
      maxExtraClass: 0,
      useSchoolEnd: 'Y',
      titleFont: '2',
      wk2tuesdayEndTime: '',
      saturdayTeach: 'N',
      wk2wednesdayTeach: 'N',
      useSchoolStart: 'Y',
      wk2thursdayEndTime: '',
      wk2saturdayEndTime: '',
      thursdayStartTime: '',
      wk2mondayStartTime: '',
      fridayEndTime: '',
      dTs: [],
      eTs: [],
      sTs: [],
      classSchedule: [],
      extraLessons: [],
      noClass: [],
      sourceSettings: Classes.sourceSettingsDefault(),
      lessonLayoutId: 0
    };
  }

  private static sourceSettingsDefault() {
    return {
      connectStudents: true,
      connectAssignments: true,
      connectGrades: true
    }
  }

  get classScheduleDefault() {
    const currentYear = this.context.rootGetters['settings/getCurrentYear'];
    return [{
      day16EndTime: '',
      day20Teach: false,
      day8Teach: false,
      day16StartTime: '',
      day10StartTime: '',
      day12Teach: false,
      day12EndTime: '',
      day13EndTime: '',
      day14EndTime: '',
      day18EndTime: '',
      day19StartTime: '',
      day10EndTime: '',
      day11EndTime: '',
      subjectId: 0,
      day15EndTime: '',
      day17EndTime: '',
      day18Teach: false,
      day19EndTime: '',
      day7StartTime: '',
      day7Teach: currentYear.satCycle === 'Y',
      day11Teach: false,
      day17Teach: false,
      day1StartTime: '',
      scheduleId: 0,
      day4StartTime: '',
      day13StartTime: '',
      day19Teach: false,
      day20StartTime: '',
      day6EndTime: '',
      day8EndTime: '',
      day13Teach: false,
      day16Teach: false,
      day14StartTime: '',
      day9Teach: false,
      day1Teach: currentYear.sunCycle === 'Y',
      day3Teach: currentYear.tueCycle === 'Y',
      day15StartTime: '',
      day2StartTime: '',
      day6Teach: currentYear.friCycle === 'Y',
      day10Teach: false,
      additionalClassDays: [],
      day2EndTime: '',
      day4EndTime: '',
      day8StartTime: '',
      day5Teach: currentYear.thuCycle === 'Y',
      day3StartTime: '',
      day15Teach: false,
      day6StartTime: '',
      day9StartTime: '',
      day20EndTime: '',
      day18StartTime: '',
      scheduleStart: '',
      day4Teach: currentYear.wedCycle === 'Y',
      day14Teach: false,
      day5EndTime: '',
      day7EndTime: '',
      day5StartTime: '',
      day11StartTime: '',
      day1EndTime: '',
      day12StartTime: '',
      day17StartTime: '',
      day9EndTime: '',
      day3EndTime: '',
      day2Teach: currentYear.monCycle === 'Y',
      isNewSchedule: true
    },
    {
      day16EndTime: '',
      day20Teach: false,
      day8Teach: currentYear.sunCycle === 'Y',
      day16StartTime: '',
      day10StartTime: '',
      day12Teach: currentYear.thuCycle === 'Y',
      day12EndTime: '',
      day13EndTime: '',
      day14EndTime: '',
      day18EndTime: '',
      day19StartTime: '',
      day10EndTime: '',
      day11EndTime: '',
      subjectId: 0,
      day15EndTime: '',
      day17EndTime: '',
      day18Teach: false,
      day19EndTime: '',
      day7StartTime: '',
      day7Teach: currentYear.satCycle === 'Y',
      day11Teach: currentYear.wedCycle === 'Y',
      day17Teach: false,
      day1StartTime: '',
      scheduleId: 0,
      day4StartTime: '',
      day13StartTime: '',
      day19Teach: false,
      day20StartTime: '',
      day6EndTime: '',
      day8EndTime: '',
      day13Teach: currentYear.friCycle === 'Y',
      day16Teach: false,
      day14StartTime: '',
      day9Teach: currentYear.monCycle === 'Y',
      day1Teach: currentYear.sunCycle === 'Y',
      day3Teach: currentYear.tueCycle === 'Y',
      day15StartTime: '',
      day2StartTime: '',
      day6Teach: currentYear.friCycle === 'Y',
      day10Teach: currentYear.tueCycle === 'Y',
      additionalClassDays: [],
      day2EndTime: '',
      day4EndTime: '',
      day8StartTime: '',
      day5Teach: currentYear.thuCycle === 'Y',
      day3StartTime: '',
      day15Teach: false,
      day6StartTime: '',
      day9StartTime: '',
      day20EndTime: '',
      day18StartTime: '',
      scheduleStart: '',
      day4Teach: currentYear.wedCycle === 'Y',
      day14Teach: currentYear.satCycle === 'Y',
      day5EndTime: '',
      day7EndTime: '',
      day5StartTime: '',
      day11StartTime: '',
      day1EndTime: '',
      day12StartTime: '',
      day17StartTime: '',
      day9EndTime: '',
      day3EndTime: '',
      day2Teach: currentYear.monCycle === 'Y',
      isNewSchedule: true
    },
    {
      day16EndTime: '',
      day20Teach: false,
      day8Teach: false,
      day16StartTime: '',
      day10StartTime: '',
      day12Teach: false,
      day12EndTime: '',
      day13EndTime: '',
      day14EndTime: '',
      day18EndTime: '',
      day19StartTime: '',
      day10EndTime: '',
      day11EndTime: '',
      subjectId: 0,
      day15EndTime: '',
      day17EndTime: '',
      day18Teach: false,
      day19EndTime: '',
      day7StartTime: '',
      day7Teach: false,
      day11Teach: false,
      day17Teach: false,
      day1StartTime: '',
      scheduleId: 0,
      day4StartTime: '',
      day13StartTime: '',
      day19Teach: false,
      day20StartTime: '',
      day6EndTime: '',
      day8EndTime: '',
      day13Teach: false,
      day16Teach: false,
      day14StartTime: '',
      day9Teach: false,
      day1Teach: true,
      day3Teach: false,
      day15StartTime: '',
      day2StartTime: '',
      day6Teach: false,
      day10Teach: false,
      additionalClassDays: [],
      day2EndTime: '',
      day4EndTime: '',
      day8StartTime: '',
      day5Teach: false,
      day3StartTime: '',
      day15Teach: false,
      day6StartTime: '',
      day9StartTime: '',
      day20EndTime: '',
      day18StartTime: '',
      scheduleStart: '',
      day4Teach: false,
      day14Teach: false,
      day5EndTime: '',
      day7EndTime: '',
      day5StartTime: '',
      day11StartTime: '',
      day1EndTime: '',
      day12StartTime: '',
      day17StartTime: '',
      day9EndTime: '',
      day3EndTime: '',
      day2Teach: true,
      isNewSchedule: true
    }];
  }

  // GETTERS: START
  get getCollabStatuses() {
    const data: any = {};
    CommonUtils.forEach(this.classes, c => {
      if (c.collaborateSubjectId > 0) {
        data[c.collaborateSubjectId] = c.collaborateStatuses || [];
      }
    });
    return data;
  }

  get getClassesMap(): any {
    const classesMap: any = {};
    CommonUtils.forEach(this.classes, c => {
      classesMap[+c.cId] = c;
    });
    return classesMap;
  }

  get getClassItems(): any[] {
    const items = [];
    if (CurrentUser.isStudent) {
      if (CurrentUser.id > 0) {
        return this.studentClasses.map(c => {
          return {
            value: c.subjectId,
            text: c.subjectName,
            color: c.subjectColor,
            yearId: c.yearId,
            teacherId: c.teacherId
          }
        });
      } else {
        return this.teacherClasses.map(c => {
          return {
            value: c.subjectId,
            text: c.className,
            color: c.color,
            yearId: c.yearId,
            isReplica: +c.collaborateType > 2,
            isGoogleClass: +c.collaborateType === 4
          }
        });
      }
    } else {
      for (const i in this.classes) {
        const c: any = this.classes[i];
        items.push({
          value: c.cId,
          text: c.cN,
          color: c.cC,
          yearId: c.cYId,
          isReplica: +c.collaborateType > 2,
          isGoogleClass: +c.collaborateType === 4,
          textColor: c.titleColor
        });
      }
    }
    return items;
  }

  get getClassUnits() {
    return (classId: number) => {
      return this.classUnits[+classId] || [];
    };
  }

  get startTime24HrFmt(): any {
    const currentYear = this.context.rootGetters['settings/getCurrentYear']
    if (currentYear.schoolStartTime && currentYear.schoolStartTime !== '') {
      return moment(currentYear.schoolStartTime, ['h:mm A']).format('HH:mm:ss');
    }
    return '07:00:00';
  }

  get endTime24HrFmt(): any {
    const currentYear = this.context.rootGetters['settings/getCurrentYear']
    if (currentYear.schoolEndTime && currentYear.schoolEndTime !== '') {
      return moment(currentYear.schoolEndTime, ['h:mm A']).format('HH:mm:ss');
    }
    return '19:00:00';
  }

  get classLessonLayoutIds() {
    return this.classes.filter(c => c.lessonLayoutId > 0).map(c => c.lessonLayoutId);
  }

  get getClassLessonLayoutId() {
    return (subjectId: number) => {
      const allSubjects = this.classes.concat(this.lessonBanks).concat(this.districtLessonBanks);
      const subject = allSubjects.find(c => +c.cId === subjectId);
      if (subject) {
        return subject.lessonLayoutId;
      }
      return 0;
    }
  }

  get getCyleDays(): any {
    return (checkCyle?: boolean) => {
      const schoolYearSettings = this.context.rootGetters['settings/getSchoolYearSettings'];
      const currentYear = this.context.rootGetters['settings/getCurrentYear'];
      const cycleDayNames = this.context.rootGetters['settings/getCycleDayNames'];
      const dow = [];
      const cycleDays: any = [];
      let cycleDay: any = {};
      if (schoolYearSettings.classCycle === 'oneWeek' || schoolYearSettings.classCycle === 'twoWeeks') {
        if ((currentYear.sunCycle === 'Y' && checkCyle) || !checkCyle) {
          cycleDay = {}; cycleDay.id = 'A-teachDay1'; cycleDay.title = 'Sunday'; cycleDays.push(cycleDay); dow.push(0);
        }
        if ((currentYear.monCycle === 'Y' && checkCyle) || !checkCyle) {
          cycleDay = {}; cycleDay.id = 'B-teachDay2'; cycleDay.title = 'Monday'; cycleDays.push(cycleDay); dow.push(1);
        }
        if ((currentYear.tueCycle === 'Y' && checkCyle) || !checkCyle) {
          cycleDay = {}; cycleDay.id = 'C-teachDay3'; cycleDay.title = 'Tuesday'; cycleDays.push(cycleDay); dow.push(2);
        }
        if ((currentYear.wedCycle === 'Y' && checkCyle) || !checkCyle) {
          cycleDay = {}; cycleDay.id = 'D-teachDay4'; cycleDay.title = 'Wednesday'; cycleDays.push(cycleDay); dow.push(3);
        }
        if ((currentYear.thuCycle === 'Y' && checkCyle) || !checkCyle) {
          cycleDay = {}; cycleDay.id = 'E-teachDay5'; cycleDay.title = 'Thursday'; cycleDays.push(cycleDay); dow.push(4);
        }
        if ((currentYear.friCycle === 'Y' && checkCyle) || !checkCyle) {
          cycleDay = {}; cycleDay.id = 'F-teachDay6'; cycleDay.title = 'Friday'; cycleDays.push(cycleDay); dow.push(5);
        }
        if ((currentYear.satCycle === 'Y' && checkCyle) || !checkCyle) {
          cycleDay = {}; cycleDay.id = 'G-teachDay7'; cycleDay.title = 'Saturday'; cycleDays.push(cycleDay); dow.push(6);
        }
        if (schoolYearSettings.classCycle === 'twoWeeks') {
          if ((currentYear.sunCycle === 'Y' && checkCyle) || !checkCyle) {
            cycleDay = {}; cycleDay.id = 'H-teachDay8'; cycleDay.title = 'Sunday'; cycleDays.push(cycleDay);
          }
          if ((currentYear.monCycle === 'Y' && checkCyle) || !checkCyle) {
            cycleDay = {}; cycleDay.id = 'I-teachDay9'; cycleDay.title = 'Monday'; cycleDays.push(cycleDay);
          }
          if ((currentYear.tueCycle === 'Y' && checkCyle) || !checkCyle) {
            cycleDay = {}; cycleDay.id = 'J-teachDay10'; cycleDay.title = 'Tuesday'; cycleDays.push(cycleDay);
          }
          if ((currentYear.wedCycle === 'Y' && checkCyle) || !checkCyle) {
            cycleDay = {}; cycleDay.id = 'K-teachDay11'; cycleDay.title = 'Wednesday'; cycleDays.push(cycleDay);
          }
          if ((currentYear.thuCycle === 'Y' && checkCyle) || !checkCyle) {
            cycleDay = {}; cycleDay.id = 'L-teachDay12'; cycleDay.title = 'Thursday'; cycleDays.push(cycleDay);
          }
          if ((currentYear.friCycle === 'Y' && checkCyle) || !checkCyle) {
            cycleDay = {}; cycleDay.id = 'M-teachDay13'; cycleDay.title = 'Friday'; cycleDays.push(cycleDay);
          }
          if ((currentYear.satCycle === 'Y' && checkCyle) || !checkCyle) {
            cycleDay = {}; cycleDay.id = 'N-teachDay14'; cycleDay.title = 'Saturday'; cycleDays.push(cycleDay);
          }
        }
      } else {
        if (cycleDayNames && cycleDayNames.length > 0) {
          for (let j = 0; j < cycleDayNames.length; j++) {
            const businessHrs = { startTime: '', endTime: '' };
            businessHrs.startTime = this.startTime24HrFmt;
            businessHrs.endTime = this.endTime24HrFmt;
            cycleDay = {}; cycleDay.id = englishAlphabet[j] + '-teachDay' + (j + 1); cycleDay.title = cycleDayNames[j].day; cycleDays.push(cycleDay); cycleDay.businessHours = businessHrs;
          }
        } else if (schoolYearSettings.classCycle && schoolYearSettings.classCycle === '2') {
          const businessHrs = { startTime: '', endTime: '' };
          businessHrs.startTime = this.startTime24HrFmt;
          businessHrs.endTime = this.endTime24HrFmt;
          cycleDay = {}; cycleDay.id = 'A-teachDay1'; cycleDay.title = 'A Day'; cycleDays.push(cycleDay); cycleDay.businessHours = businessHrs;
          cycleDay = {}; cycleDay.id = 'B-teachDay2'; cycleDay.title = 'B Day'; cycleDays.push(cycleDay); cycleDay.businessHours = businessHrs;
        } else {
          const nDays = Number(schoolYearSettings.classCycle);
          const businessHrs = { startTime: '', endTime: '' };
          businessHrs.startTime = this.startTime24HrFmt;
          businessHrs.endTime = this.endTime24HrFmt;
          for (let j = 0; j < nDays; j++) {
            const dayName = 'Day ' + (j + 1);
            cycleDay = {}; cycleDay.id = englishAlphabet[j] + '-teachDay' + (j + 1); cycleDay.title = dayName; cycleDays.push(cycleDay); cycleDay.businessHours = businessHrs;
          }
        }
      }
      return cycleDays;
    };
  }

  get cycleDaysForEditor() {
    return this.getCyleDays();
  }

  get cycleDays() {
    return this.getCyleDays(true);
  }

  get getSchoolYearClassItems(): any[] {
    const items = [];
    for (const i in this.schoolYearClasses) {
      const c: any = this.schoolYearClasses[i];
      items.push({
        value: c.cId,
        text: c.cN,
        isReplica: +c.collaborateSubjectId > 2,
        isGoogleClass: +c.collaborateSubjectId === 4
      });
    }
    return items.filter(i => !i.isGoogleClass);
  }

  get getAllClassItemValues(): any[] {
    const values = [];
    const classItems: any = this.getClassItems;
    for (const i in this.getClassItems) {
      const item = classItems[i];
      values.push(item.value);
    }
    return values;
  }

  get getLessonBanks(): any [] {
    return this.lessonBanks;
  }

  get getLessonBankItems(): any[] {
    const items = [];
    for (const i in this.lessonBanks) {
      const c: any = this.lessonBanks[i];
      items.push({
        value: c.cId,
        text: c.cN,
        yearId: c.cYId
      });
    }
    return items;
  }

  get getDistrictLessonBankItems(): any[] {
    const items = [];
    for (const i in this.districtLessonBanks) {
      const c: any = this.districtLessonBanks[i];
      items.push({
        value: c.cId,
        text: c.cN,
        yearId: c.cYId
      });
    }
    return items;
  }

  get getAllLessonBankItemValues(): any[] {
    const values = [];
    const lessonBankItems: any = this.getLessonBankItems;
    for (const i in this.getLessonBankItems) {
      const item = lessonBankItems[i];
      values.push(item.value);
    }
    return values;
  }

  get getClassName() {
    const classes: any = this.getClassesMap;
    return (classId: number) => {
      return classes[classId].cN;
    };
  }

  get getClassIds() {
    const ids = [];
    const classItems: any = this.getClassItems;
    for (const i in this.getClassItems) {
      const item = classItems[i];
      ids.push(item.value);
    }
    return ids;
  }

  get getClassColor() {
    const classes: any = this.getClassesMap;
    return (classId: number) => {
      try {
        const c = classes[classId];
        return CommonUtils.getColor(c.color || c.cC);
      } catch (e) {
        return vuetify.framework.theme.currentTheme.primary;
      }
    }
  }

  get getClassStart() {
    const classes: any = this.getClassesMap;
    return (classId: number) => {
      return (classes[classId] || { cSd: '01/01/0001' }).cSd;
    };
  }

  get getClassEnd() {
    const classes: any = this.getClassesMap;
    return (classId: number) => {
      return (classes[classId] || { cEd: '12/31/9999' }).cEd;
    };
  }

  get getSpecialDayItems() {
    const items = [{
      text: 'Regular Schedule',
      value: 0,
      description: ''
    }];
    for (const i in this.specialDays) {
      const specialDay: any = this.specialDays[i];
      items.push({
        text: specialDay.name,
        value: +specialDay.specialDayId,
        description: specialDay.description
      })
    }
    return items;
  }

  get getSpecialDay() {
    return (id: number) => {
      const specialDays = this.specialDays;
      for (const i in specialDays) {
        const specialDay: any = specialDays[i];
        if (+specialDay.specialDayId === id) {
          return specialDay;
        }
      }
      return { eventExists: 0 };
    }
  }
  // GETTERS: END

  // MUTATIONS: START
  @Mutation
  setScheduleAsOfFC(v: string) {
    this.scheduleAsOfFC = v;
  }

  @Mutation
  setIsEditingFromClassStart(v: boolean) {
    this.isEditingFromClassStart = v;
  }

  @Mutation
  setIsRegularSchedule(v: boolean) {
    this.isRegularSchedule = v;
  }

  @Mutation
  setEditMode(v: boolean) {
    this.editMode = v;
  }

  @Mutation
  setFullEditorEditMode(v: boolean) {
    this.fullEditorEditMode = v;
  }

  @Mutation
  setScheduleName(v: string) {
    this.scheduleName = v;
  }

  @Mutation
  reset() {
    this.haveClass = false;
    this.currentYearId = 0;
    this.classes = [];
    this.studentClasses = [];
    this.teacherClasses = [];
    this.classesMap = {};
    this.lessonBanks = [];
    this.currentStandards = [];
    this.classUnits = {};
    this.selectedClass = Classes.classDefault();
    this.sharedClasses = {};
  }

  @Mutation
  resetClass() {
    this.selectedClass = Classes.classDefault();
  }

  @Mutation
  resetSharedClasses() {
    this.sharedClasses = {};
  }

  @Mutation
  setClasses(data: any) {
    const classesMap: any = this.classesMap;
    const currentStandards: any = this.currentStandards;
    this.classes = data.classes || [];
    this.teacherClasses = data.teacherClasses || [];
    this.studentClasses = data.studentClasses || [];
    this.lessonBanks = data.lessonBanks || [];
    this.districtLessonBanks = data.districtLessonBanks || [];
    this.classUnits = {};
    CommonUtils.forEach(data.classes, c => {
      classesMap[+c.cId] = c;
      currentStandards[c.cId] = {
        classId: c.cId,
        grade: c.currentGrade,
        state: c.currentState,
        subject: c.currentSubject
      };
      const classUnits: any[] = [];
      CommonUtils.forEach(c.units, u => {
        u.classId = c.cId;
        classUnits.push(u);
      });
      this.classUnits[c.cId] = classUnits;
    });
  }

  @Mutation
  setSchoolYearClasses(data: any) {
    this.schoolYearClasses = data.classes || [];
    this.schoolYearClasses = this.schoolYearClasses.filter((c: any) => c.collaborateSubjectId <= 3);
    return this.schoolYearClasses;
  }

  @Mutation
  setClass(selectedClass: any) {
    this.selectedClass = selectedClass;
  }

  @Mutation
  setHaveClass(haveClass: boolean) {
    this.haveClass = haveClass;
  }

  @Mutation
  setClassName(className: string) {
    this.selectedClass.className = className;
  }

  @Mutation
  setTitleFont(titleFont: string) {
    this.selectedClass.titleFont = titleFont;
  }

  @Mutation
  setTitleSize(titleSize: string) {
    this.selectedClass.titleSize = titleSize;
  }

  @Mutation
  setClassLabelBold(classLabelBold: boolean) {
    this.selectedClass.classLabelBold = classLabelBold;
  }

  @Mutation
  setClassLabelItalic(classLabelItalic: boolean) {
    this.selectedClass.classLabelItalic = classLabelItalic;
  }

  @Mutation
  setClassLabelUnderline(classLabelUnderline: boolean) {
    this.selectedClass.classLabelUnderline = classLabelUnderline;
  }

  @Mutation
  setTitleColor(titleColor: string) {
    this.selectedClass.titleColor = titleColor;
  }

  @Mutation
  setClassColor(classColor: string) {
    this.selectedClass.color = classColor;
  }

  @Mutation
  setClassStartDate(classStartDate: string) {
    this.selectedClass.classStartDate = classStartDate;
  }

  @Mutation
  setClassEndDate(classEndDate: string) {
    this.selectedClass.classEndDate = classEndDate;
  }

  @Mutation
  setUseSchoolStart(useSchoolStart: string) {
    this.selectedClass.useSchoolStart = useSchoolStart;
  }

  @Mutation
  setUseSchoolEnd(useSchoolEnd: string) {
    this.selectedClass.useSchoolEnd = useSchoolEnd;
  }

  @Mutation
  setNoStudents(noStudents: string) {
    this.selectedClass.noStudents = noStudents;
  }

  @Mutation
  setCollaborateType(collaborateType: number) {
    this.selectedClass.collaborateType = collaborateType;
  }

  @Mutation
  setStandardSubjectSettings(params: any) {
    if (this.currentStandards) {
      const clone: any = ld.cloneDeep(this.currentStandards);
      clone[params.classId] = params;
      this.currentStandards = clone;
    }
  }

  @Mutation
  setSharedClasses(sharedClasses: any) {
    this.sharedClasses = sharedClasses
  }

  @Mutation
  setCollaborateTeacherEmail(collaborateTeacherEmail: string) {
    this.selectedClass.collaborateTeacherEmail = collaborateTeacherEmail;
  }

  @Mutation
  setCollaborateKey(collaborateKey: string) {
    this.selectedClass.collaborateKey = collaborateKey;
  }

  @Mutation
  setCollaborateYearId(collaborateYearId: number) {
    this.selectedClass.collaborateYearId = collaborateYearId;
  }

  @Mutation
  setCollaborateSubjectId(collaborateSubjectId: number) {
    this.selectedClass.collaborateSubjectId = collaborateSubjectId;
  }

  @Mutation
  setClassSchedule(classSchedule: any) {
    this.selectedClass.classSchedule = classSchedule;
  }

  @Mutation
  setExtraLessons(extraLessons: any) {
    this.selectedClass.extraLessons = extraLessons;
  }

  @Mutation
  setNoClass(noClass: any) {
    this.selectedClass.noClass = noClass;
  }

  @Mutation
  setClassDescription(description: string) {
    this.selectedClass.classDesc = description;
  }

  @Mutation
  setHaveSpecialDays(haveSpecialDays: boolean) {
    this.haveSpecialDays = haveSpecialDays;
  }

  @Mutation
  resetSpecialDays() {
    this.haveSpecialDays = false;
    this.specialDays = [];
  }

  @Mutation
  setSpecialDays(specialDays: any) {
    this.specialDays = specialDays;
  }

  @Mutation
  setAttendanceClasses(attendanceClasses: any) {
    this.attendanceClasses = attendanceClasses;
  }
  // MUTATIONS: END

  // ACTIONS: START
  @Action({ rawError: true })
  async loadClasses(params?: any) {
    try {
      const context = this.context;
      let requestParams = {};
      const userMode = context.rootState.settings.userMode;
      const studentId = this.context.rootState.settings.studentId;
      const studentKey = this.context.rootState.settings.studentKey;
      const studentTeacherId = this.context.rootState.settings.teacherId;
      const currentSubjectId = this.context.rootState.settings.currentSubjectId;
      const currentYearId = this.context.rootState.settings.currentYearId;
      if (userMode === 'A') {
        requestParams = {
          userMode,
          yearId: params && params.yearId ? params.yearId : context.rootState.settings.currentYearId,
          teacherId: params && params.currentTeacherId ? params.currentTeacherId : context.rootState.settings.currentTeacherId,
          schoolId: context.rootGetters['settings/getSchoolId']
        };
      } else if (userMode === 'S') {
        if (studentId > 0) {
          requestParams = {
            studentId: studentId,
            asOf: this.context.rootState.plans.currentDay,
            interval: params && params.interval ? params.interval : 28
          }
        } else {
          requestParams = {
            apiKey: '2e6c6353-3891-4e8a-b579-f6ef074dea34',
            teacherId: studentTeacherId,
            yearId: currentYearId,
            subjectId: currentSubjectId > 0 ? currentSubjectId : undefined,
            studentKey
          }
        }
      }
      if (this.haveClass) {
        return Promise.resolve({ data: {} });
      }
      let resp;
      if (userMode === 'S') {
        if (studentId > 0) {
          resp = await ClassServices.getStudentClasses(requestParams)
        } else {
          resp = await ClassServices.getTeacherClasses(requestParams)
        }
      } else {
        resp = await ClassServices.getClasses(requestParams)
      }
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          console.log('GET CLASSES EXPIRED');
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          context.commit('setHaveClass', true);
          context.commit('setClasses', data);
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async loadAttendanceClasses(params: any) {
    try {
      const context = this.context;
      const resp = await ClassServices.getAttendanceClassesByDate(params);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          if (debug) {
            console.log('GET ATTENDANCE CLASSES RESPONSE --> ' + JSON.stringify(data));
          }
          context.commit('setAttendanceClasses', data);
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async loadSchoolYearClasses(yearId: number) {
    try {
      const context = this.context;
      const userMode = context.rootState.settings.userMode;
      const resp = userMode === 'T' && yearId > 0 ? await ClassServices.getClasses({ yearId }) : null;
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          context.commit('setSchoolYearClasses', data);
          return Promise.resolve({ data });
        }
      } else {
        context.commit('setSchoolYearClasses', []);
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async loadTeachersClasses(params: any) {
    try {
      const context = this.context;
      const userMode = context.rootState.settings.userMode;
      let requestParams = {};
      if (userMode === 'A') {
        requestParams = {
          userMode,
          yearId: params.yearId,
          teacherId: params.teacherId,
          schoolId: context.rootGetters['settings/getSchoolId']
        };
      } else {
        return Promise.reject(new AppError('contactSupport'));
      }
      const resp = await ClassServices.getClasses(requestParams);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          console.log('GET CLASSES EXPIRED');
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async getClass(params: any) {
    try {
      const context = this.context;
      let data: any = {};
      if (params) {
        const resp = await ClassServices.getClass(params);
        if (resp) {
          data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            console.log('GET CLASS EXPIRED');
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            if (debug) {
              console.log('GET CLASS RESPONSE --> ' + JSON.stringify(data));
            }
            context.commit('setClass', data);
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } else {
        return Promise.resolve({ data });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async getSharedClasses(params?: any) {
    try {
      const context = this.context;
      let data: any = {};
      if (params) {
        const resp = await ClassServices.getSharedClasses(params);
        if (resp) {
          data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            console.log('GET SHARED CLASSES EXPIRED');
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            if (debug) {
              console.log('GET SHARED CLASSES RESPONSE --> ' + JSON.stringify(data));
            }
            context.commit('setSharedClasses', data);
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } else {
        context.commit('resetSharedClasses');
        return Promise.resolve({ data });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async addClass(params?: any) {
    try {
      let data: any = {};
      if (params) {
        const resp = await ClassServices.addClass(params);
        if (resp) {
          data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            console.log('ADD CLASS EXPIRED');
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            if (debug) {
              console.log('ADD CLASS RESPONSE --> ' + JSON.stringify(data));
            }
            this.context.commit('setClasses', data);
            await this.context.dispatch('plans/reloadPlans', {}, { root: true });
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } else {
        return Promise.resolve({ data });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async updateClass(params?: any) {
    try {
      const context = this.context;
      let data: any = {};
      if (params) {
        const resp = await ClassServices.updateClass(params);
        if (resp) {
          data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            console.log('UPDATE CLASS EXPIRED');
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else if (data.deletedLessons) {
            if (debug) {
              console.log('UPDATE CLASS RESPONSE --> ' + JSON.stringify(data));
            }
            return Promise.resolve({ data });
          } else {
            if (debug) {
              console.log('UPDATE CLASS RESPONSE --> ' + JSON.stringify(data));
            }
            context.commit('setClasses', data);
            await this.context.dispatch('plans/reloadPlans', {}, { root: true });
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } else {
        return Promise.resolve({ data });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async deleteClass(params?: any) {
    try {
      let data: any = {};
      if (params) {
        const resp = await ClassServices.deleteClass(params);
        if (resp) {
          data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            console.log('DELETE CLASS EXPIRED');
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            if (debug) {
              console.log('DELETE CLASS RESPONSE --> ' + JSON.stringify(data));
            }
            this.context.commit('setClasses', data);
            await this.context.dispatch('plans/reloadPlans', {}, { root: true });
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } else {
        return Promise.resolve({ data });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async removeLinkedLessons(params?: any) {
    try {
      const context = this.context;
      params = params || {};
      const resp = await LessonServices.unlink(params);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          await context.dispatch('plans/loadLessonsEvents', {}, { root: true });
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async loadSpecialDays() {
    try {
      const context = this.context;
      const userMode = context.rootState.settings.userMode;
      const userInfo = context.rootGetters['settings/getSettings'];

      if (userMode !== 'T' || this.haveSpecialDays) {
        return Promise.resolve({ data: {} });
      }
      const resp = await ClassServices.getSpecialDays({
        teacherId: userInfo.userId,
        yearId: userInfo.schoolYearSettings.currentYearId,
        schoolId: userInfo.schoolSettings.schoolId
      });
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          context.commit('setHaveSpecialDays', true);
          context.commit('setSpecialDays', data.specialDays || []);
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async updateSpecialDays(params?:any) {
    try {
      const resp = await ClassServices.updateSpecialDay(params);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          this.context.commit('resetSpecialDays');
          this.context.dispatch('classes/loadSpecialDays', {}, { root: true });
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async export(params: any) {
    const formData = new URLSearchParams();
    formData.append('classId', params.classId);
    formData.append('removeHTML', CommonUtils.booleanToString(params.removeHTML));
    formData.append('X-PB-ACCESS-TOKEN', UserSession.getAccessToken());
    formData.append('X-PB-CLIENT-YEAR-ID', UserSession.getCurrentYearIdAsString());
    formData.append('exportType', 'pdf');
    return fetch(BASE_URL + '/downloadClassLessons', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      credentials: 'include',
      body: formData
    }).then(async (response) => {
      const type = response.headers.get('Content-Type') as string;
      const disposition = response.headers.get('Content-Disposition') as string;
      const fileName = FileServices.getFileName(disposition);
      if (type.includes('json')) {
        // process errors
      } else {
        const blob = await response.blob();
        FileServices.downloadFile(blob, fileName, type);
      }
      return Promise.resolve();
    });
  }
  // ACTIONS: END
}

export default Classes;
