var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "pb-base-editor",
    {
      attrs: {
        typeLabel: _vm.$t("messageLabel"),
        isModal: _vm.isModal,
        input: _vm.input,
        applying: _vm.listLoading,
        withActions: false,
        withApply: !_vm.isViewing,
        onApply: _vm.doApply,
        isDirty: _vm.isDirty,
      },
      on: { close: _vm.cancelEditor },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c("div", [
                !_vm.isViewing
                  ? _c("span", [_vm._v(_vm._s(_vm.$t("addLabel")))])
                  : _c("span", [_vm._v(_vm._s(_vm.$t("viewLabel")))]),
                _c("span", [_vm._v(" " + _vm._s(_vm.$t("messageLabel")))]),
              ]),
            ]
          },
          proxy: true,
        },
        {
          key: "action-button",
          fn: function () {
            return [
              _vm.isViewing && _vm.isToUser
                ? _c(
                    "pb-btn",
                    {
                      attrs: { icon: "", label: _vm.$t("quickReplyLabel") },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.replyToMessage.apply(null, arguments)
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("fal fa-reply")])],
                    1
                  )
                : _vm._e(),
              _vm.isViewing && _vm.isFromUser
                ? _c(
                    "pb-btn",
                    {
                      attrs: { icon: "", label: _vm.$t("deleteLabel") },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.deleteSelectedMessage.apply(
                            null,
                            arguments
                          )
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("fal fa-trash-alt")])],
                    1
                  )
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.localValue,
        callback: function ($$v) {
          _vm.localValue = $$v
        },
        expression: "localValue",
      },
    },
    [
      _c("message-editor-form", {
        ref: "form",
        attrs: { input: _vm.localInput, isModal: _vm.isModal },
      }),
      _c("pb-confirm", { ref: "confirm" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }