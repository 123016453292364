import AppError, { createErrOptions, SessionExpiredError } from '@/errors';
import StandardServices from '@/services/standard-services';
import CommonUtils from '@/utils/common-utils';
import ld from 'lodash';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';

@Module({ namespaced: true })
export default class Standards extends VuexModule {
    haveStandards = false;
    haveStrategies = false;
    haveMyList = false;
    haveSchoolList = false;
    standards = null;
    strategies = null;
    currentStrategyGroup = null;
    myList = null;
    schoolList = null;
    framework = '';
    frameworkDesc = '';
    frameworkGradeLevel = '';
    frameworkSubjectDesc = '';
    hoveredStandardsData:any = [];

    // GETTERS: START

    get getHoveredStandardsData() {
      return this.hoveredStandardsData;
    }

    get getFrameworkSubjectDesc() {
      return this.frameworkSubjectDesc;
    }

    get getFrameworkGradeLevel() {
      return this.frameworkGradeLevel;
    }

    get getFrameworkDesc() {
      return this.frameworkDesc;
    }

    get getFramework() {
      return this.framework;
    }

    get getCurrentStrategyGroup() {
      if (!this.currentStrategyGroup) {
        return this.context.rootGetters['settings/getUserId'];
      }
      return this.currentStrategyGroup;
    }

    get getStrategyGroups() {
      const data = [{
        value: this.context.rootGetters['settings/getUserId'],
        text: 'My Strategies'
      }];
      const userInfo = this.context.rootGetters['settings/getSettings'];
      if (userInfo) {
        const standardSettings = userInfo.standardSettings;
        const schoolSettings = userInfo.schoolSettings;
        const accountSettings = userInfo.accountSettings;
        if (+schoolSettings.schoolId > 0) {
          data.push({
            text: 'School - ' + schoolSettings.schoolName,
            value: '' + schoolSettings.schoolId
          });
        }
        if (+schoolSettings.districtId > 0) {
          data.push({
            text: 'District - ' + schoolSettings.districtName,
            value: '' + schoolSettings.districtId
          });
        }
        CommonUtils.forEach(accountSettings.strategyGroup, function(item) {
          data.push({
            text: item.value,
            value: item.key
          });
        });
      }
      return data;
    }
    // GETTERS: END

    // MUTATIONS: START

    @Mutation
    setHoveredStandardsData(standardsData:any) {
      this.hoveredStandardsData.push(standardsData);
    }

    @Mutation
    resetHoveredStandardsData(data:any) {
      this.hoveredStandardsData = data;
    }

    @Mutation
    resetFrameworkData(val: string) {
      this.framework = val;
      this.frameworkDesc = val;
      this.frameworkGradeLevel = val;
      this.frameworkSubjectDesc = val;
    }

    @Mutation
    setFrameworkData(params: any) {
      this.framework = params.framework;
      this.frameworkDesc = params.desc;
      this.frameworkGradeLevel = params.gradeLevel;
      this.frameworkSubjectDesc = params.subjectDesc;
    }

    @Mutation
    setFramework(val: string) {
      this.framework = val;
    }

    @Mutation
    setFrameworkDesc(val: string) {
      this.frameworkDesc = val;
    }

    @Mutation
    setFrameworkGradeLevel(val: string) {
      this.frameworkGradeLevel = val;
    }

    @Mutation
    setFrameworkSubjectDesc(val: string) {
      this.frameworkSubjectDesc = val;
    }

    @Mutation
    setStandards(standards: any) {
      this.standards = standards;
    }

    @Mutation
    setHaveStandards(haveStandards: boolean) {
      this.haveStandards = haveStandards;
    }

    @Mutation
    setShowSubjectIds(subjectIds: Array<number>) {
      if (this.standards) {
        const clone: any = ld.cloneDeep(this.standards);
        clone.showSubjectIds = subjectIds;
        this.standards = clone;
      }
    }

    @Mutation
    setStrategies(strategies: any) {
      this.strategies = strategies;
    }

    @Mutation
    setHaveStrategies(haveStrategies: boolean) {
      this.haveStrategies = haveStrategies;
    }

    @Mutation
    setCurrentStrategyGroup(currentStrategyGroup: any) {
      this.currentStrategyGroup = currentStrategyGroup;
    }

    @Mutation
    setHaveMyList(haveMyList: boolean) {
      this.haveMyList = haveMyList;
    }

    @Mutation
    setMyList(myList: any) {
      this.myList = myList;
    }

    @Mutation
    setHaveSchoolList(haveSchoolList: boolean) {
      this.haveSchoolList = haveSchoolList;
    }

    @Mutation
    setSchoolList(schoolList: any) {
      this.schoolList = schoolList;
    }
    // MUTATIONS: END

    // ACTIONS: START
    @Action({ rawError: true })
    async loadStandards(params?: any) {
      try {
        const userMode = store.state.settings.userMode;
        if (!this.haveStandards && userMode !== 'S') {
          const resp = await StandardServices.getStandards(params);
          if (resp) {
            const data = resp.data;
            if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
              return Promise.reject(new SessionExpiredError());
            } else if (data.error === 'true' || data.error === true) {
              return Promise.reject(new AppError('contactSupport', { data }));
            } else {
              this.context.commit('setHaveStandards', true);
              this.context.commit('setStandards', resp.data);
              return Promise.resolve({ data });
            }
          } else {
            return Promise.resolve({ data: {} });
          }
        } else {
          return Promise.resolve({ data: this.standards });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async loadStrategies(params?: any) {
      try {
        if (!this.haveStrategies) {
          const resp = await StandardServices.getStrategies(params);
          if (resp) {
            const data = resp.data;
            if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
              return Promise.reject(new SessionExpiredError());
            } else if (data.error === 'true' || data.error === true) {
              return Promise.reject(new AppError('contactSupport', { data }));
            } else {
              this.context.commit('setStrategies', resp.data);
              this.context.commit('setCurrentStrategyGroup', params.group)
              this.context.commit('setHaveStrategies', true);
              return Promise.resolve({ data });
            }
          } else {
            return Promise.resolve({ data: {} });
          }
        } else {
          return Promise.resolve({ data: this.strategies });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async loadMyList(params?: any) {
      try {
        if (!this.haveMyList) {
          const resp = await StandardServices.getMyList(params);
          if (resp) {
            const data = resp.data;
            if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
              return Promise.reject(new SessionExpiredError());
            } else if (data.error === 'true' || data.error === true) {
              return Promise.reject(new AppError('contactSupport', { data }));
            } else {
              this.context.commit('setHaveMyList', true);
              this.context.commit('setMyList', resp.data);
              return Promise.resolve({ data });
            }
          } else {
            return Promise.resolve({ data: {} });
          }
        } else {
          return Promise.resolve({ data: this.myList });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async loadSchoolList(params?: any) {
      try {
        if (!this.haveSchoolList) {
          const resp = await StandardServices.getSchoolList(params);
          if (resp) {
            const data = resp.data;
            if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
              return Promise.reject(new SessionExpiredError());
            } else if (data.error === 'true' || data.error === true) {
              return Promise.reject(new AppError('contactSupport', { data }));
            } else {
              this.context.commit('setHaveSchoolList', true);
              this.context.commit('setSchoolList', resp.data);
              return Promise.resolve({ data });
            }
          } else {
            return Promise.resolve({ data: {} });
          }
        } else {
          return Promise.resolve({ data: this.schoolList });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async updateSelectedFrameworks(params?: any) {
      try {
        const resp = await StandardServices.updateShowStates(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            this.context.commit('settings/setShowStateIds', params.stateIds.split(','), { root: true });
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async updateSelectedSubjects(params?: any) {
      try {
        const resp = await StandardServices.updateShowSubjects(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            this.context.commit('setShowSubjectIds', params.subjectIds.split(','));
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async getStandardData(params?: any) {
      try {
        const resp = await StandardServices.getStandardData(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }
    // ACTIONS: END
}
