var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "pb-base-modal",
    {
      attrs: { maxWidth: 730, cardClass: "substitute-notes-card" },
      on: { apply: _vm.applySubstituteNotes },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [_vm._v(_vm._s(_vm.$t("substituteNotesLabel")))]
          },
          proxy: true,
        },
        {
          key: "action-button",
          fn: function () {
            return [
              _c(
                "v-menu",
                {
                  attrs: {
                    "offset-y": "",
                    "close-on-click": true,
                    "close-on-content-click": true,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                { attrs: { icon: "", right: "" } },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v("fal fa-ellipsis-v-alt")])],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "v-list",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-list-item",
                        { on: { click: _vm.deleteSubstituteNotes } },
                        [
                          _c(
                            "v-list-item-icon",
                            [_c("v-icon", [_vm._v("fal fa-trash-alt")])],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(_vm.$t("deleteLabel"))),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        { on: { click: _vm.printSubstituteNotes } },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", [
                                _vm._v("fal fa-arrow-up-from-square"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(_vm.$t("sendToPDFLabel2"))),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        { on: { click: _vm.emailSubstituteNotes } },
                        [
                          _c(
                            "v-list-item-icon",
                            [_c("v-icon", [_vm._v("fal fa-at")])],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(_vm.$t("emailToLabel"))),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.localValue,
        callback: function ($$v) {
          _vm.localValue = $$v
        },
        expression: "localValue",
      },
    },
    [
      _c(
        "div",
        { staticClass: "editor" },
        [
          _c(
            "v-form",
            { ref: "substituteNotesForm" },
            [
              _c(
                "v-container",
                { staticStyle: { "max-width": "initial" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-0", attrs: { cols: "12" } },
                        [
                          _c("pb-text-editor", {
                            attrs: { config: { height: "300px" } },
                            model: {
                              value: _vm.substituteNotes,
                              callback: function ($$v) {
                                _vm.substituteNotes = $$v
                              },
                              expression: "substituteNotes",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }