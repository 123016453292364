import CurrentUser from '@/common/current-user';
import AppError, { createErrOptions, SessionExpiredError } from '@/errors';
import MyStrategiesServices from '@/services/my-strategies';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

const debug = process.env.NODE_ENV !== 'production';

@Module({ namespaced: true })
export default class MyStrategies extends VuexModule {
    allStrategies: Array<any> = [];
    selectedStrategy = MyStrategies.strategyDefault();
    localStrategy: any = {};
    listLoading = false;

    // toolbar attributes
    searchText = '';
    selectedRows: Array<any> = [];
    editMode = false;

    private static strategyDefault() {
      return {
        dbId: 0,
        strategyDesc: '',
        strategyId: '',
        strategyTitle: '',
        strategyGroupType: ''
      }
    }

    get getDefaultStrategy() {
      return MyStrategies.strategyDefault();
    }

    get getAllStrategies() {
      return this.allStrategies;
    }

    get getStrategy() {
      return this.selectedStrategy;
    }

    get getListLoading() {
      return this.listLoading;
    }

    get getLocalStrategy() {
      return this.localStrategy;
    }

    @Mutation
    setEditMode(editMode: boolean) {
      this.editMode = editMode;
    }

    @Mutation
    setSelectedRows(selectedRows: Array<any>) {
      this.selectedRows = selectedRows;
    }

    @Mutation
    setSearchText(searchText: string) {
      this.searchText = searchText;
    }

    @Mutation
    resetStrategy() {
      this.selectedStrategy = MyStrategies.strategyDefault();
    }

    @Mutation
    setStrategy(strategy: any) {
      this.selectedStrategy = strategy;
    }

    @Mutation
    setStrategies(strategies: Array<any>) {
      this.allStrategies = strategies;
    }

    @Mutation
    setListLoading(listLoading: boolean) {
      this.listLoading = listLoading;
    }

    @Mutation
    setLocalStrategy(strategy: any) {
      this.localStrategy = strategy;
    }

    @Action({ rawError: true })
    async loadStrategies() {
      try {
        const resp = await MyStrategiesServices.getStrategies({
          userMode: CurrentUser.userMode
        });
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            console.log('GET STRATEGIES EXPIRED');
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            if (debug) {
              console.log('GET STRATEGIES RESPONSE --> ' + JSON.stringify(data));
            }
            this.context.commit('setStrategies', MyStrategies.prepareStrategiesList(data) || []);
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    private static prepareStrategiesList(responseData: any) {
      const list: Array<any> = [];
      responseData.strategies.forEach((strategy: any) => {
        list.push({
          dbId: strategy.strategyId,
          strategyId: strategy.code,
          strategyDesc: strategy.description,
          strategyTitle: strategy.title,
          strategyGroupType: strategy.strategyGroupType
        })
      });
      return list;
    }

    @Action({ rawError: true })
    async addStrategy(params?: any) {
      try {
        if (params) {
          const resp = await MyStrategiesServices.updateStrategies({
            category: '',
            code: params.strategyId,
            description: params.strategyDesc,
            title: params.strategyTitle,
            strategyGroupType: params.strategyGroupType,
            action: 'A',
            userMode: CurrentUser.userMode
          });
          if (resp) {
            const data = resp.data;
            if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
              console.log('ADD STRATEGY EXPIRED');
              return Promise.reject(new SessionExpiredError());
            } else if (data.error === 'true' || data.error === true) {
              return Promise.reject(new AppError('contactSupport', { data }));
            } else {
              if (debug) {
                console.log('ADD STRATEGY RESPONSE --> ' + JSON.stringify(data));
              }
              return Promise.resolve({ data });
            }
          } else {
            return Promise.resolve({ data: {} });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async updateStrategy(params?: any) {
      try {
        if (params) {
          const resp = await MyStrategiesServices.updateStrategies({
            strategyId: params.dbId,
            category: '',
            code: params.strategyId,
            description: params.strategyDesc,
            title: params.strategyTitle,
            strategyGroupType: params.strategyGroupType,
            action: 'U',
            userMode: CurrentUser.userMode
          });
          if (resp) {
            const data = resp.data;
            if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
              console.log('UPDATE STANDARD EXPIRED');
              return Promise.reject(new SessionExpiredError());
            } else if (data.error === 'true' || data.error === true) {
              return Promise.reject(new AppError('contactSupport', { data }));
            } else {
              if (debug) {
                console.log('UPDATE STANDARD RESPONSE --> ' + JSON.stringify(data));
              }
              return Promise.resolve({ data });
            }
          } else {
            return Promise.resolve({ data: {} });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async deleteStrategy(params?: any) {
      try {
        if (params) {
          const resp = await MyStrategiesServices.updateStrategies({
            userMode: CurrentUser.userMode,
            strategyId: params.dbId,
            action: 'D'
          });
          if (resp) {
            const data = resp.data;
            if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
              console.log('DELETE STRATEGY EXPIRED');
              return Promise.reject(new SessionExpiredError());
            } else if (data.error === 'true' || data.error === true) {
              return Promise.reject(new AppError('contactSupport', { data }));
            } else {
              if (debug) {
                console.log('DELETE STRATEGY RESPONSE --> ' + JSON.stringify(data));
              }
              return Promise.resolve({ data });
            }
          } else {
            return Promise.resolve({ data: {} });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }
}
