import { render, staticRenderFns } from "./Content.vue?vue&type=template&id=1fc1dd79&"
import script from "./Content.vue?vue&type=script&lang=ts&"
export * from "./Content.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VMain } from 'vuetify/lib/components/VMain';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
installComponents(component, {VMain,VResponsive})


/* hot reload */
if (module.hot) {
  var api = require("/Users/adriandaluz/Development/git/planbook-web-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1fc1dd79')) {
      api.createRecord('1fc1dd79', component.options)
    } else {
      api.reload('1fc1dd79', component.options)
    }
    module.hot.accept("./Content.vue?vue&type=template&id=1fc1dd79&", function () {
      api.rerender('1fc1dd79', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/core/Content.vue"
export default component.exports