











import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class TextEditorFullScreenButton extends Vue {
  @Prop({ type: Boolean, default: true })
  isTextFieldsVisible!: boolean;

  @Prop({ type: Boolean, default: false })
  isSectionsHovered!: boolean;

  hasFocus = false;

  hideOrShowTextFields() {
    this.$emit('hideOrShowTextFields');
  }
}
