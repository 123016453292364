import Vue from 'vue';
import Blank from './../components/core/Blank.vue';
import Content from './../components/core/Content.vue';
import LeftDrawer from './../components/core/LeftDrawer.vue';
import RightDrawer from './../components/core/RightDrawer.vue';
import BottomDrawer from './../components/core/BottomDrawer.vue';
import BaseModal from './../components/core/BaseModal.vue';
import MultiSelect from './../components/core/MultiSelect.vue';
import SlidingSheet from './../components/core/SlidingSheet.vue';
import FormatOptions from './../components/core/FormatOptions.vue';
import TextEditor from './../components/core/TextEditor.vue';
import DateField from './../components/core/DateField.vue';
import TimeField from './../components/core/TimeField.vue';
import ColorPicker from './../components/core/ColorPicker.vue';
import ClassEditor from './../components/classes/ClassEditor.vue';
import Confirm from './../components/core/Confirm.vue';
import DateTimeRangeField from '@/components/core/DateTimeRangeField.vue';
import LessonLayoutEditor from '@/components/lessonlayouts/LessonLayoutEditor.vue';
import NotesTodoEditor from '@/components/reminders/NotesTodoEditor.vue';
import MessageEditor from '@/components/messages/MessageEditor.vue';
import BaseSubPage from './../components/core/BaseSubPage.vue';
import MyListEditor from '@/components/mylists/MyListEditor.vue';
import MyStrategiesEditor from '@/components/mystrategies/MyStrategiesEditor.vue';
import PermissionsEditor from '@/components/sharingoptions/PermissionsEditor.vue';
import CsvEditor from '@/components/lessonlists/LessonBankCsvEditor.vue';
import TeacherListAdminView from '@/components/teachers/TeacherListAdminView.vue';
import LabeledButton from '@/components/core/LabeledButton.vue';
import LabeledIcon from '@/components/core/LabeledIcon.vue';
import Banner from '@/components/core/Banner.vue';
import BaseEditor from '@/components/core/BaseEditor.vue';
import UserModeSelector from '@/components/core/UserModeSelector.vue';
import FocusableChip from '@/components/core/FocusableChip.vue';

Vue.component('pb-blank', Blank);
Vue.component('pb-left-drawer', LeftDrawer);
Vue.component('pb-left-drawer', LeftDrawer);
Vue.component('pb-banner', Banner);
Vue.component('pb-right-drawer', RightDrawer);
Vue.component('pb-bottom-drawer', BottomDrawer);
Vue.component('pb-content', Content);
Vue.component('pb-base-modal', BaseModal);
Vue.component('pb-user-mode-selector', UserModeSelector);
Vue.component('pb-multi-select', MultiSelect);
Vue.component('pb-sliding-sheet', SlidingSheet);
Vue.component('pb-format-options', FormatOptions);
Vue.component('pb-text-editor', TextEditor);
Vue.component('pb-date-field', DateField);
Vue.component('pb-time-field', TimeField);
Vue.component('pb-color-picker', ColorPicker);
Vue.component('pb-class-editor', ClassEditor);
Vue.component('pb-confirm', Confirm);
Vue.component('pb-date-time-range-field', DateTimeRangeField);
Vue.component('pb-lesson-layout-editor', LessonLayoutEditor);
Vue.component('pb-notestodo-editor', NotesTodoEditor);
Vue.component('pb-message-editor', MessageEditor);
Vue.component('pb-base-sub-page', BaseSubPage);
Vue.component('pb-my-list-editor', MyListEditor);
Vue.component('pb-my-strategies-editor', MyStrategiesEditor);
Vue.component('pb-permissions-editor', PermissionsEditor);
Vue.component('pb-csv-editor', CsvEditor);
Vue.component('pb-teacher-list-admin-view', TeacherListAdminView);
Vue.component('pb-btn', LabeledButton);
Vue.component('pb-icon', LabeledIcon);
Vue.component('pb-base-editor', BaseEditor);
Vue.component('pb-chip', FocusableChip);
