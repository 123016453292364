import axios from '@/axios/index';
import qs from 'qs';

export default class SchoolProfileServices {
  public static updateSchoolProfile(params?: any) {
    params = params || {};
    return axios.post('services/planbook/school/updateSchool', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static updateSchoolSettings(params?: any) {
    params = params || {};
    return axios.post('services/planbook/school/updateSchoolSettings', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static updateDistrictSettings(params?: any) {
    params = params || {};
    return axios.post('services/planbook/school/updateDistrictSettings', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }
}
