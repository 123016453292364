import { lessonLayoutSectionsDefault } from '@/data/sample';
import AppError, { createErrOptions, SessionExpiredError } from '@/errors';
import LessonLayoutServices from '@/services/lesson-layout-services';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';

const debug = process.env.NODE_ENV !== 'production';

@Module({ namespaced: true })
export default class LessonLayouts extends VuexModule {
  layouts: Array<any> = [];
  selectedLayout = LessonLayouts.layoutDefault();
  localLayout: any = {};
  loadSelectedLayout = true;
  listLoading = false;
  haveLayouts = false;

  private static layoutDefault() {
    return {
      action: 'T',
      name: '',
      lessonLayoutId: 0,
      defaultLayout: 'N',
      tab1Label: '',
      tab2Label: '',
      tab3Label: '',
      tab4Label: '',
      tab5Label: '',
      tab6Label: '',
      tab7Label: '',
      tab8Label: '',
      tab9Label: '',
      tab10Label: '',
      tab11Label: '',
      tab12Label: '',
      strategiesLabel: '',
      tab1DisplayOrder: 1,
      tab2DisplayOrder: 2,
      tab3DisplayOrder: 3,
      tab4DisplayOrder: 4,
      tab5DisplayOrder: 5,
      tab6DisplayOrder: 6,
      tab7DisplayOrder: 8,
      tab8DisplayOrder: 9,
      tab9DisplayOrder: 10,
      tab10DisplayOrder: 13,
      tab11DisplayOrder: 12,
      tab12DisplayOrder: 11,
      strategiesDisplayOrder: 7,
      tab1Enabled: 'Y',
      tab2Enabled: 'Y',
      tab3Enabled: 'Y',
      tab4Enabled: 'N',
      tab5Enabled: 'N',
      tab6Enabled: 'N',
      tab7Enabled: 'N',
      tab8Enabled: 'Y',
      tab9Enabled: 'N',
      tab10Enabled: 'Y',
      tab11Enabled: 'Y',
      tab12Enabled: 'Y',
      strategiesEnabled: 'N',
      sections: lessonLayoutSectionsDefault
    }
  }

  get getDefaultLayout() {
    return LessonLayouts.layoutDefault();
  }

  get getLayouts() {
    return this.layouts;
  }

  get getListLoading() {
    return this.listLoading;
  }

  get getLocalLayout() {
    return this.localLayout;
  }

  @Mutation
  setLocalLayout(val: any) {
    this.localLayout = val;
  }

  get getLoadSelectedLayout() {
    return this.loadSelectedLayout;
  }

  @Mutation
  setLoadSelectedLayout(val: any) {
    this.loadSelectedLayout = val;
  }

  @Mutation
  resetLayout() {
    this.selectedLayout = LessonLayouts.layoutDefault();
    this.loadSelectedLayout = true;
  }

  @Mutation
  setHaveLayouts(haveLayouts: boolean) {
    this.haveLayouts = haveLayouts;
  }

  @Mutation
  setLayout(layout: any) {
    this.selectedLayout = layout;
    this.loadSelectedLayout = true;
  }

  @Mutation
  setLayouts(layouts: Array<any>) {
    this.layouts = layouts;
  }

  @Mutation
  setListLoading(listLoading: boolean) {
    this.listLoading = listLoading;
  }

  @Action({ rawError: true })
  async loadLayouts() {
    try {
      const userMode = store.state.settings.userMode;
      if (this.haveLayouts) {
        return Promise.resolve({ data: { lessonlayouts: this.layouts } });
      }
      if (userMode !== 'S') {
        const context = this.context;
        const userMode = context.rootState.settings.userMode;
        const resp = await LessonLayoutServices.getLayouts({ userMode: userMode });
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            console.log('GET LESSONLAYOUTS EXPIRED');
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            if (debug) {
              console.log('GET LESSONLAYOUTS RESPONSE --> ' + JSON.stringify(data));
            }
            this.context.commit('setLayouts', data.lessonlayouts || []);
            this.context.commit('setHaveLayouts', true);
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async loadLayout(params?: any) {
    try {
      const context = this.context;
      const userMode = context.rootState.settings.userMode;
      params.userMode = userMode;
      const resp = await LessonLayoutServices.getLayout(params);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          console.log('GET LESSONLAYOUT EXPIRED');
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          if (debug) {
            console.log('GET LESSONLAYOUT RESPONSE --> ' + JSON.stringify(data));
          }
          this.context.commit('setLayout', data.lessonlayout || {});
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async addOrUpdateLayout(params?: any) {
    try {
      if (params) {
        const resp = await LessonLayoutServices.addOrUpdateLayout(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            console.log('ADD OR UPDATE LESSONLAYOUT EXPIRED');
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            if (debug) {
              console.log('ADD OR UPDATE LESSONLAYOUT RESPONSE --> ' + JSON.stringify(data));
            }
            this.context.commit('settings/setHaveSettings', false, { root: true });
            await this.context.dispatch('plans/reloadPlans', {}, { root: true });
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async saveAsCurrenLayout(params?: any) {
    try {
      if (params) {
        const resp = await LessonLayoutServices.saveAsCurrenLayout(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            console.log('SAVE AS CURRENT LESSON LAYOUT EXPIRED');
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            if (debug) {
              console.log('SAVE AS CURRENT LESSON LAYOUT RESPONSE --> ' + JSON.stringify(data));
            }
            this.context.commit('settings/setHaveSettings', false, { root: true });
            await this.context.dispatch('plans/reloadPlans', {}, { root: true });
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async deleteLayouts(params?: any) {
    try {
      if (params) {
        const resp = await LessonLayoutServices.deleteLayouts({
          lessonLayoutIds: params.lessonLayoutIds
        });
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            console.log('DELETE LESSONLAYOUTS EXPIRED');
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            if (debug) {
              console.log('DELETE LESSONLAYOUTS RESPONSE --> ' + JSON.stringify(data));
            }
            this.context.commit('settings/setHaveSettings', false, { root: true });
            await this.context.dispatch('plans/reloadPlans', {}, { root: true });
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }
}
