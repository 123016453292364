





























































import CommonUtils from '@/utils/common-utils';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import moment from 'moment';

const settings = namespace('settings');
const teachers = namespace('teachers');
const plans = namespace('plans');

@Component
export default class SchoolYearSelector extends Vue {
  @Prop({ type: Boolean, default: true })
  outlined!: boolean;

  @Prop({ type: Boolean, default: false })
  warnPastYears!: boolean;

  @Prop({ type: Boolean, default: false })
  slideItem!: boolean

  @settings.Getter('getSchoolYearItems')
  teacherSchoolYearItems!: any[];

  @teachers.Getter('getAdminYearItems')
  getAdminYearItems!: (teacherId: number) => any[];

  @plans.Action
  changePlans!: (param?: any) => Promise<any>;

  get schoolYearItems() {
    if (this.$currentUser.isAdmin) {
      return this.getAdminYearItems(+this.currentTeacherId);
    } else {
      return this.teacherSchoolYearItems;
    }
  }

  get currentTeacherId(): number {
    if (this.$currentUser.isTeacher) {
      return +this.$store.state.settings.userId;
    } else {
      return +this.$store.state.settings.currentTeacherId;
    }
  }

  set currentTeacherId(value: number) {
    this.$store.commit('settings/setCurrentTeacherId', value);
  }

  get currentYearId(): number {
    if (this.$currentUser.isTeacher) {
      return +this.$store.getters['settings/getCurrentSchoolYearId'];
    } else {
      return +this.$store.state.settings.currentYearId;
    }
  }

  get hasText() {
    return CommonUtils.hasText;
  }

  get currentYearText() {
    for (let x = 0; x < this.schoolYearItems.length; x++) {
      if (this.schoolYearItems[x].value === this.currentYearId) {
        return this.schoolYearItems[x].text;
      }
    }
    return '...';
  }

  get currentYear() {
    for (let x = 0; x < this.schoolYearItems.length; x++) {
      if (this.schoolYearItems[x].value === this.currentYearId) {
        return this.schoolYearItems[x];
      }
    }
    return { firstDay: '12/31/9999', lastDay: '12/31/9999' };
  }

  get isCurrentDateInSchoolYearRange() {
    const lastDay = moment(this.currentYear.lastDay || '12/31/9999', 'MM/DD/YYYY');
    return !this.warnPastYears || moment().isSameOrBefore(lastDay);
  }

  schoolYearChanged(param: any) {
    this.changePlans(param).then(() => {
      this.$eventBus.$emit('schoolYearChanged', param);
    });
  }
}
