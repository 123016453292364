import qs from 'qs';
import axios from '@/axios';

export default class LessonLayoutServices {
  public static getLayouts(params?: any) {
    params = params || {};
    return axios.get('services/planbook/lessonlayout/getAll', { params });
  }

  public static getLayout(params?: any) {
    params = params || {};
    return axios.get('services/planbook/lessonlayout/get', { params });
  }

  public static addOrUpdateLayout(params?: any) {
    params = params || {};
    return axios.post('services/planbook/lessonlayout/save', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static saveAsCurrenLayout(params?: any) {
    params = params || {};
    return axios.post('services/planbook/lessonlayout/saveAsCurrenLayout', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static deleteLayouts(params?: any) {
    params = params || {};
    return axios.delete('services/planbook/lessonlayout/delete', {
      data: qs.stringify(params, {
        arrayFormat: 'repeat'
      }),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }
}
