var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasLabel
    ? _c(
        "div",
        { attrs: { id: _vm.id } },
        [
          _c(
            "v-tooltip",
            {
              attrs: { "open-delay": "200", bottom: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "div",
                          _vm._g({}, on),
                          [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    class: {
                                      "no-focus-border": !_vm.focusBorder
                                    },
                                    attrs: { "aria-label": _vm.ariaLabel },
                                    on: {
                                      focus: function($event) {
                                        _vm.tooltipOpen = true
                                      },
                                      blur: function($event) {
                                        _vm.tooltipOpen = false
                                      }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        _vm._l(_vm.$scopedSlots, function(
                                          _,
                                          scopedSlotName
                                        ) {
                                          return {
                                            key: scopedSlotName,
                                            fn: function(slotData) {
                                              return [
                                                _vm._t(
                                                  scopedSlotName,
                                                  null,
                                                  null,
                                                  slotData
                                                )
                                              ]
                                            }
                                          }
                                        }),
                                        _vm._l(_vm.$slots, function(
                                          _,
                                          slotName
                                        ) {
                                          return {
                                            key: slotName,
                                            fn: function() {
                                              return [_vm._t(slotName)]
                                            },
                                            proxy: true
                                          }
                                        })
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  "v-btn",
                                  Object.assign({}, _vm.$attrs),
                                  false
                                ),
                                _vm.$listeners
                              )
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                2346319525
              ),
              model: {
                value: _vm.tooltipOpen,
                callback: function($$v) {
                  _vm.tooltipOpen = $$v
                },
                expression: "tooltipOpen"
              }
            },
            [_c("span", [_vm._v(_vm._s(_vm.label))])]
          )
        ],
        1
      )
    : _c(
        "div",
        { attrs: { id: _vm.id } },
        [
          _c(
            "v-btn",
            _vm._g(
              _vm._b(
                {
                  class: { "no-focus-border": !_vm.focusBorder },
                  attrs: { "aria-label": _vm.ariaLabel },
                  on: {
                    focus: function($event) {
                      _vm.tooltipOpen = true
                    },
                    blur: function($event) {
                      _vm.tooltipOpen = false
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      _vm._l(_vm.$scopedSlots, function(_, scopedSlotName) {
                        return {
                          key: scopedSlotName,
                          fn: function(slotData) {
                            return [
                              _vm._t(scopedSlotName, null, null, slotData)
                            ]
                          }
                        }
                      }),
                      _vm._l(_vm.$slots, function(_, slotName) {
                        return {
                          key: slotName,
                          fn: function() {
                            return [_vm._t(slotName)]
                          },
                          proxy: true
                        }
                      })
                    ],
                    null,
                    true
                  )
                },
                "v-btn",
                Object.assign({}, _vm.$attrs),
                false
              ),
              _vm.$listeners
            )
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }