import AppError, { createErrOptions, SessionExpiredError } from '@/errors';
import TemplateServices from '@/services/template-services';
import store from '@/store';
import CommonUtils from '@/utils/common-utils';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true })
export default class Templates extends VuexModule {
    templates: Array<any> = [];
    attachments: any[] = [];
    standards: any[] = [];
    strategies: any[] = [];
    myStandards: any[] = [];
    schoolStandards: any[] = [];
    localTemplate: any = {}
    origTemplate: any = {};
    origSections: any = {};
    activeTab = 0;
    listLoading = false;
    editMode = false;
    searchText = '';
    selectedRows: Array<any> = [];
    isFiltered = false;
    haveTemplates = false;

    get getAllAttachments() {
      return this.attachments;
    }

    get getListLoading() {
      return this.listLoading;
    }

    @Mutation
    setHaveTemplates(haveTemplates: boolean) {
      this.haveTemplates = haveTemplates;
    }

    @Mutation
    setActiveTab(tab: number) {
      this.activeTab = tab;
    }

    @Mutation
    setLocalTemplate(template: any) {
      this.localTemplate = template;
    }

    @Mutation
    setOrigTemplate(template: any) {
      this.origTemplate = template;
    }

    @Mutation
    setOrigSections(sections: any) {
      this.origSections = sections;
    }

    @Mutation
    setAttachments(attachments: Array<any>) {
      this.attachments = attachments;
    }

    @Mutation
    setStandards(standards: Array<any>) {
      this.standards = standards;
    }

    @Mutation
    setStrategies(strategies: Array<any>) {
      this.strategies = strategies;
    }

    @Mutation
    setMyStandards(myStandards: Array<any>) {
      this.myStandards = myStandards;
    }

    @Mutation
    setSchoolStandards(schoolStandards: Array<any>) {
      this.schoolStandards = schoolStandards;
    }

    @Mutation
    setTemplates(t: Array<any>) {
      this.templates = t;
    }

    @Mutation
    setEditMode(v: boolean) {
      this.editMode = v;
    }

    @Mutation
    setSearchText(v: string) {
      this.searchText = v;
    }

    @Mutation
    setListLoading(listLoading: boolean) {
      this.listLoading = listLoading;
    }

    @Mutation
    setSelectedRows(v: Array<any>) {
      this.selectedRows = v;
    }

    @Mutation
    setIsFiltered(v: boolean) {
      this.isFiltered = v;
    }

    get getAllTemplates() {
      return this.templates;
    }

    get getFavoriteTemplates() {
      return this.templates.filter((t: any) => t.favorite === 1);
    }

    @Action({ rawError: true })
    async loadTemplates(param?: any) {
      try {
        if (this.haveTemplates) {
          return Promise.resolve({ data: {} });
        }
        param = param || { userType: this.context.rootState.settings.userMode, teacherId: this.context.rootGetters['settings/getUserId'] };
        const resp = await TemplateServices.getTemplates({
          teacherId: param.teacherId,
          userMode: param.userType
        });

        if (resp) {
          const data = resp.data
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            if (CommonUtils.isNotEmpty(data.templates)) {
              data.templates.forEach((t: any, index: number) => {
                data.templates[index].legacy = CommonUtils.hasNoText(t.title);
              });
            }
            this.context.commit('setTemplates', data.templates);
            this.context.commit('setHaveTemplates', true);
            return Promise.resolve({ resp });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async toggleFavoriteStatus(param?: any) {
      try {
        const request = Templates.toggleFavoriteRequest(param, this.context.rootState.settings.userMode);
        const resp = await TemplateServices.updateTemplate(request);
        if (resp) {
          const data = resp.data
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve({ resp });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    private static toggleFavoriteRequest(param: any, userMode: any) {
      const payload: any = {
        userMode: userMode,
        templateId: param.id,
        title: param.title,
        favorite: (param.favorite === 1) ? 0 : 1,
        yearId: param.yearId,
        hide: param.hide,
        types: []
      }

      if (param.schoolId) {
        payload.schoolId = param.schoolId;
      }
      if (param.districtId) {
        payload.districtId = param.districtId;
      }

      // content sections
      payload.lessonText = param.lessonContent;
      if (param.lessonContent && CommonUtils.hasText(param.lessonContent)) {
        payload.types.push('L');
      }
      payload.homeworkText = param.homeworkContent;
      if (param.homeworkContent && CommonUtils.hasText(param.homeworkContent)) {
        payload.types.push('H');
      }
      payload.notesText = param.notesContent;
      if (param.notesContent && CommonUtils.hasText(param.notesContent)) {
        payload.types.push('N');
      }
      payload.section4Text = param.tab4Content;
      if (param.tab4Content && CommonUtils.hasText(param.tab4Content)) {
        payload.types.push('4');
      }
      payload.section5Text = param.tab5Content;
      if (param.tab5Content && CommonUtils.hasText(param.tab5Content)) {
        payload.types.push('5');
      }
      payload.section6Text = param.tab6Content;
      if (param.tab6Content && CommonUtils.hasText(param.tab6Content)) {
        payload.types.push('6');
      }

      // content sections
      for (const section in param.content) {
        payload.types.push(param.content[section].section);
        switch (param.content[section].section) {
          case 'L':
            payload.lessonText = param.content[section].value;
            break;
          case 'H':
            payload.homeworkText = param.content[section].value;
            break;
          case 'N':
            payload.notesText = param.content[section].value;
            break;
          case '4':
            payload.section4Text = param.content[section].value;
            break;
          case '5':
            payload.section5Text = param.content[section].value;
            break;
          case '6':
            payload.section6Text = param.content[section].value;
            break;
        }
      }

      // applied classes -> not included when unchanged

      // my standards
      if (param.myStandards.length > 0) {
        payload.types.push('MS');
        payload.myStandardDBIds = param.myStandards.map((s: any) => { return s.standardDBId });
      }
      // strategies
      if (param.strategies.length > 0) {
        payload.types.push('STR');
        payload.strategiesIds = param.strategies.map((s: any) => { return s.strategyId });
      }
      // standards
      if (param.standards.length > 0) {
        payload.types.push('S');
        payload.standardDBIds = param.standards.map((s: any) => { return s.standardDBId })
      }
      // schools standards
      if (param.schoolStandards.length > 0) {
        payload.types.push('SS');
        payload.schoolStandardDBIds = param.schoolStandards.map((s: any) => { return s.standardDBId })
      }
      // attachments
      if (param.attachments.length > 0) {
        payload.types.push('A');
      }
      for (const s in param.attachments) {
        payload['attachments[' + s + '].name'] = param.attachments[s].name || param.attachments[s].filename;
        payload['attachments[' + s + '].url'] = param.attachments[s].url;
        payload['attachments[' + s + '].privateFlag'] = CommonUtils.booleanToString(param.attachments[s].privateFlag);
        payload['attachments[' + s + '].attachmentType'] = param.attachments[s].type;
      }

      return payload;
    }

    @Action({ rawError: true })
    async deleteTemplate(param?: any) {
      try {
        const request = {
          templateId: param.id,
          isAdminTemplate: param.isAdminTemplate
        }
        const resp = await TemplateServices.deleteTemplate(request);
        if (resp) {
          const data = resp.data
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve({ resp });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async deleteTemplates(param?: any) {
      try {
        const resp = await TemplateServices.deleteTemplates(param);
        if (resp) {
          const data = resp.data
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve({ resp });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async saveTemplate(param: any) {
      try {
        const schoolId = this.context.rootGetters['settings/getSchoolId'];
        const isCycleSchedule = this.context.rootGetters['settings/isCycleSchedule'];
        const totalDays = this.context.rootGetters['classes/cycleDays'].length;
        const request = Templates.saveRequest(param, this.context.rootState.settings.userMode, schoolId, isCycleSchedule, totalDays);
        const resp = await TemplateServices.updateTemplate(request);
        if (resp) {
          const data = resp.data
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            this.context.commit('plans/resetPlans', null, { root: true });
            return Promise.resolve({ resp });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    private static saveRequest(param: any, userMode: any, schoolId: any, isCycleSchedule: any, totalDays: any) {
      const payload: any = {
        userMode: userMode,
        title: param.title,
        favorite: param.favorite,
        yearId: param.yearId,
        lessonLayoutId: param.lessonLayoutId,
        hide: 0,
        types: [],
        legacy: param.legacy
      }

      // including templateId to change existing template
      if (param.id !== 0) {
        payload.templateId = param.id;
      }

      if (param.districtId !== 0) {
        payload.districtId = param.districtId;
        param.yearId = 0;
      } else if (userMode === 'A' || param.schoolId !== 0) {
        payload.schoolId = param.schoolId || schoolId;
        param.yearId = 0;
      }

      // content sections
      payload.lessonText = param.lessonContent;
      if (param.lessonContent && CommonUtils.hasText(param.lessonContent)) {
        payload.types.push('L');
      }
      payload.homeworkText = param.homeworkContent;
      if (param.homeworkContent && CommonUtils.hasText(param.homeworkContent)) {
        payload.types.push('H');
      }
      payload.notesText = param.notesContent;
      if (param.notesContent && CommonUtils.hasText(param.notesContent)) {
        payload.types.push('N');
      }
      payload.section4Text = param.tab4Content;
      if (param.tab4Content && CommonUtils.hasText(param.tab4Content)) {
        payload.types.push('4');
      }
      payload.section5Text = param.tab5Content;
      if (param.tab5Content && CommonUtils.hasText(param.tab5Content)) {
        payload.types.push('5');
      }
      payload.section6Text = param.tab6Content;
      if (param.tab6Content && CommonUtils.hasText(param.tab6Content)) {
        payload.types.push('6');
      }

      const state = store.state.templates;
      // my standards
      if (state.myStandards.length > 0) {
        payload.types.push('MS');
        payload.myStandardDBIds = state.myStandards.map((s: any) => { return s.dbId });
      }
      // strategies
      if (state.strategies.length > 0) {
        payload.types.push('STR');
        payload.strategiesIds = state.strategies.map((s: any) => { return s.strategyId });
      }
      // standards
      if (state.standards.length > 0) {
        payload.types.push('S');
        payload.standardDBIds = state.standards.map((s: any) => { return s.dbId || s.standardDBId });
      }
      // school standards
      if (state.schoolStandards.length > 0) {
        payload.types.push('SS');
        payload.schoolStandardDBIds = state.schoolStandards.map((s: any) => { return s.dbId });
      }
      // attachments
      if (state.attachments.length > 0) {
        payload.types.push('A');
        for (const i in state.attachments) {
          payload['attachments[' + i + '].name'] = state.attachments[i].name || state.attachments[i].filename;
          payload['attachments[' + i + '].url'] = state.attachments[i].url;
          payload['attachments[' + i + '].privateFlag'] = CommonUtils.booleanToString(state.attachments[i].privateFlag);
          payload['attachments[' + i + '].attachmentType'] = 'T';
        }
      }

      // applied classes
      payload.customDayIdsForDelete = param.appliedClasses.filter((c: any) => c.customDayId !== 0).map((c: any) => { return c.customDayId });
      payload.customDayIdsForDelete = payload.customDayIdsForDelete.concat(param.removeAppliedClass);
      payload.classIds = param.appliedClasses.map((c: any) => { return c.class.cId || c.class.classId });
      payload.when = param.appliedClasses.map((c: any) => {
        if (!isCycleSchedule && c.days.length === totalDays) {
          return 'E';
        } else {
          return c.days.toString();
        }
      });
      payload.start = param.appliedClasses.map((c: any) => { return c.startDate });
      payload.end = param.appliedClasses.map((c: any) => { return c.endDate });

      return payload;
    }

    @Action({ rawError: true })
    async applyTemplate(param: any) {
      try {
        const resp = await TemplateServices.applyTemplate(param);
        if (resp) {
          const data = resp.data
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve({ resp });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async removeRestoreTemplate(param: any) {
      try {
        const resp = await TemplateServices.removeRestoreTemplate(param);
        if (resp) {
          const data = resp.data
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve({ resp });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }
}
