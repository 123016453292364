var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "pb-base-editor",
    {
      attrs: {
        isModal: _vm.isModal,
        withApply: false,
        withActions: false,
        onApply: _vm.doApply,
        isDirty: _vm.isDirty,
        typeLabel: _vm.$t("strategyLabel")
      },
      on: { close: _vm.cancelEditor },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              !_vm.isEditing
                ? _c("span", [_vm._v(_vm._s(_vm.$t("addLabel")))])
                : _c("span", [_vm._v(_vm._s(_vm.$t("editLabel")))]),
              _c("span", [_vm._v(" " + _vm._s(_vm.$t("strategyLabel")))])
            ]
          },
          proxy: true
        },
        {
          key: "action-button",
          fn: function() {
            return [
              _vm.isEditing &&
              (_vm.localStrategy.strategyGroupType != "D" ||
                _vm.$currentUser.userType === "D")
                ? _c(
                    "pb-btn",
                    {
                      attrs: { icon: "", label: _vm.$t("deleteLabel") },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.deleteSelectedStrategy.apply(
                            null,
                            arguments
                          )
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("fal fa-trash-alt")])],
                    1
                  )
                : _vm._e(),
              _vm.$currentUser.isTeacher
                ? _c(
                    "pb-btn",
                    {
                      attrs: {
                        label: _vm.$t("saveLabel"),
                        icon: "",
                        right: "",
                        disabled: _vm.listLoading
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.addNewStrategyTo("T", true)
                        }
                      }
                    },
                    [
                      _vm.listLoading
                        ? _c("v-icon", [_vm._v("fal fa-sync fa-spin")])
                        : _c("v-icon", [_vm._v("fal fa-check")])
                    ],
                    1
                  )
                : _vm.$currentUser.isAdmin &&
                  !_vm.$currentUser.isDistrictAdmin &&
                  _vm.localStrategy.strategyGroupType != "D"
                ? _c(
                    "pb-btn",
                    {
                      attrs: {
                        label: _vm.$t("saveLabel"),
                        icon: "",
                        right: "",
                        disabled: _vm.listLoading
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.addNewStrategyTo("S", true)
                        }
                      }
                    },
                    [
                      _vm.listLoading
                        ? _c("v-icon", [_vm._v("fal fa-sync fa-spin")])
                        : _c("v-icon", [_vm._v("fal fa-check")])
                    ],
                    1
                  )
                : _vm.$currentUser.isAdmin && !_vm.$currentUser.isDistrictAdmin
                ? _c(
                    "pb-btn",
                    {
                      attrs: {
                        label: _vm.$t("doneLabel"),
                        icon: "",
                        right: "",
                        disabled: _vm.listLoading
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.close.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _vm.listLoading
                        ? _c("v-icon", [_vm._v("fal fa-sync fa-spin")])
                        : _c("v-icon", [_vm._v("fal fa-check")])
                    ],
                    1
                  )
                : _vm.$currentUser.isDistrictAdmin
                ? _c(
                    "v-menu",
                    {
                      attrs: { "offset-y": "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "pb-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        label: _vm.$t("applyLabel"),
                                        icon: "",
                                        right: "",
                                        disabled: _vm.listLoading
                                      }
                                    },
                                    "pb-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _vm.listLoading
                                    ? _c("v-icon", [
                                        _vm._v("fal fa-sync fa-spin")
                                      ])
                                    : _c("v-icon", [_vm._v("fal fa-check")])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.districtMenu,
                        callback: function($$v) {
                          _vm.districtMenu = $$v
                        },
                        expression: "districtMenu"
                      }
                    },
                    [
                      _c(
                        "v-list",
                        [
                          _vm.$currentUser.isAdmin
                            ? _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.addNewStrategyTo("S", true)
                                    }
                                  }
                                },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(_vm.$t("saveToSchoolLabel")))
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.$currentUser.isDistrictAdmin
                            ? _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.addNewStrategyTo("D", true)
                                    }
                                  }
                                },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      _vm._s(_vm.$t("saveToDistrictLabel"))
                                    )
                                  ])
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.localValue,
        callback: function($$v) {
          _vm.localValue = $$v
        },
        expression: "localValue"
      }
    },
    [
      _c("my-strategies-editor-form", {
        ref: "form",
        attrs: { input: _vm.input, isModal: _vm.isModal }
      }),
      _c("pb-confirm", { ref: "strategyEditorConfirm" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }