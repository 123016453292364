import axios from '@/axios';

export default class ChangeHistoryServices {
  public static getLessonChangeHistory(lessonId: number): Promise<any> {
    return axios.get(`services/planbook/revisionhistory/LESSON/${lessonId}`);
  }

  public static getChangeHistory() {
    return axios.get('services/planbook/revisionhistory');
  }
}
