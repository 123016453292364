import CurrentUser from '@/common/current-user';
import { PlanbookStorage } from '@/common/planbook-storage';
import { UserSession } from '@/common/user-session';
import { displayViewTypeMapping, otherViewItems, selectLinkedViewItems, selectViewItems, studentViewItems } from '@/constants';
import AppError, { SessionExpiredError, createErrOptions } from '@/errors';
import eventBus from '@/events';
import vuetify from '@/plugins/vuetify';
import LessonServices from '@/services/lesson-services';
import SettingsServices from '@/services/settings-services';
import CommonUtils from '@/utils/common-utils';
import DateTimeUtils from '@/utils/date-time-utils';
import ld from 'lodash';
import moment from 'moment-timezone';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '..';

@Module({ namespaced: true })
class Plans extends VuexModule {
  month = '';
  monday = '';
  cyclePage = 1;
  viewType = '';
  currentDay = '';
  adminEvents: any[] = [];
  cycleSchedule: any = {};
  currentCycleData: any = null;
  currentData: any = null;
  currentListData: any = null;
  currentMonthData: any = null;
  showDetails = true;
  parentChange = false;
  showCalendarDay = false;
  haveWeeks = false;
  haveMonth = false;
  haveList = false;
  haveCycleSchedule = false;
  haveCycle = false;
  resetOnly = false;
  searchLessonArray:any[] = [];
  seachLessonString = '';
  viewKey = '';
  remindersFloatingButtonIcon = 'fal fa-angle-right';

  // GETTERS: START
  get getWeekDateRange() {
    const currentPlans: any = this.getCurrentPlans;
    if (currentPlans) {
      return {
        startDate: (currentPlans['day' + 1] || { date: '' }).date,
        endDate: (currentPlans['day' + currentPlans.dayCount] || { date: '' }).date
      }
    }
    return {
      startDate: '',
      endDate: ''
    }
  }

  get getMonthDateRange() {
    return {
      startDate: moment(this.month, 'MM/DD/YYYY').startOf('month').format('MM/DD/YYYY'),
      endDate: moment(this.month, 'MM/DD/YYYY').endOf('month').format('MM/DD/YYYY')
    }
  }

  get getCycleScheduleDateStartEnd() {
    if (this.haveCycleSchedule) {
      return { start: this.cycleSchedule.days[0].date, end: this.cycleSchedule.days[this.cycleSchedule.days.length - 1].date } || { start: '... ..', end: '... ..' };
    }
    return {
      start: '... ..',
      end: '... ..'
    }
  }

  get getCycleDateRange() {
    if (this.haveCycleSchedule) {
      return this.cycleSchedule.pages[this.cyclePage] || { cycleStart: '... ..', cycleEnd: '... ..' };
    }
    return {
      cycleStart: '... ..',
      cycleEnd: '... ..'
    }
  }

  get getCyclePages() {
    const pages = this.cycleSchedule.pages || {};
    const items: any = {};
    for (const i in pages) {
      const page = pages[i];
      items[+i] = {
        cycleStart: moment(page.cycleStart, 'MM/DD/YYYY').format('MMM DD'),
        cycleEnd: moment(page.cycleEnd, 'MM/DD/YYYY').format('MMM DD')
      };
    }
    return items;
  }

  get getEnabledDayNums() {
    const dateStyling = this.context.rootGetters['settings/getDateStyling'];
    const otherSettings = this.context.rootGetters['settings/getOtherSettings'];
    const currentYearId = this.context.rootState.settings.currentYearId;
    const days: any = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
    const items = [];
    for (const i in days) {
      const day = days[i];
      let enabled = false;

      if (CommonUtils.hasValue(otherSettings[day + 'ShowSchoolYears']) && otherSettings[day + 'ShowSchoolYears'].years[currentYearId] !== undefined) {
        enabled = CommonUtils.isTrue(otherSettings[day + 'ShowSchoolYears'].years[currentYearId]);
      } else {
        enabled = dateStyling[day + 'Show'] ? CommonUtils.isTrue(dateStyling[day + 'Show']) : ['mon', 'tue', 'wed', 'thu', 'fri'].includes(day);
      }

      if (enabled) {
        items.push(+i);
      }
    }
    return items;
  }

  get getEnabledDaysCount() {
    return this.getEnabledDayNums.length;
  }

  get getSelectedClassItems() {
    const viewClassItems = this.context.rootGetters['classes/getClassItems']
    const hideClassIds = this.context.rootGetters['settings/getHideClassIds'];
    const allValues = viewClassItems.map((item: any) => item.value).sort();
    const rawSelectedItems = allValues.filter((c: any) => !hideClassIds.includes(c)).sort();
    if (ld.isEqual(allValues, rawSelectedItems)) {
      return ['all'].concat(rawSelectedItems);
    } else if (CommonUtils.isEmpty(rawSelectedItems)) {
      return ['none'];
    } else {
      return rawSelectedItems;
    }
  }

  get getSelectedSchoolStatusItems() {
    const viewStatusItems = this.context.rootGetters['settings/getViewSchoolStatusItems'];
    const allValues = viewStatusItems.map((item: any) => item.value).sort();
    const rawSelectedItems = this.context.rootGetters['settings/getSelectedSchoolStatusItems'].sort();
    if (ld.isEqual(allValues, rawSelectedItems)) {
      return ['all'].concat(rawSelectedItems);
    } else if (CommonUtils.isEmpty(rawSelectedItems)) {
      return ['none'];
    } else {
      return rawSelectedItems;
    }
  }

  get getSelectedStatusItems() {
    const viewStatusItems = this.context.rootGetters['settings/getViewStatusItems'];
    const allValues = viewStatusItems.map((item: any) => item.value).sort();
    const rawSelectedItems = this.context.rootGetters['settings/getSelectedViewStatusItems'].sort();
    if (ld.isEqual(allValues, rawSelectedItems)) {
      return ['all'].concat(rawSelectedItems);
    } else if (CommonUtils.isEmpty(rawSelectedItems)) {
      return ['none'];
    } else {
      return rawSelectedItems;
    }
  }

  get otherViewItemValues() {
    return otherViewItems.map(o => o.value);
  }

  get viewItemValues() {
    if (this.context.rootState.settings.userMode === 'S') {
      return studentViewItems.map(o => o.value);
    } else {
      return selectViewItems.map(o => o.value);
    }
  }

  get linkedItemValues() {
    return selectLinkedViewItems.map(o => o.value);
  }

  get getSelectedLessonSectionItems() {
    const viewLessonSectionItems = this.context.rootGetters['settings/getLessonSectionViewItems'];
    const isLessonSectionAndEnabled = this.context.rootGetters['settings/isLessonSectionAndEnabled'];
    const allValues = (viewLessonSectionItems[0] || []).map((item: any) => item.value).sort();
    const allLinkedValues = (viewLessonSectionItems[1] || []).map((item: any) => item.value).sort();
    const allOtherValues = (viewLessonSectionItems[2] || []).map((item: any) => item.value).sort();
    const rawSelectedItems = this.context.rootGetters['settings/getDisplayViewItems'].filter((s: string) => isLessonSectionAndEnabled(s, true) && this.viewItemValues.includes(s)).sort();
    const rawSelectedLinkedItems = this.context.rootGetters['settings/getDisplayViewItems'].filter((s: string) => isLessonSectionAndEnabled(s) && this.linkedItemValues.includes(s)).sort();
    const rawSelectedOtherItems = this.context.rootGetters['settings/getDisplayViewItems'].filter((s: string) => isLessonSectionAndEnabled(s) && this.otherViewItemValues.includes(s)).sort();
    let selectedItems = [];
    let selectedLinkedItems = [];
    let selectedOtherItems = [];
    if (ld.isEqual(allValues, rawSelectedItems)) {
      selectedItems = ['all'].concat(rawSelectedItems);
    } else if (CommonUtils.isEmpty(rawSelectedItems)) {
      selectedItems = ['none'];
    } else {
      selectedItems = rawSelectedItems;
    }
    if (ld.isEqual(allLinkedValues, rawSelectedLinkedItems)) {
      selectedLinkedItems = ['allLinked'].concat(rawSelectedLinkedItems);
    } else if (CommonUtils.isEmpty(rawSelectedLinkedItems)) {
      selectedLinkedItems = ['noneLinked'];
    } else {
      selectedLinkedItems = rawSelectedLinkedItems;
    }
    if (ld.isEqual(allOtherValues, rawSelectedOtherItems)) {
      selectedOtherItems = ['allOther'].concat(rawSelectedOtherItems);
    } else if (CommonUtils.isEmpty(rawSelectedOtherItems)) {
      selectedOtherItems = ['noneOther'];
    } else {
      selectedOtherItems = rawSelectedOtherItems;
    }
    return selectedItems.concat(selectedLinkedItems).concat(selectedOtherItems);
  }

  get isVertical() {
    return !this.isHorizontal;
  }

  get isHorizontal() {
    const orientationSettings = this.context.rootGetters['settings/getLessonOrientationSettings'];
    return orientationSettings.viewOrientation === 1 && ['C', 'W'].includes(this.viewType);
  }

  get getBoxSize() {
    const orientationSettings = this.context.rootGetters['settings/getLessonOrientationSettings'];
    return orientationSettings.boxSize;
  }

  get getZoom() {
    const settings = this.context.rootGetters['settings/getSettings'];
    const plansTextScale = settings.otherSettings.plansTextScale;
    if (plansTextScale && this.context.rootState.settings.userMode !== 'S') {
      return (parseInt(plansTextScale, 10)) / 100;
    }
    return 1;
  }

  get isEqual() {
    const orientationSettings = this.context.rootGetters['settings/getLessonOrientationSettings'];
    return orientationSettings.equalSize === 'Y' && ['C', 'W'].includes(this.viewType);
  }

  get isAdjustToFit() {
    const orientationSettings = this.context.rootGetters['settings/getLessonOrientationSettings'];
    return orientationSettings.adjustToFit === 'Y' && ['C', 'W'].includes(this.viewType);
  }

  get getCurrentClassLessons() {
    const data = this.currentListData;
    const items = [];
    if (data && CommonUtils.isNotEmpty(data.lessons)) {
      for (const i in data.lessons) {
        const lesson = data.lessons[i];
        const item: any = { data: lesson }
        item.title = lesson.lessonId > 0 ? lesson.lessonTitle || lesson.linkedLessonTitle || 'Untitled Lesson' : 'Empty Lesson';
        item.unitNum = lesson.unitNum || (lesson.linkedUnit || {}).unitNum || '';
        item.date = DateTimeUtils.formatDate(lesson.date, 'ddd MMM DD, YYYY');
        item.linked = lesson.linkedLessonId > 0;
        items.push(item);
      }
    }
    return items;
  }

  get getWeekSchoolStatus() {
    if (this.currentData) {
      return this.currentData.schoolStatuses;
    } else {
      return [];
    }
  }

  get getCurrentPlans() {
    const data = this.currentData;
    if (data) {
      const settings = this.getCurrentPlanSettings;
      const userMode = this.context.rootState.settings.userMode;
      const daysMap = LessonServices.createDays(data.days, settings, userMode);
      const studentId = this.context.rootState.settings.studentId;
      const studentTeacherId = this.context.rootState.settings.studentTeacherId;
      const weekdays = this.getEnabledDayNums;
      let maxElements = 0;
      let firstElement = false;
      let dayCount = 0;
      const plans: any = {};
      for (const day in daysMap) {
        dayCount++;
        const plan: any = plans[day] = {};
        const date = daysMap[day].date;
        const enabled = weekdays.includes(moment(date, 'MM/DD/YYYY').day());
        plan.show = (this.viewType === 'W' && enabled) || (enabled && this.currentDay === date);
        if (!firstElement && plan.show) {
          firstElement = true;
          plan.first = true;
        }
        if (daysMap[day]) {
          plan.text = daysMap[day].dayOfWeek + ' ' + DateTimeUtils.formatToShow(daysMap[day].date, settings);
          plan.date = daysMap[day].date;
          plan.name = daysMap[day].cycleDay;
          plan.count = daysMap[day].dayCount;
          plan.gradingPeriodCount = daysMap[day].gradingPeriodCount;
          plan.weekCount = daysMap[day].weekCount;
          plan.weekTotal = daysMap[day].weekTotal;
          plan.total = daysMap[day].totalCount;
          plan.dayObjects = daysMap[day].dayObjects || [];
          plan.schoolStatuses = (data.schoolStatuses || []).filter((addendum: any) => {
            if (addendum.type === 'L' || addendum.type === 'D') {
              return DateTimeUtils.daysDiff(daysMap[day].date, addendum.startDate) === 0
            } else {
              return true;
            }
          });

          if (userMode !== 'S') {
            for (const obj in plan.dayObjects) {
              const statusSection = plan.dayObjects[obj].sections.find((s: any) => s.section === 'statuses');
              if (!statusSection) {
                continue;
              }
              for (const status in plan.schoolStatuses) {
                if (plan.schoolStatuses[status].subjectId === 0 || plan.schoolStatuses[status].subjectId === plan.dayObjects[obj].rawDayObject.classId) {
                  const appliedStatus = settings.accountSettings.schoolStatuses.find((s: any) => s.schoolStatusId === plan.schoolStatuses[status].schoolStatusId)
                  if (appliedStatus) {
                    statusSection.items.push({ name: appliedStatus.statusText, color: appliedStatus.color });
                  }
                }
              }
            }
          }
          plan.stickers = (data.stickers || []).filter((sticker:any) => daysMap[day].date === sticker.date);
          if (userMode === 'S' && studentId > 0) {
            const schoolEvents: any = [];
            plan.dayObjects = plan.dayObjects.filter((o: any) => {
              if (studentTeacherId === 0 && o.type === 'E') {
                if (!schoolEvents.find((e: any) => e.eventId === o.rawDayObject.eventId && e.customEventId === o.rawDayObject.customEventId)) {
                  schoolEvents.push({ eventId: o.rawDayObject.eventId, customEventId: o.rawDayObject.customEventId });
                  return true;
                } else {
                  return false;
                }
              } else {
                return o.teacherId === studentTeacherId || studentTeacherId === 0
              }
            });
          }
        } else {
          plan.dayObjects = [];
        }
        if (plan.dayObjects.length > maxElements) {
          maxElements = plan.dayObjects.length;
        }
      }
      plans.dayCount = dayCount;
      plans.maxElements = maxElements;
      return plans;
    } else {
      return Plans.getEmptyPlans();
    }
  }

  extractSchoolStatus(id: any, statuses: any) {
    return statuses.find((s: any) => s.schoolStatusId === id)
  }

  get getCurrentCyclePlans() {
    const data = this.currentCycleData;
    if (data) {
      const settings = this.getCyclePlanSettings;
      const userMode = this.context.rootState.settings.userMode;
      const daysMap = LessonServices.createDays(data.days, settings, userMode);
      const studentId = this.context.rootState.settings.studentId;
      const studentTeacherId = this.context.rootState.settings.studentTeacherId;
      let maxElements = 0;
      let firstElement = false;
      let dayCount = 0;
      const plans: any = {};
      for (const day in daysMap) {
        dayCount++;
        const plan: any = plans[day] = {};
        plan.show = true;
        if (!firstElement && plan.show) {
          firstElement = true;
          plan.first = true;
        }
        if (daysMap[day]) {
          plan.text = daysMap[day].dayOfWeek + ' ' + DateTimeUtils.formatToShow(daysMap[day].date, settings);
          plan.date = daysMap[day].date;
          plan.name = daysMap[day].cycleDay;
          plan.count = daysMap[day].dayCount;
          plan.total = daysMap[day].totalCount;
          plan.gradingPeriodCount = daysMap[day].gradingPeriodCount;
          plan.weekCount = daysMap[day].weekCount;
          plan.weekTotal = daysMap[day].weekTotal;
          plan.dayObjects = daysMap[day].dayObjects || [];
          if (userMode === 'S' && studentId > 0) {
            plan.dayObjects = plan.dayObjects.filter((o: any) => o.teacherId === studentTeacherId || studentTeacherId === 0);
          }
        } else {
          plan.dayObjects = [];
        }
        if (plan.dayObjects.length > maxElements) {
          maxElements = plan.dayObjects.length;
        }
      }
      plans.dayCount = dayCount;
      plans.maxElements = maxElements;
      return plans;
    } else {
      return Plans.getEmptyPlans();
    }
  }

  get getMaxElements() {
    if (this.viewType === 'C') {
      return this.getCurrentCyclePlans.maxElements || 0;
    } else {
      return this.getCurrentPlans.maxElements || 0;
    }
  }

  get getCalendarMonth() {
    const planSettings = this.getMonthPlanSettings;
    const viewSettings = planSettings.viewSettings;
    const hideClassIds = CommonUtils.getOrDefaultTo(viewSettings.hideClassIds, []);
    const calendar: any = {
      start: moment(this.month, 'MM/DD/YYYY').startOf('month').format('YYYY-MM-DD'),
      end: moment(this.month, 'MM/DD/YYYY').endOf('month').format('YYYY-MM-DD'),
      events: [],
      weekdays: [0, 1, 2, 3, 4, 5, 6]
    };
    const eventStyling: any = this.context.rootGetters['settings/getEventStyling'];
    const isReduceBrightnessOfPastDays: boolean = this.context.rootGetters['settings/isReduceBrightnessOfPastDays'];
    const days = (this.currentMonthData || { days: [] }).days;
    if (CommonUtils.isNotEmpty(days)) {
      calendar.weekdays = this.getEnabledDayNums;
      calendar.events = [];
      calendar.days = {};
      days.forEach((day: any) => {
        const objects: any[] = day.objects;
        const isPast = DateTimeUtils.isPast(day.date);
        const daysKey = moment(day.date, 'MM/DD/YYYY').format('YYYYMMDD');
        calendar.days[daysKey] = day;
        objects.forEach((dayObject: any) => {
          const event: any = {};
          const startTime = dayObject.startTime;
          const endTime = dayObject.endTime;
          const allDay = CommonUtils.hasNoText(startTime) && CommonUtils.hasNoText(endTime);
          const title = dayObject.lessonTitle || dayObject.eventTitle || dayObject.evenTitle || '';
          const unitTitle = dayObject.unitNum || (dayObject.linkedUnit || {}).unitNum || '';
          let name = unitTitle;
          name += CommonUtils.hasText(unitTitle) && CommonUtils.hasText(title) ? ': ' : '';
          name += title;
          event.name = name;
          if (CommonUtils.hasText(startTime)) {
            event.start = DateTimeUtils.toTimestamp(`${day.date} ${startTime}`);
          } else if (CommonUtils.hasNoText(startTime) && dayObject.type === 'L') {
            event.start = DateTimeUtils.toTimestamp(`${day.date} 11:59 PM`);
          } else {
            event.start = moment(day.date, 'MM/DD/YYYY').format('YYYY-MM-DD');
          }
          if (CommonUtils.hasText(endTime)) {
            event.end = DateTimeUtils.toTimestamp(`${day.date} ${endTime}`);
          } else if (CommonUtils.hasNoText(startTime) && dayObject.type === 'L') {
            event.end = DateTimeUtils.toTimestamp(`${day.date} 11:59 PM`);
          } else {
            event.end = moment(day.date, 'MM/DD/YYYY').format('YYYY-MM-DD');
          }
          event.color = dayObject.color || dayObject.titleFillColor || (dayObject.type === 'E' ? eventStyling.eventLabelFillColor : dayObject.eventColor);
          if (isPast && isReduceBrightnessOfPastDays) event.color = CommonUtils.addAlpha(event.color, 0.4);
          event.timed = !allDay || !(CommonUtils.hasNoText(startTime) && dayObject.type === 'L');
          if (dayObject.type === 'L') {
            if (!hideClassIds.includes(dayObject.classId)) {
              calendar.events.push(event);
            }
          } else if (dayObject.type === 'E') {
            if (CommonUtils.hasText(dayObject.googleId)) {
              if (LessonServices.isDisplayLessonSection('googleEvents', viewSettings)) {
                calendar.events.push(event);
              }
            } else {
              if (LessonServices.isDisplayLessonSection('events', viewSettings)) {
                calendar.events.push(event);
              }
            }
          } else {
            calendar.events.push(event);
          }
        });
      });
    }
    return calendar;
  }

  get getCalendarMonthDays() {
    const days = (this.currentMonthData || { days: [] }).days;
    const userMode = this.context.rootState.settings.userMode;
    return LessonServices.createDays(days, this.getMonthPlanSettings, userMode);
  }

  get getCyclePlanSettings() {
    const userMode = this.context.rootState.settings.userMode;
    const settings = ld.cloneDeep(this.getPlanSettings);
    if (['A', 'S'].includes(userMode) && this.currentCycleData) {
      ld.set(settings, 'displaySettings.lessonSections.lessonLayouts', this.currentCycleData.lessonLayouts);
    }
    return settings;
  }

  get getMonthPlanSettings() {
    const userMode = this.context.rootState.settings.userMode;
    const settings = ld.cloneDeep(this.getPlanSettings);
    if (['A', 'S'].includes(userMode) && this.currentMonthData) {
      ld.set(settings, 'displaySettings.lessonSections.lessonLayouts', this.currentMonthData.lessonLayouts);
    }
    return settings;
  }

  get getCurrentPlanSettings() {
    const userMode = this.context.rootState.settings.userMode;
    const settings = ld.cloneDeep(this.getPlanSettings);
    if (['A', 'S'].includes(userMode) && this.currentData) {
      ld.set(settings, 'displaySettings.lessonSections.lessonLayouts', this.currentData.lessonLayouts);
    }
    return settings;
  }

  get getPlanSettings() {
    const userInfo = this.context.rootGetters['settings/getUserInfo'];
    const userMode = this.context.rootState.settings.userMode;
    const currentTeacherId = this.context.rootState.settings.currentTeacherId;
    const getSettingsByUserId = this.context.rootGetters['settings/getSettingsByUserId'];
    let settings = ld.cloneDeep(userInfo);
    if (userMode === 'A') {
      let statusSettings = userInfo.viewSettings.statusProfileSettings.statusSettings;
      statusSettings = statusSettings.filter((ss: any) => ss.teacherId === currentTeacherId);
      settings = ld.cloneDeep(getSettingsByUserId(currentTeacherId));
      const currentTeacherStatusSettings = settings?.viewSettings?.statusProfileSettings?.statusSettings
      if (currentTeacherStatusSettings) {
        const statusSettingsIds = statusSettings.map((s: any) => { return s.lessonStatusId })
        const currentTeacherStatusSettingsIds: any[] = [];
        currentTeacherStatusSettings.forEach((status: any) => {
          currentTeacherStatusSettingsIds.push(status.lessonStatusId);
          if (!(statusSettingsIds.includes(status.lessonStatusId))) {
            const newStatus = ld.cloneDeep(status);
            newStatus.view = true;
            statusSettings.push(newStatus);
          }
        });
        statusSettings = statusSettings.filter((status: any) => currentTeacherStatusSettingsIds.includes(status.lessonStatusId));
      }
      settings.accountSettings.schoolStatuses = this.context.rootGetters['settings/getSchoolStatuses'];
      let displayItems = '';
      const viewItems = ld.cloneDeep(settings?.viewSettings?.viewItems);
      if (viewItems) {
        ld.merge(viewItems, ld.pick(userInfo.viewSettings.viewItems, Object.keys(viewItems)));
        settings.viewSettings.viewItems = viewItems;
        console.log('VIEW ITEMS ORDER', viewItems)
        for (const value of Object.values(viewItems)) {
          displayItems += value;
        }
      }
      if (CommonUtils.hasText(displayItems)) {
        settings.viewSettings.displayItems = displayItems;
      } else {
        settings.viewSettings.displayItems = userInfo.viewSettings.displayItems;
      }
      settings.viewSettings.hideClassIds = userInfo.viewSettings.hideClassIds;
      settings.viewSettings.statusProfileSettings = {
        statusSettings: statusSettings,
        viewNoStatus: userInfo.viewSettings.statusProfileSettings?.viewNoStatus,
        viewNoSchoolStatus: userInfo.viewSettings.statusProfileSettings?.viewNoSchoolStatus,
        schoolStatusSettings: userInfo.viewSettings.statusProfileSettings?.schoolStatusSettings
      };
    } else if (userMode === 'S') {
      settings = ld.cloneDeep(settings);
      if (settings.viewSettings && CommonUtils.isNotEmpty(settings.viewSettings.viewItems)) {
        for (const section in settings.viewSettings.viewItems) {
          settings.viewSettings.viewItems[section] = 'Y';
        }
        settings.viewSettings.viewItems.myStandards = 'N';
        settings.viewSettings.viewItems.schoolStandards = 'N';
        settings.viewSettings.viewItems.sharedStandards = 'N';
        settings.viewSettings.viewItems.sectionTags = 'N';
        settings.viewSettings.viewItems.standardsId = this.context.rootState.settings.studentViewStandardsId;
        settings.viewSettings.viewItems.standardsDesc = this.context.rootState.settings.studentViewStandardsDesc;
        settings.viewSettings.viewItems.attachments = this.context.rootState.settings.studentViewAttachments;
      }
    }
    return settings;
  }

  get currentDisplayView() {
    return displayViewTypeMapping[this.viewType] || 'W';
  }

  get getCalendarDay() {
    const days = this.getCalendarMonthDays;
    for (const key in days) {
      const day = days[key];
      if (day && day.date === this.currentDay) {
        return day;
      }
    }
    return {};
  }

  get getSearchLessonArray() {
    return this.searchLessonArray;
  }

  get getSearchLessonString() {
    return this.seachLessonString;
  }

  get getMonday() {
    return this.monday;
  }
  // GETTERS: END

  // MUTATIONS: START
  @Mutation
  gotoDate(date: Date) {
    const weekdays = store.getters['plans/getEnabledDayNums'];
    let m = moment(date, 'MM/DD/YYYY');
    if (weekdays.length > 0) {
      while (!weekdays.includes(m.day())) {
        m = m.add(1, 'day');
      }
    } else {
      m = m.add(1, 'week');
    }
    this.currentDay = m.format('MM/DD/YYYY');
    const month = moment(this.month, 'MM/DD/YYYY');
    if (!m.isSame(month, 'month')) {
      this.month = m.clone().startOf('month').format('MM/DD/YYYY');
      this.haveMonth = false;
    }
    const monday = moment(this.monday, 'MM/DD/YYYY');
    if (!m.isSame(monday, 'week')) {
      this.monday = DateTimeUtils.startOfWeek(m.clone()).format('MM/DD/YYYY');
      this.haveWeeks = false;
    }

    const pages = (this.cycleSchedule || { pages: [] }).pages;
    let cyclePage = 1;
    for (const i in pages) {
      const page = pages[i];
      const end = moment(page.cycleEnd, 'MM/DD/YYYY');
      if (!m.isAfter(end)) {
        break;
      }
      cyclePage = +i;
    }
    this.haveCycle = this.cyclePage === cyclePage;
  }

  @Mutation
  setViewKey(viewKey: string) {
    this.viewKey = viewKey;
  }

  @Mutation
  setMonday(monday: string) {
    this.monday = monday;
  }

  @Mutation
  setMonth(month: string) {
    this.month = month;
  }

  @Mutation
  setCyclePage(cyclePage: number) {
    this.cyclePage = cyclePage;
  }

  @Mutation
  setResetOnly(resetOnly: boolean) {
    this.resetOnly = resetOnly;
  }

  @Mutation
  next(skipNoSchoolDays: boolean) {
    console.log('PLANS: next');
    const weekdays = store.getters['plans/getEnabledDayNums'];
    if (this.viewType === 'C') {
      this.cyclePage++;
      this.haveCycle = false;
    } else if (this.viewType === 'M') {
      this.month = DateTimeUtils.addMonths(this.month, 1);
      this.monday = DateTimeUtils.getFirstMonday(this.month);
      this.currentDay = DateTimeUtils.getFirstActiveStart(this.month, weekdays);
      this.haveWeeks = false;
      this.haveMonth = false;
    } else if (this.viewType === 'W') {
      this.monday = Plans.addWeek(this.monday, 1);
      const startOfWeek = DateTimeUtils.getStartOfWeek(this.monday);
      this.currentDay = DateTimeUtils.getFirstActiveStart(startOfWeek, weekdays);
      this.haveWeeks = false;
    } else {
      if (skipNoSchoolDays) {
        do {
          this.currentDay = DateTimeUtils.getNextDay(this.currentDay, weekdays);
          const monday = moment(this.monday, 'MM/DD/YYYY');
          const currentDay = moment(this.currentDay, 'MM/DD/YYYY');
          if (!currentDay.isSame(monday, 'week')) {
            this.monday = Plans.addWeek(this.monday, 1);
            this.haveWeeks = false;

            return;
          }
        } while (!Plans.validateCurrentDay(this.currentData, this.currentDay));
      } else {
        this.currentDay = DateTimeUtils.getNextDay(this.currentDay, weekdays);
        const monday = moment(this.monday, 'MM/DD/YYYY');
        const currentDay = moment(this.currentDay, 'MM/DD/YYYY');
        if (!currentDay.isSame(monday, 'week')) {
          this.monday = Plans.addWeek(this.monday, 1);
          this.haveWeeks = false;
        }
      }
    }
  }

  @Mutation
  prev(skipNoSchoolDays: boolean) {
    console.log('PLANS: prev');
    const weekdays = store.getters['plans/getEnabledDayNums'];
    if (this.viewType === 'C') {
      this.cyclePage--;
      this.haveCycle = false;
    } else if (this.viewType === 'M') {
      this.month = DateTimeUtils.addMonths(this.month, -1);
      const endOfMonth = moment(this.month, 'MM/DD/YYYY').endOf('month').format('MM/DD/YYYY');
      this.monday = DateTimeUtils.getFirstMonday(this.month);
      this.currentDay = DateTimeUtils.getLastActiveEnd(endOfMonth, weekdays);
      this.haveWeeks = false;
      this.haveMonth = false;
    } else if (this.viewType === 'W') {
      this.monday = Plans.addWeek(this.monday, -1);
      const endOfWeek = DateTimeUtils.getEndOfWeek(this.monday);
      this.currentDay = DateTimeUtils.getLastActiveEnd(endOfWeek, weekdays);
      this.haveWeeks = false;
    } else {
      if (skipNoSchoolDays) {
        do {
          this.currentDay = DateTimeUtils.getPrevDay(this.currentDay, weekdays);
          const monday = moment(this.monday, 'MM/DD/YYYY');
          const currentDay = moment(this.currentDay, 'MM/DD/YYYY');
          if (!currentDay.isSame(monday, 'week')) {
            this.monday = Plans.addWeek(this.monday, -1);
            this.haveWeeks = false;

            return;
          }
        } while (!Plans.validateCurrentDay(this.currentData, this.currentDay));
      } else {
        this.currentDay = DateTimeUtils.getPrevDay(this.currentDay, weekdays);
        const monday = moment(this.monday, 'MM/DD/YYYY');
        const currentDay = moment(this.currentDay, 'MM/DD/YYYY');
        if (!currentDay.isSame(monday, 'week')) {
          this.monday = Plans.addWeek(this.monday, -1);
          this.haveWeeks = false;
        }
      }
    }
  }

  @Mutation
  setAdminEvents(adminEvents: any) {
    this.adminEvents = adminEvents;
  }

  @Mutation
  setParentChange(parentChange: boolean) {
    this.parentChange = parentChange;
  }

  @Mutation
  initializePlans(data: any) {
    const settings = data.settings;
    const viewSettings = settings.viewSettings;
    const displayView = viewSettings.displayView;
    const view = UserSession.getCurrentUser()?.view as string || '';
    if (vuetify.framework.breakpoint.mobile) {
      this.viewType = 'D';
    } else {
      if (CommonUtils.hasNoText(this.viewType)) {
        if (CurrentUser.isStudent && CommonUtils.hasText(view)) {
          this.viewType = view;
        } else if (data.viewType) {
          this.viewType = data.viewType;
        } else {
          if (displayView === 'day') {
            this.viewType = 'D';
          } else if (displayView === 'month') {
            this.viewType = 'M';
          } else if (displayView === 'cycle') {
            this.viewType = 'C';
          } else if (displayView === 'list') {
            this.viewType = 'L';
          } else {
            this.viewType = 'W';
          }
        }
      }
    }
  }

  @Mutation
  setHaveWeeks(haveWeeks: boolean) {
    this.haveWeeks = haveWeeks;
  }

  @Mutation
  setHaveCycleSchedule(haveCycleSchedule: boolean) {
    this.haveCycleSchedule = haveCycleSchedule;
  }

  @Mutation
  setHaveList(haveList: boolean) {
    this.haveList = haveList;
  }

  @Mutation
  setHaveCycle(haveCycle: boolean) {
    this.haveCycle = haveCycle;
  }

  @Mutation
  setHaveMonth(haveMonth: boolean) {
    this.haveMonth = haveMonth;
  }

  @Mutation
  setShowDetails(showDetails: boolean) {
    this.showDetails = showDetails;
  }

  @Mutation
  setShowCalendarDay(showCalendarDay: boolean) {
    this.showCalendarDay = showCalendarDay;
  }

  @Mutation
  setCurrentData(currentData: any) {
    this.currentData = currentData;
  }

  @Mutation
  setCurrentCycleData(currentCycleData: any) {
    this.currentCycleData = currentCycleData;
  }

  @Mutation
  setCurrentMonthData(currentMonthData: boolean) {
    this.currentMonthData = currentMonthData;
  }

  @Mutation
  setCurrentListData(currentListData: any) {
    this.currentListData = currentListData;
  }

  @Mutation
  setCycleSchedule(cycleSchedule: any) {
    this.cycleSchedule = cycleSchedule;
  }

  @Mutation
  setCurrentDay(currentDay: string) {
    const weekdays = store.getters['plans/getEnabledDayNums'];
    let m;
    if (weekdays.length > 0) {
      m = moment(currentDay, 'MM/DD/YYYY');
      while (!weekdays.includes(m.day())) {
        m = m.add(1, 'day');
      }
      this.currentDay = m.format('MM/DD/YYYY');
    } else {
      this.currentDay = Plans.addWeek(this.monday, 1);
    }
  }

  @Mutation
  resetPlans() {
    this.haveList = false;
    this.haveMonth = false;
    this.haveWeeks = false;
    this.haveCycle = false;
  }

  @Mutation
  reset() {
    this.monday = '';
    this.viewType = '';
    this.showDetails = true;
    this.showCalendarDay = false;
    this.currentDay = '';
    this.adminEvents = [];
    this.currentCycleData = null;
    this.currentData = null;
    this.currentListData = null;
    this.currentMonthData = null;
    this.parentChange = false;
    this.haveList = false;
    this.haveMonth = false;
    this.haveWeeks = false;
    this.haveCycle = false;
    this.haveCycleSchedule = false;
    store.commit('lessonlists/resetLessonBanks');
    store.commit('lessonlists/resetLessonList');
  }

  @Mutation
  updateLessons(data: any) {
    const day = data.day || {};
    const objects = day.objects || [];
    objects.forEach((o: any) => {
      o.date = day.date;
    });
    if (this.currentData) {
      const days = this.currentData.days || [];
      days.every((d: any, i: number) => {
        if (day.date === d.date) {
          ld.set(this.currentData, 'days[' + i + '].objects', day.objects);
          return false;
        }
        return true;
      });
    }
    if (this.currentCycleData) {
      const days = this.currentCycleData.days || [];
      days.every((d: any, i: number) => {
        if (day.date === d.date) {
          ld.set(this.currentCycleData, 'days[' + i + '].objects', day.objects);
          return false;
        }
        return true;
      });
    }
    if (this.currentListData) {
      const lessons = this.currentListData.lessons || [];
      const map = Plans.getDayObjectsMap(objects);
      lessons.forEach((l: any, i: number) => {
        const o = map.get(Plans.getDayObjectKey(l));
        if (o) {
          ld.set(this.currentListData, 'lessons[' + i + ']', o);
        }
      });
    }
    if (CommonUtils.isNotEmpty(this.searchLessonArray)) {
      const searchLessons = this.searchLessonArray || [];
      const lessonMap = Plans.getDayObjectsMap(objects);
      searchLessons.forEach((l: any, i: number) => {
        let o = lessonMap.get(Plans.getDayObjectKey(l));
        if (o) {
          o.searchClassName = o.className;
          o.searchDate = o.date;
          o.searchDayOfWeek = o.dayOfWeek;
          o.searchColor = o.color;
          o.searchYear = l.searchYear;
          o.searchYearId = l.yearId;
          const list = [o];
          o = Plans.getSearchDataDayObjects({ searchList: list });
          ld.set(this.searchLessonArray, '[' + i + ']', o[0]);
        }
      });
    }
    if (CommonUtils.isNotEmpty(store.getters['reporting/standardLessonList'])) {
      const standardLessons = store.getters['reporting/standardLessonList'] || [];
      const standardMap = Plans.getDayObjectsMap(objects);
      standardLessons.forEach((l: any, i: number) => {
        l.classId = l.rawDayObject.classId;
        const o = standardMap.get(Plans.getDayObjectKey(l));
        if (o) {
          o.searchClassName = o.className;
          o.searchDate = o.date;
          o.searchDayOfWeek = o.dayOfWeek;
          o.searchColor = o.color;
          o.searchYear = l.searchYear || l.yearName;
          o.searchYearId = l.yearId;
          store.commit('reporting/updateStandardLesson', { updatedData: o, index: i }, { root: true });
        }
      });
    }
    if (CommonUtils.isNotEmpty(store.getters['lessonlists/getSelectedLessonsInLessonBanks'])) {
      const lessonBanksLessons = store.getters['lessonlists/getSelectedLessonsInLessonBanks'] || [];
      const lessonBanksMap = Plans.getDayObjectsMap(objects);
      lessonBanksLessons.forEach((l: any, i: number) => {
        const o = lessonBanksMap.get(Plans.getDayObjectKey(l));
        if (o) {
          if (!o.lessonNum) o.lessonNum = o.extraLesson;
          store.commit('lessonlists/updateLessonBanksLessons', { updatedData: o, index: i }, { root: true });
        }
      });
    }
    if (this.currentMonthData) {
      const days = this.currentMonthData.days || [];
      days.every((d: any, i: number) => {
        if (day.date === d.date) {
          ld.set(this.currentMonthData, 'days[' + i + '].objects', day.objects);
          return false;
        }
        return true;
      });
    }
  }

  @Mutation
  setSearchLessonArray(searchData: any) {
    this.searchLessonArray = searchData;
  }

  @Mutation
  setSearchLessonString(searchString: any) {
    this.seachLessonString = searchString;
  }

  @Mutation
  setRemindersFloatingIcon(val:string) {
    this.remindersFloatingButtonIcon = val;
  }
  // MUTATIONS: END

  // ACTIONS: START
  @Action({ rawError: true })
  async init() {
    console.log('PLANS: init');
    this.context.commit('setParentChange', false);
    this.context.commit('setAdminEvents', []);
    this.context.commit('initializePlans', {
      settings: this.context.rootGetters['settings/getSettings']
    });
    return Promise.resolve();
  }

  @Mutation
  setViewType(viewType: string) {
    const oldViewType = this.viewType;
    this.viewType = viewType;
    const monday = moment(this.monday, 'MM/DD/YYYY');
    const month = moment(this.month, 'MM/DD/YYYY');
    const currentDay = moment(this.currentDay, 'MM/DD/YYYY').clone();
    if (oldViewType !== viewType) {
      if (viewType === 'C') {
        const pages = (this.cycleSchedule || { pages: [] }).pages;
        for (const i in pages) {
          const page = pages[i];
          const end = moment(page.cycleEnd, 'MM/DD/YYYY');
          if (!currentDay.isAfter(end)) {
            this.cyclePage = +i;
            this.haveCycle = false;
            break;
          }
        }
      } else if (viewType === 'M') {
        if (!month.isSame(monday, 'month')) {
          this.month = monday.startOf('month').format('MM/DD/YYYY');
          this.haveMonth = false;
        }
      } else {
        if (!currentDay.isSame(monday, 'week')) {
          this.monday = DateTimeUtils.startOfWeek(currentDay).format('MM/DD/YYYY');
          this.haveWeeks = false;
        }
      }
    }
  }

  @Action({ rawError: true })
  async changeCyclePage(cyclePage: number) {
    if (this.cyclePage !== cyclePage) {
      this.context.commit('setCyclePage', cyclePage);
      this.context.commit('setHaveCycle', false);
      return this.context.dispatch('fetchPlans');
    }
    return Promise.resolve({});
  }

  @Action({ rawError: true })
  async changeViewType(viewType: string) {
    const changed = this.viewType !== viewType;
    this.context.commit('setViewType', viewType);
    if (this.viewType !== 'L') {
      await this.context.dispatch('fetchPlans');
      eventBus.$emit('checkAdminEventShift');
    }
    return Promise.resolve({ changed });
  }

  @Action({ rawError: true })
  async changeUserMode(userMode: any) {
    CommonUtils.showLoading();
    let currentTeacherId = PlanbookStorage.get('currentTeacherId') || 0;
    let currentYearId = PlanbookStorage.get('currentYearId', 'T' + currentTeacherId) || 0;
    this.context.commit('settings/setUserMode', userMode, { root: true });
    this.context.commit('classes/setHaveClass', false, { root: true });
    this.context.commit('students/setHasNotesStudents', false, { root: true });
    this.context.commit('settings/setHaveSettings', false, { root: true });
    this.context.commit('reporting/reset', {}, { root: true });
    this.context.commit('reporting/init', {}, { root: true });
    this.context.commit('setMonday', null);
    this.context.commit('setMonth', null);
    this.context.commit('integration/clearRosteredData', {}, { root: true });
    this.context.dispatch('integration/getAllRosteredItems', {}, { root: true });
    PlanbookStorage.set('userMode', userMode);
    if (userMode === 'A') {
      this.context.commit('settings/setCurrentTeacherId', currentTeacherId, { root: true });
      this.context.commit('settings/setCurrentYearId', currentYearId, { root: true });
      this.context.commit('setHaveList', false);
      this.context.commit('setHaveMonth', false);
      this.context.commit('setHaveWeeks', false);
      this.context.commit('setHaveCycle', false);
      this.context.commit('setHaveCycleSchedule', false);
      this.context.commit('setCurrentListData', null);
      this.context.commit('setCurrentMonthData', null);
      this.context.commit('setCurrentData', null);
      this.context.commit('setCurrentCycleData', null);
      this.context.commit('setCycleSchedule', null);
      await this.context.dispatch('teachers/loadTeachers', {}, { root: true });
      await this.context.dispatch('classes/loadClasses', {}, { root: true });
      await this.context.dispatch('init');
      await this.context.dispatch('loadPlans');
      currentTeacherId = +this.context.rootState.settings.currentTeacherId;
      currentYearId = +this.context.rootState.settings.currentYearId
      PlanbookStorage.set('currentTeacherId', currentTeacherId);
      PlanbookStorage.set('currentYearId', 'T' + currentTeacherId, currentYearId);
    } else {
      this.context.commit('settings/setCurrentTeacherId', 0, { root: true });
      this.context.commit('settings/setCurrentYearId', 0, { root: true });
      this.context.commit('setHaveList', false);
      this.context.commit('setHaveMonth', false);
      this.context.commit('setHaveWeeks', false);
      this.context.commit('setHaveCycle', false);
      this.context.commit('setHaveCycleSchedule', false);
      this.context.commit('setCurrentListData', null);
      this.context.commit('setCurrentMonthData', null);
      this.context.commit('setCurrentData', null);
      this.context.commit('setCurrentCycleData', null);
      this.context.commit('setCycleSchedule', null);
      await this.context.dispatch('settings/loadSettings', null, { root: true });
      await this.context.dispatch('classes/loadClasses', {}, { root: true });
      await this.context.dispatch('init');
      await this.context.dispatch('loadPlans');
    }
    CommonUtils.hideLoading();
    return Promise.resolve();
  }

  @Action({ rawError: true })
  async changePlans(param?: any) {
    CommonUtils.showLoading();
    if (this.context.rootState.settings.userMode === 'T') {
      this.context.commit('classwork/setHaveClasswork', false, { root: true });
      this.context.commit('classes/resetSpecialDays', null, { root: true });
      return this.context.dispatch('changeTerm', param);
    } else {
      const storedTeacherId = +PlanbookStorage.get('currentTeacherId');
      const storedYearId = PlanbookStorage.get('currentYearId', 'T' + param.teacherId);
      if (storedTeacherId !== +param.teacherId) {
        param.yearId = storedYearId ? +storedYearId : 0;
      }
      const teachers = this.context.rootGetters['teachers/getTeachersOnly'];
      let selected = { teacherId: param.teacherId, yearId: param.yearId };
      if (CommonUtils.isNotEmpty(teachers)) {
        let matchedTeacher;
        let firstTeacher;
        for (const t in teachers) {
          const teacher = teachers[t];
          if (!firstTeacher) {
            firstTeacher = teacher;
          }
          if (selected.teacherId === teacher.teacherId) {
            matchedTeacher = teacher;
            break;
          }
        }

        let firstYear;
        let matchedYear;
        const teacher = matchedTeacher || firstTeacher;
        const years = CommonUtils.getOrDefaultTo(teacher.years, []);
        if (CommonUtils.isNotEmpty(years)) {
          for (const y in years) {
            const year = years[y];
            if (!firstYear) {
              firstYear = year;
            }
            if (selected.yearId === year.yearId) {
              matchedYear = year;
              break;
            }
          }
        }
        const year = matchedYear || firstYear || { yearId: 0 };
        selected = {
          teacherId: teacher.teacherId,
          yearId: year.yearId
        }
        this.context.commit('settings/setCurrentTeacherId', +selected.teacherId, { root: true });
        this.context.commit('settings/setCurrentYearId', +selected.yearId, { root: true });
        PlanbookStorage.set('currentTeacherId', +param.teacherId);
        PlanbookStorage.set('currentYearId', 'T' + param.teacherId, +param.yearId);
      } else {
        this.context.commit('settings/setCurrentTeacherId', +param.teacherId, { root: true });
        this.context.commit('settings/setCurrentYearId', param.yearId || +param.yearId, { root: true });
        PlanbookStorage.set('currentTeacherId', +param.teacherId);
        PlanbookStorage.set('currentYearId', 'T' + param.teacherId, +param.yearId);
      }
      this.context.commit('classes/setHaveClass', false, { root: true });
      this.context.commit('classwork/setHaveClasswork', false, { root: true });
      this.context.commit('setHaveList', false);
      this.context.commit('setHaveMonth', false);
      this.context.commit('setHaveWeeks', false);
      this.context.commit('setHaveCycle', false);
      this.context.commit('setHaveCycleSchedule', false)
      this.context.commit('setCurrentListData', null);
      this.context.commit('setCurrentMonthData', null);
      this.context.commit('setCurrentData', null);
      this.context.commit('setCurrentCycleData', null);
      this.context.commit('setCycleSchedule', null);
      await this.context.dispatch('classes/loadClasses', {}, { root: true });
      const userMode = this.context.rootState.settings.userMode;
      const isCycleSchedule: boolean = this.context.rootGetters['settings/isCycleSchedule'];
      const isCurrentTeacherCycleSchedule: boolean = this.context.rootGetters['teachers/isCurrentTeacherCycleSchedule'];
      if ((isCycleSchedule && userMode === 'T') || (isCurrentTeacherCycleSchedule && userMode === 'A')) {
        await this.context.dispatch('loadCycleSchedule');
      }
      await this.context.dispatch('init');
      await this.context.dispatch('loadPlans');
      CommonUtils.hideLoading();
      return Promise.resolve();
    }
  }

  @Action({ rawError: true })
  async loadPlans() {
    try {
      const currentDate = moment().tz(moment.tz.guess());
      this.context.commit('setCurrentDay', currentDate.format('MM/DD/YYYY'));
      const haveClass = this.context.rootState.classes.haveClass;
      if (!haveClass) await this.context.dispatch('classes/loadClasses', { interval: 168 }, { root: true });
      const userMode = this.context.rootState.settings.userMode;
      const studentId = this.context.rootState.settings.studentId;
      let schoolYear = this.context.rootGetters['settings/getCurrentYear'];
      if (userMode === 'A') schoolYear = this.context.rootGetters['teachers/getCurrentAdminItem'].year;
      const monday = this.monday ? moment(this.monday, 'MM/DD/YYYY').tz(moment.tz.guess()) : DateTimeUtils.startOfWeek(currentDate.clone());
      const firstDay = moment(schoolYear.firstDay, 'MM/DD/YYYY').tz(moment.tz.guess()).startOf('week');
      const lastDay = moment(schoolYear.lastDay, 'MM/DD/YYYY').tz(moment.tz.guess()).endOf('week');
      let date = null;
      const studentClasses = studentId > 0 ? this.context.rootState.classes.studentClasses : this.context.rootState.classes.teacherClasses;
      if (userMode === 'S' && CommonUtils.isNotEmpty(studentClasses)) {
        let noClasses = true;
        const classDates = [];
        for (let i = 0; i < studentClasses.length; i++) {
          classDates.push(studentClasses[i].classStartDate);
          classDates.push(studentClasses[i].classEndDate);
          if (currentDate.isBetween(studentClasses[i].classStartDate, studentClasses[i].classEndDate, undefined, '[]')) {
            noClasses = false;
            break;
          }
        }
        let nearestDate = classDates[0];
        if (noClasses) {
          classDates.forEach(date => {
            const classDate = moment(date);
            const diff = Math.abs(classDate.diff(moment(currentDate), 'days'));
            if (diff < Math.abs(moment(nearestDate).diff(moment(currentDate), 'days'))) {
              nearestDate = date;
            }
          });
        }
        const studentDate = noClasses ? moment(nearestDate) : currentDate;
        this.context.commit('setCurrentDay', studentDate.format('MM/DD/YYYY'));
        this.context.commit('setMonday', DateTimeUtils.startOfWeek(studentDate).format('MM/DD/YYYY'));
        this.context.commit('setMonth', studentDate.startOf('month').format('MM/DD/YYYY'));
      } else if (currentDate.isBefore(firstDay) || currentDate.isAfter(lastDay)) {
        for (let i = firstDay.get('year'); i <= lastDay.get('year'); i++) {
          const dateWithYear = monday.clone().set('year', i);
          if (dateWithYear.isAfter(firstDay) && dateWithYear.isBefore(lastDay)) {
            date = DateTimeUtils.startOfWeek(monday.set('year', i));
            break;
          }
        }
        if (date == null) {
          date = DateTimeUtils.startOfWeek(firstDay.clone());
        }
        const currentDay = Plans.getCurrentDay(firstDay, lastDay, date);
        this.context.commit('setMonday', date.format('MM/DD/YYYY'));
        this.context.commit('setCurrentDay', currentDay.format('MM/DD/YYYY'));
        this.context.commit('setMonth', date.clone().startOf('month').format('MM/DD/YYYY'));
      } else {
        this.context.commit('setCurrentDay', currentDate.format('MM/DD/YYYY'));
        this.context.commit('setMonday', DateTimeUtils.startOfWeek(currentDate).format('MM/DD/YYYY'));
        this.context.commit('setMonth', currentDate.startOf('month').format('MM/DD/YYYY'));
      }
      if (this.haveCycleSchedule) {
        const pages = (this.cycleSchedule || { pages: [] }).pages;
        const currentDay = moment(this.currentDay, 'MM/DD/YYYY');
        let cyclePage = 1;
        let haveCycle = false;
        for (const i in pages) {
          const page = pages[i];
          const end = moment(page.cycleEnd, 'MM/DD/YYYY');
          if (!currentDay.isAfter(end)) {
            cyclePage = +i;
            haveCycle = false;
            break;
          }
        }
        this.context.commit('setCyclePage', cyclePage);
        this.context.commit('setHaveCycle', haveCycle);
      }
      await this.context.dispatch('fetchPlans');
      return Promise.resolve();
    } catch (e) {
      console.log(`Error calling loadPlans: ${e}`)
      return Promise.reject(new AppError('contactSupport', { data: { error: 'true', message: e } }));
    }
  }

  private static getCurrentDay(firstDay: moment.Moment, lastDay: moment.Moment, monday: moment.Moment) {
    let currentDay = moment().tz(moment.tz.guess());
    const currentDay1 = currentDay.clone().set('year', firstDay.get('year'));
    const currentDay2 = currentDay.clone().set('year', lastDay.get('year'));
    if (currentDay1.isBetween(firstDay, lastDay, undefined, '[]')) {
      currentDay = currentDay1;
    } else if (currentDay2.isBetween(firstDay, lastDay, undefined, '[]')) {
      currentDay = currentDay2;
    } else {
      currentDay = firstDay.clone();
    }
    if (currentDay.isBefore(monday)) {
      currentDay = monday.clone();
    }
    return currentDay;
  }

  @Action({ rawError: true })
  async changeTerm(params: any) {
    try {
      const resp = await SettingsServices.changeTerm({ yearId: params.yearId });
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          this.context.commit('settings/storeSettings', resp.data, { root: true });
          this.context.commit('settings/setCurrentSubjectId', 0, { root: true });
          this.context.commit('classes/setHaveClass', false, { root: true });
          this.context.commit('students/setHasNotesStudents', false, { root: true });
          this.context.commit('setCurrentListData', null);
          this.context.commit('setCurrentMonthData', null);
          this.context.commit('setCurrentData', null);
          this.context.commit('setHaveList', false);
          this.context.commit('setHaveMonth', false);
          this.context.commit('setHaveWeeks', false);
          this.context.commit('integration/clearRosteredData', {}, { root: true });
          await this.context.dispatch('classes/loadClasses', {}, { root: true });
          const isCycleSchedule: boolean = this.context.rootGetters['settings/isCycleSchedule'];
          const isCurrentTeacherCycleSchedule: boolean = this.context.rootGetters['teachers/isCurrentTeacherCycleSchedule'];
          if (isCycleSchedule || isCurrentTeacherCycleSchedule) {
            await this.context.dispatch('loadCycleSchedule');
          }
          await this.context.dispatch('loadPlans');
          this.context.dispatch('integration/getAllRosteredItems', {}, { root: true });
          CommonUtils.hideLoading();
          return Promise.resolve({ data });
        }
      } else {
        CommonUtils.hideLoading();
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      console.log(`Error calling changeTerm: ${e}`)
      return Promise.reject(new AppError('contactSupport', { data: { error: 'true', message: e } }));
    }
  }

  @Action({ rawError: true })
  async loadCycleSchedule() {
    try {
      const userMode = this.context.rootState.settings.userMode;
      const currentTeacherId = this.context.rootState.settings.currentTeacherId;
      const currentYearId = this.context.rootState.settings.currentYearId;
      const request = {};
      if (userMode === 'A') {
        ld.merge(request, {
          teacherId: currentTeacherId,
          yearId: currentYearId
        });
      }
      const resp = !this.haveCycleSchedule ? await LessonServices.getCycleSchedule(request) : { data: this.cycleSchedule };
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          this.context.commit('setHaveCycleSchedule', true)
          this.context.commit('setCycleSchedule', data);
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      console.log(`Error calling loadCycleSchedule: ${e}`)
      return Promise.reject(new AppError('contactSupport', { data: { error: 'true', message: e } }));
    }
  }

  @Action({ rawError: true })
  async loadCycleLessonsEvents() {
    try {
      const userMode = this.context.rootState.settings.userMode;
      const currentTeacherId = this.context.rootState.settings.currentTeacherId;
      const currentYearId = this.context.rootState.settings.currentYearId;
      const request = {
        cycleStart: '01/01/0001',
        cycleEnd: '01/01/0001'
      }
      if (userMode === 'A' && currentTeacherId) {
        ld.merge(request, {
          teacherId: currentTeacherId,
          yearId: currentYearId
        });
      }
      if (this.haveCycleSchedule) {
        ld.merge(request, this.cycleSchedule.pages[this.cyclePage]);
      }
      const resp = !this.haveCycle ? await LessonServices.getCycleLessonsEvents(request) : { data: this.currentCycleData };
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          this.context.commit('setHaveCycle', true)
          this.context.commit('setCurrentCycleData', data);
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      console.log(`Error calling loadCycleLessonsEvents: ${e}`)
      return Promise.reject(new AppError('contactSupport', { data: { error: 'true', message: e } }));
    }
  }

  @Action({ rawError: true })
  async reloadPlans(params?: any) {
    this.context.commit('resetPlans');
    return Promise.resolve().then(() => {
      if (!this.resetOnly) {
        const showLoading = params ? params.showLoading : undefined;
        return this.context.dispatch('fetchPlans', showLoading);
      }
      return Promise.resolve();
    });
  }

  @Action({ rawError: true })
  async fetchPlans(showLoading?: boolean) {
    if (showLoading === true || showLoading === undefined) {
      CommonUtils.showLoading();
    }
    return Promise.resolve().then(async () => {
      if (this.viewType === 'C') {
        if (!this.currentCycleData || !this.haveCycle) {
          return this.context.dispatch('loadCycleLessonsEvents');
        }
      } else if (this.viewType === 'L') {
        if (!this.currentListData || !this.haveList) {
          return this.context.dispatch('loadClassLessons');
        }
      } else if (this.viewType === 'M') {
        if (!this.currentMonthData || !this.haveMonth) {
          return this.context.dispatch('loadMonthLessonsEvents');
        }
      } else if (!this.currentData || !this.haveWeeks) {
        return this.context.dispatch('loadLessonsEvents');
      }
      return Promise.resolve();
    }).finally(() => {
      if (showLoading === true || showLoading === undefined) {
        CommonUtils.hideLoading();
      }
    });
  }

  @Action({ rawError: true })
  async nextLessonsEvents(input: any) {
    let skipNoSchoolDays = false;
    CommonUtils.showLoading();
    if (input && Object.prototype.hasOwnProperty.call(input, 'skipNoSchoolDays')) {
      skipNoSchoolDays = input.skipNoSchoolDays;
    }
    this.context.commit('next', skipNoSchoolDays);
    eventBus.$emit('changeWeek');
    await this.context.dispatch('fetchPlans');
  }

  @Action({ rawError: true })
  async prevLessonsEvents(input: any) {
    let skipNoSchoolDays = false;
    CommonUtils.showLoading();
    if (input && Object.prototype.hasOwnProperty.call(input, 'skipNoSchoolDays')) {
      skipNoSchoolDays = input.skipNoSchoolDays;
    }
    this.context.commit('prev', skipNoSchoolDays);
    eventBus.$emit('changeWeek');
    await this.context.dispatch('fetchPlans');
  }

  @Action({ rawError: true })
  async gotoLessonsEvents(date: Date) {
    CommonUtils.showLoading();
    this.context.commit('gotoDate', date);
    eventBus.$emit('changeWeek');
    await this.context.dispatch('fetchPlans');
  }

  @Action({ rawError: true })
  async loadClassLessons(params?: any) {
    try {
      const userMode = this.context.rootState.settings.userMode;
      const studentTeacherId = this.context.rootState.settings.studentTeacherId;
      const currentTeacherId = this.context.rootState.settings.currentTeacherId;
      const studentId = this.context.rootState.settings.studentId;
      const studentKey = this.context.rootState.settings.studentKey;
      const currentYearId = this.context.rootState.settings.currentYearId;
      const currentSubjectId = this.context.rootState.settings.currentSubjectId;
      const request = {
        userMode,
        lessonView: 'list',
        fetchClassUnits: true,
        allLessons: true,
        classId: currentSubjectId
      };
      if (userMode === 'A' && currentTeacherId) {
        ld.merge(request, {
          teacherId: currentTeacherId,
          yearId: currentYearId
        });
      } else if (userMode === 'S') {
        if (studentId > 0) {
          ld.merge(request, {
            teacherId: studentTeacherId || 0,
            fetchDetails: true
          });
        } else {
          ld.merge(request, {
            teacherId: currentTeacherId || 0,
            viewKey: studentKey,
            yearId: currentYearId,
            fetchDetails: true
          });
        }
      }
      if (params && userMode !== 'S') ld.merge(request, params);
      let resp: any;
      if (request.classId > 0) {
        if (userMode === 'S') {
          if (studentId > 0) {
            resp = await LessonServices.getStudentClassLessons(request);
          } else {
            resp = await LessonServices.getViewClassLessons(request);
          }
        } else {
          resp = await LessonServices.getClassLessons(request);
        }
      }

      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          this.context.commit('setHaveList', true);
          this.context.commit('setCurrentListData', data);
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      console.log(`Error calling loadLessonsEvents: ${e}`)
      return Promise.reject(new AppError('contactSupport', { data: { error: 'true', message: e } }));
    }
  }

  @Action({ rawError: true })
  async loadMonthLessonsEvents() {
    try {
      const userMode = this.context.rootState.settings.userMode;
      const studentId = this.context.rootState.settings.studentId;
      const studentKey = this.context.rootState.settings.studentKey;
      const studentTeacherId = this.context.rootState.settings.teacherId;
      const currentSubjectId = this.context.rootState.settings.currentSubjectId;
      const currentTeacherId = this.context.rootState.settings.currentTeacherId;
      const currentYearId = this.context.rootState.settings.currentYearId;
      const userInfo = this.context.rootGetters['settings/getUserInfo'];
      const request = {
        monday: this.month,
        userMode: userMode,
        lessonView: 'month'
      };
      if (userMode === 'A' && currentTeacherId) {
        ld.merge(request, {
          teacherId: currentTeacherId,
          yearId: currentYearId,
          getSettings: true
        });
      } else if (userMode === 'S') {
        if (studentId > 0) {
          ld.merge(request, {
            teacherId: 0,
            studentKey: 0,
            yearId: 0,
            subjectId: 0,
            studentId: 0,
            viewType: 'M'
          });
        } else {
          ld.merge(request, {
            teacherId: studentTeacherId,
            studentKey: studentKey,
            yearId: currentYearId,
            subjectId: currentSubjectId,
            viewType: 'M'
          });
        }
      }
      let resp: any = {};
      if (userMode === 'S') {
        resp = await LessonServices.getStudentLessonsEvents(request);
      } else {
        resp = await LessonServices.getMonthLessonsEvents(request);
      }
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          let settings = userInfo;
          const getSettingsByUserId = this.context.rootGetters['settings/getSettingsByUserId'];
          if (userMode === 'A') {
            data.currentYearId = currentYearId;
            data.userId = currentTeacherId;
            this.context.commit('settings/storeSettings', data, { root: true });
            let statusSettings = userInfo.viewSettings.statusProfileSettings.statusSettings;
            statusSettings = statusSettings.filter((ss: any) => ss.teacherId === currentTeacherId);
            settings = ld.cloneDeep(getSettingsByUserId(data.userId));
            settings.viewSettings.displayItems = userInfo.viewSettings.displayItems;
            settings.viewSettings.viewItems = userInfo.viewSettings.viewItems;
            settings.viewSettings.hideClassIds = userInfo.viewSettings.hideClassIds;
            settings.viewSettings.statusProfileSettings = {
              statusSettings: statusSettings,
              viewNoStatus: userInfo.viewSettings.statusProfileSettings.viewNoStatus,
              viewNoSchoolStatus: userInfo.viewSettings.statusProfileSettings.viewNoSchoolStatus,
              schoolStatusSettings: userInfo.viewSettings.statusProfileSettings.schoolStatusSettings
            };
            this.context.commit('settings/setCurrentStatuses', settings.accountSettings.statuses, { root: true });
            this.context.commit('initializePlans', {
              settings: settings,
              viewType: this.viewType
            });
          } else if (userMode === 'S') {
            if (studentId > 0) {
              data.userId = studentId;
              this.context.commit('settings/setStudentTeachers', data.studentTeachers, { root: true });
            }
            this.context.commit('settings/storeSettings', data, { root: true });
          }
          this.context.commit('setHaveMonth', true);
          this.context.commit('setCurrentMonthData', data);
          this.context.commit('setParentChange', data.parentChange);
          this.context.commit('setAdminEvents', CommonUtils.getOrDefaultTo(data.events));
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      console.log(`Error calling loadLessonsEvents: ${e}`)
      return Promise.reject(new AppError('contactSupport', { data: { error: 'true', message: e } }));
    }
  }

  @Action({ rawError: true })
  async loadLessonsEvents() {
    try {
      const userMode = this.context.rootState.settings.userMode;
      const studentId = this.context.rootState.settings.studentId;
      const studentKey = this.context.rootState.settings.studentKey;
      const studentTeacherId = this.context.rootState.settings.teacherId;
      const currentSubjectId = this.context.rootState.settings.currentSubjectId;
      const currentTeacherId = this.context.rootState.settings.currentTeacherId;
      const currentYearId = this.context.rootState.settings.currentYearId;
      const userInfo = this.context.rootGetters['settings/getUserInfo'];
      const request = {
        monday: this.monday,
        userMode: userMode,
        fetchWeekSize: 1
      };
      if (userMode === 'A' && currentTeacherId) {
        ld.merge(request, {
          teacherId: currentTeacherId,
          yearId: currentYearId
        });
      } else if (userMode === 'S') {
        if (studentId > 0) {
          ld.merge(request, {
            teacherId: 0,
            studentKey: 0,
            yearId: 0,
            subjectId: 0,
            studentId: CommonUtils.get(studentId, 0),
            viewType: 'W'
          });
        } else {
          ld.merge(request, {
            teacherId: CommonUtils.get(studentTeacherId, 0),
            studentKey: studentKey,
            yearId: CommonUtils.get(currentYearId, 0),
            subjectId: CommonUtils.get(currentSubjectId, 0),
            viewType: 'W'
          });
        }
      }
      let resp: any = {};
      if (userMode === 'S') {
        resp = await LessonServices.getStudentLessonsEvents(request);
      } else {
        resp = await LessonServices.getLessonsEvents(request);
      }
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          let settings = userInfo;
          const getSettingsByUserId = this.context.rootGetters['settings/getSettingsByUserId'];
          if (userMode === 'A') {
            data.currentYearId = currentYearId;
            this.context.commit('settings/storeSettings', data, { root: true });
            let statusSettings = userInfo.viewSettings.statusProfileSettings.statusSettings;
            statusSettings = statusSettings.filter((ss: any) => ss.teacherId === currentTeacherId);
            settings = ld.cloneDeep(getSettingsByUserId(data.userId));
            settings.viewSettings.displayItems = userInfo.viewSettings.displayItems;
            settings.viewSettings.viewItems = userInfo.viewSettings.viewItems;
            settings.viewSettings.hideClassIds = userInfo.viewSettings.hideClassIds;
            settings.viewSettings.statusProfileSettings = {
              statusSettings: statusSettings,
              viewNoStatus: userInfo.viewSettings.statusProfileSettings.viewNoStatus,
              viewNoSchoolStatus: userInfo.viewSettings.statusProfileSettings.viewNoSchoolStatus,
              schoolStatusSettings: userInfo.viewSettings.statusProfileSettings.schoolStatusSettings
            };
            this.context.commit('settings/setCurrentStatuses', settings.accountSettings.statuses, { root: true });
            this.context.commit('initializePlans', {
              settings: settings,
              viewType: this.viewType
            });
          } else if (userMode === 'S') {
            if (studentId > 0) {
              data.userId = studentId;
              this.context.commit('settings/setStudentTeachers', data.studentTeachers, { root: true });
            }
            this.context.commit('settings/storeSettings', data, { root: true });
          }
          this.context.commit('setCurrentData', data);
          this.context.commit('setHaveWeeks', true);
          this.context.commit('setParentChange', data.parentChange);
          this.context.commit('setAdminEvents', CommonUtils.getOrDefaultTo(data.events));
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      console.log(`Error calling loadLessonsEvents: ${e}`)
      return Promise.reject(new AppError('contactSupport', { data: { error: 'true', message: e } }));
    }
  }

  @Action({ rawError: true })
  async selectLessonSection(value: any) {
    const viewLessonSectionItems = this.context.rootGetters['settings/getLessonSectionViewItems'] || [];
    const allValues = (viewLessonSectionItems[0] || []).map((item: any) => item.value).sort();
    const allLinkedValues = (viewLessonSectionItems[1] || []).map((item: any) => item.value).sort();
    const allOtherValues = (viewLessonSectionItems[2] || []).map((item: any) => item.value).sort();
    const rawSelectedItems = this.getSelectedLessonSectionItems.filter((s: any) => !['all', 'none', 'allLinked', 'noneLinked', 'allOther', 'noneOther'].includes(s) && this.viewItemValues.includes(s));
    const rawSelectedLinkedItems = this.getSelectedLessonSectionItems.filter((s: any) => !['all', 'none', 'allLinked', 'noneLinked', 'allOther', 'noneOther'].includes(s) && this.linkedItemValues.includes(s));
    const rawSelectedOtherItems = this.getSelectedLessonSectionItems.filter((s: any) => !['all', 'none', 'allLinked', 'noneLinked', 'allOther', 'noneOther'].includes(s) && this.otherViewItemValues.includes(s));
    let selectedItems = ld.clone(this.getSelectedLessonSectionItems.filter((s: any) => this.viewItemValues.includes(s)) || []);
    let selectedLinkedItems = ld.clone(this.getSelectedLessonSectionItems.filter((s: any) => this.linkedItemValues.includes(s)) || []);
    let selectedOtherItems = ld.clone(this.getSelectedLessonSectionItems.filter((s: any) => this.otherViewItemValues.includes(s)) || []);
    if (value === 'all' && !selectedItems.includes('all')) {
      selectedItems = ['all'].concat(allValues);
    } else if (value === 'none' && !selectedItems.includes('none')) {
      selectedItems = ['none'];
    } else if (value === 'allLinked' && !selectedLinkedItems.includes('allLinked')) {
      selectedLinkedItems = ['allLinked'].concat(allLinkedValues);
    } else if (value === 'noneLinked' && !selectedLinkedItems.includes('noneLinked')) {
      selectedLinkedItems = ['noneLinked'];
    } else if (value === 'allOther' && !selectedOtherItems.includes('allOther')) {
      selectedOtherItems = ['allOther'].concat(allOtherValues);
    } else if (value === 'noneOther' && !selectedOtherItems.includes('noneOther')) {
      selectedOtherItems = ['noneOther'];
    } else {
      if (rawSelectedItems.includes(value)) {
        selectedItems = rawSelectedItems.filter((s: any) => s !== value);
      } else if (rawSelectedLinkedItems.includes(value)) {
        selectedLinkedItems = rawSelectedLinkedItems.filter((s: any) => s !== value);
      } else if (rawSelectedOtherItems.includes(value)) {
        selectedOtherItems = rawSelectedOtherItems.filter((s: any) => s !== value);
      } else {
        if (this.otherViewItemValues.includes(value)) {
          selectedOtherItems = rawSelectedOtherItems.concat([value]);
        } else if (this.linkedItemValues.includes(value)) {
          selectedLinkedItems = rawSelectedLinkedItems.concat([value]);
        } else {
          selectedItems = rawSelectedItems.concat([value]);
        }
      }
      selectedItems.sort();
      selectedOtherItems.sort();
      selectedLinkedItems.sort();
      if (CommonUtils.isEmpty(selectedItems)) {
        selectedItems = ['none'];
      } else if (ld.isEqual(selectedItems, allValues)) {
        selectedItems = ['all'].concat(allValues);
      }
      if (CommonUtils.isEmpty(selectedLinkedItems)) {
        selectedLinkedItems = ['noneLinked'];
      } else if (ld.isEqual(selectedLinkedItems, allValues)) {
        selectedLinkedItems = ['allLinked'].concat(allValues);
      }
      if (CommonUtils.isEmpty(selectedOtherItems)) {
        selectedOtherItems = ['noneOther'];
      } else if (ld.isEqual(selectedOtherItems, allValues)) {
        selectedOtherItems = ['allOther'].concat(allValues);
      }
    }
    selectedItems = selectedItems.filter((s: any) => !['all', 'none'].includes(s));
    selectedLinkedItems = selectedLinkedItems.filter((s: any) => !['allLinked', 'noneLinked'].includes(s));
    selectedOtherItems = selectedOtherItems.filter((s: any) => !['allOther', 'noneOther'].includes(s));
    const combinedItems = selectedItems.concat(selectedLinkedItems).concat(selectedOtherItems).sort();
    this.context.commit('settings/setDisplayViewItems', combinedItems, { root: true });
    return Promise.resolve();
  }

  @Action({ rawError: true })
  async selectSchoolStatus(value: any) {
    const viewStatusItems = this.context.rootGetters['settings/getViewSchoolStatusItems'];
    const allValues = viewStatusItems.map((item: any) => item.value).sort();
    const rawSelectedItems = this.getSelectedSchoolStatusItems.filter((s: any) => !['all', 'none'].includes(s));
    let selectedItems = ld.clone(this.getSelectedSchoolStatusItems || []);
    if (value === 'all' && !selectedItems.includes('all')) {
      selectedItems = ['all'].concat(allValues);
    } else if (value === 'none' && !selectedItems.includes('none')) {
      selectedItems = ['none'];
    } else {
      if (rawSelectedItems.includes(value)) {
        selectedItems = rawSelectedItems.filter((s: any) => s !== value);
      } else {
        selectedItems = rawSelectedItems.concat([value]);
      }
      selectedItems.sort();
      if (CommonUtils.isEmpty(selectedItems)) {
        selectedItems = ['none']
      } else if (ld.isEqual(selectedItems, allValues)) {
        selectedItems.push('all');
      }
    }
    selectedItems = selectedItems.filter((s: any) => !['all', 'none'].includes(s));
    this.context.commit('settings/setSelectedViewSchoolStatusItems', selectedItems, { root: true });
    return Promise.resolve();
  }

  @Action({ rawError: true })
  async selectStatus(value: any) {
    const viewStatusItems = this.context.rootGetters['settings/getViewStatusItems'];
    const allValues = viewStatusItems.map((item: any) => item.value).sort();
    const rawSelectedItems = this.getSelectedStatusItems.filter((s: any) => !['all', 'none'].includes(s));
    let selectedItems = ld.clone(this.getSelectedStatusItems || []);
    if (value === 'all' && !selectedItems.includes('all')) {
      selectedItems = ['all'].concat(allValues);
    } else if (value === 'none' && !selectedItems.includes('none')) {
      selectedItems = ['none', 0];
    } else {
      if (rawSelectedItems.includes(value)) {
        selectedItems = rawSelectedItems.filter((s: any) => s !== value);
      } else {
        selectedItems = rawSelectedItems.concat([value]);
      }
      selectedItems.sort();
      if (CommonUtils.isEmpty(selectedItems)) {
        selectedItems = ['none']
      } else if (ld.isEqual(selectedItems, allValues)) {
        selectedItems.push('all');
      }
    }
    selectedItems = selectedItems.filter((s: any) => !['all', 'none'].includes(s));
    this.context.commit('settings/setSelectedViewStatusItems', selectedItems, { root: true });
    return Promise.resolve();
  }

  @Action({ rawError: true })
  async selectClass(value: any) {
    const viewClassItems = this.context.rootGetters['classes/getClassItems'];
    const allValues = viewClassItems.map((item: any) => item.value).sort();
    const rawSelectedItems = this.getSelectedClassItems.filter((s: any) => !['all', 'none'].includes(s));
    let selectedItems = ld.clone(this.getSelectedClassItems || []);
    if (value === 'all' && !selectedItems.includes('all')) {
      selectedItems = ['all'].concat(allValues);
    } else if (value === 'none' && !selectedItems.includes('none')) {
      selectedItems = ['none'];
    } else {
      if (rawSelectedItems.includes(value)) {
        selectedItems = rawSelectedItems.filter((s: any) => s !== value);
      } else {
        selectedItems = rawSelectedItems.concat([value]);
      }
      selectedItems.sort();
      if (CommonUtils.isEmpty(selectedItems)) {
        selectedItems = ['none']
      } else if (ld.isEqual(selectedItems, allValues)) {
        selectedItems.push('all');
      }
    }
    selectedItems = selectedItems.filter((s: any) => !['all', 'none'].includes(s));
    selectedItems = allValues.filter((c: any) => !selectedItems.includes(c));
    this.context.commit('settings/setHideClassIds', selectedItems, { root: true });
  }

  @Action({ rawError: true })
  async getSearchedLessons(params?: any) {
    try {
      params = params || {};
      const resp = await LessonServices.getSearchedLessons(params);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          console.log('SEARCH LESSON EXPIRED');
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          const dataObjects = Plans.getSearchDataDayObjects(data);
          return Promise.resolve({ dataObjects });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }
  // ACTIONS: END

  // HELPER FUNCTIONS: START
  private static getDayObjectKey(o: any): string {
    return o.classId + '-' + o.date + '-' + o.extraLesson;
  }

  private static getDayObjectsMap(objects: any[]): Map<string, any> {
    const map: Map<string, any> = new Map();
    const dayObjects = objects || [];
    dayObjects.forEach((o: any) => {
      map.set(Plans.getDayObjectKey(o), o);
    });
    return map;
  }

  private static getEmptyPlans() {
    return {
      dayCount: 7,
      day1: {
        enabled: false,
        visible: false,
        text: '',
        name: '',
        date: '',
        count: 0,
        col: 0
      },
      day2: {
        enabled: true,
        visible: true,
        text: '',
        name: '',
        date: '',
        count: 0,
        col: 0
      },
      day3: {
        enabled: true,
        visible: true,
        text: '',
        name: '',
        date: '',
        count: 0,
        col: 0
      },
      day4: {
        enabled: true,
        visible: true,
        text: '',
        name: '',
        date: '',
        count: 0,
        col: 0
      },
      day5: {
        enabled: true,
        visible: true,
        text: '',
        name: '',
        date: '',
        count: 0,
        col: 0
      },
      day6: {
        enabled: true,
        visible: true,
        text: '',
        name: '',
        date: '',
        count: 0,
        col: 0
      },
      day7: {
        enabled: false,
        visible: false,
        text: '',
        name: '',
        date: '',
        count: 0,
        col: 0
      }
    }
  }

  private static validateCurrentDay(currentData: any, currentDay: any) {
    let isValid = true;
    const currentDataMatch = currentData.days.find((day: any) => day.dayOfWeek === moment(currentDay, 'MM/DD/YYYY').format('dddd'));

    if (currentDataMatch !== undefined) {
      isValid = currentDataMatch.count !== -1;
    }

    return isValid;
  }

  private static addWeek(date: string, week: number) {
    return moment(date, 'MM/DD/YYYY').add(week, 'weeks').format('MM/DD/YYYY');
  }

  private static getSearchDataDayObjects(data:any) {
    const classLessons = {
      objects: data.searchList.map((lesson:any) => {
        lesson.type = 'L';
        lesson.date = lesson.searchDate;
        lesson.dayOfWeek = lesson.searchDayOfWeek;
        lesson.className = lesson.searchClassName;
        lesson.yearName = lesson.searchYear;
        lesson.color = lesson.searchColor;
        lesson.yearId = lesson.searchYearId;
        return lesson;
      })
    }

    const settings = store.getters['plans/getPlanSettings'];
    const userMode = store.getters['settings/getUserMode'];
    let dataObjects = LessonServices.createDay(classLessons, 'search', {
      settings: settings,
      lessonSections: LessonServices.createLessonSections(settings),
      eventSections: LessonServices.createEventSections(settings),
      googleClassSections: LessonServices.createGoogleClassroomSections(),
      lessonHeadings: settings.displaySettings.lessonHeadings,
      statuses: settings.accountSettings.statuses,
      eventStyling: settings.displaySettings.eventStyling,
      otherSettings: settings.otherSettings,
      userMode: userMode,
      sharedClasses: settings.sharingOptionsSettings.sharedClasses,
      viewSettings: settings.viewSettings
    }).dayObjects || [];
    dataObjects = dataObjects.map((dayObject: any) => {
      const lesson = dayObject.rawDayObject;
      delete dayObject.rawDayObject;
      lesson.dayObject = dayObject;
      lesson.dayObject.sections.forEach((section:any) => {
        section.hide = 'N';
      });
      return lesson;
    });
    return dataObjects;
  }
  // HELPER FUNCTIONS: END
}

export default Plans;
