var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "validation-observer",
    { ref: "observer" },
    [
      _vm.type === "date"
        ? _c(
            "v-menu",
            {
              attrs: {
                "offset-y": "",
                transition: "scale-transition",
                "min-width": "auto",
                "close-on-content-click": false,
                origin: _vm.origin,
                disabled: _vm.disabled || _vm.readonly,
                "nudge-left": _vm.shift + "px"
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "v-card",
                          {
                            ref: "card",
                            staticClass: "date-time-range-field d-flex",
                            class: {
                              "is-focused": _vm.localFocused,
                              "has-error": _vm.hasError,
                              dense: _vm.dense,
                              disabled: _vm.disabled,
                              "rounded-pill": _vm.pill
                            },
                            attrs: { outlined: "" }
                          },
                          [
                            _vm.localFocused || _vm.hasStart || _vm.hasEnd
                              ? _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !_vm.pill,
                                        expression: "!pill"
                                      }
                                    ],
                                    staticClass: "title-on-border v-label"
                                  },
                                  [_vm._v(_vm._s(_vm.localLabel))]
                                )
                              : _vm._e(),
                            _c(
                              "validation-provider",
                              {
                                attrs: {
                                  name: "startField",
                                  rules:
                                    "validDateTime" +
                                    _vm.localRefreshKey +
                                    ":@endField"
                                }
                              },
                              [
                                _c("v-text-field", {
                                  staticClass: "input d-inline-block",
                                  attrs: {
                                    "hide-details": "auto",
                                    dense: "",
                                    disabled: _vm.disabled,
                                    readonly: _vm.readonly,
                                    outlined: false,
                                    placeholder: _vm.localStartLabel,
                                    "aria-label":
                                      _vm.localStartLabel + " " + _vm.id,
                                    "aria-required": _vm.required
                                  },
                                  on: {
                                    focus: function($event) {
                                      return _vm.onFocus("localStart", $event)
                                    },
                                    blur: function($event) {
                                      return _vm.onBlur("localStart", $event)
                                    },
                                    keyup: function($event) {
                                      _vm.localMenu = false
                                    },
                                    click: function($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.onClick("localStart", $event)
                                    },
                                    change: _vm.setLocalStartFromDisplay
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "prepend-inner",
                                        fn: function() {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass:
                                                      "focus-icon-border",
                                                    attrs: {
                                                      small: _vm.pill,
                                                      "aria-label":
                                                        _vm.$t("selectLabel") +
                                                        " " +
                                                        _vm.localStartLabel
                                                    }
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v("fal fa-calendar-days")]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: _vm.localStartForDisplay,
                                    callback: function($$v) {
                                      _vm.localStartForDisplay = $$v
                                    },
                                    expression: "localStartForDisplay"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "mt-1 spacer d-inline-block",
                                attrs: { align: "left" }
                              },
                              [_vm._v(" - ")]
                            ),
                            _c(
                              "validation-provider",
                              { attrs: { name: "endField" } },
                              [
                                _c("v-text-field", {
                                  staticClass: "input d-inline-block",
                                  attrs: {
                                    "hide-details": "auto",
                                    dense: "",
                                    disabled: _vm.disabled,
                                    readonly: _vm.readonly,
                                    outlined: false,
                                    placeholder: _vm.localEndLabel,
                                    "aria-label":
                                      _vm.localEndLabel + " " + _vm.id,
                                    "aria-required": _vm.required
                                  },
                                  on: {
                                    focus: function($event) {
                                      return _vm.onFocus("localEnd", $event)
                                    },
                                    blur: function($event) {
                                      return _vm.onBlur("localEnd", $event)
                                    },
                                    keyup: function($event) {
                                      _vm.localMenu = false
                                    },
                                    click: function($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.onClick("localEnd", $event)
                                    },
                                    change: _vm.setLocalEndFromDisplay
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "prepend-inner",
                                        fn: function() {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass:
                                                      "focus-icon-border",
                                                    attrs: {
                                                      small: _vm.pill,
                                                      "aria-label":
                                                        _vm.$t("selectLabel") +
                                                        " " +
                                                        _vm.localEndLabel
                                                    }
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v("fal fa-calendar-days")]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: _vm.localEndForDisplay,
                                    callback: function($$v) {
                                      _vm.localEndForDisplay = $$v
                                    },
                                    expression: "localEndForDisplay"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showLocalMessage,
                                expression: "showLocalMessage"
                              }
                            ],
                            staticStyle: {
                              height: "20px",
                              "margin-top": "4px",
                              color: "#b04122",
                              "font-size": "12px",
                              padding: "0px 12px"
                            }
                          },
                          [_vm._v(_vm._s(_vm.localMessage))]
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                1159996954
              ),
              model: {
                value: _vm.localMenu,
                callback: function($$v) {
                  _vm.localMenu = $$v
                },
                expression: "localMenu"
              }
            },
            [
              _c("v-date-picker", {
                ref: "datePicker",
                attrs: {
                  disabled: _vm.readonly || _vm.disabled,
                  locale: _vm.getGlobalLanguage
                },
                on: { change: _vm.onChange },
                model: {
                  value: _vm.localPickerValue,
                  callback: function($$v) {
                    _vm.localPickerValue = $$v
                  },
                  expression: "localPickerValue"
                }
              })
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "v-card",
                {
                  ref: "card",
                  staticClass: "date-time-range-field d-flex",
                  class: {
                    "is-focused": _vm.localFocused,
                    "has-error": _vm.hasError,
                    dense: _vm.dense,
                    disabled: _vm.disabled,
                    "rounded-pill": _vm.pill
                  },
                  attrs: { outlined: "" }
                },
                [
                  _vm.localFocused || _vm.hasStart || _vm.hasEnd
                    ? _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.pill,
                              expression: "!pill"
                            }
                          ],
                          staticClass: "title-on-border v-label"
                        },
                        [_vm._v(_vm._s(_vm.localLabel))]
                      )
                    : _vm._e(),
                  _c(
                    "validation-provider",
                    {
                      attrs: {
                        name: "startField",
                        rules:
                          "validDateTime" + _vm.localRefreshKey + ":@endField"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          on: {
                            mouseenter: _vm.hoverEnterTimepickerStart,
                            mouseleave: _vm.hoverExitTimepickerStart
                          }
                        },
                        [
                          _c("vue-timepicker", {
                            class: _vm.$vuetify.theme.dark
                              ? "dark-time-picker"
                              : "",
                            attrs: {
                              "auto-scroll": "",
                              "manual-input": "",
                              format: _vm.vueTimepickerFormat,
                              placeholder: _vm.localStartLabel,
                              disabled: _vm.disabled || _vm.readonly,
                              "hour-range":
                                _vm.min !== "" && _vm.max !== ""
                                  ? _vm.startTimeHourRange
                                  : undefined,
                              "minute-range":
                                _vm.min !== "" && _vm.max !== ""
                                  ? _vm.startTimeMinuteRange
                                  : undefined,
                              "drop-direction": _vm.offsetTop ? "up" : "down"
                            },
                            on: {
                              open: function($event) {
                                return _vm.onFocus("localStart", $event)
                              },
                              close: function($event) {
                                return _vm.onBlur("localStart", $event)
                              },
                              error: _vm.startTimeErrorHandler
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "icon",
                                fn: function() {
                                  return [
                                    _c(
                                      "v-icon",
                                      {
                                        style: {
                                          color:
                                            _vm.$vuetify.theme.dark &&
                                            _vm.disabled
                                              ? "gray"
                                              : "unset"
                                        }
                                      },
                                      [_vm._v("fal fa-clock")]
                                    )
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "clearButton",
                                fn: function() {
                                  return [
                                    _c(
                                      "v-fade-transition",
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !_vm.hideStartTimeClearButton,
                                                expression:
                                                  "!hideStartTimeClearButton"
                                              }
                                            ]
                                          },
                                          [_vm._v("fas fa-circle-xmark")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ]),
                            model: {
                              value: _vm.localStart,
                              callback: function($$v) {
                                _vm.localStart = $$v
                              },
                              expression: "localStart"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "mt-1 spacer d-inline-block",
                      style: {
                        color:
                          _vm.$vuetify.theme.dark && _vm.disabled
                            ? "gray"
                            : "unset"
                      },
                      attrs: { align: "center" }
                    },
                    [_vm._v(" - ")]
                  ),
                  _c("validation-provider", { attrs: { name: "endField" } }, [
                    _c(
                      "div",
                      {
                        on: {
                          mouseenter: _vm.hoverEnterTimepickerEnd,
                          mouseleave: _vm.hoverExitTimepickerEnd
                        }
                      },
                      [
                        _c("vue-timepicker", {
                          class: _vm.$vuetify.theme.dark
                            ? "dark-time-picker"
                            : "",
                          attrs: {
                            "auto-scroll": "",
                            "manual-input": "",
                            format: _vm.vueTimepickerFormat,
                            placeholder: _vm.localEndLabel,
                            disabled: _vm.disabled || _vm.readonly,
                            "hour-range":
                              _vm.min !== "" && _vm.max !== ""
                                ? _vm.endTimeHourRange
                                : undefined,
                            "minute-range":
                              _vm.min !== "" && _vm.max !== ""
                                ? _vm.endTimeMinuteRange
                                : undefined,
                            "drop-direction": _vm.offsetTop ? "up" : "down"
                          },
                          on: {
                            open: function($event) {
                              return _vm.onFocus("localEnd", $event)
                            },
                            close: function($event) {
                              return _vm.onBlur("localEnd", $event)
                            },
                            error: _vm.endTimeErrorHandler
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "icon",
                              fn: function() {
                                return [
                                  _c(
                                    "v-icon",
                                    {
                                      style: {
                                        color:
                                          _vm.$vuetify.theme.dark &&
                                          _vm.disabled
                                            ? "gray"
                                            : "unset"
                                      }
                                    },
                                    [_vm._v("fal fa-clock")]
                                  )
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "clearButton",
                              fn: function() {
                                return [
                                  _c(
                                    "v-fade-transition",
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !_vm.hideEndTimeClearButton,
                                              expression:
                                                "!hideEndTimeClearButton"
                                            }
                                          ]
                                        },
                                        [_vm._v("fas fa-circle-xmark")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ]),
                          model: {
                            value: _vm.localEnd,
                            callback: function($$v) {
                              _vm.localEnd = $$v
                            },
                            expression: "localEnd"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showLocalMessage,
                      expression: "showLocalMessage"
                    }
                  ],
                  staticStyle: {
                    height: "20px",
                    "margin-top": "4px",
                    color: "#b04122",
                    "font-size": "12px",
                    padding: "0px 12px",
                    "text-align": "start"
                  }
                },
                [_vm._v(_vm._s(_vm.localMessage))]
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }