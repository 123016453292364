import AppError, { createErrOptions, SessionExpiredError } from '@/errors';
import FileServices from '@/services/file-services';
import ld from 'lodash';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true })
export default class Files extends VuexModule {
    haveFiles = false;
    files: Array<any> = [];
    allTeacherFiles: Array<any> = [];
    haveAllTeacherFiles = false;

    @Mutation
    setFiles(files: Array<any>) {
      this.files = files;
    }

    @Mutation
    setHaveFiles(haveFiles: boolean) {
      this.haveFiles = haveFiles;
    }

    @Mutation
    setAllTeacherFiles(allTeacherFiles: Array<any>) {
      this.allTeacherFiles = allTeacherFiles;
    }

    @Mutation
    setHaveAllTeacherFiles(haveAllTeacherFiles: boolean) {
      this.haveAllTeacherFiles = haveAllTeacherFiles;
    }

    @Mutation
    clearAllTeacherFiles() {
      this.allTeacherFiles = [];
    }

    @Action({ rawError: true })
    async loadMyFiles(params?: any) {
      try {
        if (!this.haveFiles) {
          const request = { teacherId: this.context.rootGetters['settings/getUserId'] };
          if (params) {
            ld.merge(request, params)
          }
          const resp = await FileServices.getMyFiles(request);
          if (resp) {
            const data = resp.data;
            if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
              return Promise.reject(new SessionExpiredError());
            } else if (data.error === 'true' || data.error === true) {
              return Promise.reject(new AppError('contactSupport', { data }));
            } else {
              this.context.commit('setHaveFiles', true);
              this.context.commit('setFiles', resp.data);
              return Promise.resolve({ data });
            }
          } else {
            return Promise.resolve({ data: {} });
          }
        } else {
          return Promise.resolve({ data: this.files });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async getAllTeacherFiles(params?: any) {
      try {
        if (!this.haveAllTeacherFiles) {
          const request = { teacherId: this.context.rootGetters['settings/getUserId'] };
          if (params) {
            ld.merge(request, params)
          }
          const resp = await FileServices.getMyFiles(request);
          if (resp) {
            const data = resp.data;
            if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
              return Promise.reject(new SessionExpiredError());
            } else if (data.error === 'true' || data.error === true) {
              return Promise.reject(new AppError('contactSupport', { data }));
            } else {
              this.context.commit('setAllTeacherFiles', resp.data);
              this.context.commit('setHaveAllTeacherFiles', true);
              return Promise.resolve({ data });
            }
          } else {
            return Promise.resolve({ data: {} });
          }
        } else {
          return Promise.resolve({ data: this.allTeacherFiles });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async getAllFilesInsideFolder(params?: any) {
      try {
        const request = { teacherId: this.context.rootGetters['settings/getUserId'] };
        if (params) {
          ld.merge(request, params)
        }
        const resp = await FileServices.getMyFiles(request);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }
}
