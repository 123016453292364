
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import NotesTodoEditorForm from '@/components/reminders/NotesTodoEditorForm.vue';
import { namespace } from 'vuex-class';
import CommonUtils from '@/utils/common-utils';
import { Editor } from '@/types/global.types';
import { FormError } from '@/errors';

const notestodo = namespace('notestodo');

@Component({
  components: {
    NotesTodoEditorForm
  }
})
export default class NotesTodoEditor extends Vue implements Editor {
  @Prop({ type: Boolean })
  value!: boolean;

  @Prop({ type: Boolean, default: true })
  isModal!: boolean;

  @Prop({
    required: true,
    type: Object,
    default: () => {
      return {};
    }
  })
  input!: any;

  $refs!: {
    form: NotesTodoEditorForm
  }

  @notestodo.State
  listLoading!: any;

  notesToDoDialogTitle: any = '';

  @Watch('value')
  @Watch('input')
  doInit(v:any) {
    if (v) {
      if (this.input.addWhat === 'TD') {
        this.notesToDoDialogTitle = this.$t('addTodoDropdownLabel');
      } else if (this.input.addWhat === 'NT') {
        this.notesToDoDialogTitle = this.$t('addNotesDropdownLabel');
      } else if (this.input.editWhat === 'TD') {
        this.notesToDoDialogTitle = this.$t('editToDoDialogTitle');
      } else if (this.input.editWhat === 'NT') {
        this.notesToDoDialogTitle = this.$t('editNotesDialogTitle');
      }
    }
  }

  get localValue() {
    return this.value;
  }

  set localValue(val: boolean) {
    this.$emit('input', val);
  }

  get localPage() {
    return CommonUtils.getPage(this.$route);
  }

  async doApply() {
    return this.$refs.form.applyNotesToDos()
      .then(() => {
        this.$eventBus.$emit('refreshReminders');
        return Promise.resolve();
      })
      .catch((error) => {
        if (!(error instanceof FormError)) {
          return Promise.reject(error);
        } else {
          return Promise.resolve(true);
        }
      })
      .finally(() => {
        if (this.localPage !== 'notes-todo') {
          this.localValue = false;
          CommonUtils.hideLoading();
        }
        return Promise.resolve(false);
      });
  }

  isDirty() {
    return this.$refs.form && this.$refs.form.isDirty();
  }

  typeChange() {
    this.input.loadData = false;
  }
}
