













































































































import CommonUtils from '@/utils/common-utils';
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class FolderPickerTable extends Vue {
  @Prop({
    required: true,
    type: Object,
    default: () => {
      return {};
    }
  })
  moveToCurrentOpenedFolder!: any;

  @Prop({
    required: true,
    type: Array,
    default: []
  })
  folders!: any[];

 @Prop({ required: true, type: Boolean, default: false })
 isTableLoading!: boolean;

  @Prop({
    required: true,
    type: Array,
    default: []
  })
  moveToFolder!: any[];

  @Prop({
    required: true,
    type: Array,
    default: []
  })
  moveToOpenedFolder!: any[];

  @Prop({ type: Boolean })
  value!: boolean;

  @Prop({ type: Boolean })
  hasSelectedRows!: boolean;

  @Prop({ type: Boolean })
  moveToIsAddingFolder!: boolean;

  @Prop({ type: String })
  moveToNewFolderName!: string;

  @Prop({ required: false, type: Boolean, default: false })
  uploadMode!: boolean;

  get localValue() {
    return this.value;
  }

  set localValue(val: boolean) {
    this.$emit('input', val);
  }

  get localMoveToFolder() {
    return this.moveToFolder;
  }

  set localMoveToFolder(val:any[]) {
    this.$emit('update:moveToFolder', val);
  }

  get localMoveToOpenedFolder() {
    return this.moveToOpenedFolder;
  }

  set localMoveToOpenedFolder(val:any[]) {
    this.$emit('update:moveToOpenedFolder', val);
  }

  get isMoveToOpenedFolderNotEmpty() {
    return CommonUtils.isNotEmpty(this.localMoveToOpenedFolder);
  }

  get localMoveToIsAddingFolder() {
    return this.moveToIsAddingFolder;
  }

  set localMoveToIsAddingFolder(val:boolean) {
    this.$emit('update:moveToIsAddingFolder', val);
  }

  get localMoveToNewFolderName() {
    return this.moveToNewFolderName;
  }

  set localMoveToNewFolderName(val:string) {
    this.$emit('update:moveToNewFolderName', val);
  }

  get isMoveToFolderEmpty() {
    return CommonUtils.isEmpty(this.localMoveToFolder);
  }

  openMoveToFolder(item:any) {
    this.$emit('openMoveToFolder', item);
  }

  moveToCreateNewFolder() {
    this.$emit('moveToCreateNewFolder');
  }

  doMove() {
    this.$emit('doMove');
  }
}
