
import { ActionsModal } from '@/types/global.types';
import CommonUtils from '@/utils/common-utils';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Confirm from '../core/Confirm.vue';
import moment from 'moment';

const settings = namespace('settings');

@Component
export default class ClassActions extends Vue implements ActionsModal {
  actions: Array<any> = [
    {
      id: 'delete',
      icon: 'fal fa-trash-alt',
      label: 'deleteLabel'
    },
    {
      id: 'export',
      icon: 'fal fa-file-export',
      label: 'exportLessonsLabel'
    },
    {
      id: 'manageLessons',
      icon: 'fal fa-book-open',
      label: 'manageLessonsLabel'
    },
    {
      id: 'unlink',
      icon: 'fal fa-link-slash',
      label: 'unlinkLessonsLabel'
    },
    {
      id: 'importCsv',
      icon: 'fal fa-upload',
      label: 'importCSVLabel'
    }
  ];

  @Prop({
    required: true,
    type: Object,
    default: () => {
      return { loadData: true, data: {}, exclude: [] };
    }
  })
  input!: any;

  @Prop({
    required: false,
    type: String
  })
  color!: ''

  emit(eventName: string) {
    this.$emit(eventName);
  }

  showMenu = false;
  x = 0;
  y = 0;

  @Prop({ required: false, type: String, default: 'absolute' })
  type!: string;

  $refs!: {
      confirm: Confirm
  }

  @settings.Getter
  getCurrentYear!: any;

  get mCurrentDate() {
    return moment();
  }

  get mCurrentYearLastDay() {
    return moment(this.getCurrentYear.lastDay, 'MM/DD/YYYY');
  }

  get filteredActions() {
    return this.actions.filter(a => {
      if (this.mCurrentDate.isAfter(this.mCurrentYearLastDay) && a.id !== 'export') {
        return false;
      } else if (a.id === 'export' && (this.input.data.action === 'A' || this.input.selectedClass?.noStudents)) {
        return false;
      } else if (a.id === 'manageLessons' && (this.input.data.action === 'A' || this.input.selectedClass?.noStudents)) {
        return false;
      } else if (a.id === 'unlink' && (this.input.data.action === 'A' || this.input.selectedClass?.noStudents)) {
        return false;
      } else if (a.id === 'delete' && this.input.data.action === 'A') {
        return false;
      } else if (a.id === 'importCsv' && (this.input.data.action === 'A' || this.input.selectedClass?.noStudents)) {
        return false;
      }
      return true;
    });
  }

  async doAction(action: any) {
    CommonUtils.showLoading();
    return Promise.resolve().then(() => {
      if (action.id === 'delete') {
        this.$eventBus.$emit('doAction', 'delete');
      } else if (action.id === 'export') {
        this.$eventBus.$emit('doAction', 'export');
      } else if (action.id === 'manageLessons') {
        this.$eventBus.$emit('doAction', 'manageLessons');
      } else if (action.id === 'unlink') {
        this.$eventBus.$emit('doAction', 'unlink');
      } else if (action.id === 'importCsv') {
        this.$eventBus.$emit('openSubPage', {
          type: 'csv',
          width: 600,
          modal: true,
          input: {
            type: this.$t('lessonLabel'),
            importWhat: 'lessons',
            CSVclassId: this.input.selectedClass.classId || this.input.selectedClass.cId
          }
        });
      }
    }).finally(() => {
      this.showMenu = false;
      CommonUtils.hideLoading();
    });
  }

  public show(e: MouseEvent) {
    e.preventDefault()
    this.showMenu = false;
    this.x = e.clientX;
    this.y = e.clientY;
    this.$nextTick(() => {
      new Promise(resolve => setTimeout(resolve, 10)).then(() => {
        this.showMenu = true;
      });
    })
  }
}
