
import { Component, Prop, Vue } from 'vue-property-decorator';
import Confirm from '../core/Confirm.vue';

@Component
export default class BaseEditor extends Vue {
  @Prop({ type: Boolean })
  value!: boolean;

  @Prop({ type: Boolean })
  isModal!: boolean;

  @Prop({ required: false, type: [Object, Array], default: () => { return {} } })
  input!: any;

  @Prop({ required: false, type: Boolean, default: false })
  applying!: boolean;

  @Prop({ required: false, type: Boolean, default: true })
  withActions!: boolean;

  @Prop({ required: false, type: Boolean, default: true })
  withApply!: boolean;

  @Prop({ required: false, type: [String, Object], default: '' })
  cardClass!: any;

  @Prop({ required: false, type: Boolean, default: true })
  expandable!: boolean;

  @Prop({ required: false, type: Boolean, default: true })
  isEditable!: boolean;

  @Prop({ required: false, type: Function, default: () => { return Promise.resolve(true) } })
  onApply!: () => Promise<boolean>;

  @Prop({ required: false, type: Function, default: () => { return () => { return Promise.resolve(true) } } })
  onActions!: () => Promise<boolean>;

  @Prop({ required: true, type: Function })
  isDirty!: () => boolean;

  @Prop({ required: false, type: Boolean, default: true })
  isSubpage!: () => boolean;

  @Prop({ required: true, type: String })
  typeLabel!: string

  $refs!: {
    confirm: Confirm
  }

  get localValue() {
    return this.value;
  }

  set localValue(val: boolean) {
    this.$emit('input', val);
  }

  get fullScreen() {
    return this.$store.state.index.fullScreen;
  }

  set fullScreen(val: boolean) {
    this.$store.commit('index/setFullScreen', val, { root: true });
  }

  doOnClose() {
    if (this.isDirty() && this.isEditable) {
      return this.$refs.confirm.confirm({
        title: this.typeLabel,
        text: this.$t('confirmAbandonUnsavedChangesGenericMsg'),
        option1ButtonAlternativeText: this.$t('saveChangesLabel'),
        cancelButtonText: this.$t('discardChangesLabel')
      }).then((result) => {
        if (result === 1) {
          return this.onApply();
        } else {
          this.fullScreen = false;
          this.$emit('close');
          return Promise.resolve(false);
        }
      });
    } else {
      this.fullScreen = false;
      this.$emit('close');
      return Promise.resolve(false);
    }
  }
}

