
import { infiniteScrollDelay } from '@/constants';
import CommonUtils from '@/utils/common-utils';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const settings = namespace('settings');
@Component
export default class EditorSectionList extends Vue {
  @Prop({
    required: true,
    type: Array,
    default: () => {
      return [];
    }
  })
  items!: Array<any>

  @Prop({ type: Boolean, required: false, default: false })
  disabled!: boolean;

  @Prop({ type: Boolean, required: false, default: true })
  showDelete!: boolean;

  @Prop({ type: Boolean, required: false, default: false })
  hideStudentDelete!: boolean;

  @Prop({ required: false, type: Boolean, default: true })
  showShortValue!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  infiniteScroll!:boolean;

  @Prop({ required: false, type: Boolean, default: false })
  useVueHTMLShortValue!: boolean;

  @Prop({
    required: false,
    type: Object,
    default: () => {
      return {};
    }
  })
  rowStyle!: any;

  @Prop({ required: false, type: String, default: '' })
  rowClass!: any;

  @Prop({ required: false, type: String, default: '' })
  label!: any;

  $refs!: {
    sentinel: any,
    scrollContainer:any
  };

  @settings.Getter('getBrowserHeight')
  browserHeight!: number;

  isLoadingMore = false;
  canLoadMore = true;
  initialLoad = true;
  infiniteScrollList:any[] = [];
  pageNumber = 0;
  pageCount = 0;
  listEndObserver:any = undefined;
  tableHeight = 350;

  get removeLabel() {
    if (CommonUtils.hasText(this.label)) {
      return this.$t('removeItemLabel', { text: this.label });
    } else {
      return this.$t('removeDefaultLabel');
    }
  }

  get hasText() {
    return CommonUtils.hasText;
  }

  get htmlEscape() {
    return CommonUtils.htmlEscape;
  }

  get localItems():Array<any> {
    return this.infiniteScroll ? this.infiniteScrollList : this.filteredItems;
  }

  get allowAllDataToLoad() {
    const isMacOS = /macintosh|mac os x/i.test(navigator.userAgent);
    return !isMacOS && this.browserHeight < 700;
  }

  get filteredItems() {
    const tempItems:any[] = [];
    this.items.forEach((data:any) => {
      if (!tempItems.some(list => list.key === data.key)) {
        tempItems.push(data);
      }
    });
    return tempItems;
  }

  getRowClass(item: any) {
    let c = this.rowClass || '';
    if (item.noExpressions) {
      c += ' no-expressions';
    }
    return c;
  }

  mounted() {
    setTimeout(() => {
      this.onItemsChange();
    }, 1000);
  }

  @Watch('items')
  onItemsChange() {
    if (this.infiniteScroll) {
      this.initializeInfiniteScroll();
    }
  }

  initializeInfiniteScroll() {
    const that = this;
    this.resetInfiniteScrollData();
    if (this.infiniteScroll && CommonUtils.isNotEmpty(that.filteredItems)) {
      const scrollContainer = that.$refs.scrollContainer;
      const tableRowHeight = 48;

      const itemsPerScreen = (Math.round(scrollContainer.clientHeight / tableRowHeight));
      this.tableHeight = scrollContainer.clientHeight;
      that.pageCount = Math.round(that.filteredItems.length / itemsPerScreen);
      that.pageNumber = 0;
      that.setInfiniteScrollInitialData();
    } else if (this.infiniteScroll && CommonUtils.isEmpty(that.filteredItems)) {
      that.initialLoad = false;
      that.canLoadMore = false;
    }
  }

  setInfiniteScrollInitialData() {
    const that = this;
    this.fetchMoreItems().then((items:any) => {
      items.forEach((data:any) => {
        if (!that.infiniteScrollList.some(list => list.key === data.key)) {
          that.infiniteScrollList.push(data);
        }
      });
      that.initialLoad = false;
      // wait for initial list to render and then set up observer
      that.$nextTick(() => {
        that.setUpInterSectionObserver();
      })
    });
  }

  setUpInterSectionObserver() {
    const options = {
      root: this.$refs.scrollContainer,
      rootMargin: '0px 0px 0px 0px'
    };
    this.listEndObserver = new IntersectionObserver(
      this.handleIntersection,
      options
    );

    this.listEndObserver.observe(this.$refs.sentinel);
  }

  handleIntersection([entry]:any) {
    if (entry.isIntersecting) {
      console.log('sentinel intersecting');
    }
    if (entry.isIntersecting && this.canLoadMore && !this.isLoadingMore) {
      this.loadMore();
    }
  }

  async loadMore() {
    try {
      const that = this;
      this.isLoadingMore = true;
      const items:any = await this.fetchMoreItems();
      console.log('loaded page ' + this.pageNumber);
      this.pageNumber++;
      items.forEach((data:any) => {
        if (!that.infiniteScrollList.some(list => list.key === data.key)) {
          that.infiniteScrollList.push(data);
        }
      });
    } catch (error) {
      console.log('Reached end of page');
      console.log('total items in the page ' + this.infiniteScrollList.length);
      this.canLoadMore = false;
    } finally {
      this.isLoadingMore = false;
    }
  }

  fetchMoreItems() {
    const that = this;
    const scrollContainer = that.$refs.scrollContainer;
    const tableRowHeight = 48;

    const itemsPerScreen = (Math.round(scrollContainer.clientHeight / tableRowHeight));
    return new Promise((resolve, reject) => {
      const data:any = [];
      let indexOfLastData = 0;
      if (CommonUtils.isNotEmpty(that.infiniteScrollList)) {
        indexOfLastData = that.filteredItems.findIndex((l:any) => {
          return l.key === that.infiniteScrollList[that.infiniteScrollList.length - 1].key
        });
        indexOfLastData++;
      }
      if (((that.filteredItems.length - indexOfLastData) < itemsPerScreen)) {
        for (let index = indexOfLastData; index <= that.filteredItems.length - 1; index++) {
          data.push(that.filteredItems[index]);
        }
      } else {
        for (let i = 1; i <= itemsPerScreen; i++) {
          data.push(that.filteredItems[indexOfLastData]);
          indexOfLastData++;
        }
      }

      setTimeout(() => {
        if (CommonUtils.isNotEmpty(data)) {
          resolve(data);
        } else {
          reject();
        }
      }, infiniteScrollDelay);
    });
  }

  resetInfiniteScrollData() {
    this.infiniteScrollList = [];

    if (this.listEndObserver) {
      this.listEndObserver.disconnect();
    }

    this.isLoadingMore = false;
    this.canLoadMore = true;

    this.initialLoad = true;
    this.pageNumber = 0;
    this.pageCount = 0;
    this.listEndObserver = undefined;
  }
}
