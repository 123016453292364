var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-chip",
    _vm._g(
      _vm._b(
        {
          staticClass: "focus-border",
          attrs: { tabindex: "0", role: "button" },
          scopedSlots: _vm._u(
            [
              _vm._l(_vm.$scopedSlots, function (_, scopedSlotName) {
                return {
                  key: scopedSlotName,
                  fn: function (slotData) {
                    return [_vm._t(scopedSlotName, null, null, slotData)]
                  },
                }
              }),
              _vm._l(_vm.$slots, function (_, slotName) {
                return {
                  key: slotName,
                  fn: function () {
                    return [_vm._t(slotName)]
                  },
                  proxy: true,
                }
              }),
            ],
            null,
            true
          ),
        },
        "v-chip",
        { ..._vm.$attrs },
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }