
import { UserSession } from '@/common/user-session';
import RemindersServices from '@/services/reminders-services';
import CommonUtils from '@/utils/common-utils';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const settings = namespace('settings');
const reminders = namespace('reminders');

@Component
export default class StudentNotes extends Vue {
  @Prop({ type: Boolean })
  value!: boolean;

  @settings.Getter('getStudentNotes')
  settingsStudentNotes!: string;

  @reminders.Action
  updateStudentNotes!: (params: any) => Promise<any>;

  localStudentNotes = '';
  localShowActions = false;

  get localValue() {
    return this.value;
  }

  set localValue(val: boolean) {
    this.$emit('input', val);
  }

  get studentNotes() {
    return this.localStudentNotes || this.settingsStudentNotes;
  }

  set studentNotes(val: string) {
    this.localStudentNotes = val;
  }

  applyStudentNotes() {
    CommonUtils.showLoading();
    this.updateStudentNotes({ studentNotes: this.studentNotes }).then(() => {
      this.localStudentNotes = '';
      this.localValue = false;
    }).finally(() => {
      CommonUtils.hideLoading();
    });
  }

  deleteStudentNotes() {
    CommonUtils.showLoading();
    this.updateStudentNotes({ studentNotes: '' }).then(() => {
      this.localStudentNotes = '';
      this.localValue = false;
    }).finally(() => {
      CommonUtils.hideLoading();
    });
  }

  printStudentNotes() {
    CommonUtils.showLoading();
    RemindersServices.createPDFNotes([
      { name: 'printNotesType', value: 'student' },
      { name: 'printTeacherNotesText', value: this.settingsStudentNotes },
      { name: 'X-PB-ACCESS-TOKEN', value: UserSession.getAccessToken() },
      { name: 'X-PB-CLIENT-YEAR-ID', value: UserSession.getCurrentYearId() }
    ]).finally(() => {
      CommonUtils.hideLoading();
    });
  }
}
