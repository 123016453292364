import qs from 'qs';
import axios from '@/axios';

export default class StudentServices {
  public static getStudentsForNotes(params?: any) {
    params = params || {};
    return axios.post('services/planbook/student/getAllFromSchool', qs.stringify(params, {
      arrayFormat: 'repeat'
    }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static getStudentsTagged(params?: any) {
    params = params || {};
    return axios.get('services/planbook/student/studentsTagged', { params });
  }

  public static async addStudent(params?: any) {
    params = params || {};
    return axios.post('addStudentServlet', qs.stringify(params, {
      arrayFormat: 'repeat'
    }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static async uploadStudents(params?: any) {
    const formData = new FormData();
    for (const key in params) {
      formData.append(key, params[key]);
    }
    return axios.post('uploadStudents', formData);
  }

  public static async updateStudent(params?: any) {
    params = params || {};
    return axios.post('updateStudentServlet', qs.stringify(params, {
      arrayFormat: 'repeat'
    }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static addTag(params?: any) {
    params = params || {};
    return axios.post('services/planbook/student/addTag', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static removeTag(params?: any) {
    params = params || {};
    return axios.delete('services/planbook/student/removeTag', {
      data: qs.stringify(params, { arrayFormat: 'repeat' }),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static async deleteStudent(params?: any) {
    params = params || {};
    return axios.post('deleteStudentServlet', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static async deleteStudents(params?: any) {
    params = params || {};
    return axios.post('services/planbook/student/deleteStudents', qs.stringify(params, {
      arrayFormat: 'repeat'
    }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static async getPromoteDetails(params?: any) {
    params = params || {};
    return axios.get('services/planbook/school/getPromoteDetails', { params });
  }

  public static async promoteStudents(params?: any) {
    params = params || {};
    return axios.post('services/planbook/student/promote', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static async getStudentScores(params?: any) {
    params = params || {};
    return axios.post('getStudentScoresServlet', qs.stringify(params, {
      arrayFormat: 'repeat'
    }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static async getScoresForStudent(params?: any) {
    params = params || {};
    return axios.post('getScoresForStudentServlet', qs.stringify(params, {
      arrayFormat: 'repeat'
    }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static async verifyStudentJoinCode(joinCodes: string) {
    const params = { joinCodes };
    return axios.get('services/welcome/verifyStudentJoinCode', { params });
  }

  public static async getGooogleCourseWorkStatus(params?: any) {
    params = params || {};
    return axios.post('services/planbook/googleclassroom/courseWorkStatus', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static async updateAttendance(params?: any) {
    params = params || {};
    return axios.post('services/planbook/attendance/add', qs.stringify(params, {
      arrayFormat: 'repeat'
    }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static joinClass(params?: any) {
    params = params || {};
    return axios.post('services/common/student/addClass', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static getSchoolYears(params?: any) {
    params = params || {};
    return axios.get('services/common/student/getSchoolYears', { params });
  }

  public static async enrollToClass(params?: any) {
    params = params || {};
    return axios.post('services/student/enrollToClass', qs.stringify(params, {
      arrayFormat: 'brackets'
    }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }
}
