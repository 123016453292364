import axios, { AxiosBuilder } from '@/axios';
import { AxiosResponse } from 'axios';

export default class IntegrationServices {
  public static getOrgs(params?: any) {
    params = params || {};
    return axios.get('services/planbook/oneRosterClient/orgs', { params });
  }

  public static getOrg(params?: any) {
    params = params || {};
    return axios.get('services/planbook/oneRosterClient/org/' + params.sourcedId, { params });
  }

  public static getTeachersForSchool(params?: any) {
    params = params || {};
    return axios.post('services/planbook/oneRosterClient/schools/teachers', params, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  public static getTeacher(params?: any) {
    params = params || {};
    return axios.get('services/planbook/oneRosterClient/teacher/' + params.sourcedId, { params });
  }

  public static getAcademicSessions(params?: any) {
    params = params || {};
    return axios.get('services/planbook/oneRosterClient/academicSessions', { params });
  }

  public static getAcademicSession(params?: any) {
    params = params || {};
    return axios.get('services/planbook/oneRosterClient/academicSession', { params });
  }

  public static refOrgs(params?: any) {
    params = params || {};
    const polling = AxiosBuilder.buildPolling();
    polling.setup();
    polling.getAxios().post('services/planbook/oneRosterClient/refOrgs', params, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return polling.getResult();
  }

  public static refUsers(params?: any) {
    params = params || {};
    const polling = AxiosBuilder.buildPolling();
    polling.setup();
    polling.getAxios().post('services/planbook/oneRosterClient/refUsers', params, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return polling.getResult();
  }

  public static refOrgsRefUsers(params?: any) {
    params = params || {};
    return axios.post('services/planbook/oneRosterClient/refOrgsRefUsers', params, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  public static refAcademicSessions(params?: any) {
    params = params || {};
    const polling = AxiosBuilder.buildPolling();
    polling.setup();
    polling.getAxios().post('services/planbook/oneRosterClient/refAcademicSessions', params, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return polling.getResult();
  }

  public static async addSyncTime(params?: any) {
    params = params || {};

    return await axios.post('services/planbook/oneRosterClient/refOrgs/syncTime', params, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  public static async getrefOrgsByDistrictId(params?: any) {
    params = params || {};
    return await axios.get('services/planbook/oneRosterClient/refOrgsByDistrictId/' + params.districtId);
  }

  public static async getrefOrgsBySchoolId(params?: any) {
    params = params || {};
    return await axios.get('services/planbook/oneRosterClient/refOrgsBySchoolId/' + params.schoolId);
  }

  public static async getrefOrgsByAuthorId(params?: any) {
    params = params || {};
    return await axios.get('services/planbook/oneRosterClient/refOrgsByAuthorId/' + params.authorId);
  }

  public static async getrefUsersByDomain(params?: any) {
    params = params || {};
    return await axios.get('services/planbook/oneRosterClient/refUsersByDomain/' + params.domain);
  }

  public static async deleteRefOrg(params?: any) {
    params = params || {};
    return await axios.delete('services/planbook/oneRosterClient/refOrg/' + params.id);
  }

  public static async reconnectRefOrg(params?: any) {
    params = params || {};
    return await axios.put('services/planbook/oneRosterClient/refOrg/reconnect/' + params.id);
  }

  public static async sync(params?: any) {
    return await axios.post('services/planbook/oneRosterClient/sync', params);
  }

  public static async changeRefOrgSettings(params?: any) {
    params = params || {};
    const url = new URL(params.endpoint);
    params.domain = url.hostname;
    return await axios.put('services/planbook/oneRosterClient/refOrgsSettings/' + params.authorId, params, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  public static async getRefAcademicSessionByDistrictId(params?: any) {
    params = params || {};
    return await axios.get('services/planbook/oneRosterClient/refAcademicSession/' + params.districtId, params);
  }

  public static updateRefOrgs(params?: any) {
    params = params || {};
    const polling = AxiosBuilder.buildPolling();
    polling.setup();
    polling.getAxios().put('services/planbook/oneRosterClient/refOrgs', params, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return polling.getResult();
  }

  public static updateRefAcademicSession(params?: any) {
    params = params || {};
    const polling = AxiosBuilder.buildPolling();
    polling.setup();
    polling.getAxios().put('services/planbook/oneRosterClient/refAcademicSessions', params, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return polling.getResult();
  }

  public static updateRefUser(params?: any) {
    params = params || {};
    const polling = AxiosBuilder.buildPolling();
    polling.setup();
    polling.getAxios().put('services/planbook/oneRosterClient/refUsers', params, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return polling.getResult();
  }

  public static getSyncStatus(params: any, onDataUpdateOrFinish: (response: AxiosResponse) => void) {
    const polling = AxiosBuilder.buildPolling();
    polling.setup(onDataUpdateOrFinish);
    polling.getAxios().post('services/planbook/oneRosterClient/getSyncStatus', params, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return polling;
  }

  public static getAllRosteredItems(params?: any) {
    params = params || {};
    return axios.get('services/planbook/oneRosterClient/getAllRosteredItems', { params });
  }
}
