













































import CommonUtils from '@/utils/common-utils';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Confirm from './../../components/core/Confirm.vue';
import ld from 'lodash';
import { VForm } from 'vuetify/lib';
import TextEditorFullScreenButton from '@/components/common/TextEditorFullScreenButton.vue';

const mystrategies = namespace('mystrategies');
const settings = namespace('settings');

@Component({
  props: {
    value: {
      type: Boolean,
      default() {
        return false;
      }
    },
    input: {
      type: Object,
      required: false
    }
  },
  components: {
    TextEditorFullScreenButton
  }
})
export default class MyListEditorForm extends Vue {
  @Prop({ type: Boolean, default: true })
  isModal!: boolean;

  localValue = false;
  localRefreshKey = CommonUtils.generateUUID();
  localStrategyClone: any = {};
  isEditing = false;
  isSectionsHovered = false;
  isTextFieldsVisible = true;

  localRules = [
    (v: any) => !!v || this.$t('requiredLabel')
  ];

  $refs!: {
    strategyEditorForm: InstanceType<typeof VForm>,
    strategyEditorConfirm: Confirm
  }

  @mystrategies.Getter
  getDefaultStrategy!: any;

  @mystrategies.Action
  addStrategy!: (params?: any) => Promise<any>;

  @mystrategies.Action
  updateStrategy!: (params?: any) => Promise<any>;

  @settings.Getter('getShowSnackbarNotifications')
  showSnackbarNotifications!: boolean;

  @Watch('value')
  updateLocalValue(v: boolean) {
    this.localValue = v;
  }

  @Watch('localValue')
  updateValue(v: boolean) {
    this.$emit('update:value', v);
  }

  get localPage() {
    return CommonUtils.getPage(this.$route);
  }

  get localStrategy() {
    return this.$store.state.mystrategies.localStrategy;
  }

  set localStrategy(strategy: any) {
    this.$store.commit('mystrategies/setLocalStrategy', strategy)
  }

  get localTextEditorConfig() {
    return this.isModal ? { height: this.isTextFieldsVisible ? '370px' : 'fill' } : { height: 'fill' };
  }

  addNewStrategyTo(level: string) {
    this.localStrategy.strategyGroupType = level;
    return this.addNewStrategy();
  }

  addNewStrategy() {
    const form: any = this.$refs.strategyEditorForm;
    if (form.validate()) {
      this.localPage === 'strategies' ? this.$store.commit('mystrategies/setListLoading', true) : CommonUtils.showLoading();
      if (this.isEditing) {
        return this.updateStrategy({ dbId: this.localStrategy.dbId, strategyId: this.localStrategy.strategyId, strategyDesc: this.localStrategy.strategyDesc, strategyTitle: this.localStrategy.strategyTitle, strategyGroupType: this.localStrategy.strategyGroupType }).then(() => {
          this.localPage === 'strategies' ? this.$store.commit('mystrategies/setListLoading', false) : CommonUtils.hideLoading();
          form.resetValidation();
          this.$eventBus.$emit('closeStrategyEditor', true);
          if (this.showSnackbarNotifications) {
            if (this.$currentUser.isTeacher) {
              this.$snotify.success(this.$t('statusMsg55') as string);
            } else {
              this.$snotify.success(this.$t('statusMsg55b') as string);
            }
          }
          return Promise.resolve(false);
        });
      } else {
        return this.addStrategy({ strategyId: this.localStrategy.strategyId, strategyDesc: this.localStrategy.strategyDesc, strategyTitle: this.localStrategy.strategyTitle, strategyGroupType: this.localStrategy.strategyGroupType }).then(() => {
          this.localPage === 'strategies' ? this.$store.commit('mystrategies/setListLoading', false) : CommonUtils.hideLoading();
          form.resetValidation();
          this.$eventBus.$emit('closeStrategyEditor', true);
          if (this.showSnackbarNotifications) {
            if (this.$currentUser.isTeacher) {
              this.$snotify.success(this.$t('statusMsg56') as string);
            } else {
              this.$snotify.success(this.$t('statusMsg56b') as string);
            }
          }
          return Promise.resolve(false);
        });
      }
    }
    return Promise.resolve(true);
  }

  resetValidation() {
    try {
      const form: any = this.$refs.strategyEditorForm;
      form.resetValidation();
    } catch (e) {
    }
  }

  initialize() {
    if (this.$props.input.loadData === false) {
      return;
    }
    CommonUtils.showLoading();
    this.isEditing = (this.$props.input.action === 'edit')
    if (this.isEditing) {
      this.localStrategy = ld.cloneDeep(this.$props.input.strategyObject);
    } else {
      this.localStrategy = ld.cloneDeep(this.getDefaultStrategy);
    }
    this.localStrategyClone = ld.cloneDeep(this.localStrategy);
    this.localRefreshKey = CommonUtils.generateUUID();
    CommonUtils.hideLoading();
  }

  hideOrShowTextFields() {
    this.isTextFieldsVisible = !this.isTextFieldsVisible;
    this.localRefreshKey = CommonUtils.generateUUID();
  }

  isDirty() {
    return (!ld.isEqual(this.localStrategy, this.localStrategyClone))
  }

  @Watch('input')
  inputChange() {
    this.initialize();
  }

  created() {
    this.initialize();
  }
}
