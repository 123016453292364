import axios from '@/axios';
import store from '@/store';

export default class StickersServices {
  public static addSticker(params?: any): Promise<any> {
    params = params || {};
    return axios.post('services/planbook/stickers', params, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  public static editSticker(params?: any): Promise<any> {
    params = params || {};
    return axios.put('services/planbook/stickers', params, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  public static removeSticker(params? : any) : Promise<any> {
    return axios.delete('services/planbook/stickers', {
      data: params,
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  public static getAllStickers(): Promise<any> {
    const params = { apiKey: '2e6c6353-3891-4e8a-b579-f6ef074dea34' };
    return axios.get('services/api/stickers', { params, withCredentials: false });
  }

  public static eventStickerStyling(stickerSize:any, stickerBackgroundStyle:string, stickerBackgroundColor:string) {
    let styling = `width: auto; height: ${stickerSize}pt; vertical-align:middle;`;
    if (stickerBackgroundStyle === '5') {
      styling += `filter: drop-shadow(0.2rem 0 0 ${stickerBackgroundColor}) drop-shadow(0 0.2rem 0 ${stickerBackgroundColor}) drop-shadow(-0.2rem 0 0 ${stickerBackgroundColor}) drop-shadow(0 -0.2rem 0 ${stickerBackgroundColor}) brightness(1.1); margin: 0 8px;`;
    } else if (stickerBackgroundStyle === '3' || stickerBackgroundStyle === '4') {
      styling = `padding: 6px; height: ${+stickerSize}pt; width: auto; vertical-align:middle; position: relative;`;
    } else if (stickerBackgroundStyle === '2') {
      styling += 'padding: 4px; padding-bottom: 10px;';
    }
    return styling;
  }

  public static innerJaggedStyling(stickerBackgroundColor:string) {
    return `position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
        background-image: linear-gradient(45deg,${stickerBackgroundColor} 25%,transparent 25%,transparent 75%,${stickerBackgroundColor} 75%,${stickerBackgroundColor}),linear-gradient(-45deg,${stickerBackgroundColor} 25%,transparent 25%,transparent 75%,${stickerBackgroundColor} 75%,${stickerBackgroundColor});
        background-size: ${this.getIsBrowserSafari() ? '10px 20px' : '10px 10px'};
        background-repeat: round round;
        background-position: ${this.getIsBrowserSafari() ? '-6px -9px' : '-5px -5px'};`;
  }

  public static innerJaggedAfterStyling(stickerBackgroundColor:string) {
    return `content: "";
    position: absolute;
    left: 4px;
    top: ${this.getIsBrowserSafari() ? '9px' : '4px'};
    right: ${this.getIsBrowserSafari() ? '3px' : '4px'};
    bottom: ${this.getIsBrowserSafari() ? '9px' : '4px'};
    background: ${stickerBackgroundColor};
    z-index: 1;`;
  }

  public static innerWavyStyling(stickerBackgroundColor:string) {
    return `position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
        aspect-ratio: auto;
        background: linear-gradient(135deg,${stickerBackgroundColor}, ${stickerBackgroundColor}, ${stickerBackgroundColor}, ${stickerBackgroundColor}, ${stickerBackgroundColor}, ${stickerBackgroundColor}, ${stickerBackgroundColor}, ${stickerBackgroundColor});
        -webkit-mask: var(--mask);
        mask: var(--mask);
        --mask: linear-gradient(#000 0 0) 50%/calc(100% - 46.25px) calc(100% - 46.25px) no-repeat,radial-gradient(farthest-side,#000 98%,#0000) 0 0/${this.getIsBrowserSafari() ? '20px 20px' : '10px 10px'} round;`;
  }

  public static innerWavyAfterStyling(stickerBackgroundColor:string) {
    return `content: "";
    position: absolute;
    left: 5px;
    top: 6px;
    right: ${this.getIsBrowserSafari() ? '3px' : '5px'};
    bottom: 6px;
    background: ${stickerBackgroundColor};
    z-index: 1;`;
  }

  public static straightEdgeStyling(stickerBackgroundColor:string, stickerSize?:any) {
    return `border-style: solid; border-color: ${stickerBackgroundColor}; background-color: ${stickerBackgroundColor}; border-radius: 5px; width: fit-content; height: ${stickerSize}pt;`;
  }

  public static getStickerFileName(stickerId:number) {
    try {
      return store.getters['stickers/getAllStickers'].stickers.find((sticker:any) => sticker.id === stickerId).value;
    } catch (e) {
      return '';
    }
  }

  public static getIsBrowserSafari():boolean {
    const chromeAgent = window.navigator.userAgent.indexOf('Chrome') > -1;
    let safariAgent = window.navigator.userAgent.indexOf('Safari') > -1;
    if ((chromeAgent) && (safariAgent)) safariAgent = false;
    return safariAgent;
  }
}
