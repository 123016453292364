import { Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true })
export default class Classlink extends VuexModule {
  orgsMappingArray: Array<any> = [];
  teachersMappingArray: Array<any> = [];
  schoolYearsMappingArray: Array<any> = [];
  selectedOrgs: Array<any> = [];
  selectedTeachers: Array<any> = [];
  selectedSchoolYears: Array<any> = [];

  manageMappingOrgsArray: Array<any> = [];
  connectedTeachersArray: Array<any> = [];
  manageMappingSchoolYearsArray: Array<any> = [];
  manageMappingOrgsSelected: Array<any> = [];
  manageMappingTeachersSelected: Array<any> = [];
  manageMappingSchoolYearsSelected: Array<any> = [];

  setupStepCount = 1;
  setupWizard = false;
  setUpEndPoint = '';
  setUpAccessKey = '';
  setUpSecretKey = '';
  setUpSyncTime = '0';

  connectedEndPoint = '';
  connectedAccessKey = '';
  connectedSecretKey = '';
  connectedSyncTime:any = '';
  isDisconnected = false;
  isConnected = false;

  isManageMappingOrgsNotDirty = true;
  isManageMappingSchoolYearsNotDirty = true;
  isConnectedSettingsNotDirty = true;
  isManageMappingTeachersNotDirty = true;

  // mainOrgJsonObject this is the passed object in sync now button
  mainOrgJsonObject: any = { systemLock: false };

  syncStatusObject: any = {
    orgs: 0,
    sessions: 0,
    users: 0,
    enrollments: 0
  };

  syncStatusDate: any = {
    orgs: '',
    sessions: '',
    users: '',
    enrollments: ''
  };

  get getManageMappingOrgsArray(): Array<any> {
    return this.manageMappingOrgsArray;
  }

  get getConnectedTeachersArray(): Array<any> {
    return this.connectedTeachersArray;
  }

  get getManageMappingSchoolYearsArray(): Array<any> {
    return this.manageMappingSchoolYearsArray;
  }

  get getManageMappingOrgsSelected():Array<any> {
    return this.manageMappingOrgsSelected;
  }

  get getManageMappingTeachersSelected():Array<any> {
    return this.manageMappingTeachersSelected;
  }

  get getManageMappingSchoolYearsSelected():Array<any> {
    return this.manageMappingSchoolYearsSelected;
  }

  get getOrgsMappingArray(): Array<any> {
    return this.orgsMappingArray;
  }

  get getTeachersMappingArray(): Array<any> {
    return this.teachersMappingArray;
  }

  get getSchoolYearsMappingArray(): Array<any> {
    return this.schoolYearsMappingArray;
  }

  get getSelectedOrgs():Array<any> {
    return this.selectedOrgs;
  }

  get getSelectedTeachers():Array<any> {
    return this.selectedTeachers;
  }

  get getSelectedSchoolYears():Array<any> {
    return this.selectedSchoolYears;
  }

  get getMainOrgJsonObject() {
    return this.mainOrgJsonObject;
  }

  @Mutation
  setOrgsMappingArray(orgsMappingArray: Array<any>) {
    this.orgsMappingArray = orgsMappingArray;
  }

  @Mutation
  pushElementInOrgsMappingArray(element:any) {
    this.orgsMappingArray.push(element);
  }

  @Mutation
  setTeachersMappingArray(teachersMappingArray: Array<any>) {
    this.teachersMappingArray = teachersMappingArray;
  }

  @Mutation
  pushElementInTeachersMappingArray(element:any) {
    this.teachersMappingArray.push(element);
  }

  @Mutation
  spliceElementInTeachersMappingArray(start:number) {
    this.teachersMappingArray.splice(start, 1);
  }

  @Mutation
  setSchoolYearsMappingArray(schoolYearsMappingArray: Array<any>) {
    this.schoolYearsMappingArray = schoolYearsMappingArray;
  }

  @Mutation
  pushElementInSchoolYearsMappingArray(element:any) {
    this.schoolYearsMappingArray.push(element);
  }

  @Mutation
  spliceElementInSchoolYearsMappingArray(start:number) {
    this.schoolYearsMappingArray.splice(start, 1);
  }

  @Mutation
  setSelectedOrgs(selectedOrgs: Array<any>) {
    this.selectedOrgs = selectedOrgs;
  }

  @Mutation
  pushElementInSelectedOrgs(element:any) {
    this.selectedOrgs.push(element);
  }

  @Mutation
  spliceElementInSelectedOrgs(start:number) {
    this.selectedOrgs.splice(start, 1);
  }

  @Mutation
  setSelectedTeacher(selectedTeachers: Array<any>) {
    this.selectedTeachers = selectedTeachers;
  }

  @Mutation
  pushElementInSelectedTeacher(element:any) {
    this.selectedTeachers.push(element);
  }

  @Mutation
  spliceElementInSelectedTeacher(start:number) {
    this.selectedTeachers.splice(start, 1);
  }

  @Mutation
  setSelectedSchoolYears(selectedSchoolYears: Array<any>) {
    this.selectedSchoolYears = selectedSchoolYears;
  }

  @Mutation
  pushElementInSelectedSchoolYears(element:any) {
    this.selectedSchoolYears.push(element);
  }

  @Mutation
  spliceElementInSelectedSchoolYears(start:number) {
    this.selectedSchoolYears.splice(start, 1);
  }

  @Mutation
  setSetupStepCount(setupStepCount: number) {
    this.setupStepCount = setupStepCount;
  }

  @Mutation
  setSetupWizard(setupWizard:boolean) {
    this.setupWizard = setupWizard;
  }

  @Mutation
  setSetUpEndPoint(setUpEndPoint:string) {
    this.setUpEndPoint = setUpEndPoint;
  }

  @Mutation
  setSetUpAccessKey(setUpAccessKey:string) {
    this.setUpAccessKey = setUpAccessKey;
  }

  @Mutation
  setSetUpSecretKey(setUpSecretKey:string) {
    this.setUpSecretKey = setUpSecretKey;
  }

  @Mutation
  setSetUpSyncTime(setUpSyncTime:string) {
    this.setUpSyncTime = setUpSyncTime;
  }

  @Mutation
  resetClasslinkData() {
    this.orgsMappingArray = [];
    this.teachersMappingArray = [];
    this.schoolYearsMappingArray = [];
    this.selectedOrgs = [];
    this.selectedTeachers = [];
    this.selectedSchoolYears = [];
    this.setupStepCount = 1;
    this.setupWizard = false;
    this.setUpEndPoint = '';
    this.setUpAccessKey = '';
    this.setUpSecretKey = '';
    this.setUpSyncTime = '0';
    this.mainOrgJsonObject = { systemLock: false };

    this.manageMappingOrgsArray = [];
    this.connectedTeachersArray = [];
    this.manageMappingSchoolYearsArray = [];
    this.manageMappingOrgsSelected = [];
    this.manageMappingTeachersSelected = [];
    this.manageMappingSchoolYearsSelected = [];

    this.connectedEndPoint = '';
    this.connectedAccessKey = '';
    this.connectedSecretKey = '';
    this.connectedSyncTime = '';
    this.isDisconnected = false;
    this.isConnected = false;

    this.isManageMappingOrgsNotDirty = true;
    this.isManageMappingSchoolYearsNotDirty = true;
    this.isConnectedSettingsNotDirty = true;
    this.isManageMappingTeachersNotDirty = true;

    this.syncStatusObject = {
      orgs: 0,
      sessions: 0,
      users: 0,
      enrollments: 0
    };

    this.syncStatusDate = {
      orgs: '',
      sessions: '',
      users: '',
      enrollments: ''
    };
  }

  @Mutation
  setMainOrgJsonObject(mainOrgJsonObject:any) {
    this.mainOrgJsonObject = mainOrgJsonObject || { systemLock: false };
  }

  @Mutation
  setManageMappingOrgsArray(manageMappingOrgsArray: Array<any>) {
    this.manageMappingOrgsArray = manageMappingOrgsArray;
  }

  // @Mutation
  // pushElementInManageMappingOrgsArray(element:any) {
  //   this.classlinkConnectedMappingData.manageMappingOrgsArray.push(element);
  // }

  // @Mutation
  // spliceElementInManageMappingOrgsArray(start:number) {
  //   this.classlinkConnectedMappingData.manageMappingOrgsArray.splice(start, 1);
  // }

  @Mutation
  setManageMappingOrgsSelected(manageMappingOrgsSelected: Array<any>) {
    this.manageMappingOrgsSelected = manageMappingOrgsSelected;
  }

  @Mutation
  pushElementInManageMappingOrgsSelected(element:any) {
    this.manageMappingOrgsSelected.push(element);
  }

  @Mutation
  spliceElementInManageMappingOrgsSelected(start:number) {
    this.manageMappingOrgsSelected.splice(start, 1);
  }

  @Mutation
  setConnectedTeachersArray(connectedTeachersArray: Array<any>) {
    this.connectedTeachersArray = connectedTeachersArray;
  }

  @Mutation
  pushElementInConnectedTeachersArray(element:any) {
    this.connectedTeachersArray.push(element);
  }

  @Mutation
  spliceElementInConnectedTeachersArray(start:number) {
    this.connectedTeachersArray.splice(start, 1);
  }

  @Mutation
  setManageMappingTeachersSelected(manageMappingTeachersSelected: Array<any>) {
    this.manageMappingTeachersSelected = manageMappingTeachersSelected;
  }

  @Mutation
  pushElementInManageMappingTeachersSelected(element:any) {
    this.manageMappingTeachersSelected.push(element);
  }

  @Mutation
  spliceElementInManageMappingTeachersSelected(start:number) {
    this.manageMappingTeachersSelected.splice(start, 1);
  }

  @Mutation
  setManageMappingSchoolYearsArray(manageMappingSchoolYearsArray: Array<any>) {
    this.manageMappingSchoolYearsArray = manageMappingSchoolYearsArray;
  }

  @Mutation
  pushElementInManageMappingSchoolYearsArray(element:any) {
    this.manageMappingSchoolYearsArray.push(element);
  }

  @Mutation
  spliceElementInManageMappingSchoolYearsArray(start:number) {
    this.manageMappingSchoolYearsArray.splice(start, 1);
  }

  @Mutation
  setManageMappingSchoolYearsSelected(manageMappingSchoolYearsSelected: Array<any>) {
    this.manageMappingSchoolYearsSelected = manageMappingSchoolYearsSelected;
  }

  @Mutation
  pushElementInManageMappingSchoolYearsSelected(element:any) {
    this.manageMappingSchoolYearsSelected.push(element);
  }

  @Mutation
  spliceElementInManageMappingSchoolYearsSelected(start:number) {
    this.manageMappingSchoolYearsSelected.splice(start, 1);
  }

  @Mutation
  setConnectedEndPoint(connectedEndPoint:string) {
    this.connectedEndPoint = connectedEndPoint;
  }

  @Mutation
  setConnectedAccessKey(connectedAccessKey:string) {
    this.connectedAccessKey = connectedAccessKey;
  }

  @Mutation
  setConnectedSecretKey(connectedSecretKey:string) {
    this.connectedSecretKey = connectedSecretKey;
  }

  @Mutation
  setConnectedSyncTime(connectedSyncTime:any) {
    this.connectedSyncTime = connectedSyncTime;
  }

  @Mutation
  setIsDisconnected(isDisconnected:boolean) {
    this.isDisconnected = isDisconnected;
  }

  @Mutation
  setIsConnected(isConnected:boolean) {
    this.isConnected = isConnected;
  }

  @Mutation
  setIsManageMappingOrgsNotDirty(isManageMappingOrgsNotDirty:boolean) {
    this.isManageMappingOrgsNotDirty = isManageMappingOrgsNotDirty;
  }

  @Mutation
  setIsManageMappingSchoolYearsNotDirty(isManageMappingSchoolYearsNotDirty:boolean) {
    this.isManageMappingSchoolYearsNotDirty = isManageMappingSchoolYearsNotDirty;
  }

  @Mutation
  setIsConnectedSettingsNotDirty(isConnectedSettingsNotDirty:boolean) {
    this.isConnectedSettingsNotDirty = isConnectedSettingsNotDirty;
  }

  @Mutation
  setIsManageMappingTeachersNotDirty(isManageMappingTeachersNotDirty:boolean) {
    this.isManageMappingTeachersNotDirty = isManageMappingTeachersNotDirty;
  }

  @Mutation
  setSyncStatusObject(syncStatusObject:any) {
    this.syncStatusObject = syncStatusObject;
  }

  @Mutation
  setSyncStatusDate(syncStatusDate:any) {
    this.syncStatusDate = syncStatusDate;
  }
}
