var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasSelectedRows
    ? _c(
        "v-menu",
        {
          attrs: {
            transition: "scroll-y-transition",
            "offset-x": "",
            left: "",
            "close-on-content-click": false,
            "close-on-click": false
          },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function(ref) {
                  var on = ref.on
                  var attrs = ref.attrs
                  return [
                    _vm.uploadMode
                      ? _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              { staticClass: "mr-3", attrs: { small: "" } },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [_c("span", [_vm._v(_vm._s(_vm.$t("uploadLabel")))])]
                        )
                      : _c(
                          "pb-btn",
                          _vm._g(
                            _vm._b(
                              {
                                staticClass: "ml-2 my-1",
                                attrs: {
                                  color: "primary",
                                  label: _vm.uploadMode
                                    ? _vm.$t("uploadLabel")
                                    : _vm.$t("moveLabel"),
                                  text: "",
                                  outlined: ""
                                }
                              },
                              "pb-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [_c("v-icon", [_vm._v("fal fa-folder-tree")])],
                          1
                        )
                  ]
                }
              }
            ],
            null,
            false,
            2645805428
          ),
          model: {
            value: _vm.localValue,
            callback: function($$v) {
              _vm.localValue = $$v
            },
            expression: "localValue"
          }
        },
        [
          _c(
            "v-card",
            { attrs: { width: "450" } },
            [
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    [
                      _vm.isMoveToOpenedFolderNotEmpty
                        ? _c(
                            "pb-btn",
                            {
                              staticClass: "mr-2",
                              attrs: { icon: "", label: _vm.$t("backLabel") },
                              on: {
                                click: function($event) {
                                  return _vm.localMoveToOpenedFolder.pop()
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("fal fa-angle-left")])],
                            1
                          )
                        : _vm._e(),
                      _vm.localMoveToIsAddingFolder
                        ? _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              clearable: "",
                              "hide-details": "",
                              "append-icon": "fal fa-check",
                              "prepend-inner-icon": "fal fa-folder",
                              label: _vm.$t("folderNameLabel"),
                              required: ""
                            },
                            on: {
                              "click:append": function($event) {
                                return _vm.moveToCreateNewFolder()
                              }
                            },
                            model: {
                              value: _vm.localMoveToNewFolderName,
                              callback: function($$v) {
                                _vm.localMoveToNewFolderName = $$v
                              },
                              expression: "localMoveToNewFolderName"
                            }
                          })
                        : _c("span", { staticClass: "text-h6" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.isMoveToOpenedFolderNotEmpty
                                    ? _vm.moveToCurrentOpenedFolder.text
                                    : _vm.$t("homeLabel")
                                ) +
                                " "
                            )
                          ]),
                      _c("v-spacer"),
                      _c(
                        "pb-btn",
                        {
                          attrs: {
                            icon: "",
                            "x-small": "",
                            label: _vm.$t("closeLabel")
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              _vm.localValue = false
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("fal fa-times")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-list-item",
                    { staticClass: "px-0" },
                    [
                      _c(
                        "v-list-item-content",
                        {
                          staticClass: "py-0",
                          staticStyle: {
                            "overflow-y": "scroll",
                            height: "350px",
                            "align-items": "start"
                          }
                        },
                        [
                          _c("v-data-table", {
                            attrs: {
                              loading: _vm.isTableLoading,
                              items: _vm.folders,
                              "single-select": "",
                              "hide-default-footer": "",
                              "item-key": "value"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "body",
                                  fn: function(ref) {
                                    var items = ref.items
                                    var isSelected = ref.isSelected
                                    var select = ref.select
                                    return [
                                      _c(
                                        "transition-group",
                                        {
                                          tag: "tbody",
                                          staticClass: "moveToTableBody",
                                          attrs: { name: "list" }
                                        },
                                        _vm._l(items, function(item, index) {
                                          return _c("v-hover", {
                                            key: index,
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var hover = ref.hover
                                                    return [
                                                      _c(
                                                        "tr",
                                                        {
                                                          style: isSelected(
                                                            item
                                                          )
                                                            ? "background-color: #4c90fe; color: white;"
                                                            : ""
                                                        },
                                                        [
                                                          _c(
                                                            "td",
                                                            {
                                                              staticStyle: {
                                                                "border-bottom":
                                                                  "none !important"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  select(
                                                                    item,
                                                                    !isSelected(
                                                                      item
                                                                    )
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    color: isSelected(
                                                                      item
                                                                    )
                                                                      ? "white"
                                                                      : ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-folder"
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "ml-2"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.text
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "td",
                                                            {
                                                              staticStyle: {
                                                                "border-bottom":
                                                                  "none !important"
                                                              },
                                                              attrs: {
                                                                align: "end"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  select(
                                                                    item,
                                                                    !isSelected(
                                                                      item
                                                                    )
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              hover
                                                                ? _c(
                                                                    "pb-btn",
                                                                    {
                                                                      attrs: {
                                                                        icon: ""
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          $event.preventDefault()
                                                                          return _vm.openMoveToFolder(
                                                                            item
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            "fal fa-angle-right"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        }),
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1931084621
                            ),
                            model: {
                              value: _vm.localMoveToFolder,
                              callback: function($$v) {
                                _vm.localMoveToFolder = $$v
                              },
                              expression: "localMoveToFolder"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "pb-btn",
                        {
                          staticClass: "mt-2",
                          attrs: {
                            label: _vm.localMoveToIsAddingFolder
                              ? _vm.$t("cancelLabel")
                              : _vm.$t("createFolderLabel"),
                            icon: ""
                          },
                          on: {
                            click: function($event) {
                              _vm.localMoveToIsAddingFolder = !_vm.localMoveToIsAddingFolder
                            }
                          }
                        },
                        [
                          _c("v-icon", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.localMoveToIsAddingFolder
                                    ? "fal fa-folder-xmark"
                                    : "fal fa-folder-plus"
                                )
                            )
                          ])
                        ],
                        1
                      ),
                      _c("v-spacer"),
                      _c(
                        "pb-btn",
                        {
                          staticClass: "mt-2",
                          attrs: {
                            color: "primary",
                            label: _vm.uploadMode
                              ? _vm.$t("uploadLabel")
                              : _vm.$t("moveLabel"),
                            text: "",
                            outlined: "",
                            disabled: _vm.isTableLoading
                          },
                          on: { click: _vm.doMove }
                        },
                        [
                          _vm.isMoveToFolderEmpty
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.uploadMode
                                      ? _vm.$t("uploadHereLabel")
                                      : _vm.$t("moveHereLabel")
                                  )
                                )
                              ])
                            : _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.uploadMode
                                      ? _vm.$t("uploadLabel")
                                      : _vm.$t("moveLabel")
                                  )
                                )
                              ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }