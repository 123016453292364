var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticStyle: { "max-height": "58px" } }, [
    _c(
      "div",
      {
        class: [_vm.outlined ? "outlined" : "", +_vm.dense ? "dense" : ""],
        on: {
          mouseenter: _vm.hoverEnterTimepicker,
          mouseleave: _vm.hoverExitTimepicker,
        },
      },
      [
        _c("vue-timepicker", {
          attrs: {
            "auto-scroll": "",
            "manual-input": "",
            format: _vm.vueTimepickerFormat,
            placeholder: _vm.label,
            disabled: _vm.disabled,
            "hour-range": _vm.hourRange,
            "minute-range": _vm.minuteRange,
            "hide-clear-button": _vm.hideClearButton,
          },
          scopedSlots: _vm._u([
            {
              key: "icon",
              fn: function () {
                return [
                  _c("v-icon", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.prependInnerIcon && _vm.prependInnerIcon !== ""
                            ? _vm.prependInnerIcon
                            : "fal fa-clock"
                        ) +
                        " "
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "clearButton",
              fn: function () {
                return [_c("v-icon", [_vm._v("fas fa-circle-xmark")])]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.localValue,
            callback: function ($$v) {
              _vm.localValue = $$v
            },
            expression: "localValue",
          },
        }),
      ],
      1
    ),
    _vm.label != ""
      ? _c("div", { staticClass: "label" }, [_vm._v(_vm._s(_vm.label))])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }