var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      ref: _vm.localRef,
      attrs: {
        transition: "scale-transition",
        origin: _vm.origin,
        "min-width": "auto",
        "close-on-content-click": false,
        "close-on-click": _vm.closeOnClick,
        "offset-overflow": "",
        bottom: "",
        left: ""
      },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function(ref) {
              var onMenu = ref.on
              return [
                _c(
                  "div",
                  _vm._g(
                    {
                      on: {
                        keydown: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "down", 40, $event.key, [
                              "Down",
                              "ArrowDown"
                            ])
                          ) {
                            return null
                          }
                          $event.preventDefault()
                          $event.stopPropagation()
                        },
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "down", 40, $event.key, [
                              "Down",
                              "ArrowDown"
                            ])
                          ) {
                            return null
                          }
                          return _vm.focusColorPicker.apply(null, arguments)
                        }
                      }
                    },
                    Object.assign({}, onMenu)
                  ),
                  [
                    _vm._t("activator", function() {
                      return [
                        _vm.localIcon && _vm.localIcon !== ""
                          ? _c(
                              "pb-btn",
                              _vm._g(
                                {
                                  staticClass: "border-padding",
                                  attrs: { label: _vm.localLabel, icon: "" }
                                },
                                Object.assign({}, onMenu)
                              ),
                              [
                                _c(
                                  "v-icon",
                                  {
                                    class: _vm.isColorReadable(_vm.localValue)
                                      ? ""
                                      : "pb-bordered-icon",
                                    attrs: {
                                      color:
                                        _vm.localValue === "#FFFFFF" ||
                                        _vm.localValue === "#FFF" ||
                                        _vm.localValue === "#ffffff" ||
                                        _vm.localValue === "#fff"
                                          ? "info"
                                          : _vm.localValue,
                                      large: _vm.large,
                                      small: _vm.small,
                                      dense: _vm.dense
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.localIcon))]
                                )
                              ],
                              1
                            )
                          : _c(
                              "pb-btn",
                              _vm._g(
                                {
                                  staticClass: "border-padding",
                                  attrs: { label: _vm.localLabel, icon: "" }
                                },
                                Object.assign({}, onMenu)
                              ),
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "color-picker-button",
                                    style: { backgroundColor: _vm.localValue },
                                    attrs: {
                                      color: _vm.localValue,
                                      large: _vm.large,
                                      small: _vm.small,
                                      dense: _vm.dense
                                    }
                                  },
                                  [_vm._v("fas fa-square")]
                                )
                              ],
                              1
                            )
                      ]
                    })
                  ],
                  2
                )
              ]
            }
          }
        ],
        null,
        true
      ),
      model: {
        value: _vm.localMenu,
        callback: function($$v) {
          _vm.localMenu = $$v
        },
        expression: "localMenu"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c(
            "v-card-text",
            { staticClass: "px-0 py-1" },
            [
              _c("v-color-picker", {
                ref: "colorPicker",
                staticClass: "ma-2",
                attrs: {
                  "show-swatches": "",
                  swatches: _vm.colors,
                  mode: "hexa"
                },
                model: {
                  value: _vm.localValue,
                  callback: function($$v) {
                    _vm.localValue = $$v
                  },
                  expression: "localValue"
                }
              })
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "justify-center" },
            [
              _c(
                "v-btn",
                {
                  attrs: { small: "" },
                  on: {
                    click: function($event) {
                      _vm.localMenu = false
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("doneLabel")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }