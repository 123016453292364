var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.drawerMode === 0
      ? _c(
          "div",
          { staticClass: "view-content-settings" },
          [
            _c("div", { ref: "spacer" }),
            _c("masonry-wall", {
              attrs: {
                items: [0, 1, 2, 3, 4],
                "column-width": _vm.masonryColumnWidth,
                "max-columns": 10,
                "min-columns": 5
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(ref) {
                      var index = ref.index
                      return [
                        _vm.userMode != "S" && index === 0
                          ? _c(
                              "div",
                              { staticClass: "view-settings-col" },
                              [
                                _c(
                                  "v-card",
                                  { attrs: { flat: "" } },
                                  [
                                    _c(
                                      "v-card-title",
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(_vm.$t("classesLabel")))
                                        ]),
                                        _c(
                                          "pb-chip",
                                          {
                                            staticClass: "ml-2",
                                            staticStyle: { opacity: "1" },
                                            attrs: {
                                              filter: "",
                                              outlined: "",
                                              value: "all",
                                              "active-class":
                                                "accent1--text font-weight-bold",
                                              disabled: _vm.selectedClassItems.includes(
                                                "all"
                                              ),
                                              "input-value": _vm.selectedClassItems.includes(
                                                "all"
                                              )
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.selectClass("all")
                                              },
                                              keyup: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.selectClass("all")
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(_vm.$t("allLabel")))
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "pb-chip",
                                          {
                                            staticClass: "ml-2",
                                            staticStyle: { opacity: "1" },
                                            attrs: {
                                              filter: "",
                                              outlined: "",
                                              value: "none",
                                              "active-class":
                                                "accent1--text font-weight-bold",
                                              disabled: _vm.selectedClassItems.includes(
                                                "none"
                                              ),
                                              "input-value": _vm.selectedClassItems.includes(
                                                "none"
                                              )
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.selectClass("none")
                                              },
                                              keyup: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.selectClass("none")
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.$t("noneLabel"))
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-card-text",
                                      [
                                        _c(
                                          "v-chip-group",
                                          {
                                            staticClass: "v-chip-group-column",
                                            attrs: {
                                              "active-class":
                                                "accent1--text font-weight-bold",
                                              column: "",
                                              multiple: "",
                                              value: _vm.selectedClassItems
                                            }
                                          },
                                          _vm._l(_vm.viewClassItems, function(
                                            item,
                                            i
                                          ) {
                                            return _c(
                                              "pb-chip",
                                              {
                                                key: i,
                                                staticClass: "v-chip-column",
                                                attrs: {
                                                  filter: "",
                                                  outlined: "",
                                                  value: item.value
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.selectClass(
                                                      item.value
                                                    )
                                                  },
                                                  keyup: function($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    return _vm.selectClass(
                                                      item.value
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "v-chip-text text-center"
                                                  },
                                                  [_vm._v(_vm._s(item.text))]
                                                )
                                              ]
                                            )
                                          }),
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.userMode != "S" &&
                        _vm.isNotEmpty(_vm.viewStatusItems) &&
                        _vm.viewStatusItems.length > 1 &&
                        index === 1
                          ? _c(
                              "div",
                              { staticClass: "view-settings-col" },
                              [
                                _c(
                                  "v-card",
                                  { attrs: { flat: "" } },
                                  [
                                    _c(
                                      "v-card-title",
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("lessonStatusLabel"))
                                          )
                                        ]),
                                        _c(
                                          "pb-chip",
                                          {
                                            staticClass: "ml-2",
                                            staticStyle: { opacity: "1" },
                                            attrs: {
                                              filter: "",
                                              outlined: "",
                                              value: "all",
                                              "active-class":
                                                "accent3--text font-weight-bold",
                                              disabled: _vm.selectedStatusItems.includes(
                                                "all"
                                              ),
                                              "input-value": _vm.selectedStatusItems.includes(
                                                "all"
                                              )
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.selectStatus("all")
                                              },
                                              keyup: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.selectStatus("all")
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(_vm.$t("allLabel")))
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "pb-chip",
                                          {
                                            staticClass: "ml-2",
                                            staticStyle: { opacity: "1" },
                                            attrs: {
                                              filter: "",
                                              outlined: "",
                                              value: "none",
                                              "active-class":
                                                "accent3--text font-weight-bold",
                                              disabled: _vm.selectedStatusItems.includes(
                                                "none"
                                              ),
                                              "input-value": _vm.selectedStatusItems.includes(
                                                "none"
                                              )
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.selectStatus("none")
                                              },
                                              keyup: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.selectStatus("none")
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.$t("noneLabel"))
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-card-text",
                                      [
                                        _c(
                                          "v-chip-group",
                                          {
                                            staticClass: "v-chip-group-column",
                                            attrs: {
                                              "active-class":
                                                "accent3--text font-weight-bold",
                                              column: "",
                                              multiple: "",
                                              value: _vm.selectedStatusItems
                                            }
                                          },
                                          _vm._l(_vm.viewStatusItems, function(
                                            item,
                                            i
                                          ) {
                                            return _c(
                                              "pb-chip",
                                              {
                                                key: i,
                                                staticClass: "v-chip-column",
                                                attrs: {
                                                  filter: "",
                                                  outlined: "",
                                                  value: item.value
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.selectStatus(
                                                      item.value
                                                    )
                                                  },
                                                  keyup: function($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    return _vm.selectStatus(
                                                      item.value
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "v-chip-text text-center"
                                                  },
                                                  [_vm._v(_vm._s(item.text))]
                                                )
                                              ]
                                            )
                                          }),
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isNotEmpty(_vm.viewLessonSectionItems) &&
                        _vm.viewLessonSectionItems[0].length > 0 &&
                        index === 2
                          ? _c(
                              "div",
                              { staticClass: "view-settings-col" },
                              [
                                _c(
                                  "v-card",
                                  { attrs: { flat: "" } },
                                  [
                                    _c(
                                      "v-card-title",
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("lessonSectionsLabel")
                                            )
                                          )
                                        ]),
                                        _c(
                                          "pb-chip",
                                          {
                                            staticClass: "ml-2",
                                            staticStyle: { opacity: "1" },
                                            attrs: {
                                              filter: "",
                                              outlined: "",
                                              value: "all",
                                              "active-class":
                                                "accent2--text font-weight-bold",
                                              "input-value": _vm.selectedLessonSectionItems.includes(
                                                "all"
                                              ),
                                              disabled: _vm.selectedLessonSectionItems.includes(
                                                "all"
                                              )
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.selectLessonSection(
                                                  "all"
                                                )
                                              },
                                              keyup: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.selectLessonSection(
                                                  "all"
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(_vm.$t("allLabel")))
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "pb-chip",
                                          {
                                            staticClass: "ml-2",
                                            staticStyle: { opacity: "1" },
                                            attrs: {
                                              filter: "",
                                              outlined: "",
                                              "active-class":
                                                "accent2--text font-weight-bold",
                                              "input-value": _vm.selectedLessonSectionItems.includes(
                                                "none"
                                              ),
                                              disabled: _vm.selectedLessonSectionItems.includes(
                                                "none"
                                              )
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.selectLessonSection(
                                                  "none"
                                                )
                                              },
                                              keyup: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.selectLessonSection(
                                                  "none"
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.$t("noneLabel"))
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-card-text",
                                      [
                                        _c(
                                          "v-chip-group",
                                          {
                                            staticClass: "v-chip-group-column",
                                            attrs: {
                                              "active-class":
                                                "accent2--text font-weight-bold",
                                              column: "",
                                              multiple: "",
                                              value:
                                                _vm.selectedLessonSectionItems
                                            }
                                          },
                                          _vm._l(
                                            _vm.viewLessonSectionItems[0],
                                            function(item, i) {
                                              return _c(
                                                "span",
                                                {
                                                  key: i,
                                                  staticClass:
                                                    "text-center v-chip-column"
                                                },
                                                [
                                                  _vm.hasText(item.tooltip)
                                                    ? [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              "open-delay":
                                                                "100",
                                                              bottom: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    return [
                                                                      _c(
                                                                        "pb-chip",
                                                                        _vm._g(
                                                                          {
                                                                            staticClass:
                                                                              "v-chip-column",
                                                                            attrs: {
                                                                              filter:
                                                                                "",
                                                                              outlined:
                                                                                "",
                                                                              value:
                                                                                item.value
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.selectLessonSection(
                                                                                  item.value
                                                                                )
                                                                              },
                                                                              keyup: function(
                                                                                $event
                                                                              ) {
                                                                                if (
                                                                                  !$event.type.indexOf(
                                                                                    "key"
                                                                                  ) &&
                                                                                  _vm._k(
                                                                                    $event.keyCode,
                                                                                    "enter",
                                                                                    13,
                                                                                    $event.key,
                                                                                    "Enter"
                                                                                  )
                                                                                ) {
                                                                                  return null
                                                                                }
                                                                                return _vm.selectLessonSection(
                                                                                  item.value
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "v-chip-text text-center"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  item.text
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.tooltip
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      ]
                                                    : [
                                                        _c(
                                                          "pb-chip",
                                                          {
                                                            staticClass:
                                                              "v-chip-column",
                                                            attrs: {
                                                              filter: "",
                                                              outlined: "",
                                                              value: item.value
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.selectLessonSection(
                                                                  item.value
                                                                )
                                                              },
                                                              keyup: function(
                                                                $event
                                                              ) {
                                                                if (
                                                                  !$event.type.indexOf(
                                                                    "key"
                                                                  ) &&
                                                                  _vm._k(
                                                                    $event.keyCode,
                                                                    "enter",
                                                                    13,
                                                                    $event.key,
                                                                    "Enter"
                                                                  )
                                                                ) {
                                                                  return null
                                                                }
                                                                return _vm.selectLessonSection(
                                                                  item.value
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "v-chip-text text-center"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.text
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                ],
                                                2
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.userMode != "S" &&
                        _vm.isNotEmpty(_vm.viewLessonSectionItems) &&
                        _vm.viewLessonSectionItems.length > 1 &&
                        _vm.viewLessonSectionItems[2].length > 0 &&
                        index === 3
                          ? _c(
                              "div",
                              { staticClass: "view-settings-col" },
                              [
                                _c(
                                  "v-card",
                                  { attrs: { flat: "" } },
                                  [
                                    _c(
                                      "v-card-title",
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("otherSectionsLabel"))
                                          )
                                        ]),
                                        _c(
                                          "pb-chip",
                                          {
                                            staticClass: "ml-2",
                                            staticStyle: { opacity: "1" },
                                            attrs: {
                                              filter: "",
                                              outlined: "",
                                              value: "allLinked",
                                              "active-class":
                                                "error--text font-weight-bold",
                                              disabled: _vm.selectedLessonSectionItems.includes(
                                                "allOther"
                                              ),
                                              "input-value": _vm.selectedLessonSectionItems.includes(
                                                "allOther"
                                              )
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.selectLessonSection(
                                                  "allOther"
                                                )
                                              },
                                              keyup: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.selectLessonSection(
                                                  "allOther"
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(_vm.$t("allLabel")))
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "pb-chip",
                                          {
                                            staticClass: "ml-2",
                                            staticStyle: { opacity: "1" },
                                            attrs: {
                                              filter: "",
                                              outlined: "",
                                              value: "noneLinked",
                                              "active-class":
                                                "error--text font-weight-bold",
                                              disabled: _vm.selectedLessonSectionItems.includes(
                                                "noneOther"
                                              ),
                                              "input-value": _vm.selectedLessonSectionItems.includes(
                                                "noneOther"
                                              )
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.selectLessonSection(
                                                  "noneOther"
                                                )
                                              },
                                              keyup: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.selectLessonSection(
                                                  "noneOther"
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.$t("noneLabel"))
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-card-text",
                                      [
                                        _c(
                                          "v-chip-group",
                                          {
                                            staticClass: "v-chip-group-column",
                                            attrs: {
                                              "active-class":
                                                "error--text font-weight-bold",
                                              column: "",
                                              multiple: "",
                                              value:
                                                _vm.selectedLessonSectionItems
                                            }
                                          },
                                          _vm._l(
                                            _vm.viewLessonSectionItems[2],
                                            function(item, i) {
                                              return _c(
                                                "span",
                                                {
                                                  key: i,
                                                  staticClass: "text-center"
                                                },
                                                [
                                                  _c(
                                                    "pb-chip",
                                                    {
                                                      staticClass:
                                                        "v-chip-column",
                                                      attrs: {
                                                        filter: "",
                                                        outlined: "",
                                                        value: item.value
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.selectLessonSection(
                                                            item.value
                                                          )
                                                        },
                                                        keyup: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          ) {
                                                            return null
                                                          }
                                                          return _vm.selectLessonSection(
                                                            item.value
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "v-chip-text text-center"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.text)
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.userMode != "S" &&
                        _vm.isNotEmpty(_vm.viewLessonSectionItems) &&
                        _vm.viewLessonSectionItems.length > 1 &&
                        _vm.viewLessonSectionItems[1].length > 0 &&
                        index === 4
                          ? _c(
                              "div",
                              { staticClass: "view-settings-col" },
                              [
                                _c(
                                  "v-card",
                                  { attrs: { flat: "" } },
                                  [
                                    _c(
                                      "v-card-title",
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("linkedSectionsLabel")
                                            )
                                          )
                                        ]),
                                        _c(
                                          "pb-chip",
                                          {
                                            staticClass: "ml-2",
                                            staticStyle: { opacity: "1" },
                                            attrs: {
                                              filter: "",
                                              outlined: "",
                                              value: "allLinked",
                                              "active-class":
                                                "accent4--text font-weight-bold",
                                              disabled: _vm.selectedLessonSectionItems.includes(
                                                "allLinked"
                                              ),
                                              "input-value": _vm.selectedLessonSectionItems.includes(
                                                "allLinked"
                                              )
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.selectLessonSection(
                                                  "allLinked"
                                                )
                                              },
                                              keyup: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.selectLessonSection(
                                                  "allLinked"
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(_vm.$t("allLabel")))
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "pb-chip",
                                          {
                                            staticClass: "ml-2",
                                            staticStyle: { opacity: "1" },
                                            attrs: {
                                              filter: "",
                                              outlined: "",
                                              value: "noneLinked",
                                              "active-class":
                                                "accent4--text font-weight-bold",
                                              disabled: _vm.selectedLessonSectionItems.includes(
                                                "noneLinked"
                                              ),
                                              "input-value": _vm.selectedLessonSectionItems.includes(
                                                "noneLinked"
                                              )
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.selectLessonSection(
                                                  "noneLinked"
                                                )
                                              },
                                              keyup: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.selectLessonSection(
                                                  "noneLinked"
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.$t("noneLabel"))
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-card-text",
                                      [
                                        _c(
                                          "v-chip-group",
                                          {
                                            staticClass: "v-chip-group-column",
                                            attrs: {
                                              "active-class":
                                                "accent4--text font-weight-bold",
                                              column: "",
                                              multiple: "",
                                              value:
                                                _vm.selectedLessonSectionItems
                                            }
                                          },
                                          _vm._l(
                                            _vm.viewLessonSectionItems[1],
                                            function(item, i) {
                                              return _c(
                                                "span",
                                                {
                                                  key: i,
                                                  staticClass: "text-center"
                                                },
                                                [
                                                  _c(
                                                    "pb-chip",
                                                    {
                                                      staticClass:
                                                        "v-chip-column",
                                                      attrs: {
                                                        filter: "",
                                                        outlined: "",
                                                        value: item.value
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.selectLessonSection(
                                                            item.value
                                                          )
                                                        },
                                                        keyup: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          ) {
                                                            return null
                                                          }
                                                          return _vm.selectLessonSection(
                                                            item.value
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "v-chip-text text-center"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.text)
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ],
                null,
                false,
                3203948527
              )
            })
          ],
          1
        )
      : _c(
          "div",
          {
            staticClass: "view-content-settings",
            class: { "px-5": !_vm.$vuetify.breakpoint.smAndDown }
          },
          [
            _c("div", { ref: "spacer" }),
            _vm.userMode != "S"
              ? _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "v-card",
                          { attrs: { flat: "" } },
                          [
                            _c(
                              "v-card-title",
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("classesLabel")))
                                ]),
                                _c(
                                  "pb-chip",
                                  {
                                    staticClass: "ml-2",
                                    staticStyle: { opacity: "1" },
                                    attrs: {
                                      filter: "",
                                      outlined: "",
                                      value: "all",
                                      "active-class":
                                        "accent1--text font-weight-bold",
                                      disabled: _vm.selectedClassItems.includes(
                                        "all"
                                      ),
                                      "input-value": _vm.selectedClassItems.includes(
                                        "all"
                                      )
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.selectClass("all")
                                      },
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.selectClass("all")
                                      }
                                    }
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("allLabel")))
                                    ])
                                  ]
                                ),
                                _c(
                                  "pb-chip",
                                  {
                                    staticClass: "ml-2",
                                    staticStyle: { opacity: "1" },
                                    attrs: {
                                      filter: "",
                                      outlined: "",
                                      value: "none",
                                      "active-class":
                                        "accent1--text font-weight-bold",
                                      disabled: _vm.selectedClassItems.includes(
                                        "none"
                                      ),
                                      "input-value": _vm.selectedClassItems.includes(
                                        "none"
                                      )
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.selectClass("none")
                                      },
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.selectClass("none")
                                      }
                                    }
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("noneLabel")))
                                    ])
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-card-text",
                              [
                                _c(
                                  "v-chip-group",
                                  {
                                    attrs: {
                                      "active-class":
                                        "accent1--text font-weight-bold",
                                      column: "",
                                      multiple: "",
                                      value: _vm.selectedClassItems
                                    }
                                  },
                                  _vm._l(_vm.viewClassItems, function(item, i) {
                                    return _c(
                                      "pb-chip",
                                      {
                                        key: i,
                                        attrs: {
                                          filter: "",
                                          outlined: "",
                                          value: item.value
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.selectClass(item.value)
                                          },
                                          keyup: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.selectClass(item.value)
                                          }
                                        }
                                      },
                                      [_c("span", [_vm._v(_vm._s(item.text))])]
                                    )
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.userMode != "S" && _vm.viewStatusItems.length > 1
              ? _c("v-divider")
              : _vm._e(),
            _vm.userMode != "S" &&
            _vm.isNotEmpty(_vm.viewStatusItems) &&
            _vm.viewStatusItems.length > 1
              ? _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "v-card",
                          { attrs: { flat: "" } },
                          [
                            _c(
                              "v-card-title",
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("lessonStatusLabel")))
                                ]),
                                _c(
                                  "pb-chip",
                                  {
                                    staticClass: "ml-2",
                                    staticStyle: { opacity: "1" },
                                    attrs: {
                                      filter: "",
                                      outlined: "",
                                      value: "all",
                                      "active-class":
                                        "accent3--text font-weight-bold",
                                      disabled: _vm.selectedStatusItems.includes(
                                        "all"
                                      ),
                                      "input-value": _vm.selectedStatusItems.includes(
                                        "all"
                                      )
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.selectStatus("all")
                                      },
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.selectStatus("all")
                                      }
                                    }
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("allLabel")))
                                    ])
                                  ]
                                ),
                                _c(
                                  "pb-chip",
                                  {
                                    staticClass: "ml-2",
                                    staticStyle: { opacity: "1" },
                                    attrs: {
                                      filter: "",
                                      outlined: "",
                                      value: "none",
                                      "active-class":
                                        "accent3--text font-weight-bold",
                                      disabled: _vm.selectedStatusItems.includes(
                                        "none"
                                      ),
                                      "input-value": _vm.selectedStatusItems.includes(
                                        "none"
                                      )
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.selectStatus("none")
                                      },
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.selectStatus("none")
                                      }
                                    }
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("noneLabel")))
                                    ])
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-card-text",
                              [
                                _c(
                                  "v-chip-group",
                                  {
                                    attrs: {
                                      "active-class":
                                        "accent3--text font-weight-bold",
                                      column: "",
                                      multiple: "",
                                      value: _vm.selectedStatusItems
                                    }
                                  },
                                  _vm._l(_vm.viewStatusItems, function(
                                    item,
                                    i
                                  ) {
                                    return _c(
                                      "pb-chip",
                                      {
                                        key: i,
                                        attrs: {
                                          filter: "",
                                          outlined: "",
                                          value: item.value
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.selectStatus(item.value)
                                          },
                                          keyup: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.selectStatus(item.value)
                                          }
                                        }
                                      },
                                      [_c("span", [_vm._v(_vm._s(item.text))])]
                                    )
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.userMode != "S" ? _c("v-divider") : _vm._e(),
            _vm.isNotEmpty(_vm.viewLessonSectionItems) &&
            _vm.viewLessonSectionItems[0].length > 0
              ? _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "v-card",
                          { attrs: { flat: "" } },
                          [
                            _c(
                              "v-card-title",
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("lessonSectionsLabel")))
                                ]),
                                _c(
                                  "pb-chip",
                                  {
                                    staticClass: "ml-2",
                                    staticStyle: { opacity: "1" },
                                    attrs: {
                                      filter: "",
                                      outlined: "",
                                      value: "all",
                                      "active-class":
                                        "accent2--text font-weight-bold",
                                      "input-value": _vm.selectedLessonSectionItems.includes(
                                        "all"
                                      ),
                                      disabled: _vm.selectedLessonSectionItems.includes(
                                        "all"
                                      )
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.selectLessonSection("all")
                                      },
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.selectLessonSection("all")
                                      }
                                    }
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("allLabel")))
                                    ])
                                  ]
                                ),
                                _c(
                                  "pb-chip",
                                  {
                                    staticClass: "ml-2",
                                    staticStyle: { opacity: "1" },
                                    attrs: {
                                      filter: "",
                                      outlined: "",
                                      "active-class":
                                        "accent2--text font-weight-bold",
                                      "input-value": _vm.selectedLessonSectionItems.includes(
                                        "none"
                                      ),
                                      disabled: _vm.selectedLessonSectionItems.includes(
                                        "none"
                                      )
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.selectLessonSection("none")
                                      },
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.selectLessonSection("none")
                                      }
                                    }
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("noneLabel")))
                                    ])
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-card-text",
                              [
                                _c(
                                  "v-chip-group",
                                  {
                                    attrs: {
                                      "active-class":
                                        "accent2--text font-weight-bold",
                                      column: "",
                                      multiple: "",
                                      value: _vm.selectedLessonSectionItems
                                    }
                                  },
                                  _vm._l(
                                    _vm.viewLessonSectionItems[0],
                                    function(item, i) {
                                      return _c(
                                        "span",
                                        { key: i },
                                        [
                                          _vm.hasText(item.tooltip)
                                            ? [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      "open-delay": "100",
                                                      bottom: ""
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            return [
                                                              _c(
                                                                "pb-chip",
                                                                _vm._g(
                                                                  {
                                                                    attrs: {
                                                                      filter:
                                                                        "",
                                                                      outlined:
                                                                        "",
                                                                      value:
                                                                        item.value
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.selectLessonSection(
                                                                          item.value
                                                                        )
                                                                      },
                                                                      keyup: function(
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          !$event.type.indexOf(
                                                                            "key"
                                                                          ) &&
                                                                          _vm._k(
                                                                            $event.keyCode,
                                                                            "enter",
                                                                            13,
                                                                            $event.key,
                                                                            "Enter"
                                                                          )
                                                                        ) {
                                                                          return null
                                                                        }
                                                                        return _vm.selectLessonSection(
                                                                          item.value
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.text
                                                                      )
                                                                    )
                                                                  ])
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(item.tooltip)
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ]
                                            : [
                                                _c(
                                                  "pb-chip",
                                                  {
                                                    attrs: {
                                                      filter: "",
                                                      outlined: "",
                                                      value: item.value
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.selectLessonSection(
                                                          item.value
                                                        )
                                                      },
                                                      keyup: function($event) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        ) {
                                                          return null
                                                        }
                                                        return _vm.selectLessonSection(
                                                          item.value
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(_vm._s(item.text))
                                                    ])
                                                  ]
                                                )
                                              ]
                                        ],
                                        2
                                      )
                                    }
                                  ),
                                  0
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.userMode != "S" ? _c("v-divider") : _vm._e(),
            _vm.userMode != "S" &&
            _vm.isNotEmpty(_vm.viewLessonSectionItems) &&
            _vm.viewLessonSectionItems.length > 1 &&
            _vm.viewLessonSectionItems[2].length > 0
              ? _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "v-card",
                          { attrs: { flat: "" } },
                          [
                            _c(
                              "v-card-title",
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("otherSectionsLabel")))
                                ]),
                                _c(
                                  "pb-chip",
                                  {
                                    staticClass: "ml-2",
                                    staticStyle: { opacity: "1" },
                                    attrs: {
                                      filter: "",
                                      outlined: "",
                                      value: "allLinked",
                                      "active-class":
                                        "error--text font-weight-bold",
                                      disabled: _vm.selectedLessonSectionItems.includes(
                                        "allOther"
                                      ),
                                      "input-value": _vm.selectedLessonSectionItems.includes(
                                        "allOther"
                                      )
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.selectLessonSection(
                                          "allOther"
                                        )
                                      },
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.selectLessonSection(
                                          "allOther"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("allLabel")))
                                    ])
                                  ]
                                ),
                                _c(
                                  "pb-chip",
                                  {
                                    staticClass: "ml-2",
                                    staticStyle: { opacity: "1" },
                                    attrs: {
                                      filter: "",
                                      outlined: "",
                                      value: "noneLinked",
                                      "active-class":
                                        "error--text font-weight-bold",
                                      disabled: _vm.selectedLessonSectionItems.includes(
                                        "noneOther"
                                      ),
                                      "input-value": _vm.selectedLessonSectionItems.includes(
                                        "noneOther"
                                      )
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.selectLessonSection(
                                          "noneOther"
                                        )
                                      },
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.selectLessonSection(
                                          "noneOther"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("noneLabel")))
                                    ])
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-card-text",
                              [
                                _c(
                                  "v-chip-group",
                                  {
                                    attrs: {
                                      "active-class":
                                        "error--text font-weight-bold",
                                      column: "",
                                      multiple: "",
                                      value: _vm.selectedLessonSectionItems
                                    }
                                  },
                                  _vm._l(
                                    _vm.viewLessonSectionItems[2],
                                    function(item, i) {
                                      return _c(
                                        "span",
                                        { key: i },
                                        [
                                          _c(
                                            "pb-chip",
                                            {
                                              attrs: {
                                                filter: "",
                                                outlined: "",
                                                value: item.value
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.selectLessonSection(
                                                    item.value
                                                  )
                                                },
                                                keyup: function($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  ) {
                                                    return null
                                                  }
                                                  return _vm.selectLessonSection(
                                                    item.value
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(item.text))
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.userMode != "S" ? _c("v-divider") : _vm._e(),
            _vm.userMode != "S" &&
            _vm.isNotEmpty(_vm.viewLessonSectionItems) &&
            _vm.viewLessonSectionItems.length > 1 &&
            _vm.viewLessonSectionItems[1].length > 0
              ? _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "v-card",
                          { attrs: { flat: "" } },
                          [
                            _c(
                              "v-card-title",
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("linkedSectionsLabel")))
                                ]),
                                _c(
                                  "pb-chip",
                                  {
                                    staticClass: "ml-2",
                                    staticStyle: { opacity: "1" },
                                    attrs: {
                                      filter: "",
                                      outlined: "",
                                      value: "allLinked",
                                      "active-class":
                                        "accent4--text font-weight-bold",
                                      disabled: _vm.selectedLessonSectionItems.includes(
                                        "allLinked"
                                      ),
                                      "input-value": _vm.selectedLessonSectionItems.includes(
                                        "allLinked"
                                      )
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.selectLessonSection(
                                          "allLinked"
                                        )
                                      },
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.selectLessonSection(
                                          "allLinked"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("allLabel")))
                                    ])
                                  ]
                                ),
                                _c(
                                  "pb-chip",
                                  {
                                    staticClass: "ml-2",
                                    staticStyle: { opacity: "1" },
                                    attrs: {
                                      filter: "",
                                      outlined: "",
                                      value: "noneLinked",
                                      "active-class":
                                        "accent4--text font-weight-bold",
                                      disabled: _vm.selectedLessonSectionItems.includes(
                                        "noneLinked"
                                      ),
                                      "input-value": _vm.selectedLessonSectionItems.includes(
                                        "noneLinked"
                                      )
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.selectLessonSection(
                                          "noneLinked"
                                        )
                                      },
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.selectLessonSection(
                                          "noneLinked"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("noneLabel")))
                                    ])
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-card-text",
                              [
                                _c(
                                  "v-chip-group",
                                  {
                                    attrs: {
                                      "active-class":
                                        "accent4--text font-weight-bold",
                                      column: "",
                                      multiple: "",
                                      value: _vm.selectedLessonSectionItems
                                    }
                                  },
                                  _vm._l(
                                    _vm.viewLessonSectionItems[1],
                                    function(item, i) {
                                      return _c(
                                        "span",
                                        { key: i },
                                        [
                                          _c(
                                            "pb-chip",
                                            {
                                              attrs: {
                                                filter: "",
                                                outlined: "",
                                                value: item.value
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.selectLessonSection(
                                                    item.value
                                                  )
                                                },
                                                keyup: function($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  ) {
                                                    return null
                                                  }
                                                  return _vm.selectLessonSection(
                                                    item.value
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(item.text))
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        ),
    _c(
      "div",
      [
        _c(
          "v-row",
          { staticClass: "ma-0 pa-0" },
          [
            _c(
              "v-col",
              {
                staticClass: "ma-0 pa-0 text-right mt-2",
                attrs: { cols: "12" }
              },
              [
                _c(
                  "v-btn",
                  {
                    attrs: { color: "error", elevation: "0", dark: "" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.savePreferences.apply(null, arguments)
                      }
                    }
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.$t("savePreferencesLabel")))])]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }