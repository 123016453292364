import qs from 'qs';
import axios from '@/axios';

export default class CopyServices {
  public static copyAssessments(params?: any): Promise<any> {
    params = params || {};
    return axios.post('services/planbook/assessment/copy', qs.stringify(params, { arrayFormat: 'repeat' }));
  }

  public static copyAssessmentsMany(params?: any): Promise<any> {
    params = params || {};
    return axios.post('services/planbook/assessment/copyMany', qs.stringify(params, { arrayFormat: 'repeat' }));
  }

  public static copyAssessmentsToAll(params?: any): Promise<any> {
    params = params || {};
    return axios.post('services/planbook/assessment/copyToAll', qs.stringify(params, { arrayFormat: 'repeat' }));
  }

  public static copyAssessmentsAll(params?: any): Promise<any> {
    params = params || {};
    return axios.post('services/planbook/assessment/copyAll', qs.stringify(params, { arrayFormat: 'repeat' }));
  }

  public static copyAssignments(params?: any): Promise<any> {
    params = params || {};
    return axios.post('services/planbook/assignment/copy', qs.stringify(params, { arrayFormat: 'repeat' }));
  }

  public static copyAssignmentsMany(params?: any): Promise<any> {
    params = params || {};
    return axios.post('services/planbook/assignment/copyMany', qs.stringify(params, { arrayFormat: 'repeat' }));
  }

  public static copyAssignmentsToAll(params?: any): Promise<any> {
    params = params || {};
    return axios.post('services/planbook/assignment/copyToAll', qs.stringify(params, { arrayFormat: 'repeat' }));
  }

  public static copyAssignmentsAll(params?: any): Promise<any> {
    params = params || {};
    return axios.post('services/planbook/assignment/copyAll', qs.stringify(params, { arrayFormat: 'repeat' }));
  }

  public static copyClasses(params?: any): Promise<any> {
    params = params || {};
    return axios.post('services/planbook/subject/copy', params);
  }

  public static importLessons(params?: any): Promise<any> {
    params = params || {};
    return axios.post('importLessons', qs.stringify(params));
  }

  public static copyStudents(params?: any): Promise<any> {
    params = params || {};
    return axios.post('services/planbook/student/copy', qs.stringify(params, { arrayFormat: 'repeat' }));
  }
}
