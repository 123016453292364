import axios from '@/axios';
import queryString from 'query-string';

export class SchoolYearServices {
  public static async getAdminYear(yearId: number): Promise<any> {
    const params = {
      yearId,
      action: 'one'
    }
    return axios.get('getAdminYears', { params });
  }

  public static async getAdminYears(schoolId: number): Promise<any> {
    const params = {
      schoolId,
      action: 'all'
    }
    return axios.get('getAdminYears', { params });
  }

  public static async getGroupAccountSchoolYears(schoolId: number): Promise<any> {
    return axios.get('services/planbook/school/groupAccountSchoolYears/' + schoolId);
  }

  public static async deleteSchoolYear(yearId: number): Promise<any> {
    return axios.post('deleteYear', queryString.stringify({ yearId }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static async updateAdminYear(params: any) {
    return axios.post('updateAdminYear', queryString.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static async verifySchoolYear(params: any) {
    return axios.post('services/planbook/lesson/schoolYearVerifyShift', queryString.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static async addSchoolYear(params: any) {
    return axios.post('addTerm', queryString.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static deleteSchoolYears(params: any) {
    return axios.delete('services/planbook/schoolyears:batchDelete', {
      data: params,
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  public static updateSchoolYearName(params: any) {
    const requestPath = 'services/planbook/schoolyears/' + params.yearId + ':updateName';
    return axios.post(requestPath, params, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  public static async copyTerm(params?: any) {
    params = params || {};
    return axios.get('copyTerm', { params });
  }
}

export default SchoolYearServices;
