var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.localValue
    ? _c(
        "v-card",
        {
          class: _vm.cardClass,
          attrs: { elevation: "0" },
          on: {
            "click:outside": function ($event) {
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.doClose()
            },
          },
        },
        [
          _c(
            "v-card-title",
            { ref: "toolbar", staticClass: "px-0 pt-0" },
            [
              _vm.withToolbar
                ? _c(
                    "v-toolbar",
                    {
                      attrs: { elevation: "0", tabindex: "-1" },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "escape",
                              undefined,
                              $event.key,
                              undefined
                            )
                          )
                            return null
                          return _vm.doClose()
                        },
                      },
                    },
                    [
                      !_vm.preventClose
                        ? _c(
                            "pb-btn",
                            {
                              staticClass: "ml-1",
                              attrs: {
                                icon: "",
                                right: "",
                                label: _vm.$t("closeLabel"),
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  $event.stopPropagation()
                                  return _vm.doClose()
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("fal fa-times")])],
                            1
                          )
                        : _vm._e(),
                      _c("div", {
                        style: {
                          width: `${
                            90 -
                            (_vm.withActions ? 0 : 50) -
                            (_vm.expandable ? 0 : 50)
                          }px`,
                        },
                      }),
                      _c("v-spacer"),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "white-space": "nowrap",
                            overflow: "hidden",
                          },
                        },
                        [_vm._t("header", null, null, _vm.input)],
                        2
                      ),
                      _c("v-spacer"),
                      _vm._t(
                        "actions",
                        function () {
                          return [
                            _vm.expandable
                              ? _c(
                                  "pb-btn",
                                  {
                                    attrs: {
                                      icon: "",
                                      right: "",
                                      label: _vm.$t("fullscreenLabel"),
                                    },
                                    on: { click: _vm.resize },
                                  },
                                  [
                                    _vm.fullScreen
                                      ? _c("v-icon", [
                                          _vm._v("fal fa-compress-wide"),
                                        ])
                                      : _c("v-icon", [
                                          _vm._v("fal fa-expand-wide"),
                                        ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._t(
                              "action-button",
                              function () {
                                return [
                                  _vm.withActions
                                    ? _c(
                                        "pb-btn",
                                        {
                                          attrs: {
                                            icon: "",
                                            right: "",
                                            label: _vm.$t("actionsLabel"),
                                          },
                                          on: {
                                            click: function ($event) {
                                              !_vm.applying &&
                                                _vm.$emit("actions", $event)
                                            },
                                          },
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v("fal fa-ellipsis-v-alt"),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              },
                              null,
                              _vm.input
                            ),
                            _vm.withApply
                              ? _c(
                                  "pb-btn",
                                  {
                                    attrs: {
                                      icon: "",
                                      right: "",
                                      label: _vm.$t("saveLabel"),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.doApply()
                                      },
                                    },
                                  },
                                  [
                                    _vm.applying
                                      ? _c("v-icon", [
                                          _vm._v("fal fa-sync fa-spin"),
                                        ])
                                      : _c("v-icon", [_vm._v("fal fa-check")]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c("div", { staticClass: "mr-1" }),
                          ]
                        },
                        null,
                        _vm.input
                      ),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-card-text",
            {
              staticClass: "px-0 py-0",
              style: { "overflow-x": "hidden", height: _vm.maxContentHeight },
            },
            [
              _c(
                "div",
                { staticClass: "sub-page-content fill-height" },
                [_vm._t("default", null, null, _vm.input)],
                2
              ),
            ]
          ),
          _c("div", { ref: "bottombar" }, [_vm._t("bottom-actions")], 2),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }