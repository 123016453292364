import CurrentUser from '@/common/current-user';
import { Deferred } from './../common/deferred';
import { UserSession } from '@/common/user-session';
import Google from '@/store/modules/google';
import CommonUtils from '@/utils/common-utils';
import axiosCore, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import moment from 'moment-timezone';
import { CHECK_SUBSCRIPTION } from '@/constants';
import { SubscriptionExpired } from '@/errors';
import ClientUtils from '@/utils/client-utils';
import WebUtils from '@/utils/web-utils';
declare const window: any;

const debug = process.env.NODE_ENV !== 'production';

export const BASE_URL = WebUtils.getBaseUrl();

export class AxiosPolling {
  private axios: AxiosInstance;
  private interceptorId = 0;
  private deferred: Deferred<AxiosResponse<any>>;
  constructor(axios: AxiosInstance) {
    this.axios = axios;
    this.deferred = new Deferred();
  }

  setup(onDataUpdateOrFinish?: (response: AxiosResponse) => void) {
    const retryAfter = (timeout: number, config: AxiosRequestConfig) => {
      return new Promise((resolve) => {
        setTimeout(() => resolve(this.axios(config)), timeout);
      });
    };

    const runRequestAfter = (timeout: number, location: string) => {
      return new Promise((resolve) => {
        setTimeout(() => resolve(this.axios.get(location)), timeout);
      });
    };
    this.interceptorId = this.axios.interceptors.response.use(response => {
      const { config, status } = response;
      if (onDataUpdateOrFinish) {
        onDataUpdateOrFinish(response);
      }
      if (status === 202) {
        if (CommonUtils.hasText(response.data.location)) {
          return runRequestAfter(2000, response.data.location);
        } else {
          return retryAfter(2000, config);
        }
      }
      return this.deferred.resolve(response);
    }, error => {
      return this.deferred.reject(error);
    });
    return this;
  }

  getResult() {
    return this.deferred.promise;
  }

  getAxios() {
    return this.axios;
  }

  stop() {
    this.axios.interceptors.response.eject(this.interceptorId);
  }
}

export class AxiosBuilder {
  private static getAuthBearerToken() {
    return 'Bearer ' + (UserSession.getAccessToken() || '');
  }

  public static build() {
    const axios = axiosCore.create({
      baseURL: BASE_URL
    });

    axios.defaults.withCredentials = true;
    axios.defaults.headers.common['X-PB-MOMENT-TZ'] = moment.tz.guess();
    axios.defaults.headers.common['X-PB-REQUEST-VERSION'] = 'V12';
    axios.defaults.headers.common['X-PB-CLIENT-VIEW-ID'] = ClientUtils.getClientViewId();

    axios.interceptors.request.use(config => {
      const url = config.url;
      if (CHECK_SUBSCRIPTION.includes(url as string) && (CurrentUser.isPaymentNeeded || CurrentUser.isSchoolSuspended)) {
        throw new SubscriptionExpired();
      }
      if (!url?.endsWith('services/security/login') && config.headers) {
        config.headers['X-PB-CLIENT-YEAR-ID'] = UserSession.getCurrentYearId();
      }
      return config;
    }, error => {
      return Promise.reject(error);
    });

    axios.interceptors.response.use(resp => {
      const config = resp.config;
      const url = config.url;
      const token = resp.headers['access-token'] || '';
      const gstoken = resp.headers['gs-token'] || '';
      const yearId = resp.headers['user-year'];
      if (!url?.endsWith('services/security/login') && CommonUtils.hasText(token)) {
        UserSession.setCurrentUserFromToken(token, yearId);
      }
      UserSession.setGoogleAuthToken(gstoken);
      Google.setIsConnectedToGoogle(CommonUtils.hasText(gstoken));
      return resp;
    }, error => {
      return Promise.reject(error);
    });
    return axios;
  }

  public static buildPolling() {
    return new AxiosPolling(AxiosBuilder.build());
  }
}

if (debug) {
  window.AxiosBuilder = AxiosBuilder;
}

const axios = AxiosBuilder.build();
export default axios;
