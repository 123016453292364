import AppError, { createErrOptions, SessionExpiredError } from '@/errors';
import DayServices from '@/services/day-services';
import LessonServices from '@/services/lesson-services';
import ld from 'lodash';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true })
export default class DayActions extends VuexModule {
    copy = [
      { id: 'all', section: 'all', selected: true },
      { id: 'lessonText', section: 'lesson', selected: false },
      { id: 'tab2Text', section: 'tab2', selected: false },
      { id: 'tab3Text', section: 'tab3', selected: false },
      { id: 'tab4Text', section: 'tab4', selected: false },
      { id: 'tab5Text', section: 'tab5', selected: false },
      { id: 'tab6Text', section: 'tab6', selected: false },
      { id: 'standards', section: 'sharedStandards', selected: false },
      { id: 'myStandards', section: 'myStandards', selected: false },
      { id: 'schoolStandards', section: 'schoolStandards', selected: false },
      { id: 'strategies', section: 'strategies', selected: false },
      { id: 'attachments', section: 'attachments', selected: false },
      { id: 'linkedLessonId', section: 'linkedLessonId', selected: false }
    ];

    forward = 1;
    backward = 1;
    extend = 1;
    extendStandards = 1;
    printOptions: any = {};
    day: any = {};
    toCopy: any = {};

    get getDayLessons() {
      return this.getDayObjects.filter(o => o.type === 'L').map(o => LessonServices.populateLesson({ lesson: o.rawDayObject }, { setLesson: true })).map(l => {
        delete l.lesson;
        return l;
      });
    }

    get getSaveLessonsRequest() {
      return this.getDayLessons.map(l => LessonServices.createRequest(l));
    }

    get getDayObjects(): Array<any> {
      return this.day.dayObjects || [];
    }

    get getCopyLabel() {
      let copyCount = 0;
      for (const i in this.copy) {
        const c = this.copy[i];
        if (c.section !== 'all' && c.selected) {
          copyCount++;
        }
      }
      return copyCount > 0 ? '' + copyCount : 'All';
    }

    get isCopyAllSections() {
      return this.copy[0].selected;
    }

    get getSectionsToCopy() {
      const toCopy = [];
      if (!this.isCopyAllSections) {
        for (const i in this.copy) {
          if (this.copy[i].selected) {
            toCopy.push(this.copy[i].id);
          }
        }
      }
      return toCopy;
    }

    @Mutation
    setCopy(copy: Array<any>) {
      this.copy = copy;
    }

    @Mutation
    setForward(forward: number) {
      this.forward = forward;
    }

    @Mutation
    setBackward(backward: number) {
      this.backward = backward;
    }

    @Mutation
    setExtend(extend: number) {
      this.extend = extend;
    }

    @Mutation
    setExtendStandards(extendStandards: number) {
      this.extendStandards = extendStandards;
    }

    @Mutation
    setPrintOptions(printOptions: any) {
      this.printOptions = printOptions;
    }

    @Mutation
    setDay(day: any) {
      this.day = day;
    }

    @Mutation
    reset(day?: any) {
      this.copy = [];
      this.forward = 1;
      this.backward = 1;
      this.extend = 1;
      this.extendStandards = 1;
      this.printOptions = {};
      this.day = day || {};
      this.toCopy = {};
    }

    @Action({ rawError: true })
    init(day?: any) {
      this.context.commit('reset', day);
      return Promise.resolve();
    }

    @Action({ rawError: true })
    async saveLessons(requests: Array<any>) {
      try {
        const promises = requests.map(r => {
          return LessonServices.updateLesson(r);
        });
        return Promise.all(promises).then(responses => {
          return Promise.resolve(responses.filter(r => r).map(r => r.data));
        }).then(async data => {
          let notLoggedIn = false;
          const failed: Array<any> = [];
          const success: Array<any> = [];
          data.forEach((d: any, i: number) => {
            if (d.notLoggedIn === 'true' || d.notLoggedIn === true) {
              notLoggedIn = notLoggedIn || true;
            } else if (d.error === 'true' || d.error === true) {
              failed.push({
                request: requests[i],
                response: d
              })
            } else {
              success.push({
                request: requests[i],
                response: d
              })
            }
          });
          if (notLoggedIn) {
            return Promise.reject(new SessionExpiredError());
          } else {
            return this.context.dispatch('plans/reloadPlans', {}, { root: true }).then(() => {
              return Promise.resolve({
                failed,
                success
              });
            });
          }
        });
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async save(params?: any) {
      this.context.commit('setSaving', true);
      return this.context.dispatch('saveLessons', params).finally(() => {
        this.context.commit('setSaving', false);
      })
    }

    @Action({ rawError: true })
    async bumpLessons(params?: any) {
      try {
        const requests = this.getSaveLessonsRequest.filter((r) => {
          return r.extraLesson === 0 && params.mainLessonsOnly && !Object.prototype.hasOwnProperty.call(r, 'customTimeClassId');
        }).map(r => {
          const request = ld.cloneDeep(r);
          request.fetchDay = false;
          request.action = 'bump';
          request.numDays = params.numDays;
          request.verify = params.verify;
          return request;
        });
        const promises = requests.map(r => {
          return LessonServices.bump(r);
        });
        return Promise.all(promises).then(responses => {
          return Promise.resolve(responses.filter(r => r).map(r => r.data));
        }).then(async data => {
          let notLoggedIn = false;
          const failed: Array<any> = [];
          const success: Array<any> = [];
          const confirmDeletes: Array<any> = [];
          data.forEach((d: any, i: number) => {
            if (d.notLoggedIn === 'true' || d.notLoggedIn === true) {
              notLoggedIn = notLoggedIn || true;
            } else if (d.error === 'true' || d.error === true) {
              failed.push({
                request: requests[i],
                response: d
              })
            } else if (d.count > 0) {
              confirmDeletes.push({
                request: requests[i],
                response: d
              })
            } else {
              success.push({
                request: requests[i],
                response: d
              })
            }
          });
          if (notLoggedIn) {
            return Promise.reject(new SessionExpiredError());
          } else {
            return this.context.dispatch('plans/reloadPlans', {}, { root: true }).then(() => {
              return Promise.resolve({
                failed,
                success,
                confirmDeletes
              });
            });
          }
        });
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async substituteDay(params: any) {
      try {
        const resp = await DayServices.substituteDay(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            this.context.commit('settings/setSubPlans', params.notes || '', { root: true });
            await this.context.dispatch('plans/reloadPlans', {}, { root: true });
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }
}
