



























import { Component, Vue } from 'vue-property-decorator';
import CommonUtils from '@/utils/common-utils';
import { namespace } from 'vuex-class';

const settings = namespace('settings');

@Component
export default class Content extends Vue {
  @settings.State
  userMode!: string;

  get isEmbedded(): boolean {
    return (CommonUtils.hasText(this.$router.currentRoute.query.embedded) && this.$router.currentRoute.query.embedded === 'true');
  }

  get localPage() {
    return CommonUtils.getPage(this.$route);
  }

  get mainClass() {
    if (['plans', 'students', 'messages'].includes(this.localPage) && this.userMode === 'A') {
      return 'pa-0';
    } else {
      return 'pl-0';
    }
  }
}
