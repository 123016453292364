import qs, { stringify } from 'qs';
import axios from '@/axios';

export default class StudentTagServices {
  public static getTags(params?: any) {
    params = params || {};
    return axios.get('services/planbook/studenttags/get', { params });
  }

  public static addTag(params?: any) {
    params = params || {};
    return axios.post('services/planbook/studenttags/add', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static updateTag(params?: any) {
    params = params || {};
    return axios.put('services/planbook/studenttags/update', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static deleteTag(params?: any) {
    params = params || {};
    return axios.delete('services/planbook/studenttags/delete', {
      data: stringify(params),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }
}
