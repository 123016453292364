import axios, { BASE_URL } from '@/axios/index';
import qs from 'qs';

import CommonUtils from '@/utils/common-utils';

export default class EventServices {
  public static getEvents(params?: any) {
    params = params || {};
    return axios.post('getEvents', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static getEventNotificationsById(params?: any) {
    params = params || {};
    return axios.post('services/planbook/event/getEventNotificationsById', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static async addEvent(params?: any) {
    params = params || {};
    const resp = await axios.post('addEvent', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });

    if (params.verifyShift) {
      const data = resp ? resp.data : null;
      if (!data || data.notLoggedIn === 'true' || data.notLoggedIn === true || data.error === 'true' || data.error === true || CommonUtils.isNotEmpty(data.deletedLessons)) {
        return resp;
      } else {
        params.verifyShift = false;
        return axios.post('addEvent', qs.stringify(params, { arrayFormat: 'repeat' }), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });
      }
    } else {
      return resp;
    }
  }

  public static async updateEvent(params?: any) {
    params = params || {};
    const resp = await axios.post('updateEvent/v5', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });

    if (params.verifyShift) {
      const data = resp ? resp.data : null;
      if (!data || data.notLoggedIn === 'true' || data.notLoggedIn === true || data.error === 'true' || data.error === true || CommonUtils.isNotEmpty(data.deletedLessons)) {
        return resp;
      } else {
        params.verifyShift = false;
        return axios.post('updateEvent/v5', qs.stringify(params, { arrayFormat: 'repeat' }), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });
      }
    } else {
      return resp;
    }
  }

  public static async setNotifications(params?: any) {
    params = params || {};
    return axios.post('services/planbook/event/setNotifications', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static deleteEvent(params?: any) {
    params = params || {};
    return axios.post('deleteEvent', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static hideGoogleCalendarEvent(params?: any) {
    params = params || {};
    return axios.post('services/planbook/googleCalendar/hideEvent', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static updateGoogleCalendarEvents(params?: any) {
    params = params || {};
    return axios.post('services/planbook/googleCalendar/updateEvents', params, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  public static deleteEvents(params: any) {
    return axios.delete('services/planbook/event', {
      data: params,
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  public static adminEventShift(params?: any) {
    params = params || {};
    return axios.post('adminEventShift', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static getSharedTerms(params?: any) {
    params = params || {};
    return axios.post('getSharedTerms', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static importEvents(params?: any) {
    params = params || {};
    return axios.post('importEvents', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static uploadEvents(params: any) {
    const formData = new FormData();
    for (const key in params) {
      formData.append(key, params[key]);
    }
    return axios.post(BASE_URL + '/uploadEvents', formData);
  }
}
