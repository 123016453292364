import { PlanbookStorage } from '@/common/planbook-storage';
import { UserSession } from '@/common/user-session';
import store from '@/store';
import CommonUtils from '@/utils/common-utils';

declare const window: any;
const debug = process.env.NODE_ENV !== 'production';

export default class CommonServices {
  public static async initSession(params?: any): Promise<any> {
    CommonUtils.showLoading();
    return store.dispatch('settings/loadSettings', params ? params.settings : undefined).then(async () => {
      const paramUserMode = params.userMode;
      const userType = store.getters['settings/getUserType'];
      if (CommonUtils.hasText(params.userMode)) {
        if (['D', 'B', 'A'].includes(userType) && paramUserMode === 'A') {
          store.commit('settings/setUserMode', paramUserMode);
          PlanbookStorage.set('userMode', paramUserMode);
        } else {
          store.commit('settings/setUserMode', 'T');
          PlanbookStorage.set('userMode', 'T');
        }
      }
      const userMode = store.state.settings.userMode;
      const currentUser: any = UserSession.getCurrentUser() || {};
      if (debug) window.app.$storage = PlanbookStorage.getStorage();
      const teacherId = PlanbookStorage.get('currentTeacherId') || 0;
      if (userMode === 'S' && +currentUser.teacherId > 0 && CommonUtils.hasText(currentUser.key)) {
        store.commit('settings/setCurrentTeacherId', currentUser.teacherId);
        store.commit('settings/setCurrentYearId', currentUser.currentSchoolYearId);
        store.commit('settings/setCurrentSubjectId', currentUser.subjectId);
      } else if (userMode === 'A') {
        store.commit('settings/setCurrentTeacherId', +PlanbookStorage.get('currentTeacherId') || 0);
        store.commit('settings/setCurrentYearId', +PlanbookStorage.get('currentYearId', 'T' + teacherId) || 0);
        return store.dispatch('teachers/loadTeachers').then(() => {
          const currentTeacherYears = store.getters['teachers/getAdminYearItems'](teacherId);
          if (+store.state.settings.currentYearId === 0 && currentTeacherYears.length) {
            store.commit('settings/setCurrentYearId', currentTeacherYears[0].value);
          }
          PlanbookStorage.set('currentTeacherId', +store.state.settings.currentTeacherId);
          PlanbookStorage.set('currentYearId', 'T' + teacherId, +store.state.settings.currentYearId);
          store.commit('reporting/reset', {}, { root: true });
          store.commit('reporting/init', {}, { root: true });
          return Promise.resolve();
        });
      } else {
        return Promise.resolve();
      }
    }).then(async () => {
      const userMode = store.getters['settings/getUserMode'];
      const currentYearId = store.getters['settings/getCurrentSchoolYearId'];
      if (userMode === 'M') {
        store.commit('classes/setHaveClass', true);
        return Promise.resolve({ newUser: false });
      } else if (userMode === 'S') {
        if (currentYearId > 0) {
          return store.dispatch('classes/loadClasses').then(() => {
            store.commit('classes/setHaveClass', true);
            return Promise.resolve({ newUser: false });
          });
        }
      } else {
        if (!currentYearId || currentYearId === 0 || isNaN(currentYearId)) {
          return Promise.resolve({ newUser: true });
        } else {
          return store.dispatch('classes/loadClasses').then(() => {
            store.commit('classes/setHaveClass', true);
            return Promise.resolve({ newUser: false });
          });
        }
      }
      return Promise.resolve({ newUser: false });
    }).then(async (data) => {
      if (data.newUser) {
        return Promise.resolve(data);
      } else {
        const schoolYearId = store.getters['settings/getCurrentSchoolYearId'];
        const userMode = store.state.settings.userMode;
        if (userMode !== 'S' && !schoolYearId) {
          return store.dispatch('referenceData/loadHolidays').then(() => {
            return store.dispatch('referenceData/loadCountriesStates');
          });
        } else {
          return Promise.resolve({ newUser: false });
        }
      }
    }).finally(() => {
      CommonUtils.hideLoading();
    });
  }

  public static getUserInfo() {
    return store.getters('settings/getUserInfo');
  }
}
