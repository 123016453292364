import axios from '@/axios/index';
import qs from 'qs';

export default class AttendanceServices {
  public static getLessonsByDate(params?: any) {
    params = params || {};
    return axios.post('/services/planbook/attendance/getLessonsByDate', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static getStudents(params?: any) {
    params = params || {};
    return axios.get('/services/planbook/attendance/get', {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      params: {
        subjectIds: params.subjectIds,
        attendanceDate: params.attendanceDate,
        extraClass: params.extraClass
      }
    });
  }

  public static addAttendance(params?: any) {
    params = params || {};
    return axios.post('/services/planbook/attendance/add', params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }
}
