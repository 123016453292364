
import CommonUtils from '@/utils/common-utils';
import DateTimeUtils from '@/utils/date-time-utils';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import moment, { Moment } from 'moment';
import { planbookColors, classEditMoreOptions, allowedGracePeriodDays, collaborateSelectItems } from '../../constants';
import ld from 'lodash';
import Confirm from './../core/Confirm.vue';
import { VForm } from 'vuetify/lib';
import ManageLessons from '@/components/classes/ManageLessons.vue';

const settings = namespace('settings');
const google = namespace('google');
const classes = namespace('classes');
const lessonlayouts = namespace('lessonlayouts');

@Component({
  components: {
    ManageLessons
  }
})
export default class ClassEditorForm extends Vue {
  localRefreshKey = CommonUtils.generateUUID();
  localClassOptions = classEditMoreOptions;
  localSelectedClassClone: any = {};
  localSelectedClassScheduleArrayClone: any[] = [];
  localClassCycle = '';
  localWeekCycleWeekdaysOnly = true;
  localClassEditorSelectedTab = 0;
  localClassNameShowFormatter = false;
  localClassStartDateMessages: any = [];
  localClassStartDateErrorMessage = '';
  localClassStartUseSchoolStart = true;
  localClassEndDateMessages: any = [];
  localClassEndDateErrorMessage = '';
  localClassEndUseSchoolEnd = true;
  localClassNoStudents = false;
  localClassLessonLayout = '';
  localClassRemoveDate = '';
  localClassRemoveDateErrorMessage = '';
  localClassScheduleFrequency = [{ text: this.$t('dailyLabel'), value: 'D' }, { text: this.$t('dailySameTimeLabel'), value: 'DS' }, { text: this.$t('customLabel'), value: 'C' }];
  localClassScheduleFrequencySelected = 'D';
  localClassScheduleArraySelected = 0;
  localClassScheduleArrayStartDates: any = [];
  localDailyStartTime = '';
  localDailyStartTimeErrors: any = [];
  localDailyEndTime = '';
  localDailyEndTimeErrors: any = [];
  localStartTimeErrors: any = [];
  localEndTimeErrors: any = [];
  localACDStartTimeErrors: any = [];
  localACDEndTimeErrors: any = [];
  localShowRemoveDays = false;
  localShowDescription = false;
  localOldNoClassDays = [];
  localOldSchedule: any = [];
  localOldAddedClassDays = [];
  localNeedConfirmation = false;
  localDaysOfWeek: any = [];
  localWeekDayCodesAlphaKeys = { U: this.$t('sundayLabel'), M: this.$t('mondayLabel'), T: this.$t('tuesdayLabel'), W: this.$t('wednesdayLabel'), H: this.$t('thursdayLabel'), F: this.$t('fridayLabel'), S: this.$t('saturdayLabel'), UU: this.$t('sundayLabel'), MM: this.$t('mondayLabel'), TT: this.$t('tuesdayLabel'), WW: this.$t('wednesdayLabel'), HH: this.$t('thursdayLabel'), FF: this.$t('fridayLabel'), SS: this.$t('saturdayLabel') };
  localClassEditScheduleMenu: any = [false, false];
  localClassScheduleNewStart = '';
  localClassScheduleNewStartErrorMessage = '';
  localReplicaSelectedTab = 0;
  localTeacherDropdownItems: any = [];
  localSchoolYearDropdownItems: any = [];
  localSchoolYearDropdownItemsLoading = false;
  localSubjectDropdownItems: any = [];
  localSubjectDropdownItemsLoading = false;
  localCollaborateTeacherEmail = '';
  localCollaborateTeacherEmailError = '';
  localSavedCollaborateTeacherEmailError = '';
  localCollaborateTeacherKey = '';
  localCollaborateTeacherKeyError = '';
  localCollaborateSaveTeacher = true;
  localShowCollaborateClassesLoading = false;
  localIsGoogleConnected = false;
  localGoogleConnectedName = '';
  localLessonLayouts: any = [];
  showManageLessons = false;
  localEditScheduleOption = '';
  localIsEditingSchedule = false;
  localOldSelectedClassClone: any = {};
  week1DayCodes = ['U', 'M', 'T', 'W', 'H', 'F', 'S'];
  week2DayCodes = ['UU', 'MM', 'TT', 'WW', 'HH', 'FF', 'SS'];

  scheduleTableHeaders = [
    {
      text: this.$t('dayLabel'),
      align: 'start',
      sortable: false,
      value: 'title',
      width: 140
    },
    {
      text: this.$t('startLabel'),
      align: 'end',
      sortable: false,
      value: 'start',
      width: 110
    },
    {
      text: this.$t('endLabel'),
      align: 'end',
      sortable: false,
      value: 'end',
      width: 110
    }
  ];

  additionalScheduleTableHeaders = [
    {
      text: '',
      align: 'start',
      sortable: false,
      value: 'title',
      width: 140
    },
    {
      text: '',
      align: 'end',
      sortable: false,
      value: 'start',
      width: 110
    },
    {
      text: '',
      align: 'end',
      sortable: false,
      value: 'end',
      width: 110
    }
  ];

  localRules = [
    (v: any) => !!v || 'Required'
  ];

  $refs!: {
    classEditorForm: InstanceType<typeof VForm>,
    editScheduleMenuForm0: InstanceType<typeof VForm>,
    editScheduleMenuForm1: InstanceType<typeof VForm>,
    classEditorConfirm: Confirm,
    classNameFormatter: any
  }

  @google.State
  googleClasses!: any;

  @google.Getter
  getIsConnectedToGoogle!: boolean;

  @settings.Getter
  getUserInfo!: any;

  @settings.Getter
  getDisplaySettings!: any;

  @settings.Getter
  getSettings!: any;

  @settings.Getter
  getSchoolYearSettings!: any;

  @settings.Getter
  getCurrentYear!: any;

  @settings.Getter
  getUserMode!: any;

  @settings.Getter
  getEmailAddress!: any;

  @settings.Getter
  getSharingOptionsSettings!: any;

  @settings.Action
  saveSharedTeacher!: (params?: any) => Promise<any>;

  @settings.Getter('getTimeFormat')
  timeFormat!: number;

  @settings.Getter('getBrowserWidth')
  browserWidth!: number;

  @settings.Getter('getShowSnackbarNotifications')
  showSnackbarNotifications!: boolean;

  @classes.Getter
  startTime24HrFmt!: any;

  @classes.Getter
  endTime24HrFmt!: any;

  @classes.State
  selectedClass!: any;

  @classes.State
  sharedClasses!: any;

  @classes.Getter('cycleDaysForEditor')
  cycleDays!: any;

  @classes.Getter
  classScheduleDefault!: any;

  @classes.Action
  getSharedClasses!: (params?: any) => Promise<any>;

  @classes.Action
  addClass!: (params?: any) => Promise<any>;

  @classes.Action
  updateClass!: (params?: any) => Promise<any>;

  @classes.Action
  deleteClass!: (params?: any) => Promise<any>;

  @classes.Action
  removeLinkedLessons!: (params?: any) => Promise<any>;

  @lessonlayouts.Getter
  getLayouts!: any;

  @lessonlayouts.Action
  loadLayouts!: () => Promise<any>;

  @classes.Action('export')
  doExport!: (params: any) => Promise<any>;

  @settings.Getter('getUserMode')
  userMode!: string;

  @Prop({ required: false, type: Function })
  onEditorClose?: () => Promise<any>;

  @Prop({
    type: Object,
    default: () => {
      return {};
    }
  })
  input!: any;

  @Watch('localClassEditorSelectedTab')
  updateProperties(v: number) {
    if (v === 0) {
      this.localClassNoStudents = false;
    } else if (v === 1) {
      this.localClassNoStudents = true;
    }
  }

  @Watch('localClassStartUseSchoolStart')
  updateUseSchoolStart(v: boolean) {
    this.localSelectedClassClone.useSchoolStart = v ? 'Y' : 'N';
    if (v) {
      this.localSelectedClassClone.classStartDate = this.getCurrentYear.firstDay;
    }
  }

  @Watch('localClassEndUseSchoolEnd')
  updateUseSchoolEnd(v: boolean) {
    this.localSelectedClassClone.useSchoolEnd = v ? 'Y' : 'N';
    if (v) {
      this.localSelectedClassClone.classEndDate = this.getCurrentYear.lastDay;
    }
  }

  @Watch('localClassNoStudents')
  updateNoStudents(v: boolean) {
    this.localSelectedClassClone.noStudents = v ? 'Y' : 'N';
  }

  get isEmpty() {
    return CommonUtils.isEmpty;
  }

  get arrayAddOrRemove() {
    return CommonUtils.arrayAddOrRemove;
  }

  get fcStartOfDay(): Moment {
    return moment().startOf('day');
  }

  get mCurrentDate() {
    return moment();
  }

  get mCurrentYearFirstDay() {
    return moment(this.getCurrentYear.firstDay, 'MM/DD/YYYY');
  }

  get mCurrentYearLastDay() {
    return moment(this.getCurrentYear.lastDay, 'MM/DD/YYYY');
  }

  get isNewClass(): boolean {
    return !(this.localSelectedClassClone.classId && this.localSelectedClassClone.classId > 0);
  }

  get selectedClassExtraLessonsClone(): any {
    if (this.localSelectedClassClone.extraLessons && this.localSelectedClassClone.extraLessons.length > 0) {
      return this.localSelectedClassClone.extraLessons;
    }
    return [];
  }

  removeExtraLesson(index: number) {
    this.localSelectedClassClone.extraLessons.splice(index, 1);
  }

  get collaborateItems(): any {
    return collaborateSelectItems;
  }

  get showConnectToGoogle(): boolean {
    return this.getIsConnectedToGoogle && this.googleClasses.length > 0;
  }

  get googleClassesList(): any {
    return this.googleClasses;
  }

  get classSchedule(): any {
    if (this.getSchoolYearSettings.classCycle === 'oneWeek') {
      return this.classScheduleDefault[0];
    } else if (this.getSchoolYearSettings.classCycle === 'twoWeeks') {
      return this.classScheduleDefault[1]
    } else {
      return this.classScheduleDefault[2]
    }
  }

  @Watch('localIsGoogleConnected')
  connectedGoogleClassName() {
    if (this.localIsGoogleConnected) {
      const connectedGoogleClass = this.googleClasses.filter((a: any) => a.id === this.localSelectedClassClone.sourceId);
      if (connectedGoogleClass[0]) {
        this.localGoogleConnectedName = connectedGoogleClass[0].name;
      } else {
        this.localGoogleConnectedName = '';
      }
    } else {
      this.localGoogleConnectedName = '';
    }
  }

  getCurrentActiveScheduleIndex(schedules: any) {
    let index = 0;
    for (let x = 0; x < schedules.length; x++) {
      const mScheduleStart = moment(schedules[x].scheduleStart, 'MM/DD/YYYY');
      if (this.fcStartOfDay.isSame(mScheduleStart) || this.fcStartOfDay.isAfter(mScheduleStart)) {
        index = x;
        break;
      }
    }
    return index;
  }

  getDisplayDate(date: string) {
    if (CommonUtils.hasText(date)) {
      return DateTimeUtils.formatToShow(date, this.getSettings);
    }
    return '....'
  }

  getExtraLessonOrNoClassDate(date: string) {
    if (CommonUtils.hasText(date)) {
      return moment(date, 'YYYY-MM-DD').format('MM/DD/YYYY');
    }
    return '';
  }

  getScheduleStartDisplayDateNoYear(scheduleStart: string) {
    return this.getDisplayDate(scheduleStart).substr(0, 5);
  }

  showClassNameFormatter(focused: boolean) {
    this.localClassNameShowFormatter = focused;
  }

  @Watch('localClassNameShowFormatter')
  openClassNameFormatter() {
    if (this.localClassNameShowFormatter) {
      this.$nextTick(() => {
        this.$refs.classNameFormatter.focus();
      });
    }
  }

  initialize() {
    CommonUtils.showLoading();
    const form: any = this.$refs.classEditorForm;
    const me = 'Me (' + this.getEmailAddress + ')';
    if (form) {
      form.resetValidation();
    }
    if (typeof this.selectedClass.sourceId === 'undefined') {
      this.selectedClass.source = '';
      this.selectedClass.sourceId = '';
      this.selectedClass.sourceSettings = {
        connectStudents: false,
        connectAssignments: false,
        connectGrades: false
      };
      this.localIsGoogleConnected = false;
    } else if (this.selectedClass.source === 'gc') {
      this.localIsGoogleConnected = true;
    }
    this.localSelectedClassClone = ld.cloneDeep(this.selectedClass);
    if (this.isNewClass) {
      this.localIsEditingSchedule = true;
      this.localSelectedClassClone.className = this.input.className;
      this.localSelectedClassClone.color = this.input.color && this.input.color !== '' ? this.input.color : planbookColors[Math.floor(Math.random() * planbookColors.length)];
      this.localClassScheduleFrequencySelected = 'D';
    } else {
      this.localIsEditingSchedule = false;
    }
    if (this.localSelectedClassClone.noStudents === 'Y' || this.localSelectedClassClone.noStudents === true) {
      this.localClassEditorSelectedTab = 1;
    } else {
      this.localClassEditorSelectedTab = 0;
    }
    if (!this.localSelectedClassClone.classSchedule || this.localSelectedClassClone.classSchedule.length === 0) {
      this.localSelectedClassClone.classSchedule = [this.classSchedule];
    }
    this.localSelectedClassScheduleArrayClone = this.localSelectedClassClone.classSchedule;
    this.localSelectedClassScheduleArrayClone.sort(function (a: any, b: any) {
      const ma = moment(a.scheduleStart, 'MM/DD/YYYY');
      const mb = moment(b.scheduleStart, 'MM/DD/YYYY');
      return mb.diff(ma, 'days');
    });
    this.localClassScheduleArrayStartDates = [];
    this.localSelectedClassScheduleArrayClone.forEach((sched: any) => {
      this.localClassScheduleArrayStartDates.push(sched.scheduleStart);
      if (sched.additionalClassDays && sched.additionalClassDays.length > 0) {
        sched.additionalClassDays.sort(function (a: any, b: any) {
          return a.extraDay - b.extraDay;
        });
      }
    });
    this.localClassCycle = this.getSchoolYearSettings.classCycle;
    this.localClassScheduleArraySelected = this.getCurrentActiveScheduleIndex(this.localSelectedClassScheduleArrayClone);
    if (!(this.localSelectedClassClone.classStartDate && this.localSelectedClassClone.classStartDate !== '')) {
      this.localSelectedClassClone.classStartDate = this.getCurrentYear.firstDay;
    }
    this.localClassStartUseSchoolStart = this.localSelectedClassClone.useSchoolStart === 'Y';
    if (!(this.localSelectedClassClone.classEndDate && this.localSelectedClassClone.classEndDate !== '')) {
      this.localSelectedClassClone.classEndDate = this.getCurrentYear.lastDay;
    }
    this.localClassEndUseSchoolEnd = this.localSelectedClassClone.useSchoolEnd === 'Y';
    this.localShowRemoveDays = false;
    this.localShowDescription = false;
    if (!this.localSelectedClassClone.noClass) {
      this.localSelectedClassClone.noClass = [];
    }
    this.localOldNoClassDays = ld.cloneDeep(this.localSelectedClassClone.noClass);
    this.localOldSchedule = ld.cloneDeep(this.localSelectedClassClone.classSchedule);
    this.localOldAddedClassDays = ld.cloneDeep(this.localSelectedClassClone.extraLessons);
    this.localOldSelectedClassClone = ld.cloneDeep(this.localSelectedClassClone);
    // To make sure changesMade function will work accurately
    this.formatAdditionalClassDaysClassTimes();
    this.cycleDays.forEach((cycleDay: any) => {
      const teachDayIndex = +cycleDay.id.substr(cycleDay.id.lastIndexOf('y') + 1);
      const day = {
        text: cycleDay.title,
        value: this.getSchoolYearSettings.classCycle === 'oneWeek' || this.getSchoolYearSettings.classCycle === 'twoWeeks' ? Object.keys(this.localWeekDayCodesAlphaKeys)[Number(teachDayIndex - 1)] : String(teachDayIndex - 1)
      }
      this.localDaysOfWeek.push(day);
      cycleDay.classScheduleDayIndex = teachDayIndex;
    });
    this.localClassScheduleFrequencySelected = 'D';
    this.localDailyStartTime = '';
    this.localDailyStartTimeErrors = [];
    this.localDailyEndTime = '';
    this.localDailyEndTimeErrors = [];
    this.localStartTimeErrors = [];
    this.localEndTimeErrors = [];
    this.localACDStartTimeErrors = [];
    this.localACDEndTimeErrors = [];
    this.localClassRemoveDate = '';
    this.localClassStartDateMessages = [];
    this.localClassStartDateErrorMessage = '';
    this.localClassEndDateMessages = [];
    this.localClassEndDateErrorMessage = '';
    this.localClassRemoveDateErrorMessage = '';
    this.showClassNameFormatter(false);
    this.localClassScheduleNewStart = '';
    this.localClassScheduleNewStartErrorMessage = '';
    this.localReplicaSelectedTab = 0;
    this.localTeacherDropdownItems = [{ text: me, value: this.getEmailAddress, key: this.getSharingOptionsSettings.sharedUserKey }];
    this.localSchoolYearDropdownItems = [];
    this.localSchoolYearDropdownItemsLoading = false;
    this.localSubjectDropdownItems = [];
    this.localSubjectDropdownItemsLoading = false;
    this.localCollaborateTeacherEmail = '';
    this.localCollaborateTeacherEmailError = '';
    this.localSavedCollaborateTeacherEmailError = '';
    this.localCollaborateTeacherKey = '';
    this.localCollaborateTeacherKeyError = '';
    this.localCollaborateSaveTeacher = true;
    this.localShowCollaborateClassesLoading = false;
    Promise.all([
      this.initLessonLayouts(),
      this.initCollaborateTeachers()
    ]).finally(() => {
      CommonUtils.hideLoading();
      this.localRefreshKey = CommonUtils.generateUUID();
    });
  }

  deleteItemFromScheduleArray(index: number, scheduleStart: string) {
    if (this.localSelectedClassScheduleArrayClone[index].scheduleStart === scheduleStart) {
      this.localSelectedClassScheduleArrayClone.splice(index, 1);
      this.localRefreshKey = CommonUtils.generateUUID();
    }
  }

  applyClass() {
    const form: any = this.$refs.classEditorForm;
    var hasLocalStartTimeErrors = false;
    var hasLocalEndTimeErrors = false;
    var hasLocalACDStartTimeErrors = false;
    var hasLocalACDEndTimeErrors = false;
    for (var j = 0; j < this.localStartTimeErrors.length; j++) {
      if (this.localStartTimeErrors[j] && this.localStartTimeErrors[j].length > 0) {
        hasLocalStartTimeErrors = true;
        break;
      }
    }
    for (var b = 0; b < this.localEndTimeErrors.length; b++) {
      if (this.localEndTimeErrors[j] && this.localEndTimeErrors[j].length > 0) {
        hasLocalEndTimeErrors = true;
        break;
      }
    }
    for (var l = 0; l < this.localACDStartTimeErrors.length; l++) {
      if (this.localACDStartTimeErrors[l] && this.localACDStartTimeErrors[l].length > 0) {
        hasLocalACDStartTimeErrors = true;
        break;
      }
    }
    for (var c = 0; c < this.localACDEndTimeErrors.length; c++) {
      if (this.localACDEndTimeErrors[c] && this.localACDEndTimeErrors[c].length > 0) {
        hasLocalACDEndTimeErrors = true;
        break;
      }
    }
    if (form.validate() && this.localClassStartDateErrorMessage === '' && this.localClassEndDateErrorMessage === '' && this.localDailyStartTimeErrors.length === 0 && this.localDailyEndTimeErrors.length === 0 && !hasLocalStartTimeErrors && !hasLocalEndTimeErrors && !hasLocalACDStartTimeErrors && !hasLocalACDEndTimeErrors) {
      CommonUtils.showLoading();
      const classInfo: any = {
        updateNoClass: 'Y',
        oldNoClass: this.localOldNoClassDays,
        shiftLessons: false,
        scheduleChange: false,
        className: this.localSelectedClassClone.className,
        classStartDate: this.localSelectedClassClone.classStartDate,
        classEndDate: this.localSelectedClassClone.classEndDate,
        color: this.localSelectedClassClone.color,
        titleColor: this.localSelectedClassClone.titleColor,
        titleSize: this.localSelectedClassClone.titleSize,
        titleFont: this.localSelectedClassClone.titleFont,
        classLabelBold: (this.localSelectedClassClone.classLabelBold) ? 'Y' : 'N',
        classLabelItalic: (this.localSelectedClassClone.classLabelItalic) ? 'Y' : 'N',
        classLabelUnderline: (this.localSelectedClassClone.classLabelUnderline) ? 'Y' : 'N',
        scheduleStart: this.localSelectedClassClone.scheduleStart,
        noStudents: this.localSelectedClassClone.noStudents,
        classDesc: this.localSelectedClassClone.classDesc,
        source: this.localSelectedClassClone.source,
        sourceId: this.localSelectedClassClone.sourceId,
        sourceSettings: this.localSelectedClassClone.sourceSettings,
        useSchoolStart: this.localSelectedClassClone.useSchoolStart,
        useSchoolEnd: this.localSelectedClassClone.useSchoolEnd,
        mondayTeach: this.localSelectedClassClone.mondayTeach,
        tuesdayTeach: this.localSelectedClassClone.tuesdayTeach,
        wednesdayTeach: this.localSelectedClassClone.wednesdayTeach,
        thursdayTeach: this.localSelectedClassClone.thursdayTeach,
        fridayTeach: this.localSelectedClassClone.fridayTeach,
        saturdayTeach: this.localSelectedClassClone.saturdayTeach,
        sundayTeach: this.localSelectedClassClone.sundayTeach,
        wk2mondayTeach: this.localSelectedClassClone.wk2mondayTeach,
        wk2tuesdayTeach: this.localSelectedClassClone.wk2tuesdayTeach,
        wk2wednesdayTeach: this.localSelectedClassClone.wk2wednesdayTeach,
        wk2thursdayTeach: this.localSelectedClassClone.wk2thursdayTeach,
        wk2fridayTeach: this.localSelectedClassClone.wk2fridayTeach,
        wk2saturdayTeach: this.localSelectedClassClone.wk2saturdayTeach,
        wk2sundayTeach: this.localSelectedClassClone.wk2sundayTeach,
        classId: (this.localSelectedClassClone.classId === 0) ? null : this.localSelectedClassClone.classId,
        userMode: this.getUserMode,
        collaborateType: this.localSelectedClassClone.collaborateType,
        collaborateTeacherEmail: (this.localSelectedClassClone.collaborateType < 3) ? undefined as any : this.localSelectedClassClone.collaborateTeacherEmail,
        collaborateTeacherId: (this.localSelectedClassClone.collaborateType < 3) ? undefined as any : this.localSelectedClassClone.collaborateTeacherId,
        collaborateYearId: (this.localSelectedClassClone.collaborateType < 3) ? undefined as any : this.localSelectedClassClone.collaborateYearId,
        collaborateSubjectId: (this.localSelectedClassClone.collaborateType < 3) ? 0 : this.localSelectedClassClone.collaborateSubjectId,
        collaborateKey: (this.localSelectedClassClone.collaborateType < 3) ? '' : this.localSelectedClassClone.collaborateKey,
        lessons: this.localSelectedClassClone.lessons,
        noClass: [] as any,
        noClass2: undefined as any,
        dayTeach: [] as any,
        startTime: [] as any,
        endTime: [] as any,
        schedules: undefined as any,
        lessonLayoutId: this.localSelectedClassClone.lessonLayoutId
      };

      const schedulesAndNoClassDaysToCauseShift: moment.MomentInput[] = [];
      if (this.localSelectedClassScheduleArrayClone && this.localSelectedClassScheduleArrayClone.length > 0) {
        const schedules: any[] = [];
        this.localSelectedClassScheduleArrayClone.forEach((sched: any) => {
          schedules.push(this.prepareSchedule(sched));
          if (!sched.scheduleId || sched.scheduleId === 0) {
            schedulesAndNoClassDaysToCauseShift.push(moment(sched.scheduleStart, 'MM/DD/YYYY'));
          }
        });
        classInfo.schedules = JSON.stringify(schedules);
      }

      if (this.localIsGoogleConnected) {
        classInfo.sourceSettings = JSON.stringify(classInfo.sourceSettings);
      }

      if (this.localSelectedClassClone.noClass && this.localSelectedClassClone.noClass.length > 0) {
        this.localSelectedClassClone.noClass.forEach((item: any, index: number) => {
          classInfo.noClass[index] = {};
          classInfo.noClass[index].customDate = moment(this.localSelectedClassClone.noClass[index].customDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
          classInfo.noClass[index].extraClass = this.localSelectedClassClone.noClass[index].extraClass;
          if (!item.customDayId || item.customDayId === 0) {
            schedulesAndNoClassDaysToCauseShift.push(moment(this.localSelectedClassClone.noClass[index].customDate, 'YYYY-MM-DD'));
          }
        });
        if (classInfo.noClass.length > 0) {
          classInfo.noClass2 = JSON.stringify(classInfo.noClass);
        }
      }

      if (this.getSchoolYearSettings.classCycle !== 'oneWeek' && this.getSchoolYearSettings.classCycle !== 'twoWeeks') {
        for (let x = 0; x < parseInt(this.getSchoolYearSettings.classCycle); x++) {
          classInfo.dayTeach[x] = 'N';
          classInfo.startTime[x] = '';
          classInfo.endTime[x] = '';
        }
      }
      if (classInfo.classId) {
        if (!CommonUtils.isArrayEqualDeepCompare(this.localOldSchedule, this.localSelectedClassScheduleArrayClone)) {
          if (CommonUtils.isNotEmpty(this.localSelectedClassClone.extraLessons)) {
            classInfo.extraLessons = this.localSelectedClassClone.extraLessons.map((l: any) => l.customDayId);
          }
          classInfo.scheduleChange = true;
        } else if (!CommonUtils.isArrayEqualDeepCompare(this.localOldAddedClassDays, this.localSelectedClassClone.extraLessons)) {
          classInfo.extraLessons = this.localSelectedClassClone.extraLessons.map((l: any) => l.customDayId)
          classInfo.scheduleChange = true;
        } else if (!CommonUtils.isArrayEqualDeepCompare(this.localOldNoClassDays, this.localSelectedClassClone.noClass)) {
          classInfo.scheduleChange = true;
        }

        if (classInfo.scheduleChange) {
          let displayShiftBox = false;
          if (classInfo.lessons && classInfo.lessons.length > 0) {
            const mLastLessonDate = moment(classInfo.lessons[classInfo.lessons.length - 1].lessonDate, 'YYYY-MM-DD');

            // Determine scheduleStart, go through the list in descending order to determine the earliest scheduleStart with a delta
            const oldScheduleMap = new Map();
            const oldScheduleStarts: Array<string> = [];
            const newScheduleStarts: Array<string> = [];
            this.localOldSchedule.forEach((oldSched: any) => {
              oldScheduleMap.set(oldSched.scheduleStart, oldSched);
              oldScheduleStarts.push(oldSched.scheduleStart);
            });
            this.localSelectedClassScheduleArrayClone.forEach((newSched: any) => {
              newScheduleStarts.push(newSched.scheduleStart);
              if (!oldScheduleStarts.includes(newSched.scheduleStart)) {
                classInfo.scheduleStart = newSched.scheduleStart;
              } else {
                if (!ld.isEqualWith(newSched, oldScheduleMap.get(newSched.scheduleStart), this.scheduleCompareCustomizer)) {
                  classInfo.scheduleStart = newSched.scheduleStart;
                }
              }
            });
            // Check if there are deleted schedules, then update scheduleStart accordingly
            this.localOldSchedule.forEach((oldSched: any) => {
              if (!newScheduleStarts.includes(oldSched.scheduleStart) && (!classInfo.scheduleStart || moment(oldSched.scheduleStart, 'MM/DD/YYYY').isBefore(moment(classInfo.scheduleStart, 'MM/DD/YYYY')))) {
                classInfo.scheduleStart = oldSched.scheduleStart;
              }
            });

            if (mLastLessonDate.isSameOrAfter(moment(this.localSelectedClassScheduleArrayClone[this.localClassScheduleArraySelected].scheduleStart, 'MM/DD/YYYY'))) {
              displayShiftBox = true;
            } else if (schedulesAndNoClassDaysToCauseShift.length > 0) {
              for (let l = 0; l < schedulesAndNoClassDaysToCauseShift.length; l++) {
                if (mLastLessonDate.isSameOrAfter(schedulesAndNoClassDaysToCauseShift[l])) {
                  displayShiftBox = true;
                  break;
                }
              }
            }
          }
          if (displayShiftBox) {
            CommonUtils.hideLoading();
            const that = this;
            return this.$refs.classEditorConfirm.confirm({
              title: this.$t('classScheduleChangeLabel'),
              text: this.$t('classScheduleChangeConfirmMsg'),
              option1ButtonAlternativeText: this.$t('shiftLessonsLabel'),
              option2ButtonAlternativeText: this.$t('doNotShiftLessonsLabel')
            }).then((result) => {
              if (result === 1) {
                CommonUtils.showLoading();
                classInfo.shiftLessons = true;
                return that.checkShiftLessons(classInfo);
              } else if (result === 2) {
                CommonUtils.showLoading();
                classInfo.shiftLessons = false;
                return that.checkShiftLessons(classInfo);
              }
            });
          } else {
            classInfo.shiftLessons = false;
            return this.checkShiftLessons(classInfo);
          }
        } else {
          classInfo.shiftLessons = false;
          return this.checkShiftLessons(classInfo);
        }
      } else {
        const that = this;
        return this.addClass(classInfo).then(() => {
          CommonUtils.hideLoading();
          that.closeEditor();

          if (that.showSnackbarNotifications) {
            that.$snotify.success(that.$t('statusMsg5') as string);
          }

          return Promise.resolve();
        });
      }
    } else {
      return Promise.resolve(true);
    }
  }

  scheduleCompareCustomizer(newSched: any, oldSched: any) {
    if (oldSched) {
      oldSched = ld.cloneDeep(oldSched);
      oldSched.additionalClassDays.forEach((acd: any) => {
        acd.startTime = moment(acd.startTime, 'HH:mm:ss').format('h:mm A');
        acd.endTime = moment(acd.endTime, 'HH:mm:ss').format('h:mm A');
      });
    }
    return ld.isEqual(newSched, oldSched);
  }

  prepareSchedule(inputSchedule: any) {
    const schedule: any = {
      scheduleStart: this.isNewClass ? this.localSelectedClassClone.classStartDate : inputSchedule.scheduleStart,
      additionalClassDays: inputSchedule.additionalClassDays
    };
    const week1DefaultDays = [2, 3, 4, 5, 6];
    const week2DefaultDays = [9, 10, 11, 12, 13];

    if (this.getCurrentYear.sunCycle === 'Y') {
      week1DefaultDays.unshift(1);
      week2DefaultDays.unshift(8);
    }

    if (this.getCurrentYear.satCycle === 'Y') {
      week1DefaultDays.push(7);
      week2DefaultDays.push(14);
    }

    let scheduleDays = week1DefaultDays;

    if (this.getSchoolYearSettings.classCycle === 'twoWeeks') {
      scheduleDays = week1DefaultDays.concat(week2DefaultDays);
    }

    if ((this.isNewClass || inputSchedule.isNewSchedule) && (this.localClassScheduleFrequencySelected === 'D' || this.localClassScheduleFrequencySelected === 'DS')) {
      for (let x = 1; x < 21; x++) {
        const teachDayName = 'teachDay' + x;
        const startDayName = 'startDay' + x;
        const endDayName = 'endDay' + x;
        schedule[teachDayName] = false;
        schedule[startDayName] = '';
        schedule[endDayName] = '';
        if (this.getSchoolYearSettings.classCycle === 'oneWeek' && scheduleDays.includes(x)) {
          schedule[teachDayName] = true;
          if (this.localClassScheduleFrequencySelected === 'DS') {
            schedule[startDayName] = this.localDailyStartTime;
            schedule[endDayName] = this.localDailyEndTime;
          }
        } else if (this.getSchoolYearSettings.classCycle === 'twoWeeks' && scheduleDays.includes(x)) {
          schedule[teachDayName] = true;
          if (this.localClassScheduleFrequencySelected === 'DS') {
            schedule[startDayName] = this.localDailyStartTime;
            schedule[endDayName] = this.localDailyEndTime;
          }
        }
      }
    } else {
      for (let x = 1; x < 21; x++) {
        const teachDayName = 'teachDay' + x;
        const dayTeachName = 'day' + x + 'Teach';
        const startDayName = 'startDay' + x;
        const dayStartTimeName = 'day' + x + 'StartTime';
        const endDayName = 'endDay' + x;
        const dayEndTimeName = 'day' + x + 'EndTime';
        schedule[teachDayName] = inputSchedule[dayTeachName] ? inputSchedule[dayTeachName] : false;
        schedule[startDayName] = inputSchedule[dayStartTimeName] ? (inputSchedule[dayStartTimeName]) : '';
        schedule[endDayName] = inputSchedule[dayEndTimeName] ? (inputSchedule[dayEndTimeName]) : '';
      }
    }

    if (schedule.additionalClassDays && schedule.additionalClassDays.length > 0) {
      schedule.additionalClassDays.forEach((acd: any) => {
        if (acd.startTime) {
          if (moment(acd.startTime, 'HH:mm:ss', true).isValid()) {
            acd.startTime = moment(acd.startTime, 'HH:mm:ss').format('h:mm A');
          }
        } else {
          acd.startTime = '';
        }
        if (acd.endTime) {
          if (moment(acd.endTime, 'HH:mm:ss', true).isValid()) {
            acd.endTime = moment(acd.endTime, 'HH:mm:ss').format('h:mm A');
          }
        } else {
          acd.endTime = '';
        }
        acd.dayCode = String(acd.dayCode);
      });
    }

    return schedule;
  }

  checkShiftLessons(classInfo: any) {
    if (classInfo.lessons && classInfo.lessons.length > 0 && this.selectedClass.classStartDate !== this.localSelectedClassClone.classStartDate) {
      CommonUtils.hideLoading();
      const that = this;
      return this.$refs.classEditorConfirm.confirm({
        title: this.$t('classStartDateLabel'),
        text: this.$t('classStartChangeConfirmMsg'),
        option1ButtonAlternativeText: this.$t('continueLabel')
      }).then((result) => {
        if (result === 1) {
          CommonUtils.showLoading();
          classInfo.verifyShift = true;
          return that.submitUpdate(classInfo);
        }
      });
    } else {
      if (this.selectedClass.classEndDate !== this.localSelectedClassClone.classEndDate) {
        classInfo.scheduleChange = true;
        classInfo.shiftLessons = true;
      }
      classInfo.verifyShift = true;
      return this.submitUpdate(classInfo);
    }
  }

  submitUpdate(classInfo: any) {
    const that = this
    return this.updateClass(classInfo).then((response: any) => {
      if (classInfo.verifyShift && response.data.deletedLessons && response.data.deletedLessons.length > 0) {
        CommonUtils.hideLoading();
        let message = that.$t('confirmDeleteLesson1') + '<br/><br/>';
        response.data.deletedLessons.forEach((item: any) => {
          message += DateTimeUtils.formatToShow(item.date, that.getUserInfo) + '<br/>';
        });
        message += '<br/>' + that.$t('confirmDeleteLesson2');
        return that.$refs.classEditorConfirm.confirm({
          title: that.$t('lessonsToDeleteLabel'),
          text: message,
          option1ButtonAlternativeText: that.$t('deleteLessonsLabel')
        }).then((result) => {
          if (result === 1) {
            CommonUtils.showLoading();
            classInfo.verifyShift = false;
            return that.updateClass(classInfo).then(function() {
              CommonUtils.hideLoading();
              that.closeEditor();

              if (that.showSnackbarNotifications) {
                that.$snotify.success(that.$t('statusMsg6') as string);
              }

              return Promise.resolve();
            });
          }
          return Promise.resolve();
        });
      } else {
        classInfo.verifyShift = false;
        return that.updateClass(classInfo).then(function() {
          CommonUtils.hideLoading();
          that.closeEditor();

          if (that.showSnackbarNotifications) {
            that.$snotify.success(that.$t('statusMsg6') as string);
          }

          return Promise.resolve();
        });
      }
    });
  }

  connectGoogleClass(item: any) {
    this.localSelectedClassClone.source = 'gc';
    this.localSelectedClassClone.sourceId = item.id;
    this.localSelectedClassClone.sourceSettings = {
      connectStudents: true,
      connectAssignments: true,
      connectGrades: true
    }
    this.localIsGoogleConnected = true;
  }

  disconnectGoogleClass() {
    this.localSelectedClassClone.source = '';
    this.localSelectedClassClone.sourceId = '';
    this.localIsGoogleConnected = false;
    this.localSelectedClassClone.sourceSettings = {
      connectStudents: false,
      connectAssignments: false,
      connectGrades: false
    };
  }

  get localGoogleChipOptions() {
    const val = [];
    if (this.localSelectedClassClone.sourceSettings.connectAssignments) val.push(0);
    if (this.localSelectedClassClone.sourceSettings.connectStudents) val.push(1);
    if (this.localSelectedClassClone.sourceSettings.connectGrades) val.push(2);
    return val;
  }

  set localGoogleChipOptions(val: Array<any>) {
    this.localSelectedClassClone.sourceSettings.connectAssignments = val.includes(0);
    this.localSelectedClassClone.sourceSettings.connectStudents = val.includes(1);
    this.localSelectedClassClone.sourceSettings.connectGrades = val.includes(2);
  }

  closeEditor() {
    if (this.onEditorClose) {
      this.onEditorClose();
    }
  }

  changesMade() {
    return !ld.isEqual(this.localOldSelectedClassClone, this.localSelectedClassClone) || (this.isNewClass && CommonUtils.hasText(this.localSelectedClassClone.className))
  }

  copyTimes(start: string, end: string) {
    const selectedClassSchedule = this.localSelectedClassScheduleArrayClone[this.localClassScheduleArraySelected];
    this.cycleDays.forEach((cycleDay: any, index:number) => {
      let teachDayIndex;
      if (index >= 9) {
        teachDayIndex = cycleDay.id.substr(cycleDay.id.length - 2);
      } else {
        teachDayIndex = cycleDay.id.substr(cycleDay.id.length - 1);
      }
      selectedClassSchedule['day' + teachDayIndex + 'StartTime'] = start;
      selectedClassSchedule['day' + teachDayIndex + 'EndTime'] = end;
      this.localRefreshKey = CommonUtils.generateUUID();
    });
  }

  addScheduleDay(scheduleId: number, week?:string) {
    let extraDayHigh = 0;
    this.localSelectedClassScheduleArrayClone.forEach((sched: any) => {
      if (sched.additionalClassDays && sched.additionalClassDays.length > 0) {
        sched.additionalClassDays.forEach((acd: any) => {
          if (acd.extraDay > extraDayHigh) {
            extraDayHigh = acd.extraDay;
          }
        });
      }
    });
    const selectedClassSchedule = this.localSelectedClassScheduleArrayClone[this.localClassScheduleArraySelected];
    let dayCode = '';
    if (this.getSchoolYearSettings.classCycle === 'twoWeeks') {
      dayCode = week === 'week1' ? this.localDaysOfWeek[0].value : this.localDaysOfWeek[7].value;
    } else {
      dayCode = this.localDaysOfWeek[0].value
    }
    const acd = {
      dayCode: dayCode,
      endTime: '',
      extraDay: ++extraDayHigh,
      scheduleId: scheduleId,
      startTime: '',
      subjectId: this.localSelectedClassClone.classId
    };
    selectedClassSchedule.additionalClassDays.push(acd);
  }

  deleteScheduleDay(index: number) {
    const selectedClassSchedule = this.localSelectedClassScheduleArrayClone[this.localClassScheduleArraySelected];
    selectedClassSchedule.additionalClassDays.splice(index, 1);
  }

  addNoClassDay() {
    this.localClassRemoveDateErrorMessage = '';
    if (CommonUtils.hasText(this.localClassRemoveDate) && DateTimeUtils.isThisDateBeforeThatDate(this.localClassRemoveDate, this.localSelectedClassClone.classStartDate)) {
      this.localClassRemoveDateErrorMessage = this.$t('noClassDayCannotBeBeforeClassStart') as string;
    } else if (CommonUtils.hasText(this.localClassRemoveDate) && DateTimeUtils.isThisDateAfterThatDate(this.localClassRemoveDate, this.localSelectedClassClone.classEndDate)) {
      this.localClassRemoveDateErrorMessage = this.$t('noClassDayCannotBeAfterClassEnd') as string;
    } else {
      const ncd = {
        addedClassDay: false,
        customDate: moment(this.localClassRemoveDate, 'MM/DD/YYYY').format('YYYY-MM-DD'),
        extraClass: 0,
        noClass: true,
        parentId: 0
      };
      this.localSelectedClassClone.noClass.push(ncd);
      this.localClassRemoveDate = '';
      this.localRefreshKey = CommonUtils.generateUUID();
    }
  }

  deleteNoClassDay(index: number) {
    this.localSelectedClassClone.noClass.splice(index, 1);
    this.localRefreshKey = CommonUtils.generateUUID();
  }

  handleClassStartChanged() {
    this.localClassStartDateMessages = [];
    this.localClassStartDateErrorMessage = '';
    if (this.selectedClass.classStartDate !== this.localSelectedClassClone.classStartDate) {
      const mOldStartDate = moment(this.selectedClass.classStartDate, 'MM/DD/YYYY');
      const mNewStartDate = moment(this.localSelectedClassClone.classStartDate, 'MM/DD/YYYY');
      const classStartValidity = this.checkClassStart(this.localSelectedClassClone.classStartDate);
      if (classStartValidity === 1) {
        this.localClassStartDateErrorMessage = this.$t('classStartCannotBeBeforeSchoolStart') as string;
      } else if (classStartValidity === 3) {
        this.localClassStartDateErrorMessage = this.$t('startMustBeBeforeEnd') as string;
      } else if (classStartValidity === 4) {
        this.localClassStartDateErrorMessage = this.$t('notAllowedToPickThisDate') as string;
      } else if (DateTimeUtils.daysDiff(this.localSelectedClassClone.classStartDate, this.localSelectedClassClone.classEndDate) > 365) {
        this.localClassStartDateErrorMessage = this.$t('rangeCannotExceedOneYear') as string;
      } else {
        if (this.localSelectedClassScheduleArrayClone.length === 1 || mNewStartDate.isBefore(mOldStartDate)) {
          this.localSelectedClassScheduleArrayClone[0].scheduleStart = this.localSelectedClassClone.classStartDate;
          if (!this.isNewClass) {
            this.localClassStartDateMessages.push(this.$t('classStartEndChangeImpactOnScheduleMsg') as string);
          }
        } else {
          const tempArray: any = [];
          for (let m = 0; m < this.localSelectedClassScheduleArrayClone.length; m++) {
            const sched = this.localSelectedClassScheduleArrayClone[m];
            const mScheduleStart = moment(sched.scheduleStart, 'MM/DD/YYYY');
            if (mScheduleStart.isAfter(mNewStartDate)) {
              tempArray.push(sched);
            } else {
              sched.scheduleStart = this.localSelectedClassClone.classStartDate;
              tempArray.push(sched);
              break;
            }
          }
          if (!this.isNewClass) {
            this.localClassStartDateMessages.push(this.$t('classStartEndChangeImpactOnScheduleMsg') as string);
          }
          this.localSelectedClassScheduleArrayClone = tempArray;
        }
      }
    }
    this.localRefreshKey = CommonUtils.generateUUID();
  }

  handleClassStartClicked(isEditable: boolean) {
    if (!isEditable) {
      this.localClassStartDateMessages = [];
      this.localClassStartDateMessages.push(this.$t('notAllowedToChangeAClassStartDateInThePast'));
    }
  }

  handleUseSchoolStartClicked(isEditable: boolean, value: boolean) {
    this.handleClassStartClicked(isEditable);
    if (isEditable) {
      return !value;
    }
    return value;
  }

  handleClassEndChanged() {
    const mEndDate = moment(this.localSelectedClassClone.classEndDate, 'MM/DD/YYYY');
    const mCurrentYearLastDay = moment(this.getCurrentYear.lastDay, 'MM/DD/YYYY');
    if (mEndDate.isAfter(mCurrentYearLastDay)) {
      this.localClassEndDateErrorMessage = this.$t('classEndCannotBeAfterSchoolEnd') as string;
    } else if (DateTimeUtils.daysDiff(this.localSelectedClassClone.classStartDate, this.localSelectedClassClone.classEndDate) > 365) {
      this.localClassEndDateErrorMessage = this.$t('rangeCannotExceedOneYear') as string;
    } else if (this.selectedClass.classEndDate !== this.localSelectedClassClone.classEndDate && this.localSelectedClassScheduleArrayClone.length > 1) {
      const mNewEndDate = moment(this.localSelectedClassClone.classEndDate, 'MM/DD/YYYY');
      const tempArray: any = [];
      this.localSelectedClassScheduleArrayClone.forEach((sched: any) => {
        const mScheduleStart = moment(sched.scheduleStart, 'MM/DD/YYYY');
        if (mScheduleStart.isBefore(mNewEndDate)) {
          tempArray.push(sched);
        }
      });
      if (!this.isNewClass && this.localSelectedClassScheduleArrayClone.length !== tempArray.length) {
        this.localClassEndDateMessages.push(this.$t('classStartEndChangeImpactOnScheduleMsg') as string);
      }
      this.localSelectedClassScheduleArrayClone = tempArray;
    }
    this.localRefreshKey = CommonUtils.generateUUID();
  }

  // handleDailyStartTimeChanged(v: string, compareTo: string) {
  //   this.localDailyStartTimeErrors = [];
  //   this.localDailyEndTimeErrors = [];
  //   var format = this.timeFormat === 1 ? 'HH:mm' : 'h:mm A';
  //   var mV = moment(v, format);
  //   var mCompareTo = moment(compareTo, format);
  //   if (mV.isAfter(mCompareTo)) {
  //     this.localDailyStartTimeErrors.push(this.$t('startTimeMustBeBeforeEndTimeMsg') as string);
  //   }
  // }

  // handleDailyEndTimeChanged(v: string, compareTo: string) {
  //   this.localDailyStartTimeErrors = [];
  //   this.localDailyEndTimeErrors = [];
  //   var format = this.timeFormat === 1 ? 'HH:mm' : 'h:mm A';
  //   var mV = moment(v, format);
  //   var mCompareTo = moment(compareTo, format);
  //   if (mV.isBefore(mCompareTo)) {
  //     this.localDailyEndTimeErrors.push(this.$t('endTimeMustBeAfterStartTimeMsg') as string)
  //   }
  // }

  // handleStartTimeChanged(v: string, compareTo: string, index: number) {
  //   this.localStartTimeErrors[index] = [];
  //   this.localEndTimeErrors[index] = [];
  //   var format = this.timeFormat === 1 ? 'HH:mm' : 'h:mm A';
  //   var mV = moment(v, format);
  //   var mCompareTo = moment(compareTo, format);
  //   if (mV.isAfter(mCompareTo)) {
  //     this.localStartTimeErrors[index].push(this.$t('startTimeMustBeBeforeEndTimeMsg') as string);
  //   }
  // }

  // handleEndTimeChanged(v: string, compareTo: string, index: number) {
  //   this.localStartTimeErrors[index] = [];
  //   this.localEndTimeErrors[index] = [];
  //   var format = this.timeFormat === 1 ? 'HH:mm' : 'h:mm A';
  //   var mV = moment(v, format);
  //   var mCompareTo = moment(compareTo, format);
  //   if (mV.isBefore(mCompareTo)) {
  //     this.localEndTimeErrors[index].push(this.$t('endTimeMustBeAfterStartTimeMsg') as string)
  //   }
  // }

  // handleACDStartTimeChanged(v: string, compareTo: string, index: number) {
  //   this.localACDStartTimeErrors[index] = [];
  //   this.localACDEndTimeErrors[index] = [];
  //   var format = this.timeFormat === 1 ? 'HH:mm' : 'h:mm A';
  //   var mV = moment(v, format);
  //   var mCompareTo = moment(compareTo, format);
  //   if (mV.isAfter(mCompareTo)) {
  //     this.localACDStartTimeErrors[index].push(this.$t('startTimeMustBeBeforeEndTimeMsg') as string);
  //   }
  // }

  // handleACDEndTimeChanged(v: string, compareTo: string, index: number) {
  //   this.localACDStartTimeErrors[index] = [];
  //   this.localACDEndTimeErrors[index] = [];
  //   var format = this.timeFormat === 1 ? 'HH:mm' : 'h:mm A';
  //   var mV = moment(v, format);
  //   var mCompareTo = moment(compareTo, format);
  //   if (mV.isBefore(mCompareTo)) {
  //     this.localACDEndTimeErrors[index].push(this.$t('endTimeMustBeAfterStartTimeMsg') as string)
  //   }
  // }

  isDateWithinGracePeriod(date: string) {
    var mFieldDate = moment(date, 'MM/DD/YYYY');
    var elapsedDaysFromDate = this.fcStartOfDay.diff(mFieldDate, 'days');
    return this.fcStartOfDay.isBefore(mFieldDate) || this.fcStartOfDay.isSame(mFieldDate) || elapsedDaysFromDate <= allowedGracePeriodDays;
  }

  isClassEditorFieldEditable(fieldDate: string, classCreateDate: string) {
    if (fieldDate && classCreateDate) {
      var mToday = moment();
      var mFieldDate = moment(fieldDate, 'MM/DD/YYYY');
      var mClassCreateDate = moment(classCreateDate, 'MM/DD/YYYY');
      var elapsedDaysFromClassCreated = mToday.diff(mClassCreateDate, 'days');
      var elapsedDaysFromDate = mToday.diff(mFieldDate, 'days');
      var withinGracePeriod = false;
      if (mToday.isAfter(mFieldDate) && (elapsedDaysFromDate <= allowedGracePeriodDays || elapsedDaysFromClassCreated <= allowedGracePeriodDays)) {
        withinGracePeriod = true;
      }
      if (mToday.isBefore(mFieldDate) || mToday.isSame(mFieldDate) || withinGracePeriod) {
        return true
      }
      return false;
    }
    return true;
  }

  editSchedule(menuIndex: number, isDuplicatingCurrentSchedule:boolean) {
    this.localClassEditScheduleMenu = [false, false];
    if (this.localEditScheduleOption === 'CS') {
      this.$refs.classEditorConfirm.confirm({
        title: this.$t('editScheduleLabel'),
        text: this.$t('startNewScheduleOnClassStartDateMsg'),
        option1ButtonAlternativeText: this.$t('continueLabel')
      }).then((result) => {
        if (result === 1) {
          this.processSchedule(menuIndex, isDuplicatingCurrentSchedule);
        }
      });
    } else {
      this.processSchedule(menuIndex, isDuplicatingCurrentSchedule);
    }
  }

  @Watch('localClassEditScheduleMenu')
  focusMenuOnOpen() {
    if (this.localClassEditScheduleMenu[0] || this.localClassEditScheduleMenu[1]) {
      this.$nextTick(() => {
        new Promise(resolve => setTimeout(resolve, 100)).then(() => {
          try {
            if ((this.$refs as any).continueButton[0].$el.querySelector('.v-btn')) {
              (this.$refs as any).continueButton[0].$el.querySelector('.v-btn').focus();
            } else {
              (this.$refs as any).continueButton[0].$el.focus();
            }
          } catch (e) {
            console.log(this.$t('focusFailed'));
          }
        });
      });
    }
  }

  processSchedule(menuIndex: number, isDuplicatingCurrentSchedule:boolean) {
    let form: any = menuIndex === 0 ? this.$refs.editScheduleMenuForm0 : this.$refs.editScheduleMenuForm1;
    form = form.length ? form[0] : form;
    this.localClassScheduleNewStartErrorMessage = '';
    if (isDuplicatingCurrentSchedule) {
      this.localClassScheduleFrequencySelected = 'C';
    } else {
      this.localClassScheduleFrequencySelected = 'D';
    }
    if (this.localEditScheduleOption === 'T') {
      this.localClassScheduleNewStart = this.fcStartOfDay.format('MM/DD/YYYY');
    } else if (this.localEditScheduleOption === 'CS') {
      this.localClassScheduleNewStart = this.localSelectedClassClone.classStartDate;
    }
    if (this.localEditScheduleOption === 'CS' && !isDuplicatingCurrentSchedule) {
      this.localSelectedClassScheduleArrayClone = ld.cloneDeep([this.classSchedule]);
      this.localSelectedClassScheduleArrayClone[0].scheduleStart = this.localSelectedClassClone.classStartDate;
      this.localSelectedClassScheduleArrayClone[0].frequency = this.localClassScheduleFrequencySelected;
      this.localClassEditScheduleMenu[menuIndex] = false;
      this.localClassScheduleArraySelected = 0;
    } else {
      const scheduleStartValidity = this.checkScheduleStart(this.localClassScheduleNewStart);
      if (this.localEditScheduleOption === 'O' && !form.validate()) {
        return;
      } else if (scheduleStartValidity > 0) {
        this.localClassScheduleNewStartErrorMessage = this.$t('dateMustBeBetweenClassStartAndEnd') as string;
        return;
      } else {
        const tempClassScheduleClonedArray = ld.cloneDeep(this.localSelectedClassScheduleArrayClone).filter((c:any) => {
          const ma = moment(c.scheduleStart, 'MM/DD/YYYY');
          const mb = moment(this.localClassScheduleNewStart, 'MM/DD/YYYY');
          return ma.isBefore(mb);
        });
        const tempArray: any = ld.cloneDeep(tempClassScheduleClonedArray);
        var tempDuplicatedSched: any = {};
        if (isDuplicatingCurrentSchedule) {
          for (var x = 0; x < this.localOldSchedule.length; x++) {
            const mScheduleStart = moment(this.localOldSchedule[x].scheduleStart, 'MM/DD/YYYY');
            if (mScheduleStart.isBefore(this.fcStartOfDay) || mScheduleStart.isSame(this.fcStartOfDay)) {
              tempDuplicatedSched = ld.cloneDeep(this.localOldSchedule[x]);
              break;
            }
            if (this.mCurrentDate.isBefore(this.localSelectedClassClone.classStartDate) && this.localOldSchedule[x].scheduleStart === this.localSelectedClassClone.classStartDate) {
              tempDuplicatedSched = ld.cloneDeep(this.localOldSchedule[x]);
              break;
            }
          }
          tempDuplicatedSched.isNewSchedule = true;
          tempDuplicatedSched.scheduleId = 0;
          tempDuplicatedSched.frequency = this.localClassScheduleFrequencySelected;
          tempArray.push(tempDuplicatedSched);
        } else {
          tempDuplicatedSched = ld.cloneDeep(this.classSchedule);
          tempDuplicatedSched.isNewSchedule = true;
          tempDuplicatedSched.scheduleId = 0;
          tempDuplicatedSched.frequency = this.localClassScheduleFrequencySelected;
          tempArray.push(ld.cloneDeep(tempDuplicatedSched));
        }
        tempArray[tempArray.length - 1].scheduleStart = this.localClassScheduleNewStart;
        tempArray.sort(function (a: any, b: any) {
          const ma = moment(a.scheduleStart, 'MM/DD/YYYY');
          const mb = moment(b.scheduleStart, 'MM/DD/YYYY');
          return mb.diff(ma, 'days');
        });
        for (var w = 0; w < tempArray.length; w++) {
          if (tempArray[w].isNewSchedule) {
            this.localClassScheduleArraySelected = w;
            break;
          }
        }
        this.localSelectedClassScheduleArrayClone = ld.cloneDeep(tempArray);
        this.localClassEditScheduleMenu[menuIndex] = false;
      }
    }
    this.localClassScheduleNewStart = '';
    this.localEditScheduleOption = 'T';
    this.localIsEditingSchedule = true;
    this.localRefreshKey = CommonUtils.generateUUID();
  }

  canDeleteSchedule(schedule: any) {
    const mScheduleStart = moment(schedule.scheduleStart, 'MM/DD/YYYY');
    const mStartDate = moment(this.localSelectedClassClone.classStartDate, 'MM/DD/YYYY');
    if (mScheduleStart.isSame(mStartDate)) {
      return false;
    }
    return true;
  }

  deleteSchedule(index: number) {
    var tempClassScheduleClonedArray = ld.cloneDeep(this.localSelectedClassScheduleArrayClone);
    tempClassScheduleClonedArray.splice(index, 1);
    this.localClassScheduleFrequencySelected = tempClassScheduleClonedArray[0].frequency;
    this.localSelectedClassScheduleArrayClone = ld.cloneDeep(tempClassScheduleClonedArray);
    this.localRefreshKey = CommonUtils.generateUUID();
  }

  undoEditSchedule() {
    this.localSelectedClassScheduleArrayClone = ld.cloneDeep(this.localOldSchedule);
    this.localIsEditingSchedule = false;
    this.localClassEditScheduleMenu = [false, false];
    this.localRefreshKey = CommonUtils.generateUUID();
  }

  checkClassStart(date: string) {
    const mDate = moment(date, 'MM/DD/YYYY');
    const mStartDate = moment(this.localSelectedClassClone.classStartDate, 'MM/DD/YYYY');
    const mEndDate = moment(this.localSelectedClassClone.classEndDate, 'MM/DD/YYYY');
    const mCurrentYearFirstDay = moment(this.getCurrentYear.firstDay, 'MM/DD/YYYY');
    if (mDate.isBefore(mCurrentYearFirstDay)) {
      return 1;
    } else if (mDate.isBefore(mStartDate)) {
      return 2;
    } else if (mDate.isAfter(mEndDate)) {
      return 3;
    } else if (!this.isNewClass && this.localSelectedClassClone.lessons && this.localSelectedClassClone.lessons.length > 0 && !this.isDateWithinGracePeriod(date)) {
      return 4;
    } else {
      return 0
    }
  }

  checkScheduleStart(date: string) {
    const mDate = moment(date, 'MM/DD/YYYY');
    const mStartDate = moment(this.localSelectedClassClone.classStartDate, 'MM/DD/YYYY');
    const mEndDate = moment(this.localSelectedClassClone.classEndDate, 'MM/DD/YYYY');
    const mCurrentYearFirstDay = moment(this.getCurrentYear.firstDay, 'MM/DD/YYYY');
    if (mDate.isBefore(mCurrentYearFirstDay)) {
      return 1;
    } else if (mDate.isBefore(mStartDate)) {
      return 2;
    } else if (mDate.isAfter(mEndDate)) {
      return 3;
    } else {
      return 0
    }
  }

  handleReplicaSelectedTabChange() {
    if (this.localReplicaSelectedTab === 1) {
      const form: any = this.$refs.classEditorForm;
      form.resetValidation();
    }
  }

  async initCollaborateTeachers() {
    if (this.getSharingOptionsSettings) {
      let foundCollaborateTeacherInHaystack = false;
      const sharedList = this.getSharingOptionsSettings.sharedList;
      const collaborateTeacherEmail = this.localSelectedClassClone.collaborateTeacherEmail;
      const collaborateTeacherKey = this.localSelectedClassClone.collaborateKey;
      if (sharedList && sharedList.length > 0) {
        for (const x in sharedList) {
          const email = sharedList[x].sharedUserEmail;
          const key = sharedList[x].sharedUserKey;
          if (email && key) {
            if (collaborateTeacherEmail && !foundCollaborateTeacherInHaystack && email.toLowerCase() === collaborateTeacherEmail.toLowerCase()) {
              foundCollaborateTeacherInHaystack = true;
            }
            const item = { text: email, value: email, key: key };
            this.localTeacherDropdownItems.push(item);
          }
        }
        for (const y in this.localTeacherDropdownItems) {
          const email = this.localTeacherDropdownItems[y].value;
          if (email && CommonUtils.hasText(collaborateTeacherEmail)) {
            if (collaborateTeacherEmail && !foundCollaborateTeacherInHaystack && email.toLowerCase() === collaborateTeacherEmail.toLowerCase()) {
              foundCollaborateTeacherInHaystack = true;
            }
          }
        }
        if (!foundCollaborateTeacherInHaystack && collaborateTeacherEmail) {
          const item = { text: collaborateTeacherEmail.toLowerCase() + ' (Not Saved)', value: collaborateTeacherEmail.toLowerCase(), key: collaborateTeacherKey };
          this.localTeacherDropdownItems.push(item);
          this.localSelectedClassClone.collaborateTeacherEmail = collaborateTeacherEmail.toLowerCase();
        } else {
          this.localSelectedClassClone.collaborateTeacherEmail = collaborateTeacherEmail.toLowerCase();
        }
        return this.handleCollaborateTeacherChange();
      }
    }
    return Promise.resolve();
  }

  private async initLessonLayouts() {
    return this.loadLayouts().then(() => {
      this.getLayouts.forEach((item: any) => {
        this.localLessonLayouts.push({ text: item.name, value: item.lessonLayoutId });
      });
      return Promise.resolve();
    });
  }

  async handleCollaborateTeacherChange() {
    this.localSchoolYearDropdownItemsLoading = true;
    this.localSavedCollaborateTeacherEmailError = '';
    for (const w in this.localTeacherDropdownItems) {
      if (this.localSelectedClassClone.collaborateTeacherEmail === this.localTeacherDropdownItems[w].value) {
        this.localSelectedClassClone.collaborateKey = this.localTeacherDropdownItems[w].key;
        break;
      }
    }
    if (this.localSelectedClassClone.collaborateTeacherEmail !== this.selectedClass.collaborateTeacherEmail) {
      this.localSelectedClassClone.collaborateYearId = 0;
      this.localSelectedClassClone.collaborateSubjectId = 0;
    }
    return this.getSharedClasses({ sharedUserEmail: this.localSelectedClassClone.collaborateTeacherEmail, sharedUserKey: this.localSelectedClassClone.collaborateKey }).then((data) => {
      this.localSchoolYearDropdownItems = [];
      this.localSubjectDropdownItems = [];
      if (data.data.invalidTeacher === 'true') {
        this.localSavedCollaborateTeacherEmailError = this.$t('collaborateErrorMsg3') as string;
      } else if (this.sharedClasses && this.sharedClasses.years && this.sharedClasses.years.length > 0) {
        const schoolYears = this.sharedClasses.years;
        for (const j in schoolYears) {
          const item = { text: schoolYears[j].yearName, value: schoolYears[j].yearId };
          this.localSchoolYearDropdownItems.push(item);
          if (this.localSelectedClassClone.collaborateYearId === schoolYears[j].yearId && schoolYears[j].classes && schoolYears[j].classes.length > 0) {
            for (const l in schoolYears[j].classes) {
              const classItem = { text: schoolYears[j].classes[l].className, value: schoolYears[j].classes[l].classId };
              this.localSubjectDropdownItems.push(classItem);
            }
          }
        }
      }
      this.localSchoolYearDropdownItemsLoading = false;
      this.localRefreshKey = CommonUtils.generateUUID();
      return Promise.resolve();
    });
  }

  async handleCollaborateTeacherPasswordChange() {
    this.localSchoolYearDropdownItemsLoading = true;
    this.localSavedCollaborateTeacherEmailError = '';
    return this.getSharedClasses({ sharedUserEmail: this.localSelectedClassClone.collaborateTeacherEmail, sharedUserKey: this.localSelectedClassClone.collaborateKey }).then((data) => {
      this.localSchoolYearDropdownItems = [];
      this.localSubjectDropdownItems = [];
      if (data.data.invalidTeacher === 'true') {
        this.localSavedCollaborateTeacherEmailError = this.$t('collaborateErrorMsg3') as string;
      } else if (this.sharedClasses && this.sharedClasses.years && this.sharedClasses.years.length > 0) {
        this.saveSharedTeacher({ sharedUserEmail: this.localSelectedClassClone.collaborateTeacherEmail, sharedUserKey: this.localSelectedClassClone.collaborateKey })
        const schoolYears = this.sharedClasses.years;
        for (const j in schoolYears) {
          const item = { text: schoolYears[j].yearName, value: schoolYears[j].yearId };
          this.localSchoolYearDropdownItems.push(item);
          if (this.localSelectedClassClone.collaborateYearId === schoolYears[j].yearId && schoolYears[j].classes && schoolYears[j].classes.length > 0) {
            for (const l in schoolYears[j].classes) {
              const classItem = { text: schoolYears[j].classes[l].className, value: schoolYears[j].classes[l].classId };
              this.localSubjectDropdownItems.push(classItem);
            }
          }
        }
      }
      this.localSchoolYearDropdownItemsLoading = false;
      this.localRefreshKey = CommonUtils.generateUUID();
      return Promise.resolve();
    });
  }

  handleCollaborateSchoolYearChange() {
    this.localSubjectDropdownItemsLoading = true;
    this.localSubjectDropdownItems = [];
    if (this.sharedClasses && this.sharedClasses.years && this.sharedClasses.years.length > 0) {
      const schoolYears = this.sharedClasses.years;
      for (const j in schoolYears) {
        if (this.localSelectedClassClone.collaborateYearId === schoolYears[j].yearId && schoolYears[j].classes && schoolYears[j].classes.length > 0) {
          for (const l in schoolYears[j].classes) {
            const classItem = { text: schoolYears[j].classes[l].className, value: schoolYears[j].classes[l].classId };
            this.localSubjectDropdownItems.push(classItem);
          }
        }
      }
      this.localSubjectDropdownItemsLoading = false;
      this.localSelectedClassClone.collaborateSubjectId = 0;
      this.localRefreshKey = CommonUtils.generateUUID();
    }
  }

  showCollaborateClasses() {
    this.localCollaborateTeacherEmailError = '';
    this.localCollaborateTeacherKeyError = ''
    if (this.localCollaborateTeacherEmail === '') {
      this.localCollaborateTeacherEmailError = this.$t('collaborateErrorMsg1') as string;
    } else if (this.localCollaborateTeacherKey === '') {
      this.localCollaborateTeacherKeyError = this.$t('collaborateErrorMsg2') as string;
    }
    if (this.localCollaborateTeacherEmailError === '' && this.localCollaborateTeacherKeyError === '') {
      if (this.localCollaborateSaveTeacher) {
        this.localShowCollaborateClassesLoading = true;
        this.saveSharedTeacher({ sharedUserEmail: this.localCollaborateTeacherEmail, sharedUserKey: this.localCollaborateTeacherKey }).then((data) => {
          if (data.data.invalidTeacher === 'true') {
            this.localCollaborateTeacherEmailError = this.$t('collaborateErrorMsg3') as string;
            this.localShowCollaborateClassesLoading = false;
          }
          if (this.localCollaborateTeacherEmailError === '') {
            this.localSelectedClassClone.collaborateTeacherEmail = this.localCollaborateTeacherEmail;
            this.localSelectedClassClone.collaborateKey = this.localCollaborateTeacherKey;
            this.localSelectedClassClone.collaborateYearId = 0;
            this.localSelectedClassClone.collaborateSubjectId = 0;
            this.initCollaborateTeachers();
            this.localShowCollaborateClassesLoading = false;
            this.localReplicaSelectedTab = 0;
            this.localRefreshKey = CommonUtils.generateUUID();
          }
        });
      } else {
        this.localSelectedClassClone.collaborateTeacherEmail = this.localCollaborateTeacherEmail;
        this.localSelectedClassClone.collaborateKey = this.localCollaborateTeacherKey;
        this.localSelectedClassClone.collaborateYearId = 0;
        this.localSelectedClassClone.collaborateSubjectId = 0;
        this.initCollaborateTeachers();
        this.localShowCollaborateClassesLoading = false;
        this.localReplicaSelectedTab = 0;
        this.localRefreshKey = CommonUtils.generateUUID();
      }
    }
  }

  created() {
    if (this.userMode !== 'S') {
      this.initialize();
    }
    this.$eventBus.$on('selectedClassLoaded', () => {
      this.initialize();
    });
  }

  destroyed() {
    this.$eventBus.$off('selectedClassLoaded');
  }

  changeScheduleItemCycleDays(scheduleItem:any, cycleDay:any) {
    scheduleItem['day' + cycleDay.classScheduleDayIndex + 'Teach'] = !scheduleItem['day' + cycleDay.classScheduleDayIndex + 'Teach'];
    if (!scheduleItem['day' + cycleDay.classScheduleDayIndex + 'Teach']) {
      scheduleItem['day' + cycleDay.classScheduleDayIndex + 'StartTime'] = '';
      scheduleItem['day' + cycleDay.classScheduleDayIndex + 'EndTime'] = '';
    }
  }

  formatAdditionalClassDaysClassTimes() {
    // To make sure that the old class clone class schedule's additional class days have the same format as the new selected class clone
    for (const oldSchedule of this.localOldSelectedClassClone.classSchedule) {
      oldSchedule.additionalClassDays.forEach((acd: any) => {
        if (acd.startTime) {
          if (moment(acd.startTime, 'HH:mm:ss', true).isValid()) {
            acd.startTime = moment(acd.startTime, 'HH:mm:ss').format('h:mm A');
          }
        } else {
          acd.startTime = '';
        }
        if (acd.endTime) {
          if (moment(acd.endTime, 'HH:mm:ss', true).isValid()) {
            acd.endTime = moment(acd.endTime, 'HH:mm:ss').format('h:mm A');
          }
        } else {
          acd.endTime = '';
        }
        acd.dayCode = String(acd.dayCode);
      });
    }

    // To make sure that the new class clone class schedule's additional class days have the same format as the old selected class clone
    for (const newSchedule of this.localSelectedClassClone.classSchedule) {
      newSchedule.additionalClassDays.forEach((acd: any) => {
        if (acd.startTime) {
          if (moment(acd.startTime, 'HH:mm:ss', true).isValid()) {
            acd.startTime = moment(acd.startTime, 'HH:mm:ss').format('h:mm A');
          }
        } else {
          acd.startTime = '';
        }
        if (acd.endTime) {
          if (moment(acd.endTime, 'HH:mm:ss', true).isValid()) {
            acd.endTime = moment(acd.endTime, 'HH:mm:ss').format('h:mm A');
          }
        } else {
          acd.endTime = '';
        }
        acd.dayCode = String(acd.dayCode);
      });
    }
  }
}
