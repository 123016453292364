
import { Component, Prop, Vue } from 'vue-property-decorator';
import AuthServices from '@/services/auth-services';
import CommonUtils from '@/utils/common-utils';
import { cmnSession } from '@/common/planbook-storage';
import { namespace } from 'vuex-class';
import StudentServices from '@/services/student-services';
import TeacherServices from '@/services/teacher-services';

const welcome = namespace('welcome');

@Component
export default class OAuth2Callback extends Vue {
  @Prop({ required: true })
  code!: string;

  @Prop({ required: true })
  state!: string;

  get joinCode() {
    return this.$store.state.welcome.joinCode || '';
  }

  set joinCode(value: string) {
    this.$store.commit('welcome/setJoinCode', value);
  }

  get studentJoinCode() {
    return this.$store.state.welcome.studentJoinCode || '';
  }

  set studentJoinCode(value: string) {
    this.$store.commit('welcome/setStudentJoinCode', value);
  }

  get hasStudentJoinCode() {
    return CommonUtils.hasText(this.studentJoinCode);
  }

  get hasJoinCode() {
    return CommonUtils.hasText(this.joinCode);
  }

  mounted() {
    AuthServices.exchangeCodeForTokens(this.code).then(() => {
      if (this.hasJoinCode) {
        return TeacherServices.connectToSchool({ joinCode: this.joinCode });
      } else if (this.hasStudentJoinCode) {
        return StudentServices.enrollToClass({ joinCode: this.studentJoinCode });
      }
      return Promise.resolve({});
    }).then(() => {
      this.joinCode = '';
      this.studentJoinCode = '';
      const redirect = cmnSession.get('savedRedirectUri');
      this.$store.commit('settings/setInitialSignIn', true);
      if (CommonUtils.hasText(redirect)) {
        this.$router.push(redirect).then(() => {
          cmnSession.set('savedRedirectUri', '');
        });
      } else {
        this.$router.push('/');
      }
    }).catch(() => {
      this.$router.push('/error');
    });
  }
}
