var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-form",
        {
          ref: "lessonLayoutEditorForm",
          staticClass: "lesson-layout-editor-tab px-4",
          attrs: { "lazy-validation": "" }
        },
        [
          _c(
            "v-container",
            { attrs: { id: "lessonLayoutEditor" } },
            [
              _c(
                "v-row",
                { attrs: { justify: "center", align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "pb-0", attrs: { cols: "8" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("layoutNameLabel") + "*",
                          "aria-required": "true",
                          outlined: "",
                          rules: _vm.localRules,
                          readonly:
                            !_vm.isEditable() ||
                            _vm.localSelectedLayoutClone.lessonLayoutId === -1,
                          "validate-on-blur": "",
                          autofocus: ""
                        },
                        model: {
                          value: _vm.localSelectedLayoutClone.name,
                          callback: function($$v) {
                            _vm.$set(_vm.localSelectedLayoutClone, "name", $$v)
                          },
                          expression: "localSelectedLayoutClone.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pb-7 text-right", attrs: { cols: "4" } },
                    [
                      _c(
                        "pb-chip",
                        {
                          staticStyle: { opacity: "1" },
                          attrs: {
                            "active-class": "accent4--text",
                            "input-value": _vm.localSelectedLayoutDefaultLayout,
                            filter: "",
                            "filter-icon": "fas fa-star",
                            outlined: "",
                            large: "",
                            disabled: _vm.localDisableMarkAsCurrentOrDefault
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              _vm.localSelectedLayoutDefaultLayout = !_vm.localSelectedLayoutDefaultLayout
                            },
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              $event.stopPropagation()
                              _vm.localSelectedLayoutDefaultLayout = _vm.localDisableMarkAsCurrentOrDefault
                                ? _vm.localSelectedLayoutDefaultLayout
                                : !_vm.localSelectedLayoutDefaultLayout
                            }
                          }
                        },
                        [
                          _vm.$currentUser.isTeacher
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.$t("markAsCurrentLabel")))
                              ])
                            : _c("span", [
                                _vm._v(_vm._s(_vm.$t("markAsDefaultLabel")))
                              ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.isEditable()
                ? _c(
                    "v-row",
                    {
                      staticClass: "mt-0",
                      attrs: { align: "center", justify: "center" }
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-card",
                            { attrs: { outlined: "" } },
                            [
                              _c(
                                "v-card-text",
                                { staticClass: "ma-0 pa-0" },
                                [
                                  _c(
                                    "v-expansion-panels",
                                    {
                                      attrs: { flat: "", role: "tablist" },
                                      model: {
                                        value: _vm.localShowPanel,
                                        callback: function($$v) {
                                          _vm.localShowPanel = $$v
                                        },
                                        expression: "localShowPanel"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-expansion-panel",
                                        { attrs: { role: "tab" } },
                                        [
                                          _c("v-expansion-panel-header", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("sampleLayoutsLabel")
                                              )
                                            )
                                          ]),
                                          _c(
                                            "v-expansion-panel-content",
                                            [
                                              _c(
                                                "v-row",
                                                {
                                                  attrs: { justify: "center" }
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "4" } },
                                                    [
                                                      _c(
                                                        "v-card",
                                                        {
                                                          attrs: {
                                                            outlined: ""
                                                          }
                                                        },
                                                        [
                                                          _c("v-card-text", [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "title-on-border"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "basicLabel"
                                                                    )
                                                                  )
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "ul",
                                                              [
                                                                _vm._l(
                                                                  _vm.basic,
                                                                  function(
                                                                    item,
                                                                    i
                                                                  ) {
                                                                    return [
                                                                      item.enabled
                                                                        ? _c(
                                                                            "li",
                                                                            {
                                                                              key: i
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    item.label
                                                                                  ) +
                                                                                  " "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e()
                                                                    ]
                                                                  }
                                                                )
                                                              ],
                                                              2
                                                            )
                                                          ]),
                                                          _c(
                                                            "v-card-actions",
                                                            [
                                                              _c(
                                                                "v-row",
                                                                {
                                                                  attrs: {
                                                                    justify:
                                                                      "center",
                                                                    align:
                                                                      "center"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "pb-btn",
                                                                    {
                                                                      staticClass:
                                                                        "mb-2",
                                                                      attrs: {
                                                                        color:
                                                                          "primary",
                                                                        text: ""
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          return _vm.selectSampleLayout(
                                                                            _vm.basic
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "ml-1"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "selectLabel"
                                                                              )
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "4" } },
                                                    [
                                                      _c(
                                                        "v-card",
                                                        {
                                                          attrs: {
                                                            outlined: ""
                                                          }
                                                        },
                                                        [
                                                          _c("v-card-text", [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "title-on-border"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "instructionalLabel"
                                                                    )
                                                                  )
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "ul",
                                                              [
                                                                _vm._l(
                                                                  _vm.instructional,
                                                                  function(
                                                                    item,
                                                                    i
                                                                  ) {
                                                                    return [
                                                                      item.enabled
                                                                        ? _c(
                                                                            "li",
                                                                            {
                                                                              key: i
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    item.label
                                                                                  ) +
                                                                                  " "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e()
                                                                    ]
                                                                  }
                                                                )
                                                              ],
                                                              2
                                                            )
                                                          ]),
                                                          _c(
                                                            "v-card-actions",
                                                            [
                                                              _c(
                                                                "v-row",
                                                                {
                                                                  attrs: {
                                                                    justify:
                                                                      "center",
                                                                    align:
                                                                      "center"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "pb-btn",
                                                                    {
                                                                      staticClass:
                                                                        "mb-2",
                                                                      attrs: {
                                                                        color:
                                                                          "primary",
                                                                        text: ""
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          return _vm.selectSampleLayout(
                                                                            _vm.instructional
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "ml-1"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "selectLabel"
                                                                              )
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "4" } },
                                                    [
                                                      _c(
                                                        "v-card",
                                                        {
                                                          attrs: {
                                                            outlined: ""
                                                          }
                                                        },
                                                        [
                                                          _c("v-card-text", [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "title-on-border"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "detailedLabel"
                                                                    )
                                                                  )
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "ul",
                                                              [
                                                                _vm._l(
                                                                  _vm.detailed,
                                                                  function(
                                                                    item,
                                                                    i
                                                                  ) {
                                                                    return [
                                                                      item.enabled
                                                                        ? _c(
                                                                            "li",
                                                                            {
                                                                              key: i
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    item.label
                                                                                  ) +
                                                                                  " "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e()
                                                                    ]
                                                                  }
                                                                )
                                                              ],
                                                              2
                                                            )
                                                          ]),
                                                          _c(
                                                            "v-card-actions",
                                                            [
                                                              _c(
                                                                "v-row",
                                                                {
                                                                  attrs: {
                                                                    justify:
                                                                      "center",
                                                                    align:
                                                                      "center"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "pb-btn",
                                                                    {
                                                                      staticClass:
                                                                        "mb-2",
                                                                      attrs: {
                                                                        color:
                                                                          "primary",
                                                                        text: ""
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          return _vm.selectSampleLayout(
                                                                            _vm.detailed
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "ml-1"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "selectLabel"
                                                                              )
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                {
                  staticClass: "mt-4",
                  attrs: { align: "center", justify: "center" },
                  on: {
                    mouseenter: _vm.disableResizeable,
                    mouseleave: _vm.enableResizeable
                  }
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "rounded-lg",
                          style: {
                            "border-color": "#546E7A",
                            "background-color": "#546E7A"
                          },
                          attrs: { outlined: "" }
                        },
                        [
                          _c(
                            "v-card-title",
                            {
                              staticClass: "pa-1",
                              staticStyle: {
                                "background-color": "#546E7A",
                                color: "white"
                              }
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("lessonLabel")))
                              ]),
                              _c("v-spacer"),
                              _vm.hasCustomizations
                                ? _c(
                                    "pb-btn",
                                    {
                                      staticStyle: {
                                        "text-decoration": "none"
                                      },
                                      attrs: {
                                        label: _vm.$t(
                                          "removeAllCustomizations"
                                        ),
                                        icon: "",
                                        small: "",
                                        dark: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.resetDisplaySettings(-1)
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v("fal fa-arrow-rotate-left")
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "v-card-text",
                            {
                              staticClass: "pa-0",
                              staticStyle: {
                                "background-color": "white",
                                "border-bottom-left-radius": "7px",
                                "border-bottom-right-radius": "7px"
                              }
                            },
                            [
                              _vm.isEditable()
                                ? _c(
                                    "div",
                                    _vm._l(
                                      _vm.localSelectedLayoutClone.sections,
                                      function(item, i) {
                                        return _c(
                                          "div",
                                          {
                                            key: "top" + _vm.localRefreshKey + i
                                          },
                                          [
                                            item.section == "lessonTitle"
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "d-table pa-1",
                                                    staticStyle: {
                                                      width: "100%"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-table-row"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-table-cell pr-1",
                                                            staticStyle: {
                                                              width: "25px"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "pt-4",
                                                                attrs: {
                                                                  color:
                                                                    "transparent",
                                                                  small: ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "fal fa-sort-alt"
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-table-cell",
                                                            staticStyle: {
                                                              width: "100%"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-table",
                                                                staticStyle: {
                                                                  width: "100%"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-table-row"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-table-cell",
                                                                        style: _vm.getTitleStyle(
                                                                          item
                                                                        )
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item.label
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-table-cell"
                                                          },
                                                          [
                                                            _c(
                                                              "display-format-options",
                                                              {
                                                                attrs: {
                                                                  withShowTitle: false,
                                                                  formatBody: false,
                                                                  type:
                                                                    "absolute",
                                                                  value: item
                                                                },
                                                                on: {
                                                                  input: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.setLessonSectionFormat(
                                                                      i,
                                                                      item,
                                                                      $event
                                                                    )
                                                                  }
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var onMenu =
                                                                          ref.on
                                                                        return [
                                                                          _c(
                                                                            "pb-btn",
                                                                            _vm._g(
                                                                              {
                                                                                staticStyle: {
                                                                                  "text-decoration":
                                                                                    "none"
                                                                                },
                                                                                attrs: {
                                                                                  icon:
                                                                                    "",
                                                                                  small:
                                                                                    "",
                                                                                  label: _vm.$t(
                                                                                    "formatLabel"
                                                                                  )
                                                                                }
                                                                              },
                                                                              Object.assign(
                                                                                {},
                                                                                onMenu
                                                                              )
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    color: _vm.darkMode
                                                                                      ? "grey"
                                                                                      : "",
                                                                                    small:
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "fal fa-pen-paintbrush"
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                              _vm.isEditable()
                                ? _c(
                                    "draggable",
                                    {
                                      staticClass: "draggable-item__grab",
                                      attrs: {
                                        options: {
                                          forceFallback: true,
                                          disabled: _vm.draggableDisabled
                                        },
                                        "ghost-class": "draggable-item__move",
                                        animation: 200
                                      },
                                      model: {
                                        value:
                                          _vm.localSelectedLayoutClone.sections,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.localSelectedLayoutClone,
                                            "sections",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "localSelectedLayoutClone.sections"
                                      }
                                    },
                                    _vm._l(
                                      _vm.localSelectedLayoutClone.sections,
                                      function(item, i) {
                                        return _c(
                                          "div",
                                          {
                                            key: "mid" + _vm.localRefreshKey + i
                                          },
                                          [
                                            i != 0 &&
                                            item.section != "lessonTitle" &&
                                            item.section != "assessments" &&
                                            item.section != "assignments" &&
                                            item.section != "attachments"
                                              ? _c("div", {
                                                  staticClass:
                                                    "plans-place-holder",
                                                  staticStyle: {
                                                    "border-top":
                                                      "1px dotted #546E7A"
                                                  }
                                                })
                                              : _vm._e(),
                                            item.section != "lessonTitle" &&
                                            item.section != "assessments" &&
                                            item.section != "assignments" &&
                                            item.section != "attachments"
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "d-table pa-1",
                                                    staticStyle: {
                                                      width: "100%",
                                                      height: "64px"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-table-row"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-table-cell pr-1",
                                                            staticStyle: {
                                                              width: "25px"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-menu",
                                                              {
                                                                attrs: {
                                                                  bottom: "",
                                                                  "offset-y": ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var onMenu =
                                                                          ref.on
                                                                        return [
                                                                          _c(
                                                                            "v-icon",
                                                                            _vm._g(
                                                                              {
                                                                                ref:
                                                                                  "moveButton" +
                                                                                  i,
                                                                                refInFor: true,
                                                                                staticClass:
                                                                                  "mt-4 focus-icon-border",
                                                                                attrs: {
                                                                                  small:
                                                                                    ""
                                                                                }
                                                                              },
                                                                              onMenu
                                                                            ),
                                                                            [
                                                                              _vm._v(
                                                                                "fal fa-sort-alt"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              [
                                                                _c(
                                                                  "v-list",
                                                                  [
                                                                    i !== 1
                                                                      ? _c(
                                                                          "v-list-item",
                                                                          {
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.moveSectionUp(
                                                                                  i
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "moveUpLabel"
                                                                                )
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    i !==
                                                                    _vm
                                                                      .localSelectedLayoutClone
                                                                      .sections
                                                                      .length -
                                                                      4
                                                                      ? _c(
                                                                          "v-list-item",
                                                                          {
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.moveSectionDown(
                                                                                  i
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "moveDownLabel"
                                                                                )
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      : _vm._e()
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-table-cell",
                                                            staticStyle: {
                                                              width: "100%"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-table",
                                                                style: item.enabled
                                                                  ? "width: 100%"
                                                                  : "width: 100%; opacity: 0.35"
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-table-row"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-table-cell",
                                                                        style: _vm.getTitleStyle(
                                                                          item
                                                                        )
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "d-flex flex-nowrap"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.label
                                                                                  )
                                                                                )
                                                                              ]
                                                                            ),
                                                                            item.enabled
                                                                              ? _c(
                                                                                  "v-menu",
                                                                                  {
                                                                                    attrs: {
                                                                                      "close-on-content-click": false,
                                                                                      "close-on-click":
                                                                                        "",
                                                                                      "offset-x":
                                                                                        "",
                                                                                      right:
                                                                                        ""
                                                                                    },
                                                                                    scopedSlots: _vm._u(
                                                                                      [
                                                                                        {
                                                                                          key:
                                                                                            "activator",
                                                                                          fn: function(
                                                                                            ref
                                                                                          ) {
                                                                                            var onMenu =
                                                                                              ref.on
                                                                                            return [
                                                                                              _c(
                                                                                                "pb-btn",
                                                                                                _vm._g(
                                                                                                  {
                                                                                                    attrs: {
                                                                                                      label: _vm.$t(
                                                                                                        "editLabel"
                                                                                                      ),
                                                                                                      icon:
                                                                                                        "",
                                                                                                      "x-small":
                                                                                                        ""
                                                                                                    }
                                                                                                  },
                                                                                                  Object.assign(
                                                                                                    {},
                                                                                                    onMenu
                                                                                                  )
                                                                                                ),
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-icon",
                                                                                                    {
                                                                                                      attrs: {
                                                                                                        color: _vm.darkMode
                                                                                                          ? "grey"
                                                                                                          : "",
                                                                                                        "x-small":
                                                                                                          ""
                                                                                                      }
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "fal fa-pencil"
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              )
                                                                                            ]
                                                                                          }
                                                                                        }
                                                                                      ],
                                                                                      null,
                                                                                      true
                                                                                    ),
                                                                                    model: {
                                                                                      value:
                                                                                        item.isEditingLabel,
                                                                                      callback: function(
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          item,
                                                                                          "isEditingLabel",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                      expression:
                                                                                        "item.isEditingLabel"
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-card",
                                                                                      [
                                                                                        _c(
                                                                                          "v-card-text",
                                                                                          [
                                                                                            _c(
                                                                                              "v-text-field",
                                                                                              {
                                                                                                attrs: {
                                                                                                  outlined:
                                                                                                    "",
                                                                                                  clearable:
                                                                                                    "",
                                                                                                  dense:
                                                                                                    "",
                                                                                                  "hide-details":
                                                                                                    "auto",
                                                                                                  rules: item.enabled
                                                                                                    ? _vm.localRules
                                                                                                    : [],
                                                                                                  "validate-on-blur":
                                                                                                    "",
                                                                                                  autofocus:
                                                                                                    "",
                                                                                                  "aria-required":
                                                                                                    "true"
                                                                                                },
                                                                                                on: {
                                                                                                  keydown: function(
                                                                                                    $event
                                                                                                  ) {
                                                                                                    return _vm.closeLabelEditor(
                                                                                                      $event,
                                                                                                      item
                                                                                                    )
                                                                                                  }
                                                                                                },
                                                                                                model: {
                                                                                                  value:
                                                                                                    item.label,
                                                                                                  callback: function(
                                                                                                    $$v
                                                                                                  ) {
                                                                                                    _vm.$set(
                                                                                                      item,
                                                                                                      "label",
                                                                                                      $$v
                                                                                                    )
                                                                                                  },
                                                                                                  expression:
                                                                                                    "item.label"
                                                                                                }
                                                                                              }
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e()
                                                                          ],
                                                                          1
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-table-row"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-table-cell",
                                                                        style: _vm.getBodyStyle(
                                                                          item
                                                                        )
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                item.label
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-table-cell text-right",
                                                            on: {
                                                              mouseenter:
                                                                _vm.disableDraggable,
                                                              mouseleave:
                                                                _vm.enableDraggable
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "pb-chip",
                                                              {
                                                                staticStyle: {
                                                                  opacity: "1"
                                                                },
                                                                style:
                                                                  !item.enabled &&
                                                                  _vm.darkMode
                                                                    ? "border-color: #0000001f !important; color: #000000de;"
                                                                    : "",
                                                                attrs: {
                                                                  "active-class":
                                                                    "accent3--text",
                                                                  "input-value":
                                                                    item.enabled,
                                                                  filter: "",
                                                                  outlined: "",
                                                                  small: ""
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.preventDefault()
                                                                    $event.stopPropagation()
                                                                    return _vm.enableDisableSection(
                                                                      item
                                                                    )
                                                                  },
                                                                  keyup: function(
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    ) {
                                                                      return null
                                                                    }
                                                                    $event.preventDefault()
                                                                    $event.stopPropagation()
                                                                    return _vm.enableDisableSection(
                                                                      item
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.placeholderLabel +
                                                                        (item.enabled
                                                                          ? " Enabled"
                                                                          : " Disabled")
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            item.enabled
                                                              ? _c(
                                                                  "display-format-options",
                                                                  {
                                                                    attrs: {
                                                                      type:
                                                                        "absolute",
                                                                      value: item
                                                                    },
                                                                    on: {
                                                                      input: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.setLessonSectionFormat(
                                                                          i,
                                                                          item,
                                                                          $event
                                                                        )
                                                                      }
                                                                    },
                                                                    scopedSlots: _vm._u(
                                                                      [
                                                                        {
                                                                          key:
                                                                            "activator",
                                                                          fn: function(
                                                                            ref
                                                                          ) {
                                                                            var onMenu =
                                                                              ref.on
                                                                            return [
                                                                              _c(
                                                                                "pb-btn",
                                                                                _vm._g(
                                                                                  {
                                                                                    staticStyle: {
                                                                                      "text-decoration":
                                                                                        "none"
                                                                                    },
                                                                                    attrs: {
                                                                                      icon:
                                                                                        "",
                                                                                      small:
                                                                                        "",
                                                                                      label: _vm.$t(
                                                                                        "formatLabel"
                                                                                      )
                                                                                    }
                                                                                  },
                                                                                  Object.assign(
                                                                                    {},
                                                                                    onMenu
                                                                                  )
                                                                                ),
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    {
                                                                                      attrs: {
                                                                                        color: _vm.darkMode
                                                                                          ? "grey"
                                                                                          : "",
                                                                                        small:
                                                                                          ""
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "fal fa-pen-paintbrush"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ]
                                                                          }
                                                                        }
                                                                      ],
                                                                      null,
                                                                      true
                                                                    )
                                                                  }
                                                                )
                                                              : _vm._e()
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                              _vm.isEditable()
                                ? _c(
                                    "div",
                                    _vm._l(
                                      _vm.localSelectedLayoutClone.sections,
                                      function(item, i) {
                                        return _c(
                                          "div",
                                          {
                                            key:
                                              "bottom" + _vm.localRefreshKey + i
                                          },
                                          [
                                            item.section == "assessments" ||
                                            item.section == "assignments" ||
                                            item.section == "attachments"
                                              ? _c("div", {
                                                  staticClass:
                                                    "plans-place-holder",
                                                  staticStyle: {
                                                    "border-top":
                                                      "1px dotted #546E7A"
                                                  }
                                                })
                                              : _vm._e(),
                                            item.section == "assessments" ||
                                            item.section == "assignments" ||
                                            item.section == "attachments"
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "d-table pa-1",
                                                    staticStyle: {
                                                      width: "100%",
                                                      height: "64px"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-table-row"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-table-cell pr-1",
                                                            staticStyle: {
                                                              width: "25px"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "pt-4",
                                                                attrs: {
                                                                  color:
                                                                    "transparent",
                                                                  small: ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "fal fa-sort-alt"
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-table-cell",
                                                            staticStyle: {
                                                              width: "100%"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-table",
                                                                staticStyle: {
                                                                  width: "100%"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-table-row"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-table-cell",
                                                                        style: _vm.getTitleStyle(
                                                                          item
                                                                        )
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item.label
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-table-row"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-table-cell",
                                                                        style: _vm.getBodyStyle(
                                                                          item
                                                                        )
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                item.label
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-table-cell"
                                                          },
                                                          [
                                                            _c(
                                                              "display-format-options",
                                                              {
                                                                attrs: {
                                                                  type:
                                                                    "absolute",
                                                                  value: item
                                                                },
                                                                on: {
                                                                  input: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.setLessonSectionFormat(
                                                                      i,
                                                                      item,
                                                                      $event
                                                                    )
                                                                  }
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var onMenu =
                                                                          ref.on
                                                                        return [
                                                                          _c(
                                                                            "pb-btn",
                                                                            _vm._g(
                                                                              {
                                                                                staticStyle: {
                                                                                  "text-decoration":
                                                                                    "none"
                                                                                },
                                                                                attrs: {
                                                                                  icon:
                                                                                    "",
                                                                                  small:
                                                                                    "",
                                                                                  label: _vm.$t(
                                                                                    "formatLabel"
                                                                                  )
                                                                                }
                                                                              },
                                                                              Object.assign(
                                                                                {},
                                                                                onMenu
                                                                              )
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    color: _vm.darkMode
                                                                                      ? "grey"
                                                                                      : "",
                                                                                    small:
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "fal fa-pen-paintbrush"
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                              !_vm.isEditable()
                                ? _c(
                                    "div",
                                    _vm._l(
                                      _vm.localSelectedLayoutClone.sections,
                                      function(item, i) {
                                        return _c(
                                          "div",
                                          {
                                            key:
                                              "bottom" + _vm.localRefreshKey + i
                                          },
                                          [
                                            _c("div", {
                                              staticClass: "plans-place-holder",
                                              staticStyle: {
                                                "border-top":
                                                  "1px dotted #546E7A"
                                              }
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "d-table pa-1",
                                                style:
                                                  item.section != "lessonTitle"
                                                    ? "width: 100%; height: 64px"
                                                    : "width: 100%"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "d-table-row"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-table-cell pr-1",
                                                        staticStyle: {
                                                          width: "25px"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass: "pt-4",
                                                            attrs: {
                                                              color:
                                                                "transparent",
                                                              small: ""
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "fal fa-sort-alt"
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-table-cell",
                                                        staticStyle: {
                                                          width: "100%"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-table",
                                                            style: item.enabled
                                                              ? "width: 100%"
                                                              : "width: 100%; opacity: 0.35"
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-table-row"
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-table-cell",
                                                                    style: _vm.getTitleStyle(
                                                                      item
                                                                    )
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.label
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            ),
                                                            item.section !=
                                                            "lessonTitle"
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-table-row"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-table-cell",
                                                                        style: _vm.getBodyStyle(
                                                                          item
                                                                        )
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                item.label
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-table-cell"
                                                      },
                                                      [
                                                        item.enabled
                                                          ? _c(
                                                              "display-format-options",
                                                              {
                                                                attrs: {
                                                                  withShowTitle:
                                                                    item.section ==
                                                                    "lessonTitle"
                                                                      ? false
                                                                      : true,
                                                                  formatBody:
                                                                    item.section ==
                                                                    "lessonTitle"
                                                                      ? false
                                                                      : true,
                                                                  type:
                                                                    "absolute",
                                                                  value: item
                                                                },
                                                                on: {
                                                                  input: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.setLessonSectionFormat(
                                                                      i,
                                                                      item,
                                                                      $event
                                                                    )
                                                                  }
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var onMenu =
                                                                          ref.on
                                                                        return [
                                                                          _c(
                                                                            "pb-btn",
                                                                            _vm._g(
                                                                              {
                                                                                staticStyle: {
                                                                                  "text-decoration":
                                                                                    "none"
                                                                                },
                                                                                attrs: {
                                                                                  icon:
                                                                                    "",
                                                                                  small:
                                                                                    "",
                                                                                  label: _vm.$t(
                                                                                    "formatLabel"
                                                                                  )
                                                                                }
                                                                              },
                                                                              Object.assign(
                                                                                {},
                                                                                onMenu
                                                                              )
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    color: _vm.darkMode
                                                                                      ? "grey"
                                                                                      : "",
                                                                                    small:
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "fal fa-pen-paintbrush"
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              }
                                                            )
                                                          : _vm._e(),
                                                        item.type == 3
                                                          ? _c(
                                                              "pb-btn",
                                                              {
                                                                staticStyle: {
                                                                  "text-decoration":
                                                                    "none"
                                                                },
                                                                attrs: {
                                                                  label: _vm.$t(
                                                                    "removeCustomization"
                                                                  ),
                                                                  icon: "",
                                                                  small: ""
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.resetDisplaySettings(
                                                                      i
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      small: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "fal fa-arrow-rotate-left"
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("pb-confirm", { ref: "lessonLayoutEditorConfirm" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }