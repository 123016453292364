























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class MultiSelect extends Vue {
  @Prop({ required: false, type: String, default: '' })
  label!: string;

  @Prop({ required: true, type: Array })
  items!: Array<any>;

  @Prop({ required: false, type: String })
  itemText!: string;

  @Prop({ required: true, type: Array })
  selected!: any;

  get localSelected() {
    return this.selected;
  }

  set localSelected(val: Array<any>) {
    this.$emit('update:selected', val);
  }

  selectAllItems() {
    if (this.localSelected.length === this.items.length) {
      this.localSelected = [];
    } else {
      this.localSelected = this.items;
    }
  }
}
