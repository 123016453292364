var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.localValue
    ? _c(
        "pb-base-modal",
        {
          attrs: {
            persistent: true,
            maxWidth: 450,
            input: {},
            withActions: false,
            isExpandable: false,
            withApply: false
          },
          scopedSlots: _vm._u(
            [
              {
                key: "header",
                fn: function() {
                  return [_vm._v(" " + _vm._s(_vm.$t("sendToLabel")) + " ")]
                },
                proxy: true
              },
              {
                key: "actions",
                fn: function() {
                  return [
                    _c(
                      "pb-btn",
                      {
                        staticClass: "mr-1",
                        attrs: {
                          label: _vm.$t("sendLabel"),
                          icon: "",
                          right: ""
                        },
                        on: { click: _vm.doRequestPrint }
                      },
                      [_c("v-icon", [_vm._v("fal fa-arrow-up-from-square")])],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ],
            null,
            false,
            1047251404
          ),
          model: {
            value: _vm.localValue,
            callback: function($$v) {
              _vm.localValue = $$v
            },
            expression: "localValue"
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "v-form",
                { ref: "form" },
                [
                  _c(
                    "v-container",
                    { staticClass: "pa-5" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  "hide-details": "auto",
                                  outlined: "",
                                  label: _vm.$t("sendToLabel"),
                                  items: _vm.exportTypes
                                },
                                model: {
                                  value: _vm.exportType,
                                  callback: function($$v) {
                                    _vm.exportType = $$v
                                  },
                                  expression: "exportType"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.exportType !== "calendar"
                        ? [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("v-select", {
                                      attrs: {
                                        "hide-details": "auto",
                                        outlined: "",
                                        label: _vm.$t("layoutLabel"),
                                        items: _vm.printViews
                                      },
                                      model: {
                                        value: _vm.printView,
                                        callback: function($$v) {
                                          _vm.printView = $$v
                                        },
                                        expression: "printView"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            [
                              "Week",
                              "Day",
                              "Class",
                              "ClassMonth",
                              "LessonBank"
                            ].includes(_vm.printView) && _vm.userMode !== "S"
                              ? _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c("multi-select-field", {
                                          attrs: {
                                            "hide-details": "auto",
                                            label: _vm.$t("itemsLabel"),
                                            suffix: _vm.$t("itemsLabel"),
                                            items: _vm.printItems,
                                            mode: "chip"
                                          },
                                          model: {
                                            value: _vm.selectedPrintItems,
                                            callback: function($$v) {
                                              _vm.selectedPrintItems = $$v
                                            },
                                            expression: "selectedPrintItems"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            ["Class"].includes(_vm.printView)
                              ? _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c("multi-select-field", {
                                          attrs: {
                                            "hide-details": "auto",
                                            label: _vm.$t("classesLabel"),
                                            suffix: _vm.$t("classesLabel"),
                                            items: _vm.classItems,
                                            parent: "actionsFrame",
                                            mode: "chip"
                                          },
                                          model: {
                                            value: _vm.selectedClassItems,
                                            callback: function($$v) {
                                              _vm.selectedClassItems = $$v
                                            },
                                            expression: "selectedClassItems"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            ["ClassMonth"].includes(_vm.printView)
                              ? _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            "hide-details": "auto",
                                            outlined: "",
                                            items: _vm.classItems,
                                            label: _vm.$t("classesLabel")
                                          },
                                          model: {
                                            value: _vm.classId,
                                            callback: function($$v) {
                                              _vm.classId = $$v
                                            },
                                            expression: "classId"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            ["ToDo"].includes(_vm.printView)
                              ? _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c("multi-select-field", {
                                          attrs: {
                                            "hide-details": "auto",
                                            label: _vm.$t("itemsLabel"),
                                            suffix: _vm.$t("itemsLabel"),
                                            items: _vm.todoItems,
                                            mode: "chip"
                                          },
                                          model: {
                                            value: _vm.selectedToDoItems,
                                            callback: function($$v) {
                                              _vm.selectedToDoItems = $$v
                                            },
                                            expression: "selectedToDoItems"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            ["LessonBank"].includes(_vm.printView)
                              ? _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            "hide-details": "auto",
                                            outlined: "",
                                            items: _vm.lessonBankItems,
                                            label: _vm.$t("lessonBanksLabel")
                                          },
                                          model: {
                                            value: _vm.lessonBankId,
                                            callback: function($$v) {
                                              _vm.lessonBankId = $$v
                                            },
                                            expression: "lessonBankId"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        : _vm._e(),
                      !["LessonBank", "Notes", "ToDo", "Comments"].includes(
                        _vm.printView
                      ) || _vm.exportType === "calendar"
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("pb-date-time-range-field", {
                                    ref: "printRange",
                                    attrs: {
                                      hideDetails: "auto",
                                      start: _vm.startDate,
                                      end: _vm.endDate,
                                      rules: _vm.printRangeRules
                                    },
                                    on: {
                                      "update:start": function($event) {
                                        _vm.startDate = $event
                                      },
                                      "update:end": function($event) {
                                        _vm.endDate = $event
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.exportType !== "calendar"
                        ? [
                            ["Notes"].includes(_vm.printView)
                              ? _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c("pb-date-time-range-field", {
                                          ref: "printRange",
                                          attrs: {
                                            hideDetails: "auto",
                                            start: _vm.notesStartDate,
                                            end: _vm.notesEndDate,
                                            rules: _vm.printRangeRules
                                          },
                                          on: {
                                            "update:start": function($event) {
                                              _vm.notesStartDate = $event
                                            },
                                            "update:end": function($event) {
                                              _vm.notesEndDate = $event
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            ["Comments"].includes(_vm.printView)
                              ? _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c("pb-date-time-range-field", {
                                          ref: "printRange",
                                          attrs: {
                                            hideDetails: "auto",
                                            start: _vm.commentsStartDate,
                                            end: _vm.commentEndDate,
                                            rules: _vm.printRangeRules
                                          },
                                          on: {
                                            "update:start": function($event) {
                                              _vm.commentsStartDate = $event
                                            },
                                            "update:end": function($event) {
                                              _vm.commentEndDate = $event
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            ["ToDo"].includes(_vm.printView)
                              ? _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c("pb-date-time-range-field", {
                                          ref: "printRange",
                                          attrs: {
                                            hideDetails: "auto",
                                            start: _vm.todoStartDate,
                                            end: _vm.todoEndDate,
                                            rules: _vm.printRangeRules
                                          },
                                          on: {
                                            "update:start": function($event) {
                                              _vm.todoStartDate = $event
                                            },
                                            "update:end": function($event) {
                                              _vm.todoEndDate = $event
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            ["Week", "Day"].includes(_vm.printView)
                              ? _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c("pb-date-time-range-field", {
                                          attrs: {
                                            hideDetails: true,
                                            start: _vm.startTime,
                                            end: _vm.endTime,
                                            type: "time"
                                          },
                                          on: {
                                            "update:start": function($event) {
                                              _vm.startTime = $event
                                            },
                                            "update:end": function($event) {
                                              _vm.endTime = $event
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            ["Notes"].includes(_vm.printView)
                              ? _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            "hide-details": "auto",
                                            outlined: "",
                                            items: _vm.notesClassItems,
                                            label: _vm.$t("classesLabel")
                                          },
                                          model: {
                                            value: _vm.notesClassId,
                                            callback: function($$v) {
                                              _vm.notesClassId = $$v
                                            },
                                            expression: "notesClassId"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            ["Notes"].includes(_vm.printView)
                              ? _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            "hide-details": "auto",
                                            outlined: "",
                                            items: _vm.studentItems,
                                            label: _vm.$t("studentsLabel")
                                          },
                                          model: {
                                            value: _vm.notesStudentId,
                                            callback: function($$v) {
                                              _vm.notesStudentId = $$v
                                            },
                                            expression: "notesStudentId"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        : _vm._e(),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pt-0", attrs: { cols: "12" } },
                            [
                              _vm.exportType !== "calendar"
                                ? [
                                    !["Notes", "ToDo", "Comments"].includes(
                                      _vm.printView
                                    )
                                      ? _c(
                                          "v-radio-group",
                                          {
                                            staticClass: "mt-2 pt-0",
                                            attrs: {
                                              row: "",
                                              "hide-details": "auto"
                                            },
                                            model: {
                                              value: _vm.orientation,
                                              callback: function($$v) {
                                                _vm.orientation = $$v
                                              },
                                              expression: "orientation"
                                            }
                                          },
                                          [
                                            _c("v-radio", {
                                              attrs: {
                                                label: _vm.$t("portraitLabel"),
                                                value: "portrait"
                                              }
                                            }),
                                            _c("v-radio", {
                                              attrs: {
                                                label: _vm.$t("landscapeLabel"),
                                                value: "landscape"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    ["Week"].includes(_vm.printView)
                                      ? _c("v-checkbox", {
                                          attrs: {
                                            "hide-details": "auto",
                                            label: _vm.$t(
                                              "splitOver2WeeksLabel"
                                            )
                                          },
                                          model: {
                                            value: _vm.weekPages,
                                            callback: function($$v) {
                                              _vm.weekPages = $$v
                                            },
                                            expression: "weekPages"
                                          }
                                        })
                                      : _vm._e(),
                                    [
                                      "Week",
                                      "Day",
                                      "Class",
                                      "ClassMonth",
                                      "LessonBank",
                                      "Event",
                                      "EventMonth"
                                    ].includes(_vm.printView)
                                      ? _c("v-checkbox", {
                                          attrs: {
                                            "hide-details": "auto",
                                            label: _vm.$t(
                                              "equalSizeLessonLabel"
                                            )
                                          },
                                          model: {
                                            value: _vm.equalSize,
                                            callback: function($$v) {
                                              _vm.equalSize = $$v
                                            },
                                            expression: "equalSize"
                                          }
                                        })
                                      : _vm._e(),
                                    !["Notes", "ToDo", "Comments"].includes(
                                      _vm.printView
                                    )
                                      ? _c("v-checkbox", {
                                          attrs: {
                                            "hide-details": "auto",
                                            label: _vm.$t("avoidPageBreakLabel")
                                          },
                                          model: {
                                            value: _vm.avoidPageBreak,
                                            callback: function($$v) {
                                              _vm.avoidPageBreak = $$v
                                            },
                                            expression: "avoidPageBreak"
                                          }
                                        })
                                      : _vm._e(),
                                    ["Week", "Day", "Class"].includes(
                                      _vm.printView
                                    )
                                      ? _c("v-checkbox", {
                                          attrs: {
                                            "hide-details": "auto",
                                            label: _vm.$t(
                                              "printInGrayscaleLabel"
                                            )
                                          },
                                          model: {
                                            value: _vm.grayScale,
                                            callback: function($$v) {
                                              _vm.grayScale = $$v
                                            },
                                            expression: "grayScale"
                                          }
                                        })
                                      : _vm._e(),
                                    ["Day"].includes(_vm.printView)
                                      ? _c("v-checkbox", {
                                          attrs: {
                                            "hide-details": "auto",
                                            label: _vm.$t("print1LessonPerPage")
                                          },
                                          model: {
                                            value: _vm.oneLessonPerPage,
                                            callback: function($$v) {
                                              _vm.oneLessonPerPage = $$v
                                            },
                                            expression: "oneLessonPerPage"
                                          }
                                        })
                                      : _vm._e(),
                                    ["Day"].includes(_vm.printView)
                                      ? _c("v-checkbox", {
                                          attrs: {
                                            "hide-details": "auto",
                                            label: _vm.$t("printDayIn2Columns")
                                          },
                                          model: {
                                            value: _vm.twoColumns,
                                            callback: function($$v) {
                                              _vm.twoColumns = $$v
                                            },
                                            expression: "twoColumns"
                                          }
                                        })
                                      : _vm._e()
                                  ]
                                : [
                                    _c("v-checkbox", {
                                      attrs: {
                                        "hide-details": "auto",
                                        label: _vm.$t("passwordProtected")
                                      },
                                      model: {
                                        value: _vm.encrypted,
                                        callback: function($$v) {
                                          _vm.encrypted = $$v
                                        },
                                        expression: "encrypted"
                                      }
                                    })
                                  ]
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }