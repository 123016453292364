import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true })
export default class Index extends VuexModule {
    teacherListFullWidth = false
    openSubpage = '';
    isModal = false;
    subpageReference: any = null;
    confirmFunction = null;
    mainContentWidth = 0;
    resizeable: any = null;
    bannerOpen = false;
    fullScreen = false;

    get hasBanner() {
      return this.bannerOpen;
    }

    get isTeacherListFullWidth() {
      return this.teacherListFullWidth;
    }

    get getMobileBreakpoint() {
      if (this.mainContentWidth < 600) {
        return 2000; // mobile view
      } else {
        return 0; // desktop view
      }
    }

    get getIsMobileMode() {
      if (this.mainContentWidth < 600) {
        return true;
      } else {
        return false;
      }
    }

    get getMainContentBreakpoint() {
      if (this.mainContentWidth < 600) {
        return 'xs'
      } else if (this.mainContentWidth < 960) {
        return 'sm'
      } else if (this.mainContentWidth < 1264) {
        return 'md'
      } else if (this.mainContentWidth < 1904) {
        return 'lg'
      } else {
        return 'xl'
      }
    }

    @Mutation
    setHasBanner(bannerOpen: boolean) {
      this.bannerOpen = bannerOpen;
    }

    @Mutation
    setResizeable(resizeable: any) {
      this.resizeable = resizeable;
    }

    @Mutation
    setIsTeacherListFullWidth(val: boolean) {
      this.teacherListFullWidth = val;
    }

    @Mutation
    setConfirmFunction(value: any) {
      this.confirmFunction = value;
    }

    @Mutation
    setOpenSubpage(value: any) {
      this.openSubpage = value;
    }

    @Mutation
    setIsModal(value: any) {
      this.isModal = value;
    }

    @Mutation
    setFullScreen(fullScreen: boolean) {
      this.fullScreen = fullScreen;
    }

    @Mutation
    setSubpageReference(reference: any) {
      this.subpageReference = reference;
    }

    @Mutation
    reset() {
      this.teacherListFullWidth = false
      this.openSubpage = '';
      this.isModal = false;
      this.subpageReference = null;
      this.confirmFunction = null;
    }

    @Mutation
    setMobileBreakpoint(mainContentWidth: any) {
      this.mainContentWidth = mainContentWidth;
    }

    @Action({ rawError: true })
    disableResizeable() {
      if (this.resizeable) this.resizeable.destroy();
    }

    @Action({ rawError: true })
    enableResizeable() {
      if (this.resizeable) this.resizeable.setup();
    }
}
