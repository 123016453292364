import axios from '@/axios';
import qs from 'qs';

export default class FirebaseServices {
  public static registerToken(params?: any) {
    params = params || {};
    return axios.post('services/planbook/notification/register', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static updateEmail(params?: any) {
    params = params || {};
    return axios.post('services/planbook/notification/updateEmail', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static removeToken(params?: any) {
    params = params || {};
    return axios.post('services/planbook/notification/remove', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static removeAllTokens(params?: any) {
    params = params || {};
    return axios.post('services/planbook/notification/removeAll', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static removeAllOtherTokens(params?: any) {
    params = params || {};
    return axios.post('services/planbook/notification/removeAllOther', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static getAllDevices() {
    return axios.get('services/planbook/notification/getAllDevices', {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }
}
