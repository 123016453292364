import axios from '@/axios';
import queryString from 'query-string';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface BaseSaveUnitRequest {}

export interface DeleteUnitRequest extends BaseSaveUnitRequest {
    action: string;
    unitId: number;
    subjectId: number;
    userMode: string;
    fetchClassUnits?: boolean;
}

export interface SaveUnitRequest extends BaseSaveUnitRequest {
    action: string;
    unitId: number;
    subjectId: number;
    unitNum: string;
    unitTitle: string;
    unitDesc: string;
    unitColor: string;
    unitStart: string;
    unitEnd: string;
    applyStandards?: string;
    autoAdjustInd?: string;
    userMode: string;
    types: Array<string>;
    unitLessonText: string;
    unitHomeworkText: string;
    unitNotesText: string;
    unitSection4Text: string;
    unitSection5Text: string;
    unitSection6Text: string;
    standardDBIds: Array<number>;
    myStandardDBIds: Array<number>;
    schoolStandardDBIds: Array<number>;
    strategiesIds: Array<number>;
    attachmentNames: Array<string>;
    attachmentPrivate: Array<string>;
    attachmentURL: Array<string>;
}

export class UnitServices {
  public static async list(classId?: string, userMode?: string): Promise<any> {
    const params = {
      classId,
      userMode
    }
    return axios.get('getUnits', { params });
  }

  public static async getUnits(params: any): Promise<any> {
    return axios.get('getUnits', { params });
  }

  public static async getUnit(unitId?:number): Promise<any> {
    const params = {
      unitId
    }
    return axios.get('getUnit', { params });
  }

  public static async save(params: BaseSaveUnitRequest): Promise<any> {
    params = params || <BaseSaveUnitRequest>{};
    return axios.post('updateUnit', queryString.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static async deleteUnits(params: any) {
    return axios.delete('services/planbook/unit', {
      data: params,
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }
}

export default UnitServices;
