
import { Component, Vue, Prop } from 'vue-property-decorator';
import PermissionsEditorForm from '@/components/sharingoptions/PermissionsEditorForm.vue';
import Confirm from '../core/Confirm.vue';
import { namespace } from 'vuex-class';
import CommonUtils from '@/utils/common-utils';
import { Editor } from '@/types/global.types';

const permissions = namespace('permissions');

@Component({
  components: {
    PermissionsEditorForm
  }
})
export default class PermissionsEditor extends Vue implements Editor {
  @Prop({ type: Boolean })
  value!: boolean;

  @Prop({ type: Boolean, default: true })
  isModal!: boolean;

  @Prop({
    type: Object,
    default: () => {
      return { loadData: true, data: {} };
    }
  })
  input!: any

  $refs!: {
    form: PermissionsEditorForm,
    permissionEditorConfirm: Confirm
  }

  @permissions.Mutation
  setFetchingData!: (v: boolean) => void

  @permissions.Getter('getPermissionsData')
  lessonPermissions!: any[];

  get localValue() {
    return this.value;
  }

  set localValue(val: boolean) {
    this.$emit('input', val);
  }

  isDirty() {
    return this.$refs.form && this.$refs.form.isDirty();
  }

  typeChange() {
    this.input.loadData = false;
  }

  cancelEditor() {
    this.$eventBus.$emit('closePermissionEditor', false);
    return Promise.resolve();
  }

  deletePermission() {
    const that = this;
    return this.$refs.permissionEditorConfirm.confirm({
      title: this.$t('deletePermissionLabel'),
      text: this.$t('deletePermissionText'),
      option1ButtonAlternativeText: this.$t('continueLabel')
    }).then((result:any) => {
      if (result === 1) {
        that.setFetchingData(true);
        CommonUtils.showLoading();
        const payload = that.lessonPermissions.filter((p: any) => p.id !== that.input.data.id);
        that.$refs.form.savePermission(payload).then(() => {
          this.$eventBus.$emit('closeSubPage');
        });
      }
    });
  }

  doApply() {
    return this.$refs.form.doApply();
  }
}
