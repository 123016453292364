import qs from 'qs';
import axios from '@/axios';

export default class EmailServices {
  public static send(params: any) {
    return axios.post('services/planbook/emails/send', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }
}
