import AppError, { createErrOptions, SessionExpiredError } from '@/errors';
import gs from '@/services/google-app-services';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true })
export default class Google extends VuexModule {
  drive = {};
  calendars = [];
  googleClasses = [];
  profile = {};
  private static isConnectedToGoogle = false;

  get getIsConnectedToGoogle(): boolean {
    return Google.isConnectedToGoogle;
  }

  @Mutation
  setDrive(drive: any) {
    this.drive = drive;
  }

  @Mutation
  setCalendars(calendars: any) {
    this.calendars = calendars || [];
  }

  @Mutation
  setGoogleClasses(googleClasses: any) {
    this.googleClasses = googleClasses || [];
  }

  @Mutation
  setProfile(profile: any) {
    this.profile = profile;
  }

  @Mutation
  clear() {
    this.drive = {};
    this.calendars = [];
    this.googleClasses = [];
    this.profile = {};
  }

  @Action({ rawError: true })
  async loadGoogleClasses() {
    try {
      const context = this.context;
      await gs.connect();
      const resp = await gs.getGoogleClasses();
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          context.commit('setGoogleClasses', resp.data);
          return Promise.resolve({ googleClasses: resp.data });
        }
      }
      return Promise.resolve({ data: {} });
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async loadCalendars() {
    try {
      const context = this.context;
      await gs.connect();
      const resp = await gs.getCalendars();
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          context.commit('setCalendars', resp.data.calendars);
          context.commit('setProfile', resp.data.profile);
          return Promise.resolve({ calendars: resp.data.calendars, profile: resp.data.profile });
        }
      }
      return Promise.resolve({ data: {} });
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  public static setIsConnectedToGoogle(v: boolean) {
    Google.isConnectedToGoogle = v;
  }
}
