
import { Component, Vue, Watch } from 'vue-property-decorator';
import { fontSizes, swatches } from '../../constants';
import { namespace } from 'vuex-class';
const settings = namespace('settings');
@Component({
  props: {
    textAndFill: {
      type: Boolean,
      default: () => { return false; }
    },
    dualRow: {
      type: Boolean,
      default: () => { return false; }
    },
    font1: {
      type: String,
      default: '2'
    },
    font2: {
      type: String,
      default: '2'
    },
    fontSize1: {
      type: String,
      default: '10'
    },
    fontSize2: {
      type: String,
      default: '10'
    },
    foregroundColor1: {
      type: String,
      default: '#212121'
    },
    foregroundColor2: {
      type: String,
      default: '#212121'
    },
    backgroundColor1: {
      type: String,
      default: '#FFFFFF'
    },
    backgroundColor2: {
      type: String,
      default: '#FFFFFF'
    },
    bold1: {
      type: Boolean,
      default: () => { return false; }
    },
    bold2: {
      type: Boolean,
      default: () => { return false; }
    },
    italic1: {
      type: Boolean,
      default: () => { return false; }
    },
    italic2: {
      type: Boolean,
      default: () => { return false; }
    },
    underline1: {
      type: Boolean,
      default: () => { return false; }
    },
    underline2: {
      type: Boolean,
      default: () => { return false; }
    },
    dense1: {
      type: Boolean,
      default: () => { return false; }
    },
    dense2: {
      type: Boolean,
      default: () => { return false; }
    },
    origin1: {
      type: String,
      default: 'top left'
    },
    origin2: {
      type: String,
      default: 'top left'
    }
  }
})
export default class FormatOptions extends Vue {
  localToggleValues1: number[] = [];
  localToggleValues2: number[] = [];
  localFont1 = '2';
  localFont2 = '2';
  localFontSize1 = '10';
  localFontSize2 = '10';
  localForegroundColor1 = '#212121';
  localForegroundColor2 = '#212121';
  localBackgroundColor1 = '#FFFFFF';
  localBackgroundColor2 = '#FFFFFF';
  localBold1 = false;
  localBold2 = false;
  localItalic1 = false;
  localItalic2 = false;
  localUnderline1 = false;
  localUnderline2 = false;

  @settings.Getter('getFontItems')
  fonts!: Array<any>;

  get colors() {
    return swatches;
  }

  get fontList() {
    return this.fonts;
  }

  get fontSizesList() {
    return fontSizes;
  }

  @Watch('font1')
  updateLocalFont1(v: string) {
    this.localFont1 = v;
  }

  @Watch('localFont1')
  updateFont1(v: string) {
    this.$emit('update:font1', v);
  }

  @Watch('font2')
  updateLocalFont2(v: string) {
    this.localFont2 = v;
  }

  @Watch('localFont2')
  updateFont2(v: string) {
    this.$emit('update:font2', v);
  }

  @Watch('fontSize1')
  updateLocalFontSize1(v: string) {
    this.localFontSize1 = v;
  }

  @Watch('localFontSize1')
  updateFontSize1(v: string) {
    this.$emit('update:fontSize1', v);
  }

  @Watch('fontSize2')
  updateLocalFontSize2(v: string) {
    this.localFontSize2 = v;
  }

  @Watch('localFontSize2')
  updateFontSize2(v: string) {
    this.$emit('update:fontSize2', v);
  }

  @Watch('foregroundColor1')
  updateLocalForegroundColor1(v: string) {
    this.localForegroundColor1 = v;
  }

  @Watch('localForegroundColor1')
  updateForegroundColor1(v: string) {
    this.$emit('update:foregroundColor1', v);
  }

  @Watch('foregroundColor2')
  updateLocalForegroundColor2(v: string) {
    this.localForegroundColor2 = v;
  }

  @Watch('localForegroundColor2')
  updateForegroundColor2(v: string) {
    this.$emit('update:foregroundColor2', v);
  }

  @Watch('backgroundColor1')
  updateLocalBackgroundColor1(v: string) {
    this.localBackgroundColor1 = v;
  }

  @Watch('localBackgroundColor1')
  updateBackgroundColor1(v: string) {
    this.$emit('update:backgroundColor1', v);
  }

  @Watch('backgroundColor2')
  updateLocalBackgroundColor2(v: string) {
    this.localBackgroundColor2 = v;
  }

  @Watch('localBackgroundColor2')
  updateBackgroundColor2(v: string) {
    this.$emit('update:backgroundColor2', v);
  }

  @Watch('bold1')
  updateLocalBold1(v: boolean) {
    this.localBold1 = v;
    this.localToggleValues1 = this.getLocalToggleValues(v, this.localItalic1, this.localUnderline1);
  }

  @Watch('italic1')
  updateLocalItalic1(v: boolean) {
    this.localItalic1 = v;
    this.localToggleValues1 = this.getLocalToggleValues(this.localBold1, v, this.localUnderline1);
  }

  @Watch('underline1')
  updateLocalUnderline1(v: boolean) {
    this.localUnderline1 = v;
    this.localToggleValues1 = this.getLocalToggleValues(this.localBold1, this.localItalic1, v);
  }

  @Watch('localToggleValues1')
  updateBold1Italic1Underline1(v: number[]) {
    if (v.includes(0)) {
      this.$emit('update:bold1', true);
    } else {
      this.$emit('update:bold1', false);
    }
    if (v.includes(1)) {
      this.$emit('update:italic1', true);
    } else {
      this.$emit('update:italic1', false);
    }
    if (v.includes(2)) {
      this.$emit('update:underline1', true);
    } else {
      this.$emit('update:underline1', false);
    }
  }

  @Watch('bold2')
  updateLocalBold2(v: boolean) {
    this.localBold2 = v;
    this.localToggleValues2 = this.getLocalToggleValues(v, this.localItalic2, this.localUnderline2);
  }

  @Watch('italic2')
  updateLocalItalic2(v: boolean) {
    this.localItalic2 = v;
    this.localToggleValues2 = this.getLocalToggleValues(this.localBold2, v, this.localUnderline2);
  }

  @Watch('underline2')
  updateLocalUnderline2(v: boolean) {
    this.localUnderline2 = v;
    this.localToggleValues2 = this.getLocalToggleValues(this.localBold2, this.localItalic2, v);
  }

  @Watch('localToggleValues2')
  updateBold2Italic2Underline2(v: number[]) {
    if (v.includes(0)) {
      this.$emit('update:bold2', true);
    } else {
      this.$emit('update:bold2', false);
    }
    if (v.includes(1)) {
      this.$emit('update:italic2', true);
    } else {
      this.$emit('update:italic2', false);
    }
    if (v.includes(2)) {
      this.$emit('update:underline2', true);
    } else {
      this.$emit('update:underline2', false);
    }
  }

  getLocalToggleValues(b: boolean, i: boolean, u: boolean) {
    const temp = [];
    if (b) {
      temp.push(0);
    }
    if (i) {
      temp.push(1);
    }
    if (u) {
      temp.push(2);
    }
    return temp;
  }

  focus() {
    this.$nextTick(() => {
      new Promise(resolve => setTimeout(resolve, 100)).then(() => {
        (this.$refs as any).selectFontType.$el.querySelector('input').focus();
      });
    });
  }

  created() {
    this.focus();
    if (this.$props.font1 !== '') {
      this.localFont1 = this.$props.font1;
    }
    if (this.$props.dualRow && this.$props.font2 !== '') {
      this.localFont2 = this.$props.font2;
    }
    if (this.$props.fontSize1 !== '') {
      this.localFontSize1 = this.$props.fontSize1;
    }
    if (this.$props.dualRow && this.$props.fontSize2 !== '') {
      this.localFontSize2 = this.$props.fontSize2;
    }
    if (this.$props.foregroundColor1 !== '') {
      this.localForegroundColor1 = this.$props.foregroundColor1;
    }
    if (this.$props.dualRow && this.$props.textAndFill && this.$props.foregroundColor2 !== '') {
      this.localForegroundColor2 = this.$props.foregroundColor2
    }
    if (this.$props.backgroundColor1 !== '') {
      this.localBackgroundColor1 = this.$props.backgroundColor1;
    }
    if (this.$props.dualRow && this.$props.textAndFill && this.$props.backgroundColor2 !== '') {
      this.localBackgroundColor2 = this.$props.backgroundColor2;
    }
    this.localToggleValues1 = this.getLocalToggleValues(this.$props.bold1, this.$props.italic1, this.$props.underline1);
    if (this.$props.dualRow) {
      this.localToggleValues2 = this.getLocalToggleValues(this.$props.bold2, this.$props.italic2, this.$props.underline2);
    }
  }
}
