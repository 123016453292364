import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import eventBus from '@/events';
import i18n from '@/plugins/i18n';
import { getToken, isSupported, onMessage } from 'firebase/messaging'
import firebase from '@/firebase';
import FirebaseServices from '@/services/firebase-services';
import AppError, { SessionExpiredError } from '@/errors';
import DateTimeUtils from '@/utils/date-time-utils';

@Module({ namespaced: true })
export default class Firebase extends VuexModule {
    vapidKey = 'BDNQot6bUO0gc9oZDUbRcUJr3yYGOKf7iuoIj9OJyBgQfZP8kNDldX3L-xREs2qiQX3qxbx42_humzDL6gpIGnI';
    firebaseMessaging: any = null;
    firebaseToken: any = null;
    isSupported: any = null;
    promptNotification = 'false';
    notificationBlocked = false;

    get getToken() {
      return this.firebaseToken;
    }

    get getPromptNotification() {
      return this.promptNotification;
    }

    get getNotificationBlocked() {
      return this.notificationBlocked;
    }

    @Mutation
    setPromptNotificatin(prompt: string) {
      if (this.promptNotification === 'prompted' || this.promptNotification === 'blocked') {
        return;
      }
      this.promptNotification = prompt;
    }

    @Mutation
    setNotificationBlocked(val: boolean) {
      this.notificationBlocked = val;
    }

    @Mutation
    setApp(app: any) {
      this.firebaseMessaging = app
    }

    @Mutation
    setOnMessage() {
      onMessage(this.firebaseMessaging, (payload) => {
        const data = payload.data || {};
        eventBus.$emit('notificationReceived', data);
      })
    }

    @Mutation
    setToken(token: any) {
      this.firebaseToken = token;
    }

    @Mutation
    setIsSupported(val: boolean) {
      this.isSupported = val;
    }

    @Action({ rawError: true })
    saveToken(token: any) {
      if (token !== null && token !== this.firebaseToken) {
        const deviceName = Firebase.getBrowserName();
        this.context.dispatch('registerToken', { teacherId: this.context.rootState.settings.currentTeacherId, token: token, name: deviceName });
      }
      this.context.commit('setToken', token);
    }

    @Action({ rawError: true })
    async initializeFirebase() {
      if (this.isSupported === null) {
        await isSupported().then((resp) => {
          this.context.commit('setIsSupported', resp);
        });
      }
      if (this.isSupported === false) {
        return Promise.resolve();
      }
      this.context.commit('setApp', firebase.firebaseMessaging);
      this.context.commit('setOnMessage');
      return getToken(this.firebaseMessaging, { vapidKey: this.vapidKey }).then((token) => {
        this.context.dispatch('saveToken', token);
      }).catch((e: any) => {
        if (e.message === 'Registration failed - push service error') {
          this.context.commit('setNotificationBlocked', true);
          return Promise.resolve();
        } else {
          this.context.commit('setPromptNotificatin', 'true');
          return Promise.resolve();
        }
      });
    }

    @Action({ rawError: true })
    async promptNotificationPermission() {
      return Notification.requestPermission().then((permission: any) => {
        if (permission === 'granted') {
          return getToken(this.firebaseMessaging, { vapidKey: this.vapidKey }).then((token) => {
            this.context.commit('setPromptNotificatin', 'granted');
            this.context.dispatch('saveToken', token);
          })
        } else {
          // options : default, denied
          this.context.dispatch('saveToken', null);
        }
      })
    }

    @Action({ rawError: true })
    async signOut(token?: string) {
      if (token !== null || this.firebaseToken !== null) {
        const params = {
          teacherId: this.context.rootState.settings.currentTeacherId,
          token: token || this.firebaseToken
        };
        const resp = await FirebaseServices.removeToken(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            this.context.commit('setToken', null);
            return Promise.resolve();
          }
        } else {
          return Promise.resolve();
        }
      } else {
        return Promise.resolve();
      }
    }

    @Action({ rawError: true })
    async signOutAll() {
      const resp = await FirebaseServices.removeAllTokens();
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          this.context.commit('setToken', null);
          return Promise.resolve();
        }
      } else {
        return Promise.resolve();
      }
    }

    @Action({ rawError: true })
    async signOutAllOther(token?: string) {
      if (this.firebaseToken !== null) {
        const params = {
          teacherId: this.context.rootState.settings.currentTeacherId,
          token: token || this.firebaseToken
        };
        const resp = await FirebaseServices.removeAllOtherTokens(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            this.context.commit('setToken', null);
            return Promise.resolve();
          }
        } else {
          return Promise.resolve();
        }
      } else {
        return this.signOutAll();
      }
    }

    @Action({ rawError: true })
    async registerToken(params: any) {
      const resp = await FirebaseServices.registerToken(params);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          return Promise.resolve();
        }
      } else {
        return Promise.resolve();
      }
    }

    @Action({ rawError: true })
    async updateEmail(params: any) {
      const resp = await FirebaseServices.updateEmail(params);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          return Promise.resolve();
        }
      } else {
        return Promise.resolve();
      }
    }

    @Action({ rawError: true })
    async getAllDevices() {
      const resp = await FirebaseServices.getAllDevices();
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    }

    static getBrowserName() {
      const nav: any = navigator;
      let browser = 'Unknown';
      if ((nav.userAgent.indexOf('Opera') || nav.userAgent.indexOf('OPR')) !== -1) {
        browser = 'Opera';
      } else if (nav.userAgent.indexOf('Edg') !== -1) {
        browser = 'Edge';
      } else if (nav.brave) {
        browser = 'Brave';
      } else if (nav.userAgent.indexOf('Chrome') !== -1) {
        browser = 'Chrome';
      } else if (nav.userAgent.indexOf('Safari') !== -1) {
        browser = 'Safari';
      } else if (nav.userAgent.indexOf('Firefox') !== -1) {
        browser = 'Firefox';
      } else if ((nav.userAgent.indexOf('MSIE') !== -1) || (!!document.DOCUMENT_NODE === true)) {
        browser = 'Internet Explorer';
      }

      let platform = 'Unknown';
      if (nav.platform.indexOf('Mac') !== -1) {
        platform = 'Mac';
      } else if (nav.platform.indexOf('Win') !== -1) {
        platform = 'Windows';
      } else if (nav.platform.indexOf('Linux') !== -1) {
        platform = 'Linux';
      } else {
        platform = 'Unkown';
      }

      return (browser + ' on ' + platform);
    }
}
