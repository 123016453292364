var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isGenericView
    ? _c(
        "v-navigation-drawer",
        {
          attrs: {
            id: "leftDrawer",
            "mini-variant": _vm.isMiniVariant,
            "mini-variant-width": "82",
            width: "290",
            height: "100vh",
            "mobile-breakpoint": "960",
            "expand-on-hover": _vm.expandOnHover && _vm.isDockedExpandOnHover,
            permanent: _vm.isDrawerPermanent,
            stateless: _vm.drawerLeftStateless,
            app: "",
            light: _vm.highContrastMode,
            dark: !_vm.highContrastMode,
            temporary: _vm.isLeftDrawerMenuTypeHidden
          },
          on: {
            "update:miniVariant": function($event) {
              _vm.isMiniVariant = $event
            },
            "update:mini-variant": function($event) {
              _vm.isMiniVariant = $event
            }
          },
          model: {
            value: _vm.drawer,
            callback: function($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer"
          }
        },
        [
          _c(
            "div",
            { staticStyle: { height: "72px" } },
            [
              _c(
                "router-link",
                { attrs: { to: "/plans" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "ma-0 pa-0", attrs: { width: "290" } },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "ma-0 pa-0" },
                        [
                          _c(
                            "v-list",
                            {
                              staticClass: "ma-0 pa-0",
                              attrs: { height: "72", width: "auto" }
                            },
                            [
                              _c(
                                "v-list-item",
                                {
                                  class: { "seasonal-icon": _vm.isSeasonal },
                                  staticStyle: { "padding-left": "14px" }
                                },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    { staticStyle: { "margin-right": "10px" } },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            "open-delay": "500",
                                            bottom:
                                              _vm.isDockedExpandOnHover ||
                                              _vm.isLeftDrawerMenuTypeHidden,
                                            right: _vm.isDockedCompact
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var onMenu = ref.on
                                                  return [
                                                    _c(
                                                      "v-img",
                                                      _vm._g(
                                                        {
                                                          staticClass:
                                                            "seasonal-icon-image ma-0",
                                                          staticStyle: {
                                                            cursor: "pointer"
                                                          },
                                                          attrs: {
                                                            width: "55",
                                                            height: "auto",
                                                            src: _vm.iconSrc,
                                                            alt: _vm.$t(
                                                              "app.name"
                                                            )
                                                          }
                                                        },
                                                        Object.assign(
                                                          {},
                                                          onMenu
                                                        )
                                                      )
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            1880948571
                                          )
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(_vm.seasonalTooltip)
                                              )
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-img", {
                                        staticClass: "mx-0 my-1",
                                        staticStyle: { cursor: "pointer" },
                                        attrs: {
                                          width: "150",
                                          height: "auto",
                                          src: _vm.highContrastMode
                                            ? "https://cdn.planbook.com/images/planbook-text-blue.svg"
                                            : "https://cdn.planbook.com/images/planbook-text-white.svg",
                                          alt: _vm.$t("app.name")
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "perfect-scrollbar",
            { attrs: { id: "goto-items" } },
            [
              _c(
                "v-card",
                { staticClass: "ma-0 pa-0", attrs: { width: "290" } },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "px-0" },
                    [
                      _c(
                        "v-list",
                        {
                          staticClass: "mb-4 px-3",
                          attrs: { nav: "", dense: "" }
                        },
                        [
                          _c(
                            "v-list-item-group",
                            {
                              key: _vm.refreshKey,
                              class: _vm.highContrastMode
                                ? "accent1--text"
                                : "white--text",
                              attrs: {
                                "aria-label": _vm.$t("goToItemsLabel"),
                                role: "navigation"
                              },
                              model: {
                                value: _vm.localSelected,
                                callback: function($$v) {
                                  _vm.localSelected = $$v
                                },
                                expression: "localSelected"
                              }
                            },
                            [
                              _vm._l(_vm.gotoItems, function(item, i) {
                                return [
                                  _c(
                                    "v-tooltip",
                                    {
                                      key: i,
                                      attrs: {
                                        "open-delay": "200",
                                        right: "",
                                        disabled: !_vm.isDockedCompact
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                !item.subitems
                                                  ? _c(
                                                      "v-list-item",
                                                      _vm._g(
                                                        {
                                                          key: i,
                                                          attrs: {
                                                            to: item.to,
                                                            dense: "",
                                                            id: _vm.getId(item)
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "v-list-item-icon",
                                                          [
                                                            item.withBadge
                                                              ? _c(
                                                                  "v-badge",
                                                                  {
                                                                    attrs: {
                                                                      dot: "",
                                                                      color:
                                                                        "error",
                                                                      "offset-x":
                                                                        "10",
                                                                      "offset-y":
                                                                        "5"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        staticClass:
                                                                          "list-item-icon",
                                                                        attrs: {
                                                                          color:
                                                                            item.color
                                                                        },
                                                                        domProps: {
                                                                          textContent: _vm._s(
                                                                            item.icon
                                                                          )
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _c("v-icon", {
                                                                  staticClass:
                                                                    "list-item-icon",
                                                                  attrs: {
                                                                    color:
                                                                      item.color
                                                                  },
                                                                  domProps: {
                                                                    textContent: _vm._s(
                                                                      item.icon
                                                                    )
                                                                  }
                                                                })
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-list-item-content",
                                                          [
                                                            _c(
                                                              "v-list-item-title",
                                                              {
                                                                domProps: {
                                                                  textContent: _vm._s(
                                                                    item.text
                                                                  )
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "v-list-group",
                                                      _vm._g(
                                                        {
                                                          key: i,
                                                          attrs: {
                                                            id: item.id
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "appendIcon",
                                                                fn: function() {
                                                                  return [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          small:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "fal fa-chevron-down"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                },
                                                                proxy: true
                                                              },
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function() {
                                                                  return [
                                                                    _c(
                                                                      "v-list-item-icon",
                                                                      [
                                                                        item.withBadge
                                                                          ? _c(
                                                                              "v-badge",
                                                                              {
                                                                                attrs: {
                                                                                  dot:
                                                                                    "",
                                                                                  color:
                                                                                    "error",
                                                                                  "offset-x":
                                                                                    "10",
                                                                                  "offset-y":
                                                                                    "5"
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  {
                                                                                    staticClass:
                                                                                      "list-item-icon",
                                                                                    attrs: {
                                                                                      color:
                                                                                        item.color
                                                                                    },
                                                                                    domProps: {
                                                                                      textContent: _vm._s(
                                                                                        item.icon
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _c(
                                                                              "v-icon",
                                                                              {
                                                                                staticClass:
                                                                                  "list-item-icon",
                                                                                attrs: {
                                                                                  color:
                                                                                    item.color
                                                                                },
                                                                                domProps: {
                                                                                  textContent: _vm._s(
                                                                                    item.icon
                                                                                  )
                                                                                }
                                                                              }
                                                                            )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-list-item-content",
                                                                      [
                                                                        _c(
                                                                          "v-list-item-title",
                                                                          {
                                                                            domProps: {
                                                                              textContent: _vm._s(
                                                                                item.text
                                                                              )
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                },
                                                                proxy: true
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _vm._l(
                                                          item.subitems,
                                                          function(subitem, j) {
                                                            return [
                                                              _c(
                                                                "v-tooltip",
                                                                {
                                                                  key: j,
                                                                  attrs: {
                                                                    "open-delay":
                                                                      "200",
                                                                    right: "",
                                                                    disabled: !_vm.isDockedCompact
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "activator",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var on =
                                                                            ref.on
                                                                          return [
                                                                            _c(
                                                                              "v-list-item",
                                                                              _vm._g(
                                                                                {
                                                                                  key: j,
                                                                                  class: {
                                                                                    "ml-5":
                                                                                      (_vm.isDockedExpandOnHover &&
                                                                                        !_vm.isMiniVariant) ||
                                                                                      (_vm.isLeftDrawerMenuTypeHidden &&
                                                                                        !_vm.isMiniVariant)
                                                                                  },
                                                                                  attrs: {
                                                                                    to:
                                                                                      subitem.to,
                                                                                    dense:
                                                                                      "",
                                                                                    id: _vm.getId(
                                                                                      subitem
                                                                                    )
                                                                                  }
                                                                                },
                                                                                on
                                                                              ),
                                                                              [
                                                                                _c(
                                                                                  "v-list-item-icon",
                                                                                  [
                                                                                    subitem.withBadge
                                                                                      ? _c(
                                                                                          "v-badge",
                                                                                          {
                                                                                            attrs: {
                                                                                              dot:
                                                                                                "",
                                                                                              color:
                                                                                                "error",
                                                                                              "offset-x":
                                                                                                "10",
                                                                                              "offset-y":
                                                                                                "5"
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-icon",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "list-item-icon",
                                                                                                attrs: {
                                                                                                  color:
                                                                                                    subitem.color
                                                                                                },
                                                                                                domProps: {
                                                                                                  textContent: _vm._s(
                                                                                                    subitem.icon
                                                                                                  )
                                                                                                }
                                                                                              }
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      : _c(
                                                                                          "v-icon",
                                                                                          {
                                                                                            staticClass:
                                                                                              "list-item-icon",
                                                                                            attrs: {
                                                                                              color:
                                                                                                subitem.color
                                                                                            },
                                                                                            domProps: {
                                                                                              textContent: _vm._s(
                                                                                                subitem.icon
                                                                                              )
                                                                                            }
                                                                                          }
                                                                                        )
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                                _c(
                                                                                  "v-list-item-content",
                                                                                  [
                                                                                    _c(
                                                                                      "v-list-item-title",
                                                                                      {
                                                                                        domProps: {
                                                                                          textContent: _vm._s(
                                                                                            subitem.text
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    true
                                                                  )
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        subitem.text
                                                                      )
                                                                    )
                                                                  ])
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        )
                                                      ],
                                                      2
                                                    )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [_c("span", [_vm._v(_vm._s(item.text))])]
                                  )
                                ]
                              })
                            ],
                            2
                          ),
                          _c("v-list-item-subtitle", { staticClass: "mt-5" }),
                          !_vm.isGenericView
                            ? _c("v-divider", { staticClass: "my-3" })
                            : _vm._e(),
                          !_vm.isGenericView
                            ? _c(
                                "v-tooltip",
                                {
                                  staticClass: "left-drawer-sign-out",
                                  attrs: {
                                    "open-delay": "200",
                                    right: "",
                                    disabled: !_vm.isDockedCompact
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-list-item",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    dense: "",
                                                    to: "/signOut"
                                                  }
                                                },
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-list-item-icon",
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass:
                                                          "list-item-icon",
                                                        attrs: {
                                                          color:
                                                            "info lighten-1"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " fal fa-sign-out-alt "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item-content",
                                                  [
                                                    _c("v-list-item-title", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("signOutLabel")
                                                        )
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    641586960
                                  )
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("signOutLabel")))
                                  ])
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }