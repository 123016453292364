import { cmnLocal, cmnSession } from '@/common/planbook-storage';
import { nanoid } from 'nanoid';
declare const Cookies: any;
export default class ClientUtils {
  public static getClientViewId() {
    const clientViewId = cmnSession.get('clientViewId') as string || nanoid();
    cmnSession.set('clientViewId', clientViewId);
    const clientViewIds = ClientUtils.getClientViewIds();
    if (!clientViewIds.includes(clientViewId)) {
      clientViewIds.push(clientViewId);
    }
    cmnLocal.set('clientViewIds', clientViewIds);
    return clientViewId;
  }

  public static removeClientViewId() {
    const clientViewId = cmnSession.get('clientViewId') as string || '';
    let clientViewIds = ClientUtils.getClientViewIds();
    clientViewIds = clientViewIds.filter(v => clientViewId === v);
    cmnLocal.set('clientViewIds', clientViewIds);
    return clientViewId;
  }

  public static getClientViewIds(): string[] {
    return cmnLocal.get('clientViewIds') || [];
  }

  public static get(key: string) {
    const jsonString = Cookies.get(key);
    if (jsonString) {
      return JSON.parse(jsonString);
    }
    return null;
  }
}
