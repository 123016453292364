
import { accountTypes } from '@/constants';
import FileServices from '@/services/file-services';
import CommonUtils from '@/utils/common-utils';
import ld from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class TeacherTagsInput extends Vue {
  @Prop({
    required: false,
    type: Array,
    default: () => {
      return [];
    }
  })
  value!: Array<any>;

  @Prop({
    required: false,
    type: Boolean,
    default: () => {
      return true;
    }
  })
  dynamicAccountType!: boolean;

  selectingFile = false;
  tagsInputText = '';

  localRules = [
    (v: any) => v.length > 0 || this.$t('requiredLabel')
  ];

  $refs!: {
    fileInput: HTMLInputElement;
    tagsInput: Vue;
  };

  get localValue(): Array<any> {
    return this.value;
  }

  set localValue(v: Array<any>) {
    this.$emit('input', v);
  }

  get localEmails() {
    return this.localValue.map(v => v.email);
  }

  set localEmails(emails) {
    if (CommonUtils.isNotEmpty(emails)) {
      const existingEmails = this.localValue.map((v) => { return v.email });
      this.localValue = this.localValue.concat(emails.filter((e) => !existingEmails.includes(e)).map(email => {
        return {
          email,
          userType: 'T'
        };
      }));
    } else {
      this.localValue = [];
    }
  }

  get accountTypes() {
    return accountTypes.filter((t: any) => {
      return t.value !== 'D';
    });
  }

  get localSelected() {
    return this.value.map(v => {
      return v.value;
    });
  }

  onFileChanged(e: Event) {
    const fileInput: any = e.target;
    FileServices.readTextFile(fileInput.files[0]).then(data => {
      if (CommonUtils.hasText(data)) {
        const emails = CommonUtils.split(data, [';', ',', /\s+/]);
        this.localEmails = CommonUtils.extractValidEmails(emails);
      }
      fileInput.value = null;
    });
  }

  uploadFile() {
    (window as any).tagsInput = this.$refs.tagsInput;
    this.selectingFile = true;
    window.addEventListener(
      'focus',
      () => {
        this.selectingFile = false;
      },
      { once: true }
    );
    this.$refs.fileInput.click();
  }

  getType(email: any) {
    const item = this.localValue.find(v => v.email === email);
    return item ? item.userType : 'T';
  }

  getSelectedClass(email: any, accountType: string) {
    if (this.isSelected(email, accountType)) {
      return 'primary--text v-list-item--active v-list-item--link theme--light v-list-item--highlighted';
    }
    return '';
  }

  isSelected(email: any, accountType: string) {
    return this.getType(email) === accountType;
  }

  selectType(email: any, accountType: string) {
    const newValue = ld.cloneDeep(this.localValue);
    newValue.some((v: any) => {
      const found = v.email === email;
      if (found) v.userType = accountType;
      return found;
    });
    this.localValue = newValue;
  }

  remove(email: any) {
    this.localValue = this.localValue.filter(v => {
      return v.email !== email;
    });
  }

  onPaste() {
    navigator.clipboard
      .readText()
      .then(text => {
        const emails = CommonUtils.split(text, [';', ',', /\s+/]);
        this.localEmails = CommonUtils.extractValidEmails(emails);
      })
      .finally(() => {
        this.tagsInputText = '';
      });
  }

  onDragOver(e: DragEvent) {
    this.$refs.tagsInput.$el.querySelector('input')?.focus();
    e.preventDefault();
  }

  onDrop(e: DragEvent) {
    e.preventDefault();
    const items = e.dataTransfer?.files;
    const promises: Array<Promise<string>> = [];
    if (items) {
      for (let i = 0; i < items.length; i++) {
        promises.push(FileServices.readTextFile(items[i]));
      }
    }
    Promise.all(promises).then(result => {
      let allEmails: Array<any> = [];
      result.forEach(text => {
        const emails = CommonUtils.split(text, [';', ',', /\s+/]);
        allEmails = allEmails.concat(CommonUtils.extractValidEmails(emails));
      });
      this.localEmails = allEmails;
    });
  }

  mounted() {
    const element = this.$refs.tagsInput.$el as HTMLElement;
    const tagsInput = this.$refs.tagsInput.$el.querySelector(
      'input'
    ) as HTMLInputElement;
    if (!tagsInput.onpaste) {
      tagsInput.onpaste = this.onPaste;
    }

    if (!element.ondragover) {
      element.ondragover = this.onDragOver;
    }

    if (!element.ondrop) {
      element.ondrop = this.onDrop;
    }
  }
}
