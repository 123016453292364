
import CommonUtils from '@/utils/common-utils';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Confirm from '@/components/core/Confirm.vue';

const settings = namespace('settings');
const index = namespace('index');

@Component
export default class Banner extends Vue {
  @settings.Getter
  infoMsg!: string;

  @settings.Getter
  alertMsg!: string;

  @settings.Getter
  errorMessages!: Array<string>;

  @settings.Getter
  infoMessages!: Array<string>;

  @settings.Action
  updateBannerInfo!: (params: any) => Promise<any>;

  @settings.Mutation
  resetBrowserDimensions!: () => void;

  @index.Mutation
  setHasBanner!: (params: boolean) => void;

  @settings.Mutation
  setAdditionalInfoMsgs!: (params: Array<string>) => void;

  showInfoMsg = false;
  showAlertMsg = false;

  $refs!: {
    confirm: Confirm,
  }

  @Watch('showInfoMsg')
  @Watch('showAlertMsg')
  updateHasBanenr() {
    this.setHasBanner(this.showInfoMsg || this.showAlertMsg);
  }

  get hasInfoMessages() {
    return CommonUtils.isNotEmpty(this.localInfoMessages);
  }

  get hasErrorMessages() {
    return CommonUtils.isNotEmpty(this.localErrorMessages);
  }

  get localInfoMessages() {
    const messages = [];
    if (CommonUtils.hasText(this.localInfoMsg)) {
      messages.push(this.localInfoMsg);
    }
    return messages.concat(this.infoMessages);
  }

  get localErrorMessages() {
    const messages = [];
    if (CommonUtils.hasText(this.localAlertMsg)) {
      messages.push(this.localAlertMsg);
    }
    return messages.concat(this.errorMessages);
  }

  get localInfoMsg() {
    return this.infoMsg || '';
  }

  get localAlertMsg() {
    return this.alertMsg || '';
  }

  get leftPaddingWidth() {
    if (this.$vuetify.breakpoint.smAndDown) {
      return 0;
    }
    return 81;
  }

  async closeInfoMsg(params: any) {
    if (['2', '3'].includes(params?.closeMode)) {
      this.setAdditionalInfoMsgs([]);
    }
    return this.updateBannerInfo(params).then(() => {
      this.showInfoMsg = false;
      this.$nextTick(() => {
        this.resetBrowserDimensions();
        new Promise(resolve => setTimeout(resolve, 10)).then(() => {
          this.$eventBus.$emit('resize');
        });
      })
    })
  }

  closeAlertMsg() {
    this.showAlertMsg = false;
    this.$nextTick(() => {
      this.resetBrowserDimensions();
      new Promise(resolve => setTimeout(resolve, 10)).then(() => {
        this.$eventBus.$emit('resize');
      });
    })
  }

  onMsgClick(event: Event) {
    const element = event.target as HTMLElement;
    if (element.classList.contains('messageButton')) {
      const messageBox = element.closest('.messageBox');
      const title = messageBox?.querySelector('.messageTitle')?.textContent;
      const text = `<div class="banner-message-box-content">${messageBox?.querySelector('.messageContent')?.innerHTML || ''}</div>`;
      this.$refs.confirm.alert({ title, text, option1ButtonAlternativeText: '' });
    }
  }

  created() {
    this.showInfoMsg = this.hasInfoMessages;
    this.showAlertMsg = this.hasErrorMessages;
  }
}
