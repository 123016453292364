import { Mutation, Module, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true })
export default class Drawer extends VuexModule {
    drawerLeft = false;
    drawerLeftStateless = false;
    drawerRight = false;
    drawerRightStateless = false;
    drawerBottom = false;
    drawerRightMode = '';
    remindersMobileDrawer = false;
    remindersMobileClickCounter = 0;
    drawerMode = 0;

    @Mutation
    setDrawerLeft(v: boolean) {
      this.drawerLeft = v;
    }

    @Mutation
    setDrawerRight(v: boolean) {
      this.drawerRight = v;
    }

    @Mutation
    setDrawerLeftStateless(v: boolean) {
      this.drawerLeftStateless = v;
    }

    @Mutation
    setDrawerRightStateless(v: boolean) {
      this.drawerRightStateless = v;
    }

    @Mutation
    setDrawerBottom(v: boolean) {
      this.drawerBottom = v;
    }

    @Mutation
    setDrawerRightMode(v: string) {
      this.drawerRightMode = v;
    }

    @Mutation
    setRemindersMobileDrawer(v: boolean) {
      this.remindersMobileDrawer = v;
    }

    @Mutation
    setRemindersMobileClickCounter(v: number) {
      this.remindersMobileClickCounter = v;
    }

    @Mutation
    setDrawerMode(v: number) {
      this.drawerMode = v;
    }
}
