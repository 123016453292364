import Snotify, { SnotifyPosition } from 'vue-snotify';
import 'vue-snotify/styles/material.css';
import Vue from 'vue';

Vue.use(Snotify, {
  toast: {
    position: SnotifyPosition.rightBottom,
    timeout: 2000,
    showProgressBar: false
  }
});
