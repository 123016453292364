import axios from '@/axios';
import { UserSession } from '@/common/user-session';
import CommonUtils from '@/utils/common-utils';
import qs from 'qs';
import i18n from '@/plugins/i18n';
import AuthServices from './auth-services';

export default class UserServices {
  public static getSettings(param?: any, reloadSchools?:any): Promise<any> {
    if (param) {
      if (CommonUtils.hasText(param.accessToken)) {
        return axios.post('services/security/login', qs.stringify({ idToken: param.accessToken }));
      } else if (param.teacherId > 0 && CommonUtils.hasText(param.key)) {
        return axios.post('services/security/login', qs.stringify({ teacherId: param.teacherId, password: param.key }), { withCredentials: false });
      } else if (UserSession.hasSession()) {
        const currentUser = UserSession.getCurrentUser();
        if (CommonUtils.hasText(currentUser?.key)) {
          return axios.post('services/security/login', qs.stringify({ teacherId: currentUser?.teacherId, password: currentUser?.key }), { withCredentials: false });
        } else {
          return axios.post('services/security/login', qs.stringify({ idToken: currentUser?.accessToken }));
        }
      } else {
        return Promise.resolve({ data: { error: 'true' } });
      }
    } else {
      return axios.post('getSettings', qs.stringify(reloadSchools), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: UserSession.hasSession() ? `Bearer ${UserSession.getAccessToken()}` : undefined
        }
      });
    }
  }

  public static verifyInviteToken(params: any) {
    return axios.post('services/welcome/verifyInviteToken', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static async registerStudent(params: any) {
    const studentEmailOrCode = params.email;
    return this.handleResult(await axios.post('services/welcome/registerStudent', qs.stringify({ studentEmailOrCode, ...params }, { arrayFormat: 'brackets' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }));
  }

  public static verifyStudentJoinCode(params: any) {
    return axios.get('services/welcome/verifyStudentJoinCode', { params });
  }

  public static signOut(params: any) {
    return axios.post('signout', qs.stringify(params || {}), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static async login(params: any) {
    const resp = await this.handleResult(await axios.post('services/security/login', qs.stringify(params)));
    if (resp.data.userType === 'S') {
      return Promise.resolve(resp);
    } else {
      return this.getSettings();
    }
  }

  public static async register(params: any) {
    const emailAddress = params.email;
    const userPassword = params.password;
    delete params.email;
    delete params.password;
    await this.handleResult(await axios.post('services/security/register', qs.stringify({ emailAddress, userPassword, ...params }), { withCredentials: false }));
    return this.getSettings();
  }

  public static async signOutAllOtherDevices() {
    return axios.get('services/planbook/teacher/signout/all');
  }

  public static async clearSessionCache() {
    return axios.get('services/planbook/teacher/flushSessionCache');
  }

  public static async resetPassword(params: any, update?: boolean) {
    params = params || {};
    params.baseUrl = CommonUtils.getAppBaseUrl();
    if (update) {
      return axios.post('services/user/resetPassword', qs.stringify(params), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
    } else {
      return axios.get('services/user/resetPassword', { params });
    }
  }

  public static async connectLoginTeacher(params: any) {
    const emailAddress = params.email;
    const userPassword = params.password;
    delete params.email;
    delete params.password;
    const resp = await this.handleResult(await axios.post('services/welcome/connectLoginTeacher', qs.stringify({ emailAddress, userPassword, ...params }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }));
    if (resp.data.userType === 'S') {
      return Promise.resolve(resp);
    } else {
      return this.getSettings();
    }
  }

  private static async handleResult(resp: any) {
    const data = resp ? resp.data : { data: { systemDown: 'true' } };
    if (data.status === -1 && CommonUtils.isNotEmpty(data.messages)) {
      throw new Error(data.messages[0] as string);
    } else if (data.existingEmail === 'true') {
      throw new Error(i18n.t('emailIsAlreadyRegisteredMsg') as string);
    } else if (data.invalidLogin === 'true') {
      throw new Error(i18n.t('notification05') as string);
    } else if (data.systemDown === 'true') {
      throw new Error(i18n.t('notification06') as string);
    } else if (data.error === 'true') {
      throw new Error(i18n.t('notification04') as string);
    } else if (data.noUserName) {
      throw new Error(i18n.t('requiredMsg', { s: i18n.t('emailOrUserIdLabel') }) as string);
    } else if (data.invalidPassword) {
      throw new Error(i18n.t('invalidStudentCredentials') as string);
    } else if (data.matchedStudents > 0) {
      throw new Error(i18n.t('multipleStudentProfiles') as string);
    } else if (data.profileExists) {
      throw new Error(i18n.t('duplicateStudentProfile') as string);
    } else if (data.notFound) {
      throw new Error(i18n.t('studentProfileNotFound') as string);
    } else {
      if (CommonUtils.hasText(data.idToken)) {
        UserSession.setCurrentUserFromToken(data.idToken);
        await AuthServices.initSession(data.idToken);
      }
      return Promise.resolve(resp);
    }
  }
}
