import AppError, { createErrOptions, SessionExpiredError } from '@/errors';
import ClassworkServices from '@/services/classwork-services';
import store from '@/store';
import CommonUtils from '@/utils/common-utils';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import ld from 'lodash';
import router from '@/router';

@Module({ namespaced: true })
export default class Classwork extends VuexModule {
    allAssessments: Array<any> = [];
    allAssignments: Array<any> = [];
    listLoading = false;
    editMode = false;
    selectedRows: Array<any> = [];
    searchText = '';
    showScoreWeightDialog = false;
    haveClasswork = false;

    filterSelections: any = {
      showClassworkTypes: [],
      selectedClass: {
        className: '',
        classID: 0
      },
      selectedUnit: {
        displayTitle: '',
        id: 0
      }
    };

    filterOptions: any = {
      showUnitFilter: false,
      unitList: [{
        displayTitle: '',
        id: 0
      }],
      classList: []
    };

    // classwork form fields
    selectedTab = 0;

    classworkId = 0;
    classworkType = 0;
    classworkTitle = '';
    classworkStartDate = '';
    classworkEndDate = '';
    classworkPoints = '';
    classworkClassId = 0;
    classworkUnitId = 0;
    classworkScoreWeightId = 0;

    classworkDescription = '';
    questions: any[] = [];
    standards: any[] = [];
    attachments: any[] = [];
    classworkTransferId = 0;

    origClasswork: any = {};

    get getAllAttachments() {
      return this.attachments;
    }

    @Mutation
    setHaveClasswork(v: boolean) {
      this.haveClasswork = v;
    }

    @Mutation
    setFilterSelections(v: any) {
      this.filterSelections = v;
    }

    @Mutation
    setFilterOptions(v: any) {
      this.filterOptions = v;
    }

    @Mutation
    setFilterSelectionsSelectedClass(v: any) {
      this.filterSelections.selectedClass = v;
    }

    @Mutation
    setFilterOptionsClassList(v: Array<any>) {
      this.filterOptions.classList = v;
    }

    @Mutation
    setFilterOptionsShowUnitFilter(v: boolean) {
      this.filterOptions.showUnitFilter = v;
    }

    @Mutation
    setFilterSelectionsSelectedUnit(v: any) {
      this.filterSelections.selectedUnit = v;
    }

    @Mutation
    setFilterOptionsUnitList(v: Array<any>) {
      this.filterOptions.unitList = v;
    }

    @Mutation
    setFilterSelectionsShowClassworkTypes(v: Array<any>) {
      this.filterSelections.showClassworkTypes = v;
    }

    @Mutation
    setShowScoreWeightDialog(v: boolean) {
      this.showScoreWeightDialog = v;
    }

    @Mutation
    setEditMode(v: boolean) {
      this.editMode = v;
    }

    @Mutation
    setSelectedRows(v: Array<any>) {
      this.selectedRows = v;
    }

    @Mutation
    setSearchText(v: string) {
      this.searchText = v;
    }

    @Mutation
    setListLoading(listLoading: boolean) {
      this.listLoading = listLoading;
    }

    @Mutation
    setAttachments(attachments: Array<any>) {
      this.attachments = attachments;
    }

    @Mutation
    setStandards(standards: Array<any>) {
      this.standards = standards;
    }

    @Mutation
    setAssignments(data: Array<any>) {
      this.allAssignments = data;

      this.allAssignments.map((a: any) => {
        a.isAssignment = true;
        a.classworkStart = a.assignmentStart;
        a.classworkEnd = a.assignmentEnd;
        a.classworkTitle = a.assignmentTitle;
        a.classworkId = a.assignmentId;
        a.classworkDesc = a.assignmentDesc;

        delete a.assignmentStart;
        delete a.assignmentEnd;
        delete a.assignmentTitle;
        delete a.assignmentId;
        delete a.assignmentDesc;

        if (a.source === 'gc') {
          a.classworkId = a.alternateLink + '-' + a.subjectId;
        }
      })
    }

    @Mutation
    setAssessments(data: Array<any>) {
      this.allAssessments = data;

      this.allAssessments.map((a: any) => {
        a.isAssignment = false;
        a.classworkStart = a.assessmentStart;
        a.classworkEnd = a.assessmentEnd;
        a.classworkTitle = a.assessmentTitle;
        a.classworkId = a.assessmentId;
        a.classworkDesc = a.assessmentDesc;

        delete a.assessmentStart;
        delete a.assessmentEnd;
        delete a.assessmentTitle;
        delete a.assessmentId;
        delete a.assessmentDesc;

        if (a.source === 'gc') {
          a.classworkId = a.alternateLink + '-' + a.subjectId;
        }
      })
    }

    @Mutation
    setCleanState() {
      const classwork: any = {};
      classwork.classworkId = this.classworkId;
      classwork.classworkType = this.classworkType;
      classwork.classworkTitle = this.classworkTitle;
      classwork.classworkStartDate = this.classworkStartDate;
      classwork.classworkEndDate = this.classworkEndDate;
      classwork.classworkPoints = this.classworkPoints;
      classwork.classworkClassId = this.classworkClassId;
      classwork.classworkUnitId = this.classworkUnitId;
      classwork.classworkScoreWeightId = this.classworkScoreWeightId;

      classwork.classworkDescription = this.classworkDescription;
      classwork.questions = this.questions;
      classwork.standards = ld.cloneDeep(this.standards);
      classwork.attachments = ld.cloneDeep(this.attachments);

      classwork.classworkTransferId = this.classworkTransferId;

      this.origClasswork = classwork;
    }

    get isDirty() {
      const classwork: any = {};
      classwork.classworkId = this.classworkId;
      classwork.classworkType = this.classworkType;
      classwork.classworkTitle = this.classworkTitle;
      classwork.classworkStartDate = this.classworkStartDate;
      classwork.classworkEndDate = this.classworkEndDate;
      classwork.classworkPoints = this.classworkPoints;
      classwork.classworkClassId = this.classworkClassId;
      classwork.classworkUnitId = this.classworkUnitId;
      classwork.classworkScoreWeightId = this.classworkScoreWeightId;

      classwork.classworkDescription = this.classworkDescription;
      classwork.questions = this.questions;
      classwork.standards = this.standards;
      classwork.attachments = this.attachments;

      classwork.classworkTransferId = this.classworkTransferId;
      return !ld.isEqual(classwork, this.origClasswork)
    }

    get getListLoading() {
      return this.listLoading;
    }

    get getAllClasswork() {
      const classwork = this.allAssessments.concat(this.allAssignments);
      classwork.sort(function compareFn(a, b) {
        return Classwork.compareClasswork(a, b);
      })
      return classwork;
    }

    // sort priority: end date -> class name -> unit -> title
    private static compareClasswork(a: any, b: any) {
      return (this.compareStrings(a.classworkEnd, b.classworkEnd) || this.compareStrings(a.className, b.className)) || this.compareStrings(a.unitNum, b.unitNum) || this.compareStrings(a.classworkTitle, b.classworkTitle);
    }

    private static compareStrings(a: any, b: any) {
      if (a === b) {
        return 0;
      } if (CommonUtils.hasNoText(a)) {
        return 1;
      } else if (CommonUtils.hasNoText(b)) {
        return -1;
      } else {
        return a < b ? -1 : 1;
      }
    }

    @Mutation
    clearClassworkForm() {
      this.selectedTab = 0;
      const classwork: any = {};
      classwork.classworkId = 0;
      classwork.classworkType = 0;
      classwork.classworkTitle = '';
      classwork.classworkStartDate = '';
      classwork.classworkEndDate = '';
      classwork.classworkPoints = '';
      classwork.classworkClassId = 0;
      classwork.classworkUnitId = 0;
      classwork.classworkScoreWeightId = 0;

      classwork.classworkDescription = '';
      classwork.questions = [];
      classwork.standards = [];
      classwork.attachments = [];

      classwork.classworkTransferId = 0;

      const state: any = this;
      for (const key in classwork) state[key] = classwork[key];
      this.origClasswork = classwork;
    }

    @Mutation
    setClassworkId(id: number) {
      this.classworkId = id;
    }

    @Mutation
    setSelectedTab(idx: number) {
      this.selectedTab = idx;
    }

    @Mutation
    setClassworkType(type: number) {
      this.classworkType = type;
    }

    @Mutation
    setClassworkTitle(title: string) {
      this.classworkTitle = title;
    }

    @Mutation
    setClassworkStartDate(date: string) {
      this.classworkStartDate = date;
    }

    @Mutation
    setClassworkEndDate(date: string) {
      this.classworkEndDate = date;
    }

    @Mutation
    setClassworkPoints(points: string) {
      this.classworkPoints = points;
    }

    @Mutation
    setClassworkClassId(id: number) {
      this.classworkClassId = id;
    }

    @Mutation
    setClassworkUnitId(id: number) {
      this.classworkUnitId = id;
    }

    @Mutation
    setClassworkScoreWeightId(id: number) {
      this.classworkScoreWeightId = id;
    }

    @Mutation
    setClassworkDescription(desc: string) {
      this.classworkDescription = desc;
    }

    @Mutation
    setClassworkQuestions(val: any) {
      this.questions = val;
    }

    @Mutation
    setClassworkTransferId(id: any) {
      this.classworkTransferId = id;
    }

    @Action({ rawError: true })
    async updateAssignment(param: any) {
      try {
        const resp = await ClassworkServices.updateAssignment(param);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            if (CommonUtils.getPage(router.currentRoute) === 'plans') {
              await this.context.dispatch('plans/reloadPlans', {}, { root: true });
            } else {
              this.context.commit('plans/resetPlans', {}, { root: true });
            }
            return Promise.resolve({ resp });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async updateAssessment(param: any) {
      try {
        const resp = await ClassworkServices.updateAssessment(param);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            if (CommonUtils.getPage(router.currentRoute) === 'plans') {
              await this.context.dispatch('plans/reloadPlans', {}, { root: true });
            } else {
              this.context.commit('plans/resetPlans', {}, { root: true });
            }
            return Promise.resolve({ resp });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async getAssignment(param: any) {
      try {
        param.userMode = this.context.rootState.settings.userMode
        const resp = await ClassworkServices.getAssignment(param);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve({ resp });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async getAssessment(param: any) {
      try {
        param.userMode = this.context.rootState.settings.userMode
        const resp = await ClassworkServices.getAssessment(param);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve({ resp });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async getAssessments(param: any) {
      try {
        param.userMode = this.context.rootState.settings.userMode
        const resp = await ClassworkServices.getAssessments(param);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve({ resp });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async getAssignments(param: any) {
      try {
        param.userMode = this.context.rootState.settings.userMode
        const resp = await ClassworkServices.getAssignments(param);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve({ resp });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async loadClasswork() {
      try {
        if (this.haveClasswork) {
          return Promise.resolve({ data: {} });
        }
        const respAll = await Promise.all([
          ClassworkServices.getAssessments(),
          ClassworkServices.getAssignments()
        ]);

        if (respAll) {
          const dataAssess = respAll[0].data;
          const dataAssign = respAll[1].data;
          if (dataAssign.notLoggedIn === 'true' || dataAssign.notLoggedIn === true || dataAssess.notLoggedIn === 'true' || dataAssess.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (dataAssign.error === 'true' || dataAssign.error === true) {
            return Promise.reject(new AppError('contactSupport', { data: dataAssign }));
          } else if (dataAssess === 'true' || dataAssess === true) {
            return Promise.reject(new AppError('contactSupport', { data: dataAssess }));
          } else {
            this.context.commit('setAssignments', dataAssign.assignments);
            this.context.commit('setAssessments', dataAssess.assessments);
            this.context.commit('setHaveClasswork', true);
            return Promise.resolve({ respAll });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async addClasswork() {
      if (this.classworkType === 0) {
        const payload = Classwork.createUpdateAssignmentRequest('A');
        return this.context.dispatch('updateAssignment', payload);
      } else {
        const payload = Classwork.createUpdateAssessmentRequest('A');
        return this.context.dispatch('updateAssessment', payload);
      }
    }

    @Action({ rawError: true })
    async updateClasswork() {
      if (this.classworkType === 0) {
        const payload = Classwork.createUpdateAssignmentRequest('U');
        return this.context.dispatch('updateAssignment', payload);
      } else {
        const payload = Classwork.createUpdateAssessmentRequest('U');
        return this.context.dispatch('updateAssessment', payload);
      }
    }

    @Action({ rawError: true })
    async deleteClasswork(param: any) {
      if (param.isAssignment || param.classworkType === 0) {
        const payload = Classwork.createUpdateAssignmentRequest('D', param);
        return this.context.dispatch('updateAssignment', payload);
      } else {
        const payload = Classwork.createUpdateAssessmentRequest('D', param);
        return this.context.dispatch('updateAssessment', payload);
      }
    }

    @Action({ rawError: true })
    async deleteMultipleClasswork(param: any) {
      const payload = Classwork.createDeleteMultipleRequest(param);
      try {
        const resp = await ClassworkServices.deleteMultiple(payload);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            if (CommonUtils.getPage(router.currentRoute) === 'plans') {
              await this.context.dispatch('plans/reloadPlans', {}, { root: true });
            } else {
              this.context.commit('plans/resetPlans', {}, { root: true });
            }
            return Promise.resolve({ resp });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    private static getCommonFields(requestType: string) {
      const request = { updateType: 'A' };
      if (requestType === 'D') {
        ld.merge(request, {
          action: 'D'
        });
      } else if (requestType === 'U') {
        ld.merge(request, {
          action: 'C'
        });
      } else if (requestType === 'A') {
        ld.merge(request, {
          action: 'A'
        });
      }

      if (requestType !== 'D') {
        // add attachments
        const state = store.state.classwork;
        ld.merge(request, {
          attachmentNames: CommonUtils.getPropertyValues(state.attachments, 'filename'),
          attachmentPrivate: CommonUtils.getPropertyValues(state.attachments, 'privateFlag', CommonUtils.booleanToString),
          attachmentUrl: CommonUtils.getPropertyValues(state.attachments, 'url')
        });
        // add standards
        if (state.standards.length > 0) {
          ld.merge(request, {
            standardDBIds: CommonUtils.getPropertyValues(state.standards, 'dbId')
          });
        }
        // if transfering to google class
        if (state.classworkTransferId && state.classworkTransferId !== 0) {
          ld.merge(request, {
            transferToCourseId: state.classworkTransferId
          });
        }
      }
      return request;
    }

    private static createUpdateAssignmentRequest(requestType: string, param?: any) {
      const request = this.getCommonFields(requestType);
      const state = store.state.classwork;
      if (requestType !== 'D') {
        ld.merge(request, {
          unitId: state.classworkUnitId || 0,
          subjectId: state.classworkClassId,
          scoreWeightId: state.classworkScoreWeightId || 0,
          assignmentTitle: state.classworkTitle,
          assignmentDesc: state.classworkDescription,
          assignmentStart: state.classworkStartDate,
          assignmentEnd: state.classworkEndDate,
          assignmentPoints: (Number.parseInt(state.classworkPoints)) ? state.classworkPoints : '0'
        });
      }
      if (requestType === 'D') {
        ld.merge(request, {
          assignmentId: param.classworkId || param.assignmentId
        });
      } else if (requestType !== 'A') {
        ld.merge(request, {
          assignmentId: state.classworkId
        });
      }
      return request;
    }

    private static createUpdateAssessmentRequest(requestType: string, param?: any) {
      const request = this.getCommonFields(requestType);
      const state = store.state.classwork;
      if (requestType !== 'D') {
        ld.merge(request, {
          unitId: state.classworkUnitId || 0,
          subjectId: state.classworkClassId,
          scoreWeightId: state.classworkScoreWeightId || 0,
          assessmentTitle: state.classworkTitle,
          assessmentDesc: state.classworkDescription,
          assessmentStart: state.classworkStartDate,
          assessmentEnd: state.classworkEndDate,
          assessmentPoints: (Number.parseInt(state.classworkPoints)) ? state.classworkPoints : '0'
        });
        if (CommonUtils.isNotEmpty(state.questions)) {
          const optionsObject = {};
          Object.assign(optionsObject, state.questions);
          ld.merge(request, {
            options: JSON.stringify(optionsObject)
          })
        }
      }
      if (requestType === 'D') {
        ld.merge(request, {
          assessmentId: param.classworkId || param.assessmentId
        });
      } else if (requestType !== 'A') {
        ld.merge(request, {
          assessmentId: state.classworkId
        });
      }
      return request;
    }

    private static createDeleteMultipleRequest(param: Array<any>) {
      const request: any = {};
      request.assignmentId = param.filter((c: any) => c.isAssignment || c.classworkType === 0).map((c: any) => { return c.classworkId });
      request.assessmentId = param.filter((c: any) => !(c.isAssignment || c.classworkType === 0)).map((c: any) => { return c.classworkId });
      return request;
    }
}
