var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: { "offset-y": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              !_vm.slideItem
                ? _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          class: {
                            red: !_vm.isCurrentDateInSchoolYearRange,
                            "lighten-4":
                              !_vm.isCurrentDateInSchoolYearRange &&
                              !_vm.$vuetify.theme.dark
                          },
                          style: { height: _vm.outlined ? "40px" : "20px" },
                          attrs: {
                            elevation: "0",
                            color: "primary",
                            outlined: _vm.outlined,
                            text: !_vm.outlined
                          }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c(
                        "span",
                        {
                          staticClass: "switch-year__text d-flex",
                          class: { mobile: _vm.$vuetify.breakpoint.smAndDown }
                        },
                        [
                          _c("span", [_vm._v(_vm._s(_vm.currentYearText))]),
                          !_vm.outlined
                            ? _c(
                                "v-icon",
                                {
                                  staticClass: "ml-1 my-auto",
                                  attrs: { small: "" }
                                },
                                [_vm._v("fal fa-angle-down")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  )
                : _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          class: {
                            red: !_vm.isCurrentDateInSchoolYearRange,
                            "lighten-4":
                              !_vm.isCurrentDateInSchoolYearRange &&
                              !_vm.$vuetify.theme.dark
                          },
                          attrs: {
                            elevation: "0",
                            color: "primary",
                            outlined: "",
                            rounded: "",
                            small: ""
                          }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c(
                        "span",
                        {
                          staticClass: "switch-year__text d-flex",
                          class: { mobile: _vm.$vuetify.breakpoint.smAndDown }
                        },
                        [
                          _c("span", [_vm._v(_vm._s(_vm.currentYearText))]),
                          _c(
                            "v-icon",
                            {
                              staticClass: "ml-1 my-auto",
                              attrs: { small: "" }
                            },
                            [_vm._v("fal fa-angle-down")]
                          )
                        ],
                        1
                      )
                    ]
                  )
            ]
          }
        }
      ])
    },
    [
      _c(
        "perfect-scrollbar",
        { staticStyle: { "max-height": "calc(100vh - 120px)" } },
        [
          _c(
            "v-list",
            _vm._l(_vm.schoolYearItems, function(item, index) {
              return _c(
                "v-list-item",
                {
                  key: index,
                  class: {
                    "primary--text v-list-item--active v-list-item--link theme--light v-list-item--highlighted":
                      +item.value === _vm.currentYearId
                  },
                  on: {
                    click: function($event) {
                      return _vm.schoolYearChanged({
                        yearId: +item.value,
                        teacherId: +_vm.currentTeacherId
                      })
                    }
                  }
                },
                [
                  _c("v-list-item-title", [
                    _vm.hasText(item.type)
                      ? _c(
                          "span",
                          {
                            class: [
                              "year-item-type",
                              "year-item-type-" + item.type.toLowerCase()
                            ]
                          },
                          [_vm._v(_vm._s(item.type))]
                        )
                      : _vm._e(),
                    _c("span", [_vm._v(_vm._s(item.text))])
                  ])
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }