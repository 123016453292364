import i18n from '@/plugins/i18n';

export const emailFormat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const profileFormat = /^[a-zA-Z0-9\-_.~!]+$/;
export const urlFormat = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\\.-]+)+[\w\-\\._~:/?#[\]@!\\$&'\\(\\)\\*\\+,;=.]+$/
export const timeFormat12HrWithAMPM = /^((1[0-2]|0?[1-9]):([0-5][0-9])? ([AaPp][Mm]))$/;
export const timeFormat12Hr = /^((1[0-2]|0?[1-9]):([0-5][0-9]))/;
export const timeFormat24Hr = /^([01][0-9]|2[0-3]):([0-5][0-9])$/;
export const infiniteScrollDelay = 0;

export const grades = [
  { value: '-999', text: i18n.t('allLevelLabel') },
  { value: '-1', text: i18n.t('preKLabel') },
  { value: '0', text: i18n.t('kinderLabel') },
  { value: '1', text: i18n.t('grade1Label') },
  { value: '2', text: i18n.t('grade2Label') },
  { value: '3', text: i18n.t('grade3Label') },
  { value: '4', text: i18n.t('grade4Label') },
  { value: '5', text: i18n.t('grade5Label') },
  { value: '6', text: i18n.t('grade6Label') },
  { value: '7', text: i18n.t('grade7Label') },
  { value: '8', text: i18n.t('grade8Label') },
  { value: '9', text: i18n.t('grade9Label') },
  { value: '10', text: i18n.t('grade10Label') },
  { value: '11', text: i18n.t('grade11Label') },
  { value: '12', text: i18n.t('grade12Label') },
  { value: '80', text: i18n.t('otherLabel') },
  { value: '99', text: i18n.t('inactiveLabel') }
]

export const genders = [
  { value: 'M', text: i18n.t('maleLabel') },
  { value: 'F', text: i18n.t('femaleLabel') },
  { value: 'U', text: i18n.t('unspecifiedLabel') }
]

export const displayViewTypeMapping: any = {
  D: 'day',
  W: 'week',
  M: 'month',
  C: 'cycle',
  L: 'list'
}

export const timeTypes = [
  { title: i18n.t('minutesLabel'), display: 'M', value: 1, string: 'xMinutesBeforeLabel' },
  { title: i18n.t('hoursLabel'), display: 'H', value: 60, string: 'xHoursBeforeLabel' },
  { title: i18n.t('daysLabel'), display: 'D', value: 1440, string: 'xDaysBeforeLabel', type: 'Day' },
  { title: i18n.t('weeksLabel'), display: 'W', value: 10080, string: 'xWeeksBeforeLabel', type: 'Day' }
]

export const tableWidths = {
  photo: '60px',
  icon: '100px',
  date: '120px',
  fullDate: '170px',
  shortText: '120px',
  mediumText: '160px',
  longText: '240px',
  email: '240px',
  action2: '190px',
  action3: '275px'
}

export const planbookColors = ['#C7561E', '#B5515D', '#C244AB', '#603F99', '#536CA6', '#3640AD', '#3C995B', '#5CA632', '#7EC225', '#A7B828',
  '#CF9911', '#D47F1E', '#B56414', '#914D14', '#AB2671', '#9643A5', '#4585A3', '#737373', '#41A587', '#D1BC36',
  '#AD2D2D', '#D96666', '#E67399', '#B373B3', '#8C66D9', '#668CB3', '#668CD9', '#59BFB3', '#65AD89', '#4CB052',
  '#8CBF40', '#BFBF4D', '#E0C240', '#F2A640', '#E6804D', '#BE9494', '#A992A9', '#8997A5', '#94A2BE', '#85AAA5', '#A7A77D', '#C4A883',
  '#993300', '#333300', '#003300', '#003366', '#000080', '#333399', '#333333', '#800000', '#FF6600',
  '#808000', '#008000', '#008080', '#0000FF', '#666699', '#808080', '#FE090A', '#FE9C0A', '#9CCD0A', '#3A9C6C',
  '#33CCCC', '#3366FF', '#800080', '#999999', '#FF00FF', '#FFCC00', '#FFFF00', '#00FF00', '#00FFFF', '#00CCFF',
  '#993366', '#C0C0C0', '#FF99CC', '#FFCC99', '#FFFF99', '#CCFFCC', '#CCFFFF', '#99CCFF', '#CC99FF', '#FFFFFF', '#000000']

export const contrastModeColors: any = {
  primary: '#0053B8',
  secondary: '#333333',
  accent: '#0053B8',
  accent1: '#145D76',
  accent2: '#603f99',
  accent3: '#28623C',
  accent4: '#7F4A06',
  error: '#B5122A',
  warning: '#7F4A06',
  info: '#595959',
  success: '#28623C',
  fg: '#000000',
  bg: '#ffffff'
}

export const lightModeColors: any = {
  primary: '#0057c1',
  secondary: '#333333',
  accent: '#82b1ff',
  accent1: '#1d96c0',
  accent2: '#603f99',
  accent3: '#3e985c',
  accent4: '#f29a25',
  error: '#d81632',
  warning: '#f29a25',
  info: '#757575',
  success: '#3e985c',
  fg: '#000000',
  bg: '#ffffff'
}

export const sections = ['lesson', 'tab2', 'tab3', 'tab4', 'tab5', 'tab6', 'sharedStandards', 'myStandards', 'strategies', 'schoolStandards', 'attachments']

export const planbookFonts = [
  'none',
  'andale mono,times',
  'arial,helvetica,sans-serif',
  'arial black,avant garde',
  'book antiqua,palatino',
  'comic sans ms,sans-serif',
  'courier new,courier',
  'georgia,palatino',
  'helvetica, arial, sans-serif',
  'impact,chicago',
  'tahoma,arial,helvetica,sans-serif',
  'terminal,monaco',
  'times new roman,times',
  'trebuchet ms,geneva',
  'verdana,geneva'
]

export const collaborateSelectItems = [
  { text: i18n.t('collabNoneLabel'), value: 0 },
  { text: i18n.t('collabViewImportLabel'), value: 1 },
  { text: i18n.t('collabViewEditLabel'), value: 2 },
  { text: i18n.t('collabReplicaLabel'), value: 3 }
]

export const exportTypes = [
  { text: i18n.t('sendToPDFLabel'), value: 'pdf' },
  { text: i18n.t('sendToWordLabel'), value: 'word' },
  { text: i18n.t('sendToGoogleCalLabel'), value: 'calendar' },
  { text: i18n.t('sendToGoogleDocsLabel'), value: 'drive' }
]

export const printViews = [
  { text: i18n.t('dayLabel'), value: 'Day' },
  { text: i18n.t('weekLabel'), value: 'Week' },
  { text: i18n.t('classMonthLabel'), value: 'ClassMonth' },
  { text: i18n.t('eventsMonthLabel'), value: 'EventMonth' },
  { text: i18n.t('classListLabel'), value: 'Class' },
  { text: i18n.t('eventsListLabel'), value: 'Event' },
  { text: i18n.t('lessonBankListLabel'), value: 'LessonBank' },
  { text: i18n.t('notesEvent'), value: 'Notes' },
  { text: i18n.t('todoLabel'), value: 'ToDo' },
  { text: i18n.t('messagesLabel'), value: 'Comments' }
]

export const remindersEventsprintViews = [
  { text: i18n.t('eventsListLabel'), value: 'Event' },
  { text: i18n.t('eventsMonthLabel'), value: 'EventMonth' },
  { text: i18n.t('notesLabel'), value: 'Notes' },
  { text: i18n.t('todoLabel'), value: 'ToDo' }
]

export const printItems = [
  { value: 'printLessons', section: 'lesson' },
  { value: 'printHomework', section: 'tab2' },
  { value: 'printNotes', section: 'tab3' },
  { value: 'printTab4', section: 'tab4' },
  { value: 'printTab5', section: 'tab5' },
  { value: 'printTab6', section: 'tab6' },
  { value: 'printMyList', section: 'myStandards' },
  { value: 'printSchoolList', section: 'schoolStandards' },
  { value: 'printStrategies', section: 'strategies' },
  { value: 'printStanId', text: i18n.t('standardIdLabel') },
  { value: 'printStanText', text: i18n.t('standardDescLabel') },
  { value: 'printAttachments', text: i18n.t('attachmentsLabel') },
  { value: 'printAssignments', text: i18n.t('assignmentsLabel') },
  { value: 'printAssessments', text: i18n.t('assessmentsLabel') },
  { value: 'printTimes', text: i18n.t('timesLabel') },
  { value: 'printEvents', text: i18n.t('eventsLabel') },
  { value: 'printGoogleEvents', text: i18n.t('googleEventsLabel') },
  { value: 'printEmbeddedImages', text: i18n.t('embeddedImagesLabel') },
  { value: 'printStatus', text: i18n.t('tagLabel') },
  { value: 'printStatusLabel', text: i18n.t('tagLabelLabel') },
  { value: 'printUnitId', text: i18n.t('unitIDLabel') },
  { value: 'printUnitTitle', text: i18n.t('unitTitleLabel') },
  { value: 'printUnitDetails', text: i18n.t('unitDetailsLabel') },
  { value: 'printLinkedUnitId', text: i18n.t('linkedUnitIdLabel') },
  { value: 'printLinkedUnitTitle', text: i18n.t('linkedUnitTitleLabel') },
  { value: 'printLinkedUnitDetails', text: i18n.t('linkedUnitDetailsLabel') },
  { value: 'printLinkedLessonTitle', text: i18n.t('linkedLessonTitleLabel') },
  { value: 'printLinkedLessonDetails', text: i18n.t('linkedLessonDetailsLabel') }
]

export const printToDoItems = [
  { text: i18n.t('doneLabel'), value: '0', checkedName: 'printToDoDone', checkedValue: 'Y' },
  { text: i18n.t('notDoneLabel'), value: '1', checkedName: 'printToDoNotDone', checkedValue: 'Y' },
  { text: i18n.t('noneLabel'), value: '2', checkedName: 'printToDoLevel', checkedValue: '0' },
  { text: i18n.t('lowLabel'), value: '3', checkedName: 'printToDoLevel', checkedValue: '1' },
  { text: i18n.t('mediumLabel'), value: '4', checkedName: 'printToDoLevel', checkedValue: '2' },
  { text: i18n.t('highLabel'), value: '5', checkedName: 'printToDoLevel', checkedValue: '3' }
]

export const viewItems = [
  'lesson',
  'tab2',
  'tab3',
  'tab4',
  'tab5',
  'tab6',
  'standardsId',
  'standardsDesc',
  'templates',
  'times',
  'events',
  'action',
  'attachments',
  'assignments',
  'assessments',
  'lessonTitle',
  'unitId',
  'strategies',
  'unitTitle',
  'unitDetails',
  'linkedLessonDetails',
  'sectionTags',
  'linkedUnitTitle',
  'linkedUnitId',
  'linkedUnitDetails',
  'linkedLessonTitle',
  'schoolStatus',
  'googleEvents'
]

export const selectViewItems = [
  { text: i18n.t('unitIDLabel'), value: 'unitId' },
  { text: i18n.t('unitTitleLabel'), value: 'unitTitle' },
  { text: i18n.t('unitDetailsLabel'), value: 'unitDetails' },
  { text: i18n.t('lessonTitleLabel'), value: 'lessonTitle' },
  { text: i18n.t('lessonLabel'), value: 'lesson' },
  { text: i18n.t('homeworkLabel'), value: 'tab2' },
  { text: i18n.t('notesLabel'), value: 'tab3' },
  { text: i18n.t('tag4Label'), value: 'tab4' },
  { text: i18n.t('tag5Label'), value: 'tab5' },
  { text: i18n.t('tag6Label'), value: 'tab6' },
  { text: i18n.t('strategiesLabel'), value: 'strategies' },
  { text: i18n.t('standardIdLabel'), value: 'standardsId' },
  { text: i18n.t('standardDescLabel'), value: 'standardsDesc' }
]

export const studentViewItems = [
  { text: i18n.t('standardIdLabel'), value: 'studentViewStandardsId' },
  { text: i18n.t('standardDescLabel'), value: 'studentViewStandardsDesc' },
  { text: i18n.t('attachmentsLabel'), value: 'studentViewAttachments' }
]

export const otherViewItems = [
  { text: i18n.t('actionsLabel'), value: 'action' },
  { text: i18n.t('assessmentsLabel'), value: 'assessments' },
  { text: i18n.t('assignmentsLabel'), value: 'assignments' },
  { text: i18n.t('attachmentsLabel'), value: 'attachments' },
  { text: i18n.t('eventsLabel'), value: 'events' },
  { text: i18n.t('googleEventsLabel'), value: 'googleEvents' },
  { text: i18n.t('sectionTagsLabel'), value: 'sectionTags' },
  { text: i18n.t('templatesLabel'), value: 'templates' },
  { text: i18n.t('timesLabel'), value: 'times' }
]

export const selectLinkedViewItems = [
  { text: i18n.t('linkedUnitIdLabel'), value: 'linkedUnitId' },
  { text: i18n.t('linkedUnitTitleLabel'), value: 'linkedUnitTitle' },
  { text: i18n.t('linkedUnitDetailsLabel'), value: 'linkedUnitDetails' },
  { text: i18n.t('linkedLessonTitleLabel'), value: 'linkedLessonTitle' },
  { text: i18n.t('linkedLessonDetailsLabel'), value: 'linkedLessonDetails' }
]

export const permissionsMapping: any = {
  lessonTitle: 'permissions11',
  lesson: 'permissions1',
  tab2: 'permissions2',
  tab3: 'permissions3',
  tab4: 'permissions4',
  tab5: 'permissions5',
  tab6: 'permissions6',
  strategies: 'permissions7',
  myStandards: 'permissions8',
  sharedStandards: 'permissions9',
  schoolStandards: 'permissions10',
  attachments: 'permissions12'
}

export const permissionsMappingMirrored: any = {
  permissions11: 'lessonTitle',
  permissions1: 'lessonText',
  permissions2: 'tab2Text',
  permissions3: 'tab3Text',
  permissions4: 'tab4Text',
  permissions5: 'tab5Text',
  permissions6: 'tab6Text',
  permissions7: 'strategies',
  permissions8: 'myStandards',
  permissions9: 'sharedStandards',
  permissions10: 'schoolStandards',
  permissions12: 'attachments'
}

export const occurrences = [
  { text: i18n.t('repeatDailyLabel'), value: 'daily' },
  { text: i18n.t('repeatWeeklyLabel'), value: 'weekly' },
  { text: i18n.t('repeatBiWeeklyLabel'), value: 'biweekly' }
]

export const fonts = [
  { value: '1', text: 'Andale Mono' },
  { value: '2', text: 'Arial' },
  { value: '3', text: 'Arial Black' },
  { value: '4', text: 'Book Antiqua' },
  { value: '5', text: 'Comic Sans MS' },
  { value: '6', text: 'Courier New' },
  { value: '7', text: 'Georgia' },
  { value: '8', text: 'Helvetica' },
  { value: '9', text: 'Impact' },
  { value: '10', text: 'Tahoma' },
  { value: '11', text: 'Terminal' },
  { value: '12', text: 'Times New Roman' },
  { value: '13', text: 'Trebuchet MS' },
  { value: '14', text: 'Verdana' }
]

export const stickerBackgroundTypes = [
  { value: '1', text: i18n.t('noneLabel') },
  { value: '2', text: i18n.t('straightEdgeLabel') },
  { value: '3', text: i18n.t('wavyLabel') },
  { value: '4', text: i18n.t('jaggedLabel') },
  { value: '5', text: i18n.t('imageShapeLabel') }
]

export const stickerSizes = [
  { value: '20', text: '20pt' },
  { value: '25', text: '25pt' },
  { value: '30', text: '30pt' },
  { value: '35', text: '35pt' },
  { value: '40', text: '40pt' },
  { value: '45', text: '45pt' },
  { value: '50', text: '50pt' },
  { value: '55', text: '55pt' },
  { value: '60', text: '60pt' },
  { value: '65', text: '65pt' },
  { value: '70', text: '70pt' },
  { value: '75', text: '75pt' },
  { value: '80', text: '80pt' }
]

export const fontsText = [
  'Andale Mono',
  'Arial',
  'Arial Black',
  'Book Antiqua',
  'Comic Sans MS',
  'Courier New',
  'Georgia',
  'Helvetica',
  'Impact',
  'Tahoma',
  'Terminal',
  'Times New Roman',
  'Trebuchet MS',
  'Verdana'
]

export const fontSizes = [
  { value: '8', text: '8pt' },
  { value: '9', text: '9pt' },
  { value: '10', text: '10pt' },
  { value: '11', text: '11pt' },
  { value: '12', text: '12pt' },
  { value: '13', text: '13pt' },
  { value: '14', text: '14pt' }
]

export const fontSizesText = [
  '8pt',
  '9pt',
  '10pt',
  '11pt',
  '12pt',
  '13pt',
  '14pt'
]

export const accountTypes = [
  { text: i18n.t('teacherLabel'), value: 'T' },
  { text: i18n.t('adminLabel'), value: 'A' },
  { text: i18n.t('dualLabel'), value: 'B' },
  { text: i18n.t('districtAdminLabel'), value: 'D' }
]

export const E_NOTIFICATIONS: Array<string> = [
  'sendEmail',
  'sendEmail2',
  'sendEmail3',
  'sendEmail4',
  'sendEmail5',
  'sendEmail6',
  'sendEmail7',
  'sendEmail8'
]

export const E_NOTIFICATIONS_ADMIN: Array<string> = [
  'sendEmail',
  'sendEmail2',
  'sendEmail3',
  'sendEmail4',
  'sendEmail5',
  'sendEmail6',
  'sendEmail7'
]

export const EVENT_NOTIFICATIONS: Array<string> = [
  'allowPush',
  'eventEmail'
]

export const SUBSCRIPTIONS = [
  i18n.t('oneYearSubscriptionLabel'),
  i18n.t('twoYearSubscriptionLabel'),
  i18n.t('threeYearSubscriptionLabel')
]

export const SUBSCRIPTION_AMOUNTS = [
  15.00,
  27.00,
  36.00
]

export const CHECK_SUBSCRIPTION: Array<string> = [
  'updateLesson',
  'bumpLesson',
  'extendStandards',
  'extendLesson',
  'deleteLesson',
  'removeClassDay',
  'moveLesson',
  'undoDeleteLesson',
  'services/planbook/lesson/delete',
  'services/planbook/lesson/reorderLessons',
  'services/planbook/lesson/swapLesson',
  'services/planbook/lesson/doLink',
  'services/planbook/lesson/doUnlink',
  'services/planbook/template/save',
  'services/planbook/template/delete',
  'services/planbook/template/deleteBatch',
  'services/planbook/template/apply',
  'services/planbook/template/removeOrRestore',
  'addClass',
  'updateClass/v10',
  'deleteClass',
  'addEvent',
  'updateEvent/v5',
  'deleteEvent',
  'updateToDo',
  'deleteYear',
  'updateAdminYear',
  'addTerm',
  'services/planbook/schoolyears:batchDelete',
  'services/planbook/newNote/add',
  'services/planbook/newNote/deleteNote',
  'updateUnit'
]

export const settingsItems = [
  {
    action: 'acccount',
    icon: 'fal fa-user',
    text: i18n.t('accountLabel'),
    color: 'primary lighten-3',
    to: '/account',
    userModes: ['A', 'T']
  },
  {
    action: 'changeHistory',
    icon: 'fal fa-list-timeline',
    text: i18n.t('changeHistoryLabel'),
    color: 'primary lighten-3',
    to: '/change-history',
    userModes: ['T']
  },
  {
    action: 'displaySettings',
    icon: 'fal fa-cog',
    text: i18n.t('displaySettingsLabel'),
    color: 'primary lighten-3',
    to: '/display-settings',
    userModes: ['T', 'A']
  },
  {
    icon: 'fal fa-link',
    text: i18n.t('integrationsLabel'),
    color: 'primary lighten-3',
    to: '/integrations',
    userModes: ['A']
  },
  {
    icon: 'fal fa-window-maximize',
    text: i18n.t('lessonLayoutsLabel'),
    color: 'primary lighten-3',
    to: '/lesson-layouts',
    userModes: ['A', 'T']
  },
  {
    icon: 'fal fa-list-ul',
    text: i18n.t('myListLabel'),
    color: 'primary lighten-3',
    to: '/my-list',
    userModes: ['T']
  },
  {
    icon: 'fal fa-list-ul',
    text: i18n.t('schoolListLabel'),
    color: 'primary lighten-3',
    to: '/school-list',
    userModes: ['A']
  },
  {
    icon: 'fal fa-school',
    text: i18n.t('schoolProfileLabel'),
    color: 'primary lighten-3',
    to: '/school-profile',
    userModes: ['A']
  },
  {
    icon: 'fal fa-chess-rook-alt',
    text: i18n.t('myStrategiesLabel'),
    color: 'primary lighten-3',
    to: '/strategies',
    userModes: ['T']
  },
  {
    icon: 'fal fa-chess-rook-alt',
    text: i18n.t('strategiesLabel'),
    color: 'primary lighten-3',
    to: '/strategies',
    userModes: ['A']
  },
  {
    icon: 'fal fa-share-alt',
    text: i18n.t('sharingOptionsLabel'),
    color: 'primary lighten-3',
    to: '/sharing-options',
    userModes: ['A', 'T']
  }
]

export const helpItems = [
  // Will add a function to toggle this for the success team.
  // {
  //   icon: 'fal fa-comments',
  //   text: i18n.t('chatUsLabel'),
  //   color: 'primary lighten-3',
  //   to: '/chat-us',
  //   userModes: ['A', 'T', 'S']
  // },
  {
    icon: 'fal fa-at',
    text: i18n.t('contactUsLabel'),
    color: 'primary lighten-3',
    to: '/contact-us',
    id: 'contact-us',
    userModes: ['A', 'T', 'S']
  },
  {
    icon: 'fal fa-lightbulb-on',
    text: i18n.t('giveFeedbackLabel'),
    color: 'primary lighten-3',
    to: '',
    href: 'https://planbook.uservoice.com/forums/146191-general',
    target: '_blank',
    userModes: ['A', 'T', 'S']
  },
  {
    icon: 'fal fa-info-circle',
    text: i18n.t('knowledgeBaseLabel'),
    color: 'primary lighten-3',
    to: '',
    href: 'https://planbook.uservoice.com/',
    target: '_blank',
    userModes: ['A', 'T', 'S']
  },
  {
    icon: 'fal fa-rocket',
    text: i18n.t('overviewLabel'),
    color: 'primary lighten-3',
    to: '/overview',
    userModes: ['A', 'T']
  },
  {
    icon: 'fab fa-youtube-square',
    text: i18n.t('tutorialsLabel'),
    color: 'primary lighten-3',
    to: '',
    href: 'https://planbook.uservoice.com/knowledgebase/topics/146887-video-tutorials',
    target: '_blank',
    userModes: ['A', 'T', 'S']
  }
]

export const addItems = [
  {
    action: 'attendance',
    icon: 'fal fa-child',
    text: i18n.t('attendanceLabel'),
    color: 'accent3 lighten-3'
  },
  {
    action: 'assessment',
    icon: 'fal fa-clipboard-list-check',
    text: i18n.t('classworkLabel'),
    color: 'accent3 lighten-3'
  },
  {
    action: 'event',
    icon: 'fal fa-calendar-alt',
    text: i18n.t('eventLabel'),
    color: 'accent3 lighten-3'
  },
  {
    action: 'extraLesson',
    icon: 'fal fa-window-maximize',
    text: i18n.t('extraLessonLabel'),
    color: 'accent3 lighten-3'
  },
  {
    action: 'noSchool',
    icon: 'fal fa-calendar-times',
    text: i18n.t('noSchoolDayLabel'),
    color: 'accent3 lighten-3'
  },
  {
    action: 'notes',
    icon: 'fal fa-sticky-note',
    text: i18n.t('notesLabel'),
    color: 'accent3 lighten-3'
  },
  {
    action: 'subNotes',
    icon: 'fal fa-memo-pad',
    text: i18n.t('substituteNotesLabel'),
    color: 'accent3 lighten-3'
  },
  {
    action: 'template',
    icon: 'fal fa-shapes',
    text: i18n.t('templateLabel'),
    color: 'accent3 lighten-3'
  },
  {
    action: 'toDo',
    icon: 'fal fa-clipboard',
    text: i18n.t('todoLabel'),
    color: 'accent3 lighten-3'
  },
  {
    action: 'unit',
    icon: 'fal fa-puzzle-piece',
    text: i18n.t('unitLabel'),
    color: 'accent3 lighten-3'
  },
  {
    action: 'announcements',
    icon: 'fal fa-bullhorn',
    text: i18n.t('viewAnnouncementsLabel'),
    color: 'accent3 lighten-3'
  }
]

export const gotoItems = [
  {
    icon: 'fal fa-memo-pad',
    text: i18n.t('plansLabel'),
    color: 'accent1',
    to: '/plans',
    userModes: ['A', 'T', 'S']
  },
  {
    icon: 'fal fa-child',
    text: i18n.t('attendanceLabel'),
    color: 'accent1',
    to: '/attendance',
    userModes: ['T']
  },
  {
    icon: 'fal fa-book-open-cover',
    text: i18n.t('classesLabel'),
    color: 'accent1',
    to: '/classes',
    userModes: ['T']
  },
  {
    icon: 'fal fa-clipboard-list-check',
    text: i18n.t('classworkLabel'),
    color: 'accent1',
    to: '/classwork',
    userModes: ['T']
  },
  {
    icon: 'fal fa-calendar-alt',
    text: i18n.t('eventsLabel'),
    color: 'accent1',
    to: '/events',
    userModes: ['A', 'T']
  },
  {
    icon: 'fal fa-poll-people',
    text: i18n.t('gradesLabel'),
    color: 'accent1',
    to: '/grades',
    userModes: ['A', 'T', 'S']
  },
  {
    icon: 'fal fa-cabinet-filing',
    text: i18n.t('lessonBanksLabel'),
    color: 'accent1',
    id: 'lesson-banks',
    userModes: ['A'],
    subitems: [
      {
        icon: 'fal fa-memo',
        text: i18n.t('lessonsLabel'),
        color: 'accent1',
        to: '/lesson-banks'
      },
      {
        icon: 'fal fa-puzzle-piece',
        text: i18n.t('unitsLabel'),
        color: 'accent1',
        to: '/units'
      }
    ]
  },
  {
    icon: 'fal fa-cabinet-filing',
    text: i18n.t('lessonBanksLabel'),
    color: 'accent1',
    to: '/lesson-banks',
    userModes: ['T']
  },
  {
    icon: 'fal fa-envelope',
    text: i18n.t('messagesLabel'),
    color: 'accent1',
    to: '/messages',
    userModes: ['A', 'T', 'S']
  },
  {
    icon: 'fal fa-note-sticky',
    text: i18n.t('notesTodosLabel'),
    color: 'accent1',
    to: '/notes-todo',
    userModes: ['T']
  },
  {
    icon: 'fal fa-square-poll-horizontal',
    text: i18n.t('reportingLabel'),
    color: 'accent1',
    to: '/reporting',
    userModes: ['T']
  },
  {
    icon: 'fal fa-square-poll-horizontal',
    text: i18n.t('reportingLabel'),
    color: 'accent1',
    to: '',
    id: 'reporting',
    userModes: ['A'],
    subfolder: true,
    subitems: [
      {
        icon: 'fal fa-memo-circle-check',
        text: i18n.t('plansLabel'),
        color: 'accent1',
        to: '/reporting-plans'
      },
      {
        icon: 'fal fa-list-check',
        text: i18n.t('standardsLabel'),
        color: 'accent1',
        to: '/reporting'
      }
    ]
  },
  {
    icon: 'fal fa-folders',
    text: i18n.t('resourcesLabel'),
    color: 'accent1',
    to: '/resources',
    userModes: ['T']
  },
  {
    icon: 'fal fa-school',
    text: i18n.t('schoolYearsLabel'),
    color: 'accent1',
    to: '/school-years',
    userModes: ['A', 'T']
  },
  {
    icon: 'fal fa-chair',
    text: i18n.t('seatingChartsLabel'),
    color: 'accent1',
    id: 'seating-charts',
    to: '',
    userModes: ['T'],
    subfolder: true,
    subitems: [
      {
        icon: 'fal fa-person-seat',
        text: i18n.t('seatAssignmentsLabel'),
        color: 'accent1',
        to: '/class-seating'
      },
      {
        icon: 'fal fa-chart-tree-map',
        text: i18n.t('seatLayoutsLabel'),
        color: 'accent1',
        to: '/room-layouts'
      }
    ]
  },
  {
    icon: 'fal fa-screen-users',
    text: i18n.t('studentsLabel'),
    color: 'accent1',
    id: 'manage-students',
    to: '',
    userModes: ['A', 'T'],
    subitems: [
      {
        icon: 'fal fa-users-gear',
        text: i18n.t('manageLabel'),
        color: 'accent1',
        to: '/students'
      },
      {
        icon: 'fal fa-chart-network',
        text: i18n.t('assignLabel'),
        color: 'accent1',
        to: '/assign-students'
      }]
  },
  {
    icon: 'fal fa-chalkboard-user',
    text: i18n.t('teachersLabel'),
    color: 'accent1',
    id: 'manage-teachers',
    to: '/teachers',
    userModes: ['A']
  },
  {
    icon: 'fal fa-shapes',
    text: i18n.t('templatesLabel'),
    color: 'accent1',
    to: '/templates',
    userModes: ['A', 'T']
  },
  {
    icon: 'fal fa-puzzle-piece',
    text: i18n.t('unitsLabel'),
    color: 'accent1',
    to: '/units',
    userModes: ['T']
  }
]

export const sectionTagLabels = {
  lesson: '',
  unit: 'U',
  linkedLesson: 'LL',
  linkedUnit: 'LU'
}

export const englishAlphabet = [...Array(26)].map((_, i) => String.fromCharCode(65 + i));

export const swatches = [
  ['#980000', '#ff0000', '#ff9900', '#ffff00', '#00ff00', '#00ffff', '#4a86e8', '#0000ff', '#9900ff', '#ff00ff', '#000000', '#cccccc'],
  ['#dd7e6b', '#ea9999', '#f9cb9c', '#ffe599', '#b6d7a8', '#a2c4c9', '#a4c2f4', '#9fc5e8', '#b4a7d6', '#d5a6bd', '#434343', '#d9d9d9'],
  ['#cc4125', '#e06666', '#f6b26b', '#ffd966', '#93c47d', '#76a5af', '#6d9eeb', '#6fa8dc', '#8e7cc3', '#c27ba0', '#666666', '#efefef'],
  ['#a61c00', '#cc0000', '#e69138', '#f1c232', '#6aa84f', '#45818e', '#3c78d8', '#3d85c6', '#674ea7', '#a64d79', '#999999', '#f3f3f3'],
  ['#85200c', '#990000', '#b45f06', '#bf9000', '#38761d', '#134f5c', '#1155cc', '#0b5394', '#351c75', '#741b47', '#b7b7b7', '#ffffff']
]

export const classEditMoreOptions = [
  {
    text: i18n.t('deleteLabel'),
    value: 'delete'
  },
  {
    text: i18n.t('exportLabel'),
    value: 'export'
  },
  {
    text: i18n.t('manageLessonsLabel'),
    value: 'manageLessons'
  },
  {
    text: i18n.t('unlinkLessonsLabel'),
    value: 'unlink'
  }
];

export const allowedGracePeriodDays = 7;

export const lessonActionsAdmin = [
  {
    id: 'edit',
    icon: 'fal fa-pencil',
    label: 'editLessonLabel'
  },
  {
    id: 'comment',
    icon: 'fal fa-comment',
    label: 'commentLabel'
  },
  {
    id: 'copy',
    icon: 'fal fa-copy',
    label: 'copyLabel',
    subAction: true
  },
  {
    id: 'paste',
    icon: 'fal fa-paste',
    label: 'pasteLabel',
    subAction: true
  },
  {
    id: 'delete',
    icon: 'fal fa-trash-alt',
    label: 'deleteLessonLabel'
  },
  {
    id: 'changeHistory',
    icon: 'fal fa-history',
    label: 'changeHistoryLabel'
  },
  {
    id: 'print',
    icon: 'fal fa-arrow-up-from-square',
    label: 'sendToLabel',
    subAction: true
  }
]

export const lessonActions = [
  {
    id: 'edit',
    icon: 'fal fa-pencil',
    label: 'editLessonLabel'
  },
  {
    id: 'editLink',
    icon: 'fal fa-pencil',
    label: 'editLinkedLessoLabel'
  },
  {
    id: 'lock',
    icon: 'fal fa-lock',
    label: 'lockLessonToDateLabel'
  },
  {
    id: 'move',
    icon: 'fal fa-arrows',
    label: 'moveLessonLabel'
  },
  {
    id: 'link',
    icon: 'fal fa-link',
    label: 'linkLessonLabel'
  },
  {
    id: 'unlink',
    icon: 'fal fa-unlink',
    label: 'unlinkLessonLabel'
  },
  {
    id: 'copy',
    icon: 'fal fa-copy',
    label: 'copyLabel',
    subAction: true
  },
  {
    id: 'paste',
    icon: 'fal fa-paste',
    label: 'pasteLabel',
    subAction: true
  },
  {
    id: 'forward',
    icon: 'fal fa-arrow-right',
    label: 'bumpForwardLabel',
    subAction: true
  },
  {
    id: 'backward',
    icon: 'fal fa-arrow-left',
    label: 'bumpBackwardLabel',
    subAction: true
  },
  {
    id: 'extend',
    icon: 'fal fa-angle-double-right',
    label: 'extendLessonLabel',
    subAction: true
  },
  {
    id: 'extendStandards',
    icon: 'fal fa-angle-double-right',
    label: 'extendStandardsLabel',
    subAction: true
  },
  {
    id: 'delete',
    icon: 'fal fa-trash-alt',
    label: 'deleteLessonLabel'
  },
  {
    id: 'noClass',
    icon: 'fal fa-calendar-times',
    label: 'noClassDayLabel'
  },
  {
    id: 'applyTemplate',
    icon: 'fal fa-wand-magic-sparkles',
    label: 'applyTemplateLabel',
    subAction: true
  },
  {
    id: 'removeTemplate',
    icon: 'fal fa-ban',
    label: 'removeTemplateLabel'
  },
  {
    id: 'restoreTemplate',
    icon: 'fal fa-arrow-rotate-left',
    label: 'restoreTemplateLabel'
  },
  {
    id: 'addClasswork',
    icon: 'fal fa-clipboard-list-check',
    label: 'addClassworkLabel'
  },
  {
    id: 'changeHistory',
    icon: 'fal fa-history',
    label: 'changeHistoryLabel'
  },
  {
    id: 'swap',
    icon: 'fal fa-right-left',
    label: 'swapLessonLabel'
  },
  {
    id: 'print',
    icon: 'fal fa-arrow-up-from-square',
    label: 'sendToLabel',
    subAction: true
  }
];

export const dayActions = [
  {
    id: 'noSchool',
    icon: 'fal fa-calendar-xmark',
    label: 'addNoSchoolLabel'
  },
  {
    id: 'event',
    icon: 'fal fa-calendar-day',
    label: 'addEventLabel'
  },
  {
    id: 'lesson',
    icon: 'fal fa-window-maximize',
    label: 'addExtraLessonLabel'
  },
  {
    id: 'sticker',
    icon: 'fal fa-image',
    label: 'addStickerLabel'
  },
  {
    id: 'forward',
    icon: 'fal fa-arrow-right',
    label: 'bumpDayLabel',
    subAction: false
  },
  {
    id: 'manageGoogleEvents',
    icon: 'fab fa-google',
    label: 'manageGoogleEventsLabel'
  },
  {
    id: 'substitute',
    icon: 'fal fa-calendar-circle-user',
    label: 'substituteDayLabel'
  },
  {
    id: 'print',
    icon: 'fal fa-arrow-up-from-square',
    label: 'sendToLabel',
    subAction: true
  }
];

export const sessionItems = [
  { text: i18n.t('timeout24HLabel'), value: 1 },
  { text: i18n.t('timeout30DLabel'), value: 30 },
  { text: i18n.t('timeoutNeverLabel'), value: -1 }
]

export const subscriptionPlans = [
  { years: 1, price: 15, title: i18n.t('priceForOneYearLabel', { price: '15.00' }) },
  { years: 2, price: 27, title: i18n.t('priceForTwoYearsLabel', { price: '27.00' }) },
  { years: 3, price: 36, title: i18n.t('priceForThreeYearsLabel', { price: '36.00' }) }
]

export const subscriptionPlansNew = [
  { years: 1, price: 20, title: i18n.t('priceForOneYearLabel', { price: '20.00' }) },
  { years: 2, price: 36, title: i18n.t('priceForTwoYearsLabel', { price: '36.00' }) },
  { years: 3, price: 48, title: i18n.t('priceForThreeYearsLabel', { price: '48.00' }) }
]

export const paymentTypes = [
  { value: 'CC', text: i18n.t('creditCardLabel') },
  { value: 'GC', text: i18n.t('giftCardLabel') }
]

export const orientationTypes = [
  { value: 0, text: i18n.t('verticalLabel') },
  { value: 1, text: i18n.t('horizontalLabel') }
]

export const lessonHeadingsOrder = [
  { value: '1', text: i18n.t('classNameFirstLabel') },
  { value: '2', text: i18n.t('classTimeFirstLabel') }
]

export const lessonHeadingsAlignment = [
  { value: 'Y', text: i18n.t('centerLabel') },
  { value: 'N', text: i18n.t('leftJustifyLabel') }
]

export const autoSaveIntervals = [
  { value: 0, text: i18n.t('autoSaveOffLabel') },
  { value: 15, text: i18n.t('secondsLabel', { seconds: 15 }) },
  { value: 30, text: i18n.t('secondsLabel', { seconds: 30 }) },
  { value: 45, text: i18n.t('secondsLabel', { seconds: 45 }) },
  { value: 60, text: i18n.t('secondsLabel', { seconds: 60 }) }
]

export const retrieveIntervals = [
  { value: 1, text: i18n.t('oneWeekAtATimeLabel') },
  { value: 3, text: i18n.t('threeWeekAtATimeLabel') }
]

export const printLayoutItems = [
  { value: 'SY', text: i18n.t('schoolYearLabel') },
  { value: 'TN', text: i18n.t('teacherNameLabel') },
  { value: 'DR', text: i18n.t('dateRangeLabel') },
  { value: 'NA', text: i18n.t('noTextLabel') }
]

export const weekDays = [
  { value: 'sunShow', text: i18n.t('sundayLabel') },
  { value: 'monShow', text: i18n.t('mondayLabel') },
  { value: 'tueShow', text: i18n.t('tuesdayLabel') },
  { value: 'wedShow', text: i18n.t('wednesdayLabel') },
  { value: 'thuShow', text: i18n.t('thursdayLabel') },
  { value: 'friShow', text: i18n.t('fridayLabel') },
  { value: 'satShow', text: i18n.t('saturdayLabel') }
]

export const cycleDays = [
  { value: 'sunCycle', shortValue: 'S', text: i18n.t('sundayLabel') },
  { value: 'monCycle', shortValue: 'M', text: i18n.t('mondayLabel') },
  { value: 'tueCycle', shortValue: 'T', text: i18n.t('tuesdayLabel') },
  { value: 'wedCycle', shortValue: 'W', text: i18n.t('wednesdayLabel') },
  { value: 'thuCycle', shortValue: 'R', text: i18n.t('thursdayLabel') },
  { value: 'friCycle', shortValue: 'F', text: i18n.t('fridayLabel') },
  { value: 'satCycle', shortValue: 'S', text: i18n.t('saturdayLabel') }
]

export const lessonSectionsPlaceHolders = [
  { value: 'lesson', text: i18n.t('textLabel') + ' 1' },
  { value: 'tab2', text: i18n.t('textLabel') + ' 2' },
  { value: 'tab3', text: i18n.t('textLabel') + ' 3' },
  { value: 'tab4', text: i18n.t('textLabel') + ' 4' },
  { value: 'tab5', text: i18n.t('textLabel') + ' 5' },
  { value: 'tab6', text: i18n.t('textLabel') + ' 6' },
  { value: 'sharedStandards', text: i18n.t('standardsLabel') },
  { value: 'myStandards', text: i18n.t('myListLabel') },
  { value: 'strategies', text: i18n.t('strategiesLabel') },
  { value: 'schoolStandards', text: i18n.t('schoolListLabel') }
]

export const dateFormatItems = [
  { value: 1, text: 'MM/DD/YYYY' },
  { value: 2, text: 'DD/MM/YYYY' }
]

export const dateDelimiterItems = [
  { value: '/', text: '/ (Slash)' },
  { value: '-', text: '- (Dash)' },
  { value: '.', text: '. (Dot)' }
]

export const nameOrderItems = [
  { value: 1, text: i18n.t('lastNameFirstNameLabel') },
  { value: 2, text: i18n.t('firstNameLastNameLabel') },
  { value: 3, text: i18n.t('firstNameLabel') },
  { value: 4, text: i18n.t('lastNameLabel') }
]

export const lessonSectionsMapping: any = {
  lesson: 'tab1',
  tab2: 'tab2',
  tab3: 'tab3',
  tab4: 'tab4',
  tab5: 'tab5',
  tab6: 'tab6',
  sharedStandards: 'tab8',
  myStandards: 'tab7',
  strategies: 'strategies',
  schoolStandards: 'tab9'
}

export const lessonStylingMapping: any = {
  lessonTitle: 'lessonTitle',
  attachments: 'tab10',
  assignments: 'tab11',
  assessments: 'tab12'
}

export const imageFileExtensions: any = {
  'image/vnd.microsoft.icon': '.ico',
  'image/jpeg': '.jpg',
  'image/png': '.png',
  'image/svg+xml': '.svg',
  'image/tiff': '.tif',
  'image/webp': '.webp'
}

export const stickerContext = {
  holidayContext: 1105,
  schoolEventsContext: 1106,
  remindersContext: 1107
}

export const fontStyles = [
  { value: 'italic', text: 'Italic' },
  { value: 'underline', text: 'Underline' },
  { value: 'bold', text: 'Bold' }
]

export const stickerOrientationOptions = [
  { value: i18n.t('stickerOrientationVertical'), text: 'Vertical' },
  { value: i18n.t('stickerOrientationHorizontal'), text: 'Horizontal' }
]

export const iconStyles = [
  { value: i18n.t('iconSettingLabel'), text: 'Icon' },
  { value: i18n.t('iconPlusTextSettingLabel'), text: 'Icon Plus Text' }
]

export const lessonPropertyDefinitions: any[] = [
  { key: 'lessonTitle', type: 'string', default: '' },
  { key: 'lessonText', type: 'string', default: '' },
  { key: 'customStart', type: 'string', default: '' },
  { key: 'customEnd', type: 'string', default: '' },
  { key: 'homeworkText', type: 'string', default: '' },
  { key: 'notesText', type: 'string', default: '' },
  { key: 'tab4Text', type: 'string', default: '' },
  { key: 'tab5Text', type: 'string', default: '' },
  { key: 'tab6Text', type: 'string', default: '' },
  { key: 'unitId', type: 'number', default: 0 },
  { key: 'lessonLock', type: 'string', default: '' },
  { key: 'strategyIds', type: 'array', default: [] },
  { key: 'standardDBIds', type: 'array', default: [] },
  { key: 'myStandardDBIds', type: 'array', default: [] },
  { key: 'schoolStandardDBIds', type: 'array', default: [] },
  { key: 'statuses', type: 'array', default: [] },
  { key: 'schoolStatuses', type: 'array', default: [] },
  { key: 'attachmentNames', type: 'array', default: [] },
  { key: 'attachmentPrivate', type: 'array', default: [] },
  { key: 'attachmentURL', type: 'array', default: [] },
  { key: 'schoolWorks', type: 'string', default: '[]' },
  { key: 'linkedLessonId', type: 'number', default: 0 }
]

export const seasonalImages = [
  { start: '01-01', end: '01-10', src: 'images/seasonal/welcome-home-jan.png' },
  { start: '02-01', end: '02-15', src: 'images/seasonal/welcome-home-feb.png' },
  { start: '03-10', end: '03-20', src: 'images/seasonal/welcome-home-mar.png' },
  { start: '04-01', end: '04-10', src: 'images/seasonal/welcome-home-apr.png' },
  { start: '04-15', end: '04-25', src: 'images/seasonal/welcome-home-earth.png' },
  // { start: '05-01', end: '05-07', src: 'images/seasonal/welcome-home-taw-world.png' },
  { start: '05-15', end: '05-31', src: 'images/seasonal/welcome-home-may.png' },
  { start: '09-01', end: '09-15', src: 'images/seasonal/welcome-home-sep.png' },
  { start: '10-15', end: '10-31', src: 'images/seasonal/welcome-home-oct.png' },
  { start: '11-15', end: '11-30', src: 'images/seasonal/welcome-home-nov.png' },
  { start: '12-15', end: '12-31', src: 'images/seasonal/welcome-home-dec.png' }
];

export const tawImages = [
  'images/seasonal/welcome-home-taw-grow.png',
  'images/seasonal/welcome-home-taw-rock.png',
  'images/seasonal/welcome-home-taw-heroes.png',
  'images/seasonal/welcome-home-taw-space.png',
  'images/seasonal/welcome-home-taw-thankyou.png'
];

export const seasonalIcons = [
  { start: '01-01', end: '12-31', tooltip: 'happyBirthday', src: 'images/seasonal/planbook-icon-birthday.png' },
  { start: '01-01', end: '01-10', tooltip: 'newYearTagline', src: 'images/seasonal/planbook-icon-jan' },
  { start: '02-01', end: '02-15', tooltip: 'valentinesTagline', src: 'images/seasonal/planbook-icon-feb.png' },
  { start: '03-10', end: '03-20', tooltip: 'mar10Tagline', src: 'images/seasonal/planbook-icon-mar.png' },
  { start: '04-01', end: '04-10', tooltip: 'aprilTagline', src: 'images/seasonal/planbook-icon-apr.png' },
  { start: '04-15', end: '04-25', tooltip: 'earthWeekTagline', src: 'images/seasonal/planbook-icon-earth.png' },
  // { start: '05-01', end: '05-07', tooltip: 'tawTagline', src: 'images/seasonal/planbook-icon-taw.png' },
  { start: '05-15', end: '05-31', tooltip: 'mayTagline', src: 'images/seasonal/planbook-icon-may.png' },
  { start: '09-01', end: '09-15', tooltip: 'sepTagline', src: 'images/seasonal/planbook-icon-sep.png' },
  { start: '10-15', end: '10-31', tooltip: 'halloweenTagline', src: 'images/seasonal/planbook-icon-oct.png' },
  { start: '11-15', end: '11-30', tooltip: 'fallTagline', src: 'images/seasonal/planbook-icon-nov.png' },
  { start: '12-15', end: '12-31', tooltip: 'decTagline', src: 'images/seasonal/planbook-icon-dec.png' }
];

export const tawIcon = { tooltip: 'tawTagline', src: 'images/seasonal/planbook-icon-taw.png' };

export const employeeBirthdays = [
  { birthday: '03-04', name: 'Jacob' },
  { birthday: '03-10', name: 'Sara' },
  { birthday: '03-17', name: 'John' },
  { birthday: '07-10', name: 'Evan' },
  { birthday: '07-12', name: 'Adrian' },
  { birthday: '08-03', name: 'Byron' },
  { birthday: '08-09', name: 'Meghan' },
  { birthday: '08-10', name: 'Justin' },
  { birthday: '08-20', name: 'Rod' },
  { birthday: '09-17', name: 'Sarah' },
  { birthday: '10-14', name: 'Hannah' },
  { birthday: '12-12', name: 'Jayson' }
];

export const displaySettingsTabs = [
  { icon: 'fal fa-object-group', label: 'lessonSectionsGroupingLabel', id: 'lessonSectionsGrouping' },
  { icon: 'fal fa-clock', label: 'lessonAutoSaveLabel', id: 'lessonAutoSave' },
  { icon: 'fal fa-arrows-alt', label: 'lessonOrientationLabel', id: 'lessonOrientation' },
  { icon: 'fal fa-print', label: 'documentHeadersLabel', id: 'printLayoutHeader' },
  { icon: 'fal fa-heading', label: 'lessonHeadingsLabel', id: 'lessonHeadings' },
  { icon: 'fal fa-info', label: 'lessonStatusLabel', id: 'lessonStatus' },
  { icon: 'fal fa-layer-group', label: 'lessonHoverLabel', id: 'lessonHover' },
  { icon: 'fal fa-calendar-alt', label: 'eventsAndStickersLabel', id: 'events' },
  { icon: 'fal fa-calendar-edit', label: 'datesAndTimeLabel', id: 'dates' },
  { icon: 'fal fa-clipboard-list', label: 'classworkLabel', id: 'classwork' },
  { icon: 'fal fa-font', label: 'customFontsLabel', id: 'customFonts' },
  { icon: 'fal fa-users', label: 'studentsLabel', id: 'studentDisplay' },
  { icon: 'fal fa-note-sticky', label: 'notesTodosLabel', id: 'notesTodo' }
];

export const whiteValues: any = {
  '#fff': true,
  '#ffffff': true,
  'rgb(255,255,255)': true,
  'hsl(0,0%,100%)': true,
  'rgba(255,255,255,1)': true,
  white: true
};

export const globalFontItems = [
  { text: i18n.t('classNamesLabel'), value: 'classNames' },
  { text: i18n.t('eventsLabel'), value: 'events' },
  { text: i18n.t('lessonLayoutsLabel'), value: 'lessonLayouts' },
  { text: i18n.t('stickersLabel'), value: 'stickers' }
]
