import qs from 'qs';
import axios from '@/axios';
import CommonUtils from '@/utils/common-utils';

export default class SchoolServices {
  public static generateSchoolJoinCode(params?: any) {
    params = params || {};
    return axios.post('services/planbook/school/generateJoinSchoolUrl', qs.stringify({ ...params, baseUrl: CommonUtils.getAppBaseUrl() }, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static removeTeachers(params?: any) {
    params = params || {};
    return axios.post('services/planbook/school/removeTeachers', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }
}
