import qs from 'qs';
import axios from '@/axios';

export default class MyListServices {
  public static getStandards(params?: any) {
    params = params || {};
    return axios.post('getMyStandards', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static addStandard(params?: any) {
    params = params || {};
    return axios.post('addMyStandard', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static updateStandard(params?: any) {
    params = params || {};
    return axios.post('updateMyStandard', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static deleteStandard(params?: any) {
    params = params || {};
    return axios.post('deleteMyStandard', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }
}
