import AppError, { createErrOptions, SessionExpiredError } from '@/errors';
import MyListServices from '@/services/my-list-services';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

const debug = process.env.NODE_ENV !== 'production';

@Module({ namespaced: true })
export default class MyLists extends VuexModule {
    allStandards: Array<any> = [];
    selectedStandard = MyLists.standardDefault();
    localStandard: any = {};
    listLoading = false;

    // toolbar attributes
    searchText = '';
    selectedRows: Array<any> = [];
    editMode = false;

    private static standardDefault() {
      return {
        dbId: 0,
        standardDesc: '',
        standardId: ''
      }
    }

    get getDefaultStandard() {
      return MyLists.standardDefault();
    }

    get getAllStandards() {
      return this.allStandards;
    }

    get getStandard() {
      return this.selectedStandard;
    }

    get getLocalStandard() {
      return this.localStandard;
    }

    get getListLoading() {
      return this.listLoading;
    }

    @Mutation
    setEditMode(editMode: boolean) {
      this.editMode = editMode;
    }

    @Mutation
    setSelectedRows(selectedRows: Array<any>) {
      this.selectedRows = selectedRows;
    }

    @Mutation
    setSearchText(searchText: string) {
      this.searchText = searchText;
    }

    @Mutation
    resetStandard() {
      this.selectedStandard = MyLists.standardDefault();
    }

    @Mutation
    setStandard(standard: any) {
      this.selectedStandard = standard;
    }

    @Mutation
    setLocalStandard(standard: any) {
      this.localStandard = standard;
    }

    @Mutation
    setStandards(standards: Array<any>) {
      this.allStandards = standards;
    }

    @Mutation
    setListLoading(listLoading: boolean) {
      this.listLoading = listLoading;
    }

    @Action({ rawError: true })
    async loadStandards() {
      try {
        const loggedInUserMode = this.context.rootState.settings.userMode;
        const resp = await MyListServices.getStandards({
          userMode: loggedInUserMode
        });
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            console.log('GET STANDARDS EXPIRED');
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            if (debug) {
              console.log('GET STANDARDS RESPONSE --> ' + JSON.stringify(data));
            }
            this.context.commit('setStandards', MyLists.prepareStandardsList(data) || []);
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    private static prepareStandardsList(responseData: any) {
      const list: Array<any> = [];
      responseData.standards.forEach((standard: any) => {
        list.push({
          dbId: standard.dbId,
          standardId: standard.sI,
          standardDescription: standard.sD
        })
      });
      return list;
    }

    @Action({ rawError: true })
    async addStandard(params?: any) {
      try {
        if (params) {
          const loggedInUserMode = this.context.rootState.settings.userMode;
          const schoolId = this.context.rootGetters['settings/getSchoolId'];
          const resp = await MyListServices.addStandard({
            userMode: loggedInUserMode,
            sI: params.standardId,
            sD: params.standardDescription,
            schoolId: schoolId
          });
          if (resp) {
            const data = resp.data;
            if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
              console.log('ADD STANDARD EXPIRED');
              return Promise.reject(new SessionExpiredError());
            } else if (data.error === 'true' || data.error === true) {
              return Promise.reject(new AppError('contactSupport', { data }));
            } else {
              if (debug) {
                console.log('ADD STANDARD RESPONSE --> ' + JSON.stringify(data));
              }
              return Promise.resolve({ data });
            }
          } else {
            return Promise.resolve({ data: {} });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async updateStandard(params?: any) {
      try {
        if (params) {
          const loggedInUserMode = this.context.rootState.settings.userMode;
          const schoolId = this.context.rootGetters['settings/getSchoolId'];
          const resp = await MyListServices.updateStandard({
            userMode: loggedInUserMode,
            dbId: params.dbId,
            sI: params.standardId,
            sD: params.standardDescription,
            schoolId: schoolId
          });
          if (resp) {
            const data = resp.data;
            if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
              console.log('UPDATE STANDARD EXPIRED');
              return Promise.reject(new SessionExpiredError());
            } else if (data.error === 'true' || data.error === true) {
              return Promise.reject(new AppError('contactSupport', { data }));
            } else {
              if (debug) {
                console.log('UPDATE STANDARD RESPONSE --> ' + JSON.stringify(data));
              }
              return Promise.resolve({ data });
            }
          } else {
            return Promise.resolve({ data: {} });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async deleteStandard(params?: any) {
      try {
        if (params) {
          const resp = await MyListServices.deleteStandard(params);
          if (resp) {
            const data = resp.data;
            if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
              console.log('DELETE STANDARD EXPIRED');
              return Promise.reject(new SessionExpiredError());
            } else if (data.error === 'true' || data.error === true) {
              return Promise.reject(new AppError('contactSupport', { data }));
            } else {
              if (debug) {
                console.log('DELETE STANDARD RESPONSE --> ' + JSON.stringify(data));
              }
              return Promise.resolve({ data });
            }
          } else {
            return Promise.resolve({ data: {} });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }
}
