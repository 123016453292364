import AppError, { createErrOptions, SessionExpiredError } from '@/errors';
import AttendanceServices from '@/services/attendance-services';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

interface Lesson {
  classColor: string;
  className: string;
  extraLessonId: number;
  subjectId: number;
}

@Module({ namespaced: true })
export default class Attendance extends VuexModule {
  lessons: Lesson[] = [];
  students: any[] = [];
  listLoading = false;

  get getLessons() {
    return this.lessons;
  }

  get getStudents() {
    return this.students;
  }

  get getListLoading() {
    return this.listLoading;
  }

  @Mutation
  clearLessons() {
    this.lessons = [];
  }

  @Mutation
  addLesson(lesson: Lesson) {
    this.lessons.push(lesson);
  }

  @Mutation
  clearStudents() {
    this.students = [];
  }

  @Mutation
  setStudents(students: any[]) {
    this.students = students;
  }

  @Mutation
  setListLoading(listLoading: boolean) {
    this.listLoading = listLoading;
  }

  @Action({ rawError: true })
  async loadLessonsByDate(params?: any) {
    try {
      const resp = await AttendanceServices.getLessonsByDate(params);

      if (resp) {
        const data = resp.data;

        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          this.context.commit('clearLessons');

          for (const lesson of data) {
            const typedLesson = {
              classColor: lesson.classColor,
              className: lesson.className,
              extraLessonId: +lesson.extraLessonId,
              subjectId: +lesson.subjectId
            }

            this.context.commit('addLesson', typedLesson);
          }

          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async loadStudents(params?: any) {
    try {
      const resp = await AttendanceServices.getStudents(params);

      if (resp) {
        const data = resp.data;

        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          this.context.commit('clearStudents');
          this.context.commit('setStudents', data.students);

          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async addAttendance(params?: any) {
    try {
      const resp = await AttendanceServices.addAttendance(params);

      if (resp) {
        const data = resp.data;

        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }
}
