import CommonUtils from '@/utils/common-utils';
import store from '../store';

export default class StudentUtils {
  public static getStudentLabel(student: any) {
    const nameOrder = store.getters['settings/getNameOrder'] || 1;
    const isNameCommaSeparated = store.getters['settings/getIsNameCommaSeparated'];

    switch (nameOrder) {
      case 1: return student.lastName + (isNameCommaSeparated ? ', ' : ' ') + student.firstName;
      case 2: return student.firstName + (isNameCommaSeparated ? ', ' : ' ') + student.lastName;
      case 3: return student.firstName;
      case 4: return student.lastName;
    }
  }

  public static getStudentInitials(student: any) {
    try {
      let initials = '';
      if (CommonUtils.hasText(student.firstName)) {
        initials += student.firstName[0].toUpperCase();
      }

      if (CommonUtils.hasText(student.lastName)) {
        initials += student.lastName[0].toUpperCase();
      }
      return initials || student.emailAddress[0];
    } catch (e) {
      return CommonUtils.hasText(student.emailAddress) ? student.emailAddress[0] : '';
    }
  }
}
