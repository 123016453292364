var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-navigation-drawer",
    {
      class:
        _vm.drawerMode == "add"
          ? "accent3"
          : _vm.drawerMode == "help"
          ? "accent2"
          : "primary",
      attrs: {
        id: "rightDrawer",
        value: _vm.showDrawer,
        width: _vm.drawerMode == "add" ? 290 : 256,
        app: "",
        temporary: "",
        right: "",
        dark: "",
        stateless: _vm.drawerRightStateless,
      },
    },
    [
      _vm.drawerMode == "add"
        ? _c("add-menu", { ref: "addMenu", on: { close: _vm.closeDrawer } })
        : _vm.drawerMode == "account"
        ? _c("account-menu", {
            ref: "accountMenu",
            on: { close: _vm.closeDrawer },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }