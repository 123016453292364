var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "perfect-scrollbar",
    {
      staticClass: "add-menu",
      on: {
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "escape", undefined, $event.key, undefined)
          )
            return null
          return _vm.closeMenu.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "ma-0 pa-0", attrs: { width: "290" } },
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-list",
                { attrs: { nav: "", dense: "" } },
                [
                  _c(
                    "v-list-item-group",
                    { attrs: { "aria-label": _vm.$t("addItemsLabel") } },
                    _vm._l(_vm.addItems, function (item, i) {
                      return _c(
                        "v-list-item",
                        {
                          key: i,
                          ref: "addItem" + i,
                          refInFor: true,
                          attrs: { dense: "", to: item.to },
                          on: {
                            click: function ($event) {
                              _vm.isAction(item) && _vm.doAction(item)
                            },
                            keyup: [
                              function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k($event.keyCode, "up", 38, $event.key, [
                                    "Up",
                                    "ArrowUp",
                                  ])
                                )
                                  return null
                                return _vm.focusItem(i - 1)
                              },
                              function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "down",
                                    40,
                                    $event.key,
                                    ["Down", "ArrowDown"]
                                  )
                                )
                                  return null
                                return _vm.focusItem(i + 1)
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", {
                                staticClass: "list-item-icon",
                                attrs: { color: item.color },
                                domProps: { textContent: _vm._s(item.icon) },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", {
                                domProps: { textContent: _vm._s(item.text) },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("substitute-notes-editor", {
        model: {
          value: _vm.localShowSubstituteNotes,
          callback: function ($$v) {
            _vm.localShowSubstituteNotes = $$v
          },
          expression: "localShowSubstituteNotes",
        },
      }),
      _c("student-notes-editor", {
        model: {
          value: _vm.localShowAnnouncements,
          callback: function ($$v) {
            _vm.localShowAnnouncements = $$v
          },
          expression: "localShowAnnouncements",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }