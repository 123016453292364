import AppError, { createErrOptions, SessionExpiredError } from '@/errors';
import CopyServices from '@/services/copy-services';
import LessonServices from '@/services/lesson-services';
import { Action, Module, VuexModule } from 'vuex-module-decorators';
import ld from 'lodash';
import DateTimeUtils from '@/utils/date-time-utils';

@Module({ namespaced: true })
export default class Classwork extends VuexModule {
  @Action({ rawError: true })
  async copyAssessments(param: any) {
    try {
      const payload = {
        classId: param.classId,
        sharedClassId: param.sharedClassId,
        sharedAssessmentIds: param.assessmentIds
      }
      const resp = await CopyServices.copyAssessments(payload);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          return Promise.resolve({ resp });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async copyAssessmentsMany(param: any) {
    try {
      const payload = {
        classId: param.classId,
        sharedClassId: param.sharedClassId,
        sharedAssessmentIds: param.assessmentIds
      }
      const resp = await CopyServices.copyAssessmentsMany(payload);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          return Promise.resolve({ resp });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async copyAssessmentsToAll(param: any) {
    try {
      const payload = {
        classId: param.classId,
        sharedClassId: param.sharedClassId,
        sharedAssessmentIds: param.assessmentIds
      }
      const resp = await CopyServices.copyAssessmentsToAll(payload);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          return Promise.resolve({ resp });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async copyAssessmentsAll(param: any) {
    try {
      const payload = {
        classId: param.classId,
        sharedClassId: param.sharedClassId,
        sharedAssessmentIds: param.assessmentIds
      }
      const resp = await CopyServices.copyAssessmentsAll(payload);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          return Promise.resolve({ resp });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async copyAssignments(param: any) {
    try {
      const payload = {
        classId: param.classId,
        sharedClassId: param.sharedClassId,
        sharedAssignmentIds: param.assignmentIds
      }
      const resp = await CopyServices.copyAssignments(payload);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          return Promise.resolve({ resp });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async copyAssignmentsMany(param: any) {
    try {
      const payload = {
        classId: param.classId,
        sharedClassId: param.sharedClassId,
        sharedAssignmentIds: param.assignmentIds
      }
      const resp = await CopyServices.copyAssignmentsMany(payload);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          return Promise.resolve({ resp });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async copyAssignmentsToAll(param: any) {
    try {
      const payload = {
        classId: param.classId,
        sharedClassId: param.sharedClassId,
        sharedAssignmentIds: param.assignmentIds
      }
      const resp = await CopyServices.copyAssignmentsToAll(payload);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          return Promise.resolve({ resp });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async copyAssignmentsAll(param: any) {
    try {
      const payload = {
        classId: param.classId,
        sharedClassId: param.sharedClassId,
        sharedAssignmentIds: param.assignmentIds
      }
      const resp = await CopyServices.copyAssignmentsAll(payload);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          return Promise.resolve({ resp });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async copyClasses(param: any) {
    try {
      const payload = new URLSearchParams();
      payload.append('yearId', param.yearId); // TO
      payload.append('sharedYearId', param.sharedYearId); // FROM
      for (const i in param.subjectIds) {
        payload.append('subjectIds', param.subjectIds[i]);
      }
      for (const i in param.items) {
        payload.append(param.items[i], 'true');
      }
      const resp = await CopyServices.copyClasses(payload);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          return Promise.resolve({ resp });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async copyLesson(param: any) {
    try {
      const resp = await CopyServices.importLessons(param);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          this.context.commit('classes/setHaveClass', false, { root: true });
          await this.context.dispatch('classes/loadClasses', {}, { root: true });
          return Promise.resolve({ resp });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async copyLessonMany(param: any) {
    try {
      const request = ld.cloneDeep(param);
      delete request.copyList;

      const respAll = await Promise.all(
        param.copyList.map((copyRequest: any) => {
          request.sharedClassId = copyRequest.from;
          request.classId = copyRequest.to;
          return CopyServices.importLessons(request);
        })
      )

      if (respAll) {
        let deleteCount = 0;
        let deleteDates: any[] = [];
        const copyListConfirm: any[] = [];
        for (const i in respAll) {
          const data = (respAll[i] as any).data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          }

          if (data.count) {
            deleteCount += data.count
            copyListConfirm.push(param.copyList[i]);
            deleteDates = deleteDates.concat(data.lessonDates);
          }
        }
        deleteDates.sort((a: any, b: any) => {
          if (DateTimeUtils.isThisDateBeforeThatDate(a.date, b.date)) {
            return -1;
          } else if (DateTimeUtils.isThisDateBeforeThatDate(b.date, a.date)) {
            return 1;
          } else {
            return 0;
          }
        })
        const returnData = { count: deleteCount, lessonDates: deleteDates, confirmRequests: copyListConfirm };
        this.context.commit('classes/setHaveClass', false, { root: true });
        await this.context.dispatch('classes/loadClasses', {}, { root: true });
        return Promise.resolve({ data: returnData });
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async linkToMany(params: any) {
    try {
      const request = ld.cloneDeep(params);
      delete request.copyList;
      delete request.lessons;
      const respAll = await Promise.all(
        params.copyList.map((copyRequest: any) => {
          request.targetSubjectId = copyRequest.to;
          request.targetInsertDate = copyRequest.targetInsertDate;
          request.lessonId = params.lessons.filter((l: any) => l.classId === copyRequest.from).map((l: any) => { return l.lessonId || 0 });
          request.lessonAddendums = request.lessonId.map((l: any) => {
            if (l !== 0) {
              return { type: 'LINKEDLESSON', typeId: l }
            } else {
              return { type: 'LINKEDLESSON' }
            }
          });
          return LessonServices.link(request);
        })
      )

      let confirmLinkAction = false;
      const copyListConfirm: Array<any> = [];
      if (respAll) {
        for (const i in respAll) {
          const data = (respAll[i] as any).data;
          console.log(respAll[i])
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else if (data.confirmLinkAction || data.deletedLessons) {
            confirmLinkAction = true;
            copyListConfirm.push(params.copyList[i])
          }
        }
        return Promise.resolve({ data: { confirmLinkAction: confirmLinkAction, confirmRequests: copyListConfirm } });
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async copyStudents(param: any) {
    try {
      const payload = {
        yearId: param.yearId,
        sharedYearId: param.sharedYearId,
        sharedStudentIds: param.studentIds
      }
      const resp = await CopyServices.copyStudents(payload);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          return Promise.resolve({ resp });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }
}
