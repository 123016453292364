export const lessonsEvents = {
  hasAssignments: {
    12347991: false,
    12347992: false,
    12347993: false,
    12347994: false,
    12347995: false,
    12347996: false,
    12347997: false,
    12347998: false,
    12347999: false,
    12348000: false,
    12348001: false,
    12348002: false,
    12348003: false,
    12348004: false,
    12348005: false,
    12348006: false,
    12348007: false,
    12348008: false,
    12348009: false
  },
  days: [
    {
      date: '02/03/2019',
      cD: 'Week 1',
      dayOfWeek: 'Sunday',
      objects: [],
      count: -1
    },
    {
      date: '02/04/2019',
      cD: 'Week 1',
      dayOfWeek: 'Monday',
      objects: [
        {
          assignments: [],
          classLabelUnderline: 'N',
          tab4Text: '',
          unitNum: '',
          type: 'L',
          unitTitle: '',
          homeworkText: '',
          customStart: '',
          t6Template: 'N',
          t4Template: 'N',
          collaborateSubjectId: 0,
          classLabelItalic: 'N',
          tab5Text: '',
          lessonUnitStandards: [],
          customEnd: '',
          lessonLock: 'N',
          googleAssessments: [],
          lessonText: '',
          ssTemplate: 'N',
          tab6Text: '',
          standards: [],
          lessonTitle: '',
          withMathExpressions: 'N',
          notesText: '',
          attachments: [],
          color: '#cc99ff',
          asgAttachments: [],
          classLabelBold: 'N',
          extraLesson: 0,
          className: 'Jobs ',
          titleSize: '10',
          googleAssignments: [],
          classId: 12347993,
          asmAttachments: [],
          isAddClassDays: false,
          titleColor: '#FFFFFF',
          schoolStandards: [],
          msTemplate: 'N',
          unitId: 0,
          startTime: '8:00 AM',
          classYearId: 1197353,
          t5Template: 'N',
          sTemplate: 'N',
          hTemplate: 'N',
          assessments: [],
          addClassDaysCode: '',
          strategies: [],
          myStandards: [],
          collaborateType: 0,
          addendums: [],
          titleFont: '2',
          lTemplate: 'N',
          teacherId: 1245979,
          nTemplate: 'N',
          endTime: '8:30 AM'
        },
        {
          assignments: [],
          classLabelUnderline: 'N',
          tab4Text: '',
          unitNum: '',
          type: 'L',
          unitTitle: '',
          homeworkText: '',
          customStart: '',
          t6Template: 'N',
          t4Template: 'N',
          collaborateSubjectId: 0,
          classLabelItalic: 'N',
          tab5Text: '',
          lessonUnitStandards: [],
          customEnd: '',
          lessonLock: 'N',
          googleAssessments: [],
          lessonText: '<p>Sex Education&nbsp;</p>\n<p>Lesson 1&nbsp;</p>\n<p>Sex and the law - write down their thoughts&nbsp;</p>\n<p>Role play on peer pressure - pick topics where peer pressure could be experienced and write them on the board then they have to pick one and everyone else can guess.&nbsp;</p>\n<p>What makes a good relationship - thought shower on the board of different ideas&nbsp;</p>\n<p>&nbsp;</p>\n<p>Booklets are in file&nbsp;</p>\n<p>&nbsp;</p>\n<p>If complete - write any questions down on a piece of paper&nbsp;</p>\n<p>Go through contraception types - use the red box to go through each of the different ones - get the girls to have a look at them and chat about each of them. Fill in page in the booklet&nbsp;</p>',
          ssTemplate: 'N',
          tab6Text: '',
          standards: [],
          lessonTitle: '',
          withMathExpressions: 'N',
          notesText: '',
          attachments: [],
          color: '#cc99ff',
          asgAttachments: [],
          classLabelBold: 'N',
          extraLesson: 0,
          className: 'U4 PSHE',
          titleSize: '10',
          googleAssignments: [],
          classId: 12348006,
          asmAttachments: [],
          isAddClassDays: false,
          titleColor: '#FFFFFF',
          schoolStandards: [],
          msTemplate: 'N',
          unitId: 0,
          startTime: '9:30 AM',
          classYearId: 1197353,
          t5Template: 'N',
          sTemplate: 'N',
          hTemplate: 'N',
          assessments: [],
          addClassDaysCode: '',
          strategies: [],
          myStandards: [],
          collaborateType: 0,
          addendums: [],
          lessonId: 550964277,
          titleFont: '2',
          lTemplate: 'N',
          teacherId: 1245979,
          nTemplate: 'N',
          endTime: '10:10 AM'
        },
        {
          assignments: [],
          classLabelUnderline: 'N',
          tab4Text: '',
          unitNum: '',
          type: 'L',
          unitTitle: '',
          homeworkText: '',
          customStart: '',
          t6Template: 'N',
          t4Template: 'N',
          collaborateSubjectId: 0,
          classLabelItalic: 'N',
          tab5Text: '',
          lessonUnitStandards: [],
          customEnd: '',
          lessonLock: 'N',
          googleAssessments: [],
          lessonText: '<p>Swimming gala events&nbsp;</p>\n<p>All serious races - front, back, breast, fly&nbsp;</p>\n<p>In houses do a freestyle relay&nbsp;</p>\n<p>&nbsp;</p>',
          ssTemplate: 'N',
          tab6Text: '',
          standards: [],
          lessonTitle: 'Swimming',
          withMathExpressions: 'N',
          notesText: '',
          attachments: [],
          color: '#99ccff',
          asgAttachments: [],
          classLabelBold: 'N',
          extraLesson: 0,
          className: 'U2',
          titleSize: '10',
          googleAssignments: [],
          classId: 12348001,
          asmAttachments: [],
          isAddClassDays: false,
          titleColor: '#FFFFFF',
          schoolStandards: [],
          msTemplate: 'N',
          unitId: 0,
          startTime: '10:25 AM',
          classYearId: 1197353,
          t5Template: 'N',
          sTemplate: 'N',
          hTemplate: 'N',
          assessments: [],
          addClassDaysCode: '',
          strategies: [],
          myStandards: [],
          collaborateType: 0,
          addendums: [],
          lessonId: 550964215,
          titleFont: '2',
          lTemplate: 'N',
          teacherId: 1245979,
          nTemplate: 'N',
          endTime: '11:45 AM'
        },
        {
          assignments: [],
          classLabelUnderline: 'N',
          tab4Text: '',
          unitNum: '',
          type: 'L',
          unitTitle: '',
          homeworkText: '',
          customStart: '',
          t6Template: 'N',
          t4Template: 'N',
          collaborateSubjectId: 0,
          classLabelItalic: 'N',
          tab5Text: '',
          lessonUnitStandards: [],
          customEnd: '',
          lessonLock: 'N',
          googleAssessments: [],
          lessonText: '<p>Swimming Gala Events&nbsp;</p>',
          ssTemplate: 'N',
          tab6Text: '',
          standards: [],
          lessonTitle: '',
          withMathExpressions: 'N',
          notesText: '',
          attachments: [],
          color: '#4585a3',
          asgAttachments: [],
          classLabelBold: 'N',
          extraLesson: 0,
          className: 'U3 Games ',
          titleSize: '10',
          googleAssignments: [],
          classId: 12348002,
          asmAttachments: [],
          isAddClassDays: false,
          titleColor: '#FFFFFF',
          schoolStandards: [],
          msTemplate: 'N',
          unitId: 0,
          startTime: '1:30 PM',
          classYearId: 1197353,
          t5Template: 'N',
          sTemplate: 'N',
          hTemplate: 'N',
          assessments: [],
          addClassDaysCode: '',
          strategies: [],
          myStandards: [],
          collaborateType: 0,
          addendums: [],
          lessonId: 550964231,
          titleFont: '2',
          lTemplate: 'N',
          teacherId: 1245979,
          nTemplate: 'N',
          endTime: '3:30 PM'
        }
      ],
      count: 108
    },
    {
      date: '02/05/2019',
      cD: 'Week 1',
      dayOfWeek: 'Tuesday',
      objects: [
        {
          assignments: [],
          classLabelUnderline: 'N',
          tab4Text: '',
          unitNum: '',
          type: 'L',
          unitTitle: '',
          homeworkText: '',
          customStart: '',
          t6Template: 'N',
          t4Template: 'N',
          collaborateSubjectId: 0,
          classLabelItalic: 'N',
          tab5Text: '',
          lessonUnitStandards: [],
          customEnd: '',
          lessonLock: 'N',
          googleAssessments: [],
          lessonText: '',
          ssTemplate: 'N',
          tab6Text: '',
          standards: [],
          lessonTitle: '',
          withMathExpressions: 'N',
          notesText: '',
          attachments: [],
          color: '#cc99ff',
          asgAttachments: [],
          classLabelBold: 'N',
          extraLesson: 0,
          className: 'Jobs ',
          titleSize: '10',
          googleAssignments: [],
          classId: 12347993,
          asmAttachments: [],
          isAddClassDays: false,
          titleColor: '#FFFFFF',
          schoolStandards: [],
          msTemplate: 'N',
          unitId: 0,
          startTime: '8:00 AM',
          classYearId: 1197353,
          t5Template: 'N',
          sTemplate: 'N',
          hTemplate: 'N',
          assessments: [],
          addClassDaysCode: '',
          strategies: [],
          myStandards: [],
          collaborateType: 0,
          addendums: [],
          titleFont: '2',
          lTemplate: 'N',
          teacherId: 1245979,
          nTemplate: 'N',
          endTime: '8:30 AM'
        },
        {
          assignments: [],
          classLabelUnderline: 'N',
          tab4Text: '',
          unitNum: '',
          type: 'L',
          unitTitle: '',
          homeworkText: '',
          customStart: '',
          t6Template: 'N',
          t4Template: 'N',
          collaborateSubjectId: 0,
          classLabelItalic: 'N',
          tab5Text: '',
          lessonUnitStandards: [],
          customEnd: '',
          lessonLock: 'N',
          googleAssessments: [],
          lessonText: '<p>10 bounces,&nbsp;</p>\n<p>Tick sheet - ML to complete&nbsp;</p>\n<p>Routines&nbsp;</p>\n<p>&nbsp;</p>\n<p>some on front drop&nbsp;</p>',
          ssTemplate: 'N',
          tab6Text: '',
          standards: [],
          lessonTitle: 'Trampoline',
          withMathExpressions: 'N',
          notesText: '',
          attachments: [],
          color: '#800080',
          asgAttachments: [],
          classLabelBold: 'N',
          extraLesson: 0,
          className: 'L3',
          titleSize: '10',
          googleAssignments: [],
          classId: 12347994,
          asmAttachments: [],
          isAddClassDays: false,
          titleColor: '#FFFFFF',
          schoolStandards: [],
          msTemplate: 'N',
          unitId: 0,
          startTime: '11:05 AM',
          classYearId: 1197353,
          t5Template: 'N',
          sTemplate: 'N',
          hTemplate: 'N',
          assessments: [],
          addClassDaysCode: '',
          strategies: [],
          myStandards: [],
          collaborateType: 0,
          addendums: [],
          lessonId: 550964114,
          titleFont: '2',
          lTemplate: 'N',
          teacherId: 1245979,
          nTemplate: 'N',
          endTime: '11:45 AM'
        },
        {
          assignments: [],
          classLabelUnderline: 'N',
          tab4Text: '',
          unitNum: '',
          type: 'L',
          unitTitle: '',
          homeworkText: '<p>Due 6 mark question&nbsp;</p>\n<p>&nbsp;</p>\n<p>Hand out 6 mark essay question&nbsp;</p>\n<p>research participation rates - with evidence and website details&nbsp;</p>',
          customStart: '',
          t6Template: 'N',
          t4Template: 'N',
          collaborateSubjectId: 0,
          classLabelItalic: 'N',
          tab5Text: '',
          lessonUnitStandards: [],
          customEnd: '',
          lessonLock: 'N',
          googleAssessments: [],
          lessonText: '<p>Start with mini quiz have it on the board - from last weeks powerpoint&nbsp;</p>\n<p>&nbsp;</p>\n<p>Give them the graphs and data from the textbook/student book&nbsp;</p>\n<p>Use the computers to get current data and trends&nbsp;</p>\n<p>&nbsp;</p>\n<p>How does the media affect participation - positive and negative - spider diagram sheet - use text book to help</p>\n<p>&nbsp;</p>\n<p>Then look at ways to improve participation - new powerpoint&nbsp;</p>\n<p>&nbsp;</p>',
          ssTemplate: 'N',
          tab6Text: '',
          standards: [],
          lessonTitle: '',
          withMathExpressions: 'N',
          notesText: '',
          attachments: [],
          color: '#668cd9',
          asgAttachments: [],
          classLabelBold: 'N',
          extraLesson: 0,
          className: 'U5   G C S E ',
          titleSize: '10',
          googleAssignments: [],
          classId: 12348007,
          asmAttachments: [],
          isAddClassDays: false,
          titleColor: '#FFFFFF',
          schoolStandards: [],
          msTemplate: 'N',
          unitId: 0,
          startTime: '12:50 PM',
          classYearId: 1197353,
          t5Template: 'N',
          sTemplate: 'N',
          hTemplate: 'N',
          assessments: [],
          addClassDaysCode: '',
          strategies: [],
          myStandards: [],
          collaborateType: 0,
          addendums: [],
          lessonId: 550964294,
          titleFont: '2',
          lTemplate: 'N',
          teacherId: 1245979,
          nTemplate: 'N',
          endTime: '2:10 PM'
        },
        {
          assignments: [],
          classLabelUnderline: 'N',
          tab4Text: '',
          unitNum: '',
          type: 'L',
          unitTitle: '',
          homeworkText: '',
          customStart: '',
          t6Template: 'N',
          t4Template: 'N',
          collaborateSubjectId: 0,
          classLabelItalic: 'N',
          tab5Text: '',
          lessonUnitStandards: [],
          customEnd: '',
          lessonLock: 'N',
          googleAssessments: [],
          lessonText: '<p>Wall bars&nbsp;</p>\n<p>&nbsp;</p>\n<p>Mats everywhere&nbsp;</p>\n<p>Bench x2 on one side&nbsp;</p>\n<p>&nbsp;</p>\n<p>&nbsp;</p>',
          ssTemplate: 'N',
          tab6Text: '',
          standards: [],
          lessonTitle: 'Gymnastics',
          withMathExpressions: 'N',
          notesText: '',
          attachments: [],
          color: '#603f99',
          asgAttachments: [],
          classLabelBold: 'N',
          extraLesson: 0,
          className: 'D1',
          titleSize: '10',
          googleAssignments: [],
          classId: 12347992,
          asmAttachments: [],
          isAddClassDays: false,
          titleColor: '#FFFFFF',
          schoolStandards: [],
          msTemplate: 'N',
          unitId: 0,
          startTime: '2:10 PM',
          classYearId: 1197353,
          t5Template: 'N',
          sTemplate: 'N',
          hTemplate: 'N',
          assessments: [],
          addClassDaysCode: '',
          strategies: [],
          myStandards: [],
          collaborateType: 0,
          addendums: [],
          lessonId: 550964084,
          titleFont: '2',
          lTemplate: 'N',
          teacherId: 1245979,
          nTemplate: 'N',
          endTime: '3:30 PM'
        }
      ],
      count: 109
    },
    {
      date: '02/06/2019',
      cD: 'Week 1',
      dayOfWeek: 'Wednesday',
      objects: [
        {
          assignments: [],
          classLabelUnderline: 'N',
          tab4Text: '',
          unitNum: '',
          type: 'L',
          unitTitle: '',
          homeworkText: '',
          customStart: '',
          t6Template: 'N',
          t4Template: 'N',
          collaborateSubjectId: 0,
          classLabelItalic: 'N',
          tab5Text: '',
          lessonUnitStandards: [],
          customEnd: '',
          lessonLock: 'N',
          googleAssessments: [],
          lessonText: '<p>Passing activities&nbsp;</p>\n<p>&nbsp;</p>\n<p>duos style&nbsp;</p>\n<p>Try to steal the ball, must dribble it over the line to have won the point. Both run from opposite sides to the middle.&nbsp;</p>\n<p>Attackers and then a defender in each third to try and stop the ball&nbsp;</p>\n<p>Numbers game - call into middle have people on edge to pass too&nbsp;</p>\n<p>&nbsp;</p>',
          ssTemplate: 'N',
          tab6Text: '',
          standards: [],
          lessonTitle: 'Football',
          withMathExpressions: 'N',
          notesText: '',
          attachments: [],
          color: '#800080',
          asgAttachments: [],
          classLabelBold: 'N',
          extraLesson: 0,
          className: 'L3',
          titleSize: '10',
          googleAssignments: [],
          classId: 12347994,
          asmAttachments: [],
          isAddClassDays: false,
          titleColor: '#FFFFFF',
          schoolStandards: [],
          msTemplate: 'N',
          unitId: 0,
          startTime: '8:35 AM',
          classYearId: 1197353,
          t5Template: 'N',
          sTemplate: 'N',
          hTemplate: 'N',
          assessments: [],
          addClassDaysCode: '',
          strategies: [],
          myStandards: [],
          collaborateType: 0,
          addendums: [],
          lessonId: 550964115,
          titleFont: '2',
          lTemplate: 'N',
          teacherId: 1245979,
          nTemplate: 'N',
          endTime: '9:15 AM'
        },
        {
          assignments: [],
          classLabelUnderline: 'N',
          tab4Text: '',
          unitNum: '',
          type: 'L',
          unitTitle: '',
          homeworkText: '',
          customStart: '',
          t6Template: 'N',
          t4Template: 'N',
          collaborateSubjectId: 0,
          classLabelItalic: 'N',
          tab5Text: '',
          lessonUnitStandards: [],
          customEnd: '',
          lessonLock: 'N',
          googleAssessments: [],
          lessonText: '<p>What are the different types of training?&nbsp;</p>\n<p>Aerobics- accessible and easily changed - we did some during dance&nbsp;</p>\n<p>&nbsp;</p>\n<p><strong>Warm up</strong> - teacher/video led&nbsp;</p>\n<p>Teach the steps&nbsp;</p>\n<p>Boxstep/grapevine/step touch/heel dig/push back/half star/knee repeater/marching/knee raise behind/crossover step</p>\n<p>&nbsp;</p>\n<p>Girls to create their own 32 count routine / 64 count routine&nbsp;</p>\n<p>&nbsp;</p>\n<p>Why do we have different types of training?&nbsp;</p>\n<p>Which training links to which sport?&nbsp;</p>',
          ssTemplate: 'N',
          tab6Text: '',
          standards: [],
          lessonTitle: 'Fitness- Aerobics',
          withMathExpressions: 'N',
          notesText: '',
          attachments: [],
          color: '#00ccff',
          asgAttachments: [],
          classLabelBold: 'N',
          extraLesson: 0,
          className: 'U3w',
          titleSize: '10',
          googleAssignments: [],
          classId: 12348004,
          asmAttachments: [],
          isAddClassDays: false,
          titleColor: '#FFFFFF',
          schoolStandards: [],
          msTemplate: 'N',
          unitId: 0,
          startTime: '9:15 AM',
          classYearId: 1197353,
          t5Template: 'N',
          sTemplate: 'N',
          hTemplate: 'N',
          assessments: [],
          addClassDaysCode: '',
          strategies: [],
          myStandards: [],
          collaborateType: 0,
          addendums: [],
          lessonId: 550964259,
          titleFont: '2',
          lTemplate: 'N',
          teacherId: 1245979,
          nTemplate: 'N',
          endTime: '9:55 AM'
        },
        {
          assignments: [],
          classLabelUnderline: 'N',
          tab4Text: '',
          unitNum: '',
          type: 'L',
          unitTitle: '',
          homeworkText: '',
          customStart: '',
          t6Template: 'N',
          t4Template: 'N',
          collaborateSubjectId: 0,
          classLabelItalic: 'N',
          tab5Text: '',
          lessonUnitStandards: [],
          customEnd: '',
          lessonLock: 'N',
          googleAssessments: [],
          lessonText: '',
          ssTemplate: 'N',
          tab6Text: '',
          standards: [],
          lessonTitle: 'Badminton',
          withMathExpressions: 'N',
          notesText: '',
          attachments: [],
          color: '#99ccff',
          asgAttachments: [],
          classLabelBold: 'N',
          extraLesson: 0,
          className: 'U2',
          titleSize: '10',
          googleAssignments: [],
          classId: 12348001,
          asmAttachments: [],
          isAddClassDays: false,
          titleColor: '#FFFFFF',
          schoolStandards: [],
          msTemplate: 'N',
          unitId: 0,
          startTime: '9:55 AM',
          classYearId: 1197353,
          t5Template: 'N',
          sTemplate: 'N',
          hTemplate: 'N',
          assessments: [],
          addClassDaysCode: '',
          strategies: [],
          myStandards: [],
          collaborateType: 0,
          addendums: [],
          lessonId: 550964216,
          titleFont: '2',
          lTemplate: 'N',
          teacherId: 1245979,
          nTemplate: 'N',
          endTime: '10:35 AM'
        },
        {
          assignments: [],
          classLabelUnderline: 'N',
          tab4Text: '',
          unitNum: '',
          type: 'L',
          unitTitle: '',
          homeworkText: '',
          customStart: '',
          t6Template: 'N',
          t4Template: 'N',
          collaborateSubjectId: 0,
          classLabelItalic: 'N',
          tab5Text: '',
          lessonUnitStandards: [],
          customEnd: '',
          lessonLock: 'N',
          googleAssessments: [],
          lessonText: '<p>Research STI / Contraception / create a credit card sized information document with help on&nbsp;</p>\n<p>If no computers do by hand with bits of information&nbsp;</p>\n<p>&nbsp;</p>\n<p>CONDOM ACTIVITY</p>\n<p>&nbsp;</p>\n<p>&nbsp;</p>',
          ssTemplate: 'N',
          tab6Text: '',
          standards: [],
          lessonTitle: '',
          withMathExpressions: 'N',
          notesText: '',
          attachments: [],
          color: '#59bfb3',
          asgAttachments: [],
          classLabelBold: 'N',
          extraLesson: 0,
          className: 'L5w PSHE',
          titleSize: '10',
          googleAssignments: [],
          classId: 12347999,
          asmAttachments: [],
          isAddClassDays: false,
          titleColor: '#FFFFFF',
          schoolStandards: [],
          msTemplate: 'N',
          unitId: 0,
          startTime: '1:10 PM',
          classYearId: 1197353,
          t5Template: 'N',
          sTemplate: 'N',
          hTemplate: 'N',
          assessments: [],
          addClassDaysCode: '',
          strategies: [],
          myStandards: [],
          collaborateType: 0,
          addendums: [],
          lessonId: 550964187,
          titleFont: '2',
          lTemplate: 'N',
          teacherId: 1245979,
          nTemplate: 'N',
          endTime: '1:50 PM'
        },
        {
          assignments: [],
          classLabelUnderline: 'N',
          tab4Text: '',
          unitNum: '',
          type: 'L',
          unitTitle: '',
          homeworkText: '',
          customStart: '',
          t6Template: 'N',
          t4Template: 'N',
          collaborateSubjectId: 0,
          classLabelItalic: 'N',
          tab5Text: '',
          lessonUnitStandards: [],
          customEnd: '',
          lessonLock: 'N',
          googleAssessments: [],
          lessonText: '<p>Card Game&nbsp;</p>\n<p>2x6 mins 3 mins rest&nbsp;&nbsp;</p>\n<p>2x5 mins 4 mins rest&nbsp;</p>\n<p>&nbsp;</p>\n<p>Different exericses&nbsp;</p>',
          ssTemplate: 'N',
          tab6Text: '',
          standards: [],
          lessonTitle: 'Fitness',
          withMathExpressions: 'N',
          notesText: '',
          attachments: [],
          color: '#fe9c0a',
          asgAttachments: [],
          classLabelBold: 'N',
          extraLesson: 0,
          className: 'L5e',
          titleSize: '10',
          googleAssignments: [],
          classId: 12347998,
          asmAttachments: [],
          isAddClassDays: false,
          titleColor: '#FFFFFF',
          schoolStandards: [],
          msTemplate: 'N',
          unitId: 0,
          startTime: '1:50 PM',
          classYearId: 1197353,
          t5Template: 'N',
          sTemplate: 'N',
          hTemplate: 'N',
          assessments: [],
          addClassDaysCode: '',
          strategies: [],
          myStandards: [],
          collaborateType: 0,
          addendums: [],
          lessonId: 550964176,
          titleFont: '2',
          lTemplate: 'N',
          teacherId: 1245979,
          nTemplate: 'N',
          endTime: '2:30 PM'
        },
        {
          assignments: [],
          classLabelUnderline: 'N',
          tab4Text: '',
          unitNum: '',
          type: 'L',
          unitTitle: '',
          homeworkText: '',
          customStart: '',
          t6Template: 'N',
          t4Template: 'N',
          collaborateSubjectId: 0,
          classLabelItalic: 'N',
          tab5Text: '',
          lessonUnitStandards: [],
          customEnd: '',
          lessonLock: 'N',
          googleAssessments: [],
          lessonText: '<p>Card game 2 x 6 minutes 3 minute recovery&nbsp;</p>\n<p>Basketball?&nbsp;</p>',
          ssTemplate: 'N',
          tab6Text: '',
          standards: [],
          lessonTitle: '',
          withMathExpressions: 'N',
          notesText: '',
          attachments: [],
          color: '#41a587',
          asgAttachments: [],
          classLabelBold: 'N',
          extraLesson: 0,
          className: 'U5 Games ',
          titleSize: '10',
          googleAssignments: [],
          classId: 12348008,
          asmAttachments: [],
          isAddClassDays: false,
          titleColor: '#FFFFFF',
          schoolStandards: [],
          msTemplate: 'N',
          unitId: 0,
          startTime: '2:30 PM',
          classYearId: 1197353,
          t5Template: 'N',
          sTemplate: 'N',
          hTemplate: 'N',
          assessments: [],
          addClassDaysCode: '',
          strategies: [],
          myStandards: [],
          collaborateType: 0,
          addendums: [],
          lessonId: 550964315,
          titleFont: '2',
          lTemplate: 'N',
          teacherId: 1245979,
          nTemplate: 'N',
          endTime: '3:50 PM'
        }
      ],
      count: 110
    },
    {
      date: '02/07/2019',
      cD: 'Week 1',
      dayOfWeek: 'Thursday',
      objects: [
        {
          assignments: [],
          classLabelUnderline: 'N',
          tab4Text: '',
          unitNum: '',
          type: 'L',
          unitTitle: '',
          homeworkText: '',
          customStart: '',
          t6Template: 'N',
          t4Template: 'N',
          collaborateSubjectId: 0,
          classLabelItalic: 'N',
          tab5Text: '',
          lessonUnitStandards: [],
          customEnd: '',
          lessonLock: 'N',
          googleAssessments: [],
          lessonText: '',
          ssTemplate: 'N',
          tab6Text: '',
          standards: [],
          lessonTitle: '',
          withMathExpressions: 'N',
          notesText: '',
          attachments: [],
          color: '#cc99ff',
          asgAttachments: [],
          classLabelBold: 'N',
          extraLesson: 0,
          className: 'Jobs ',
          titleSize: '10',
          googleAssignments: [],
          classId: 12347993,
          asmAttachments: [],
          isAddClassDays: false,
          titleColor: '#FFFFFF',
          schoolStandards: [],
          msTemplate: 'N',
          unitId: 0,
          startTime: '8:00 AM',
          classYearId: 1197353,
          t5Template: 'N',
          sTemplate: 'N',
          hTemplate: 'N',
          assessments: [],
          addClassDaysCode: '',
          strategies: [],
          myStandards: [],
          collaborateType: 0,
          addendums: [],
          titleFont: '2',
          lTemplate: 'N',
          teacherId: 1245979,
          nTemplate: 'N',
          endTime: '8:30 AM'
        },
        {
          assignments: [],
          classLabelUnderline: 'N',
          tab4Text: '',
          unitNum: '',
          type: 'L',
          unitTitle: '',
          homeworkText: '',
          customStart: '',
          t6Template: 'N',
          t4Template: 'N',
          collaborateSubjectId: 0,
          classLabelItalic: 'N',
          tab5Text: '',
          lessonUnitStandards: [],
          customEnd: '',
          lessonLock: 'N',
          googleAssessments: [],
          lessonText: '<p>Starter&nbsp;</p>\n<p>Bingo&nbsp;</p>\n<p>&nbsp;</p>\n<p>Give test back&nbsp;</p>\n<p>&nbsp;</p>\n<p>Main&nbsp;</p>\n<p>Use the recap powerpoint work through&nbsp;</p>\n<p>Fill &nbsp;in bitesize worksheet&nbsp;</p>\n<p>&nbsp;</p>\n<p>Create their own continuums - use images from internet&nbsp;</p>\n<p>&nbsp;</p>',
          ssTemplate: 'N',
          tab6Text: '',
          standards: [],
          lessonTitle: 'S9',
          withMathExpressions: 'N',
          notesText: '',
          attachments: [],
          color: '#8c66d9',
          asgAttachments: [],
          classLabelBold: 'N',
          extraLesson: 0,
          className: 'L5   G C S E',
          titleSize: '10',
          googleAssignments: [],
          classId: 12347997,
          asmAttachments: [],
          isAddClassDays: false,
          titleColor: '#FFFFFF',
          schoolStandards: [],
          msTemplate: 'N',
          unitId: 0,
          startTime: '8:50 AM',
          classYearId: 1197353,
          t5Template: 'N',
          sTemplate: 'N',
          hTemplate: 'N',
          assessments: [],
          addClassDaysCode: '',
          strategies: [],
          myStandards: [],
          collaborateType: 0,
          addendums: [],
          lessonId: 550964166,
          titleFont: '2',
          lTemplate: 'N',
          teacherId: 1245979,
          nTemplate: 'N',
          endTime: '10:10 AM'
        },
        {
          assignments: [],
          classLabelUnderline: 'N',
          tab4Text: '',
          unitNum: '',
          type: 'L',
          unitTitle: '',
          homeworkText: '',
          customStart: '',
          t6Template: 'N',
          t4Template: 'N',
          collaborateSubjectId: 0,
          classLabelItalic: 'N',
          tab5Text: '',
          lessonUnitStandards: [],
          customEnd: '',
          lessonLock: 'N',
          googleAssessments: [],
          lessonText: '',
          ssTemplate: 'N',
          tab6Text: '',
          standards: [],
          lessonTitle: '',
          withMathExpressions: 'N',
          notesText: '',
          attachments: [],
          color: '#603f99',
          asgAttachments: [],
          classLabelBold: 'N',
          extraLesson: 0,
          className: 'D1',
          titleSize: '10',
          googleAssignments: [],
          classId: 12347992,
          asmAttachments: [],
          isAddClassDays: false,
          titleColor: '#FFFFFF',
          schoolStandards: [],
          msTemplate: 'N',
          unitId: 0,
          startTime: '12:50 PM',
          classYearId: 1197353,
          t5Template: 'N',
          sTemplate: 'N',
          hTemplate: 'N',
          assessments: [],
          addClassDaysCode: '',
          strategies: [],
          myStandards: [],
          collaborateType: 0,
          addendums: [],
          titleFont: '2',
          lTemplate: 'N',
          teacherId: 1245979,
          nTemplate: 'N',
          endTime: '2:10 PM'
        },
        {
          assignments: [],
          classLabelUnderline: 'N',
          tab4Text: '',
          unitNum: '',
          type: 'L',
          unitTitle: '',
          homeworkText: "<p>Complete questions and hand in&nbsp;</p>\n<p>Complete file as I will mark them between lessons next week / if don't need them at home I will take this weekend&nbsp;</p>",
          customStart: '',
          t6Template: 'N',
          t4Template: 'N',
          collaborateSubjectId: 0,
          classLabelItalic: 'N',
          tab5Text: '',
          lessonUnitStandards: [],
          customEnd: '',
          lessonLock: 'N',
          googleAssessments: [],
          lessonText: '<p>Key word game&nbsp;</p>\n<p>&nbsp;</p>\n<p>Check everyone has everything needed&nbsp;</p>\n<p>&nbsp;</p>\n<p>Exam Questions&nbsp;</p>\n<p>June 2017 paper Q2 ci and cii&nbsp; di and dii&nbsp; and 4 10*</p>\n<p>H155/02 Sample 2a bi&nbsp; bii&nbsp; c&nbsp; d</p>\n<p>&nbsp;</p>\n<p>Go through the exam questions - let them fill them in on own in one colour then add to it in another colour&nbsp;</p>\n<p>Each time highlight - command - topic - focus&nbsp;</p>\n<p>&nbsp;</p>\n<p>Go through together the 10 mark question to make a plan then let them complete it at home&nbsp;</p>\n<p>&nbsp;</p>\n<p>&nbsp;</p>\n<p>&nbsp;</p>\n<p>&nbsp;</p>\n<p>&nbsp;</p>',
          ssTemplate: 'N',
          tab6Text: '',
          standards: [],
          lessonTitle: '',
          withMathExpressions: 'N',
          notesText: '',
          attachments: [],
          color: '#ff99cc',
          asgAttachments: [],
          classLabelBold: 'N',
          extraLesson: 0,
          className: 'AS  P E ',
          titleSize: '10',
          googleAssignments: [],
          classId: 12347991,
          asmAttachments: [],
          isAddClassDays: false,
          titleColor: '#FFFFFF',
          schoolStandards: [],
          msTemplate: 'N',
          unitId: 0,
          startTime: '2:10 PM',
          classYearId: 1197353,
          t5Template: 'N',
          sTemplate: 'N',
          hTemplate: 'N',
          assessments: [],
          addClassDaysCode: '',
          strategies: [],
          myStandards: [],
          collaborateType: 0,
          addendums: [],
          lessonId: 550964047,
          titleFont: '2',
          lTemplate: 'N',
          teacherId: 1245979,
          nTemplate: 'N',
          endTime: '3:30 PM'
        }
      ],
      count: 111
    },
    {
      date: '02/08/2019',
      cD: 'Week 1',
      dayOfWeek: 'Friday',
      objects: [
        {
          assignments: [],
          classLabelUnderline: 'N',
          tab4Text: '',
          unitNum: '',
          type: 'L',
          unitTitle: '',
          homeworkText: '',
          customStart: '',
          t6Template: 'N',
          t4Template: 'N',
          collaborateSubjectId: 0,
          classLabelItalic: 'N',
          tab5Text: '',
          lessonUnitStandards: [],
          customEnd: '',
          lessonLock: 'N',
          googleAssessments: [],
          lessonText: '',
          ssTemplate: 'N',
          tab6Text: '',
          standards: [],
          lessonTitle: '',
          withMathExpressions: 'N',
          notesText: '',
          attachments: [],
          color: '#cc99ff',
          asgAttachments: [],
          classLabelBold: 'N',
          extraLesson: 0,
          className: 'Jobs ',
          titleSize: '10',
          googleAssignments: [],
          classId: 12347993,
          asmAttachments: [],
          isAddClassDays: false,
          titleColor: '#FFFFFF',
          schoolStandards: [],
          msTemplate: 'N',
          unitId: 0,
          startTime: '8:00 AM',
          classYearId: 1197353,
          t5Template: 'N',
          sTemplate: 'N',
          hTemplate: 'N',
          assessments: [],
          addClassDaysCode: '',
          strategies: [],
          myStandards: [],
          collaborateType: 0,
          addendums: [],
          titleFont: '2',
          lTemplate: 'N',
          teacherId: 1245979,
          nTemplate: 'N',
          endTime: '8:30 AM'
        },
        {
          assignments: [],
          classLabelUnderline: 'N',
          tab4Text: '',
          unitNum: '',
          type: 'L',
          unitTitle: '',
          homeworkText: '',
          customStart: '',
          t6Template: 'N',
          t4Template: 'N',
          collaborateSubjectId: 0,
          classLabelItalic: 'N',
          tab5Text: '',
          lessonUnitStandards: [],
          customEnd: '',
          lessonLock: 'N',
          googleAssessments: [],
          lessonText: '<p>Numbers game, call them into the middle and those numbers play, they can use the people on the outside to support</p><p>Round the clock</p>',
          ssTemplate: 'N',
          tab6Text: '',
          standards: [],
          lessonTitle: 'Football',
          withMathExpressions: 'N',
          notesText: '',
          attachments: [],
          color: '#f2a640',
          asgAttachments: [],
          classLabelBold: 'N',
          extraLesson: 0,
          className: 'L4e',
          titleSize: '10',
          googleAssignments: [],
          classId: 12347995,
          asmAttachments: [],
          isAddClassDays: false,
          titleColor: '#FFFFFF',
          schoolStandards: [],
          msTemplate: 'N',
          unitId: 0,
          startTime: '9:30 AM',
          classYearId: 1197353,
          t5Template: 'N',
          sTemplate: 'N',
          hTemplate: 'N',
          assessments: [],
          addClassDaysCode: '',
          strategies: [],
          myStandards: [],
          collaborateType: 0,
          addendums: [],
          lessonId: 550964133,
          titleFont: '2',
          lTemplate: 'N',
          teacherId: 1245979,
          nTemplate: 'N',
          endTime: '10:10 AM'
        },
        {
          assignments: [],
          classLabelUnderline: 'N',
          tab4Text: '',
          unitNum: '',
          type: 'L',
          unitTitle: '',
          homeworkText: '',
          customStart: '',
          t6Template: 'N',
          t4Template: 'N',
          collaborateSubjectId: 0,
          classLabelItalic: 'N',
          tab5Text: '',
          lessonUnitStandards: [],
          customEnd: '',
          lessonLock: 'N',
          googleAssessments: [],
          lessonText: "<p>&nbsp;</p>\n<p>Video about the eat well plate - take notes as they go&nbsp;</p>\n<p>Do this on scrap paper then allow time to write up in neat on their plate in their book&nbsp;</p>\n<p>Videos:</p>\n<p>British nutrition - What is the eat well guide&nbsp;</p>\n<p>British nutrition - Fruit and vegetables The eat well guide&nbsp;</p>\n<p>&nbsp;</p>\n<p>Get them to draw their meals on the next page - use the different plates (DON'T LIE)&nbsp;</p>\n<p>Colour the food - to match the real colour&nbsp;</p>\n<p>&nbsp;</p>\n<p>Video:</p>\n<p>Fruit and veggies for kids/Vegetable and Fruit song/Eat your rainbow&nbsp;</p>\n<p>Use the inform to make some notes on the different colours and why they are important.&nbsp;</p>\n<p>&nbsp;</p>\n<p>&nbsp;PRINT - MULLINS USB - EAT THE RAINBOW</p>",
          ssTemplate: 'N',
          tab6Text: '',
          standards: [],
          lessonTitle: 'Healthy Eating',
          withMathExpressions: 'N',
          notesText: '',
          attachments: [],
          color: '#e67399',
          asgAttachments: [],
          classLabelBold: 'N',
          extraLesson: 0,
          className: 'U3w PSHE',
          titleSize: '10',
          googleAssignments: [],
          classId: 12348005,
          asmAttachments: [],
          isAddClassDays: false,
          titleColor: '#FFFFFF',
          schoolStandards: [],
          msTemplate: 'N',
          unitId: 0,
          startTime: '10:25 AM',
          classYearId: 1197353,
          t5Template: 'N',
          sTemplate: 'N',
          hTemplate: 'N',
          assessments: [],
          addClassDaysCode: '',
          strategies: [],
          myStandards: [],
          collaborateType: 0,
          addendums: [],
          lessonId: 550964269,
          titleFont: '2',
          lTemplate: 'N',
          teacherId: 1245979,
          nTemplate: 'N',
          endTime: '11:05 AM'
        },
        {
          assignments: [],
          classLabelUnderline: 'N',
          tab4Text: '',
          unitNum: '',
          type: 'L',
          unitTitle: '',
          homeworkText: '',
          customStart: '',
          t6Template: 'N',
          t4Template: 'N',
          collaborateSubjectId: 0,
          classLabelItalic: 'N',
          tab5Text: '',
          lessonUnitStandards: [],
          customEnd: '',
          lessonLock: 'N',
          googleAssessments: [],
          lessonText: '<p>Finish discoveries - if already done come up with ideas/fill in skills sheets at the back of their book&nbsp;</p>\n<p>&nbsp;</p>\n<p>Drugs - see KN email&nbsp;</p>\n<p>Definition and discuss&nbsp;</p>\n<p>List as many as they can&nbsp;</p>\n<p>Classify them&nbsp;</p>\n<p>&nbsp;</p>',
          ssTemplate: 'N',
          tab6Text: '',
          standards: [],
          lessonTitle: 'Mop up and Drugs',
          withMathExpressions: 'N',
          notesText: '',
          attachments: [],
          color: '#9ccd0a',
          asgAttachments: [],
          classLabelBold: 'N',
          extraLesson: 0,
          className: 'L4w PSHE',
          titleSize: '10',
          googleAssignments: [],
          classId: 12347996,
          asmAttachments: [],
          isAddClassDays: false,
          titleColor: '#FFFFFF',
          schoolStandards: [],
          msTemplate: 'N',
          unitId: 0,
          startTime: '11:05 AM',
          classYearId: 1197353,
          t5Template: 'N',
          sTemplate: 'N',
          hTemplate: 'N',
          assessments: [],
          addClassDaysCode: '',
          strategies: [],
          myStandards: [],
          collaborateType: 0,
          addendums: [],
          lessonId: 550964147,
          titleFont: '2',
          lTemplate: 'N',
          teacherId: 1245979,
          nTemplate: 'N',
          endTime: '11:45 AM'
        }
      ],
      count: 112
    },
    {
      date: '02/09/2019',
      cD: 'Week 1',
      dayOfWeek: 'Saturday',
      objects: [],
      count: -1
    }
  ],
  events: [],
  hasAssessments: {
    12347991: false,
    12347992: false,
    12347993: false,
    12347994: false,
    12347995: false,
    12347996: false,
    12347997: false,
    12347998: false,
    12347999: false,
    12348000: false,
    12348001: false,
    12348002: false,
    12348003: false,
    12348004: false,
    12348005: false,
    12348006: false,
    12348007: false,
    12348008: false,
    12348009: false
  }
};

export const dayObject = {
  name: 'AS P E',
  time: '8:50am-10:10am',
  labelOrder: 'nameFirst',
  showLock: 'Y',
  //        showGoogle : 'Y',
  style: {
    textAlign: 'left',
    color: 'white',
    backgroundColor: '#99ccff'
  },
  color: '#99ccff',
  isLocked: 'N',
  sections: [
    {
      type: 'section-statuses',
      hideItemName: 'Y',
      // filterItems : [1],
      items: [
        //                    {
        //                        id : 1,
        //                        name : 'Ready',
        //                        color : 'orange',
        //                    },
        //                    {
        //                        id : 2,
        //                        name : 'Done',
        //                        color : 'red',
        //                    },
        //                    {
        //                        id : 3,
        //                        name : 'Draft',
        //                        color : 'green',
        //                    }
      ]
    },
    {
      type: 'section-title',
      label: 'Unit 5',
      text: 'Group and Team'
    },
    {
      type: 'section-text',
      label: 'Lesson',
      text: 'Lesson Details',
      labelStyle: {}
    },
    {
      type: 'section-text',
      label: 'Homework',
      text: 'Homework Details',
      labelStyle: {},
      textItems: [{
        text: 'Homework Details'
      }]
    },
    {
      type: 'section-text',
      label: 'Notes',
      text: 'Notes Details',
      labelStyle: {}
    },
    {
      type: 'section-text',
      label: 'Section 4',
      text: 'Section 4 Details',
      labelStyle: {}
    },
    {
      type: 'section-text',
      label: 'Section 5',
      text: 'Section 5 Details',
      labelStyle: {}
    },
    {
      type: 'section-text',
      label: 'Section 6',
      text: 'Section 6 Details',
      labelStyle: {}
    },
    {
      type: 'section-standards',
      label: 'My List',
      items: [
        {
          id: '1.1',
          name: 'My List 1.1'

        },
        {
          id: '1.2',
          name: 'My List 1.2'
        }
      ]
    },
    {
      type: 'section-standards',
      label: 'School List',
      items: [
        {
          id: '1.1',
          name: 'School List 1.1'

        },
        {
          id: '1.2',
          name: 'School List 1.2'
        }
      ]
    },
    {
      type: 'section-standards',
      label: 'Standards',
      items: [
        {
          id: 'BPA.AC.1',
          name: 'Actively uses and expresses content'

        },
        {
          id: 'BPA.AC.2',
          name: 'Learning engages head, hands and heart'
        }
      ]
    },
    {
      type: 'section-standards',
      label: 'Unit Standards',
      items: [
        {
          id: 'U.BPA.AC.1',
          name: 'Actively uses and expresses content'

        },
        {
          id: 'U.BPA.AC.2',
          name: 'Learning engages head, hands and heart'
        }
      ]
    },
    {
      type: 'section-attachments',
      label: 'Attachments',
      labelStyle: {},
      textStyle: {},
      items: [
        { name: 'How Your Brain Works? - The Dr. Binocs Show | Best Learning Videos For Kids | Peekaboo Kidz', url: 'https://www.google.com' },
        { name: '10 Easy Science Experiments - That Will Amaze Kids', url: 'https://www.google.com' }
      ]
    },
    {
      type: 'section-actions',
      label: 'Assignments',
      items: [
        {
          id: '1',
          name: 'This is an assignment 1'

        },
        {
          id: '2',
          name: 'This is an assignment 2'

        }
      ]
    }

  ]
};

export const dayObject2 = {
  userMode: 'T',
  showActions: true,
  type: 'L',
  sections: [
    {
      section: 'statuses',
      type: 'section-statuses',
      displayOrder: '0',
      hide: 'N',
      items: [
        {
          name: 'Done',
          color: '#CCFFCC'
        },
        {
          name: 'Good',
          color: '#BE9494'
        },
        {
          name: 'Ready Now',
          color: '#4620FF'
        },
        {
          name: 'Complete',
          color: '#E0C240'
        },
        {
          name: 'New Status',
          color: '#668CD9'
        }
      ]
    },
    {
      section: 'lessonTitle',
      hide: 'N',
      type: 'section-title',
      textStyle: {
        backgroundColor: '#ffffff',
        color: '#000000',
        fontFamily: 'arial,helvetica,sans-serif',
        textDecoration: '',
        fontStyle: 'normal',
        fontSize: '10pt',
        fontWeight: 'bold',
        borderColor: '#d1bc36'
      },
      displayOrder: 0,
      subDisplayOrder: 0,
      items: [
        {
          dataType: 'lesson',
          text: 'Lesson Title'
        },
        {
          dataType: 'linkedLesson',
          text: 'Linked Lesson Title'
        },
        {
          dataType: 'unit',
          label: 'U1',
          text: 'Lesson Unit'
        },
        {
          dataType: 'linkedUnit',
          label: 'LU1',
          text: 'Linked Unit'
        }
      ]
    },
    {
      section: 'sharedStandards',
      displayOrder: 1,
      hide: 'N',
      hideLabel: 'N',
      type: 'section-standards',
      label: 'Standards',
      labelStyle: {
        backgroundColor: '#ffffff',
        color: '#000000',
        fontFamily: 'arial,helvetica,sans-serif',
        textDecoration: '',
        fontStyle: 'normal',
        fontSize: '10pt',
        fontWeight: 'bold'
      },
      textStyle: {
        backgroundColor: '#ffffff',
        color: '#000000',
        fontFamily: 'arial,helvetica,sans-serif',
        textDecoration: '',
        fontStyle: 'normal',
        fontSize: '10pt',
        fontWeight: 'normal'
      },
      items: [
        {
          dataType: 'lesson',
          name: 'I explore my interests and passions while making personal connections to career possibilities.',
          id: '1'
        },
        {
          dataType: 'lesson',
          name: 'I use occupational area skills, knowledge and technologies.',
          id: '2'
        },
        {
          dataType: 'lesson',
          name: 'I follow safety requirements associated with occupational areas and related technologies.',
          id: '3'
        },
        {
          dataType: 'lesson',
          name: 'I demonstrate environmental stewardship associated with occupational areas.',
          id: '4'
        },
        {
          dataType: 'linkedLesson',
          name: 'I explore my interests and passions while making personal connections to career possibilities.',
          id: '1'
        },
        {
          dataType: 'unit',
          name: 'I collaborate to achieve common goals.',
          id: '14'
        },
        {
          dataType: 'linkedUnit',
          name: 'I use occupational area skills, knowledge and technologies.',
          id: '2'
        }
      ]
    },
    {
      section: 'unitStandards',
      displayOrder: 1,
      hide: 'N',
      hideLabel: 'N',
      type: 'section-standards',
      label: 'Standards',
      labelStyle: {
        backgroundColor: '#ffffff',
        color: '#000000',
        fontFamily: 'arial,helvetica,sans-serif',
        textDecoration: '',
        fontStyle: 'normal',
        fontSize: '10pt',
        fontWeight: 'bold'
      },
      textStyle: {
        backgroundColor: '#ffffff',
        color: '#000000',
        fontFamily: 'arial,helvetica,sans-serif',
        textDecoration: '',
        fontStyle: 'normal',
        fontSize: '10pt',
        fontWeight: 'normal'
      },
      subDisplayOrder: 1
    },
    {
      section: 'tab2',
      displayOrder: 2,
      hide: 'N',
      hideLabel: 'N',
      type: 'section-text',
      label: 'Objectives',
      labelStyle: {
        backgroundColor: '#ffffff',
        color: '#000000',
        fontFamily: 'arial,helvetica,sans-serif',
        textDecoration: '',
        fontStyle: 'normal',
        fontSize: '10pt',
        fontWeight: 'bold'
      },
      textStyle: {
        backgroundColor: '#ffff99',
        color: '#000000',
        fontFamily: 'comic sans ms,sans-serif',
        textDecoration: '',
        fontStyle: 'normal',
        fontSize: '10pt',
        fontWeight: 'bold'
      },
      items: [
        {
          dataType: 'lesson',
          text: '<p>Lesson Objectives</p>'
        },
        {
          dataType: 'linkedLesson',
          text: '<p>Linked Lesson Objectives</p>'
        },
        {
          dataType: 'unit',
          text: '<p>Lesson Unit Objectives</p>'
        },
        {
          dataType: 'linkedUnit',
          text: '<p>Linked Unit Objectives</p>'
        }
      ]
    },
    {
      section: 'tab3',
      displayOrder: 3,
      hide: 'N',
      hideLabel: 'N',
      type: 'section-text',
      label: 'Vocabulary',
      labelStyle: {
        backgroundColor: '#ffffff',
        color: '#000000',
        fontFamily: 'arial,helvetica,sans-serif',
        textDecoration: '',
        fontStyle: 'normal',
        fontSize: '10pt',
        fontWeight: 'bold'
      },
      textStyle: {
        backgroundColor: '#ffffff',
        color: '#000000',
        fontFamily: 'arial,helvetica,sans-serif',
        textDecoration: '',
        fontStyle: 'normal',
        fontSize: '10pt',
        fontWeight: 'normal'
      },
      items: [
        {
          dataType: 'lesson',
          text: '<p>Lesson Vocab</p>'
        },
        {
          dataType: 'linkedLesson',
          text: '<p>Linked Lesson Vocab</p>'
        },
        {
          dataType: 'unit',
          text: '<p>Lesson Unit Vocab</p>'
        },
        {
          dataType: 'linkedUnit',
          text: '<p>Linked Unit Vocab</p>'
        }
      ]
    },
    {
      section: 'tab4',
      displayOrder: 4,
      hide: 'N',
      hideLabel: 'N',
      type: 'section-text',
      label: 'Lesson',
      labelStyle: {
        backgroundColor: '#ffffff',
        color: '#000000',
        fontFamily: 'arial,helvetica,sans-serif',
        textDecoration: '',
        fontStyle: 'normal',
        fontSize: '10pt',
        fontWeight: 'bold'
      },
      textStyle: {
        backgroundColor: '#ffffff',
        color: '#000000',
        fontFamily: 'arial,helvetica,sans-serif',
        textDecoration: '',
        fontStyle: 'normal',
        fontSize: '10pt',
        fontWeight: 'normal'
      },
      items: [
        {
          dataType: 'lesson',
          text: '<p>Lesson Lesson</p>'
        },
        {
          dataType: 'linkedLesson',
          text: '<p>Linked Lesson Lesson</p>'
        },
        {
          dataType: 'unit',
          text: '<p>Lesson Unit Lesson</p>'
        },
        {
          dataType: 'linkedUnit',
          text: '<p>Linked Unit Lesson</p>'
        }
      ]
    },
    {
      section: 'lesson',
      displayOrder: 5,
      hide: 'N',
      hideLabel: 'Y',
      type: 'section-text',
      label: 'Assessment',
      labelStyle: {
        backgroundColor: '#ffffff',
        color: '#000000',
        fontFamily: 'arial,helvetica,sans-serif',
        textDecoration: '',
        fontStyle: 'normal',
        fontSize: '10pt',
        fontWeight: 'bold'
      },
      textStyle: {
        backgroundColor: '#ffffff',
        color: '#000000',
        fontFamily: 'arial,helvetica,sans-serif',
        textDecoration: '',
        fontStyle: 'normal',
        fontSize: '10pt',
        fontWeight: 'normal'
      },
      items: [
        {
          dataType: 'lesson',
          text: '<p>Lesson Assessment</p>'
        },
        {
          dataType: 'linkedLesson',
          text: '<p>Linked Lesson Assessment</p>'
        },
        {
          dataType: 'unit',
          text: '<p>Lesson Unit Assessment</p>'
        },
        {
          dataType: 'linkedUnit',
          text: '<p>Linked Unit Assessment</p>'
        }
      ]
    },
    {
      section: 'tab5',
      displayOrder: 6,
      hide: 'N',
      hideLabel: 'N',
      type: 'section-text',
      label: 'Intervention - Students',
      labelStyle: {
        backgroundColor: '#ffffff',
        color: '#000000',
        fontFamily: 'arial,helvetica,sans-serif',
        textDecoration: '',
        fontStyle: 'normal',
        fontSize: '10pt',
        fontWeight: 'bold'
      },
      textStyle: {
        backgroundColor: '#ffffff',
        color: '#000000',
        fontFamily: 'arial,helvetica,sans-serif',
        textDecoration: '',
        fontStyle: 'normal',
        fontSize: '10pt',
        fontWeight: 'normal'
      },
      items: [
        {
          dataType: 'lesson',
          text: '<p>Lesson Intervention - Students</p>'
        },
        {
          dataType: 'linkedLesson',
          text: '<p>Linked Lesson Intervention - Students&nbsp;</p>'
        },
        {
          dataType: 'unit',
          text: '<p>Lesson Unit Intervention - Students</p>'
        },
        {
          dataType: 'linkedUnit',
          text: '<p>Linked Unit Intervention - Students</p>'
        }
      ]
    },
    {
      section: 'tab6',
      displayOrder: 7,
      hide: 'N',
      hideLabel: 'N',
      type: 'section-text',
      label: 'Interventions',
      labelStyle: {
        backgroundColor: '#ffffff',
        color: '#000000',
        fontFamily: 'arial,helvetica,sans-serif',
        textDecoration: '',
        fontStyle: 'normal',
        fontSize: '10pt',
        fontWeight: 'bold'
      },
      textStyle: {
        backgroundColor: '#ffffff',
        color: '#000000',
        fontFamily: 'arial,helvetica,sans-serif',
        textDecoration: '',
        fontStyle: 'normal',
        fontSize: '10pt',
        fontWeight: 'normal'
      },
      items: [
        {
          dataType: 'lesson',
          text: '<p>Lesson Interventions</p>'
        },
        {
          dataType: 'linkedLesson',
          text: '<p>Linked Lesson Interventions</p>'
        },
        {
          dataType: 'unit',
          text: '<p>Lesson Unit Interventions</p>'
        },
        {
          dataType: 'linkedUnit',
          text: '<p>Linked Unit Intervention</p>'
        }
      ]
    },
    {
      section: 'strategies',
      displayOrder: 8,
      hide: 'N',
      hideLabel: 'N',
      type: 'section-standards',
      label: 'Strategies',
      labelStyle: {
        backgroundColor: '#ffffff',
        color: '#000000',
        fontFamily: 'arial,helvetica,sans-serif',
        textDecoration: '',
        fontStyle: 'normal',
        fontSize: '10pt',
        fontWeight: 'bold'
      },
      textStyle: {
        backgroundColor: '#ffffff',
        color: '#000000',
        fontFamily: 'arial,helvetica,sans-serif',
        textDecoration: '',
        fontStyle: 'normal',
        fontSize: '10pt',
        fontWeight: 'normal'
      },
      items: [
        {
          dataType: 'lesson',
          name: 'Processes/Routines that embrace student differences and strengths',
          id: 'BRC.1'
        },
        {
          dataType: 'lesson',
          name: 'Class meetings ',
          id: 'BRC.2'
        },
        {
          dataType: 'linkedLesson',
          name: 'Super Scientist Awards',
          id: 'FM.1'
        },
        {
          dataType: 'unit',
          name: 'Think-Pair-Share',
          id: 'COL.ST'
        },
        {
          dataType: 'linkedUnit',
          name: 'Persisting ',
          id: '1'
        }
      ]
    },
    {
      section: 'myStandards',
      displayOrder: 9,
      hide: 'N',
      hideLabel: 'N',
      type: 'section-standards',
      label: 'My List',
      labelStyle: {
        backgroundColor: '#ffffff',
        color: '#000000',
        fontFamily: 'arial,helvetica,sans-serif',
        textDecoration: '',
        fontStyle: 'normal',
        fontSize: '10pt',
        fontWeight: 'bold'
      },
      textStyle: {
        backgroundColor: '#ffffff',
        color: '#000000',
        fontFamily: 'arial,helvetica,sans-serif',
        textDecoration: '',
        fontStyle: 'normal',
        fontSize: '10pt',
        fontWeight: 'normal'
      },
      items: [
        {
          dataType: 'lesson',
          name: '<p>test</p>',
          id: 'test'
        },
        {
          dataType: 'linkedLesson',
          name: '<p>x</p>',
          id: 'x'
        },
        {
          dataType: 'unit',
          name: '<p>test</p>',
          id: 'test'
        },
        {
          dataType: 'linkedUnit',
          name: '<p><span style="font-size: 13pt;"><em><strong>testing my list</strong></em></span></p>',
          id: 'test 123'
        }
      ]
    },
    {
      section: 'schoolStandards',
      displayOrder: 10,
      hide: 'N',
      hideLabel: 'N',
      type: 'section-standards',
      label: 'School List',
      labelStyle: {
        backgroundColor: '#ffffff',
        color: '#000000',
        fontFamily: 'arial,helvetica,sans-serif',
        textDecoration: '',
        fontStyle: 'normal',
        fontSize: '10pt',
        fontWeight: 'bold'
      },
      textStyle: {
        backgroundColor: '#ffffff',
        color: '#000000',
        fontFamily: 'arial,helvetica,sans-serif',
        textDecoration: '',
        fontStyle: 'normal',
        fontSize: '10pt',
        fontWeight: 'normal'
      },
      items: [
        {
          dataType: 'lesson',
          name: 'Testing',
          id: '111'
        },
        {
          dataType: 'linkedLesson',
          name: 'School Item 2',
          id: 'SI.2'
        },
        {
          dataType: 'unit',
          name: 'differention integration',
          id: '70513'
        },
        {
          dataType: 'linkedUnit',
          name: 'differention integration',
          id: '70513'
        }
      ]
    },
    {
      section: 'attachments',
      hide: 'N',
      hideLabel: 'N',
      type: 'section-attachments',
      label: 'Attachments',
      labelStyle: {
        backgroundColor: '#ffffff',
        color: '#000000',
        fontFamily: 'arial,helvetica,sans-serif',
        textDecoration: '',
        fontStyle: 'normal',
        fontSize: '10pt',
        fontWeight: 'bold'
      },
      textStyle: {
        backgroundColor: '#ffffff',
        color: '#000000',
        fontFamily: 'arial,helvetica,sans-serif',
        textDecoration: '',
        fontStyle: 'normal',
        fontSize: '10pt',
        fontWeight: 'normal'
      },
      displayOrder: 14,
      items: [
        {
          dataType: 'lesson',
          name: 'app-debug-6.apk',
          url: 'https://s3.amazonaws.com/PlanbookAttachments/1245979/app-debug-6.apk?AWSAccessKeyId=AKIAIBQXWGUYOJ6RIHHA&Expires=1902509552&Signature=GQvzMDlF7avFiu17sPnzaqy09iU%3D'
        },
        {
          dataType: 'linkedLesson',
          name: 'app-release-(1).apk',
          url: 'https://s3.amazonaws.com/PlanbookAttachments/1245979/app-release-%281%29.apk?AWSAccessKeyId=AKIAIBQXWGUYOJ6RIHHA&Expires=1902509647&Signature=JA9ahIWp7BdkRbu87VIXebvXRhY%3D'
        },
        {
          dataType: 'unit',
          name: 'A1772A37-E8B7-4E4C-AE01-290073EC08D3.png'
        },
        {
          dataType: 'linkedUnit',
          name: 'app-release-(1).apk'
        }
      ]
    },
    {
      section: 'googleAttachments',
      hide: 'N',
      hideLabel: 'N',
      type: 'section-attachments',
      label: 'Google Attachments',
      labelStyle: {
        backgroundColor: '#ffffff',
        color: '#000000',
        fontFamily: 'arial,helvetica,sans-serif',
        textDecoration: '',
        fontStyle: 'normal',
        fontSize: '10pt',
        fontWeight: 'bold'
      },
      textStyle: {
        backgroundColor: '#ffffff',
        color: '#000000',
        fontFamily: 'arial,helvetica,sans-serif',
        textDecoration: '',
        fontStyle: 'normal',
        fontSize: '10pt',
        fontWeight: 'normal'
      },
      displayOrder: 14,
      subDisplayOrder: 1
    },
    {
      section: 'assignments',
      hide: 'N',
      hideLabel: 'N',
      type: 'section-actions',
      label: 'Assignments',
      labelStyle: {
        backgroundColor: '#ffffff',
        color: '#000000',
        fontFamily: 'arial,helvetica,sans-serif',
        textDecoration: '',
        fontStyle: 'normal',
        fontSize: '10pt',
        fontWeight: 'bold'
      },
      textStyle: {
        backgroundColor: '#ffffff',
        color: '#000000',
        fontFamily: 'arial,helvetica,sans-serif',
        textDecoration: '',
        fontStyle: 'normal',
        fontSize: '10pt',
        fontWeight: 'normal'
      },
      displayOrder: 16,
      items: []
    },
    {
      section: 'googleAssignments',
      hide: 'N',
      hideLabel: 'N',
      type: 'section-actions',
      label: 'Google Assignments',
      labelStyle: {
        backgroundColor: '#ffffff',
        color: '#000000',
        fontFamily: 'arial,helvetica,sans-serif',
        textDecoration: '',
        fontStyle: 'normal',
        fontSize: '10pt',
        fontWeight: 'bold'
      },
      textStyle: {
        backgroundColor: '#ffffff',
        color: '#000000',
        fontFamily: 'arial,helvetica,sans-serif',
        textDecoration: '',
        fontStyle: 'normal',
        fontSize: '10pt',
        fontWeight: 'normal'
      },
      displayOrder: 16,
      subDisplayOrder: 1
    },
    {
      section: 'assessments',
      hide: 'N',
      hideLabel: 'N',
      type: 'section-actions',
      label: 'Assessments',
      labelStyle: {
        backgroundColor: '#ffffff',
        color: '#000000',
        fontFamily: 'arial,helvetica,sans-serif',
        textDecoration: '',
        fontStyle: 'normal',
        fontSize: '10pt',
        fontWeight: 'bold'
      },
      textStyle: {
        backgroundColor: '#ffffff',
        color: '#000000',
        fontFamily: 'arial,helvetica,sans-serif',
        textDecoration: '',
        fontStyle: 'normal',
        fontSize: '10pt',
        fontWeight: 'normal'
      },
      displayOrder: 18,
      items: []
    },
    {
      section: 'googleAssessments',
      hide: 'N',
      hideLabel: 'N',
      type: 'section-actions',
      label: 'Google Assessments',
      labelStyle: {
        backgroundColor: '#ffffff',
        color: '#000000',
        fontFamily: 'arial,helvetica,sans-serif',
        textDecoration: '',
        fontStyle: 'normal',
        fontSize: '10pt',
        fontWeight: 'bold'
      },
      textStyle: {
        backgroundColor: '#ffffff',
        color: '#000000',
        fontFamily: 'arial,helvetica,sans-serif',
        textDecoration: '',
        fontStyle: 'normal',
        fontSize: '10pt',
        fontWeight: 'normal'
      },
      displayOrder: 18,
      subDisplayOrder: 1
    }
  ],
  date: '04/17/2020',
  extraLesson: 0,
  name: 'Breakfast',
  time: '7:35 AM-8:10 AM',
  color: '#d1bc36',
  showLock: 'N',
  style: {
    backgroundColor: '#d1bc36',
    borderBottomColor: '#d1bc36',
    color: '#ffffff',
    textAlign: 'left',
    fontFamily: 'arial,helvetica,sans-serif',
    fontSize: '10pt',
    textDecoration: '',
    fontWeight: 'normal',
    fontStyle: 'normal'
  },
  rawDayObject: {
    assignments: [],
    classLabelUnderline: 'N',
    tab4Text: '<p>Lesson Lesson</p>',
    unitNum: 'U1',
    linkedLessonTitle: 'Linked Lesson Title',
    linkedTab4Text: '<p>Linked Lesson Lesson</p>',
    type: 'L',
    unitTitle: 'Lesson Unit',
    homeworkText: '<p>Lesson Objectives</p>',
    customStart: '',
    t6Template: 'N',
    t4Template: 'N',
    linkedTab5Text: '<p>Linked Lesson Intervention - Students&nbsp;</p>',
    collaborateSubjectId: 0,
    classLabelItalic: 'N',
    tab5Text: '<p>Lesson Intervention - Students</p>',
    lessonUnitStandards: [],
    customEnd: '',
    lessonLock: 'N',
    googleAssessments: [],
    linkedSchoolId: 0,
    lessonText: '<p>Lesson Assessment</p>',
    ssTemplate: 'N',
    linkedTab6Text: '<p>Linked Lesson Interventions</p>',
    tab6Text: '<p>Lesson Interventions</p>',
    standards: [
      {
        dbId: 926163,
        id: '1',
        withMathExpressions: 'N',
        desc: 'I explore my interests and passions while making personal connections to career possibilities.'
      },
      {
        dbId: 926164,
        id: '2',
        withMathExpressions: 'N',
        desc: 'I use occupational area skills, knowledge and technologies.'
      },
      {
        dbId: 926165,
        id: '3',
        withMathExpressions: 'N',
        desc: 'I follow safety requirements associated with occupational areas and related technologies.'
      },
      {
        dbId: 926166,
        id: '4',
        withMathExpressions: 'N',
        desc: 'I demonstrate environmental stewardship associated with occupational areas.'
      }
    ],
    linkedUnit: {
      assessments: [],
      assignments: [],
      attachments: [
        {
          privateFlag: false,
          filename: 'app-release-(1).apk',
          attachmentUrl: 'https://s3.amazonaws.com/PlanbookAttachments/1245979/app-release-%281%29.apk?AWSAccessKeyId=AKIAIBQXWGUYOJ6RIHHA&Expires=1902580893&Signature=iKXVOKJ1Ar6tJtR1%2BqRFnluxfNo%3D'
        }
      ],
      myStandards: [
        {
          dbId: 247112,
          id: 'test 123',
          withMathExpressions: 'N',
          desc: '<p><span style="font-size: 13pt;"><em><strong>testing my list</strong></em></span></p>'
        }
      ],
      strategies: [
        {
          code: '1',
          unitId: 856192,
          strategyId: 188488,
          unitStrategyId: 264,
          title: 'Persisting '
        }
      ],
      unitNum: 'LU1',
      unitTitle: 'Linked Unit',
      subjectId: 15195977,
      unitStart: '',
      standards: [
        {
          dbId: 926164,
          id: '2',
          withMathExpressions: 'N',
          desc: 'I use occupational area skills, knowledge and technologies.'
        }
      ],
      unitDesc: '',
      contents: [
        {
          section: 'L',
          id: 938,
          value: '<p>Linked Unit Assessment</p>',
          parentId: 856192
        },
        {
          section: 'H',
          id: 939,
          value: '<p>Linked Unit Objectives</p>',
          parentId: 856192
        },
        {
          section: 'N',
          id: 940,
          value: '<p>Linked Unit Vocab</p>',
          parentId: 856192
        },
        {
          section: '4',
          id: 941,
          value: '<p>Linked Unit Lesson</p>',
          parentId: 856192
        },
        {
          section: '5',
          id: 942,
          value: '<p>Linked Unit Intervention - Students</p>',
          parentId: 856192
        },
        {
          section: '6',
          id: 943,
          value: '<p>Linked Unit Intervention</p>',
          parentId: 856192
        }
      ],
      schoolStandards: [
        {
          dbId: 245222,
          id: '70513',
          withMathExpressions: 'N',
          desc: 'differention integration'
        }
      ],
      unitId: 856192,
      unitEnd: ''
    },
    unit: {
      assessments: [],
      assignments: [],
      attachments: [
        {
          privateFlag: false,
          filename: 'A1772A37-E8B7-4E4C-AE01-290073EC08D3.png',
          attachmentUrl: 'https://s3.amazonaws.com/PlanbookAttachments/1245979/A1772A37-E8B7-4E4C-AE01-290073EC08D3.png?AWSAccessKeyId=AKIAIBQXWGUYOJ6RIHHA&Expires=1902580904&Signature=gK7pAKCMKiPRoaHUIOoCAt7gYJU%3D'
        }
      ],
      color: '#d1bc36',
      myStandards: [
        {
          dbId: 227366,
          id: 'test',
          withMathExpressions: 'N',
          desc: '<p>test</p>'
        }
      ],
      strategies: [
        {
          code: 'COL.ST',
          unitId: 856193,
          strategyId: 160610,
          unitStrategyId: 265,
          title: 'Think-Pair-Share'
        }
      ],
      unitNum: 'U1',
      className: 'Breakfast',
      autoAdjustInd: 'N',
      unitTitle: 'Lesson Unit',
      subjectId: 15195977,
      unitStart: '',
      yearId: 1449284,
      standards: [
        {
          dbId: 926176,
          id: '14',
          withMathExpressions: 'N',
          desc: 'I collaborate to achieve common goals.'
        }
      ],
      unitDesc: '',
      contents: [
        {
          section: 'L',
          id: 944,
          value: '<p>Lesson Unit Assessment</p>',
          parentId: 856193
        },
        {
          section: 'H',
          id: 945,
          value: '<p>Lesson Unit Objectives</p>',
          parentId: 856193
        },
        {
          section: 'N',
          id: 946,
          value: '<p>Lesson Unit Vocab</p>',
          parentId: 856193
        },
        {
          section: '4',
          id: 947,
          value: '<p>Lesson Unit Lesson</p>',
          parentId: 856193
        },
        {
          section: '5',
          id: 948,
          value: '<p>Lesson Unit Intervention - Students</p>',
          parentId: 856193
        },
        {
          section: '6',
          id: 949,
          value: '<p>Lesson Unit Interventions</p>',
          parentId: 856193
        }
      ],
      schoolStandards: [
        {
          dbId: 245222,
          id: '70513',
          withMathExpressions: 'N',
          desc: 'differention integration'
        }
      ],
      unitId: 856193,
      unitEnd: ''
    },
    lessonTitle: 'Lesson Title',
    linkedHomeworkText: '<p>Linked Lesson Objectives</p>',
    linkedAttachments: [
      {
        privateFlag: false,
        filename: 'app-release-(1).apk',
        url: 'https://s3.amazonaws.com/PlanbookAttachments/1245979/app-release-%281%29.apk?AWSAccessKeyId=AKIAIBQXWGUYOJ6RIHHA&Expires=1902509647&Signature=JA9ahIWp7BdkRbu87VIXebvXRhY%3D'
      }
    ],
    withMathExpressions: 'N',
    notesText: '<p>Lesson Vocab</p>',
    linkedStrategies: [
      {
        code: 'FM.1',
        strategyId: 138786,
        title: 'Super Scientist Awards'
      }
    ],
    linkedUnitId: 856192,
    attachments: [
      {
        privateFlag: false,
        filename: 'app-debug-6.apk',
        collaborateSubjectId: 0,
        url: 'https://s3.amazonaws.com/PlanbookAttachments/1245979/app-debug-6.apk?AWSAccessKeyId=AKIAIBQXWGUYOJ6RIHHA&Expires=1902509552&Signature=GQvzMDlF7avFiu17sPnzaqy09iU%3D'
      }
    ],
    color: '#d1bc36',
    asgAttachments: [],
    classLabelBold: 'N',
    linkedLessonId: 710793778,
    extraLesson: 0,
    className: 'Breakfast',
    titleSize: '10',
    googleAssignments: [],
    linkedNotesText: '<p>Linked Lesson Vocab</p>',
    classId: 15195977,
    asmAttachments: [],
    isAddClassDays: false,
    titleColor: '#ffffff',
    linkedMyStandards: [
      {
        dbId: 223634,
        id: 'x',
        withMathExpressions: 'N',
        desc: '<p>x</p>'
      }
    ],
    linkedSchoolStandards: [
      {
        dbId: 70153,
        id: 'SI.2',
        withMathExpressions: 'N',
        desc: 'School Item 2'
      }
    ],
    schoolId: 0,
    schoolStandards: [
      {
        dbId: 245411,
        id: '111',
        withMathExpressions: 'N',
        desc: 'Testing'
      }
    ],
    msTemplate: 'N',
    unitId: 856193,
    strTemplate: 'N',
    teacherYearName: '2019 - 2020 (copy)',
    startTime: '7:35 AM',
    classYearId: 1449284,
    t5Template: 'N',
    sTemplate: 'N',
    hTemplate: 'N',
    assessments: [],
    addClassDaysCode: '',
    teacherName: 'Adrian Daluz',
    linkedTeacherId: 1245979,
    strategies: [
      {
        code: 'BRC.1',
        strategyId: 146793,
        title: 'Processes/Routines that embrace student differences and strengths'
      },
      {
        code: 'BRC.2',
        strategyId: 146794,
        title: 'Class meetings '
      }
    ],
    linkedStandards: [
      {
        dbId: 926163,
        id: '1',
        withMathExpressions: 'N',
        desc: 'I explore my interests and passions while making personal connections to career possibilities.'
      }
    ],
    myStandards: [
      {
        dbId: 227366,
        id: 'test',
        withMathExpressions: 'N',
        desc: '<p>test</p>'
      }
    ],
    collaborateType: 0,
    addendums: [
      {
        lessonId: 711015894,
        typeId: 710793778,
        type: 'LINKEDLESSON',
        subjectId: 15195977
      },
      {
        lessonId: 711015894,
        typeId: 5038,
        type: 'STATUS',
        subjectId: 15195977
      },
      {
        lessonId: 711015894,
        typeId: 22753,
        type: 'STATUS',
        subjectId: 15195977
      },
      {
        lessonId: 711015894,
        typeId: 25676,
        type: 'STATUS',
        subjectId: 15195977
      },
      {
        lessonId: 711015894,
        typeId: 25714,
        type: 'STATUS',
        subjectId: 15195977
      },
      {
        lessonId: 711015894,
        typeId: 25736,
        type: 'STATUS',
        subjectId: 15195977
      }
    ],
    lessonId: 711015894,
    titleFont: '2',
    lTemplate: 'N',
    teacherId: 1245979,
    linkedClassId: 15195977,
    nTemplate: 'N',
    linkedLessonUnitStandards: [],
    aTemplate: 'N',
    endTime: '8:10 AM',
    linkedLessonText: '<p>Linked Lesson Assessment</p>',
    day: 'day6',
    date: '04/17/2020',
    dayOfWeek: 'Friday'
  }
};

export const lessonLayoutSectionsDefault = [
  {
    labelKey: 'lessonTitleLabel',
    label: 'Lesson Title',
    orderKey: 'lessonTitleDisplayOrder',
    enabledKey: 'lessonTitleEnabled',
    section: 'lessonTitle',
    titleFillColorKey: 'lessonTitleLabelFillColor',
    titleColorKey: 'lessonTitleColor',
    titleBoldKey: 'lessonTitleLabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    titleUnderlineKey: 'lessonTitleLabelUnderline',
    titleBold: true,
    titleFont: '2',
    titleFontSizeKey: 'lessonTitleSize',
    titleItalicKey: 'lessonTitleLabelItalic',
    titleFontKey: 'lessonTitleFont',
    titleUnderline: false,
    titleFillColor: '#ffffff'
  },
  {
    labelKey: 'tab1Label',
    label: 'Lesson',
    orderKey: 'tab1DisplayOrder',
    enabledKey: 'tab1Enabled',
    enabled: true,
    section: 'lesson',
    bodyItalic: false,
    titleFillColorKey: 'lessonLabelFillColor',
    titleColorKey: 'lessonColor',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'lessonHide',
    bodyFillColorKey: 'lessonTextFillColor',
    bodyItalicKey: 'lessonTextItalic',
    titleBoldKey: 'lessonLabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'lessonTextSize',
    titleUnderlineKey: 'lessonLabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'lessonSize',
    bodyBoldKey: 'lessonTextBold',
    bodyColorKey: 'lessonTextColor',
    titleItalicKey: 'lessonLabelItalic',
    titleFontKey: 'lessonFont',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'lessonTextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'lessonTextFont'
  },
  {
    labelKey: 'tab2Label',
    label: 'Homework',
    orderKey: 'tab2DisplayOrder',
    enabledKey: 'tab2Enabled',
    enabled: true,
    section: 'tab2',
    bodyItalic: false,
    titleFillColorKey: 'tab2LabelFillColor',
    titleColorKey: 'tab2Color',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'tab2Hide',
    bodyFillColorKey: 'tab2TextFillColor',
    bodyItalicKey: 'tab2TextItalic',
    titleBoldKey: 'tab2LabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'tab2TextSize',
    titleUnderlineKey: 'tab2LabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'tab2Size',
    bodyBoldKey: 'tab2TextBold',
    bodyColorKey: 'tab2TextColor',
    titleItalicKey: 'tab2LabelItalic',
    titleFontKey: 'tab2Font',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'tab2TextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'tab2TextFont'
  },
  {
    labelKey: 'tab3Label',
    label: 'Notes',
    orderKey: 'tab3DisplayOrder',
    enabledKey: 'tab3Enabled',
    enabled: true,
    section: 'tab3',
    bodyItalic: false,
    titleFillColorKey: 'tab3LabelFillColor',
    titleColorKey: 'tab3Color',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'tab3Hide',
    bodyFillColorKey: 'tab3TextFillColor',
    bodyItalicKey: 'tab3TextItalic',
    titleBoldKey: 'tab3LabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'tab3TextSize',
    titleUnderlineKey: 'tab3LabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'tab3Size',
    bodyBoldKey: 'tab3TextBold',
    bodyColorKey: 'tab3TextColor',
    titleItalicKey: 'tab3LabelItalic',
    titleFontKey: 'tab3Font',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'tab3TextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'tab3TextFont'
  },
  {
    labelKey: 'tab4Label',
    label: 'Not Used',
    orderKey: 'tab4DisplayOrder',
    enabledKey: 'tab4Enabled',
    enabled: false,
    section: 'tab4',
    bodyItalic: false,
    titleFillColorKey: 'tab4LabelFillColor',
    titleColorKey: 'tab4Color',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'tab4Hide',
    bodyFillColorKey: 'tab4TextFillColor',
    bodyItalicKey: 'tab4TextItalic',
    titleBoldKey: 'tab4LabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'tab4TextSize',
    titleUnderlineKey: 'tab4LabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'tab4Size',
    bodyBoldKey: 'tab4TextBold',
    bodyColorKey: 'tab4TextColor',
    titleItalicKey: 'tab4LabelItalic',
    titleFontKey: 'tab4Font',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'tab4TextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'tab4TextFont'
  },
  {
    labelKey: 'tab5Label',
    label: 'Not Used',
    orderKey: 'tab5DisplayOrder',
    enabledKey: 'tab5Enabled',
    enabled: false,
    section: 'tab5',
    bodyItalic: false,
    titleFillColorKey: 'tab5LabelFillColor',
    titleColorKey: 'tab5Color',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'tab5Hide',
    bodyFillColorKey: 'tab5TextFillColor',
    bodyItalicKey: 'tab5TextItalic',
    titleBoldKey: 'tab5LabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'tab5TextSize',
    titleUnderlineKey: 'tab5LabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'tab5Size',
    bodyBoldKey: 'tab5TextBold',
    bodyColorKey: 'tab5TextColor',
    titleItalicKey: 'tab5LabelItalic',
    titleFontKey: 'tab5Font',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'tab5TextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'tab5TextFont'
  },
  {
    labelKey: 'tab6Label',
    label: 'Not Used',
    orderKey: 'tab6DisplayOrder',
    enabledKey: 'tab6Enabled',
    enabled: false,
    section: 'tab6',
    bodyItalic: false,
    titleFillColorKey: 'tab6LabelFillColor',
    titleColorKey: 'tab6Color',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'tab6Hide',
    bodyFillColorKey: 'tab6TextFillColor',
    bodyItalicKey: 'tab6TextItalic',
    titleBoldKey: 'tab6LabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'tab6TextSize',
    titleUnderlineKey: 'tab6LabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'tab6Size',
    bodyBoldKey: 'tab6TextBold',
    bodyColorKey: 'tab6TextColor',
    titleItalicKey: 'tab6LabelItalic',
    titleFontKey: 'tab6Font',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'tab6TextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'tab6TextFont'
  },
  {
    labelKey: 'strategiesLabel',
    label: 'Strategies',
    orderKey: 'strategiesDisplayOrder',
    enabledKey: 'strategiesEnabled',
    enabled: false,
    section: 'strategies',
    bodyItalic: false,
    titleFillColorKey: 'strategiesLabelFillColor',
    titleColorKey: 'strategiesColor',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'strategiesHide',
    bodyFillColorKey: 'strategiesTextFillColor',
    bodyItalicKey: 'strategiesTextItalic',
    titleBoldKey: 'strategiesLabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'strategiesTextSize',
    titleUnderlineKey: 'strategiesLabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'strategiesSize',
    bodyBoldKey: 'strategiesTextBold',
    bodyColorKey: 'strategiesTextColor',
    titleItalicKey: 'strategiesLabelItalic',
    titleFontKey: 'strategiesFont',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'strategiesTextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'strategiesTextFont'
  },
  {
    labelKey: 'tab7Label',
    label: 'My List',
    orderKey: 'tab7DisplayOrder',
    enabledKey: 'tab7Enabled',
    enabled: false,
    section: 'myStandards',
    bodyItalic: false,
    titleFillColorKey: 'myStandardsLabelFillColor',
    titleColorKey: 'myStandardsColor',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'myStandardsHide',
    bodyFillColorKey: 'myStandardsTextFillColor',
    bodyItalicKey: 'myStandardsTextItalic',
    titleBoldKey: 'myStandardsLabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'myStandardsTextSize',
    titleUnderlineKey: 'myStandardsLabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'myStandardsSize',
    bodyBoldKey: 'myStandardsTextBold',
    bodyColorKey: 'myStandardsTextColor',
    titleItalicKey: 'myStandardsLabelItalic',
    titleFontKey: 'myStandardsFont',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'myStandardsTextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'myStandardsTextFont'
  },
  {
    labelKey: 'tab8Label',
    label: 'Standards',
    orderKey: 'tab8DisplayOrder',
    enabledKey: 'tab8Enabled',
    enabled: true,
    section: 'sharedStandards',
    bodyItalic: false,
    titleFillColorKey: 'sharedStandardsLabelFillColor',
    titleColorKey: 'sharedStandardsColor',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'sharedStandardsHide',
    bodyFillColorKey: 'sharedStandardsTextFillColor',
    bodyItalicKey: 'sharedStandardsTextItalic',
    titleBoldKey: 'sharedStandardsLabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'sharedStandardsTextSize',
    titleUnderlineKey: 'sharedStandardsLabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'sharedStandardsSize',
    bodyBoldKey: 'sharedStandardsTextBold',
    bodyColorKey: 'sharedStandardsTextColor',
    titleItalicKey: 'sharedStandardsLabelItalic',
    titleFontKey: 'sharedStandardsFont',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'sharedStandardsTextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'sharedStandardsTextFont'
  },
  {
    labelKey: 'tab9Label',
    label: 'School List',
    orderKey: 'tab9DisplayOrder',
    enabledKey: 'tab9Enabled',
    enabled: false,
    section: 'schoolStandards',
    bodyItalic: false,
    titleFillColorKey: 'schoolStandardsLabelFillColor',
    titleColorKey: 'schoolStandardsColor',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'schoolStandardsHide',
    bodyFillColorKey: 'schoolStandardsTextFillColor',
    bodyItalicKey: 'schoolStandardsTextItalic',
    titleBoldKey: 'schoolStandardsLabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'schoolStandardsTextSize',
    titleUnderlineKey: 'schoolStandardsLabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'schoolStandardsSize',
    bodyBoldKey: 'schoolStandardsTextBold',
    bodyColorKey: 'schoolStandardsTextColor',
    titleItalicKey: 'schoolStandardsLabelItalic',
    titleFontKey: 'schoolStandardsFont',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'schoolStandardsTextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'schoolStandardsTextFont'
  },
  {
    labelKey: 'tab10Label',
    label: 'Attachments',
    orderKey: 'tab10DisplayOrder',
    enabledKey: 'tab10Enabled',
    enabled: true,
    section: 'attachments',
    bodyItalic: false,
    titleFillColorKey: 'attachmentsLabelFillColor',
    titleColorKey: 'attachmentsColor',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'attachmentsHide',
    bodyFillColorKey: 'attachmentsTextFillColor',
    bodyItalicKey: 'attachmentsTextItalic',
    titleBoldKey: 'attachmentsLabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'attachmentsTextSize',
    titleUnderlineKey: 'attachmentsLabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'attachmentsSize',
    bodyBoldKey: 'attachmentsTextBold',
    bodyColorKey: 'attachmentsTextColor',
    titleItalicKey: 'attachmentsLabelItalic',
    titleFontKey: 'attachmentsFont',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'attachmentsTextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'attachmentsTextFont'
  },
  {
    labelKey: 'tab11Label',
    label: 'Assignments',
    orderKey: 'tab11DisplayOrder',
    enabledKey: 'tab11Enabled',
    enabled: true,
    section: 'assignments',
    bodyItalic: false,
    titleFillColorKey: 'assignmentsLabelFillColor',
    titleColorKey: 'assignmentsColor',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'assignmentsHide',
    bodyFillColorKey: 'assignmentsTextFillColor',
    bodyItalicKey: 'assignmentsTextItalic',
    titleBoldKey: 'assignmentsLabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'assignmentsTextSize',
    titleUnderlineKey: 'assignmentsLabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'assignmentsSize',
    bodyBoldKey: 'assignmentsTextBold',
    bodyColorKey: 'assignmentsTextColor',
    titleItalicKey: 'assignmentsLabelItalic',
    titleFontKey: 'assignmentsFont',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'assignmentsTextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'assignmentsTextFont'
  },
  {
    labelKey: 'tab12Label',
    label: 'Assessments',
    orderKey: 'tab12DisplayOrder',
    enabledKey: 'tab12Enabled',
    enabled: true,
    section: 'assessments',
    bodyItalic: false,
    titleFillColorKey: 'assessmentsLabelFillColor',
    titleColorKey: 'assessmentsColor',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'assessmentsHide',
    bodyFillColorKey: 'assessmentsTextFillColor',
    bodyItalicKey: 'assessmentsTextItalic',
    titleBoldKey: 'assessmentsLabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'assessmentsTextSize',
    titleUnderlineKey: 'assessmentsLabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'assessmentsSize',
    bodyBoldKey: 'assessmentsTextBold',
    bodyColorKey: 'assessmentsTextColor',
    titleItalicKey: 'assessmentsLabelItalic',
    titleFontKey: 'assessmentsFont',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'assessmentsTextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'assessmentsTextFont'
  }
];

export const lessonLayoutSectionsBasic = [
  {
    labelKey: 'lessonTitleLabel',
    label: 'Lesson Title',
    orderKey: 'lessonTitleDisplayOrder',
    enabledKey: 'lessonTitleEnabled',
    section: 'lessonTitle',
    titleFillColorKey: 'lessonTitleLabelFillColor',
    titleColorKey: 'lessonTitleColor',
    titleBoldKey: 'lessonTitleLabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    titleUnderlineKey: 'lessonTitleLabelUnderline',
    titleBold: true,
    titleFont: '2',
    titleFontSizeKey: 'lessonTitleSize',
    titleItalicKey: 'lessonTitleLabelItalic',
    titleFontKey: 'lessonTitleFont',
    titleUnderline: false,
    titleFillColor: '#ffffff'
  },
  {
    labelKey: 'tab1Label',
    label: 'Lesson',
    orderKey: 'tab1DisplayOrder',
    enabledKey: 'tab1Enabled',
    enabled: true,
    section: 'lesson',
    bodyItalic: false,
    titleFillColorKey: 'lessonLabelFillColor',
    titleColorKey: 'lessonColor',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'lessonHide',
    bodyFillColorKey: 'lessonTextFillColor',
    bodyItalicKey: 'lessonTextItalic',
    titleBoldKey: 'lessonLabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'lessonTextSize',
    titleUnderlineKey: 'lessonLabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'lessonSize',
    bodyBoldKey: 'lessonTextBold',
    bodyColorKey: 'lessonTextColor',
    titleItalicKey: 'lessonLabelItalic',
    titleFontKey: 'lessonFont',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'lessonTextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'lessonTextFont'
  },
  {
    labelKey: 'tab2Label',
    label: 'Homework',
    orderKey: 'tab2DisplayOrder',
    enabledKey: 'tab2Enabled',
    enabled: true,
    section: 'tab2',
    bodyItalic: false,
    titleFillColorKey: 'tab2LabelFillColor',
    titleColorKey: 'tab2Color',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'tab2Hide',
    bodyFillColorKey: 'tab2TextFillColor',
    bodyItalicKey: 'tab2TextItalic',
    titleBoldKey: 'tab2LabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'tab2TextSize',
    titleUnderlineKey: 'tab2LabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'tab2Size',
    bodyBoldKey: 'tab2TextBold',
    bodyColorKey: 'tab2TextColor',
    titleItalicKey: 'tab2LabelItalic',
    titleFontKey: 'tab2Font',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'tab2TextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'tab2TextFont'
  },
  {
    labelKey: 'tab3Label',
    label: 'Notes',
    orderKey: 'tab3DisplayOrder',
    enabledKey: 'tab3Enabled',
    enabled: true,
    section: 'tab3',
    bodyItalic: false,
    titleFillColorKey: 'tab3LabelFillColor',
    titleColorKey: 'tab3Color',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'tab3Hide',
    bodyFillColorKey: 'tab3TextFillColor',
    bodyItalicKey: 'tab3TextItalic',
    titleBoldKey: 'tab3LabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'tab3TextSize',
    titleUnderlineKey: 'tab3LabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'tab3Size',
    bodyBoldKey: 'tab3TextBold',
    bodyColorKey: 'tab3TextColor',
    titleItalicKey: 'tab3LabelItalic',
    titleFontKey: 'tab3Font',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'tab3TextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'tab3TextFont'
  },
  {
    labelKey: 'tab8Label',
    label: 'Standards',
    orderKey: 'tab8DisplayOrder',
    enabledKey: 'tab8Enabled',
    enabled: true,
    section: 'sharedStandards',
    bodyItalic: false,
    titleFillColorKey: 'sharedStandardsLabelFillColor',
    titleColorKey: 'sharedStandardsColor',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'sharedStandardsHide',
    bodyFillColorKey: 'sharedStandardsTextFillColor',
    bodyItalicKey: 'sharedStandardsTextItalic',
    titleBoldKey: 'sharedStandardsLabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'sharedStandardsTextSize',
    titleUnderlineKey: 'sharedStandardsLabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'sharedStandardsSize',
    bodyBoldKey: 'sharedStandardsTextBold',
    bodyColorKey: 'sharedStandardsTextColor',
    titleItalicKey: 'sharedStandardsLabelItalic',
    titleFontKey: 'sharedStandardsFont',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'sharedStandardsTextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'sharedStandardsTextFont'
  },
  {
    labelKey: 'tab4Label',
    label: 'Not Used',
    orderKey: 'tab4DisplayOrder',
    enabledKey: 'tab4Enabled',
    enabled: false,
    section: 'tab4',
    bodyItalic: false,
    titleFillColorKey: 'tab4LabelFillColor',
    titleColorKey: 'tab4Color',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'tab4Hide',
    bodyFillColorKey: 'tab4TextFillColor',
    bodyItalicKey: 'tab4TextItalic',
    titleBoldKey: 'tab4LabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'tab4TextSize',
    titleUnderlineKey: 'tab4LabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'tab4Size',
    bodyBoldKey: 'tab4TextBold',
    bodyColorKey: 'tab4TextColor',
    titleItalicKey: 'tab4LabelItalic',
    titleFontKey: 'tab4Font',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'tab4TextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'tab4TextFont'
  },
  {
    labelKey: 'tab5Label',
    label: 'Not Used',
    orderKey: 'tab5DisplayOrder',
    enabledKey: 'tab5Enabled',
    enabled: false,
    section: 'tab5',
    bodyItalic: false,
    titleFillColorKey: 'tab5LabelFillColor',
    titleColorKey: 'tab5Color',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'tab5Hide',
    bodyFillColorKey: 'tab5TextFillColor',
    bodyItalicKey: 'tab5TextItalic',
    titleBoldKey: 'tab5LabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'tab5TextSize',
    titleUnderlineKey: 'tab5LabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'tab5Size',
    bodyBoldKey: 'tab5TextBold',
    bodyColorKey: 'tab5TextColor',
    titleItalicKey: 'tab5LabelItalic',
    titleFontKey: 'tab5Font',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'tab5TextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'tab5TextFont'
  },
  {
    labelKey: 'tab6Label',
    label: 'Not Used',
    orderKey: 'tab6DisplayOrder',
    enabledKey: 'tab6Enabled',
    enabled: false,
    section: 'tab6',
    bodyItalic: false,
    titleFillColorKey: 'tab6LabelFillColor',
    titleColorKey: 'tab6Color',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'tab6Hide',
    bodyFillColorKey: 'tab6TextFillColor',
    bodyItalicKey: 'tab6TextItalic',
    titleBoldKey: 'tab6LabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'tab6TextSize',
    titleUnderlineKey: 'tab6LabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'tab6Size',
    bodyBoldKey: 'tab6TextBold',
    bodyColorKey: 'tab6TextColor',
    titleItalicKey: 'tab6LabelItalic',
    titleFontKey: 'tab6Font',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'tab6TextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'tab6TextFont'
  },
  {
    labelKey: 'tab7Label',
    label: 'My List',
    orderKey: 'tab7DisplayOrder',
    enabledKey: 'tab7Enabled',
    enabled: false,
    section: 'myStandards',
    bodyItalic: false,
    titleFillColorKey: 'myStandardsLabelFillColor',
    titleColorKey: 'myStandardsColor',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'myStandardsHide',
    bodyFillColorKey: 'myStandardsTextFillColor',
    bodyItalicKey: 'myStandardsTextItalic',
    titleBoldKey: 'myStandardsLabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'myStandardsTextSize',
    titleUnderlineKey: 'myStandardsLabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'myStandardsSize',
    bodyBoldKey: 'myStandardsTextBold',
    bodyColorKey: 'myStandardsTextColor',
    titleItalicKey: 'myStandardsLabelItalic',
    titleFontKey: 'myStandardsFont',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'myStandardsTextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'myStandardsTextFont'
  },
  {
    labelKey: 'tab9Label',
    label: 'School List',
    orderKey: 'tab9DisplayOrder',
    enabledKey: 'tab9Enabled',
    enabled: false,
    section: 'schoolStandards',
    bodyItalic: false,
    titleFillColorKey: 'schoolStandardsLabelFillColor',
    titleColorKey: 'schoolStandardsColor',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'schoolStandardsHide',
    bodyFillColorKey: 'schoolStandardsTextFillColor',
    bodyItalicKey: 'schoolStandardsTextItalic',
    titleBoldKey: 'schoolStandardsLabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'schoolStandardsTextSize',
    titleUnderlineKey: 'schoolStandardsLabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'schoolStandardsSize',
    bodyBoldKey: 'schoolStandardsTextBold',
    bodyColorKey: 'schoolStandardsTextColor',
    titleItalicKey: 'schoolStandardsLabelItalic',
    titleFontKey: 'schoolStandardsFont',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'schoolStandardsTextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'schoolStandardsTextFont'
  },
  {
    labelKey: 'strategiesLabel',
    label: 'Strategies',
    orderKey: 'strategiesDisplayOrder',
    enabledKey: 'strategiesEnabled',
    enabled: false,
    section: 'strategies',
    bodyItalic: false,
    titleFillColorKey: 'strategiesLabelFillColor',
    titleColorKey: 'strategiesColor',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'strategiesHide',
    bodyFillColorKey: 'strategiesTextFillColor',
    bodyItalicKey: 'strategiesTextItalic',
    titleBoldKey: 'strategiesLabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'strategiesTextSize',
    titleUnderlineKey: 'strategiesLabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'strategiesSize',
    bodyBoldKey: 'strategiesTextBold',
    bodyColorKey: 'strategiesTextColor',
    titleItalicKey: 'strategiesLabelItalic',
    titleFontKey: 'strategiesFont',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'strategiesTextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'strategiesTextFont'
  },
  {
    labelKey: 'tab10Label',
    label: 'Attachments',
    orderKey: 'tab10DisplayOrder',
    enabledKey: 'tab10Enabled',
    enabled: true,
    section: 'attachments',
    bodyItalic: false,
    titleFillColorKey: 'attachmentsLabelFillColor',
    titleColorKey: 'attachmentsColor',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'attachmentsHide',
    bodyFillColorKey: 'attachmentsTextFillColor',
    bodyItalicKey: 'attachmentsTextItalic',
    titleBoldKey: 'attachmentsLabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'attachmentsTextSize',
    titleUnderlineKey: 'attachmentsLabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'attachmentsSize',
    bodyBoldKey: 'attachmentsTextBold',
    bodyColorKey: 'attachmentsTextColor',
    titleItalicKey: 'attachmentsLabelItalic',
    titleFontKey: 'attachmentsFont',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'attachmentsTextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'attachmentsTextFont'
  },
  {
    labelKey: 'tab11Label',
    label: 'Assignments',
    orderKey: 'tab11DisplayOrder',
    enabledKey: 'tab11Enabled',
    enabled: true,
    section: 'assignments',
    bodyItalic: false,
    titleFillColorKey: 'assignmentsLabelFillColor',
    titleColorKey: 'assignmentsColor',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'assignmentsHide',
    bodyFillColorKey: 'assignmentsTextFillColor',
    bodyItalicKey: 'assignmentsTextItalic',
    titleBoldKey: 'assignmentsLabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'assignmentsTextSize',
    titleUnderlineKey: 'assignmentsLabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'assignmentsSize',
    bodyBoldKey: 'assignmentsTextBold',
    bodyColorKey: 'assignmentsTextColor',
    titleItalicKey: 'assignmentsLabelItalic',
    titleFontKey: 'assignmentsFont',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'assignmentsTextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'assignmentsTextFont'
  },
  {
    labelKey: 'tab12Label',
    label: 'Assessments',
    orderKey: 'tab12DisplayOrder',
    enabledKey: 'tab12Enabled',
    enabled: true,
    section: 'assessments',
    bodyItalic: false,
    titleFillColorKey: 'assessmentsLabelFillColor',
    titleColorKey: 'assessmentsColor',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'assessmentsHide',
    bodyFillColorKey: 'assessmentsTextFillColor',
    bodyItalicKey: 'assessmentsTextItalic',
    titleBoldKey: 'assessmentsLabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'assessmentsTextSize',
    titleUnderlineKey: 'assessmentsLabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'assessmentsSize',
    bodyBoldKey: 'assessmentsTextBold',
    bodyColorKey: 'assessmentsTextColor',
    titleItalicKey: 'assessmentsLabelItalic',
    titleFontKey: 'assessmentsFont',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'assessmentsTextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'assessmentsTextFont'
  }
];

export const lessonLayoutSectionsInstructional = [
  {
    labelKey: 'lessonTitleLabel',
    label: 'Lesson Title',
    orderKey: 'lessonTitleDisplayOrder',
    enabledKey: 'lessonTitleEnabled',
    section: 'lessonTitle',
    titleFillColorKey: 'lessonTitleLabelFillColor',
    titleColorKey: 'lessonTitleColor',
    titleBoldKey: 'lessonTitleLabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    titleUnderlineKey: 'lessonTitleLabelUnderline',
    titleBold: true,
    titleFont: '2',
    titleFontSizeKey: 'lessonTitleSize',
    titleItalicKey: 'lessonTitleLabelItalic',
    titleFontKey: 'lessonTitleFont',
    titleUnderline: false,
    titleFillColor: '#ffffff'
  },
  {
    labelKey: 'tab1Label',
    label: 'Objective',
    orderKey: 'tab1DisplayOrder',
    enabledKey: 'tab1Enabled',
    enabled: true,
    section: 'lesson',
    bodyItalic: false,
    titleFillColorKey: 'lessonLabelFillColor',
    titleColorKey: 'lessonColor',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'lessonHide',
    bodyFillColorKey: 'lessonTextFillColor',
    bodyItalicKey: 'lessonTextItalic',
    titleBoldKey: 'lessonLabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'lessonTextSize',
    titleUnderlineKey: 'lessonLabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'lessonSize',
    bodyBoldKey: 'lessonTextBold',
    bodyColorKey: 'lessonTextColor',
    titleItalicKey: 'lessonLabelItalic',
    titleFontKey: 'lessonFont',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'lessonTextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'lessonTextFont'
  },
  {
    labelKey: 'tab2Label',
    label: 'Direct Instruction',
    orderKey: 'tab2DisplayOrder',
    enabledKey: 'tab2Enabled',
    enabled: true,
    section: 'tab2',
    bodyItalic: false,
    titleFillColorKey: 'tab2LabelFillColor',
    titleColorKey: 'tab2Color',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'tab2Hide',
    bodyFillColorKey: 'tab2TextFillColor',
    bodyItalicKey: 'tab2TextItalic',
    titleBoldKey: 'tab2LabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'tab2TextSize',
    titleUnderlineKey: 'tab2LabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'tab2Size',
    bodyBoldKey: 'tab2TextBold',
    bodyColorKey: 'tab2TextColor',
    titleItalicKey: 'tab2LabelItalic',
    titleFontKey: 'tab2Font',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'tab2TextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'tab2TextFont'
  },
  {
    labelKey: 'tab3Label',
    label: 'Guided Practice',
    orderKey: 'tab3DisplayOrder',
    enabledKey: 'tab3Enabled',
    enabled: true,
    section: 'tab3',
    bodyItalic: false,
    titleFillColorKey: 'tab3LabelFillColor',
    titleColorKey: 'tab3Color',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'tab3Hide',
    bodyFillColorKey: 'tab3TextFillColor',
    bodyItalicKey: 'tab3TextItalic',
    titleBoldKey: 'tab3LabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'tab3TextSize',
    titleUnderlineKey: 'tab3LabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'tab3Size',
    bodyBoldKey: 'tab3TextBold',
    bodyColorKey: 'tab3TextColor',
    titleItalicKey: 'tab3LabelItalic',
    titleFontKey: 'tab3Font',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'tab3TextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'tab3TextFont'
  },
  {
    labelKey: 'tab4Label',
    label: 'Independent Practice',
    orderKey: 'tab4DisplayOrder',
    enabledKey: 'tab4Enabled',
    enabled: true,
    section: 'tab4',
    bodyItalic: false,
    titleFillColorKey: 'tab4LabelFillColor',
    titleColorKey: 'tab4Color',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'tab4Hide',
    bodyFillColorKey: 'tab4TextFillColor',
    bodyItalicKey: 'tab4TextItalic',
    titleBoldKey: 'tab4LabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'tab4TextSize',
    titleUnderlineKey: 'tab4LabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'tab4Size',
    bodyBoldKey: 'tab4TextBold',
    bodyColorKey: 'tab4TextColor',
    titleItalicKey: 'tab4LabelItalic',
    titleFontKey: 'tab4Font',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'tab4TextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'tab4TextFont'
  },
  {
    labelKey: 'tab5Label',
    label: 'Homework',
    orderKey: 'tab5DisplayOrder',
    enabledKey: 'tab5Enabled',
    enabled: true,
    section: 'tab5',
    bodyItalic: false,
    titleFillColorKey: 'tab5LabelFillColor',
    titleColorKey: 'tab5Color',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'tab5Hide',
    bodyFillColorKey: 'tab5TextFillColor',
    bodyItalicKey: 'tab5TextItalic',
    titleBoldKey: 'tab5LabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'tab5TextSize',
    titleUnderlineKey: 'tab5LabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'tab5Size',
    bodyBoldKey: 'tab5TextBold',
    bodyColorKey: 'tab5TextColor',
    titleItalicKey: 'tab5LabelItalic',
    titleFontKey: 'tab5Font',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'tab5TextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'tab5TextFont'
  },
  {
    labelKey: 'tab6Label',
    label: 'Notes',
    orderKey: 'tab6DisplayOrder',
    enabledKey: 'tab6Enabled',
    enabled: true,
    section: 'tab6',
    bodyItalic: false,
    titleFillColorKey: 'tab6LabelFillColor',
    titleColorKey: 'tab6Color',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'tab6Hide',
    bodyFillColorKey: 'tab6TextFillColor',
    bodyItalicKey: 'tab6TextItalic',
    titleBoldKey: 'tab6LabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'tab6TextSize',
    titleUnderlineKey: 'tab6LabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'tab6Size',
    bodyBoldKey: 'tab6TextBold',
    bodyColorKey: 'tab6TextColor',
    titleItalicKey: 'tab6LabelItalic',
    titleFontKey: 'tab6Font',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'tab6TextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'tab6TextFont'
  },
  {
    labelKey: 'tab8Label',
    label: 'Standards',
    orderKey: 'tab8DisplayOrder',
    enabledKey: 'tab8Enabled',
    enabled: true,
    section: 'sharedStandards',
    bodyItalic: false,
    titleFillColorKey: 'sharedStandardsLabelFillColor',
    titleColorKey: 'sharedStandardsColor',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'sharedStandardsHide',
    bodyFillColorKey: 'sharedStandardsTextFillColor',
    bodyItalicKey: 'sharedStandardsTextItalic',
    titleBoldKey: 'sharedStandardsLabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'sharedStandardsTextSize',
    titleUnderlineKey: 'sharedStandardsLabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'sharedStandardsSize',
    bodyBoldKey: 'sharedStandardsTextBold',
    bodyColorKey: 'sharedStandardsTextColor',
    titleItalicKey: 'sharedStandardsLabelItalic',
    titleFontKey: 'sharedStandardsFont',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'sharedStandardsTextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'sharedStandardsTextFont'
  },
  {
    labelKey: 'tab7Label',
    label: 'My List',
    orderKey: 'tab7DisplayOrder',
    enabledKey: 'tab7Enabled',
    enabled: false,
    section: 'myStandards',
    bodyItalic: false,
    titleFillColorKey: 'myStandardsLabelFillColor',
    titleColorKey: 'myStandardsColor',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'myStandardsHide',
    bodyFillColorKey: 'myStandardsTextFillColor',
    bodyItalicKey: 'myStandardsTextItalic',
    titleBoldKey: 'myStandardsLabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'myStandardsTextSize',
    titleUnderlineKey: 'myStandardsLabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'myStandardsSize',
    bodyBoldKey: 'myStandardsTextBold',
    bodyColorKey: 'myStandardsTextColor',
    titleItalicKey: 'myStandardsLabelItalic',
    titleFontKey: 'myStandardsFont',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'myStandardsTextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'myStandardsTextFont'
  },
  {
    labelKey: 'tab9Label',
    label: 'School List',
    orderKey: 'tab9DisplayOrder',
    enabledKey: 'tab9Enabled',
    enabled: false,
    section: 'schoolStandards',
    bodyItalic: false,
    titleFillColorKey: 'schoolStandardsLabelFillColor',
    titleColorKey: 'schoolStandardsColor',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'schoolStandardsHide',
    bodyFillColorKey: 'schoolStandardsTextFillColor',
    bodyItalicKey: 'schoolStandardsTextItalic',
    titleBoldKey: 'schoolStandardsLabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'schoolStandardsTextSize',
    titleUnderlineKey: 'schoolStandardsLabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'schoolStandardsSize',
    bodyBoldKey: 'schoolStandardsTextBold',
    bodyColorKey: 'schoolStandardsTextColor',
    titleItalicKey: 'schoolStandardsLabelItalic',
    titleFontKey: 'schoolStandardsFont',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'schoolStandardsTextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'schoolStandardsTextFont'
  },
  {
    labelKey: 'strategiesLabel',
    label: 'Strategies',
    orderKey: 'strategiesDisplayOrder',
    enabledKey: 'strategiesEnabled',
    enabled: false,
    section: 'strategies',
    bodyItalic: false,
    titleFillColorKey: 'strategiesLabelFillColor',
    titleColorKey: 'strategiesColor',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'strategiesHide',
    bodyFillColorKey: 'strategiesTextFillColor',
    bodyItalicKey: 'strategiesTextItalic',
    titleBoldKey: 'strategiesLabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'strategiesTextSize',
    titleUnderlineKey: 'strategiesLabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'strategiesSize',
    bodyBoldKey: 'strategiesTextBold',
    bodyColorKey: 'strategiesTextColor',
    titleItalicKey: 'strategiesLabelItalic',
    titleFontKey: 'strategiesFont',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'strategiesTextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'strategiesTextFont'
  },
  {
    labelKey: 'tab10Label',
    label: 'Attachments',
    orderKey: 'tab10DisplayOrder',
    enabledKey: 'tab10Enabled',
    enabled: true,
    section: 'attachments',
    bodyItalic: false,
    titleFillColorKey: 'attachmentsLabelFillColor',
    titleColorKey: 'attachmentsColor',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'attachmentsHide',
    bodyFillColorKey: 'attachmentsTextFillColor',
    bodyItalicKey: 'attachmentsTextItalic',
    titleBoldKey: 'attachmentsLabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'attachmentsTextSize',
    titleUnderlineKey: 'attachmentsLabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'attachmentsSize',
    bodyBoldKey: 'attachmentsTextBold',
    bodyColorKey: 'attachmentsTextColor',
    titleItalicKey: 'attachmentsLabelItalic',
    titleFontKey: 'attachmentsFont',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'attachmentsTextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'attachmentsTextFont'
  },
  {
    labelKey: 'tab11Label',
    label: 'Assignments',
    orderKey: 'tab11DisplayOrder',
    enabledKey: 'tab11Enabled',
    enabled: true,
    section: 'assignments',
    bodyItalic: false,
    titleFillColorKey: 'assignmentsLabelFillColor',
    titleColorKey: 'assignmentsColor',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'assignmentsHide',
    bodyFillColorKey: 'assignmentsTextFillColor',
    bodyItalicKey: 'assignmentsTextItalic',
    titleBoldKey: 'assignmentsLabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'assignmentsTextSize',
    titleUnderlineKey: 'assignmentsLabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'assignmentsSize',
    bodyBoldKey: 'assignmentsTextBold',
    bodyColorKey: 'assignmentsTextColor',
    titleItalicKey: 'assignmentsLabelItalic',
    titleFontKey: 'assignmentsFont',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'assignmentsTextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'assignmentsTextFont'
  },
  {
    labelKey: 'tab12Label',
    label: 'Assessments',
    orderKey: 'tab12DisplayOrder',
    enabledKey: 'tab12Enabled',
    enabled: true,
    section: 'assessments',
    bodyItalic: false,
    titleFillColorKey: 'assessmentsLabelFillColor',
    titleColorKey: 'assessmentsColor',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'assessmentsHide',
    bodyFillColorKey: 'assessmentsTextFillColor',
    bodyItalicKey: 'assessmentsTextItalic',
    titleBoldKey: 'assessmentsLabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'assessmentsTextSize',
    titleUnderlineKey: 'assessmentsLabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'assessmentsSize',
    bodyBoldKey: 'assessmentsTextBold',
    bodyColorKey: 'assessmentsTextColor',
    titleItalicKey: 'assessmentsLabelItalic',
    titleFontKey: 'assessmentsFont',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'assessmentsTextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'assessmentsTextFont'
  }
];

export const lessonLayoutSectionsDetailed = [
  {
    labelKey: 'lessonTitleLabel',
    label: 'Lesson Title',
    orderKey: 'lessonTitleDisplayOrder',
    enabledKey: 'lessonTitleEnabled',
    section: 'lessonTitle',
    titleFillColorKey: 'lessonTitleLabelFillColor',
    titleColorKey: 'lessonTitleColor',
    titleBoldKey: 'lessonTitleLabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    titleUnderlineKey: 'lessonTitleLabelUnderline',
    titleBold: true,
    titleFont: '2',
    titleFontSizeKey: 'lessonTitleSize',
    titleItalicKey: 'lessonTitleLabelItalic',
    titleFontKey: 'lessonTitleFont',
    titleUnderline: false,
    titleFillColor: '#ffffff'
  },
  {
    labelKey: 'tab8Label',
    label: 'Standards',
    orderKey: 'tab8DisplayOrder',
    enabledKey: 'tab8Enabled',
    enabled: true,
    section: 'sharedStandards',
    bodyItalic: false,
    titleFillColorKey: 'sharedStandardsLabelFillColor',
    titleColorKey: 'sharedStandardsColor',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'sharedStandardsHide',
    bodyFillColorKey: 'sharedStandardsTextFillColor',
    bodyItalicKey: 'sharedStandardsTextItalic',
    titleBoldKey: 'sharedStandardsLabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'sharedStandardsTextSize',
    titleUnderlineKey: 'sharedStandardsLabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'sharedStandardsSize',
    bodyBoldKey: 'sharedStandardsTextBold',
    bodyColorKey: 'sharedStandardsTextColor',
    titleItalicKey: 'sharedStandardsLabelItalic',
    titleFontKey: 'sharedStandardsFont',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'sharedStandardsTextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'sharedStandardsTextFont'
  },
  {
    labelKey: 'tab1Label',
    label: 'Objectives / Essential Question',
    orderKey: 'tab1DisplayOrder',
    enabledKey: 'tab1Enabled',
    enabled: true,
    section: 'lesson',
    bodyItalic: false,
    titleFillColorKey: 'lessonLabelFillColor',
    titleColorKey: 'lessonColor',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'lessonHide',
    bodyFillColorKey: 'lessonTextFillColor',
    bodyItalicKey: 'lessonTextItalic',
    titleBoldKey: 'lessonLabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'lessonTextSize',
    titleUnderlineKey: 'lessonLabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'lessonSize',
    bodyBoldKey: 'lessonTextBold',
    bodyColorKey: 'lessonTextColor',
    titleItalicKey: 'lessonLabelItalic',
    titleFontKey: 'lessonFont',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'lessonTextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'lessonTextFont'
  },
  {
    labelKey: 'tab2Label',
    label: 'Lesson / Instruction',
    orderKey: 'tab2DisplayOrder',
    enabledKey: 'tab2Enabled',
    enabled: true,
    section: 'tab2',
    bodyItalic: false,
    titleFillColorKey: 'tab2LabelFillColor',
    titleColorKey: 'tab2Color',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'tab2Hide',
    bodyFillColorKey: 'tab2TextFillColor',
    bodyItalicKey: 'tab2TextItalic',
    titleBoldKey: 'tab2LabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'tab2TextSize',
    titleUnderlineKey: 'tab2LabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'tab2Size',
    bodyBoldKey: 'tab2TextBold',
    bodyColorKey: 'tab2TextColor',
    titleItalicKey: 'tab2LabelItalic',
    titleFontKey: 'tab2Font',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'tab2TextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'tab2TextFont'
  },
  {
    labelKey: 'tab3Label',
    label: 'Differentiation / Accommodations',
    orderKey: 'tab3DisplayOrder',
    enabledKey: 'tab3Enabled',
    enabled: true,
    section: 'tab3',
    bodyItalic: false,
    titleFillColorKey: 'tab3LabelFillColor',
    titleColorKey: 'tab3Color',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'tab3Hide',
    bodyFillColorKey: 'tab3TextFillColor',
    bodyItalicKey: 'tab3TextItalic',
    titleBoldKey: 'tab3LabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'tab3TextSize',
    titleUnderlineKey: 'tab3LabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'tab3Size',
    bodyBoldKey: 'tab3TextBold',
    bodyColorKey: 'tab3TextColor',
    titleItalicKey: 'tab3LabelItalic',
    titleFontKey: 'tab3Font',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'tab3TextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'tab3TextFont'
  },
  {
    labelKey: 'tab4Label',
    label: 'Homework / Evidence of Learning',
    orderKey: 'tab4DisplayOrder',
    enabledKey: 'tab4Enabled',
    enabled: true,
    section: 'tab4',
    bodyItalic: false,
    titleFillColorKey: 'tab4LabelFillColor',
    titleColorKey: 'tab4Color',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'tab4Hide',
    bodyFillColorKey: 'tab4TextFillColor',
    bodyItalicKey: 'tab4TextItalic',
    titleBoldKey: 'tab4LabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'tab4TextSize',
    titleUnderlineKey: 'tab4LabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'tab4Size',
    bodyBoldKey: 'tab4TextBold',
    bodyColorKey: 'tab4TextColor',
    titleItalicKey: 'tab4LabelItalic',
    titleFontKey: 'tab4Font',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'tab4TextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'tab4TextFont'
  },
  {
    labelKey: 'strategiesLabel',
    label: 'Instructional Strategies',
    orderKey: 'strategiesDisplayOrder',
    enabledKey: 'strategiesEnabled',
    enabled: true,
    section: 'strategies',
    bodyItalic: false,
    titleFillColorKey: 'strategiesLabelFillColor',
    titleColorKey: 'strategiesColor',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'strategiesHide',
    bodyFillColorKey: 'strategiesTextFillColor',
    bodyItalicKey: 'strategiesTextItalic',
    titleBoldKey: 'strategiesLabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'strategiesTextSize',
    titleUnderlineKey: 'strategiesLabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'strategiesSize',
    bodyBoldKey: 'strategiesTextBold',
    bodyColorKey: 'strategiesTextColor',
    titleItalicKey: 'strategiesLabelItalic',
    titleFontKey: 'strategiesFont',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'strategiesTextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'strategiesTextFont'
  },
  {
    labelKey: 'tab5Label',
    label: 'Materials / Resources / Technology',
    orderKey: 'tab5DisplayOrder',
    enabledKey: 'tab5Enabled',
    enabled: true,
    section: 'tab5',
    bodyItalic: false,
    titleFillColorKey: 'tab5LabelFillColor',
    titleColorKey: 'tab5Color',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'tab5Hide',
    bodyFillColorKey: 'tab5TextFillColor',
    bodyItalicKey: 'tab5TextItalic',
    titleBoldKey: 'tab5LabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'tab5TextSize',
    titleUnderlineKey: 'tab5LabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'tab5Size',
    bodyBoldKey: 'tab5TextBold',
    bodyColorKey: 'tab5TextColor',
    titleItalicKey: 'tab5LabelItalic',
    titleFontKey: 'tab5Font',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'tab5TextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'tab5TextFont'
  },
  {
    labelKey: 'tab6Label',
    label: 'Notes / Reflection',
    orderKey: 'tab6DisplayOrder',
    enabledKey: 'tab6Enabled',
    enabled: true,
    section: 'tab6',
    bodyItalic: false,
    titleFillColorKey: 'tab6LabelFillColor',
    titleColorKey: 'tab6Color',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'tab6Hide',
    bodyFillColorKey: 'tab6TextFillColor',
    bodyItalicKey: 'tab6TextItalic',
    titleBoldKey: 'tab6LabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'tab6TextSize',
    titleUnderlineKey: 'tab6LabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'tab6Size',
    bodyBoldKey: 'tab6TextBold',
    bodyColorKey: 'tab6TextColor',
    titleItalicKey: 'tab6LabelItalic',
    titleFontKey: 'tab6Font',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'tab6TextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'tab6TextFont'
  },
  {
    labelKey: 'tab7Label',
    label: 'My List',
    orderKey: 'tab7DisplayOrder',
    enabledKey: 'tab7Enabled',
    enabled: false,
    section: 'myStandards',
    bodyItalic: false,
    titleFillColorKey: 'myStandardsLabelFillColor',
    titleColorKey: 'myStandardsColor',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'myStandardsHide',
    bodyFillColorKey: 'myStandardsTextFillColor',
    bodyItalicKey: 'myStandardsTextItalic',
    titleBoldKey: 'myStandardsLabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'myStandardsTextSize',
    titleUnderlineKey: 'myStandardsLabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'myStandardsSize',
    bodyBoldKey: 'myStandardsTextBold',
    bodyColorKey: 'myStandardsTextColor',
    titleItalicKey: 'myStandardsLabelItalic',
    titleFontKey: 'myStandardsFont',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'myStandardsTextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'myStandardsTextFont'
  },
  {
    labelKey: 'tab9Label',
    label: 'School List',
    orderKey: 'tab9DisplayOrder',
    enabledKey: 'tab9Enabled',
    enabled: false,
    section: 'schoolStandards',
    bodyItalic: false,
    titleFillColorKey: 'schoolStandardsLabelFillColor',
    titleColorKey: 'schoolStandardsColor',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'schoolStandardsHide',
    bodyFillColorKey: 'schoolStandardsTextFillColor',
    bodyItalicKey: 'schoolStandardsTextItalic',
    titleBoldKey: 'schoolStandardsLabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'schoolStandardsTextSize',
    titleUnderlineKey: 'schoolStandardsLabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'schoolStandardsSize',
    bodyBoldKey: 'schoolStandardsTextBold',
    bodyColorKey: 'schoolStandardsTextColor',
    titleItalicKey: 'schoolStandardsLabelItalic',
    titleFontKey: 'schoolStandardsFont',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'schoolStandardsTextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'schoolStandardsTextFont'
  },
  {
    labelKey: 'tab10Label',
    label: 'Attachments',
    orderKey: 'tab10DisplayOrder',
    enabledKey: 'tab10Enabled',
    enabled: true,
    section: 'attachments',
    bodyItalic: false,
    titleFillColorKey: 'attachmentsLabelFillColor',
    titleColorKey: 'attachmentsColor',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'attachmentsHide',
    bodyFillColorKey: 'attachmentsTextFillColor',
    bodyItalicKey: 'attachmentsTextItalic',
    titleBoldKey: 'attachmentsLabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'attachmentsTextSize',
    titleUnderlineKey: 'attachmentsLabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'attachmentsSize',
    bodyBoldKey: 'attachmentsTextBold',
    bodyColorKey: 'attachmentsTextColor',
    titleItalicKey: 'attachmentsLabelItalic',
    titleFontKey: 'attachmentsFont',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'attachmentsTextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'attachmentsTextFont'
  },
  {
    labelKey: 'tab11Label',
    label: 'Assignments',
    orderKey: 'tab11DisplayOrder',
    enabledKey: 'tab11Enabled',
    enabled: true,
    section: 'assignments',
    bodyItalic: false,
    titleFillColorKey: 'assignmentsLabelFillColor',
    titleColorKey: 'assignmentsColor',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'assignmentsHide',
    bodyFillColorKey: 'assignmentsTextFillColor',
    bodyItalicKey: 'assignmentsTextItalic',
    titleBoldKey: 'assignmentsLabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'assignmentsTextSize',
    titleUnderlineKey: 'assignmentsLabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'assignmentsSize',
    bodyBoldKey: 'assignmentsTextBold',
    bodyColorKey: 'assignmentsTextColor',
    titleItalicKey: 'assignmentsLabelItalic',
    titleFontKey: 'assignmentsFont',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'assignmentsTextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'assignmentsTextFont'
  },
  {
    labelKey: 'tab12Label',
    label: 'Assessments',
    orderKey: 'tab12DisplayOrder',
    enabledKey: 'tab12Enabled',
    enabled: true,
    section: 'assessments',
    bodyItalic: false,
    titleFillColorKey: 'assessmentsLabelFillColor',
    titleColorKey: 'assessmentsColor',
    bodyFillColor: '#ffffff',
    bodyColor: '#000000',
    showTitleKey: 'assessmentsHide',
    bodyFillColorKey: 'assessmentsTextFillColor',
    bodyItalicKey: 'assessmentsTextItalic',
    titleBoldKey: 'assessmentsLabelBold',
    titleItalic: false,
    titleColor: '#000000',
    titleFontSize: '10',
    bodyFontSizeKey: 'assessmentsTextSize',
    titleUnderlineKey: 'assessmentsLabelUnderline',
    titleBold: true,
    bodyFont: '2',
    bodyFontSize: '10',
    titleFont: '2',
    titleFontSizeKey: 'assessmentsSize',
    bodyBoldKey: 'assessmentsTextBold',
    bodyColorKey: 'assessmentsTextColor',
    titleItalicKey: 'assessmentsLabelItalic',
    titleFontKey: 'assessmentsFont',
    bodyBold: false,
    showTitle: true,
    titleUnderline: false,
    bodyUnderlineKey: 'assessmentsTextUnderline',
    titleFillColor: '#ffffff',
    bodyUnderline: false,
    bodyFontKey: 'assessmentsTextFont'
  }
];
