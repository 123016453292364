var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-data-table", {
    staticClass: "teacher-list-admin-view",
    attrs: {
      headers: _vm.localIsTeacherListFullWidth
        ? _vm.localHeaders
        : _vm.localHeadersAvatarsOnly,
      items: _vm.getAdminItems,
      "item-key": "teacherId",
      search: _vm.localSearch,
      "custom-filter": _vm.customFilter,
      "multi-sort": false,
      "fixed-header": "",
      "show-select": false,
      "items-per-page": _vm.itemsPerPage,
      "item-class": _vm.getItemClass,
      height: _vm.tableHeight,
      "single-select": "",
      "hide-default-header": "",
      "hide-default-footer": "",
      "mobile-breakpoint": 0,
    },
    on: { "click:row": _vm.teacherChanged },
    scopedSlots: _vm._u([
      {
        key: "top",
        fn: function () {
          return [
            _c(
              "div",
              {
                staticClass: "pa-2 pb-0",
                staticStyle: { position: "relative" },
              },
              [
                _c(
                  "div",
                  { staticClass: "d-table", staticStyle: { width: "100%" } },
                  [
                    _vm.localDockPosition == "left"
                      ? _c("div", { staticClass: "d-table-row ma-2" }, [
                          _vm.localIsTeacherListFullWidth
                            ? _c(
                                "div",
                                {
                                  staticClass: "d-table-cell",
                                  staticStyle: { width: "100%" },
                                },
                                [
                                  _vm.$currentUser.isDistrictAdmin ||
                                  _vm.haveMultipleSchools
                                    ? _c("v-autocomplete", {
                                        staticClass: "ml-2 mb-2",
                                        attrs: {
                                          label: _vm.$t("schoolLabel"),
                                          "hide-details": "auto",
                                          items: _vm.filterSelections,
                                          outlined: "",
                                          dense: "",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "item",
                                              fn: function (data) {
                                                return [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(data.item.text)
                                                    ),
                                                  ]),
                                                  _c(
                                                    "rostered-item-indicator",
                                                    {
                                                      key:
                                                        data.item.value +
                                                        data.item
                                                          .isItemRostered,
                                                      attrs: {
                                                        itemType: "school",
                                                        item: data.item,
                                                      },
                                                    }
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          901267436
                                        ),
                                        model: {
                                          value: _vm.currentSchoolId,
                                          callback: function ($$v) {
                                            _vm.currentSchoolId = $$v
                                          },
                                          expression: "currentSchoolId",
                                        },
                                      })
                                    : _vm._e(),
                                  _c("v-text-field", {
                                    staticClass: "ml-2 mb-2",
                                    attrs: {
                                      clearable: "",
                                      "single-line": "",
                                      "hide-details": "",
                                      "prepend-inner-icon": "fal fa-search",
                                      label: _vm.$t("searchLabel"),
                                      outlined: "",
                                      dense: "",
                                    },
                                    model: {
                                      value: _vm.localSearch,
                                      callback: function ($$v) {
                                        _vm.localSearch = $$v
                                      },
                                      expression: "localSearch",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "d-table-cell" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    icon: "",
                                    large: "",
                                    dark: "",
                                    "aria-label": _vm.$t("showContentLabel"),
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.teacherListWidthChanged.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    [
                                      !_vm.localIsTeacherListFullWidth
                                        ? [
                                            _vm._v(
                                              "fal fa-arrow-right-from-line"
                                            ),
                                          ]
                                        : [_vm._v("fal fa-arrow-left-to-line")],
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      : _c("div", { staticClass: "d-table-row ma-2" }, [
                          _c(
                            "div",
                            { staticClass: "d-table-cell" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    icon: "",
                                    large: "",
                                    dark: "",
                                    "aria-label": _vm.$t("showContentLabel"),
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.teacherListWidthChanged.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    [
                                      [
                                        _vm.localIsTeacherListFullWidth
                                          ? [
                                              _vm._v(
                                                "fal fa-arrow-right-to-line"
                                              ),
                                            ]
                                          : [
                                              _vm._v(
                                                "fal fa-arrow-left-from-line"
                                              ),
                                            ],
                                      ],
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "d-table-cell",
                              staticStyle: { width: "100%" },
                            },
                            [
                              _vm.localIsTeacherListFullWidth
                                ? _c("v-text-field", {
                                    staticClass:
                                      "d-inline-block ma-0 pa-0 pl-2",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      "append-icon": "mdi-magnify",
                                      label: _vm.$t("searchLabel"),
                                      "single-line": "",
                                      "hide-details": "",
                                    },
                                    model: {
                                      value: _vm.localSearch,
                                      callback: function ($$v) {
                                        _vm.localSearch = $$v
                                      },
                                      expression: "localSearch",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "dock-button",
                    staticStyle: {
                      position: "absolute",
                      bottom: "-20px",
                      right: "0px",
                      padding: "0px 1px",
                      cursor: "pointer",
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "v-menu",
                      {
                        attrs: { "offset-y": "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-icon",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "focus-icon-border",
                                        attrs: {
                                          "x-small": "",
                                          "aria-label": _vm.$t("dockPositions"),
                                        },
                                      },
                                      "v-icon",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_vm._v("fal fa-grip-dots-vertical")]
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _c(
                          "v-list-item-group",
                          {
                            staticClass: "teacher-list-admin-view--dock-list",
                            attrs: {
                              dense: "",
                              "aria-label": _vm.$t("dockPositions"),
                            },
                            model: {
                              value: _vm.localDockPosition,
                              callback: function ($$v) {
                                _vm.localDockPosition = $$v
                              },
                              expression: "localDockPosition",
                            },
                          },
                          _vm._l(_vm.dockPositionItems, function (item, i) {
                            return _c(
                              "v-list-item",
                              {
                                key: i,
                                attrs: { dense: "", value: item.value },
                              },
                              [
                                _c("v-list-item-title", [
                                  _vm._v(_vm._s(item.text)),
                                ]),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function ({ items }) {
          return [
            _c(
              "tbody",
              { ref: "listContainer", staticClass: "list-container" },
              _vm._l(items, function (item, index) {
                return _c(
                  "tr",
                  {
                    key: index,
                    class: _vm.getItemClass(item),
                    staticStyle: { cursor: "pointer" },
                    attrs: {
                      tabindex: "0",
                      role: "row",
                      "aria-label": _vm.getDisplayName(item),
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.teacherChanged(item)
                      },
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.teacherChanged(item)
                      },
                    },
                  },
                  [
                    _c(
                      "td",
                      {
                        class: _vm.localIsTeacherListFullWidth
                          ? "pl-2 pr-0"
                          : "pl-2 pr-1",
                      },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { left: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-badge",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.hasNotification(item),
                                              expression:
                                                "hasNotification(item)",
                                            },
                                          ],
                                          attrs: {
                                            dot: "",
                                            color: "error",
                                            "offset-x": "6",
                                            "offset-y": "6",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-avatar",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  class:
                                                    _vm.getAppliedStatus(
                                                      item
                                                    ) != 0
                                                      ? "bar-left"
                                                      : "",
                                                  style: {
                                                    "border-color":
                                                      _vm.getStatusColor(item),
                                                  },
                                                  attrs: {
                                                    "aria-hidden": "true",
                                                    size: "2.5rem",
                                                    rounded: "",
                                                  },
                                                },
                                                "v-avatar",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _vm.hasText(item.photoUrl)
                                                ? _c("v-img", {
                                                    attrs: {
                                                      src: item.photoUrl,
                                                    },
                                                  })
                                                : _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "white--text font-weight-bold",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.getInitials(item)
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-avatar",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    !_vm.hasNotification(item),
                                                  expression:
                                                    "!hasNotification(item)",
                                                },
                                              ],
                                              class:
                                                _vm.getAppliedStatus(item) != 0
                                                  ? "bar-left"
                                                  : "",
                                              style: {
                                                "border-color":
                                                  _vm.getStatusColor(item),
                                              },
                                              attrs: {
                                                "aria-hidden": "true",
                                                size: "2.5rem",
                                                rounded: "",
                                              },
                                            },
                                            "v-avatar",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _vm.hasText(item.photoUrl)
                                            ? _c("v-img", {
                                                attrs: { src: item.photoUrl },
                                              })
                                            : _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "white--text font-weight-bold",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getInitials(item)
                                                    )
                                                  ),
                                                ]
                                              ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.getDisplayName(item))),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm.localIsTeacherListFullWidth
                      ? _c(
                          "td",
                          { staticClass: "d-flex flex-nowrap" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "pt-3",
                                style: {
                                  width: item.isItemRostered
                                    ? "198px"
                                    : "222px",
                                  "overflow-x": "hidden",
                                  "white-space": "nowrap",
                                },
                              },
                              [_vm._v(_vm._s(_vm.getDisplayName(item)))]
                            ),
                            _c("rostered-item-indicator", {
                              key: item.teacherId + item.isItemRostered,
                              attrs: { itemType: "teacher", item: item },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                )
              }),
              0
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }