import events from '@/events';
import Vue from 'vue';
import router from '@/router';
import i18n from '@/plugins/i18n';
import CommonUtils from '@/utils/common-utils';

export default class AppError extends Error {
    data?: any;
    cause?: Error;
    constructor(message?: string, options?: { data?: any, cause?: Error | unknown }) {
      super(i18n.t(message || 'contactSupport') as string);
      this.data = options?.data;
      this.cause = options?.cause as Error;
    }
}

export class FormError {
  mesage: string | undefined;
  data: any;
  constructor(message?: string, data?: any) {
    this.mesage = message;
    this.data = data;
  }
}

export class SessionExpiredError extends Error {
}

export class SubscriptionExpired extends Error {
}

export function createErrOptions(e: Error | unknown) {
  return {
    data: {
      error: 'true',
      message: (e instanceof Error) ? e.message : e + ''
    },
    cause: e instanceof Error ? e : undefined
  }
}

router.onError(error => {
  if (/Loading chunk [\d]+ failed\./.test(error.message)) {
    events.$emit('chunkLoadError');
  }
});

function handleError(error: any) {
  const stack = error ? error.stack ? error.stack.toString() : (error + '') : '';
  if (CommonUtils.hasNoText(stack)) {
    console.log('Ignore no error stack.');
  } else if (stack.includes('initializeInfiniteScroll') || stack.includes('setUpInterSectionObserver') || stack.includes('IntersectionObserver')) {
    console.log('Ignore infinite scroll errors on navigate.');
    console.log(error);
  } else if (stack.includes('shouldScroll')) {
    console.log('A bug in the internal component of TinyMCE.');
    console.log(error);
  } else if (stack.includes('ResizeObserver loop completed with undelivered notifications')) {
    console.log('A bug in the internal codes of @yeger/vue2-masonry-wall.');
    console.log(error);
  } else if (error instanceof SessionExpiredError) {
    redirectToLogin();
  } else if (error instanceof SubscriptionExpired) {
    events.$emit('subscriptionExpired', error);
  } else if (error instanceof AppError) {
    if (error.cause) {
      if (error.cause instanceof SessionExpiredError) {
        redirectToLogin();
      } else if (error.cause instanceof SubscriptionExpired) {
        events.$emit('subscriptionExpired', error);
      } else {
        events.$emit('error', error);
      }
    } else {
      events.$emit('error', error);
    }
  } else {
    events.$emit('error', error);
  }
}

function redirectToLogin() {
  router.replace({ path: '/login', query: { redirect: router.currentRoute.fullPath } });
}

Vue.config.errorHandler = (error) => {
  handleError(error);
}

window.onerror = (_msg: any, _url: any, _line: any, _col: any, error: any) => {
  if (CommonUtils.hasText(_msg) && /Loading chunk [\d]+ failed\./.test(_msg)) {
    events.$emit('chunkLoadError');
  } else {
    handleError(error);
  }
}

window.addEventListener('unhandledrejection', (event: PromiseRejectionEvent) => {
  handleError(event.reason);
});
