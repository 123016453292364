































import CommonUtils from '@/utils/common-utils';
import { Component, Vue, Watch } from 'vue-property-decorator';

@Component({
  props: {
    showCloseButton: {
      type: Boolean,
      default() {
        return true;
      }
    },
    value: {
      type: Boolean,
      default() {
        return false;
      }
    }
  }
})
export default class SlidingSheet extends Vue {
  localValue = false;
  localAnimate = false;
  localPersistent = true;
  localId = CommonUtils.generateUUID();
  localDisableHandleClickOutside = false;
  showCloseButton!: boolean;

  $refs!: {
    container: HTMLDivElement
  }

  @Watch('value')
  updateLocalValue(v: boolean) {
    this.localValue = v;
  }

  @Watch('localValue')
  updateValue(v: boolean) {
    this.$emit('update:value', v);
  }

  include() {
    return [document.querySelector('.v-menu__content')]
  }

  handleClickOutside() {
    // this.localAnimate = true;
    // setTimeout(() => {
    //   this.localAnimate = false;
    // }, 500);
  }

  handleOnMouseOver() {
    this.localDisableHandleClickOutside = true;
  }

  handleOnMouseLeave() {
    this.localDisableHandleClickOutside = false;
    // const e = event.toElement || event.relatedTarget;
    // if (!this.$refs.container.contains(e)) {
    //   this.localDisableHandleClickOutside = false;
    // }
  }
}
