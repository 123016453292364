import qs from 'qs';
import axios from '@/axios';

export default class StandardServices {
  public static getStandardData(params?: any) {
    params = params || {};
    return axios.get('/services/common/standard', { params });
  }

  public static getStandards(params?: any) {
    params = params || {};
    return axios.post('getStandards', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static updateShowStates(params?: any) {
    params = params || {};
    return axios.post('updateShowStates', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static updateShowSubjects(params?: any) {
    params = params || {};
    return axios.post('updateShowSubjects', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static getStrategies(params?: any) {
    params = params || {};
    return axios.post('getStrategies', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static getSchoolList(params?: any) {
    params = params || {};
    return axios.post('getSchoolStandards', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static getMyList(params?: any) {
    params = params || {};
    return axios.post('getMyStandards', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }
}
