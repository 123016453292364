























































import CommonUtils from '@/utils/common-utils';
import DateTimeUtils from '@/utils/date-time-utils';
import moment from 'moment';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { VTextField } from 'vuetify/lib';
import { extend } from 'vee-validate';

const settings = namespace('settings');

@Component
export default class DateField extends Vue {
  @Prop({
    type: Boolean,
    default: () => { return false; }
  })
  dense!: boolean;

  @Prop({
    type: String,
    default: ''
  })
  label!: string;

  @Prop({
    type: String,
    default: ''
  })
  ariaLabel!: string;

  @Prop({
    type: Array,
    default: () => { return []; }
  })
  messages!: Array<any>;

  @Prop({
    type: String,
    default: 'top left'
  })
  origin!: string;

  @Prop({
    type: [String, Boolean],
    default: ''
  })
  prependInnerIcon!: string | boolean;

  @Prop({
    type: [String, Boolean],
    default: false
  })
  appendOuterIcon!: string | boolean;

  @Prop({
    type: [String],
    default: ''
  })
  appendOuterLabel!: string;

  @Prop({
    type: [String, Boolean],
    default: false
  })
  hideDetails!: string | boolean;

  @Prop({
    type: Boolean,
    default: false
  })
  required!: boolean;

  @Prop({
    type: Boolean,
    default: false
  })
  disabled!: boolean;

  @Prop({
    type: Boolean,
    default: false
  })
  readonly!: boolean;

  @Prop({
    type: String,
    default: ''
  })
  value!: string;

  @Prop({
    type: String,
    default: ''
  })
  errorMessage!: string;

  @Prop({
    type: Array,
    default: () => { return []; }
  })
  rules!: Array<any>;

  @Prop({
    type: Boolean,
    default: false
  })
  clearable!: boolean;

  localRef = CommonUtils.generateUUID();
  localMenu = false;
  localRawDate = '';
  localErrorMessage = '';
  shift = 0;

  @settings.Getter
  getSettings!: any;

  @settings.Getter('getUserInfo')
  userInfo!: any;

  @settings.Getter('getGlobalLanguage')
  globalLanguage!: any;

  $refs!: {
    textField: InstanceType<typeof VTextField>,
    datePicker: any
  }

  get localRules() {
    const rules: any = this.validateDateRule();
    if (this.required) {
      rules.push((v: any) => !!v || 'Required.');
    }
    return rules.concat(this.rules);
  }

  @Watch('localMenu')
  focusDatePicker() {
    if (this.localMenu) {
      this.$nextTick(() => {
        new Promise(resolve => setTimeout(resolve, 100)).then(() => {
          try {
            this.$refs.datePicker.$el.querySelector('.v-date-picker-header__value').querySelector('button').focus()
          } catch (e) {
            console.log(this.$t('focusFailed'));
          }
        });
      });
    }
  }

  @Watch('value')
  updateLocalValue(v: string) {
    if (CommonUtils.hasText(v)) {
      this.localRawDate = v;
    } else {
      this.value = '';
      this.localRawDate = '';
    }
  }

  get hasAppendOuterIcon() {
    return this.appendOuterIcon !== false && CommonUtils.hasText(this.appendOuterIcon);
  }

  get localValueDisplay() {
    if (this.isValidDate(this.localRawDate)) {
      return DateTimeUtils.formatToShow(this.localRawDate, this.getSettings);
    } else {
      return this.localRawDate;
    }
  }

  set localValueDisplay(dateInput: any) {
    if (!dateInput) dateInput = '';
    const displaySettings = this.userInfo.displaySettings;
    const dateFormat = +displaySettings.dateStyling.dateFormat;
    const dateDelimiter = displaySettings.dateStyling.dateDelimiter;

    if (dateFormat === 1) { // MM/DD/YYYY
      if (dateInput.length === 1 && !isNaN(dateInput) && +dateInput > 1) {
        dateInput = '0' + dateInput + dateDelimiter;
      }
      if (dateInput.length === 2 && !dateInput.includes(dateDelimiter)) {
        dateInput = dateInput + dateDelimiter;
      } else if (dateInput.length === 2 && dateInput === (1 + dateDelimiter)) {
        dateInput = '0' + dateInput;
      }

      if (dateInput.length === 4 && !isNaN(dateInput.charAt(3)) && +dateInput.charAt(3) > 3) {
        dateInput = dateInput.substring(0, 3) + '0' + dateInput.charAt(3);
      }

      if (dateInput.length === 5) {
        if (dateInput.charAt(4) === dateDelimiter) {
          dateInput = dateInput.substring(0, 3) + '0' + dateInput.substring(3);
        } else {
          dateInput = dateInput + dateDelimiter;
        }
      }
    } else if (dateFormat === 2) { // DD/MM/YYYY
      if (dateInput.length === 1 && !isNaN(dateInput) && +dateInput > 3) {
        dateInput = '0' + dateInput + dateDelimiter;
      }

      if (dateInput.length === 2 && !dateInput.includes(dateDelimiter)) {
        dateInput = dateInput + dateDelimiter;
      } else if (dateInput.length === 2 && dateInput.includes(dateDelimiter)) {
        dateInput = '0' + dateInput;
      }

      if (dateInput.length === 4 && !isNaN(dateInput.charAt(3)) && +dateInput.charAt(3) > 1) {
        dateInput = dateInput.substring(0, 3) + '0' + dateInput.charAt(3);
      }

      if (dateInput.length === 5 && dateInput.charAt(4) === dateDelimiter) {
        dateInput = dateInput.substring(0, 3) + '0' + dateInput.substring(3, 5);
      } else if (dateInput.length === 5) {
        dateInput = dateInput + dateDelimiter;
      }
    }

    if (dateInput.length === 8) {
      const yearInput = dateInput.substring(6, 8);
      if (!isNaN(yearInput) && +yearInput !== 20 && +yearInput !== 19) {
        if (+yearInput >= 60) {
          dateInput = dateInput.substring(0, 6) + '19' + yearInput;
        } else {
          dateInput = dateInput.substring(0, 6) + '20' + yearInput;
        }
      }
    }

    if (this.isValidDate(dateInput)) {
      this.localRawDate = DateTimeUtils.formatToSave(dateInput);
      this.emitUpdates();
    } else {
      this.localRawDate = dateInput;
    }
  }

  get localPickerValue() {
    if (CommonUtils.hasText(this.localRawDate) && this.isValidDate(this.localRawDate)) {
      return moment(this.localRawDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
    } else {
      return '';
    }
  }

  set localPickerValue(v: string) {
    if (CommonUtils.hasText(v)) {
      this.localRawDate = moment(v, 'YYYY-MM-DD').format('MM/DD/YYYY');
      this.emitUpdates();
      this.localErrorMessage = '';
    } else {
      this.localRawDate = '';
      this.localErrorMessage = '';
    }
  }

  get getGlobalLanguage() {
    return this.globalLanguage || 'en';
  }

  emitUpdates() {
    this.$emit('update:value', this.localRawDate);
    this.$emit('change', this.localRawDate);
    this.$emit('input', this.localRawDate);
  }

  isValidDate(v: string) {
    if (v && v.length > 0) {
      return DateTimeUtils.isValidUserDate(v);
    }
    return true;
  }

  veeValidateDateRule(dateField: string) {
    return (!CommonUtils.hasText(dateField) || this.isValidDate(dateField)) || this.$t('enterValidDate') as string === '';
  }

  validateDateRule() {
    return [(d: any) => (!CommonUtils.hasText(d) || this.isValidDate(d)) || this.$t('enterValidDate') as string];
  }

  @Watch('errorMessage')
  updateLocalErrorMessage(v: string) {
    this.localErrorMessage = v;
  }

  handleClick() {
    this.$emit('click');
    this.localMenu = true;
    this.shift = Math.max(this.$refs.textField.$el.getBoundingClientRect().left + 300 - window.innerWidth, 0);
  }

  handleOnBlur() {
    this.$emit('blur');
  }

  focus() {
    (this.$refs.textField as any).focus();
  }

  created() {
    const validationDate = {
      params: [],
      validate: this.veeValidateDateRule
    }
    extend('validDate', validationDate);

    if (CommonUtils.hasText(this.$props.value)) {
      this.localRawDate = this.$props.value;
      this.localErrorMessage = this.$props.errorMessage;
    }
  }
}
