import qs from 'qs';
import axios from '@/axios';

export default class MessagesServices {
  public static getMessages(params?: any) {
    params = params || {};
    return axios.post('getComments', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static getMessage(params?: any) {
    params = params || {};
    return axios.post('/services/planbook/comment/getComment', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static getRecipients(params?: any) {
    params = params || {};
    return axios.post('getCommentsTo', qs.stringify(params, {
      arrayFormat: 'repeat'
    }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static addMessage(params?: any) {
    params = params || {};
    return axios.post('addComment', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static deleteMessage(params?: any) {
    params = params || {};
    return axios.post('deleteComment', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static deleteMessages(params?: any) {
    params = params || {};
    return axios.post('services/planbook/comment/deleteBatch', qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }
}
