import { render, staticRenderFns } from "./MultiSelectField.vue?vue&type=template&id=2ffcbed6&"
import script from "./MultiSelectField.vue?vue&type=script&lang=ts&"
export * from "./MultiSelectField.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCard,VCardText,VCheckbox,VChipGroup,VDivider,VList,VListItem,VListItemAction,VListItemContent,VListItemTitle,VMenu,VTextField,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/Users/adriandaluz/Development/git/planbook-web-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2ffcbed6')) {
      api.createRecord('2ffcbed6', component.options)
    } else {
      api.reload('2ffcbed6', component.options)
    }
    module.hot.accept("./MultiSelectField.vue?vue&type=template&id=2ffcbed6&", function () {
      api.rerender('2ffcbed6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/core/MultiSelectField.vue"
export default component.exports