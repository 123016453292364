import { render, staticRenderFns } from "./Loading.vue?vue&type=template&id=b13e295a&"
import script from "./Loading.vue?vue&type=script&lang=ts&"
export * from "./Loading.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VApp,VSheet})


/* hot reload */
if (module.hot) {
  var api = require("/Users/adriandaluz/Development/git/planbook-web-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b13e295a')) {
      api.createRecord('b13e295a', component.options)
    } else {
      api.reload('b13e295a', component.options)
    }
    module.hot.accept("./Loading.vue?vue&type=template&id=b13e295a&", function () {
      api.rerender('b13e295a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Loading.vue"
export default component.exports