import axios from '@/axios';
import qs from 'qs';

export default class DayServices {
  public static substituteDay(params?: any): Promise<any> {
    params = params || {};
    return axios.post('services/planbook/day/substitute', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }
}
