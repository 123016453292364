

































































































































































































































































































































































































































































































































































































































































































































































import { PlanbookStorage } from '@/common/planbook-storage';
import CommonUtils from '@/utils/common-utils';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const settings = namespace('settings');
const classes = namespace('classes');
const plans = namespace('plans');
const drawer = namespace('drawer');

@Component
export default class ViewSettings extends Vue {
  @settings.State
  userMode!: string;

  @settings.Getter('getSettings')
  settings!: any;

  @settings.Getter('getViewStatusItems')
  viewStatusItems!: any;

  @settings.Getter('getViewSchoolStatusItems')
  viewSchoolStatusItems!: Array<any>;

  @settings.Getter('getLessonSectionViewItems')
  viewLessonSectionItems!: any;

  @settings.Getter('isLessonSectionAndEnabled')
  isLessonSectionAndEnabled!: (section: string) => boolean;

  @settings.Getter('getViewSettingsId')
  viewSettingsId!: any;

  @settings.Getter('getSchoolId')
  schoolId!: number;

  @settings.Getter('getShowSnackbarNotifications')
  showSnackbarNotifications!: boolean;

  @classes.Getter('getClassItems')
  viewClassItems!: any;

  @drawer.Mutation
  setDrawerBottom!: (v: boolean) => void;

  @plans.Action
  reloadCurrentLessonsEvents!: (param?: any) => Promise<any>;

  @settings.Action
  saveViewSettings!: (param?: any) => Promise<any>;

  @plans.Getter('getSelectedClassItems')
  selectedClassItems!: any;

  @plans.Getter('getSelectedSchoolStatusItems')
  selectedSchoolStatusItems!: any;

  @plans.Getter('getSelectedStatusItems')
  selectedStatusItems!: any;

  @plans.Getter('getSelectedLessonSectionItems')
  selectedLessonSectionItems!: any;

  @plans.Action
  selectLessonSection!: (value: any) => Promise<any>;

  @plans.Action
  selectSchoolStatus!: (value: any) => Promise<any>;

  @plans.Action
  selectStatus!: (value: any) => Promise<any>;

  @plans.Action
  selectClass!: (value: any) => Promise<any>;

  @drawer.State
  drawerBottom!: boolean;

  @drawer.State
  drawerMode!: number;

  @settings.Getter('getBrowserHeight')
  browserHeight!: number;

  contentKey = 'initial';

  $refs!: {
    spacer: HTMLElement;
  };

  get isNotEmpty() {
    return CommonUtils.isNotEmpty;
  }

  get hasText() {
    return CommonUtils.hasText;
  }

  get showSchoolStatus() {
    return this.schoolId !== 0 && this.viewSchoolStatusItems.length > 0;
  }

  get windowWidth() {
    return this.$vuetify.breakpoint.width;
  }

  savePreferences() {
    CommonUtils.showLoading();
    Promise.resolve()
      .then(async () => {
        if (this.userMode === 'S') {
          return new Promise(resolve => setTimeout(resolve, 500)).then(() => {
            const selectedItems = this.selectedLessonSectionItems;
            PlanbookStorage.set(
              'studentViewStandardsId',
              CommonUtils.booleanToString(
                selectedItems.includes('studentViewStandardsId')
              )
            );
            PlanbookStorage.set(
              'studentViewStandardsDesc',
              CommonUtils.booleanToString(
                selectedItems.includes('studentViewStandardsDesc')
              )
            );
            PlanbookStorage.set(
              'studentViewAttachments',
              CommonUtils.booleanToString(
                selectedItems.includes('studentViewAttachments')
              )
            );
          });
        } else {
          return this.saveViewSettings();
        }
      }).then(() => {
        if (this.showSnackbarNotifications) {
          this.$snotify.success(this.$t('statusMsg46') as string);
        }

        return Promise.resolve();
      })
      .finally(() => {
        CommonUtils.hideLoading();
        this.setDrawerBottom(false);
      });
  }

  get masonryColumnWidth() {
    if (this.windowWidth >= 1820) {
      return 340;
    } else if (this.windowWidth >= 1700 && this.windowWidth < 1820) {
      return 315;
    } else if (this.windowWidth >= 1595 && this.windowWidth < 1700) {
      return 300;
    } else {
      return 280;
    }
  }
}
