var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-select", {
    attrs: {
      "hide-details": "auto",
      items: _vm.items,
      "item-key": _vm.itemId,
      "item-value": _vm.itemId,
      "item-text": _vm.itemText,
      multiple: "",
      outlined: "",
      chips: "",
      label: _vm.label,
      error: _vm.error,
      "error-messages": _vm.errorMessages,
      "aria-required": _vm.required
    },
    scopedSlots: _vm._u(
      [
        {
          key: "prepend-item",
          fn: function() {
            return [
              _c(
                "v-list-item",
                {
                  attrs: { ripple: "" },
                  on: {
                    mousedown: function($event) {
                      $event.preventDefault()
                    },
                    click: _vm.selectAllItems
                  }
                },
                [
                  _c(
                    "v-list-item-action",
                    [
                      _c("v-checkbox", {
                        attrs: { "input-value": _vm.isAllSelected }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [
                        _vm._v("All " + _vm._s(_vm.label))
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        },
        {
          key: "selection",
          fn: function(data) {
            return [
              _vm.hasText(data.item.toolTip)
                ? [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { "open-delay": "100", bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-chip",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            "text-color": _vm.getItemTextColor(
                                              data.item
                                            ),
                                            color: data.item[_vm.itemColor],
                                            "input-value": data.selected,
                                            close: ""
                                          },
                                          on: {
                                            click: data.select,
                                            "click:close": function($event) {
                                              return _vm.remove(data.item)
                                            }
                                          }
                                        },
                                        "v-chip",
                                        data.attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(data.item[_vm.itemText]) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [_c("span", [_vm._v(_vm._s(data.item.toolTip))])]
                    )
                  ]
                : [
                    _c(
                      "v-chip",
                      _vm._b(
                        {
                          attrs: {
                            "text-color": _vm.getItemTextColor(data.item),
                            color: data.item[_vm.itemColor],
                            "input-value": data.selected,
                            close: ""
                          },
                          on: {
                            click: data.select,
                            "click:close": function($event) {
                              return _vm.remove(data.item)
                            }
                          }
                        },
                        "v-chip",
                        data.attrs,
                        false
                      ),
                      [_vm._v(" " + _vm._s(data.item[_vm.itemText]) + " ")]
                    )
                  ]
            ]
          }
        },
        _vm._l(_vm.$scopedSlots, function(_, scopedSlotName) {
          return {
            key: scopedSlotName,
            fn: function(slotData) {
              return [_vm._t(scopedSlotName, null, null, slotData)]
            }
          }
        }),
        _vm._l(_vm.$slots, function(_, slotName) {
          return {
            key: slotName,
            fn: function() {
              return [_vm._t(slotName)]
            },
            proxy: true
          }
        })
      ],
      null,
      true
    ),
    model: {
      value: _vm.localSelected,
      callback: function($$v) {
        _vm.localSelected = $$v
      },
      expression: "localSelected"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }