var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "scrollContainer",
      staticClass: "scroll-container",
      style: _vm.infiniteScroll ? "height: inherit;" : ""
    },
    [
      _c("v-simple-table", {
        attrs: { height: _vm.infiniteScroll ? _vm.tableHeight : "" },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function() {
                return [
                  _c(
                    "tbody",
                    [
                      _vm._l(_vm.localItems, function(item, i) {
                        return _c(
                          "tr",
                          {
                            key: item.key,
                            staticClass: "editor-list-item",
                            class: _vm.getRowClass(item),
                            style: _vm.rowStyle,
                            on: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.$emit("itemEnter", item, i)
                              }
                            }
                          },
                          [
                            _vm.showShortValue && !_vm.useVueHTMLShortValue
                              ? _c(
                                  "td",
                                  {
                                    attrs: { width: "20%" },
                                    on: {
                                      click: function($event) {
                                        !_vm.disabled &&
                                          _vm.$emit("itemClick", item, i)
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(item.shortValue))]
                                )
                              : _vm._e(),
                            _vm.showShortValue && _vm.useVueHTMLShortValue
                              ? _c("td", {
                                  attrs: { width: "20%" },
                                  domProps: {
                                    innerHTML: _vm._s(item.shortValue)
                                  },
                                  on: {
                                    click: function($event) {
                                      !_vm.disabled &&
                                        _vm.$emit("itemClick", item, i)
                                    }
                                  }
                                })
                              : _vm._e(),
                            _c(
                              "td",
                              {
                                attrs: {
                                  width: _vm.showShortValue ? "80%" : "100%"
                                },
                                on: {
                                  click: function($event) {
                                    !_vm.disabled &&
                                      _vm.$emit("itemClick", item, i)
                                  }
                                }
                              },
                              [
                                _vm._t(
                                  "value",
                                  function() {
                                    return [
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(item.value)
                                        }
                                      })
                                    ]
                                  },
                                  null,
                                  item
                                )
                              ],
                              2
                            ),
                            _c(
                              "td",
                              {
                                on: {
                                  keyup: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.$emit("otherAction", item, i)
                                  }
                                }
                              },
                              [
                                _vm._t("other-actions", null, null, {
                                  item: item,
                                  index: i,
                                  disabled: _vm.disabled
                                })
                              ],
                              2
                            ),
                            _vm.showDelete
                              ? _c(
                                  "td",
                                  [
                                    item.isBoundByDate
                                      ? _vm._t(
                                          "actions",
                                          function() {
                                            return [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          var attrs = ref.attrs
                                                          return [
                                                            _c(
                                                              "div",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {},
                                                                  "div",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "border-padding",
                                                                    staticStyle: {
                                                                      "margin-bottom":
                                                                        "5px"
                                                                    },
                                                                    attrs: {
                                                                      icon: "",
                                                                      "x-small":
                                                                        "",
                                                                      disabled:
                                                                        "",
                                                                      "aria-label":
                                                                        _vm.removeLabel
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "fal fa-trash-alt"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "associatedByDateLabel"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ]
                                          },
                                          null,
                                          {
                                            item: item,
                                            index: i,
                                            disabled: _vm.disabled
                                          }
                                        )
                                      : !_vm.hideStudentDelete
                                      ? _vm._t(
                                          "actions",
                                          function() {
                                            return [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "border-padding",
                                                  staticStyle: {
                                                    "margin-bottom": "5px"
                                                  },
                                                  attrs: {
                                                    icon: "",
                                                    "x-small": "",
                                                    "aria-label":
                                                      _vm.removeLabel
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      !_vm.disabled &&
                                                        _vm.$emit(
                                                          "deleteItem",
                                                          item,
                                                          i
                                                        )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("fal fa-trash-alt")
                                                  ])
                                                ],
                                                1
                                              )
                                            ]
                                          },
                                          null,
                                          {
                                            item: item,
                                            index: i,
                                            disabled: _vm.disabled
                                          }
                                        )
                                      : _vm._e()
                                  ],
                                  2
                                )
                              : _vm._e()
                          ]
                        )
                      }),
                      _vm.infiniteScroll &&
                      _vm.canLoadMore &&
                      _vm.filteredItems.length !== _vm.infiniteScrollList.length
                        ? _c("tr", [
                            _vm.showShortValue
                              ? _c("td", { attrs: { width: "20%" } })
                              : _vm._e(),
                            _c(
                              "td",
                              {
                                attrs: {
                                  width: _vm.showShortValue ? "80%" : "100%"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    style: {
                                      marginLeft: _vm.showShortValue
                                        ? "-10%"
                                        : "20%"
                                    },
                                    attrs: {
                                      align: "center",
                                      justify: "center"
                                    }
                                  },
                                  [
                                    _vm.isLoadingMore || _vm.initialLoad
                                      ? _c("v-progress-circular", {
                                          attrs: {
                                            color: "primary",
                                            indeterminate: ""
                                          }
                                        })
                                      : _c(
                                          "span",
                                          {
                                            staticStyle: { "font-size": "12px" }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "scrollDownToLoadMoreLabel"
                                                  )
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "v-icon",
                                              { attrs: { small: "" } },
                                              [_vm._v("fal fa-arrow-down")]
                                            )
                                          ],
                                          1
                                        )
                                  ],
                                  1
                                )
                              ]
                            ),
                            _c("td"),
                            _vm.showDelete ? _c("td") : _vm._e()
                          ])
                        : _vm._e()
                    ],
                    2
                  ),
                  _vm.infiniteScroll
                    ? _c("div", {
                        ref: "sentinel",
                        staticClass: "sentinel",
                        style: {
                          "margin-bottom":
                            _vm.canLoadMore &&
                            !_vm.isLoadingMore &&
                            _vm.filteredItems.length !==
                              _vm.infiniteScrollList.length
                              ? "70px"
                              : ""
                        }
                      })
                    : _vm._e()
                ]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }