import AppError, { createErrOptions, SessionExpiredError } from '@/errors';
import EmailServices from '@/services/email-services';
import { Action, Module, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true })
export default class Email extends VuexModule {
  @Action({ rawError: true })
  async sendEmail(param: any) {
    try {
      const resp = await EmailServices.send(param);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          return Promise.resolve({ resp });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }
}
