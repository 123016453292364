


























































































































import { timeFormat12Hr, timeFormat24Hr } from '@/constants';
import { FormField } from '@/types/global.types';
import CommonUtils from '@/utils/common-utils';
import DateTimeUtils from '@/utils/date-time-utils';
import ld from 'lodash';
import moment from 'moment';
import { extend } from 'vee-validate';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue';
import { VCard } from 'vuetify/lib';
import { namespace } from 'vuex-class';

const settings = namespace('settings');

@Component({
  components: {
    VueTimepicker
  }
})
export default class DateTimeRangeField extends Vue implements FormField {
    @Prop({ required: false })
    label!: string;

    @Prop({ required: false })
    startLabel!: string;

    @Prop({ required: false })
    endLabel!: string;

    @Prop({ required: false, default: '' })
    id!: string;

    @Prop({ required: false, default: '' })
    start!: string;

    @Prop({ required: false, default: '' })
    end!: string;

    @Prop({ required: false, default: '' })
    initialStart!: string;

    @Prop({ required: false, default: '' })
    initialEnd!: string;

    @Prop({ required: false, default: '' })
    min!: string;

    @Prop({ required: false, default: '' })
    max!: string;

    @Prop({ required: false, default: false })
    required!: boolean;

    @Prop({ required: false, default: 'date' })
    type!: string;

    @Prop({ required: false, default: false })
    disabled!: boolean;

    @Prop({ required: false, default: false })
    readonly!: boolean;

    @Prop({ required: false, default: 'anchor' })
    origin!: string;

    @Prop({ required: false, default: false })
    dense!: boolean;

    @Prop({ required: false, default: () => [] })
    rules!: Array<any>;

    @Prop({ type: [String, Boolean], required: false, default: false })
    hideDetails!: any;

    @Prop({ required: false, type: Object })
    value!: any;

    @Prop({ required: false, default: false })
    pill!: boolean;

    @Prop({ required: false, default: false })
    offsetTop!: boolean;

    @Prop({ required: false, default: true })
    autoPopulateRange!: boolean;

    @settings.Getter('getTimeFormat')
    timeFormat!: number;

    @settings.Getter('getUserInfo')
    userInfo!: any;

    @settings.Getter('getGlobalLanguage')
    globalLanguage!: any;

    localFocused = false;

    localMenu = false;

    hideStartTimeClearButton = true;

    hideEndTimeClearButton = true;

    localPickerValue = '';

    localMessage = '';

    currentTarget: HTMLInputElement | undefined;

    localRefreshKey = CommonUtils.generateUUID();

    currentField = '';

    shift = 0;

    startTimeErrors:any[] = [];
    endTimeErrors:any[] = [];

    $refs!: {
      card: InstanceType<typeof VCard>,
      observer: any,
      datePicker: any
    }

    created() {
      this.$nextTick(() => {
        const validationDate = {
          params: ['end'],
          validate: this.veeValidateDisplay
        }
        extend('validDateTime' + this.localRefreshKey, validationDate);
      })
    }

    @Watch('localMenu')
    focusDatePicker() {
      if (this.localMenu) {
        this.$nextTick(() => {
          new Promise(resolve => setTimeout(resolve, 100)).then(() => {
            try {
              this.$refs.datePicker.$el.querySelector('.v-date-picker-header__value').querySelector('button').focus()
            } catch (e) {
              console.log(this.$t('focusFailed'));
            }
          });
        });
      }
    }

    get paramStart() {
      if (this.value) {
        return this.value.start;
      } else {
        return this.start;
      }
    }

    get vueTimepickerFormat() {
      return this.timeFormat === 1 ? 'HH:mm' : 'h:mm A';
    }

    get paramEnd() {
      if (this.value) {
        return this.value.end;
      } else {
        return this.end;
      }
    }

    get showLocalMessage() {
      return !this.hideDetails || (this.hideDetails === 'auto' && CommonUtils.hasText(this.localMessage));
    }

    get localStartLabel() {
      if (CommonUtils.hasText(this.startLabel)) {
        return this.startLabel + this.requiredSymbol;
      } else if (this.type === 'time') {
        return this.$t('startTimeLabel') + this.requiredSymbol;
      }
      return this.$t('startDateLabel') + this.requiredSymbol;
    }

    get localEndLabel() {
      if (CommonUtils.hasText(this.endLabel)) {
        return this.endLabel + this.requiredSymbol;
      } else if (this.type === 'time') {
        return this.$t('endTimeLabel') + this.requiredSymbol;
      }
      return this.$t('endDateLabel') + this.requiredSymbol;
    }

    get requiredSymbol() {
      return (this.required) ? '*' : '';
    }

    get localLabel() {
      if (CommonUtils.hasText(this.label)) {
        return this.label;
      } else if (this.type === 'time') {
        return this.$t('timeRangeLabel');
      }
      return this.$t('dateRangeLabel');
    }

    get minHour() {
      return moment(this.min, 'hh:mm:ss').hour();
    }

    get maxHour() {
      return moment(this.max, 'hh:mm:ss').hour();
    }

    get minMinutes() {
      return moment(this.min, 'hh:mm:ss').minute();
    }

    get maxMinutes() {
      return moment(this.max, 'hh:mm:ss').minute();
    }

    get localStartTimeHour() {
      return moment(this.localStart, this.vueTimepickerFormat).hour();
    }

    get localStartTimeMinutes() {
      return moment(this.localStart, this.vueTimepickerFormat).minute();
    }

    get localEndTimeHour() {
      return moment(this.localEnd, this.vueTimepickerFormat).hour();
    }

    get localEndTimeMinutes() {
      return moment(this.localEnd, this.vueTimepickerFormat).minute();
    }

    get startTimeHourRange() {
      let localMin = this.minHour;
      let localMax = this.maxHour;

      if (localMin !== localMax) {
        if (this.localStartTimeMinutes < this.minMinutes) {
          localMin = (localMin + 1) % 24;
        }

        if (this.localStartTimeMinutes > this.maxMinutes) {
          localMax = ((((localMax - 1) % 24) + 24) % 24);
        }
      }

      let localMinForParam = '';
      let localMaxForParam = '';
      if (this.localTimeFormat === '24hr') {
        localMinForParam = localMin.toString();
        localMaxForParam = localMax.toString();
      } else {
        localMinForParam = moment(localMin.toString(), 'H').format('ha')
        localMinForParam = localMinForParam.slice(0, localMinForParam.length - 1);
        localMaxForParam = moment(localMax.toString(), 'H').format('ha')
        localMaxForParam = localMaxForParam.slice(0, localMaxForParam.length - 1);
      }

      return [[localMinForParam, localMaxForParam]];
    }

    get startTimeMinuteRange() {
      let localMin = 0;
      let localMax = 59;

      if (this.localStartTimeHour === this.minHour) {
        localMin = this.minMinutes;
      }

      if (this.localStartTimeHour === this.maxHour) {
        localMax = this.maxMinutes;
      }

      return [[localMin, localMax]];
    }

    get endTimeHourRange() {
      let localMin = this.minHour;
      let localMax = this.maxHour;

      if (localMin !== localMax) {
        if (this.localEndTimeMinutes < this.minMinutes) {
          localMin = (localMin + 1) % 24;
        }

        if (this.localEndTimeMinutes > this.maxMinutes) {
          localMax = ((((localMax - 1) % 24) + 24) % 24);
        }
      }

      let localMinForParam = '';
      let localMaxForParam = '';
      if (this.localTimeFormat === '24hr') {
        localMinForParam = localMin.toString();
        localMaxForParam = localMax.toString();
      } else {
        localMinForParam = moment(localMin.toString(), 'H').format('ha')
        localMinForParam = localMinForParam.slice(0, localMinForParam.length - 1);
        localMaxForParam = moment(localMax.toString(), 'H').format('ha')
        localMaxForParam = localMaxForParam.slice(0, localMaxForParam.length - 1);
      }

      return [[localMinForParam, localMaxForParam]];
    }

    get endTimeMinuteRange() {
      let localMin = 0;
      let localMax = 59;

      if (this.localEndTimeHour === this.minHour) {
        localMin = this.minMinutes;
      }

      if (this.localEndTimeHour === this.maxHour) {
        localMax = this.maxMinutes;
      }

      return [[localMin, localMax]];
    }

    get localRules() {
      return [this.validateFormats].concat(this.rules);
    }

    veeValidateDisplay(start: string, params: any) {
      const end = params[0];
      if (this.type === 'time') {
        if (CommonUtils.hasText(start)) {
          if (this.localTimeFormat === 'ampm') {
            const time = moment(start, ['h:mmA', 'h:mm A'], true);
            if (!time.isValid()) {
              return false;
            }
          } else {
            if (!timeFormat24Hr.test(start)) {
              return false;
            }
          }
        }
        if (CommonUtils.hasText(end)) {
          if (this.localTimeFormat === '24hr') {
            if (!timeFormat24Hr.test(end)) {
              return false;
            }
          } else {
            const time = moment(end, ['h:mmA', 'h:mm A'], true);
            if (!time.isValid()) {
              return false;
            }
          }
        }
        if (moment(this.localEnd, this.vueTimepickerFormat).isBefore(moment(this.localStart, this.vueTimepickerFormat))) {
          return false;
        }
        if (CommonUtils.isNotEmpty(this.startTimeErrors)) {
          return false;
        }
        if (CommonUtils.isNotEmpty(this.endTimeErrors)) {
          return false;
        }
      } else if (this.type === 'date') {
        if (CommonUtils.hasText(start) && !this.isValid(start)) {
          return false;
        }
        if (CommonUtils.hasText(end) && !this.isValid(end)) {
          return false;
        }
        if (CommonUtils.hasText(start) && this.isValid(start) && CommonUtils.hasText(end) && this.isValid(end)) {
          if (DateTimeUtils.isThisDateAfterThatDate(start, end)) {
            return false;
          }
        }
      }
      return true;
    }

    validateFormats(start: string, end: string) {
      if (this.type === 'time') {
        if (CommonUtils.hasText(start)) {
          const time = moment(start, ['h:mmA', 'h:mm A'], true);
          if (!time.isValid()) {
            return this.$t('enterValidStartTime1') as string;
          }
        } else if (this.required) {
          return this.$t('startTimeRequired') as string;
        }
        if (CommonUtils.hasText(end)) {
          const time = moment(end, ['h:mmA', 'h:mm A'], true);
          if (!time.isValid()) {
            return this.$t('enterValidEndTime1') as string;
          }
        } else if (this.required) {
          return this.$t('endTimeRequired') as string;
        }
        if (moment(this.localEnd, this.vueTimepickerFormat).isBefore(moment(this.localStart, this.vueTimepickerFormat))) {
          return this.$t('endTimeMustBeAfterStartTimeMsg') as string;
        }

        if (CommonUtils.isNotEmpty(this.startTimeErrors)) {
          return this.$t('startTimeOutofRangeErrorMsg') as string;
        }
        if (CommonUtils.isNotEmpty(this.endTimeErrors)) {
          return this.$t('endTimeOutofRangeErrorMsg') as string;
        }
      } else if (this.type === 'date') {
        if (CommonUtils.hasText(start) && !this.isValid(start)) {
          return this.$t('enterValidStartDate') as string;
        }
        if (CommonUtils.hasText(end) && !this.isValid(end)) {
          return this.$t('enterValidEndDate') as string;
        }
        if (CommonUtils.hasText(start) && this.isValid(start) && CommonUtils.hasText(end) && this.isValid(end)) {
          if (DateTimeUtils.isThisDateAfterThatDate(start, end)) {
            return this.$t('dateErrorMsg1') as string;
          }
        }
      }
      return '';
    }

    get hasError() {
      return CommonUtils.hasText(this.localMessage);
    }

    get hasStart() {
      return CommonUtils.hasText(this.paramStart);
    }

    get hasEnd() {
      return CommonUtils.hasText(this.paramEnd);
    }

    get localStart() {
      return this.formatDataStoreToPicker(this.paramStart)
    }

    set localStart(val: string) {
      const dates = {
        start: this.formatPickerToDataStore(val),
        end: this.formatPickerToDataStore(this.localEnd)
      }
      this.$emit('update:start', dates.start);
      this.$emit('update:value', dates);
      this.$emit('change', dates);
      this.$emit('input', dates);
    }

    get localEnd() {
      return this.formatDataStoreToPicker(this.paramEnd)
    }

    set localEnd(val: string) {
      const dates = {
        start: this.formatPickerToDataStore(this.localStart),
        end: this.formatPickerToDataStore(val)
      }
      this.$emit('update:end', dates.end);
      this.$emit('update:value', dates);
      this.$emit('change', dates);
      this.$emit('input', dates);
    }

    get localStartForDisplay() {
      if (this.isValid(this.localStart)) {
        return this.formatPickerToDisplay(this.localStart)
      } else {
        return this.localStart;
      }
    }

    set localStartForDisplay(val: string) {
      if (this.type === 'date') {
        this.assistDate(ld.clone(val), true);
      } else {
        this.assistTime(ld.clone(val), true);
      }
    }

    get localEndForDisplay() {
      if (this.isValid(this.localEnd)) {
        return this.formatPickerToDisplay(this.localEnd)
      } else {
        return this.localEnd;
      }
    }

    set localEndForDisplay(val: string) {
      if (this.type === 'date') {
        this.assistDate(ld.clone(val), false);
      } else {
        this.assistTime(ld.clone(val), false);
      }
    }

    get getGlobalLanguage() {
      return this.globalLanguage || 'en';
    }

    hoverEnterTimepickerStart() {
      this.hideStartTimeClearButton = false;
    }

    hoverExitTimepickerStart() {
      this.hideStartTimeClearButton = true;
    }

    hoverEnterTimepickerEnd() {
      this.hideEndTimeClearButton = false;
    }

    hoverExitTimepickerEnd() {
      this.hideEndTimeClearButton = true;
    }

    isValid(v: string) {
      if (this.type === 'time') {
        if (v && v.length > 0) {
          return timeFormat24Hr.test(v)
        }
        return true;
      } else if (this.type === 'date') {
        if (v && v.length > 0) {
          return DateTimeUtils.isValidUserDate(v);
        }
        return true;
      }
    }

    formatDataStoreToPicker(dt: string) {
      if (this.type === 'time') {
        const time = moment(dt, ['h:mmA', 'h:mm A'], true);
        if (time.isValid()) {
          return time.format(this.vueTimepickerFormat);
        } else {
          if (this.is24HourFormatWithSec(dt)) {
            return moment(dt, 'HH:mm:ss').format(this.vueTimepickerFormat);
          }
        }
      } else if (this.type === 'date') {
        const date = moment(dt, 'MM/DD/YYYY', true);
        if (date.isValid()) {
          return date.format('YYYY-MM-DD');
        }
      }
      return dt;
    }

    formatPickerToDataStore(dt: string) {
      if (this.type === 'time') {
        const time = moment(dt, this.vueTimepickerFormat, true);
        if (time.isValid()) {
          return time.format('h:mm A');
        }
      } else if (this.type === 'date') {
        const date = moment(dt, 'YYYY-MM-DD', true);
        if (date.isValid()) {
          return date.format('MM/DD/YYYY');
        }
      }
      return dt;
    }

    formatPickerToDisplay(dt: string) {
      dt = this.formatPickerToDataStore(dt);
      return this.type === 'date' ? (DateTimeUtils.formatToShow(dt) || dt) : (DateTimeUtils.formatTimeToShow(dt) || dt);
    }

    formatDisplayToPicker(dt: string) {
      return this.formatDataStoreToPicker(this.type === 'date' ? (DateTimeUtils.formatToSave(dt) || dt) : (DateTimeUtils.formatTimeToSave(dt) || dt));
    }

    @Watch('localPickerValue')
    onLocalPickerValueChange() {
      this.setLocalValue();
    }

    @Watch('localStart')
    onLocalStartChange() {
      this.$nextTick(() => {
        this.validate();
      });
    }

    @Watch('localEnd')
    onLocalEndChange() {
      this.$nextTick(() => {
        this.validate();
      });
    }

    @Watch('localMenu')
    onLocalMenuChanged() {
      if (!this.localMenu) {
        if (this.currentTarget) {
          this.currentTarget.focus();
        }
        this.currentTarget = undefined;
        this.currentField = '';
      } else {
        if (CommonUtils.hasText(this.currentField)) {
          this.shift = Math.max(this.$refs.card.$el.getBoundingClientRect().left + 300 - window.innerWidth, 0);
        } else {
          this.shift = 0;
        }
      }
    }

    @Watch('rules')
    rulesChange() {
      this.validate();
    }

    onChange() {
      this.localMenu = false;
      this.setLocalValue();
    }

    validate() {
      this.localMessage = '';
      this.localRules.find(r => {
        const message = r(this.paramStart, this.paramEnd);
        if (typeof message === 'string' && CommonUtils.hasText(message)) {
          this.localMessage = message;
          return true;
        }
      });
      return CommonUtils.hasNoText(this.localMessage);
    }

    resetValidation() {
      this.localMessage = '';
    }

    reset() {
      this.localMessage = '';
      this.localStart = '';
      this.localEnd = '';
    }

    setLocalValue() {
      if (CommonUtils.hasText(this.currentField)) {
        if (this.currentField === 'localStart') {
          this.localStart = this.localPickerValue;
          if (CommonUtils.hasNoText(this.localEnd) && this.autoPopulateRange) {
            this.localEnd = this.localPickerValue;
          }
        } else {
          this.localEnd = this.localPickerValue;
          if (CommonUtils.hasNoText(this.localStart) && this.autoPopulateRange) {
            this.localStart = this.localPickerValue;
          }
        }
      }
    }

    setLocalStartFromDisplay() {
      this.localStart = this.formatDisplayToPicker(this.localStartForDisplay);
    }

    setLocalEndFromDisplay() {
      this.localEnd = this.formatDisplayToPicker(this.localEndForDisplay);
    }

    initLocalPickerValue() {
      if (this.currentField === 'localStart') {
        if (!CommonUtils.hasText(this.localStart)) this.localStart = this.initialStart;
        this.$nextTick(() => {
          this.localPickerValue = this.isValid(this.localStart) && CommonUtils.hasText(this.localStart) ? this.localStart : '';
        })
      } else {
        if (!CommonUtils.hasText(this.localEnd)) this.localEnd = this.initialEnd;
        this.$nextTick(() => {
          this.localPickerValue = this.isValid(this.localEnd) && CommonUtils.hasText(this.localEnd) ? this.localEnd : '';
        })
      }
    }

    onClick(field: string, e: Event) {
      if (this.readonly) {
        return;
      }
      this.$nextTick(() => {
        new Promise(resolve => setTimeout(resolve, 1)).then(() => {
          this.localMenu = true;
          this.localFocused = true;
          this.currentField = field;
          this.currentTarget = e.target as HTMLInputElement;
          this.initLocalPickerValue();
        });
      });
    }

    onFocus(field: string, e: Event) {
      if (this.localFocused) {
        this.$nextTick(() => {
          this.doFocus(field, e);
        })
      } else {
        this.doFocus(field, e);
      }
    }

    doFocus(field: string, e: Event) {
      this.localFocused = true;
      this.currentField = field;
      this.currentTarget = !!e && e.target ? e.target as HTMLInputElement : undefined;
      this.$emit('focus', { field, event: e });
      this.initLocalPickerValue();
    }

    onBlur(field: string, e: Event) {
      this.localFocused = false;
      this.currentField = field;
      this.currentTarget = !!e && e.target ? e.target as HTMLInputElement : undefined;
      this.$emit('blur', { field, event: e });
      this.validate();
    }

    saveFormat() {
      if (this.type === 'date') {
        return DateTimeUtils.formatToSave;
      } else {
        return DateTimeUtils.formatTimeToSave;
      }
    }

    get localTimeFormat() {
      return this.timeFormat === 1 ? '24hr' : 'ampm';
    }

    assistDate(dateInput: any, startDate: boolean) {
      const displaySettings = this.userInfo.displaySettings;
      const dateFormat = +displaySettings.dateStyling.dateFormat;
      const dateDelimiter = displaySettings.dateStyling.dateDelimiter;

      if (dateFormat === 1) { // MM/DD/YYYY
        if (dateInput.length === 1 && !isNaN(dateInput) && +dateInput > 1) {
          dateInput = '0' + dateInput + dateDelimiter;
        }
        if (dateInput.length === 2 && !dateInput.includes(dateDelimiter)) {
          dateInput = dateInput + dateDelimiter;
        } else if (dateInput.length === 2 && dateInput === (1 + dateDelimiter)) {
          dateInput = '0' + dateInput;
        }

        if (dateInput.length === 4 && !isNaN(dateInput.charAt(3)) && +dateInput.charAt(3) > 3) {
          dateInput = dateInput.substring(0, 3) + '0' + dateInput.charAt(3);
        }

        if (dateInput.length === 5) {
          if (dateInput.charAt(4) === dateDelimiter) {
            dateInput = dateInput.substring(0, 3) + '0' + dateInput.substring(3);
          } else {
            dateInput = dateInput + dateDelimiter;
          }
        }
      } else if (dateFormat === 2) { // DD/MM/YYYY
        if (dateInput.length === 1 && !isNaN(dateInput) && +dateInput > 3) {
          dateInput = '0' + dateInput + dateDelimiter;
        }

        if (dateInput.length === 2 && !dateInput.includes(dateDelimiter)) {
          dateInput = dateInput + dateDelimiter;
        } else if (dateInput.length === 2 && dateInput.includes(dateDelimiter)) {
          dateInput = '0' + dateInput;
        }
        if (dateInput.length === 4 && !isNaN(dateInput.charAt(3)) && +dateInput.charAt(3) > 1) {
          dateInput = dateInput.substring(0, 3) + '0' + dateInput.charAt(3);
        }

        if (dateInput.length === 5 && dateInput.charAt(4) === dateDelimiter) {
          dateInput = dateInput.substring(0, 3) + '0' + dateInput.substring(3, 5);
        } else if (dateInput.length === 5) {
          dateInput = dateInput + dateDelimiter;
        }
      }

      if (dateInput.length === 8) {
        const yearInput = dateInput.substring(6, 8);
        if (!isNaN(yearInput) && +yearInput !== 20 && +yearInput !== 19) {
          if (+yearInput >= 60) {
            dateInput = dateInput.substring(0, 6) + '19' + yearInput;
          } else {
            dateInput = dateInput.substring(0, 6) + '20' + yearInput;
          }
        }
      }

      if (this.isValid(dateInput) && startDate) {
        this.localStart = DateTimeUtils.formatToSave(dateInput);
      } else if (this.isValid(dateInput)) {
        this.localEnd = ld.clone(DateTimeUtils.formatToSave(dateInput));
      } else if (startDate) {
        this.localStart = dateInput;
      } else {
        this.localEnd = dateInput
      }
      this.validate();
    }

    assistTime(timeInput: any, startTime: boolean) {
      if (timeInput && this.localTimeFormat === 'ampm') {
        if (timeFormat12Hr.test(timeInput)) {
          if (!timeInput.includes(' ')) {
            if (!timeInput.endsWith(' ') && CommonUtils.isAlpha(timeInput.charAt(timeInput.length - 1))) {
              timeInput = timeInput.substring(0, timeInput.length - 1) + ' ' + timeInput.substring(timeInput.length - 1);
            } else {
              timeInput = timeInput + ' ';
            }
          }
        }
        if (timeInput.includes('  ')) {
          timeInput = timeInput.replace('  ', ' ');
        }
        if (timeInput.includes('. ')) {
          timeInput = timeInput.replace('. ', ' ');
        }
        timeInput = timeInput.toUpperCase();
      }
      if (this.isValid(timeInput) && startTime) {
        this.localStart = DateTimeUtils.formatTimeToSave(timeInput);
      } else if (this.isValid(timeInput)) {
        this.localEnd = DateTimeUtils.formatTimeToSave(timeInput);
      } else if (startTime) {
        this.localStart = timeInput;
      } else {
        this.localEnd = timeInput;
      }
      this.validate();
    }

    is24HourFormatWithSec(timeString:any) {
      const momentTime = moment(timeString, 'HH:mm:ss', true);
      return momentTime.isValid();
    }

    async startTimeErrorHandler(eventData:any[]) {
      this.startTimeErrors = eventData;
    }

    async endTimeErrorHandler(eventData:any[]) {
      this.endTimeErrors = eventData;
    }
}
