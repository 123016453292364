





import { Component, Vue } from 'vue-property-decorator';
import { Mutation, namespace, State } from 'vuex-class';
import Loading from './Loading.vue';
import OAuth2Callback from './views/pages/auth/OAuth2Callback.vue';

const settings = namespace('settings');

@Component({
  components: {
    Loading,
    OAuth2Callback
  }
})
export default class App extends Vue {
    @State
    loading!: boolean;

    @Mutation
    setLoading!: (value: boolean) => void;

    @settings.Mutation
    resetBrowserDimensions!: () => void;

    created() {
      this.resetBrowserDimensions();
      window.addEventListener('resize', () => {
        this.resetBrowserDimensions();
        this.$emit('resize');
        this.$eventBus.$emit('resize');
      });
    }
}
