
import { UserSession } from '@/common/user-session';
import EditorSection from '@/components/common/EditorSection.vue';
import EditorSectionList from '@/components/common/EditorSectionList.vue';
import TextEditorFullScreenButton from '@/components/common/TextEditorFullScreenButton.vue';
import { FormError } from '@/errors';
import CommonUtils from '@/utils/common-utils';
import DateTimeUtils from '@/utils/date-time-utils';
import ld from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import MyFilesPicker from '../pickers/MyFilesPicker.vue';

const messages = namespace('messages');
const settings = namespace('settings');
const lessons = namespace('lessons');

@Component({
  components: {
    EditorSectionList,
    EditorSection,
    MyFilesPicker,
    TextEditorFullScreenButton
  }
})
export default class MessageEditor extends Vue {
  @Prop({ type: Boolean })
  value!: boolean;

  @Prop({
    type: Object,
    default: () => {
      return {};
    }
  })
  input!: any;

  @Prop({ type: Boolean, default: true })
  isModal!: boolean;

  localRefreshKey = CommonUtils.generateUUID();
  recipients: any = [];
  recipientTypeSelected: any = [];
  isViewing = false;
  isFromUser = true;
  isToUser = false;
  selectedCommentId = '';
  selectedRecipient: any = 0;
  replyInfo: any = {};
  showReplyText = false;
  currentSchoolId: any = 0;
  isSectionsHovered = false;
  isTextFieldsVisible = true;

  get localSelectedMessage() {
    return this.$store.state.messages.selectedMessage;
  }

  set localSelectedMessage(message: any) {
    this.$store.commit('messages/setMessage', message);
  }

  get recipientsSelected() {
    return this.$store.state.messages.selectedRecipients;
  }

  set recipientsSelected(recipients: any) {
    this.$store.commit('messages/setSelectedReceipients', recipients);
  }

  get localOriginalMessage() {
    return this.$store.state.messages.originalSelectedMessage;
  }

  set localOriginalMessage(message: any) {
    this.$store.commit('messages/setOriginalMessage', message);
  }

  get haveMultipleSchools() {
    return this.schools.length > 1;
  }

  get schoolItems() {
    return this.schools.map((s: any) => {
      return { text: s.schoolName, value: +s.schoolId };
    });
  }

  get localTextEditorConfig() {
    return this.isModal ? { height: this.isTextFieldsVisible ? '370px' : 'fill' } : { height: 'fill' };
  }

  get localEditorSectionConfig() {
    return this.isModal ? { height: this.isTextFieldsVisible ? '370px' : 'fill' } : { height: 'fill' };
  }

  localRules = [(v: any) => v.length > 0 || this.$t('sendMessageError')];

  tabs = [
    {
      label: this.$t('descriptionLabel'),
      section: 'description'
    },
    {
      label: this.$t('attachmentsLabel'),
      section: 'attachments'
    }
  ];

  dialogs: any = {
    myFiles: false
  };

  @messages.Getter
  getRecipientsList!: any;

  @messages.Action
  loadRecipients!: (params?: any) => Promise<any>;

  @messages.Getter
  getMessage!: any;

  @settings.Getter('getUserInfo')
  userInfo!: any;

  @settings.Getter('getUserId')
  userId!: any;

  @settings.Getter('getUserMode')
  userMode!: any;

  @messages.Getter
  getAllMessages!: any;

  @settings.Getter('getSchools')
  schools!: any;

  @settings.Getter('getPrimarySchool')
  primarySchool!: any;

  @messages.Action
  addMessage!: (params?: any) => Promise<any>;

  @lessons.Action
  getLessonById!: (lessonId: any) => Promise<any>;

  get stringifyDate() {
    return DateTimeUtils.stringifyDate;
  }

  get filteredTabs() {
    if (this.isViewing && this.attachments.length === 0) {
      return [this.tabs[0]];
    } else {
      return this.tabs;
    }
  }

  get recipientsList(): any {
    return this.getRecipientsList;
  }

  get localActiveTab() {
    return this.$store.state.messages.activeTab;
  }

  set localActiveTab(tab: number) {
    this.$store.commit('messages/setActiveTab', tab);
  }

  get attachments(): Array<any> {
    return this.$store.state.messages.attachments || [];
  }

  set attachments(val: Array<any>) {
    this.$store.commit('messages/setAttachments', val);
  }

  get attachmentItems() {
    return this.attachments.map((a, i) => {
      const name = (a.name || a.filename || a.fileName || '');
      return {
        key: name + i,
        shortValue: '',
        value: name,
        data: a
      }
    })
  }

  get hasAttachments() {
    return CommonUtils.isNotEmpty(this.attachments);
  }

  get isReply() {
    return this.localSelectedMessage.replyType && this.localSelectedMessage.replyType.length > 0
  }

  get localPage() {
    return CommonUtils.getPage(this.$route);
  }

  @Watch('currentSchoolId')
  changeSchoolId() {
    this.recipients = [];
    this.loadRecipients({ schoolId: this.currentSchoolId }).then(() => {
      this.recipients = this.recipientsList;
      if (this.recipientsSelected.length) {
        const availableRecipients = this.recipientsList.map((l:any) => l.recipientId);
        this.recipientsSelected = this.recipientsSelected.filter((r: any) => availableRecipients.includes(r.recipientId))
      }
    });
  }

  @Watch('recipientsSelected')
  recipientChange() {
    if (this.recipientsSelected === undefined || this.recipientsSelected.length === 0) {
      return;
    }
    this.recipientTypeSelected = this.recipientsSelected.map((r: any) => {
      return r.recipientType
    })
    if ((this.recipientTypeSelected.includes('Y') || this.recipientTypeSelected.includes('C')) && this.recipientTypeSelected.includes('S')) {
      this.recipientsSelected = this.recipientsSelected.filter((r: any) => r.recipientType !== 'S');
    }
    if (this.recipientTypeSelected.includes('Y') && this.recipientTypeSelected.includes('C')) {
      this.recipientsSelected = this.recipientsSelected.filter((r: any) => r.recipientType !== 'C');
    }
  }

  get activeRecipients() {
    this.recipientTypeSelected = this.recipientsSelected.map((r: any) => {
      return r.recipientType
    });
    const recipients = this.recipients.map((r: any) => {
      if (this.isDisabled(r)) {
        r.disabled = true;
      } else {
        r.disabled = false;
      }
      return r;
    });

    if (this.currentSchoolId) {
      return recipients.filter((r: any) => {
        return r.schoolId === undefined || r.schoolId === this.currentSchoolId;
      });
    } else {
      return recipients;
    }
  }

  isDisabled(item: any) {
    if (this.recipientTypeSelected.includes('Y') && item.recipientType === 'C') {
      return true;
    } else if ((this.recipientTypeSelected.includes('Y') || this.recipientTypeSelected.includes('C')) && item.recipientType === 'S') {
      return true;
    }
    return false;
  }

  @Watch('input')
  initialize() {
    this.isViewing = (this.input.action === 'view');
    this.recipients = this.recipientsList;
    this.localRefreshKey = CommonUtils.generateUUID();
    let hasFoundRecipient = false;

    if (this.input.loadData === false && !this.isViewing) {
      return;
    }

    CommonUtils.showLoading();
    this.localActiveTab = 0;
    this.recipientsSelected = [];
    this.$store.commit('messages/resetMessage');

    if (this.input.action === 'view') {
      this.selectedCommentId = this.input.objectId;
      if (this.input.messageObject) {
        this.localSelectedMessage = this.input.messageObject
        this.isFromUser = (this.input.messageObject.authorId === this.userId);
        this.isToUser = (this.input.messageObject.recipientId === this.userId);
        this.attachments = this.input.messageObject.attachments;
      }
      if (this.isReply) {
        if (this.localSelectedMessage.replyType === 'LESSON') {
          this.getLessonById(this.localSelectedMessage.replyId).then((d: any) => {
            this.replyInfo = {
              teacherName: d.day.objects[0].teacherName || '',
              lessonDate: this.stringifyDate(d.day.date),
              className: d.day.objects[0].className
            }
          });
        } else if (this.localSelectedMessage.replyType === 'COMMENT') {
          this.replyInfo = this.getMessageById(this.localSelectedMessage.replyId);
        }
      }
    } else if (this.input.action === 'reply') {
      const foundRecipient = this.recipientsList.find((r: any) => r.recipientId === this.input.recipient)
      if (foundRecipient) {
        hasFoundRecipient = true;
        this.selectedRecipient = this.input.recipient;
        this.currentSchoolId = foundRecipient.schoolId;
        this.loadSelectedRecipient();
      }
      this.localSelectedMessage.attachToLesson = this.input.lessonId || 0;
      this.localSelectedMessage.attachToMessage = this.input.messageId || 0;
      if (this.localSelectedMessage.attachToLesson !== 0) {
        this.localSelectedMessage.replyType = 'LESSON';
        this.replyInfo = {
          teacherName: foundRecipient ? foundRecipient.recipientName : '',
          className: this.input.className,
          lessonDate: this.stringifyDate(this.input.lessonDate)
        }
      } else if (this.localSelectedMessage.attachToMessage !== 0) {
        this.localSelectedMessage.replyType = 'COMMENT';
        this.replyInfo = this.getMessageById(this.localSelectedMessage.attachToMessage)
        if (this.replyInfo && !foundRecipient) {
          this.selectedRecipient = this.replyInfo.authorId;
          this.loadSelectedRecipient();
        }
        if (!this.replyInfo) {
          this.localSelectedMessage.replyType = '';
          this.localSelectedMessage.attachToMessage = 0;
        }
      }
    } else if (this.input.action === 'submissions') {
      this.loadSelectedRecipients(this.input.teachers);
    }

    if (!this.isViewing) {
      this.attachments = [];
      this.localOriginalMessage = ld.cloneDeep(this.localSelectedMessage);
      this.$store.commit('messages/setOriginalRecipients');
      if ((this.$currentUser.isDistrictAdmin || this.haveMultipleSchools) && !hasFoundRecipient) {
        this.currentSchoolId = (this.$store.state.teachers.currentSchoolId || this.primarySchool.schoolId);
      }
    }

    if ((this.input.action !== 'view') || (this.input.messageObject)) {
      CommonUtils.hideLoading();
    }
  }

  getMessageById(commentId: number) {
    return this.getAllMessages.find((m: any) => m.commentId === commentId);
  }

  loadSelectedRecipient() {
    this.recipientsSelected = [];
    if (this.selectedRecipient > 0) {
      this.recipientsSelected.push(this.recipientsList.find((r: any) => r.recipientId === this.selectedRecipient));
      this.selectedRecipient = 0;
    }
  }

  loadSelectedRecipients(recipients: Array<any>) {
    this.recipientsSelected = [];
    for (const i in recipients) {
      this.recipientsSelected.push(this.recipientsList.find((r: any) => r.recipientId === recipients[i]));
    }
  }

  created() {
    this.initialize();
  }

  changesMade() {
    const origRecipients = this.$store.state.messages.originalRecipients;
    return (!this.isViewing && (!ld.isEqual(this.localOriginalMessage, this.localSelectedMessage) ||
      this.attachments.length > 0 || !ld.isEqual(this.recipientsSelected, origRecipients)))
  }

  resetValidation() {
    const form: any = this.$refs.messageEditorForm;
    form.resetValidation();
  }

  // ACTIONS
  sendMessage() {
    const form: any = this.$refs.messageEditorForm
    if (form.validate()) {
      return this.confirmMessage().then(() => {
        this.localPage === 'messages' ? this.$store.commit('messages/setListLoading', true) : CommonUtils.showLoading();
        this.recipientTypeSelected = this.recipientsSelected.map((r: any) => {
          return r.recipientType;
        })
        const recipientIds = this.recipientsSelected.map((r: any) => {
          return r.recipientId;
        })
        const replyType = this.localSelectedMessage.replyType;
        const payload: any = {
          commentText: this.localSelectedMessage.commentText,
          recipientId: recipientIds,
          recipientType: this.recipientTypeSelected,
          private: this.localSelectedMessage.messagePrivate
        }
        if (this.localSelectedMessage.attachToLesson > 0) {
          payload.lessonId = this.localSelectedMessage.attachToLesson
        }
        if (this.localSelectedMessage.attachToMessage > 0) {
          payload.attachToMessage = this.localSelectedMessage.attachToMessage
        }
        return this.addMessage(payload).then().finally(async () => {
          if (replyType === 'LESSON') await this.$store.dispatch('plans/reloadPlans');
          CommonUtils.hideLoading();
          form.resetValidation();
          this.$eventBus.$emit('closeMessageEditor', true);
          this.selectedRecipient = 0;
          return Promise.resolve(false);
        });
      }).catch(() => {
        return Promise.reject(new FormError());
      })
    } else {
      return Promise.reject(new FormError());
    }
  }

  confirmMessage() {
    if (this.localSelectedMessage.commentText.length === 0) {
      const confirm: any = this.$refs.confirm;
      return confirm.confirm({
        title: this.$t('messageLabel'),
        text: this.$t('emptyMessageWarning'),
        option1ButtonAlternativeText: this.$t('continueLabel')
      }).then((result: any) => {
        if (result === 1) {
          return Promise.resolve();
        } else {
          return Promise.reject(new FormError());
        }
      });
    }
    return Promise.resolve();
  }

  // ATTACHMENTS
  openDialog(type: string) {
    for (const key in this.dialogs) {
      this.dialogs[key] = type === key;
    }
  }

  deleteAttachment(item: any, index: number) {
    this.attachments = this.attachments.filter((s, i) => i !== index);
  }

  remove(item: any) {
    this.recipientsSelected = this.recipientsSelected.filter((i: any) => i.recipientId !== item.recipientId);
  }

  toggleAttachmentPrivateFlag(item: any, index: number) {
    const newAttachments = ld.cloneDeep(this.attachments);
    const attachment = newAttachments.find((a, i) => i === index);
    attachment.privateFlag = !attachment.privateFlag;
    ld.set(newAttachments, index, attachment);
    this.attachments = newAttachments;
  }

  addGoogleDriveAttachments() {
    this.$google.setAuthToken(UserSession.getGoogleAuthToken());
    this.$google.openPicker((data: any) => {
      this.attachments = this.attachments.concat([{
        filename: data.name,
        fileType: 'drive',
        url: data.url,
        id: data.id,
        privateFlag: false
      }]);
    });
  }

  dropAttachment(e: any) {
    e.preventDefault();
    if (!this.isViewing) {
      this.localActiveTab = 1;
      const fileCount = e.dataTransfer.files.length;
      const droppedFiles = [];
      for (let i = 0; i < fileCount; i++) {
        droppedFiles.push(e.dataTransfer.files[i]);
      }
      const filesPicker: any = this.$refs.filesPicker;
      filesPicker.appendFiles(droppedFiles);
      filesPicker.saveFiles();
    }
  }

  clearAttachments() {
    const attachmentsLabel = this.$t('attachmentsLabel') as string;
    const confirm: any = this.$refs.confirm;
    confirm.confirm({
      title: this.$t('clearAllLabel', { text: attachmentsLabel }),
      text: this.$t('clearAllMsg', { text: attachmentsLabel }),
      option1ButtonAlternativeText: this.$t('okLabel')
    }).then((result: any) => {
      if (result === 1) {
        this.attachments = [];
      }
    });
  }

  openLink(attachment: any) {
    const url = attachment.url;
    const name = attachment.name || attachment.filename || attachment.fileName;
    const downloadForm = document.getElementById('downloadForm') as HTMLFormElement;
    if (CommonUtils.hasText(url)) {
      window.open(url, '_blank');
    } else {
      downloadForm.innerHTML = '';
      const attachmentName = document.createElement('input');
      attachmentName.setAttribute('name', 'attachmentName');
      attachmentName.setAttribute('value', name);
      downloadForm.appendChild(attachmentName);

      const teacherId = document.createElement('input');
      teacherId.setAttribute('name', 'teacherId');
      teacherId.setAttribute('value', this.userInfo.teacherId);
      downloadForm.appendChild(teacherId);

      const collaborateSubjectId = document.createElement('input');
      collaborateSubjectId.setAttribute('name', 'collaborateSubjectId');
      collaborateSubjectId.setAttribute('value', '0');
      downloadForm.appendChild(collaborateSubjectId);

      const accessToken = document.createElement('input');
      accessToken.setAttribute('name', 'X-PB-ACCESS-TOKEN');
      accessToken.setAttribute('value', UserSession.getAccessToken());
      downloadForm.appendChild(accessToken);

      const yearId = document.createElement('input');
      accessToken.setAttribute('name', 'X-PB-CLIENT-YEAR-ID');
      accessToken.setAttribute('value', UserSession.getCurrentYearIdAsString());
      downloadForm.appendChild(yearId);

      downloadForm.submit();
    }
  }

  hideOrShowTextFields() {
    this.isTextFieldsVisible = !this.isTextFieldsVisible;
    this.localRefreshKey = CommonUtils.generateUUID();
  }
}
