import axios, { BASE_URL } from '@/axios';
import FileServices from './file-services';

export default class ReportingServices {
  public static async getStandardsReport(params: any) {
    return axios.get('getStandardsReport', { params });
  }

  public static async getStandardLessons(params: any) {
    return axios.get('getStandardLessons', { params });
  }

  public static async createPDFStandards(params: Array<any>) {
    const formData = new URLSearchParams();
    params.forEach((p: any) => {
      formData.append(p.name, p.value);
    });
    return fetch(BASE_URL + '/createPDFStandards', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      credentials: 'include',
      body: formData
    }).then(async (response) => {
      const type = response.headers.get('Content-Type') as string;
      const disposition = response.headers.get('Content-Disposition') as string;
      const fileName = FileServices.getFileName(disposition);
      if (type.includes('json')) {
        const data = await response.json();
        window.open(data.documentLink, '_blank');
      } else {
        const blob = await response.blob();
        FileServices.downloadFile(blob, fileName, type);
      }
      return Promise.resolve();
    });
  }

  public static async downloadStandards(params: Array<any>) {
    const formData = new URLSearchParams();
    params.forEach((p: any) => {
      formData.append(p.name, p.value);
    });
    return fetch(BASE_URL + '/downloadStandards', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      credentials: 'include',
      body: formData
    }).then(async (response) => {
      const type = response.headers.get('Content-Type') as string;
      const disposition = response.headers.get('Content-Disposition') as string;
      const fileName = FileServices.getFileName(disposition);
      if (type.includes('json')) {
        const data = await response.json();
        window.open(data.documentLink, '_blank');
      } else {
        const blob = await response.blob();
        FileServices.downloadFile(blob, fileName, type);
      }
      return Promise.resolve();
    });
  }
}
