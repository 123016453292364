var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app",
    {
      staticClass: "fill-height fill-width",
      staticStyle: { "background-color": "#8b9ed1" },
    },
    [
      _c(
        "v-sheet",
        {
          staticStyle: {
            position: "absolute",
            top: "50%",
            left: "50%",
            "margin-top": "-120px",
            "margin-left": "-100px",
            "background-color": "#8b9ed1",
          },
        },
        [
          _c("object", {
            attrs: {
              data: "https://cdn.planbook.com/images/planbook-loading-bump.svg",
              width: "200",
              height: "200",
              alt: "Planbook Loading",
            },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }