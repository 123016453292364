import CommonUtils from '@/utils/common-utils';
import { cmnSession } from './planbook-storage';
import jwtDecode from 'jwt-decode';

const debug = process.env.NODE_ENV !== 'production';
declare const window: any;

export declare interface User {
  teacherId?: number;
  currentSchoolYearId?: number;
  accessToken?: string;
  email?: string;
  key?: string;
  view?: string;
  subjectId?: number;
  studentId?: number;
  type?: string;
}

export class UserSession {
  public static setCurrentUserFromToken(accessToken: string, yearId?: any) {
    const decoded: any = jwtDecode(accessToken);
    const newClaims = decoded['https://planbook.com/claims'];
    if (newClaims) {
      const type = newClaims.type;
      const generic = newClaims.generic;
      const id = newClaims.id;
      const nYearId = newClaims.yearid;
      const email = newClaims.email;
      UserSession.setCurrentUser({
        teacherId: type !== 'S' || generic ? id : undefined,
        studentId: type === 'S' && !generic ? id : undefined,
        currentSchoolYearId: type !== 'S' ? (+yearId || +nYearId) : undefined,
        email: type !== 'S' ? email : undefined,
        accessToken: accessToken,
        type: type
      });
      return;
    }
    const rawUser: any = JSON.parse(decoded.sub);
    if (rawUser.type === 'S') {
      UserSession.setCurrentUser({
        accessToken: accessToken,
        studentId: rawUser.id,
        type: rawUser.type
      });
    } else {
      UserSession.setCurrentUser({
        teacherId: rawUser.id,
        currentSchoolYearId: +yearId || rawUser.yearId,
        accessToken: accessToken,
        email: rawUser.email,
        type: rawUser.type
      });
    }
  }

  public static setCurrentUser(user: User) {
    cmnSession.set('CurrentUser', user);
  }

  public static getCurrentUser(): User | undefined {
    return cmnSession.get('CurrentUser');
  }

  public static getCurrentYearId() {
    return UserSession.getCurrentUser()?.currentSchoolYearId;
  }

  public static getCurrentYearIdAsString() {
    const yearId = UserSession.getCurrentYearId();
    return yearId ? yearId + '' : '';
  }

  public static hasSession() {
    const currentUser = UserSession.getCurrentUser();
    return currentUser && (CommonUtils.hasText(currentUser.accessToken) || CommonUtils.hasText(currentUser.key));
  }

  public static getAccessToken(): string {
    const currentUser = UserSession.getCurrentUser();
    if (currentUser) {
      return currentUser.accessToken || '';
    }
    return '';
  }

  public static removeCurrentUser() {
    cmnSession.remove('CurrentUser');
  }

  public static setGoogleAuthToken(gsToken: string) {
    cmnSession.set('gsToken', gsToken);
  }

  public static getGoogleAuthToken(): string {
    return cmnSession.get('gsToken');
  }
}

if (debug) {
  window.UserSession = UserSession;
}
