var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "fill-height d-flex flex-column justify-center align-center",
    },
    [
      _c("div", { staticClass: "text-center" }, [
        _c(
          "div",
          [
            _c("v-icon", { attrs: { size: "200", color: "#eaeced" } }, [
              _vm._v("fal fa-chalkboard-teacher"),
            ]),
          ],
          1
        ),
        _c("div", { staticStyle: { "font-size": "20px", color: "#eaeced" } }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }